import { ReactElement } from 'react'
import { Source, Layer } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { FiltersCategories, ObjectsLayer, SignalType } from 'components/Map/ObjectsLayers/types'
import { getFilterCheckStatus, getLayerVisibilityByType } from '../../utils'

export default function GaLayer(): ReactElement {
  const { signalLayerData, filtersList } = useSelector((state: RootState) => state.object)

  return (
    <>
      <Source type="geojson" data={signalLayerData} id={`disque-${SignalType.ga}`}>
        <Layer
          id={`description-${SignalType.ga}`}
          filter={['all',
            ['==', 'auto_added', true],
            ['==', 'type', 'GUIDON_DARRET'],
          ]}
          type="symbol"
          minzoom={12}
          paint={{
            'text-color': '#000000',
          }}
          layout={{
            'text-rotation-alignment': 'map',
            'text-allow-overlap': true,
            'text-field': ['get', 'description'],
            'text-offset': [
              'case',
              [
                'all',
                ['==', ['get', 'type'], 'GUIDON_DARRET'],
                ['==', ['get', 'direction'], 'D'],
              ],
              ['literal', [2, -4.8]],
              [
                'all',
                ['==', ['get', 'type'], 'GUIDON_DARRET'],
                ['==', ['get', 'direction'], 'C'],
              ],
              ['literal', [-2, -4.8]],
              ['literal', [2.8, -3.2]],
            ],
            'text-rotate': ['get', 'angle'],
            'text-size': ['interpolate', ['linear'], ['zoom'], 10, 5, 15, 12],
            'text-font': ['Roboto Condensed'],
            visibility: getLayerVisibilityByType(filtersList, FiltersCategories.Signal, SignalType.ga,
              getFilterCheckStatus(filtersList, FiltersCategories.General, ObjectsLayer.gaia)),
          }}
        />
        <Layer
          id={`km-${SignalType.ga}`}
          filter={['all',
            ['==', 'auto_added', true],
            ['==', 'type', 'GUIDON_DARRET'],
          ]}
          type="symbol"
          minzoom={12}
          paint={{
            'text-color': '#41A8F9',
          }}
          layout={{
            'text-rotation-alignment': 'map',
            'text-allow-overlap': true,
            'text-field': ['get', 'pk_sncf'],
            'text-offset': [
              'case',
              [
                'all',
                ['==', ['get', 'type'], 'GUIDON_DARRET'],
                ['==', ['get', 'direction'], 'D'],
              ],
              ['literal', [2, -3.8]],
              [
                'all',
                ['==', ['get', 'type'], 'GUIDON_DARRET'],
                ['==', ['get', 'direction'], 'C'],
              ],
              ['literal', [-2, -3.8]],
              ['literal', [2.8, -3.2]],
            ],
            'text-rotate': ['get', 'angle'],
            'text-size': ['interpolate', ['linear'], ['zoom'], 10, 5, 15, 12],
            'text-font': ['Roboto Condensed'],
            visibility: getLayerVisibilityByType(filtersList, FiltersCategories.Signal, SignalType.ga,
              getFilterCheckStatus(filtersList, FiltersCategories.General, ObjectsLayer.gaia)),
          }}
        />
        <Layer
          id={`mat-${SignalType.ga}`}
          filter={['all',
            ['==', 'auto_added', true],
            ['==', 'type', 'GUIDON_DARRET'],
          ]}
          type="symbol"
          minzoom={12}
          paint={{}}
          layout={{
            'text-field': '',
            'text-font': ['saxmono'],
            'text-size': 9,
            'icon-image': [
              'case',
              ['==', ['get', 'direction'], 'C'],
              'MATG',
              ['==', ['get', 'direction'], 'D'],
              'MATD',
              '',
            ],
            'icon-size': [
              'interpolate',
              ['linear'],
              ['zoom'],
              10,
              0.1,
              15,
              0.7,
            ],
            'icon-rotation-alignment': 'map',
            'icon-pitch-alignment': 'map',
            'icon-rotate': ['get', 'angle'],
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
            'text-allow-overlap': true,
            'text-ignore-placement': true,
            visibility: getLayerVisibilityByType(filtersList, FiltersCategories.Signal, SignalType.ga,
              getFilterCheckStatus(filtersList, FiltersCategories.General, ObjectsLayer.gaia)),
          }}
        />
        <Layer
          id={SignalType.ga}
          filter={['all',
            ['==', 'auto_added', true],
            ['==', 'type', 'GUIDON_DARRET'],
          ]}
          type="symbol"
          minzoom={12}
          paint={{}}
          layout={{
            'icon-rotation-alignment': 'map',
            'icon-pitch-alignment': 'map',
            'icon-rotate': ['get', 'angle'],
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
            'icon-offset': [
              'case',
              ['==', ['get', 'direction'], 'D'],
              ['literal', [35, -90]],
              ['==', ['get', 'direction'], 'C'],
              ['literal', [-35, -90]],
              ['literal', [0, 0]],
            ],
            'icon-image': [
              'case',
              ['in', ['string', ['get', 'type']], ['literal', 'GUIDON_DARRET']],
              ['concat', 'SCH ', 'GA'],
              '',
            ],
            'icon-size': [
              'interpolate',
              ['linear'],
              ['zoom'],
              10,
              0.1,
              15,
              0.6,
            ],
            visibility: getLayerVisibilityByType(filtersList, FiltersCategories.Signal, SignalType.ga,
              getFilterCheckStatus(filtersList, FiltersCategories.General, ObjectsLayer.gaia)),
          }}
        />
      </Source>
    </>
  )
}
