import { ReactElement, useEffect, useState } from 'react'
import FormInput from 'components/common/Inputs/FormInput'
import { Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { updateAreaFields, updateLabelText } from 'reducers/label'
import { AiOutlineClose } from 'react-icons/ai'
import terms from 'common/terms'
import { DESCRIPTION_FIELDS } from './PanelField'

import './panelInput.scss'
import AreaInputFields from './AreaInputFields'
import { AreaFields } from '../types'

export default function PanelInputFields(): ReactElement {
  const dispatch = useDispatch()
  const {
    areaFields, responseError, labelText,
  } = useSelector((state: RootState) => state.label)

  const [labelDescription, setLabelDescription] = useState('')

  useEffect(() => {
    if (areaFields.length === 0) {
      dispatch(updateAreaFields([{
        index: 1,
        area: {
          code_ligne: '',
          nom_voie: null,
          pk_debut: '',
          pk_fin: '',
        },
      }]))
    }
  }, [areaFields])

  const handleClick = (index: number) => {
    const newAreaFields: Array<AreaFields> = []
    areaFields.forEach((area: AreaFields) => {
      if (area.index !== index) {
        newAreaFields.push({ ...area, index: area.index > index ? area.index - 1 : area.index })
      }
    })
    dispatch(updateAreaFields(newAreaFields))
  }

  const onLabelTextChange = (value: string) => {
    setLabelDescription(value)
    dispatch(updateLabelText(value))
  }

  return (
    <Form className="panel-form" autoComplete="off">
      <FormInput
        field={DESCRIPTION_FIELDS}
        onChange={e => onLabelTextChange(e.target.value)}
        value={labelText || labelDescription}
        extraStyle="px-2"
        responseError={responseError}
      />
      <div className="divider" />
      {areaFields.map((area: AreaFields) => (
        <div key={area.index}>
          <div className="d-flex justify-content-between align-items-center my-4 px-2">
            <div className="area-text">{`${terms.Portfolio.position}${area.index}`}</div>
            {areaFields.length > 1 && (
            <div className="mr-2 action-cursor-pointer">
              <AiOutlineClose onClick={() => handleClick(area.index)} />
            </div>
            )}
          </div>
          <AreaInputFields index={area.index} />
        </div>
      ))}
    </Form>
  )
}
