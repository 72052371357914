import { ReactElement } from 'react'
import { FiUpload } from 'react-icons/fi'
import Dropzone from 'react-dropzone'
import terms from 'common/terms'

export default function DocumentUpload({ handleDrop }: {handleDrop: (uploadedFile: File[]) => void}): ReactElement {
  return (
    <Dropzone
      onDrop={handleDrop}
      accept={{
        'application/vnd.ms-excel.sheet.macroenabled.12': ['.xls', '.xlsx', '.xlsm'],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xls', '.xlsx', '.xlsm'],
        'application/vnd.ms-excel': ['.xls', '.xlsx', '.xlsm'],
      }}
    >
      {({
        getRootProps,
        getInputProps,
      }) => (
        <div {...getRootProps({ className: 'dropzone' })}>
          <input
            {...getInputProps()}
          />
          <div className="import-box">
            <FiUpload className="import-icon-style" />
            <div className="import-text">
              {terms.Portfolio.Import.dragAndDropNotice}
              <strong>{terms.Portfolio.Import.selectNotice}</strong>
            </div>
          </div>
        </div>
      )}
    </Dropzone>
  )
}
