/* eslint-disable react/no-array-index-key */
import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'
import { PrFields } from 'reducers/RemarkablePoints/types'
import { Typography, IconButton } from '@mui/material'
import terms from 'common/terms'
import { toggleDeletePhaseField, updatePhaseFields } from 'reducers/MultiYearPlan/OperationActions/operationactions'
import { OperationWorkPhaseFields, OperationWorkPhase } from 'reducers/MultiYearPlan/OperationActions/types'
import { setResponseError } from 'reducers/MultiYearPlan/Library/library'
import WorkPhaseInputs from './WorkPhaseInputs'

export default function WorkPhasesContainer(): ReactElement {
  const dispatch = useDispatch()
  const { phaseFields, selectedOperation } = useSelector((state: RootState) => state.mypoperationactions)
  const { selectedDefectedOperation, libraryResponseError } = useSelector((state: RootState) => state.mypLibrary)

  useEffect(() => {
    if (phaseFields.length === 0) {
      dispatch(updatePhaseFields([{
        index: 1,
        phase: {
          year: 0,
          phase: '',
        },
      }]))
    }
  }, [phaseFields])

  useEffect(() => {
    if (selectedOperation) {
      const existingPhaseFields: OperationWorkPhaseFields[] = []
      selectedOperation.years.forEach((phase: OperationWorkPhase, phaseindex: number) => {
        existingPhaseFields.push({
          phase: {
            year: phase.year,
            phase: phase.phase,
          },
          index: phaseindex + 1,
        })
      })
      dispatch(updatePhaseFields(existingPhaseFields))
    }
    if (selectedDefectedOperation) {
      const existingPhaseFields: OperationWorkPhaseFields[] = []
      selectedDefectedOperation.defected_years.forEach((phase: OperationWorkPhase, phaseindex: number) => {
        existingPhaseFields.push({
          phase: {
            year: phase.year,
            phase: phase.phase,
          },
          index: phaseindex + 1,
        })
      })
      dispatch(updatePhaseFields(existingPhaseFields))
    }
  }, [])

  const handleClick = (index: number) => {
    const newPhaseFields: OperationWorkPhaseFields[] = []
    phaseFields.forEach((phase: OperationWorkPhaseFields) => {
      if (phase.index !== index) {
        newPhaseFields.push({ ...phase, index: phase.index > index ? phase.index - 1 : phase.index })
      }
    })
    dispatch(updatePhaseFields(newPhaseFields))
    if (selectedDefectedOperation && libraryResponseError) {
      const newErrors = {
        ...libraryResponseError,
        message: {
          ...libraryResponseError.message,
          years: libraryResponseError.message.years.filter((_error: string, errorIndex: number) => (errorIndex
             !== index - 1)),
        },
      }
      dispatch(setResponseError(newErrors))
    }
    dispatch(toggleDeletePhaseField(true))
  }

  const onPhaseAdd = () => {
    const newPhaseField: OperationWorkPhaseFields[] = [{
      index: phaseFields.length + 1,
      phase: {
        year: 0,
        phase: '',
      },
    }]
    dispatch(updatePhaseFields(phaseFields.concat(newPhaseField)))
  }

  return (
    <div className="ferroloc-phase-container">
      <div className="ferroloc-phase-header">
        <Typography>
          {terms.Inputs.phase}
        </Typography>
        <div>
          <IconButton onClick={onPhaseAdd}><AddIcon /></IconButton>
        </div>
      </div>
      {phaseFields.map((phase: PrFields, index: number) => (
        <div key={`${phase.index}-${index}`} className="d-flex">
          <WorkPhaseInputs phaseIndex={phase.index} />
          {phaseFields.length > 1 && (
          <div className="remove-btn-icon">
            <IconButton onClick={() => handleClick(phase.index)}><RemoveIcon /></IconButton>
          </div>
          )}
        </div>
      ))}
    </div>
  )
}
