export const DEFAULT_COLORS = [
  '#6e1e78',
  '#a1006b',
  '#cd0037',
  '#e05206',
  '#ffb612',
  '#d2e100',
  '#82be00',
  '#009aa6',
  '#0088ce',
  '#d52b1e',
  '#3c3732',
  '#ffffff',
  '#e0ded8',
  '#c3beb4',
  '#afa59b',
  '#988f86',
  '#82786f',
  '#675c53',
  '#e1e1e1',
  '#d7d7d7',
  '#b9b9b9',
  '#a0a0a0',
  '#747678',
  '#4d4f53',
]

export default DEFAULT_COLORS
