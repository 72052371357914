import terms from 'common/terms'
import { ReactElement } from 'react'
import { Card } from 'react-bootstrap'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { FileTypes } from 'reducers/Files/types'
import { RootState } from 'Store'

export default function ImportNotice(): ReactElement {
  const { activeFile } = useSelector((state: RootState) => state.files)
  return (
    <Card className="import-notice-card">
      <Card.Body>
        <AiOutlineInfoCircle />
        <ul>
          <li>{terms.Portfolio.Import.docNotice}</li>
          <li>{terms.Portfolio.Import.excelFilterNotice}</li>
          {activeFile?.type === FileTypes.Synoptic && <li>{terms.Portfolio.Import.ferrolocationFieldsNotice}</li>}
        </ul>
      </Card.Body>
    </Card>
  )
}
