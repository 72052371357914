import { ReactElement } from 'react'
import { Grid } from '@mui/material'
import FileUploadIcon from '@mui/icons-material/FileUploadOutlined'
import terms from 'common/terms'
import { useDropzone } from 'react-dropzone'
import history from 'customHistory'
import PathNames from 'common/PathNames'
import { useDispatch } from 'react-redux'
import { setError } from 'reducers/Repertories/repertories'
import { dropError, dropRejected } from './utils'

export default function PictureUpload(): ReactElement {
  const dispatch = useDispatch()
  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    maxFiles: 1,
    maxSize: 10485760,
    accept: {
      'image/': ['.jpeg', '.png', '.jpg'],
    },
    onDropAccepted: acceptedFile => {
      history.push({
        pathname: PathNames.PicturePreviz,
        state: acceptedFile,
      })
    },
    onDropRejected: () => {
      dispatch(setError(dropRejected))
    },
    onError: () => {
      dispatch(setError(dropError))
    },
  })
  return (
    <Grid item xs={10} sm={8} md={6} lg={4} {...getRootProps()}>
      <div>
        <input {...getInputProps()} />
        <p className="drop-zone">
          <FileUploadIcon sx={{ fontSize: '90px', cursor: 'pointer' }} />
          {terms.Repertory.selectFile}
        </p>
      </div>
    </Grid>
  )
}
