import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Filter, MypSettingsState } from './types'
import {
  deleteFilter, getAppliedFiltersList, getFiltersList, postFilter,
} from './settings.thunk'

const initialState: MypSettingsState = {
  displaySettingsPanel: false,
  filtersList: [],
  appliedFilters: [],
  exportAlert: false,
}

export const mypSettingsSlice = createSlice({
  name: 'mypSettings',
  initialState,
  reducers: {
    toggleDisplaySettingsPanel: (state, action: PayloadAction<boolean>) => {
      state.displaySettingsPanel = action.payload
    },
    updateAppliedFilters: (state, action: PayloadAction<Filter[]>) => {
      state.appliedFilters = action.payload
    },
    setExportAlert: (state, action: PayloadAction<boolean>) => {
      state.exportAlert = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getFiltersList.fulfilled, (state, action) => {
      state.filtersList = action.payload
    })
    builder.addCase(getAppliedFiltersList.fulfilled, (state, action) => {
      state.appliedFilters = action.payload
    })
    builder.addCase(postFilter.fulfilled, (state, action) => {
      state.addedFilter = action.payload
    })
    builder.addCase(deleteFilter.fulfilled, (state, action) => {
      state.deletedFilter = action.payload
    })
  },
})

export const { toggleDisplaySettingsPanel, updateAppliedFilters, setExportAlert } = mypSettingsSlice.actions

export default mypSettingsSlice.reducer
