import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { Grid } from '@mui/material'
import { Folder } from 'reducers/Folders/types'
import FolderCard from './FolderCard'
import DeleteFolderModal from './DeleteFolderModal'

import './folder.scss'
import ContextMenu from './ContextMenu'

export default function FoldersContainer(): ReactElement {
  const { folders, actionsContext, xYPosition } = useSelector((state: RootState) => state.folders)
  return (
    <Grid
      item
      container
      spacing={1}
      className="folder-container"
    >
      {folders.map((folder: Folder) => (
        <Grid item key={folder.id} sx={{ justifyContent: 'center' }}>
          <FolderCard folder={folder} />
        </Grid>
      ))}
      <ContextMenu context={actionsContext} xyPosition={xYPosition} />
      <DeleteFolderModal />
    </Grid>
  )
}
