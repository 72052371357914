import terms from 'common/terms'
import { InputField, InputTypes } from 'types'

export const ATTRIBUTS_FIELD: InputField = {
  key: 'attributs',
  label: '',
  placeholder: terms.Inputs.attritutSelectorPlaceholder,
  required: false,
  inputType: InputTypes.Select,
  withSearch: true,
}

export const FERRO_LOCALIZATION_FIELDS: Array<InputField> = [
  {
    key: 'code_ligne',
    label: terms.Inputs.lineNumber,
    hideLabel: true,
    placeholder: terms.Inputs.lineAttributPlacehoalder,
    required: false,
    inputType: InputTypes.Select,
    withSearch: true,
  },
  {
    key: 'nom_voie',
    label: terms.Inputs.trackName,
    hideLabel: true,
    placeholder: terms.Inputs.trackAttributPlacehoalder,
    required: false,
    inputType: InputTypes.Select,
    withSearch: true,
  },
  {
    key: 'pk_debut',
    label: terms.Inputs.pkDebut,
    hideLabel: true,
    placeholder: terms.Inputs.pkDebutAttributPlacehoalder,
    required: false,
    inputType: InputTypes.Select,
    withSearch: true,
  },
  {
    key: 'pk_fin',
    label: terms.Inputs.pkFin,
    hideLabel: true,
    placeholder: terms.Inputs.pkFinAttributPlacehoalder,
    required: false,
    inputType: InputTypes.Select,
    withSearch: true,
  },
]

export const ELIGIBILITY_GRID: InputField = {
  key: 'eligibility',
  label: terms.Inputs.eligibilityGrid,
  hideLabel: true,
  placeholder: terms.Inputs.eligibilityGridPlacehoalder,
  required: false,
  inputType: InputTypes.Select,
  withSearch: true,
}

export const CATEGORY: InputField = {
  key: 'category',
  label: terms.Inputs.category,
  hideLabel: true,
  placeholder: terms.Inputs.categoryPlacehoalder,
  required: false,
  inputType: InputTypes.Select,
  withSearch: true,
}
