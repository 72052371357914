import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import AddLabel from './AddLabel'
import ValidateLabelEditingButton from './ValidateLabelEditingButton'
import CancelLabelEditingButton from './CancelLabelEditingButton'
import ImportPortfolio from './Import/ImportPortfolio'

export default function PortfolioActionsButtons(): ReactElement {
  const { enterEditingMode } = useSelector((state: RootState) => state.label)
  const { ObjectPositionMode } = useSelector((state: RootState) => state.object)
  return (
    <div className="btn-container">
      {(!enterEditingMode && !ObjectPositionMode) && (
        <>
          <AddLabel />
          <ImportPortfolio />
        </>
      )}
      {(enterEditingMode && !ObjectPositionMode) && (
      <>
        <ValidateLabelEditingButton />
        <CancelLabelEditingButton />
      </>
      )}
    </div>
  )
}
