import { ReactElement } from 'react'
import { IconButton, Typography } from '@mui/material'
import terms from 'common/terms'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useDispatch } from 'react-redux'
import { setSearchValue, toggleDisplayLibraryPanel } from 'reducers/MultiYearPlan/Library/library'

export default function LibraryPanelHeader(): ReactElement {
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(toggleDisplayLibraryPanel(false))
    dispatch(setSearchValue(''))
  }
  return (
    <div className="library-panel-header">
      <IconButton onClick={handleClick}>
        <ArrowBackIcon />
      </IconButton>
      <Typography>
        {terms.MultiYearPlan.SettingsPanel.Library.libraryPanelHeader}
      </Typography>
    </div>
  )
}
