import {
  ReactElement, useEffect, useCallback, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadingButton from '@mui/lab/LoadingButton'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import { toggleShowGeoeditor } from 'reducers/object'
import Tooltip from '@mui/material/Tooltip'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import SaveIcon from '@mui/icons-material/Save'
import CircularProgress from '@mui/material/CircularProgress'
import { RootState, store } from 'Store'
import {
  disableGeoEditor, GeoEditorState, setGeoJson, updateHistory, updateMode,
} from 'components/GeoEditor/reducer'

import close from 'assets/icons/close.svg'
import undo from 'assets/icons/undo.svg'
import refresh from 'assets/icons/refresh.svg'
import verticalFlipIcon from 'assets/icons/vertical_flip.svg'

import TOOLS from './tools'
import './GeoEditorTools.scss'
import { Modes, updateCursor } from '../utils'
import GeoEditorService from '../GeoEditorService'
import { Viewport } from '../snapping/updateSnappingFeatures'
import { updateGrid } from '../GridLayer'
import verticalFlip from '../EditionLayer/verticalFlip'

const changeMode = (newMode: Modes) => {
  store.dispatch(updateMode(newMode))
  updateCursor(newMode)
}

type Props = {
  viewport: Viewport | undefined
}

export default function GeoEditorTools({ viewport }: Props): ReactElement {
  const dispatch = useDispatch()
  const {
    mode, config, loading, history,
  } = useSelector((state: RootState): GeoEditorState => state.geoEditor)
  const [open, setOpen] = useState(false)
  const onClose = () => {
    dispatch(disableGeoEditor())
    dispatch(toggleShowGeoeditor(false))
  }

  const onClick = () => {
    if (config) {
      dispatch(GeoEditorService.saveGeometry(config?.callback))
    }
  }

  const onUndoAction = () => {
    if (history.length >= 0) {
      const lastItem = history[history.length - 1]
      dispatch(setGeoJson(lastItem))
      dispatch(updateHistory(undefined))
    }
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleKeyPress = useCallback(event => {
    switch (event.key) {
      case 'w':
        return changeMode(Modes.selectRectangle)
      case 'x':
        return changeMode(Modes.draw)
      case 'c':
        return changeMode(Modes.grab)
      case 'v':
        return changeMode(Modes.rotate)
      case 'b':
        return changeMode(Modes.deletePoint)
      default:
        return null
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress)
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [handleKeyPress])

  const isSelected = (m?: Modes) => (m === mode ? 'selected' : '')

  return (
    <>
      <div className="geoeditor-tools d-flex justify-content-center align-items-center">
        <Paper className="button-group d-flex align-items-center justify-content-center">
          <Tooltip title="Annuler la dernière action" arrow>
            <IconButton
              key="undo"
              className="icon-button ml-1 undo"
              onClick={onUndoAction}
              sx={{
                opacity: history.length === 0 ? 0.2 : 1,
              }}
            >
              <img src={undo} alt="undo button" />
            </IconButton>
          </Tooltip>
          <Divider orientation="vertical" className="mr-2 divider" sx={{ height: 28, m: 0.5, color: '#F2F2F2' }} />
          {TOOLS.map(tool => (
            <Tooltip key={tool.key} title={tool.label} arrow>
              <IconButton
                className={`${'icon-button mx-1 '}${isSelected(tool.mode)}`}
                onClick={() => changeMode(tool.mode)}
              >
                <img src={tool.icon} alt={tool.key} />
              </IconButton>
            </Tooltip>
          ))}
          <Divider orientation="vertical" className="mx-1 divider" sx={{ height: 28, m: 0.5, color: '#F2F2F2' }} />
          <Tooltip title="Actualiser la grille" arrow>
            <IconButton
              key="refresh-grid"
              className="icon-button mx-1"
              onClick={() => { if (viewport) updateGrid(viewport) }}
            >
              <img src={refresh} alt="refresh button" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Retourner la ligne selon l'axe vertical" arrow>
            <IconButton
              key="close"
              className="icon-button mx-1"
              onClick={verticalFlip}
            >
              <img src={verticalFlipIcon} alt="flip button" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Quitter la linéarisation" arrow>
            <IconButton
              key="close"
              className="icon-button mx-1"
              onClick={handleClickOpen}
            >
              <img src={close} alt="close button" />
            </IconButton>
          </Tooltip>
          <Divider orientation="vertical" className="mr-2 divider" sx={{ height: 28, m: 0.5, color: '#F2F2F2' }} />
          <Tooltip title="Sauvegarder la linéarisation" arrow>
            <LoadingButton
              key="close"
              loading={loading}
              className="icon-button"
              onClick={onClick}
              sx={{ color: '#FFFFFF' }}
              loadingIndicator={<CircularProgress size={16} sx={{ color: '#FFFFFF' }} />}
            >
              <SaveIcon />
            </LoadingButton>
          </Tooltip>
        </Paper>

      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Quitter la linéarisation?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Toute modification non sauvegardée sera perdue
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>Annuler</Button>
          <Button variant="contained" onClick={onClose}>
            Quitter
          </Button>
        </DialogActions>
      </Dialog>

    </>
  )
}
