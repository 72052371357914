/* eslint-disable react/no-array-index-key */
import { ReactElement, useEffect, useState } from 'react'
import { RootState } from 'Store'
import { useDispatch, useSelector } from 'react-redux'
import terms from 'common/terms'
import { OperationWorkPhaseFields } from 'reducers/MultiYearPlan/OperationActions/types'
import { toggleDeletePhaseField, updatePhaseFields } from 'reducers/MultiYearPlan/OperationActions/operationactions'
import OperationSelectField from './OperationSelectField'
import { OperationInput, PhasesInputFields } from './OperationInputFields'
import { getErrorMessage } from './utils'

export default function WorkPhaseInputs({ phaseIndex }: {phaseIndex: number}): ReactElement {
  const dispatch = useDispatch()
  const { years, phases } = useSelector((state: RootState) => state.files)
  const {
    phaseFields, responseError, emptyFields, deletePhaseField,
  } = useSelector((state: RootState) => state.mypoperationactions)
  const { libraryResponseError } = useSelector((state: RootState) => state.mypLibrary)
  const [year, setYear] = useState<string | null>('')
  const [phase, setPhase] = useState<string>('')

  useEffect(() => {
    const newPhase = {
      year: year !== null ? +year : year,
      phase: phase === '' ? null : phase,
    }
    const newPhaseFields: OperationWorkPhaseFields[] = phaseFields.map((field:
      OperationWorkPhaseFields) => (field.index !== phaseIndex
      ? field : { index: phaseIndex, phase: newPhase }))
    dispatch(updatePhaseFields(newPhaseFields))
  }, [year, phase])

  useEffect(() => {
    setYear(phaseFields[phaseIndex - 1].phase.year)
    setPhase(phaseFields[phaseIndex - 1].phase.phase)
    if (deletePhaseField) {
      dispatch(toggleDeletePhaseField(false))
    }
  }, [deletePhaseField])

  const handlePhaseChange = (v: string | null) => {
    if (v === null) {
      setPhase('')
    } else {
      setPhase(v)
    }
  }

  const getValue = (fieldLabel: string) => {
    if (fieldLabel === terms.Inputs.years) {
      if (year !== null && +year === 0) {
        return ''
      }
      return year
    }
    return phase
  }

  const getMessage = (fieldKey: string, fieldLabel: string) => {
    if (fieldLabel === terms.Inputs.years) {
      return getErrorMessage((responseError || libraryResponseError), fieldKey, emptyFields, year as string, phaseIndex)
    }
    if (phase === '' || phase === null) {
      return ''
    }
    return getErrorMessage((responseError || libraryResponseError), fieldKey, emptyFields, phase, phaseIndex)
  }
  return (
    <div className="phase-fields">
      {PhasesInputFields.map((field: OperationInput, index: number) => (
        <OperationSelectField
          key={`${field.label}-${index}`}
          value={getValue(field.label)}
          onChange={(_event, newInputValue) => (field.label === terms.Inputs.years
            ? setYear(newInputValue as string)
            : handlePhaseChange(newInputValue as string))}
          options={field.label === terms.Inputs.years ? years : phases}
          placeholder={field.placeholder as string}
          label={field.label}
          extraStyle="phase-select-input"
          errorMessage={getMessage(field.key, field.label)}
        />
      ))}
    </div>
  )
}
