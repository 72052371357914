import { LabelsLayersIds, ObjectsLayer } from 'components/Map/ObjectsLayers/types'
import ObjectService from 'components/Objects/ObjectService'
import PortfolioService from 'components/Portfolio/PortfolioService'
import { setNewLabelObjectLayer } from 'reducers/label'

const getOldLayerData = (source : string, projectId: number, dispatch: any) => {
  switch (true) {
    case source.includes(ObjectsLayer.signal):
      dispatch(ObjectService.getSignalsLayer(projectId))
      break
    case source.includes(ObjectsLayer.adv):
      dispatch(ObjectService.GetAdvLayer(projectId))
      break
    case source.includes(ObjectsLayer.rk):
      dispatch(ObjectService.GetRkLayer(projectId))
      break
    case source.includes(ObjectsLayer.bv):
      dispatch(ObjectService.GetBvLayer(projectId))
      break
    case source.includes(ObjectsLayer.te):
      dispatch(ObjectService.getSignalsLayer(projectId))
      break
    case source.includes(LabelsLayersIds.label):
      dispatch(PortfolioService.getLabelsLayer(projectId))
      dispatch(setNewLabelObjectLayer(null))
      break
    default:
      dispatch(ObjectService.getSignalsLayer(projectId))
      break
  }
}

export default getOldLayerData
