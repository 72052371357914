import terms from 'common/terms'
import UpdateModal from 'components/common/Modals/UpdateModal'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toggleDisplayDeplaceModal } from 'reducers/Files/files'
import { patchFile } from 'reducers/Files/files.thunk'
import { Folder } from 'reducers/Folders/types'
import { RootState } from 'Store'
import FolderSelect from './FolderSelect'

export default function MoveFile(): ReactElement {
  const dispatch = useDispatch()
  const { activeRepertory } = useSelector((state: RootState) => state.repertories)
  const { displayDeplaceModal, contextFile, moveToFolder } = useSelector((state: RootState) => state.files)
  const [folder, setFolder] = useState<Folder | null>(null)

  useEffect(() => {
    if (!moveToFolder) {
      setFolder(null)
    }
  }, [moveToFolder])

  const onModalClose = () => {
    dispatch(toggleDisplayDeplaceModal(false))
  }

  const onValidate = () => {
    dispatch(patchFile({
      repertoryId: activeRepertory.id,
      file: {
        id: contextFile.id,
        folder: folder?.id,
      },
    }))
  }

  return (
    <UpdateModal
      open={displayDeplaceModal}
      onModalClose={onModalClose}
      modalTitle={terms.File.Actions.moveFile}
      content={<FolderSelect folder={folder} handleChange={setFolder} />}
      onUpdate={onValidate}
      actionType={terms.File.Actions.move}
      isDisabled={folder === null}
    />
  )
}
