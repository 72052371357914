/* eslint-disable max-len */
import { ReactElement, useEffect } from 'react'
import terms from 'common/terms'
import ActionButton from 'components/common/Buttons/ActionButton'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import {
  patchFile, postFolderFile, postRepertoryFile,
} from 'reducers/Files/files.thunk'
import history from 'customHistory'
import PathNames from 'common/PathNames'
import { FileTypes } from 'reducers/Files/types'
import { updateCreationLevel } from 'reducers/creation'
import { CreationLevels } from 'components/Dashboard/types'
import { resetResponseError, toggleUpdateFileName } from 'reducers/Files/files'
import { updateCreationStatus } from 'reducers/MultiYearPlan/multiYearPlan'
import { getPath } from '../../../../utils'

export default function FileNameDeclarationButton(): ReactElement {
  const dispatch = useDispatch()
  const {
    fileName, activeFile, fileType, createdFile, isLoading,
  } = useSelector((state: RootState) => state.files)
  const { activeRepertory } = useSelector((state: RootState) => state.repertories)
  const { activeFolder } = useSelector((state: RootState) => state.folders)

  useEffect(() => {
    if (createdFile) {
      if (fileType === FileTypes.Schema) {
        history.push(`${getPath(activeRepertory, activeFolder, createdFile)}/${PathNames.FileSchema}/${PathNames.TemporalPerimeter}`)
        dispatch(updateCreationStatus(CreationLevels.Name))
      } else {
        history.push(`${getPath(activeRepertory, activeFolder, createdFile)}/${PathNames.FileSynoptic}/${PathNames.TemporalPerimeter}`)
        dispatch(updateCreationLevel(CreationLevels.Name))
      }
    }
  }, [createdFile])

  const handleClick = () => {
    if (activeFile) {
      if (activeFile.name !== fileName) {
        dispatch(patchFile({
          repertoryId: activeFile.directory,
          folderId: activeFile.folder,
          file: {
            id: activeFile.id,
            name: fileName,
          },
        }))
        dispatch(toggleUpdateFileName(true))
      } else {
        history.push(`${window.location.pathname}/${PathNames.TemporalPerimeter}`)
        if (activeFile.type === FileTypes.Synoptic) {
          dispatch(updateCreationLevel(CreationLevels.Name))
        } else {
          dispatch(updateCreationStatus(CreationLevels.Name))
        }
        dispatch(resetResponseError())
      }
    } else if (activeFolder) {
      dispatch(postFolderFile({
        repertoryId: activeRepertory.id, folderId: activeFolder.id, fileName, fileType,
      }))
    } else {
      dispatch(postRepertoryFile({ repertoryId: activeRepertory.id, fileName, fileType }))
    }
  }

  return (
    <div className="d-flex justify-content-center">
      <ActionButton
        text={isLoading ? terms.Common.loading : terms.Common.continue}
        onClick={handleClick}
        rightIcon
        buttonStyle="action-button"
        disabled={fileName === '' || isLoading}
      />
    </div>
  )
}
