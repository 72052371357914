import { ReactElement } from 'react'
import { Source, Layer } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'

export default function AvLayerHover(): ReactElement {
  const { signalLayerData } = useSelector((state: RootState) => state.object)
  const { hoveredFeatureInfo } = useSelector((state: RootState) => state.map)

  const featureInfo = hoveredFeatureInfo
  const objectId = featureInfo ? featureInfo?.properties.object_id : 0
  return (
    <>
      <Source type="geojson" data={signalLayerData} id="avertissementHover">
        <Layer
          id="avertissementHoverDesc"
          filter={['all',
            ['==', 'object_id', objectId],
            ['==', 'type', 'AVERTISSEMENT'],
          ]}
          type="symbol"
          minzoom={12}
          paint={{
            'text-color': '#000000',
            'text-halo-width': 0.6,
            'text-halo-color': '#000000',
          }}
          layout={{
            'text-rotation-alignment': 'map',
            'text-allow-overlap': true,
            'text-field': ['get', 'description'],
            'text-offset': [
              'case',
              [
                'all',
                ['==', ['get', 'type'], 'AVERTISSEMENT'],
                ['==', ['get', 'direction'], 'D'],
              ],
              ['literal', [2, -5.2]],
              [
                'all',
                ['==', ['get', 'type'], 'AVERTISSEMENT'],
                ['==', ['get', 'direction'], 'C'],
              ],
              ['literal', [-2, -5.2]],
              ['literal', [2.8, -3.2]],
            ],
            'text-rotate': ['get', 'angle'],
            'text-size': ['interpolate', ['linear'], ['zoom'], 10, 5, 15, 12],
            'text-font': ['Roboto Condensed'],
          }}
        />
        <Layer
          id="avertissementHoverKm"
          filter={['all',
            ['==', 'object_id', objectId],
            ['==', 'type', 'AVERTISSEMENT'],
          ]}
          type="symbol"
          minzoom={12}
          paint={{
            'text-color': '#41A8F9',
            'text-halo-width': 0.6,
            'text-halo-color': '#41A8F9',
          }}
          layout={{
            'text-rotation-alignment': 'map',
            'text-allow-overlap': true,
            'text-field': ['get', 'pk_sncf'],
            'text-offset': [
              'case',
              [
                'all',
                ['==', ['get', 'type'], 'AVERTISSEMENT'],
                ['==', ['get', 'direction'], 'D'],
              ],
              ['literal', [2, -4.2]],
              [
                'all',
                ['==', ['get', 'type'], 'AVERTISSEMENT'],
                ['==', ['get', 'direction'], 'C'],
              ],
              ['literal', [-2, -4.2]],
              ['literal', [2.8, -3.2]],
            ],
            'text-rotate': ['get', 'angle'],
            'text-size': ['interpolate', ['linear'], ['zoom'], 10, 5, 15, 12],
            'text-font': ['Roboto Condensed'],
          }}
        />
        <Layer
          id="avertissementHoverMat"
          filter={['all',
            ['==', 'object_id', objectId],
            ['==', 'type', 'AVERTISSEMENT'],
          ]}
          type="symbol"
          minzoom={12}
          paint={{}}
          layout={{
            'text-field': '',
            'text-font': ['saxmono'],
            'text-size': 9,
            'icon-image': [
              'case',
              ['==', ['get', 'direction'], 'C'],
              'MATG',
              ['==', ['get', 'direction'], 'D'],
              'MATD',
              '',
            ],
            'icon-size': [
              'interpolate',
              ['linear'],
              ['zoom'],
              10,
              0.1,
              15,
              0.7,
            ],
            'icon-rotation-alignment': 'map',
            'icon-pitch-alignment': 'map',
            'icon-rotate': ['get', 'angle'],
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
            'text-allow-overlap': true,
            'text-ignore-placement': true,
          }}
        />
        <Layer
          id="avertissementHoverIcon"
          filter={['all',
            ['==', 'object_id', objectId],
            ['==', 'type', 'AVERTISSEMENT'],
          ]}
          type="symbol"
          minzoom={12}
          paint={{}}
          layout={{
            'icon-rotation-alignment': 'map',
            'icon-pitch-alignment': 'map',
            'icon-rotate': ['get', 'angle'],
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
            'icon-offset': [
              'case',
              ['==', ['get', 'direction'], 'D'],
              ['literal', [35, -90]],
              ['==', ['get', 'direction'], 'C'],
              ['literal', [-35, -90]],
              ['literal', [0, 0]],
            ],
            'icon-image': [
              'case',
              ['in', ['string', ['get', 'type']], ['literal', 'AVERTISSEMENT']],
              ['concat', 'SCH ', 'A'],
              '',
            ],
            'icon-size': [
              'interpolate',
              ['linear'],
              ['zoom'],
              10,
              0.1,
              15,
              0.6,
            ],
          }}
        />
      </Source>
    </>
  )
}
