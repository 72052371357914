import terms from 'common/terms'
import { Section } from 'components/Dashboard/types'
import { ReactElement } from 'react'
import { Table } from 'react-bootstrap'
import { Files, FileTypes } from 'reducers/Files/types'
import { MYPSection } from 'reducers/MultiYearPlan/types'
import SchemaTableBody from './SchemaTableBody'
import SynopticTableBody from './SynopticTableBody'

type Props = {
    sectionsList: Section[] | MYPSection[]
    fileType: string
    onSectionUpdate: (v: Section | MYPSection) => void
    onSectionDelete: (v: number) => void
    validationNotice: boolean
    activeFile: Files
}
export default function SectionTable({
  sectionsList, fileType, onSectionUpdate, onSectionDelete, validationNotice, activeFile,
}: Props): ReactElement {
  return (
    sectionsList.length !== 0 ? (
      <div className="table-wrapper">
        <Table hover borderless className="table-synoptic">
          <thead>
            <tr>
              {fileType === FileTypes.Schema && <th>{terms.Inputs.order}</th>}
              <th>{terms.Inputs.sectionName}</th>
              <th>{terms.Inputs.lineNumber}</th>
              <th>{terms.Inputs.pkDebut}</th>
              <th>{terms.Inputs.pkFin}</th>
              {fileType === FileTypes.Schema && <th>{terms.Inputs.step}</th>}
              {fileType === FileTypes.Schema && <th>{terms.Inputs.direction}</th>}
            </tr>
          </thead>
          {fileType === FileTypes.Synoptic ? (
            <SynopticTableBody
              sectionsList={sectionsList as Section[]}
              onSectionUpdate={onSectionUpdate}
              onSectionDelete={onSectionDelete}
              validationNotice={validationNotice}
            />
          )
            : (
              <SchemaTableBody
                sectionsList={sectionsList as MYPSection[]}
                onSectionUpdate={onSectionUpdate}
                onSectionDelete={onSectionDelete}
                validationNotice={validationNotice}
                activeFile={activeFile}
              />
            )}
        </Table>
      </div>
    )
      : <></>
  )
}
