import PathNames from 'common/PathNames'
import terms from 'common/terms'
import history from 'customHistory'
import { Files } from 'reducers/Files/types'
import {
  setActiveStep, updateEndYearAttribut, updateFerrolocalizationAttributs, updateSelectedAttributValue,
  updateStartYearAttribut, updateResponseError,
} from 'reducers/MultiYearPlan/Import/import'
import { store } from 'Store'
import { ResponseError } from 'types'
import { toggleDisplaySettingsPanel } from 'reducers/MultiYearPlan/Settings/settings'
import FormatChoiceSwitch from './Content/FormatChoiceSwitch'
import { MypImportStepsList } from './types'

export const getRollBackText = (activeStep: number) => (activeStep === 0 ? terms.MultiYearPlan.Import.backToMyp
  : terms.MultiYearPlan.Import.backToPreviousStep)

export const onRollBack = (activeStep: number, activeFile: Files) => {
  if (activeStep === 0) {
    if (activeFile?.folder !== null) {
      history.push(`${PathNames.RepertoryContent}/${activeFile.directory}/${PathNames.Folder}/${activeFile.folder}`)
    } else {
      history.push(`${PathNames.RepertoryContent}/${activeFile.directory}`)
    }
  } else {
    store.dispatch(setActiveStep(activeStep - 1))
    store.dispatch(updateSelectedAttributValue(null))
    store.dispatch(updateStartYearAttribut(null))
    store.dispatch(updateEndYearAttribut(null))
    store.dispatch(updateFerrolocalizationAttributs(undefined))
    store.dispatch(updateResponseError(undefined))
  }
}

export const onClose = (activeFile: Files, displaySettingsPanel: boolean) => {
  store.dispatch(setActiveStep(0))
  if (displaySettingsPanel) store.dispatch(toggleDisplaySettingsPanel(false))
  if (activeFile?.folder !== null) {
    history.push(`${PathNames.RepertoryContent}/${activeFile.directory}/${PathNames.Folder}/${activeFile.folder}`)
  } else {
    history.push(`${PathNames.RepertoryContent}/${activeFile.directory}`)
  }
}

export const getImportContainerTitle = (activeStep: number) => {
  switch (activeStep) {
    case MypImportStepsList.CriteriaChoice:
      return terms.MultiYearPlan.Import.criteriaChoiceTitle
    case MypImportStepsList.LabelChoice:
      return terms.MultiYearPlan.Import.labelChoiceTitle
    case MypImportStepsList.PortfolioFormat:
      return terms.MultiYearPlan.Import.formatChoiceTitle
    case MypImportStepsList.Ferrolocalization:
      return terms.MultiYearPlan.Import.ferrolocalizationTitle
    case MypImportStepsList.OperationChoice:
      return terms.MultiYearPlan.Import.operationChoiceTitle
    case MypImportStepsList.Parametrage:
      return terms.MultiYearPlan.Import.objectsFamiliesTitle
    default:
      return ''
  }
}

export const getImportContainerNotice = (activeStep: number) => {
  switch (activeStep) {
    case MypImportStepsList.CriteriaChoice:
      return terms.MultiYearPlan.Import.criteriaChoiceNotice
    case MypImportStepsList.PortfolioFormat:
      return <FormatChoiceSwitch />
    case MypImportStepsList.Parametrage:
      return terms.MultiYearPlan.Import.objectsFamiliesNotice
    default:
      return ''
  }
}

export const getErrorMessage = (error: ResponseError) => (error?.message?.label || terms.Common.Errors.error)
