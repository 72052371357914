/* eslint-disable react/no-array-index-key */
import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { Typography } from '@mui/material'
import terms from 'common/terms'
import { updateFerrolocFields } from 'reducers/MultiYearPlan/TitleBlockActions/titleblockactions'
import FerrolocalizationInputs from './FerrolocalizationInputs'

export default function FerrolocalizationFieldsContainer(): ReactElement {
  const dispatch = useDispatch()
  const {
    selectedTbElement,
  } = useSelector((state: RootState) => state.mypTitleBlockActions)

  useEffect(() => {
    if (!selectedTbElement) {
      dispatch(updateFerrolocFields({
        start_kp: '',
        end_kp: '',
        line_code: '',
      }))
    }
  }, [selectedTbElement])

  return (
    <div className="ferroloc-phase-container">
      <div className="ferroloc-phase-header">
        <Typography>
          {terms.MultiYearPlan.Operations.ferroloc}
        </Typography>
      </div>
      <FerrolocalizationInputs />
    </div>
  )
}
