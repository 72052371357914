/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */
import {
  deleteRequest, get, patch, post,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkApi } from 'types'
import { GetParams, PatchParams, Repertory } from './types'

const getRepertoriesList = createAsyncThunk<Repertory[], GetParams, ThunkApi>(
  'repertories/get',
  async (params: GetParams, thunkApi) => {
    try {
      const response: Repertory[] = await get('/module_schematique/directories/', params)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const postRepertory = createAsyncThunk<Repertory, string, ThunkApi>(
  'repertories/post',
  async (label: string, thunkApi) => {
    try {
      const response: Repertory = await post('/module_schematique/directories/', { label })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const patchRepertory = createAsyncThunk<Repertory, PatchParams, ThunkApi>(
  'repertories/patch',
  async (params: PatchParams, thunkApi) => {
    try {
      const response: Repertory = await patch(`/module_schematique/directories/${params.repertoryId}`,
        params.repertory.picture ? params.repertory.picture : params.repertory)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const deleteRepertory = createAsyncThunk<number, number, ThunkApi>(
  'repertories/delete',
  async (repertoryId: number, thunkApi) => {
    try {
      await deleteRequest(`/module_schematique/directories/${repertoryId}`)
      return repertoryId
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

export {
  getRepertoriesList,
  postRepertory,
  patchRepertory,
  deleteRepertory,
}
