import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkApi } from 'types'
import {
  get, post, deleteRequest, patch,
} from '@osrdata/app_core/dist/requests'
import {
  GetParams, DeleteParams, PatchOperationParams, Operation, GetOperationParams,
  PatchOperationPositionParams, OperationLabel, PostOperationParams, CreatedOperation,
} from './types'
import { SubClassification } from '../Import/ObjectsFamilies/type'

const generateRepresentation = createAsyncThunk<null, GetParams, ThunkApi>(
  'mypoperationactions/generateRepresentation',
  async (params: GetParams, thunkApi) => {
    if (params.folderId && params.folderId !== null) {
      try {
        await
        // eslint-disable-next-line max-len
        post(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.fileId}/generate-representation/`, {})
        return null
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response = await
        // eslint-disable-next-line max-len
        post(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/generate-representation/`, {})
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

const deleteOperation = createAsyncThunk<null, DeleteParams, ThunkApi>(
  'mypoperationactions/deleteOperation',
  async (params: DeleteParams, thunkApi) => {
    if (params.folderId && params.folderId !== null) {
      try {
        const response = await
        // eslint-disable-next-line max-len
        deleteRequest(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.projectId}/operations/${params.operationId}`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response = await
        // eslint-disable-next-line max-len
        deleteRequest(`/module_schematique/directories/${params.repertoryId}/projects/${params.projectId}/operations/${params.operationId}`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

const patchOperationPosition = createAsyncThunk<OperationLabel, PatchOperationPositionParams, ThunkApi>(
  'mypoperationactions/patchOperationPosition',
  async (params: PatchOperationPositionParams, thunkApi) => {
    if (params.folderId && params.folderId !== null) {
      try {
        const response = await
        // eslint-disable-next-line max-len
        patch(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.projectId}/operations/${params.operationId}/labels/${params.labelId}`, params)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response = await
        // eslint-disable-next-line max-len
        patch(`/module_schematique/directories/${params.repertoryId}/projects/${params.projectId}/operations/${params.operationId}/labels/${params.labelId}`, params)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

const getSubClassificationsByClassificationId = createAsyncThunk<SubClassification[], GetParams, ThunkApi>(
  'mypoperationactions/getSubClassificationsByClassificationId',
  async (params: GetParams, thunkApi) => {
    if (params.folderId) {
      try {
        const response: SubClassification[] = await
        // eslint-disable-next-line max-len
        get(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.fileId}/classifications/${params.classificationId}/sub-classifications/`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response: SubClassification[] = await
        // eslint-disable-next-line max-len
        get(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/classifications/${params.classificationId}/sub-classifications/`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

const getLineCodeTracksList = createAsyncThunk<string[], number, ThunkApi>(
  'mypoperationactions/getLineCodeTracksList',
  async (lineCode: number, thunkApi) => {
    try {
      const response: string[] = await get(`/module_schematique/lines/${lineCode}/track-names/`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const postOperation = createAsyncThunk<CreatedOperation, PostOperationParams, ThunkApi>(
  'mypoperationactions/postOperation',
  async (params: PostOperationParams, thunkApi) => {
    if (params.folderId && params.folderId !== null) {
      try {
        const response = await
        // eslint-disable-next-line max-len
        post(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.fileId}/operations/`, params.operation)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response = await
        // eslint-disable-next-line max-len
        post(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/operations/`, params.operation)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

const patchOperation = createAsyncThunk<OperationLabel, PatchOperationParams, ThunkApi>(
  'mypoperationactions/patchOperationLabel',
  async (params: PatchOperationParams, thunkApi) => {
    if (params.folderId && params.folderId !== null) {
      try {
        const response = await
        // eslint-disable-next-line max-len
        patch(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.projectId}/operations/${params.operationId}`, params.operation)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response = await
        // eslint-disable-next-line max-len
        patch(`/module_schematique/directories/${params.repertoryId}/projects/${params.projectId}/operations/${params.operationId}`, params.operation)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

const getOperation = createAsyncThunk<Operation, GetOperationParams, ThunkApi>(
  'mypoperationactions/getOperation',
  async (params: GetOperationParams, thunkApi) => {
    if (params.folderId && params.folderId !== null) {
      try {
        const response = await
        // eslint-disable-next-line max-len
        get(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.projectId}/operations/${params.operationId}`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response = await
        // eslint-disable-next-line max-len
        get(`/module_schematique/directories/${params.repertoryId}/projects/${params.projectId}/operations/${params.operationId}`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

export {
  generateRepresentation,
  getOperation,
  deleteOperation,
  getSubClassificationsByClassificationId,
  patchOperationPosition,
  patchOperation,
  getLineCodeTracksList,
  postOperation,
}
