import { Line, Layer, CustomLayerProps } from '@nivo/line'
import terms from 'common/terms'
import _ from 'lodash'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import {
  toggleDisplayAddModal, updateSelectedElement,
} from 'reducers/MultiYearPlan/TitleBlockActions/titleblockactions'
import { TitleBlockType, TBTypes } from 'reducers/MultiYearPlan/TitleBlock/types'
import '../multiYearPlan.scss'

function LineChartTbCategories() {
  const dispatch = useDispatch()
  const { TitleBlock } = useSelector((state: RootState) => state.mypTitleBlock)
  const { exportSettings } = useSelector((state: RootState) => state.mypExportPdf)

  const formated = _.cloneDeep(TitleBlock) as unknown as TitleBlockType
  formated.cartouche_display?.forEach((category: TBTypes, index) => {
    category.data = [
      {
        x: 0,
        y: index === 0 ? 10 : index * 10 + 10,
        label: category.type,
      },
      {
        x: 100,
        y: index === 0 ? 10 : index * 10 + 10,
      },
    ]
    category.id = index + 1
  })

  function Points({ points }: CustomLayerProps) {
    return (
      <g>
        {points.map((point: any) => (
          <g transform={`translate(${point.x},${point.y + 22})`} key={point.id}>
            <text
              dominantBaseline="middle"
              style={{
                fontSize: 16,
                fontFamily: 'sans-serif',
                translate: '5px -22px',
                textAnchor: 'initial',
              }}
            >
              {point.data.label}
            </text>
          </g>
        ))}
      </g>
    )
  }

  const handleClick = () => {
    dispatch(updateSelectedElement(undefined))
    dispatch(toggleDisplayAddModal(true))
  }

  return (
    <>
      <div
        className="line-chart-container-tbc mt-5"
        style={exportSettings.includeTileblock ? { display: 'block' } : { display: 'none' }}
        id="line-chart-container-tbc"
      >
        <div className="pl-2 year-bar">
          <span className="mr-2">{terms.MultiYearPlan.TileBlock.sectionTitle}</span>
          <IconButton
            onClick={handleClick}
            aria-label="add"
            size="small"
            sx={{ color: 'black', backgroundColor: 'white', borderRadius: 0 }}
          >
            <AddIcon fontSize="small" />
          </IconButton>

        </div>
        <Line
          width={250}
          height={formated?.cartouche_display.length * 40}
          data={formated?.cartouche_display}
          margin={{
            top: 20, right: 0, bottom: 20, left: 0,
          }}
          xScale={{
            type: 'linear',
          }}
          yScale={{
            type: 'point',
          }}
          axisBottom={null}
          yFormat=" >-.2f"
          colors="#f3f5f8"
          axisTop={null}
          axisRight={null}
          enableGridX={false}
          enableGridY={false}
          tooltip={() => (
            <div className="line-chart-tooltip" />
          )}
          useMesh
          lineWidth={38}
          layers={[
            'grid',
            'axes',
            'areas',
            'lines',
            'slices',
            'mesh',
            'legends',
            Points,
          ] as Layer[]}
        />
      </div>
    </>
  )
}
export default LineChartTbCategories
