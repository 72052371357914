import { ReactElement, useEffect } from 'react'
import terms from 'common/terms'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { updateFileName } from 'reducers/Files/files'
import { FileTypes } from 'reducers/Files/types'
import GenericInput from 'components/common/Inputs/GenericInput'
import { Grid } from '@mui/material'

export default function FileNameInput(): ReactElement {
  const dispatch = useDispatch()
  const { fileName, fileType, activeFile } = useSelector((state: RootState) => state.files)

  useEffect(() => {
    if (activeFile) {
      dispatch(updateFileName(activeFile.name))
    }
  }, [activeFile])

  return (
    <Grid item width={400} mt={5} mb={10} className="name-input">
      <GenericInput
        label={fileType === FileTypes.Schema ? terms.File.Declaration.Schema.multiYearSchemaName
          : terms.File.Declaration.Synoptic.synopticName}
        placeholder={fileType === FileTypes.Schema ? terms.File.Declaration.Schema.namePlaceholder
          : terms.File.Declaration.Synoptic.namePlaceholder}
        value={fileName}
        onChange={e => dispatch(updateFileName(e.target.value))}
        autoFocus
      />
    </Grid>
  )
}
