import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import { ResponseError } from 'types'
import { MypImportStepsList } from 'components/MultiYearPlan/Import/types'
import {
  generateClassifications, regenerateClassifications,
  patchFolderPortfolio, patchRepertoryPortfolio, postFolderPortfolio, postRepertoryPortfolio,
} from './import.thunk'
import {
  FerrolocalizationAttributs, MypImportState, PortfolioHeaders,
} from './types'

const initialState: MypImportState = {
  activeStep: 0,
  isLineFormat: true,
  selectedAttributValue: null,
  yearAttribut: null,
  phaseAttribut: null,
  startYearAttribut: null,
  endYearAttribut: null,
  showLoader: false,
  generatingClassifications: false,
  regeneratingClassifications: false,
  isLoading: false,

}

export const mypImportSlice = createSlice({
  name: 'mypImport',
  initialState,
  reducers: {
    resetState: () => initialState,
    setActiveStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload
    },
    updateResponseError: (state, action: PayloadAction<ResponseError | undefined>) => {
      state.responseError = action.payload
    },
    toggleIsLineFormat: (state, action: PayloadAction<boolean>) => {
      state.isLineFormat = action.payload
    },
    updateSelectedAttributValue: (state, action: PayloadAction<PortfolioHeaders | null>) => {
      state.selectedAttributValue = action.payload
    },
    updateYearAttribut: (state, action: PayloadAction<PortfolioHeaders | null>) => {
      state.yearAttribut = action.payload
    },
    updatePhaseAttribut: (state, action: PayloadAction<PortfolioHeaders | null>) => {
      state.phaseAttribut = action.payload
    },
    updateStartYearAttribut: (state, action: PayloadAction<PortfolioHeaders | null>) => {
      state.startYearAttribut = action.payload
    },
    updateEndYearAttribut: (state, action: PayloadAction<PortfolioHeaders | null>) => {
      state.endYearAttribut = action.payload
    },
    updateFerrolocalizationAttributs: (state, action: PayloadAction<FerrolocalizationAttributs | undefined>) => {
      state.ferrolocalizationAttributs = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(generateClassifications.fulfilled, state => {
      state.activeStep += 1
      state.generatingClassifications = false
    })
    builder.addCase(regenerateClassifications.fulfilled, state => {
      state.regeneratingClassifications = !state.regeneratingClassifications
      state.isLoading = false
    })
    builder.addCase(generateClassifications.pending, state => {
      state.generatingClassifications = true
    })
    builder.addCase(generateClassifications.rejected, (state, action) => {
      state.responseError = action.payload
      state.generatingClassifications = false
    })
    builder.addMatcher(isAnyOf(
      postRepertoryPortfolio.fulfilled,
      postFolderPortfolio.fulfilled,
    ), (state, action) => {
      state.activeStep += 1
      state.portfolio = action.payload
      state.showLoader = false
      state.responseError = undefined
    })
    builder.addMatcher(isAnyOf(
      postRepertoryPortfolio.pending,
      postFolderPortfolio.pending,
    ), state => {
      state.showLoader = true
    })
    builder.addMatcher(isAnyOf(
      postRepertoryPortfolio.rejected,
      postFolderPortfolio.rejected,
    ), (state, action) => {
      state.responseError = action.payload
      state.showLoader = false
    })
    builder.addMatcher(isAnyOf(
      patchRepertoryPortfolio.fulfilled,
      patchFolderPortfolio.fulfilled,
    ), (state, action) => {
      if (state.activeStep !== MypImportStepsList.OperationChoice
         && state.activeStep !== MypImportStepsList.Parametrage) {
        state.activeStep += 1
      }
      state.portfolio = action.payload
      state.selectedAttributValue = null
      state.yearAttribut = null
      state.phaseAttribut = null
      state.startYearAttribut = null
      state.endYearAttribut = null
      state.ferrolocalizationAttributs = undefined
      state.responseError = undefined
      state.isLoading = false
    })
    builder.addMatcher(isAnyOf(
      patchRepertoryPortfolio.pending,
      patchFolderPortfolio.pending,
      regenerateClassifications.pending,
    ), state => {
      state.isLoading = true
    })
    builder.addMatcher(isAnyOf(
      patchRepertoryPortfolio.rejected,
      patchFolderPortfolio.rejected,
      regenerateClassifications.rejected,
    ), (state, action) => {
      state.responseError = action.payload
      state.isLoading = false
    })
  },
})

export const {
  setActiveStep, updateResponseError, toggleIsLineFormat, updateSelectedAttributValue, updateStartYearAttribut,
  updateEndYearAttribut, updateFerrolocalizationAttributs, resetState, updateYearAttribut, updatePhaseAttribut,
} = mypImportSlice.actions

export default mypImportSlice.reducer
