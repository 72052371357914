import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import { OrderBy } from 'components/Dashboard/types'
import { shareDocument } from 'reducers/Cerbere/cerbere.thunk'
import {
  cloneFolderFile, cloneRepertoryFile, deleteFolderFile, deleteRepertoryFile,
  getFilePhases, getFileYears, getFolderFilesList, getRepertoryFilesList, getSingleFolderFile,
  getSingleRepertoryFile, patchFile, postFolderFile, postRepertoryFile, updateGeographicPerimeter,
} from './files.thunk'
import { FilesState, Files, TimeRange } from './types'

const initialState: FilesState = {
  orderBy: OrderBy.DescendingLastModification,
  search: '',
  repertoryFiles: [],
  folderFiles: [],
  displayDeleteModal: false,
  displayUpdateModal: false,
  displayShareModal: false,
  fileName: '',
  openFile: false,
  displayDeplaceModal: false,
  moveToFolder: false,
  contextFile: {},
  cloneFile: false,
  fileType: '',
  isCloning: false,
  isLoading: false,
  loadingFiles: false,
  updateFileName: false,
  searchingFiles: false,
  years: [],
  phases: [],
  displayUpdateTemporalModal: false,
  displayUpdateGeographicModal: false,
  abortFileUpdate: false,
  abortGeographicPerimeterUpdate: false,
  noPerimeterChange: false,
  validateChange: false,
  validateTemporalChange: false,
  displayUpdatePerimeterLoader: false,
  displayUpdateGeographicLoader: false,
}

export const filesSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    updateOrderBy: (state, action: PayloadAction<string>) => {
      state.orderBy = action.payload
    },
    updateSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload
      state.searchingFiles = true
    },
    toggleDisplayDeleteModal: (state, action: PayloadAction<boolean>) => {
      state.displayDeleteModal = action.payload
    },
    toggleDisplayShareModal: (state, action: PayloadAction<boolean>) => {
      state.displayShareModal = action.payload
    },
    toggleDisplayUpdateModal: (state, action: PayloadAction<boolean>) => {
      state.displayUpdateModal = action.payload
    },
    toggleDisplayDeplaceModal: (state, action: PayloadAction<boolean>) => {
      state.displayDeplaceModal = action.payload
    },
    updateFileName: (state, action: PayloadAction<string>) => {
      state.fileName = action.payload
    },
    updateContextFile: (state, action: PayloadAction<Files>) => {
      state.contextFile = action.payload
    },
    resetActiveFile: state => {
      state.activeFile = undefined
      state.fileName = ''
      state.timeRange = undefined
      state.search = ''
      state.orderBy = OrderBy.DescendingLastModification
      state.createdFile = undefined
      state.responseError = undefined
      state.searchingFiles = false
    },
    updateFileType: (state, action: PayloadAction<string>) => {
      state.fileType = action.payload
    },
    updateTimeRange: (state, action: PayloadAction<TimeRange>) => {
      state.timeRange = action.payload
    },
    updateActiveFile: (state, action: PayloadAction<Files>) => {
      state.activeFile = action.payload
    },
    toggleOpenFile: (state, action: PayloadAction<boolean>) => {
      state.openFile = action.payload
    },
    toggleMoveToFolder: state => {
      state.moveToFolder = false
    },
    toggleCloneFile: state => {
      state.cloneFile = false
    },
    toggleIsCloning: state => {
      state.isCloning = false
    },
    updateCreatedFile: (state, action: PayloadAction<Files | undefined>) => {
      state.createdFile = action.payload
    },
    resetResponseError: state => {
      state.responseError = undefined
    },
    resetDeletedFile: state => {
      state.deletedFile = undefined
    },
    toggleUpdateFileName: (state, action: PayloadAction<boolean>) => {
      state.updateFileName = action.payload
    },
    toggleDisplayUpdateTemporalModal: (state, action: PayloadAction<boolean>) => {
      state.displayUpdateTemporalModal = action.payload
    },
    toggleDisplayUpdateGeographicModal: (state, action: PayloadAction<boolean>) => {
      state.displayUpdateGeographicModal = action.payload
    },
    toggleAbortChanges: state => {
      state.abortFileUpdate = false
      state.abortGeographicPerimeterUpdate = false
      state.displayUpdateGeographicLoader = false
    },
    resetNoPerimeterChange: state => {
      state.noPerimeterChange = false
    },
    resetValidateChange: state => {
      state.validateChange = false
      state.displayUpdateGeographicLoader = false
    },
    resetValidateTemporalChange: state => {
      state.validateTemporalChange = false
      state.displayUpdatePerimeterLoader = false
    },
  },
  extraReducers: builder => {
    builder.addCase(getRepertoryFilesList.fulfilled, (state, action) => {
      state.repertoryFiles = action.payload
      state.loadingFiles = false
    })
    builder.addCase(getFolderFilesList.fulfilled, (state, action) => {
      state.folderFiles = action.payload
      state.loadingFiles = false
    })
    builder.addCase(deleteRepertoryFile.fulfilled, (state, action) => {
      state.repertoryFiles = state.repertoryFiles.filter(file => file.id !== action.payload)
      state.deletedFile = action.payload
      state.displayDeleteModal = false
      state.isLoading = false
    })
    builder.addCase(deleteFolderFile.fulfilled, (state, action) => {
      state.folderFiles = state.folderFiles.filter(file => file.id !== action.payload)
      state.deletedFile = action.payload
      state.displayDeleteModal = false
      state.isLoading = false
    })
    builder.addCase(postRepertoryFile.fulfilled, (state, action) => {
      state.activeFile = action.payload
      state.createdFile = action.payload
      state.repertoryFiles.push(action.payload)
      state.responseError = undefined
      state.isLoading = false
    })
    builder.addCase(postFolderFile.fulfilled, (state, action) => {
      state.activeFile = action.payload
      state.createdFile = action.payload
      state.folderFiles.push(action.payload)
      state.responseError = undefined
      state.isLoading = false
    })
    builder.addCase(patchFile.fulfilled, (state, action) => {
      state.isLoading = false
      state.activeFile = action.payload
      state.createdFile = action.payload
      state.responseError = undefined
      if (action.payload.folder !== null) {
        state.folderFiles = state.folderFiles.map(file => (file.id === action.payload.id
          ? action.payload : file))
      } else {
        state.repertoryFiles = state.repertoryFiles.map(file => (file.id === action.payload.id
          ? action.payload : file))
      }
      state.displayUpdateModal = false
      if (action.payload.folder !== null) {
        state.moveToFolder = true
        state.displayDeplaceModal = false
      }
      if (action.meta.arg.file.abort_change) {
        state.abortFileUpdate = true
      }
      if (action.meta.arg.file.confirm_change) {
        state.validateTemporalChange = true
      }
    })
    builder.addCase(patchFile.pending, (state, action) => {
      state.isLoading = true
      if (action.meta.arg.file.confirm_change) {
        state.displayUpdatePerimeterLoader = true
      }
      if (action.meta.arg.file.abort_change) {
        state.displayUpdateGeographicLoader = true
      }
    })
    builder.addCase(getFileYears.fulfilled, (state, action) => {
      state.years = action.payload
    })
    builder.addCase(getFilePhases.fulfilled, (state, action) => {
      state.phases = action.payload
    })
    builder.addCase(updateGeographicPerimeter.fulfilled, (state, action) => {
      if (action.meta.arg.file.abort_change) {
        state.abortGeographicPerimeterUpdate = true
      }
      if (!action.meta.arg.file.abort_change && !action.meta.arg.file.confirm_change) {
        state.noPerimeterChange = true
      }
      if (action.meta.arg.file.confirm_change) {
        state.validateChange = true
      }
      state.isLoading = false
    })
    builder.addCase(updateGeographicPerimeter.pending, (state, action) => {
      if (action.meta.arg.file.confirm_change || action.meta.arg.file.abort_change) {
        state.displayUpdateGeographicLoader = true
      } else {
        state.isLoading = true
      }
    })
    builder.addCase(updateGeographicPerimeter.rejected, (state, action) => {
      state.displayUpdateGeographicLoader = false
      state.responseError = action.payload
      state.isLoading = false
    })
    builder.addCase(shareDocument.fulfilled, state => {
      state.displayShareModal = false
    })
    builder.addMatcher(isAnyOf(
      deleteFolderFile.pending,
      deleteRepertoryFile.pending,
    ), state => {
      state.isLoading = true
    })
    builder.addMatcher(isAnyOf(
      deleteFolderFile.rejected,
      deleteRepertoryFile.rejected,
    ), state => {
      state.isLoading = false
    })
    builder.addMatcher(isAnyOf(
      cloneRepertoryFile.fulfilled,
      cloneFolderFile.fulfilled,
    ), state => {
      state.cloneFile = true
      state.isCloning = false
      state.responseError = undefined
    })
    builder.addMatcher(isAnyOf(
      cloneRepertoryFile.pending,
      cloneFolderFile.pending,
    ), state => {
      state.isCloning = true
    })
    builder.addMatcher(isAnyOf(
      getRepertoryFilesList.pending,
      getFolderFilesList.pending,
    ), state => {
      state.repertoryFiles = []
      state.folderFiles = []
      state.loadingFiles = true
    })
    builder.addMatcher(isAnyOf(
      getFolderFilesList.rejected,
      getRepertoryFilesList.rejected,
      postFolderFile.rejected,
      postRepertoryFile.rejected,
      patchFile.rejected,
      cloneRepertoryFile.rejected,
      cloneFolderFile.rejected,
      updateGeographicPerimeter.rejected,
    ), (state, action) => {
      state.isLoading = false
      state.isCloning = false
      state.responseError = action.payload
      if (typeof action.payload?.message.time_range === 'string') {
        state.displayUpdateTemporalModal = true
      }
      if (typeof action.payload?.message.geographic_perimeter === 'string') {
        state.displayUpdateGeographicModal = true
      }
    })
    builder.addMatcher(isAnyOf(
      getSingleRepertoryFile.fulfilled,
      getSingleFolderFile.fulfilled,
    ), (state, action) => {
      state.activeFile = action.payload
    })
    builder.addMatcher(isAnyOf(
      postRepertoryFile.pending,
      postFolderFile.pending,
    ), state => {
      state.isLoading = true
    })
  },
})

export const {
  updateOrderBy, updateSearch, updateFileName, resetActiveFile, toggleDisplayDeleteModal, toggleDisplayUpdateModal,
  updateTimeRange, updateActiveFile, toggleOpenFile, toggleDisplayDeplaceModal, toggleMoveToFolder,
  updateContextFile, toggleCloneFile, updateFileType, updateCreatedFile, resetResponseError, resetDeletedFile,
  toggleIsCloning, toggleUpdateFileName, toggleDisplayUpdateTemporalModal, toggleDisplayUpdateGeographicModal,
  toggleAbortChanges, resetNoPerimeterChange, resetValidateChange, resetValidateTemporalChange,
  toggleDisplayShareModal,
} = filesSlice.actions

export default filesSlice.reducer
