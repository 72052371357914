import FormInput from 'components/common/Inputs/FormInput'
import { ReactElement, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { updateCartridgeTitle } from 'reducers/export'
import { RootState } from 'Store'
import { CARTRIDGE_TITLE } from '../ExportFields'

export default function CartridgeTitle(): ReactElement {
  const dispatch = useDispatch()
  const { cartridge, cartridgeTitle } = useSelector((state: RootState) => state.export)
  const [title, setTitle] = useState<string>('')

  useEffect(() => {
    if (cartridge) {
      dispatch(updateCartridgeTitle(cartridge.title))
    }
  }, [cartridge])

  const handleChange = (value: string) => {
    setTitle(value)
    dispatch(updateCartridgeTitle(value))
  }

  return (
    <Form className="form-container cartouche-title-container" autoComplete="off">
      <FormInput
        field={CARTRIDGE_TITLE}
        onChange={e => handleChange(e.target.value)}
        value={cartridgeTitle || title}
        extraStyle="px-3"
      />
    </Form>
  )
}
