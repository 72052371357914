import { ReactElement } from 'react'
import { IconButton } from '@mui/material'
import MyLocationRoundedIcon from '@mui/icons-material/MyLocationRounded'
import { useDispatch } from 'react-redux'
import { updateRecenterMap } from 'reducers/map'

export default function RecenterButton(): ReactElement {
  const dispatch = useDispatch()
  return (
    <IconButton className="recenter-btn" onClick={() => dispatch(updateRecenterMap(true))}>
      <MyLocationRoundedIcon />
    </IconButton>
  )
}
