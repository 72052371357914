import { ReactElement } from 'react'
import terms from 'common/terms'
import { Stack, Typography, Switch } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import {
  toggleIsLineFormat, updateEndYearAttribut, updateSelectedAttributValue, updateStartYearAttribut,
} from 'reducers/MultiYearPlan/Import/import'

export default function FormatChoiceSwitch(): ReactElement {
  const dispatch = useDispatch()
  const { isLineFormat } = useSelector((state: RootState) => state.mypImport)

  const handleChange = () => {
    if (isLineFormat) {
      dispatch(updateSelectedAttributValue(null))
    } else {
      dispatch(updateStartYearAttribut(null))
      dispatch(updateEndYearAttribut(null))
    }
    dispatch(toggleIsLineFormat(!isLineFormat))
  }
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography>{terms.MultiYearPlan.Import.formatXColumn}</Typography>
      <Switch
        className="custom-format-switch"
        checked={isLineFormat}
        onChange={handleChange}
      />
      <Typography>{terms.MultiYearPlan.Import.formatLine}</Typography>
    </Stack>
  )
}
