import { Button, Menu, MenuItem } from '@mui/material'
import terms from 'common/terms'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  toggleDisplayDeleteModal, toggleDisplayInputFieldsModal,
  toggleOpenPrChartContextMenu, updateContextPosition, updateSelectedPr,
} from 'reducers/RemarkablePoints/remarkablePoints'
import { PR } from 'reducers/RemarkablePoints/types'
import { RootState } from 'Store'

export default function PrActionsMenu({ prInfo }: {prInfo: PR | undefined}): ReactElement {
  const dispatch = useDispatch()
  const { contextPosition, openPrChartContextMenu } = useSelector((state: RootState) => state.remarkablePoints)
  const handleClose = () => {
    dispatch(updateContextPosition(600))
    dispatch(toggleOpenPrChartContextMenu(false))
  }

  const onClick = (action: (v: boolean) => void) => {
    dispatch(toggleOpenPrChartContextMenu(false))
    if (prInfo) {
      dispatch(updateSelectedPr(prInfo))
    }
    dispatch(action(true))
  }
  return (
    <Menu
      open={openPrChartContextMenu}
      onClose={handleClose}
      className="file-action-menu"
      anchorReference="anchorPosition"
      anchorPosition={{ top: 270, left: contextPosition }}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
    >
      <MenuItem>
        <Button
          className="actions-menu-btn"
          onClick={() => onClick(toggleDisplayDeleteModal)}
        >
          {terms.Common.delete}
        </Button>
      </MenuItem>
      <MenuItem>
        <Button
          className="actions-menu-btn"
          onClick={() => onClick(toggleDisplayInputFieldsModal)}
        >
          {terms.Common.update}
        </Button>
      </MenuItem>
    </Menu>
  )
}
