import Alert, { AlertColor } from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit'
import { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import { ResponseError } from 'types'

type Props = {
  message: string
  error: boolean
  severity?: AlertColor
  setError: ((v: ResponseError | undefined) => void) | ActionCreatorWithoutPayload<string>
}

export default function SnackBar({
  message, error, severity, setError,
}: Props): ReactElement {
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(setError(undefined))
  }

  return (
    error ? (
      <Snackbar
        open={error}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert variant="filled" severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    ) : <></>
  )
}

SnackBar.defaultProps = {
  severity: 'warning',
}
