import { EditableGeoJsonLayer } from '@nebula.gl/layers'
import { FeatureCollection, EditAction } from '@nebula.gl/edit-modes'
import { store } from 'Store'
import { GeoEditorState } from '../reducer'
import { EditorLayerModes } from '../utils'
import onExtendLine from './onExtendLine'

const createExtendLineLayer = (): EditableGeoJsonLayer => {
  const {
    selectedPoints, mode, modeConfig, extendingFeatureCollection,
  } = store.getState().geoEditor as GeoEditorState
  return new EditableGeoJsonLayer({
    id: 'extendline',
    data: extendingFeatureCollection,
    mode: EditorLayerModes(mode, selectedPoints).extend,
    modeConfig,
    selectedFeatureIndexes: extendingFeatureCollection.features.length !== 0 ? [0] : [],
    onEdit: (editAction: EditAction<FeatureCollection>) => onExtendLine(editAction),
    visible: extendingFeatureCollection.features.length !== 0,
  })
}

export default createExtendLineLayer
