/* eslint-disable max-len */
/* eslint-disable no-self-assign */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import nextId from 'react-id-generator'
import { RootState } from 'Store'
import {
  Line, Layer, CustomLayerProps, Serie, Datum,
} from '@nivo/line'
import { toggleOpenOperationContextMenu, updateContextPosition, updateSelectedOperationPoint } from 'reducers/MultiYearPlan/OperationActions/operationactions'
import { FormatedOperationsYear } from 'reducers/MultiYearPlan/Operations/types'
import { getOperation } from 'reducers/MultiYearPlan/OperationActions/operationactions.thunk'
import DeleteOperationModal from '../OperationsActions/Modals/DeleteOperationModal'
import OperationsActionsMenu from '../OperationsActions/Buttons/OperationsActionsMenu'
import '../multiYearPlan.scss'
import MoveOperationDialog from '../OperationsActions/MoveOperationDialog'
import OperationColor from '../OperationsActions/OperationColor'
import ModifyOperationModal from '../OperationsActions/Modals/ModifyOperationModal'
import formatToSingleChart from './utils'

function LineChartSp() {
  const dispatch = useDispatch()
  const { zoomPr } = useSelector((state: RootState) => state.remarkablePoints)
  const { activeFile } = useSelector((state: RootState) => state.files)
  const { formatedOperations } = useSelector((state: RootState) => state.mypoperations)
  const { selectedOperation } = useSelector((state: RootState) => state.mypoperationactions)
  const { mypSectionsList } = useSelector((state: RootState) => state.multiYearPlan)
  const { selectedPositionedOperation } = useSelector((state: RootState) => state.mypLibrary)

  const toggleContextMenu = (point: any, event: React.MouseEvent<Element, MouseEvent>) => {
    dispatch(updateSelectedOperationPoint(point))
    dispatch(getOperation({
      repertoryId: activeFile.directory,
      folderId: activeFile.folder,
      projectId: activeFile.id,
      operationId: point.data.operationId,
    }))
    dispatch(toggleOpenOperationContextMenu(true))
    dispatch(updateContextPosition({ x: event.clientX, y: event.clientY }))
  }

  function Lines({
    series, lineGenerator, xScale, yScale,
  }: CustomLayerProps) {
    return series.map(({ id, data, color } : Serie) => (
      <>
        {data[0].data.display && (
        <>
          <path
            key={id}
            onClick={(event: React.MouseEvent<Element, MouseEvent>) => toggleContextMenu(data[0], event)}
            d={lineGenerator(
              data.map((d: Datum) => ({
                x: xScale(d.data.x),
                y: yScale(d.data.y),
              })),
            )}
            fill="none"
            stroke={color}
            className={`operation-path ${data[0].data.operationId === selectedOperation?.id || data[0].data.operationId === selectedPositionedOperation?.id
              ? ' selected-operation' : ''}`}
            strokeWidth={38}
          />
          {data[0].data.display && data[0].data.ponctual === 0 && (
            <g
              fill={color}
              onClick={(event: React.MouseEvent<Element, MouseEvent>) => toggleContextMenu(data[0], event)}
              transform={`translate(${data[0].position.x - 3},${data[0].position.y - 9})`}
            >
              <polygon
                points="0 10,10 20,20 10,10 0"
                fill={color}
                strokeWidth={2}
                stroke="white"
                className={`ponctual-operation ${data[0].data.operationId === selectedOperation?.id || data[0].data.operationId === selectedPositionedOperation?.id
                  ? ' selected-operation' : ''}`}
              />
            </g>
          )}
          <g transform={`translate(${data[0].position.x + 15},${data[0].position.y})`}>
            <text
              className={`operation-label ${data[0].data.operationId === selectedOperation?.id || data[0].data.operationId === selectedPositionedOperation?.id
                || data[0].data.operationId === selectedOperation ? ' operation-label-shadow' : ''}`}
              dominantBaseline="middle"
              style={{
                color: 'black',
                fontSize: 16,
                translate: `${data[0].data.x_label_offset}px ${
                  data[0].data.y_label_offset
                }px`,
              }}
            >
              {data[0].data.label}
            </text>
          </g>

        </>
        )}
      </>
    ))
  }

  return (
    <>
      <div className="line-chart-container-sp" id="line-chart-container-sp">
        <MoveOperationDialog />
        <OperationsActionsMenu />
        <OperationColor />
        <DeleteOperationModal />
        <ModifyOperationModal />
        {formatToSingleChart(formatedOperations).years.map((year: FormatedOperationsYear) => (
          <React.Fragment key={nextId()}>
            <div className="pl-2 year-bar no-color" style={{ width: zoomPr - 155 }}><span>{year.year}</span></div>
            <div className="container-sp-operations">
              <Line
                key={nextId()}
                width={zoomPr}
                height={formatedOperations.years[0].classifications.length * 40}
                data={year.classifications}
                margin={{
                  top: 0,
                  right: 150,
                  bottom: 0,
                  left: 20,
                }}
                xScale={{
                  type: 'linear',
                  min: 0,
                  max: mypSectionsList[mypSectionsList.length - 1]?.localizations[1]?.x,
                }}
                yScale={{
                  type: 'linear',
                  min: 0,
                  max: formatedOperations.years[0].classifications.length * 40,
                }}
                axisBottom={null}
                axisTop={null}
                axisRight={null}
                axisLeft={null}
                yFormat=" >-.2f"
                enableGridX={false}
                enableGridY={false}
                colors={{ datum: 'color' }}
                enablePoints
                useMesh
                enablePointLabel
                pointLabel="label"
                tooltip={() => <div className="line-chart-tooltip" />}
                lineWidth={38}
                layers={
            [
              'grid',
              'axes',
              'areas',
              'slices',
              'mesh',
              'legends',
              Lines,
            ] as Layer[]
          }
              />
            </div>
          </React.Fragment>
        ))}
      </div>
    </>

  )
}
export default LineChartSp
