import { ReactElement } from 'react'
import ActionButton from 'components/common/Buttons/ActionButton'

import './label.scss'
import { useDispatch } from 'react-redux'
import { toggleShowPanel } from 'reducers/label'
import { toggleDisplayLibrary } from 'reducers/library'
import terms from 'common/terms'

export default function AddLabel(): ReactElement {
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(toggleShowPanel(true))
    dispatch(toggleDisplayLibrary(false))
  }

  return (
    <ActionButton
      text={terms.Portfolio.addLabel}
      onClick={handleClick}
      buttonStyle="add-label-btn"
    />
  )
}
