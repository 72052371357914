import { ChangeEvent, ReactElement, useEffect } from 'react'
import terms from 'common/terms'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { updateTimeRange } from 'reducers/Files/files'
import GenericInput from 'components/common/Inputs/GenericInput'
import { Grid } from '@mui/material'
import { getErrorMessage } from '../utils'

export default function TemporalPerimeterInputs(): ReactElement {
  const dispatch = useDispatch()
  const { timeRange, activeFile, responseError } = useSelector((state: RootState) => state.files)

  useEffect(() => {
    if (activeFile && activeFile.time_range.start_year !== null) {
      dispatch(updateTimeRange(activeFile.time_range))
    }
  }, [activeFile])

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const inputplaceholder = e.target.getAttribute('placeholder')
    if (!Number.isNaN(+e.target.value) && e.target.value.length <= 4) {
      if (inputplaceholder === terms.Inputs.startYearPlaceholder) {
        dispatch(updateTimeRange({
          start_year: e.target.value !== '' ? +e.target.value : null,
          end_year: timeRange ? timeRange.end_year : null,
        }))
      } else {
        dispatch(updateTimeRange({
          start_year: timeRange ? timeRange.start_year : null,
          end_year: e.target.value !== '' ? +e.target.value : null,
        }))
      }
    }
  }

  return (
    <Grid item width={400} mt={5} mb={10}>
      <GenericInput
        label={terms.Inputs.startYearLabel}
        placeholder={terms.Inputs.startYearPlaceholder}
        value={timeRange?.start_year || ''}
        onChange={e => handleChange(e as ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)}
        inputProps={{ inputMode: 'numeric', pattern: '/d*', maxlenth: 4 }}
        errorMessage={getErrorMessage(responseError, 'start_year')}
        autoFocus
      />
      <GenericInput
        label={terms.Inputs.endYearLabel}
        placeholder={terms.Inputs.endYearPlaceholder}
        value={timeRange?.end_year || ''}
        onChange={e => handleChange(e as ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)}
        inputProps={{ inputMode: 'numeric', pattern: '/d*', maxlenth: 4 }}
        errorMessage={getErrorMessage(responseError, 'end_year')}
      />
    </Grid>
  )
}
