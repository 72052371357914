import { InputBase, Paper, debounce } from '@mui/material'
import terms from 'common/terms'
import { ReactElement, useCallback } from 'react'
import { FaSearch } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { setSearchValue } from 'reducers/MultiYearPlan/Library/library'

export default function LibrarySearch(): ReactElement {
  const dispatch = useDispatch()
  const debouncedSearch = useCallback(debounce(value => dispatch(setSearchValue(value)), 250), [])
  return (
    <Paper
      component="form"
      className="library-panel-search"
    >
      <FaSearch size="15px" />
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={terms.Common.search}
        onChange={e => debouncedSearch(e.target.value)}
        onKeyPress={event => {
          if (event.key === 'Enter') { event.preventDefault() }
        }}
      />
    </Paper>
  )
}
