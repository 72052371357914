import { persistCombineReducers } from 'redux-persist'
import { createFilter } from 'redux-persist-transform-filter'
import storage from 'redux-persist/lib/storage' // defaults to localStorage

import { auth } from '@osrdata/app_core'
import appReducer from './app'
import dashboardReducer from './dashboard'
import creationReducer from './creation'
import synopticReducer from './synoptic'
import exportReducer from './export'
import labelReducer from './label'
import mapReducer from './map'
import objectReducer from './object'
import geoEditorReducer from '../components/GeoEditor/reducer'
import importReducer from './import'
import libraryReducer from './library'
import repertoriesReducer from './Repertories/repertories'
import foldersReducer from './Folders/folder'
import filesReducer from './Files/files'
import mpyReducer from './MultiYearPlan/multiYearPlan'
import mpyImportReducer from './MultiYearPlan/Import/import'
import prReducer from './RemarkablePoints/remarkablePoints'
import objectsFamiliesReducer from './MultiYearPlan/Import/ObjectsFamilies/objectsFamilies'
import mypSettingsReducer from './MultiYearPlan/Settings/settings'
import legendReducer from './MultiYearPlan/Legend/legend'
import mypTitleBlockReducer from './MultiYearPlan/TitleBlock/titleblock'
import mypTitleBlockActionsReducer from './MultiYearPlan/TitleBlockActions/titleblockactions'
import mypCommentsReducer from './MultiYearPlan/Comments/comments'
import mypOperationActionsReducer from './MultiYearPlan/OperationActions/operationactions'
import operationsReducer from './MultiYearPlan/Operations/operations'
import mypLibraryReducer from './MultiYearPlan/Library/library'
import mypExportPdfReducer from './MultiYearPlan/ExportPdf/exportpdf'
import cerbereReducer from './Cerbere/cerbere'

const userReducer = auth.reducer

const userWhiteList = [
  'account',
]

const saveUserFilter = createFilter(
  'user',
  userWhiteList,
)

const persistConfig = {
  key: 'root',
  storage,
  transforms: [saveUserFilter],
  whitelist: ['user'],
}

const rootReducer = {
  user: userReducer,
  app: appReducer,
  dashboard: dashboardReducer,
  creation: creationReducer,
  synoptic: synopticReducer,
  export: exportReducer,
  label: labelReducer,
  map: mapReducer,
  object: objectReducer,
  geoEditor: geoEditorReducer,
  import: importReducer,
  library: libraryReducer,
  repertories: repertoriesReducer,
  folders: foldersReducer,
  files: filesReducer,
  multiYearPlan: mpyReducer,
  mypImport: mpyImportReducer,
  remarkablePoints: prReducer,
  objectsFamilies: objectsFamiliesReducer,
  mypSettings: mypSettingsReducer,
  mypoperations: operationsReducer,
  mypTitleBlock: mypTitleBlockReducer,
  mypTitleBlockActions: mypTitleBlockActionsReducer,
  myplegend: legendReducer,
  mypcomments: mypCommentsReducer,
  mypoperationactions: mypOperationActionsReducer,
  mypLibrary: mypLibraryReducer,
  mypExportPdf: mypExportPdfReducer,
  cerbere: cerbereReducer,
}

const persistedRootReducer = persistCombineReducers(persistConfig, rootReducer)

export default persistedRootReducer
