/* eslint-disable import/prefer-default-export */

import terms from 'common/terms'
import { OrderBy } from 'components/Dashboard/types'
import {
  resetContexts, setActionsContext, setCreactionContext, setEditableFolder, setEditionMode, setXyPosition,
} from 'reducers/Folders/folder'
import { Folder } from 'reducers/Folders/types'
import { store } from 'Store'
import { ResponseError } from 'types'

export const showMenu = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, isCreation: boolean, folder?: Folder) => {
  event.preventDefault()
  event.stopPropagation()
  store.dispatch(resetContexts())
  if (folder) {
    store.dispatch(setEditableFolder(folder))
    store.dispatch(setEditionMode(false))
  }
  const positionChange = {
    x: event.pageX,
    y: event.pageY,
  }
  store.dispatch(setXyPosition(positionChange))
  if (isCreation) {
    store.dispatch(setCreactionContext(true))
  } else {
    store.dispatch(setActionsContext(true))
  }
}

export const getOrderingValue = (orderBy: string) => {
  if (orderBy === OrderBy.AscendingAlphabetical) return OrderBy.AscendingFileAlphabetical
  if (orderBy === OrderBy.DescendingAlphabetical) return OrderBy.DescendingFileAlphabetical
  return orderBy
}
export const getAuthConfig = () => ({
  headers: {
    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
  },
})

export const getErrorMessage = (
  error: ResponseError, cloneLoading: boolean, cloneDone: boolean, cloneFailed: boolean, shareDone: boolean,
) => {
  if (error && error.message.name) return error.message.name
  if (error && error.message.label) return error.message.label
  if (cloneLoading && !cloneDone) return terms.File.Actions.cloneLoading
  if (cloneFailed) return terms.File.Actions.cloneFail
  if (cloneDone) return terms.File.Actions.cloneDone
  if (shareDone) return terms.File.Actions.shareDone
  return terms.Common.Errors.error
}
