import { ReactElement } from 'react'
import {
  Button, Card, CardActions, CardHeader,
} from '@mui/material'
import terms from 'common/terms'
import { DefectedOperation } from 'reducers/MultiYearPlan/Library/types'

import './libraryPanel.scss'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedDefectedOperationForDelete } from 'reducers/MultiYearPlan/Library/library'
import { toggleDisplayDeleteModal } from 'reducers/MultiYearPlan/OperationActions/operationactions'
import { getDefectedOperation } from 'reducers/MultiYearPlan/Library/library.thunk'
import { RootState } from 'Store'

export default function DefectedOperationCard({ operation }: {operation: DefectedOperation}): ReactElement {
  const dispatch = useDispatch()
  const { activeFile } = useSelector((state: RootState) => state.files)

  const onDelete = () => {
    dispatch(setSelectedDefectedOperationForDelete(operation))
    dispatch(toggleDisplayDeleteModal(true))
  }

  const onAnomalyFix = () => {
    dispatch(getDefectedOperation({
      repertoryId: activeFile.directory,
      folderId: activeFile.folder,
      fileId: activeFile.id,
      operationId: operation.id,
    }))
  }
  return (
    <Card className="operation-card">
      <CardHeader
        title={operation.label}
      />
      <CardActions>
        <Button variant="contained" className="fix-anomaly-btn" onClick={onAnomalyFix}>
          {terms.MultiYearPlan.SettingsPanel.Library.fixAnomaly}
        </Button>
        <Button variant="outlined" className="delete-operation-btn" onClick={onDelete}>
          {terms.MultiYearPlan.SettingsPanel.Library.deleteObject}
        </Button>
      </CardActions>
    </Card>
  )
}
