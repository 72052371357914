import terms from 'common/terms'

const IMPORT_STEPS = [
  {
    label: terms.Portfolio.Import.instructionsSteps.importPortfolio,
    description: terms.Portfolio.Import.instructionsSteps.importPortfolioDescription,
  },
  {
    label: terms.Portfolio.Import.instructionsSteps.attributsChoice,
    description: terms.Portfolio.Import.instructionsSteps.attributsChoiceDescription,
  },
  {
    label: terms.Portfolio.Import.instructionsSteps.confirmChoice,
    description: terms.Portfolio.Import.instructionsSteps.confirmChoiceDescription,
  },
  {
    label: terms.Portfolio.Import.instructionsSteps.finalStep,
    description: terms.Portfolio.Import.instructionsSteps.finalStepDescription,
  },
]

export default IMPORT_STEPS
