import { ReactElement } from 'react'
import terms from 'common/terms'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import DeleteModal from 'components/common/Modals/DeleteModal'
import { deleteTbElement } from 'reducers/MultiYearPlan/TitleBlockActions/titleblockactions.thunk'
import { toggleDisplayDeleteModal } from 'reducers/MultiYearPlan/TitleBlockActions/titleblockactions'

export default function DeleteTbElementModal():
 ReactElement {
  const dispatch = useDispatch()
  const {
    displayDeleteModal, selectedTbElement, isLoading,
  } = useSelector((state: RootState) => state.mypTitleBlockActions)

  const { activeFile } = useSelector((state: RootState) => state.files)

  const onModalClose = () => {
    dispatch(toggleDisplayDeleteModal(false))
  }

  const onDelete = () => {
    dispatch(deleteTbElement({
      repertoryId: activeFile.directory,
      folderId: activeFile.folder,
      projectId: activeFile.id,
      tbElementId: selectedTbElement.id,
    }))
  }

  return (
    <DeleteModal
      open={displayDeleteModal}
      onModalClose={onModalClose}
      modalTitle={terms.MultiYearPlan.Actions.deleteTbElement}
      modalNotice={terms.MultiYearPlan.Actions.deleteTbElementNotice}
      onDelete={onDelete}
      isLoading={isLoading}
    />
  )
}
