import terms from 'common/terms'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setLabelToUpdate, toggleEnterEditingMode, toggleShowPanel } from 'reducers/label'
import { RootState } from 'Store'
import { ToolsProps } from './types'

export default function UpdatePolygon({ closePopup }: ToolsProps): ReactElement {
  const dispatch = useDispatch()
  const { clickedFeatureInfo } = useSelector((state: RootState) => state.map)

  const handleClick = () => {
    dispatch(toggleEnterEditingMode(true))
    dispatch(toggleShowPanel(false))
    dispatch(setLabelToUpdate(clickedFeatureInfo))
    closePopup(undefined)
  }

  return (
    <div role="button" tabIndex={0} className="popup-items" onClick={handleClick}>
      {terms.Portfolio.updatePolygon}
    </div>
  )
}
