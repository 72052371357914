import { ReactElement } from 'react'
import { Source, Layer } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { FiltersCategories, ObjectsLayer } from 'components/Map/ObjectsLayers/types'
import { getFilterCheckStatus, getLayerVisibility } from '../utils'

export default function ManualAdvLayer(): ReactElement {
  const { advLayerData, filtersList } = useSelector((state: RootState) => state.object)

  return (
    <>
      <Source
        type="geojson"
        data={advLayerData}
        id={`manual-${ObjectsLayer.adv}`}
      >
        <Layer
          id={`manual-${ObjectsLayer.adv}`}
          type="symbol"
          filter={['==', 'auto_added', false]}
          minzoom={12}
          paint={{
            'text-color': '#41A8F9',
          }}
          layout={{
            'text-allow-overlap': true,
            'text-field': ['get', 'description'],
            'text-offset': [0, 0.9],
            'text-size': {
              stops: [
                [15, 6],
                [16, 14],
              ],
            },
            'text-font': ['Roboto Condensed'],
            visibility: getLayerVisibility(filtersList, FiltersCategories.Infra, ObjectsLayer.adv,
              getFilterCheckStatus(filtersList, FiltersCategories.General, ObjectsLayer.manual)),
          }}
        />
        <Layer
          id="manualAdvIconLayer"
          type="symbol"
          minzoom={12}
          paint={{}}
          filter={['==', 'auto_added', false]}
          layout={{
            'icon-rotation-alignment': 'map',
            'icon-pitch-alignment': 'map',
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
            'icon-offset': [0, -5],
            'icon-image': 'manual',
            'icon-size': [
              'interpolate',
              ['linear'],
              ['zoom'],
              10,
              0.1,
              15,
              0.8,
            ],
            visibility: getLayerVisibility(filtersList, FiltersCategories.Infra, ObjectsLayer.adv,
              getFilterCheckStatus(filtersList, FiltersCategories.General, ObjectsLayer.manual)),
          }}
        />
      </Source>
    </>
  )
}
