import { ReactElement } from 'react'
import ActionButton from 'components/common/Buttons/ActionButton'
import terms from 'common/terms'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { patchTbElement } from 'reducers/MultiYearPlan/TitleBlockActions/titleblockactions.thunk'
import { toggleEmptyFields } from 'reducers/MultiYearPlan/TitleBlockActions/titleblockactions'
import { hasEmptyFields } from '../TbElementFields/utils'

export default function ValidationModifyButton(): ReactElement {
  const dispatch = useDispatch()
  const { activeFile } = useSelector((state: RootState) => state.files)
  const {
    elementLabel, selectedTbElement, elementTypeValue, ferroLocFields,
  } = useSelector((state: RootState) => state.mypTitleBlockActions)

  const handleClick = () => {
    if (hasEmptyFields(elementLabel, elementTypeValue, ferroLocFields)) {
      dispatch(toggleEmptyFields(true))
    } else {
      const params = {
        repertoryId: activeFile.directory,
        folderId: activeFile.folder,
        fileId: activeFile.id,
        tbElementId: selectedTbElement.id,
        element: {
          label: elementLabel,
          type: elementTypeValue,
          start_kp: ferroLocFields.start_kp,
          end_kp: ferroLocFields.end_kp,
          line_code: ferroLocFields.line_code,
        },
      }
      dispatch(patchTbElement(params))
    }
  }
  return (
    <ActionButton
      text={terms.MultiYearPlan.Actions.modifyObject}
      onClick={handleClick}
      buttonStyle="operation-validation-btn"
    />
  )
}
