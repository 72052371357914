import { ReactElement } from 'react'
import { Source, Layer } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { FiltersCategories, ObjectsLayer, ObjectsName } from 'components/Map/ObjectsLayers/types'
import { getFilterCheckStatus, getLayerVisibilityByName } from '../utils'

export default function VbLayer(): ReactElement {
  const { peLayerData, filtersList } = useSelector((state: RootState) => state.object)

  return (
    <>
      <Source type="geojson" data={peLayerData} id={ObjectsName.VB}>
        <Layer
          id={ObjectsName.VB}
          filter={['all',
            ['==', 'auto_added', true],
            ['==', 'type', 'VOIE_BANALISEE'],
          ]}
          type="symbol"
          minzoom={12}
          paint={{
            'text-halo-width': 2,
            'text-halo-color': 'rgba(255,255,255,0.75)',
            'text-halo-blur': 1,
          }}
          layout={{
            'text-font': ['Roboto Condensed'],
            'icon-image': 'voieunique',
            'symbol-placement': 'line-center',
            'text-field': 'Banalisée',
            'text-size': 13,
            'icon-size': [
              'interpolate',
              ['linear'],
              ['zoom'],
              10,
              0.5,
              15,
              0.9,
            ],
            visibility: getLayerVisibilityByName(filtersList, FiltersCategories.ModeExploit, ObjectsName.VB,
              getFilterCheckStatus(filtersList, FiltersCategories.General, ObjectsLayer.gaia)),
          }}
        />
      </Source>
    </>
  )
}
