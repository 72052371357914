import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import terms from 'common/terms'
import ActionButton from 'components/common/Buttons/ActionButton'
import { PrFields } from 'reducers/RemarkablePoints/types'
import {
  patchFolderPr, patchRepertoryPr, postFolderPr, postRepertoryPr,
} from 'reducers/RemarkablePoints/remarkablePoints.thunk'
import { toggleEmptyFields } from 'reducers/RemarkablePoints/remarkablePoints'

export default function PrValidationButton(): ReactElement {
  const dispatch = useDispatch()
  const { prFields, selectedPr } = useSelector((state: RootState) => state.remarkablePoints)
  const { activeFile } = useSelector((state: RootState) => state.files)

  const onAddValidation = () => {
    prFields.forEach((pr: PrFields) => {
      if (pr.pr.line_code === '' || pr.pr.kp === '' || pr.pr.label === '') {
        dispatch(toggleEmptyFields(true))
      } else if (activeFile?.folder !== null) {
        dispatch(postFolderPr({
          repertoryId: activeFile.directory,
          folderId: activeFile.folder,
          fileId: activeFile.id,
          pr,
        }))
      } else {
        dispatch(postRepertoryPr({
          repertoryId: activeFile.directory,
          fileId: activeFile.id,
          pr,
        }))
      }
    })
  }

  const onUpdateValidation = () => {
    if (selectedPr.line_code === '' || selectedPr.kp === '' || selectedPr.label === '') {
      dispatch(toggleEmptyFields(true))
    } else if (activeFile?.folder !== null) {
      dispatch(patchFolderPr({
        repertoryId: activeFile.directory,
        folderId: activeFile.folder,
        fileId: activeFile.id,
        pr: { ...prFields[0].pr, id: selectedPr.id },
      }))
    } else {
      dispatch(patchRepertoryPr({
        repertoryId: activeFile.directory,
        fileId: activeFile.id,
        pr: { ...prFields[0].pr, id: selectedPr.id }
        ,
      }))
    }
  }

  const getText = () => (!selectedPr ? `${terms.MultiYearPlan.addPr}${prFields.length > 1
    ? `s ${prFields.length} ${terms.MultiYearPlan.pr}`
    : ` ${terms.MultiYearPlan.pr}`}` : terms.MultiYearPlan.Actions.updatePr)

  return (
    <ActionButton
      text={getText()}
      onClick={selectedPr ? onUpdateValidation : onAddValidation}
      buttonStyle="add-pr-btn"
    />
  )
}
