import { ReactElement } from 'react'
import { IconButton, Typography } from '@mui/material'
import terms from 'common/terms'
import CloseIcon from '@mui/icons-material/Close'
import { useDispatch } from 'react-redux'
import { toggleDisplaySettingsPanel } from 'reducers/MultiYearPlan/Settings/settings'

export default function PanelHeader(): ReactElement {
  const dispatch = useDispatch()
  return (
    <div className="myp-settings-panel-header">
      <Typography>
        {terms.MultiYearPlan.settings}
      </Typography>
      <IconButton onClick={() => dispatch(toggleDisplaySettingsPanel(false))}>
        <CloseIcon />
      </IconButton>
    </div>
  )
}
