/* eslint-disable no-prototype-builtins */
import { ReactElement, useEffect } from 'react'
import terms from 'common/terms'
import ActionButton from 'components/common/Buttons/ActionButton'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import CreationService from 'components/Dashboard/Creation/CreationService'
import { resetNoPerimeterChange, updateCreatedFile } from 'reducers/Files/files'
import { toggleModifySections, toggleValidationNotice } from 'reducers/creation'
import { FileTypes } from 'reducers/Files/types'
import {
  getFilesSectionsList, importFolderFilesSection, importRepertoryFileSection,
} from 'reducers/MultiYearPlan/multiYearPlan.thunk'
import { CreationLevels } from 'components/Dashboard/types'
import { patchFile, updateGeographicPerimeter } from 'reducers/Files/files.thunk'
import { toggleDisplaySettingsPanel } from 'reducers/MultiYearPlan/Settings/settings'
import { toggleUpdateMypSections } from 'reducers/MultiYearPlan/multiYearPlan'
import { displayTabContent } from '../utils'

export default function GeographicPerimeterButton(): ReactElement {
  const dispatch = useDispatch()
  const { activeFile, noPerimeterChange } = useSelector((state: RootState) => state.files)
  const { sectionsList } = useSelector((state: RootState) => state.creation)
  const { mypSectionsList, fileToImport, updateMypSections } = useSelector((state: RootState) => state.multiYearPlan)
  const { Operations } = useSelector((state: RootState) => state.mypoperations)
  const { tabsList } = useSelector((state: RootState) => state.dashboard)
  const { activeRepertory } = useSelector((state: RootState) => state.repertories)
  const { activeFolder } = useSelector((state: RootState) => state.folders)

  useEffect(() => {
    if (noPerimeterChange) {
      dispatch(toggleDisplaySettingsPanel(false))
      dispatch(toggleUpdateMypSections(false))
      dispatch(resetNoPerimeterChange())
      displayTabContent(tabsList, activeFile, activeRepertory, activeFolder)
    }
  }, [noPerimeterChange])

  const handleClick = () => {
    console.log('click')
    if (activeFile.type === FileTypes.Synoptic) {
      dispatch(CreationService.getSingleProject(activeFile.id))
      dispatch(updateCreatedFile(activeFile))
      dispatch(toggleValidationNotice(true))
      dispatch(toggleModifySections(false))
    } else if (fileToImport) {
      if (activeFile.folder !== null) {
        dispatch(importFolderFilesSection({
          repertoryId: activeFile.directory,
          folderId: activeFile.folder,
          fileId: activeFile.id,
          projectId: fileToImport.id,
        }))
      } else {
        dispatch(importRepertoryFileSection({
          repertoryId: activeFile.directory,
          fileId: activeFile.id,
          projectId: fileToImport.id,
        }))
      }
    } else if (!updateMypSections) {
      dispatch(getFilesSectionsList({
        repertoryId: activeFile.directory,
        folderId: activeFile.folder,
        fileId: activeFile.id,
      }))
      dispatch(patchFile({
        repertoryId: activeFile.directory,
        folderId: activeFile.folder,
        file: { id: activeFile.id, status: CreationLevels.Geographic },
      }))
    } else if ((Object.keys(Operations).length !== 0
    // eslint-disable-next-line max-len
    && Operations.years[0].hasOwnProperty('classifications')) || !Operations.years[0].hasOwnProperty('classifications')) {
      dispatch(updateGeographicPerimeter({
        repertoryId: activeFile.directory,
        folderId: activeFile.folder,
        file: { id: activeFile.id },
      }))
    } else {
      dispatch(toggleDisplaySettingsPanel(false))
      dispatch(toggleUpdateMypSections(false))
      displayTabContent(tabsList, activeFile, activeRepertory, activeFolder)
    }
  }

  return (
    <div className="d-flex justify-content-center">
      <ActionButton
        text={terms.Common.continue}
        onClick={handleClick}
        rightIcon
        buttonStyle="action-button"
        disabled={(sectionsList.length === 0 && mypSectionsList.length === 0 && !fileToImport)}
      />
    </div>
  )
}
