import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import AttributsSettingsButton from 'components/Portfolio/Buttons/Import/AttributsSettingsButton'
import ImportDocumentButton from 'components/Portfolio/Buttons/Import/ImportDocumentButton'
import SummaryButton from 'components/Portfolio/Buttons/Import/SummaryButton'
import FerroLocalizationButton from 'components/Portfolio/Buttons/Import/FerroLocalizationButton'
import GenerateLabelsButton from 'components/Portfolio/Buttons/Import/GenerateLabelsButton'

export default function StepsButtons(): ReactElement {
  const { activeStep } = useSelector((state: RootState) => state.import)

  switch (activeStep) {
    case -1:
      return <SummaryButton />
    case 0:
      return <ImportDocumentButton />
    case 1:
      return <AttributsSettingsButton />
    case 2:
      return <FerroLocalizationButton />
    case 3:
      return <GenerateLabelsButton />
    default:
      return <></>
  }
}
