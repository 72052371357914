import { ReactElement } from 'react'
import { Fade, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import bannerBG from 'assets/images/banner-img.jpg'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import terms from 'common/terms'

import './home.scss'
import SearchBar from 'components/common/Search/SearchBar'
import { updateSearch } from 'reducers/Repertories/repertories'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'

export default function Banner(): ReactElement {
  const { search } = useSelector((state: RootState) => state.repertories)
  return (
    <Fade in timeout={500}>
      <Box
        className="home-page-banner"
        sx={{
          backgroundImage: `linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)),
         url(${bannerBG})`,
        }}
      >
        <Grid
          container
          justifyContent="center"
          className="banner-grid"
        >
          <Grid item xs={12} sm={10} md={8} sx={{ mt: 2 }}>
            <Typography className="banner-title">
              {terms.HomePage.welcomeTo}
              {' '}
              {terms.Common.appName}
            </Typography>
            <Typography className="banner-subtitle">
              {terms.HomePage.bannerSubtitle}
              <ArrowRightAltIcon sx={{ ml: 1 }} />
            </Typography>

          </Grid>
          <Grid item xs={12} sm={10} md={8}>
            <SearchBar placeholder={terms.HomePage.searchPlaceholder} onChange={updateSearch} search={search} />
          </Grid>
        </Grid>
      </Box>
    </Fade>
  )
}
