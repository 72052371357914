const ALLOWED_ANGLES = [
  -180, -165, -150, -135, -120, -105, -90, -75, -60, -45, -30, -15,
  0, 15, 30, 45, 60, 75, 90, 105, 120, 135, 150, 165, 180,
]

// Define the angle so that created TIVs are parallels to the other
const getRotateAngle = (rawAngle: number): number => ALLOWED_ANGLES.reduce(
  (a, b) => (Math.abs(b - rawAngle) < Math.abs(a - rawAngle) ? b : a),
)

export default getRotateAngle
