import { Grid } from '@mui/material'
import { ReactElement } from 'react'
import ColumnC6ValuesPanel from './ColumnC6ValuesPanel'
import ObjectsFamiliesContainer from './ObjectsFamiliesContainer'

import './parametrage.scss'

export default function ParametrageContainer(): ReactElement {
  return (
    <Grid container className="parametrage-container">
      <ObjectsFamiliesContainer />
      <ColumnC6ValuesPanel />
    </Grid>
  )
}
