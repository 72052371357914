import { ReactElement, useState, useEffect } from 'react'
import terms from 'common/terms'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { getSubClassificationsByClassificationId } from 'reducers/MultiYearPlan/OperationActions/operationactions.thunk'
import { getClassifications } from 'reducers/MultiYearPlan/Import/ObjectsFamilies/objectsFamilies.thunk'
import {
  updateSubClassificationId,
} from 'reducers/MultiYearPlan/OperationActions/operationactions'
import {
  setResponseError,
} from 'reducers/MultiYearPlan/Library/library'
import { Classification } from 'reducers/MultiYearPlan/Operations/types'
import { SubClassification } from 'reducers/MultiYearPlan/Import/ObjectsFamilies/type'
import OperationSelectField from './OperationSelectField'
import { getErrorMessage } from './utils'

export default function TypologiesChoice(): ReactElement {
  const dispatch = useDispatch()
  const {
    subClassificationByClassification, responseError, emptyFields,
    selectedOperation,
  } = useSelector((state: RootState) => state.mypoperationactions)
  const { activeFile } = useSelector((state: RootState) => state.files)
  const { objectsFamilies } = useSelector((state: RootState) => state.objectsFamilies)
  const { selectedDefectedOperation, libraryResponseError } = useSelector((state: RootState) => state.mypLibrary)
  const [typology, setTypology] = useState<Classification | null>(null)
  const [opera, setOpera] = useState<SubClassification | null>(null)

  useEffect(() => {
    const operation = selectedOperation || selectedDefectedOperation
    if (operation && operation.sub_classification) {
      setTypology(operation.sub_classification.classification)
      setOpera(operation.sub_classification)
      dispatch(getSubClassificationsByClassificationId({
        repertoryId: activeFile.directory,
        folderId: activeFile.folder,
        fileId: activeFile.id,
        classificationId: operation.sub_classification.classification.id,
      }))
      dispatch(updateSubClassificationId(operation.sub_classification.id))
    }
  }, [])

  useEffect(() => {
    dispatch(getClassifications({
      repertoryId: activeFile.directory,
      folderId: activeFile.folder,
      fileId: activeFile.id,
    }))
  }, [])

  const handleClassificationChange = (value: Classification) => {
    setTypology(value)
    setOpera(null)
    dispatch(updateSubClassificationId(-1))
    dispatch(setResponseError(undefined))
    if (value) {
      dispatch(getSubClassificationsByClassificationId({
        repertoryId: activeFile.directory,
        folderId: activeFile.folder,
        fileId: activeFile.id,
        classificationId: value.id,
      }))
    }
  }

  const handleSubClassificationChange = (value: SubClassification) => {
    setOpera(value)
    dispatch(updateSubClassificationId(value?.id))
  }

  return (
    <div className="typology-select-container">
      <OperationSelectField
        value={typology}
        onChange={(_event, newInputValue) => handleClassificationChange(newInputValue as Classification)}
        options={objectsFamilies}
        placeholder={terms.Inputs.typologyPlaceholder}
        label={terms.Inputs.typology}
        errorMessage={getErrorMessage((responseError || libraryResponseError), 'sub_classification', emptyFields,
         typology as Classification)}
      />
      <OperationSelectField
        value={opera}
        optionLabelType="code"
        onChange={(_event, newInputValue) => handleSubClassificationChange(newInputValue as SubClassification)}
        options={subClassificationByClassification}
        placeholder={terms.Inputs.operaPlaceholder}
        label={terms.Inputs.opera}
        disabled={typology === null || subClassificationByClassification.length === 0}
        errorMessage={getErrorMessage((responseError || libraryResponseError), 'sub_classification', emptyFields,
         opera as SubClassification)}
      />
    </div>
  )
}
