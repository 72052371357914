import { ReactElement } from 'react'
import AddObject from './AddObject'
import SwitchToGeoeditor from './SwitchToGeoeditor'

export default function SynopticActionsButtons(): ReactElement {
  return (
    <div className="btn-container">
      <AddObject />
      <SwitchToGeoeditor />
    </div>
  )
}
