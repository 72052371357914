import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import {
  Box, IconButton, Modal, Typography,
} from '@mui/material'
import terms from 'common/terms'
import CloseIcon from '@mui/icons-material/Close'
import {
  toggleDisplayModifyModal, toggleEmptyFields,
  resetObjectsFileds,
} from 'reducers/MultiYearPlan/TitleBlockActions/titleblockactions'
import OperationFieldsContainer from '../TbElementFields/TbElementFieldsContainer'
import ValidationModifyButton from '../Buttons/ValidationModifyButton'

export default function ModifyTbElementModal(): ReactElement {
  const dispatch = useDispatch()
  const { displayModifyModal } = useSelector((state: RootState) => state.mypTitleBlockActions)

  const onClose = () => {
    dispatch(toggleDisplayModifyModal(false))
    dispatch(toggleEmptyFields(false))
    dispatch(resetObjectsFileds())
  }

  return (
    <Modal open={displayModifyModal}>
      <Box className="operation-creation-modal">
        <div className="operation-modal-header">
          <Typography>
            {terms.MultiYearPlan.Actions.modifyObject}
          </Typography>
          <div>
            <IconButton onClick={onClose}><CloseIcon /></IconButton>
          </div>
        </div>
        <OperationFieldsContainer />
        <div>
          <ValidationModifyButton />
        </div>
      </Box>
    </Modal>
  )
}
