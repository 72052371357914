import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkApi } from 'types'
import {
  get, deleteRequest, patch, post,
} from '@osrdata/app_core/dist/requests'
import {
  GetParams, DeleteParams, PatchParams,
  TbElementType,
} from './types'
import { CartoucheElement } from '../TitleBlock/types'

const deleteTbElement = createAsyncThunk<null, DeleteParams, ThunkApi>(
  'mypTitleBlockActions/deleteTbElement',
  async (params: DeleteParams, thunkApi) => {
    if (params.folderId && params.folderId !== null) {
      try {
        const response = await
        // eslint-disable-next-line max-len
        deleteRequest(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.projectId}/cartouche-elements/${params.tbElementId}`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response = await
        // eslint-disable-next-line max-len
        deleteRequest(`/module_schematique/directories/${params.repertoryId}/projects/${params.projectId}/cartouche-elements/${params.tbElementId}`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

const getTbElement = createAsyncThunk<CartoucheElement, GetParams, ThunkApi>(
  'mypTitleBlockActions/getTbElement',
  async (params: GetParams, thunkApi) => {
    if (params.folderId && params.folderId !== null) {
      try {
        const response: CartoucheElement = await
        // eslint-disable-next-line max-len
        get(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.fileId}/cartouche-elements/${params.tbElementId}`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response: CartoucheElement = await
        // eslint-disable-next-line max-len
        get(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/cartouche-elements/${params.tbElementId}`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

const patchTbElement = createAsyncThunk<CartoucheElement, PatchParams, ThunkApi>(
  'mypTitleBlockActions/patchTbElement',
  async (params: PatchParams, thunkApi) => {
    if (params.folderId && params.folderId !== null) {
      try {
        const response = await
        // eslint-disable-next-line max-len
        patch(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.fileId}/cartouche-elements/${params.tbElementId}`, params.element)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response = await
        // eslint-disable-next-line max-len
        patch(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/cartouche-elements/${params.tbElementId}`, params.element)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

const postTbElement = createAsyncThunk<CartoucheElement, PatchParams, ThunkApi>(
  'mypTitleBlockActions/postTbElement',
  async (params: PatchParams, thunkApi) => {
    if (params.folderId && params.folderId !== null) {
      try {
        const response = await
        // eslint-disable-next-line max-len
        post(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.fileId}/cartouche-elements/`, params.element)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response = await
        // eslint-disable-next-line max-len
        post(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/cartouche-elements/`, params.element)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

const getTbElementTypes = createAsyncThunk<TbElementType[]>(
  'mypTitleBlockActions/getTbElementTypes',
  async () => {
    const TbElementTypes: TbElementType[] = await get('/module_schematique/cartouche-element-types/')
    return TbElementTypes
  },
)

export {
  deleteTbElement,
  getTbElement,
  patchTbElement,
  getTbElementTypes,
  postTbElement,
}
