import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dropdown } from 'react-bootstrap'
import { auth } from '@osrdata/app_core'
import { PORTAL_URL } from 'config/config'
import logo from 'assets/logos/logo-DGEXSOL-full-white.svg'
import logoArpe from 'assets/logos/logo-arpe.svg'
import { FaPowerOff } from 'react-icons/fa'
import { RootState } from 'Store'
import Loader from 'components/Loader'
import terms from 'common/terms'

type Props = {
  appName: string,
}

export default function TopBar({ appName }: Props): ReactElement {
  const dispatch = useDispatch()
  const user = useSelector((state: RootState) => state.user)
  const app = useSelector((state: RootState) => state.app)
  const { loadingFiles, searchingFiles } = useSelector((state: RootState) => state.files)
  const { loadingFolders } = useSelector((state: RootState) => state.folders)
  const { searchingContent } = useSelector((state: RootState) => state.repertories)
  const { loadingOperations } = useSelector((state: RootState) => state.mypoperations)
  const { loadingDefectedOperations, loadingPositionedOperations } = useSelector((state: RootState) => state.mypLibrary)
  const logout = () => {
    dispatch(auth.logout())
  }

  return (
    <div className={`mastheader${app.fullscreen ? ' fullscreen' : ''}`}>
      <div className="mastheader-logo">
        <a href={PORTAL_URL.url}><img alt={appName} src={logo} /></a>
        <img alt={appName} src={logoArpe} className="ml-4" />
        {((loadingFiles || loadingFolders || loadingOperations || loadingDefectedOperations
         || loadingPositionedOperations) && !searchingFiles && !searchingContent) && <Loader center />}
      </div>
      <ul className="mastheader-toolbar toolbar mb-0">
        <li className="toolbar-item separator-gray-500">
          <Dropdown>
            <Dropdown.Toggle variant="transparent">
              <i className="icons-menu-account icons-size-1x25 icons-md-size-1x5 mr-xl-2" aria-hidden="true" />
              <span className="d-none d-xl-block">
                {user.account.firstName}
                {' '}
                {user.account.lastName}
              </span>
              <span className="d-none d-xl-block" />
              <i className="icons-arrow-down d-none d-xl-block" aria-hidden="true" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={logout} href="#">
                <span className="mr-2"><FaPowerOff /></span>
                {terms.Common.disconnect}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </li>
      </ul>
    </div>
  )
}
