/* eslint-disable max-len */
import { ReactElement, useEffect, useState } from 'react'
import {
  Box, Button, Fade, Grid, Typography,
} from '@mui/material'
import terms from 'common/terms'
import OrderingMenu from 'components/common/OrderingMenu/OrderingMenu'
import SearchBar from 'components/common/Search/SearchBar'
import history from 'customHistory'
import PathNames from 'common/PathNames'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded'

import './folderContent.scss'
import {
  resetActiveFile, resetResponseError, toggleCloneFile, toggleIsCloning, toggleOpenFile, updateOrderBy, updateSearch,
} from 'reducers/Files/files'
import { Files, FileTypes } from 'reducers/Files/types'
import FileCard from 'components/Files/FileCard'
import { getFolderFilesList } from 'reducers/Files/files.thunk'
import { resetSharingStatus } from 'reducers/Cerbere/cerbere'
import { getErrorMessage, getOrderingValue } from 'components/Repertories/RepertoryContent/utils'
import { setActiveFolder } from 'reducers/Folders/folder'
import { updateActiveProject, updateCreationLevel, updateSectionsList } from 'reducers/creation'
import { ActiveTab, CreationLevels } from 'components/Dashboard/types'
import CreationService from 'components/Dashboard/Creation/CreationService'
import { updateActiveBlock } from 'reducers/synoptic'
import { toggleSelectTab, updateActiveTab, updateTabsList } from 'reducers/dashboard'
import SnackBar from 'components/common/Snackbar/SnackBar'
import { updateCreationStatus } from 'reducers/MultiYearPlan/multiYearPlan'
import { getPath } from '../../utils'

export default function FolderContentContainer(): ReactElement {
  const dispatch = useDispatch()
  const { activeRepertory } = useSelector((state: RootState) => state.repertories)
  const { shareDone } = useSelector((state: RootState) => state.cerbere)
  const { activeFolder } = useSelector((state: RootState) => state.folders)
  const {
    search, orderBy, folderFiles, activeFile, openFile, cloneFile, responseError, isCloning,
  } = useSelector((state: RootState) => state.files)
  const { activeProject } = useSelector((state: RootState) => state.creation)
  const { tabsList, selectTab } = useSelector((state: RootState) => state.dashboard)
  const { gettingSections } = useSelector((state: RootState) => state.multiYearPlan)

  const [displayCloneLoading, setDisplayCloneLoading] = useState(false)
  const [displayCloneDone, setDisplayCloneDone] = useState(false)
  const [displayCloneFailed, setDisplayCloneFailed] = useState(false)

  useEffect(() => {
    if (displayCloneDone) {
      setTimeout(() => {
        setDisplayCloneLoading(false)
        setDisplayCloneDone(false)
      }, 1000)
    }
  }, [displayCloneDone])

  useEffect(() => {
    if (displayCloneFailed) {
      setTimeout(() => {
        setDisplayCloneFailed(false)
      }, 1000)
    }
  }, [displayCloneFailed])

  useEffect(() => {
    if (cloneFile && !isCloning) {
      setDisplayCloneDone(true)
      dispatch(toggleCloneFile())
    }
    if (isCloning) {
      setDisplayCloneLoading(true)
      setTimeout(() => {
        dispatch(toggleIsCloning())
      }, 2000)
    }
    if (!isCloning && responseError) {
      setDisplayCloneLoading(false)
      setDisplayCloneFailed(true)
    }
  }, [cloneFile, isCloning, responseError])

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetSharingStatus())
    }, 2000)
  }, [shareDone])

  useEffect(() => {
    dispatch(getFolderFilesList({
      repertoryId: activeRepertory.id,
      folderId: activeFolder.id,
      ordering: getOrderingValue(orderBy),
      search: search !== '' ? search : null,
    }))
  }, [activeFile, search, orderBy, cloneFile])

  useEffect(() => {
    if (activeFile && openFile) {
      if (activeFile.type === FileTypes.Synoptic) {
        if (activeProject) {
          const newActiveTab: ActiveTab = {
            tabName: activeFile.name,
            tabId: activeFile.id,
            fileType: activeFile.type,
            directoryId: activeFile.directory,
            folderId: activeFile.folder,
          }
          dispatch(updateActiveTab(newActiveTab))
          if (tabsList && tabsList.filter((tab: ActiveTab) => tab.tabId === newActiveTab.tabId).length === 0) {
            const newTabsList: Array<ActiveTab> = [newActiveTab]
            dispatch(updateTabsList(tabsList.concat(newTabsList)))
          }
          dispatch(updateCreationLevel(activeProject.status))
          if (activeProject.status === CreationLevels.Name) {
            history.push(`${getPath(activeRepertory, activeFolder, activeFile)}/${PathNames.FileSynoptic}/${PathNames.TemporalPerimeter}`)
          } else if (activeProject.status === CreationLevels.Temporal) {
            dispatch(updateSectionsList(activeProject.sections))
            history.push(`${getPath(activeRepertory, activeFolder, activeFile)}/${PathNames.FileSynoptic}/${PathNames.GeographicPerimeter}`)
          } else if (activeProject.status === CreationLevels.Geographic) {
            dispatch(CreationService.getSingleProject(activeProject.id))
            dispatch(updateActiveBlock(terms.Synoptic.createFondPlan))
          }
          dispatch(toggleOpenFile(false))
        }
      } else {
        const newActiveTab: ActiveTab = {
          tabName: activeFile.name,
          tabId: activeFile.id,
          fileType: activeFile.type,
          directoryId: activeFile.directory,
          folderId: activeFile.folder,
        }
        if (!gettingSections) {
          dispatch(updateActiveTab(newActiveTab))
          if (tabsList && tabsList.filter((tab: ActiveTab) => tab.tabId === newActiveTab.tabId).length === 0) {
            const newTabsList: Array<ActiveTab> = [newActiveTab]
            dispatch(updateTabsList(tabsList.concat(newTabsList)))
          }
          dispatch(updateCreationStatus(activeFile.status))
          if (activeFile.status === CreationLevels.Name) {
            history.push(`${getPath(activeRepertory, activeFolder, activeFile)}/${PathNames.FileSchema}/${PathNames.TemporalPerimeter}`)
          } else if (activeFile.status === CreationLevels.Temporal) {
            history.push(`${getPath(activeRepertory, activeFolder, activeFile)}/${PathNames.FileSchema}/${PathNames.GeographicPerimeter}`)
          }
          dispatch(toggleOpenFile(false))
        }
      }
    }
  }, [activeProject, openFile, activeFile, gettingSections])

  useEffect(() => {
    if (activeFile && selectTab && selectTab.tabId === activeFile.id) {
      if (activeFile.type === FileTypes.Synoptic) {
        if (activeProject && activeProject.id === activeFile.id) {
          dispatch(updateCreationLevel(activeProject.status))
          dispatch(updateActiveTab({
            tabName: activeProject.name,
            tabId: activeProject.id,
            fileType: activeFile.type,
            directoryId: activeFile.directory,
            folderId: activeFile.folder,
          }))
          if (activeProject.status === CreationLevels.Geographic) {
            dispatch(updateActiveBlock(terms.Synoptic.createFondPlan))
          } else if (activeProject.status === CreationLevels.Name) {
            history.push(`${getPath(activeRepertory, activeFolder, activeFile)}/${PathNames.FileSynoptic}/${PathNames.TemporalPerimeter}`)
          } else if (activeProject.status === CreationLevels.Temporal) {
            dispatch(updateSectionsList(activeProject.sections))
            history.push(`${getPath(activeRepertory, activeFolder, activeFile)}/${PathNames.FileSynoptic}/${PathNames.GeographicPerimeter}`)
          }
          dispatch(toggleSelectTab(undefined))
        }
      } else {
        dispatch(updateActiveProject(undefined))
        dispatch(updateCreationLevel(undefined))
        dispatch(updateActiveTab({
          tabName: activeFile.name,
          tabId: activeFile.id,
          fileType: activeFile.type,
          directoryId: activeFile.directory,
          folderId: activeFile.folder,
        }))
        dispatch(updateCreationStatus(activeFile.status))
        if (activeFile.status === CreationLevels.Name) {
          history.push(`${getPath(activeRepertory, activeFolder, activeFile)}/${PathNames.FileSchema}/${PathNames.TemporalPerimeter}`)
        } else if (activeFile.status === CreationLevels.Temporal) {
          history.push(`${getPath(activeRepertory, activeFolder, activeFile)}/${PathNames.FileSchema}/${PathNames.GeographicPerimeter}`)
        }
        dispatch(toggleSelectTab(undefined))
      }
    }
  }, [activeProject, selectTab, activeFile])

  const handleClick = () => {
    history.push(`${PathNames.RepertoryContent}/${activeRepertory?.id}/`)
    dispatch(resetActiveFile())
    dispatch(setActiveFolder())
  }

  return (
    <Fade in timeout={500}>
      <Box>
        <Grid
          container
          className="folder-content-container"
        >
          <Grid item container sx={{ height: '55px' }}>
            <Button
              className="label-style"
              sx={{ padding: '6px 24px 6px 0' }}
              onClick={handleClick}
            >
              {activeRepertory?.label}
            </Button>
            <KeyboardArrowRightRoundedIcon />
            <Typography className="label-style" sx={{ padding: '0 24px' }}>{activeFolder?.label}</Typography>
          </Grid>
          <Grid item container>
            <SearchBar
              placeholder={terms.File.searchPlaceholder}
              onChange={updateSearch}
              search={search}
            />
            <OrderingMenu
              orderBy={orderBy}
              updateOrderBy={updateOrderBy}
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          spacing={1}
          className="px-3"
          sx={{ paddingTop: '36px', overflow: 'auto', height: 'calc(100vh - 280px)' }}
        >
          {folderFiles.map((file: Files) => (
            <Grid item key={file.id} sx={{ justifyContent: 'center' }}>
              <FileCard file={file} />
            </Grid>
          ))}
        </Grid>
        <SnackBar
          message={getErrorMessage(responseError, displayCloneLoading, displayCloneDone, displayCloneFailed, shareDone)}
          error={responseError || displayCloneLoading || displayCloneDone || displayCloneFailed || shareDone}
          severity={displayCloneDone || shareDone ? 'success' : 'warning'}
          setError={resetResponseError}
        />
      </Box>
    </Fade>
  )
}
