import { ReactElement, useEffect } from 'react'
import { Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { updateVersions } from 'reducers/export'
import { RootState } from 'Store'
import { CartridgeVersion, VersionsFields } from '../types'
import CartridgeVersionsFields from './CartridgeVersionsFields'

export default function CartridgeVersions(): ReactElement {
  const dispatch = useDispatch()
  const { cartridge, versions } = useSelector((state: RootState) => state.export)

  useEffect(() => {
    if (versions.length === 0) {
      dispatch(updateVersions([{
        index: 1,
        version: {
          author: '',
          comment: '',
          date: '',
          index: null,
          version: '',
        },
      }]))
    }
  }, [versions])

  useEffect(() => {
    if (cartridge && cartridge.versions.length !== 0) {
      const existingVersions: VersionsFields[] = []
      cartridge.versions.map((v: CartridgeVersion, index: number) => (
        existingVersions.push({
          index: index + 1,
          version: v,
        })
      ))
      dispatch(updateVersions(existingVersions))
    }
  }, [cartridge])

  return (
    <div className="versions-container">
      {versions.map((cartridgeVersion: VersionsFields) => (
        <Row key={cartridgeVersion.index} className="version-row">
          <CartridgeVersionsFields existingVersion={cartridgeVersion} />
        </Row>
      ))}
    </div>
  )
}
