import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import terms from 'common/terms'
import { Comment } from 'reducers/MultiYearPlan/Comments/types'
import { getComments } from 'reducers/MultiYearPlan/Comments/comments.thunk'
import { updateComments, addNewComment, toggleCommentOnCreation } from 'reducers/MultiYearPlan/Comments/comments'
import CommentsVersions from './CommentsVersions'
import './comments.scss'

export default function Comments(): ReactElement {
  const dispatch = useDispatch()
  const { activeFile } = useSelector((state: RootState) => state.files)
  const { zoomPr } = useSelector((state: RootState) => state.remarkablePoints)
  const {
    newCommentContext, updateCommentContext, comments, CommentOnCreation,
  } = useSelector((state: RootState) => state.mypcomments)

  useEffect(() => {
    if (!newCommentContext.displayAddButton && !updateCommentContext.displayUpdateButton) {
      dispatch(getComments({
        repertoryId: activeFile.directory,
        folderId: activeFile.folder,
        fileId: activeFile.id,
      }))
    }
  }, [newCommentContext, updateCommentContext])

  const handleClick = () => {
    const newComment: Comment = {
      index: comments.length + 1,
      date: '',
      entity: '',
      author: '',
      comment: '',
    }
    dispatch(updateComments(comments.concat(newComment)))
    dispatch(addNewComment({
      displayAddButton: true,
      newCommentIndex: comments.length + 1,
    }))
    dispatch(toggleCommentOnCreation(true))
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight)
    }, 300)
  }

  return (
    <>
      <div id="line-chart-container-comments">
        <div
          style={{
            height: '38px',
            fontWeight: '900',
            color: 'black',
            background: '#e2e7ef',
            width: zoomPr + 100,
          }}
          className="pl-2 d-flex align-items-center"
        >
          {terms.MultiYearPlan.Comments.sectionTitle}
          <IconButton
            onClick={handleClick}
            aria-label="add"
            size="small"
            disabled={CommentOnCreation || comments[0]?.id === ''}
            sx={{
              color: 'black', backgroundColor: 'white', borderRadius: 0, marginLeft: 1,
            }}
          >
            <AddIcon fontSize="small" />
          </IconButton>
        </div>
        <div className="d-flex bg-white align-items-center mb-5">
          <CommentsVersions />
        </div>
      </div>
    </>
  )
}
