/* eslint-disable max-len */
import {
  useEffect, Suspense, ReactElement, useState,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Router, Switch, Route, Redirect,
} from 'react-router-dom'
import { auth } from '@osrdata/app_core'
import HomePage from 'components/Home/HomePage'
import RepertoryNameDeclaration from 'components/Repertories/Declaration/RepertoryNameDeclaration'
import PathNames from 'common/PathNames'
import PictureConfiguration from 'components/Repertories/Declaration/PictureConfiguration'
import PicturePreview from 'components/Repertories/Declaration/PicturePreview'
import RepertoriesContent from 'components/Repertories/RepertoryContent/RepertoriesContent'
import FolderContent from 'components/Folders/FolderContent'
import FileNameDeclaration from 'components/Files/Creation/Name/FileNameDeclaration'
import TemporalPerimeterDeclaration from 'components/Files/Creation/TemporalPerimeter/TemporalPerimeterDeclaration'
import GeographicPerimeterDeclaration from 'components/Files/Creation/GeographicPerimeter/GeographicPerimeterDeclaration'
import ImportInstructions from 'components/Portfolio/Import/ImportInstructions'
import ExportDocument from 'components/Export/ExportDocument'
import MypImportSteps from 'components/MultiYearPlan/Import/MypImportSteps'
import AccessDenied from 'AccessDenied'
import { getPath } from './utils'
import { RootState } from './Store'
import Loader from './components/Loader'
import history from './customHistory'
import './App.scss'

const ACCESS_PERM = 'module-schematique::ACCESS'
const ADMIN_PERM = 'module-schematique::ADMIN'

export default function App(): ReactElement {
  const dispatch = useDispatch()
  const { isLogged, appPermissions, isLoading } = useSelector((state: RootState) => state.user)
  const { activeRepertory } = useSelector((state: RootState) => state.repertories)
  const { activeFolder } = useSelector((state: RootState) => state.folders)
  const { activeFile } = useSelector((state: RootState) => state.files)
  const [accessDenied, setAccessDenied] = useState(false)

  useEffect(() => {
    dispatch(auth.attemptLoginOnLaunch())
    if (window.performance) {
      if (performance.navigation.type === 1) {
        history.push(PathNames.Home)
      }
    }
  }, [])

  useEffect(() => {
    if (isLogged && appPermissions.length > 0) {
      if (!appPermissions.includes(ACCESS_PERM) && !appPermissions.includes(ADMIN_PERM)) {
        setAccessDenied(true)
      }
    }
  }, [isLogged, appPermissions])

  return (
    <>
      <Suspense fallback={<Loader center />}>
        {(!isLoading && isLogged) && (accessDenied ? <AccessDenied /> : (
          <Router history={history}>
            <Switch>
              <Route exact component={HomePage} path={PathNames.Home} />
              <Route exact component={RepertoryNameDeclaration} path={PathNames.Repertory} />
              <Route exact component={PictureConfiguration} path={PathNames.Picture} />
              <Route exact component={PicturePreview} path={PathNames.PicturePreviz} />
              <Route
                exact
                component={RepertoriesContent}
                path={`${PathNames.RepertoryContent}/${activeRepertory?.id}`}
              />
              <Route
                exact
                component={FolderContent}
                path={`${PathNames.RepertoryContent}/${activeRepertory?.id}/${PathNames.Folder}/${activeFolder?.id}`}
              />
              <Route
                exact
                component={FileNameDeclaration}
                path={`${getPath(activeRepertory, activeFolder, activeFile)}/${PathNames.FileSchema}`}
              />
              <Route
                exact
                component={FileNameDeclaration}
                path={`${getPath(activeRepertory, activeFolder, activeFile)}/${PathNames.FileSynoptic}`}
              />
              <Route
                exact
                component={TemporalPerimeterDeclaration}
                path={`${getPath(activeRepertory, activeFolder, activeFile)}/${PathNames.FileSchema}/${PathNames.TemporalPerimeter}`}
              />
              <Route
                exact
                component={TemporalPerimeterDeclaration}
                path={`${getPath(activeRepertory, activeFolder, activeFile)}/${PathNames.FileSynoptic}/${PathNames.TemporalPerimeter}`}
              />
              <Route
                exact
                component={GeographicPerimeterDeclaration}
                path={`${getPath(activeRepertory, activeFolder, activeFile)}/${PathNames.FileSchema}/${PathNames.GeographicPerimeter}`}
              />
              <Route
                exact
                component={GeographicPerimeterDeclaration}
                path={`${getPath(activeRepertory, activeFolder, activeFile)}/${PathNames.FileSynoptic}/${PathNames.GeographicPerimeter}`}
              />
              <Route exact component={ImportInstructions} path={PathNames.Import} />
              <Route exact component={ExportDocument} path={PathNames.Export} />
              <Route exact component={MypImportSteps} path={PathNames.MypImport} />
              <Redirect to={PathNames.Home} />
            </Switch>
          </Router>
        ))}
      </Suspense>
    </>
  )
}
