/* eslint-disable no-compare-neg-zero */
import MAIN_API from 'config/config'
import { MapRequest, ViewportProps } from 'react-map-gl'

export const DEFAULT_VIEWPORT: ViewportProps = {
  latitude: 46.88731499073388,
  longitude: 2.5682289198122756,
  zoom: 6,
  bearing: 0,
  pitch: 0,
}

const isTileserverResource = (type: string) => (type === 'Source' || type === 'Tile')

const replaceUrl = (url: string): string => {
  if (!MAIN_API.proxy.includes('.dev.')) {
    const newEnv = MAIN_API.proxy.includes('staging') ? 'staging.dgexsol.' : 'dgexsol.'
    return url.replace('dev.dgexsol.', newEnv)
  }
  return url
}

export const transformRequest = (url: string | undefined, resourceType: string | undefined): MapRequest => {
  if (url === undefined) return { url: '' }
  if ((isTileserverResource(resourceType as string))) {
    const newUrl = url.startsWith(MAIN_API.proxy) ? url : replaceUrl(url)
    return {
      url: newUrl,
      headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
    }
  }

  if (resourceType === 'Glyphs' || resourceType?.includes('Sprite')) {
    return { url: replaceUrl(url) }
  }
  return { url }
}
