import terms from 'common/terms'
import { ReactElement } from 'react'
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap'

import './badge.scss'

type Props = {
    notice?: string
    isValide?: boolean
}

const defaultProps = {
  notice: '',
  isValide: false,
}

export default function NoticeBadge({ notice, isValide }: Props): ReactElement {
  return (
    <OverlayTrigger
      overlay={isValide ? <></> : <Tooltip className="tooltip-btn" id="button-tooltip-2">{notice}</Tooltip>}
    >
      <div className="action-cursor-pointer">
        <Badge pill bg={isValide ? 'success' : 'danger'} className="badge-style">
          {isValide ? terms.Validation.success : terms.Validation.fail}
        </Badge>
      </div>
    </OverlayTrigger>
  )
}

NoticeBadge.defaultProps = defaultProps
