import { ReactElement } from 'react'
import OperationLabelInput from './OperationLabelInput'
import TypologiesChoice from './TypologiesChoice'
import FerrolocalizationFieldsContainer from './FerrolocalizationFieldsContainer'
import WorkPhasesContainer from './WorkPhasesContainer'

export default function OperationFieldsContainer(): ReactElement {
  return (
    <div className="operation-modal-body operation-fields">
      <OperationLabelInput />
      <TypologiesChoice />
      <WorkPhasesContainer />
      <FerrolocalizationFieldsContainer />
    </div>
  )
}
