import { Box, Grid, SxProps } from '@mui/material'
import { ReactElement } from 'react'

import './container.scss'

// eslint-disable-next-line react/require-default-props
export default function ContainerFooter({ content, style }: {content: ReactElement, style?: SxProps})
: ReactElement {
  return (
    <Box className="container-footer" sx={style}>
      <Grid container>
        <Grid item>
          {content}
        </Grid>
      </Grid>
    </Box>
  )
}
