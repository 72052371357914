import PortfolioService from 'components/Portfolio/PortfolioService'
import ObjectService from 'components/Objects/ObjectService'
import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import terms from 'common/terms'
import { setOverlappingPopupInfo } from 'reducers/label'
import ActionZoneLayer from './LabelLayer/ActionZoneLayer'
import LabelLayer from './LabelLayer/LabelLayer'
import OutlineLayer from './LabelLayer/OutlineLayer'
import TivLayer from './TivLayer/TivLayer'
import AdvLayer from './InfraObjectsLayer/AdvLayer'
import ManualAdvLayer from './InfraObjectsLayer/ManualAdvLayer'
import RkLayer from './InfraObjectsLayer/RkLayer'
import ManualRkLayer from './InfraObjectsLayer/ManualRkLayer'
import TeLayer from './InfraObjectsLayer/TeLayer'
import ManualTeLayer from './InfraObjectsLayer/ManualTeLayer'
import EditorLayer from './EditorLayer/EditorLayer'
import AdvLayerHover from './InfraObjectsLayer/AdvLayerHover'
import RkLayerHover from './InfraObjectsLayer/RkLayerHover'
import BvLayer from './InfraObjectsLayer/BvLayer'
import ManualBvLayer from './InfraObjectsLayer/ManualBvLayer'
import VmaxLayer from './InfraObjectsLayer/VmaxLayer'
import GvLayer from './InfraObjectsLayer/GvLayer'
import GfLayer from './InfraObjectsLayer/GfLayer'
import PanLayer from './InfraObjectsLayer/PanLayer'
import UicLayer from './InfraObjectsLayer/UicLayer'
import SecteurLayer from './InfraObjectsLayer/SecteurLayer'
import SsLayer from './InfraObjectsLayer/SsLayer'
import CssLayer from './InfraObjectsLayer/CssLayer'
import VuLayer from './InfraObjectsLayer/VuLayer'
import VbLayer from './InfraObjectsLayer/VbLayer'
import McLayer from './InfraObjectsLayer/mcLayer'
import IpcsLayer from './InfraObjectsLayer/IpcsLayer'
import TunnelLayer from './InfraObjectsLayer/TunnelLayer'
import RegionSncfLayer from './InfraObjectsLayer/RegionSncfLayer'
import RegionAdmLayer from './InfraObjectsLayer/RegionAdmLayer'
import PosteLayer from './InfraObjectsLayer/PosteLayer'
import {
  CarreLayer, CarreLayerHover, CarreLayerManual,
  CarreVLayer, CarreVLayerHover, CarreVLayerManual,
  DisqueLayer, DisqueLayerHover, DisqueLayerManual,
  GaLayer, GaLayerHover, GaLayerManual,
  SemLayer, SemLayerHover, SemLayerManual,
  AvLayer, AvLayerHover, AvLayerManual,
} from './InfraObjectsLayer/Signals/index'

export default function MapLayer(): ReactElement {
  const dispatch = useDispatch()
  const { activeProject } = useSelector((state: RootState) => state.creation)
  const { activeTab } = useSelector((state: RootState) => state.dashboard)
  const { activeBlock } = useSelector((state: RootState) => state.synoptic)
  const { importedPortfolio } = useSelector((state: RootState) => state.import)
  const {
    createdLabel, deletedLabel, updatedLabel, enterEditingMode,
  } = useSelector((state: RootState) => state.label)
  const { createdObject, deletedObject, PatchObject } = useSelector((state: RootState) => state.object)

  useEffect(() => {
    if (activeProject && activeTab.tabId === activeProject.id) {
      dispatch(ObjectService.getSignalsLayer(activeProject.id))
      dispatch(ObjectService.GetAdvLayer(activeProject.id))
      dispatch(ObjectService.GetRkLayer(activeProject.id))
      dispatch(ObjectService.GetTeLayer(activeProject.id))
      dispatch(ObjectService.GetPeLayer(activeProject.id))
      dispatch(ObjectService.GetBvLayer(activeProject.id))
      dispatch(ObjectService.GetVmaxLayer(activeProject.id))
      dispatch(ObjectService.GetGvLayer(activeProject.id))
      dispatch(ObjectService.GetGfLayer(activeProject.id))
      dispatch(ObjectService.GetPanLayer(activeProject.id))
      dispatch(ObjectService.GetUicLayer(activeProject.id))
      dispatch(ObjectService.GetSecteurLayer(activeProject.id))
      dispatch(ObjectService.GetSsLayer(activeProject.id))
      dispatch(ObjectService.GetCssLayer(activeProject.id))
      dispatch(ObjectService.GetRegionSncfLayer(activeProject.id))
      dispatch(ObjectService.GetRegionAdmLayer(activeProject.id))
      dispatch(ObjectService.GetTunnelLayer(activeProject.id))
      dispatch(ObjectService.GetMcLayer(activeProject.id))
      dispatch(ObjectService.GetPosteLayer(activeProject.id))
    }
  }, [activeProject, deletedObject, createdObject, PatchObject])

  useEffect(() => {
    if (activeProject && activeBlock === terms.Synoptic.addPortfolio) {
      dispatch(PortfolioService.getLabelsLayer(activeProject.id))
      dispatch(setOverlappingPopupInfo([]))
    }
  }, [activeProject, createdLabel, deletedLabel, updatedLabel, importedPortfolio, PatchObject])

  return (
    <>
      <TivLayer />
      <CarreLayer />
      <CarreLayerHover />
      <CarreLayerManual />

      <CarreVLayer />
      <CarreVLayerHover />
      <CarreVLayerManual />

      <DisqueLayer />
      <DisqueLayerHover />
      <DisqueLayerManual />

      <GaLayer />
      <GaLayerHover />
      <GaLayerManual />

      <SemLayer />
      <SemLayerHover />
      <SemLayerManual />

      <AvLayer />
      <AvLayerHover />
      <AvLayerManual />

      <ManualAdvLayer />
      <ManualTeLayer />
      <ManualRkLayer />
      <AdvLayer />
      <RkLayer />
      <TeLayer />
      <AdvLayerHover />
      <RkLayerHover />
      <VmaxLayer />
      <GvLayer />
      <GfLayer />
      <PanLayer />
      <UicLayer />
      <SecteurLayer />
      <SsLayer />
      <CssLayer />
      <VuLayer />
      <VbLayer />
      <IpcsLayer />
      <McLayer />
      <TunnelLayer />
      <RegionSncfLayer />
      <RegionAdmLayer />
      <PosteLayer />
      <BvLayer />
      <ManualBvLayer />
      {activeBlock === terms.Synoptic.addPortfolio && (
      <>
        <LabelLayer />
        <ActionZoneLayer />
        <OutlineLayer />
      </>
      )}
      {enterEditingMode && <EditorLayer />}
    </>
  )
}
