/* eslint-disable react/no-array-index-key */
import terms from 'common/terms'
import GenericInput from 'components/common/Inputs/GenericInput'
import GenericSelect from 'components/common/Inputs/GenericSelect'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { updateFerrolocFields } from 'reducers/MultiYearPlan/TitleBlockActions/titleblockactions'
import { getErrorMessage } from './utils'

export default function FerrolocalizationInputs():
    ReactElement {
  const dispatch = useDispatch()
  const {
    selectedTbElement, emptyFields, responseError,
  } = useSelector((state: RootState) => state.mypTitleBlockActions)
  const { lineCodes } = useSelector((state: RootState) => state.remarkablePoints)

  const [pkDebut, setPkDebut] = useState<string>('')
  const [pkFin, setPkFin] = useState<string>('')
  const [lineCode, setLineCode] = useState<string>('')

  useEffect(() => {
    const newFerroloc = {
      start_kp: pkDebut,
      end_kp: pkFin,
      line_code: lineCode,
    }
    dispatch(updateFerrolocFields(newFerroloc))
  }, [pkDebut, pkFin, lineCode])

  useEffect(() => {
    if (selectedTbElement) {
      setPkDebut(selectedTbElement.start_kp)
      setPkFin(selectedTbElement.end_kp)
      setLineCode(selectedTbElement.line_code)
    }
  }, [])

  const handleChangePkDebut = (value: string) => {
    setPkDebut(value)
  }

  const handleChangePkFin = (value: string) => {
    setPkFin(value)
  }

  const handleChangeLineCode = (value: string) => {
    setLineCode(value)
  }

  return (
    <div className="ferroloc-fields">
      <GenericSelect
        label={terms.Inputs.line}
        options={lineCodes}
        onChange={e => handleChangeLineCode(e.target.value)}
        value={lineCode}
        errorMessage={getErrorMessage(responseError, 'line_code', emptyFields, lineCode)}
        labelColor="#8A8A8A"
      />
      <GenericInput
        label={terms.Inputs.pkDebut}
        placeholder={terms.Inputs.pkPlaceholder}
        value={pkDebut}
        onChange={e => handleChangePkDebut(e.target.value)}
        errorMessage={getErrorMessage(responseError, 'start_kp', emptyFields, pkDebut)}
        labelColor="#8A8A8A"
      />
      <GenericInput
        label={terms.Inputs.pkFin}
        placeholder={terms.Inputs.pkPlaceholder}
        value={pkFin}
        onChange={e => handleChangePkFin(e.target.value)}
        errorMessage={getErrorMessage(responseError, 'end_kp', emptyFields, pkFin)}
        labelColor="#8A8A8A"
      />
    </div>
  )
}
