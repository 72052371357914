import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import terms from 'common/terms'
import { updateSelectedAttributValue } from 'reducers/MultiYearPlan/Import/import'
import AttributsChoiceContainer from './AttributsChoiceContainer'
import { ImportErrorsFields } from './types'

export default function LabelChoice(): ReactElement {
  const dispatch = useDispatch()
  const { responseError } = useSelector((state: RootState) => state.mypImport)

  return (
    <AttributsChoiceContainer
      chipLabel={terms.MultiYearPlan.Import.label}
      placeholder={terms.MultiYearPlan.Import.labelPlaceholder}
      onChange={(_event, newValue) => dispatch(updateSelectedAttributValue(newValue))}
      errorMessage={responseError && responseError.message[ImportErrorsFields.LabelError]
        ? responseError.message[ImportErrorsFields.LabelError] : ''}
    />
  )
}
