import { ReactElement } from 'react'
import { Source, Layer } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { FiltersCategories, ObjectsLayer } from 'components/Map/ObjectsLayers/types'
import { getFilterCheckStatus, getLayerVisibility } from '../utils'

export default function SsLayer(): ReactElement {
  const { ssLayerData, filtersList } = useSelector((state: RootState) => state.object)

  return (
    <>
      <Source type="geojson" data={ssLayerData} id={ObjectsLayer.ss}>
        <Layer
          id={`${ObjectsLayer.ss}`}
          filter={['==', 'auto_added', true]}
          type="symbol"
          paint={{
            'icon-color': '#FFFFFF',
          }}
          layout={{
            'text-size': 12,
            'text-font': ['Roboto Condensed'],
            'text-allow-overlap': false,
            'text-letter-spacing': 0.05,
            'text-field': [
              'format',
              'SST',
              {
                'font-scale': 1.2,
              },
              '\n',
              {},
              ['get', 'description'],
              {
                'font-scale': 1,
              },
            ],
            'icon-image': 'simple_rectangle',
            'icon-text-fit': 'both',
            'icon-text-fit-padding': [15, 15, 15, 15],
            visibility: getLayerVisibility(filtersList, FiltersCategories.Te, ObjectsLayer.ss,
              getFilterCheckStatus(filtersList, FiltersCategories.General, ObjectsLayer.gaia)),
          }}
        />
      </Source>
    </>
  )
}
