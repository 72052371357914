/* eslint-disable max-len */
import { ReactElement, useEffect } from 'react'
import { Grid } from '@mui/material'
import { RootState } from 'Store'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { DefectedOperation } from 'reducers/MultiYearPlan/Library/types'
import { fixDefectedOperation } from 'reducers/MultiYearPlan/Library/library.thunk'
import DefectedOperationCard from './DefectedOperationCard'

export default function DefectedOperationsList(): ReactElement {
  const dispatch = useDispatch()
  const { defectedOperations, selectedDefectedOperation } = useSelector((state: RootState) => state.mypLibrary)
  const { activeFile } = useSelector((state: RootState) => state.files)

  useEffect(() => {
    if (selectedDefectedOperation) {
      const formated = _.cloneDeep(selectedDefectedOperation)
      formated.defected_railway_locations.forEach((item: any) => {
        if (item.end_kp === '') { item.end_kp = null }
        if (item.start_kp === '') { item.start_kp = null }
      })
      dispatch(fixDefectedOperation({
        repertoryId: activeFile.Directory,
        folderId: activeFile.folder,
        fileId: activeFile.id,
        operation: {
          id: selectedDefectedOperation.id,
          label: selectedDefectedOperation.label,
          sub_classification: selectedDefectedOperation.sub_classification
            ? selectedDefectedOperation.sub_classification.id : null,
          railway_locations: formated.defected_railway_locations,
          years: selectedDefectedOperation.defected_years,
        },
      }))
    }
  }, [selectedDefectedOperation])
  return (
    <>
      <Grid
        item
        container
        spacing={1}
        className="operation-card-container"
      >
        {defectedOperations.map((operation: DefectedOperation) => (
          <Grid item key={operation.id} sx={{ justifyContent: 'center' }}>
            <DefectedOperationCard operation={operation} />
          </Grid>
        ))}
      </Grid>
    </>
  )
}
