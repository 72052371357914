import { ReactElement, useState } from 'react'
import { useDispatch } from 'react-redux'
import terms from 'common/terms'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { toggleDisplayDeleteModal, updateContextRepertoryId } from 'reducers/Repertories/repertories'
import {
  Button, IconButton, Menu, MenuItem,
} from '@mui/material'

export default function ActionMenu({ repertoryId }: {repertoryId: number}): ReactElement {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const dispatch = useDispatch()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onClick = () => {
    dispatch(toggleDisplayDeleteModal(true))
    dispatch(updateContextRepertoryId(repertoryId))
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem style={{ fontSize: '12px' }}>
          <Button
            className="actions-menu-btn"
            onClick={onClick}
          >
            {terms.Repertory.deleteRepertory}
          </Button>
        </MenuItem>
      </Menu>
    </>
  )
}
