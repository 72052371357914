import { ReactElement, useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import filterIcon from 'assets/icons/filter.svg'
import { IoIosClose } from 'react-icons/io'
import { Badge, Divider } from '@mui/material'
import { RootState } from 'Store'
import { useDispatch, useSelector } from 'react-redux'
import { updateFilters, updateLibraryLabel, updateSearchValues } from 'reducers/library'
import terms from 'common/terms'
import FiltersFields from './FiltersFields'
import AddFiltersButton from '../Buttons/Library/AddFiltersButton'
import ApplyFiltersButton from '../Buttons/Library/ApplyFiltersButton'
import AddFilterInput from './AddFilterInput'

import './library.scss'
import { getAppliedFilters } from './utils'

export default function FiltersModal(): ReactElement {
  const dispatch = useDispatch()
  const [show, setShow] = useState<boolean>(false)
  const [appliedFilters, setAppliedFilters] = useState<number>(0)
  const { addFilter } = useSelector((state: RootState) => state.library)
  const { importedPortfolio } = useSelector((state: RootState) => state.import)
  const { importedLabels } = useSelector((state: RootState) => state.library)

  useEffect(() => {
    if (importedPortfolio) {
      setAppliedFilters(getAppliedFilters(importedPortfolio))
    }
  }, [importedPortfolio])

  const handleOpen = () => {
    setShow(true)
    dispatch(updateLibraryLabel(undefined))
  }
  const handleClose = () => {
    setShow(false)
    dispatch(updateSearchValues([]))
    dispatch(updateFilters({}))
  }

  return (
    <>
      <div className="d-flex justify-content-end pr-3 pb-1">
        <Badge
          badgeContent={appliedFilters !== 0 ? appliedFilters : undefined}
          color="error"
        >
          <Button
            className="filter-btn"
            onClick={handleOpen}
            disabled={importedLabels.length === 0 && appliedFilters === 0}
          >
            <img src={filterIcon} alt="" />
          </Button>
        </Badge>
      </div>
      <Modal
        show={show}
        animation
        centered
        backdrop="static"
        keyboard={false}
        className={`filters-modal ${addFilter ? ' add-filter-modal' : ''}`}
      >
        <Modal.Header>
          <Modal.Title>
            {terms.Portfolio.Library.Filters.modalHeader}
          </Modal.Title>
          <IoIosClose className="d-flex justify-content-end action-cursor-pointer" onClick={handleClose} />
        </Modal.Header>
        <Divider orientation="horizontal" className="divider" sx={{ m: 0.5, color: '#E6EAEE', margin: '4px 25px' }} />
        <Modal.Body>
          <FiltersFields />
        </Modal.Body>
        <Modal.Footer className="mt-3 d-flex justify-content-center flex-row">
          {addFilter && <AddFilterInput />}
          <AddFiltersButton />
          <ApplyFiltersButton handleClose={handleClose} />
        </Modal.Footer>
      </Modal>
    </>
  )
}
