import { ReactElement } from 'react'
import ExportPdf from './ExportPdf'
import ExportSvg from './ExportSvg'

import './exportBtn.scss'

export default function ExportActionsButtons(): ReactElement {
  return (
    <div className="export-btn-container">
      <ExportSvg />
      <ExportPdf />
    </div>
  )
}
