/* eslint-disable react/no-array-index-key */
import {
  ChangeEvent, ReactElement, useEffect, useState,
} from 'react'
import terms from 'common/terms'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { Grid } from '@mui/material'
import CreationService from 'components/Dashboard/Creation/CreationService'
import { updateEmptyFields } from 'reducers/creation'
import { FileTypes } from 'reducers/Files/types'
import { updateMypEmptyFields } from 'reducers/MultiYearPlan/multiYearPlan'
import { declareFilesSection } from 'reducers/MultiYearPlan/multiYearPlan.thunk'
import { DirectionSelectOptions } from './GeographicPerimeterInputFields'

import './geographicPerimeter.scss'
import GeographicPerimeterFields from './GeographicPerimeterFields'

export default function GeographicPerimeterInputs(): ReactElement {
  const dispatch = useDispatch()
  const { activeFile } = useSelector((state: RootState) => state.files)
  const { mypSectionsList, updatedMypSection } = useSelector((state: RootState) => state.multiYearPlan)
  const { updatedSection, sectionsList } = useSelector((state: RootState) => state.creation)
  const [sectionName, setSectionName] = useState('')
  const [lineCode, setLineCode] = useState('')
  const [pkDebut, setPkDebut] = useState('')
  const [pkFin, setPkFin] = useState('')
  const [step, setStep] = useState('1')
  const [direction, setDirection] = useState(terms.Inputs.increment)
  const hasError = sectionName === '' || lineCode === '' || pkDebut === '' || pkFin === ''
  const mypHasError = sectionName === '' || lineCode === '' || pkDebut === '' || pkFin === '' || step === ''

  useEffect(() => {
    if (updatedSection === undefined) {
      setSectionName('')
      setLineCode('')
      setPkDebut('')
      setPkFin('')
    }
    if (updatedSection) {
      setSectionName(updatedSection.name)
      setLineCode(updatedSection.code_ligne)
      setPkDebut(updatedSection.pk_sncf_debut)
      setPkFin(updatedSection.pk_sncf_fin)
    }
  }, [sectionsList, updatedSection])

  useEffect(() => {
    if (updatedMypSection === undefined) {
      setSectionName('')
      setLineCode('')
      setPkDebut('')
      setPkFin('')
      setStep('1')
      setDirection(terms.Inputs.increment)
    }
    if (updatedMypSection) {
      setSectionName(updatedMypSection.name)
      setLineCode(updatedMypSection.code_ligne)
      setPkDebut(updatedMypSection.pk_sncf_debut)
      setPkFin(updatedMypSection.pk_sncf_fin)
      setStep(updatedMypSection.step)
      setDirection(DirectionSelectOptions.filter(d => d.key === updatedMypSection.direction)[0].value)
    }
  }, [mypSectionsList, updatedMypSection])

  const getInputValue = (fieldLabel: string) => {
    switch (fieldLabel) {
      case terms.Inputs.sectionName:
        return sectionName
      case terms.Inputs.lineNumber:
        return lineCode
      case terms.Inputs.pkDebut:
        return pkDebut
      case terms.Inputs.step:
        return step
      case terms.Inputs.direction:
        return direction
      default:
        return pkFin
    }
  }

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, fieldLabel: string) => {
    if (fieldLabel !== terms.Inputs.direction) {
      switch (fieldLabel) {
        case terms.Inputs.sectionName:
          setSectionName(e.target.value)
          break
        case terms.Inputs.lineNumber:
          if (!Number.isNaN(+e.target.value) && e.target.value.length <= 6 && !/ /.test(e.target.value)) {
            setLineCode(e.target.value)
          }
          break
        case terms.Inputs.pkDebut:
          setPkDebut(e.target.value)
          break
        case terms.Inputs.step:
          if (!Number.isNaN(+e.target.value) && e.target.value !== '0') {
            setStep(e.target.value)
          }
          break
        default:
          setPkFin(e.target.value)
          break
      }
    }
  }

  const addSection = () => {
    if (activeFile.type === FileTypes.Synoptic && hasError) {
      dispatch(updateEmptyFields(true))
    } else if (activeFile.type === FileTypes.Schema && mypHasError) {
      dispatch(updateMypEmptyFields(true))
    } else if (activeFile.type === FileTypes.Synoptic) {
      const declarationParams = {
        projectId: activeFile?.id,
        newSection: {
          name: sectionName,
          code_ligne: lineCode,
          pk_sncf_debut: pkDebut,
          pk_sncf_fin: pkFin,
        },
      }
      dispatch(CreationService.declareSection(declarationParams))
    } else {
      const declarationParams = {
        repertoryId: activeFile.directory,
        folderId: activeFile.folder,
        fileId: activeFile.id,
        section: {
          name: sectionName,
          code_ligne: lineCode,
          pk_sncf_debut: pkDebut,
          pk_sncf_fin: pkFin,
          step: +step,
          direction: DirectionSelectOptions.filter(d => d.value === direction)[0].key,
        },
      }
      dispatch(declareFilesSection(declarationParams))
    }
  }

  return (
    <Grid item width={400} mt={5} mb={10} className="inputs-container">
      <GeographicPerimeterFields
        direction={direction}
        setDirection={setDirection}
        getInputValue={getInputValue}
        handleChange={handleChange}
        addSection={addSection}
      />
    </Grid>
  )
}
