import PathNames from 'common/PathNames'
import terms from 'common/terms'
import ActionButton from 'components/common/Buttons/ActionButton'
import PortfolioService from 'components/Portfolio/PortfolioService'
import history from 'customHistory'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { reset, setActiveStep } from 'reducers/import'
import { RootState } from 'Store'

export default function GenerateLabelsButton(): ReactElement {
  const dispatch = useDispatch()
  const { showImportLoader } = useSelector((state: RootState) => state.import)
  const { activeProject } = useSelector((state: RootState) => state.creation)
  const { activeFile } = useSelector((state: RootState) => state.files)

  const handleClick = () => {
    dispatch(setActiveStep(-1))
    dispatch(reset())
    dispatch(PortfolioService.getLabelsLayer(activeProject.id))
    if (activeFile?.folder !== null) {
      history.push(`${PathNames.RepertoryContent}/${activeFile.directory}/${PathNames.Folder}/${activeFile.folder}`)
    } else {
      history.push(`${PathNames.RepertoryContent}/${activeFile.directory}`)
    }
  }

  return (
    <>
      {!showImportLoader && (
        <ActionButton
          text={terms.Portfolio.Import.Buttons.continue}
          onClick={handleClick}
          rightIcon
          buttonStyle="start-btn"
        />
      ) }
    </>
  )
}
