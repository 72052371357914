import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import LibraryService from 'components/Portfolio/Library/LibraryService'
import { GetLabelsResponse, Libraries, WorkPhaseFields } from 'components/Portfolio/Library/types'
import { LabelData, SearchFields } from 'components/Portfolio/types'

interface LibraryState {
    displayLibrary: boolean
    importedLabels: Array<LabelData>
    createdLabels: Array<LabelData>
    notPositionedLabels: Array<LabelData>
    importedLabelsPage: number
    importedLabelsCount: number
    createdLabelsPage: number
    createdLabelsCount: number
    notPositionedLabelsPage: number
    notPositionedLabelsCount: number
    filters: any
    addFilter: boolean
    searchValues: SearchFields[]
    workPhases?: WorkPhaseFields[]
    libraryLabel?: GeoJSON.Feature
    applyFilters: boolean
}

const initialState: LibraryState = {
  displayLibrary: false,
  importedLabels: [],
  createdLabels: [],
  notPositionedLabels: [],
  importedLabelsPage: 1,
  importedLabelsCount: 0,
  createdLabelsPage: 1,
  createdLabelsCount: 0,
  notPositionedLabelsPage: 1,
  notPositionedLabelsCount: 0,
  filters: {},
  addFilter: false,
  searchValues: [],
  workPhases: [],
  applyFilters: false,
}

const getLabelsList = (state: LibraryState, action: PayloadAction<GetLabelsResponse>) => {
  switch (action.payload.library) {
    case Libraries.Positioned:
      if (state.importedLabels.length !== state.importedLabelsCount) {
        state.importedLabels = action.payload.labelsList.concat(state.importedLabels)
      } else {
        state.importedLabels = action.payload.labelsList
      }
      state.importedLabelsPage = action.payload.page
      state.importedLabelsCount = action.payload.count
      break
    case Libraries.Manual:
      if (state.createdLabels.length !== state.createdLabelsCount) {
        state.createdLabels = action.payload.labelsList.concat(state.createdLabels)
      } else {
        state.createdLabels = action.payload.labelsList
      }
      state.createdLabelsPage = action.payload.page
      state.createdLabelsCount = action.payload.count
      break
    default:
      if (state.notPositionedLabels.length !== state.notPositionedLabelsCount) {
        state.notPositionedLabels = action.payload.labelsList.concat(state.notPositionedLabels)
      } else {
        state.notPositionedLabels = action.payload.labelsList
      }
      state.notPositionedLabelsPage = action.payload.page
      state.notPositionedLabelsCount = action.payload.count
  }
}

export const librarySlice = createSlice({
  name: 'library',
  initialState,
  reducers: {
    reset: () => initialState,
    toggleDisplayLibrary: (state, action: PayloadAction<boolean>) => {
      state.displayLibrary = action.payload
    },
    updateFilters: (state, action: PayloadAction<any>) => {
      state.filters = action.payload
    },
    toggleAddFilter: (state, action: PayloadAction<boolean>) => {
      state.addFilter = action.payload
    },
    updateSearchValues: (state, action: PayloadAction<SearchFields[]>) => {
      state.searchValues = action.payload
    },
    updateWorkPhases: (state, action: PayloadAction<WorkPhaseFields[] | undefined>) => {
      state.workPhases = action.payload
    },
    updateLibraryLabel: (state, action: PayloadAction<GeoJSON.Feature | undefined>) => {
      state.libraryLabel = action.payload
    },
    updateCount: state => {
      state.importedLabelsCount = 0
      state.notPositionedLabelsCount = 0
      state.createdLabelsCount = 0
    },
    updatePages: state => {
      state.importedLabelsPage = 1
      state.notPositionedLabelsPage = 1
      state.createdLabelsPage = 1
    },
    updateLabelsLists: state => {
      state.importedLabels = []
      state.notPositionedLabels = []
      state.createdLabels = []
    },
    updateApplyFilters: (state, action: PayloadAction<boolean>) => {
      state.applyFilters = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(LibraryService.getLabels.fulfilled, getLabelsList)
  },
})

export const {
  reset, toggleDisplayLibrary, updateFilters, toggleAddFilter, updateSearchValues, updateWorkPhases, updateLibraryLabel,
  updateCount, updatePages, updateLabelsLists, updateApplyFilters,
} = librarySlice.actions

export default librarySlice.reducer
