import {
  get, post, patch, deleteRequest,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Filters } from 'components/Map/ToolBar/types'
import { ThunkApi } from 'types'
import { ObjectInfraCategory } from '../Synoptic/types'

type QueryParams = any

const getObjectsList = createAsyncThunk<Array<ObjectInfraCategory>, number, ThunkApi>(
  'objects/getObjectsList',
  async (projectId: number, thunkApi) => {
    try {
      const resp = await get(`/module_schematique/projects/${projectId}/list_layers/`)
      return resp
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const getFilterList = createAsyncThunk<Filters[], number, ThunkApi>(
  'objects/getFilterList',
  async (projectId: number, thunkApi) => {
    try {
      const resp = await get(`/module_schematique/projects/${projectId}/filters/`)
      return resp
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const patchFilterList = createAsyncThunk<Filters[], QueryParams, ThunkApi>(
  'objects/patchFilterList',
  async (params: QueryParams, thunkApi) => {
    try {
      const uri = 'module_schematique/projects'
      const response = await
      patch(`/${uri}/${params.projectId}/filters/`,
        params.newFilter)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const createObject = createAsyncThunk<any, QueryParams, ThunkApi>(
  'objects/CreateObject',
  async (params: QueryParams, thunkApi) => {
    try {
      const response = await
      post(`/module_schematique/projects/${params.projectId}/objects/${params.layer}/`,
        params.params)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const patchObject = createAsyncThunk<any, QueryParams, ThunkApi>(
  'objects/PatchObject',
  async (params: QueryParams, thunkApi) => {
    try {
      const uri = 'module_schematique/projects'
      const response = await
      patch(`/${uri}/${params.projectId}/objects/${params.layer}/${params.objectId}/`,
        params.params)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const patchLabel = createAsyncThunk<any, QueryParams, ThunkApi>(
  'objects/PatchObject',
  async (params: QueryParams, thunkApi) => {
    try {
      const uri = 'module_schematique/projects'
      const response = await
      patch(`/${uri}/${params.projectId}/${params.layer}/${params.objectId}/`,
        params.params)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

type DeleteParams = {
  projectId: number
  objectId: number
  layer: string
}

const deleteObject = createAsyncThunk<number, DeleteParams, ThunkApi>(
  'objects/deleteObject',
  async (params: DeleteParams, thunkApi) => {
    try {
      const uri = 'module_schematique/projects'
      await deleteRequest(`/${uri}/${params.projectId}/objects/${params.layer}/${params.objectId}`)
      return params.objectId
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const getSignalsLayer = createAsyncThunk<any, number, ThunkApi>(
  'objects/getSignalsLayer',
  async (projectId: number, thunkApi) => {
    try {
      const resp = await
      get('/chartis/v2/layer/module_schematique_infra_signal/geojson/sch/',
        { project_id: projectId, page_size: 200000 })
      return resp
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const GetAdvLayer = createAsyncThunk<any, number, ThunkApi>(
  'objects/GetAdvLayer',
  async (projectId: number, thunkApi) => {
    try {
      const resp = await
      get('/chartis/v2/layer/module_schematique_infra_adv/geojson/sch/',
        { project_id: projectId, page_size: 200000 })
      return resp
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const GetRkLayer = createAsyncThunk<any, number, ThunkApi>(
  'objects/GetRkLayer',
  async (projectId: number, thunkApi) => {
    try {
      const resp = await
      get('/chartis/v2/layer/module_schematique_infra_rk/geojson/sch/',
        { project_id: projectId, page_size: 200000 })
      return resp
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const GetBvLayer = createAsyncThunk<any, number, ThunkApi>(
  'objects/GetBvLayer',
  async (projectId: number, thunkApi) => {
    try {
      const resp = await
      get('/chartis/v2/layer/module_schematique_infra_bv/geojson/sch/',
        { project_id: projectId, page_size: 200000 })
      return resp
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const GetVmaxLayer = createAsyncThunk<any, number, ThunkApi>(
  'objects/GetVmaxLayer',
  async (projectId: number, thunkApi) => {
    try {
      const resp = await
      get('/chartis/v2/layer/module_schematique_infra_vm/geojson/sch/',
        { project_id: projectId, page_size: 200000 })
      return resp
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const GetGvLayer = createAsyncThunk<any, number, ThunkApi>(
  'objects/GetGvLayer',
  async (projectId: number, thunkApi) => {
    try {
      const resp = await
      get('/chartis/v2/layer/module_schematique_infra_gv/geojson/sch/',
        { project_id: projectId, page_size: 200000 })
      return resp
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const GetGfLayer = createAsyncThunk<any, number, ThunkApi>(
  'objects/GetGfLayer',
  async (projectId: number, thunkApi) => {
    try {
      const resp = await
      get('/chartis/v2/layer/module_schematique_infra_gf/geojson/sch/',
        { project_id: projectId, page_size: 200000 })
      return resp
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const GetPanLayer = createAsyncThunk<any, number, ThunkApi>(
  'objects/GetPanLayer',
  async (projectId: number, thunkApi) => {
    try {
      const resp = await
      get('/chartis/v2/layer/module_schematique_infra_pan/geojson/sch/',
        { project_id: projectId, page_size: 200000 })
      return resp
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const GetUicLayer = createAsyncThunk<any, number, ThunkApi>(
  'objects/GetUicLayer',
  async (projectId: number, thunkApi) => {
    try {
      const resp = await
      get('/chartis/v2/layer/module_schematique_infra_groupe_uic/geojson/sch/',
        { project_id: projectId, page_size: 200000 })
      return resp
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const GetSecteurLayer = createAsyncThunk<any, number, ThunkApi>(
  'objects/GetSecteurLayer',
  async (projectId: number, thunkApi) => {
    try {
      const resp = await
      get('/chartis/v2/layer/module_schematique_infra_secteur/geojson/sch/',
        { project_id: projectId, page_size: 200000 })
      return resp
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const GetSsLayer = createAsyncThunk<any, number, ThunkApi>(
  'objects/GetSsLayer',
  async (projectId: number, thunkApi) => {
    try {
      const resp = await
      get('/chartis/v2/layer/module_schematique_infra_ss/geojson/sch/',
        { project_id: projectId, page_size: 200000 })
      return resp
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const GetCssLayer = createAsyncThunk<any, number, ThunkApi>(
  'objects/GetCssLayer',
  async (projectId: number, thunkApi) => {
    try {
      const resp = await
      get('/chartis/v2/layer/module_schematique_infra_css/geojson/sch/',
        { project_id: projectId, page_size: 200000 })
      return resp
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const GetRegionSncfLayer = createAsyncThunk<any, number, ThunkApi>(
  'objects/GetRegionSncfLayer',
  async (projectId: number, thunkApi) => {
    try {
      const resp = await
      get('/chartis/v2/layer/module_schematique_infra_region_sncf/geojson/sch/',
        { project_id: projectId, page_size: 200000 })
      return resp
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const GetRegionAdmLayer = createAsyncThunk<any, number, ThunkApi>(
  'objects/GetRegionAdmLayer',
  async (projectId: number, thunkApi) => {
    try {
      const resp = await
      get('/chartis/v2/layer/module_schematique_infra_region_adm/geojson/sch/',
        { project_id: projectId, page_size: 200000 })
      return resp
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const GetTunnelLayer = createAsyncThunk<any, number, ThunkApi>(
  'objects/GetTunnelLayer',
  async (projectId: number, thunkApi) => {
    try {
      const resp = await
      get('/chartis/v2/layer/module_schematique_infra_tunnel/geojson/sch/',
        { project_id: projectId, page_size: 200000 })
      return resp
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const GetMcLayer = createAsyncThunk<any, number, ThunkApi>(
  'objects/GetMcLayer',
  async (projectId: number, thunkApi) => {
    try {
      const resp = await
      get('/chartis/v2/layer/module_schematique_infra_mc/geojson/sch/',
        { project_id: projectId, page_size: 200000 })
      return resp
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const GetPosteLayer = createAsyncThunk<any, number, ThunkApi>(
  'objects/GetPosteLayer',
  async (projectId: number, thunkApi) => {
    try {
      const resp = await
      get('/chartis/v2/layer/module_schematique_infra_poste/geojson/sch/',
        { project_id: projectId, page_size: 200000 })
      return resp
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const GetTeLayer = createAsyncThunk<any, number, ThunkApi>(
  'objects/GetTeLayer',
  async (projectId: number, thunkApi) => {
    try {
      const resp = await
      get('/chartis/v2/layer/module_schematique_infra_te/geojson/sch/',
        { project_id: projectId, page_size: 200000 })
      return resp
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const GetPeLayer = createAsyncThunk<any, number, ThunkApi>(
  'objects/GetPeLayer',
  async (projectId: number, thunkApi) => {
    try {
      const resp = await
      get('/chartis/v2/layer/module_schematique_infra_pe/geojson/sch/',
        { project_id: projectId, page_size: 200000 })
      return resp
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const GetTivLayer = createAsyncThunk<any, number, ThunkApi>(
  'objects/GetTivLayer',
  async (projectId: number, thunkApi) => {
    try {
      const resp = await
      get('/chartis/v2/layer/module_schematique_tiv/mvt/sch/',
        { project_id: projectId })
      return resp
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const getLayersStatus = createAsyncThunk<any, number, ThunkApi>(
  'objects/getLayersStatus',
  async (projectId: number, thunkApi) => {
    try {
      const layers = await get(`/module_schematique/projects/${projectId}/`)
      return layers
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const ObjectService = {
  getObjectsList,
  getFilterList,
  createObject,
  getSignalsLayer,
  patchObject,
  patchLabel,
  deleteObject,
  GetAdvLayer,
  GetRkLayer,
  GetTeLayer,
  GetPeLayer,
  GetBvLayer,
  getLayersStatus,
  patchFilterList,
  GetTivLayer,
  GetVmaxLayer,
  GetGvLayer,
  GetGfLayer,
  GetPanLayer,
  GetUicLayer,
  GetSecteurLayer,
  GetSsLayer,
  GetCssLayer,
  GetRegionSncfLayer,
  GetRegionAdmLayer,
  GetTunnelLayer,
  GetMcLayer,
  GetPosteLayer,
}

export default ObjectService
