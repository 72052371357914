import { ReactElement } from 'react'
import { Button, Typography } from '@mui/material'
import terms from 'common/terms'
import { useDispatch, useSelector } from 'react-redux'
import { toggleDisplayLibraryPanel } from 'reducers/MultiYearPlan/Library/library'
import { RootState } from 'Store'

export default function LibrarySettings(): ReactElement {
  const dispatch = useDispatch()
  const { defectedOperations, positionedOperations } = useSelector((state: RootState) => state.mypLibrary)

  return (
    <>
      <Typography className="category-title">{terms.MultiYearPlan.SettingsPanel.library}</Typography>
      <Button onClick={() => dispatch(toggleDisplayLibraryPanel(true))} className="library-setting-btn">
        {terms.MultiYearPlan.SettingsPanel.objectsLibrary}
        <Typography>
          {`(${defectedOperations.length + positionedOperations.length})`}
        </Typography>
      </Button>
    </>
  )
}
