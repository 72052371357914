import { ReactElement, useEffect, useState } from 'react'
import emptyCardBg from 'assets/images/empty-card-bg.png'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import axios from 'axios'
import {
  Box, Button, Fade, Grid, Typography,
} from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'
import terms from 'common/terms'
import history from 'customHistory'
import PathNames from 'common/PathNames'
import { getAuthConfig } from './utils'

export default function RepertoriesContentBanner(): ReactElement {
  const { activeRepertory } = useSelector((state: RootState) => state.repertories)
  const [imageUrl, setImageUrl] = useState(emptyCardBg)

  useEffect(() => {
    if (activeRepertory.picture_url !== null) {
      const baseURL = activeRepertory.picture_url
      const config = getAuthConfig()
      axios.get(baseURL, config)
        .then(response => {
          setImageUrl(response.data)
        })
        .catch(error => {
          if (error.response) {
            setImageUrl(error.response.data)
          }
        })
    }
  }, [])

  return (
    <Fade in timeout={500}>
      <Box className="repertory-banner" sx={{ backgroundImage: `url(${imageUrl})` }}>
        <Grid container>
          <Grid item xs={2} sm={6} md={12} mt={3}>
            <div className="w-100 d-flex flex-row-reverse">
              <Button
                variant="contained"
                startIcon={<SettingsIcon />}
                onClick={() => history.push(PathNames.Repertory)}
              >
                {terms.Repertory.repertorySetting}
              </Button>
            </div>
            <Typography>
              {activeRepertory.label}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Fade>
  )
}
