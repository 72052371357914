const PathNames = {
  Home: '/',
  Repertory: '/declaration-repertoire',
  Picture: '/configuration-image',
  PicturePreviz: '/configuration-image-previsualisation',
  RepertoryContent: '/repertoire',
  Folder: 'dossier',
  FileSchema: 'scheme-pluriannuel',
  FileSynoptic: 'synoptique',
  TemporalPerimeter: 'perimetre-temporel',
  GeographicPerimeter: 'perimetre-geographique',
  Import: '/importer-portefeuille',
  Export: '/exporter',
  MypImport: '/scheme-pluriannuel/importer-portefeuille',
}

export default PathNames
