import { LabelData } from '../types'

export type GetLabelsParams = {
    projectId: number,
    library: string,
    page: number
}

export type GetLabelsResponse = {
    labelsList: Array<LabelData>,
    library: string,
    page: number,
    count: number
}

export enum Libraries {
    Positioned = 'positioned',
    Manual = 'manual',
    NotPositioned = 'not_positioned'
}

export type Library = {
    category: string,
    values: Array<GeoJSON.Feature>,
    count: number
}

export type FilterValue = {
    [key: string]: Array<string>,
}

export type WorkPhaseFields = {
    index: number,
    phase: FilterValue[],
    appliedFilter: boolean,
}
