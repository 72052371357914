import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import GenericTopBar from 'components/common/TopBar/GenericTopBar'
import {
  reset, setActiveStep, toggleFerrolocalizationNoticeDisplay, toggleSettingsNoticeDisplay,
  updateResponseError,
  updateSearchValues, updateUploadedPorfolio,
} from 'reducers/import'
import { RootState } from 'Store'
import ContentContainer from 'components/common/Container/ContentContainer'
import CustomStepper from 'components/common/Stepper/CustomStepper'
import BigLoader from 'components/common/BigLoader/BigLoader'
import {
  updateCount, updateFilters, updateLabelsLists, updatePages, updateWorkPhases,
} from 'reducers/library'
import terms from 'common/terms'
import history from 'customHistory'
import PathNames from 'common/PathNames'
import IMPORT_STEPS from './ImportSteps'
import StepsButtons from './StepsContent/StepsButtons'
import { getNotice, getTitle } from './StepsContent/utils'
import StepsContent from './StepsContent/StepsContent'
import SettingsChoice from './StepsContent/SettingsChoice'
import ImportService from './ImportService'

export default function ImportInstructions(): ReactElement {
  const dispatch = useDispatch()
  const {
    activeStep, settingsNoticeDisplay, ferrolocalizationNoticeDisplay, showImportLoader, portfolio,
  } = useSelector((state: RootState) => state.import)
  const { activeProject } = useSelector((state: RootState) => state.creation)
  const { activeFile } = useSelector((state: RootState) => state.files)

  useEffect(() => {
    if (portfolio && portfolio.code_ligne !== ''
     && Object.values(portfolio.filters).filter((v: any) => v.length !== 0).length === 0) {
      dispatch(ImportService.generatePortfolio(activeProject.id))
      dispatch(updateCount())
      dispatch(updatePages())
      dispatch(updateLabelsLists())
      dispatch(updateWorkPhases([]))
      dispatch(updateFilters({}))
    }
  }, [portfolio])

  const onReturn = () => {
    if (activeStep === -1) {
      dispatch(reset())
      if (activeFile?.folder !== null) {
        history.push(`${PathNames.RepertoryContent}/${activeFile.directory}/${PathNames.Folder}/${activeFile.folder}`)
      } else {
        history.push(`${PathNames.RepertoryContent}/${activeFile.directory}`)
      }
    } else {
      dispatch(setActiveStep(activeStep - 1))
      dispatch(updateSearchValues([]))
      dispatch(updateResponseError(undefined))
      if (activeStep === 0) dispatch(updateUploadedPorfolio(undefined))
      if (activeStep === 1) {
        dispatch(toggleSettingsNoticeDisplay(true))
        dispatch(updateUploadedPorfolio(undefined))
      }
      if (activeStep === 2) dispatch(toggleFerrolocalizationNoticeDisplay(true))
    }
  }

  const getPreviousStep = () => (activeStep === -1 ? terms.Portfolio.Import.backToSynoptic
    : terms.Portfolio.Import.backToPreviousStep)

  const onClose = () => {
    dispatch(reset())
    if (activeFile?.folder !== null) {
      history.push(`${PathNames.RepertoryContent}/${activeFile.directory}/${PathNames.Folder}/${activeFile.folder}`)
    } else {
      history.push(`${PathNames.RepertoryContent}/${activeFile.directory}`)
    }
  }

  const contentNotice = showImportLoader ? (
    <div className="d-flex flex-column">
      <div className="spinner"><BigLoader /></div>
      {getNotice()}
    </div>
  ) : getNotice()

  return (
    <div className="container-view">
      <GenericTopBar
        onStepBack={onReturn}
        getPreviousStep={getPreviousStep}
        onClose={onClose}
        stepper={activeStep !== -1 ? <CustomStepper steps={IMPORT_STEPS} /> : <></>}
      />
      <ContentContainer
        fillContent={settingsNoticeDisplay || ferrolocalizationNoticeDisplay}
        extraStyle={!settingsNoticeDisplay ? 'settings-content-container' : ''}
        title={getTitle()}
        notice={contentNotice}
        content={<StepsContent />}
        contentInputStyle="steps-content"
      />
      {!settingsNoticeDisplay && <SettingsChoice />}
      <div className="d-flex justify-content-center">
        <StepsButtons />
      </div>
    </div>
  )
}
