import { setSelectedFeatures, setSelectedPoints, updateMode } from 'components/GeoEditor/reducer'
import { store } from 'Store'

import { FeatureCollection } from '@nebula.gl/edit-modes'
import turfBooleanPointInPolygon from '@turf/boolean-point-in-polygon'
import turfExplode from '@turf/explode'
import { AllGeoJSON, polygon } from '@turf/helpers'

import { SelectedPoints } from '../EditionLayer/CustomModifyMode'
import { Modes, updateCursor } from '../utils'
import { SelectParams } from './utils'

const onSelectObjects = (geoJson: FeatureCollection, { coordinates }: SelectParams): void => {
  if (coordinates.length !== 0) {
    const selectedFeatureCollection = geoJson
    const selectedPolygon = polygon(coordinates)
    let pointsInSelection = 0
    const selectedPointsCoords: SelectedPoints = selectedFeatureCollection.features.map((f, index) => {
      const explodedFeature = turfExplode(f as AllGeoJSON)
      const filteredPoints = explodedFeature.features.map(p => {
        const tempCoords = turfBooleanPointInPolygon(p.geometry.coordinates, selectedPolygon)
          ? p.geometry.coordinates as [number, number]
          : undefined
        if (tempCoords !== undefined) {
          pointsInSelection += 1
        }
        return tempCoords
      })
      return {
        featureIndex: index,
        points: filteredPoints,
      }
    }).filter(pc => {
      const uniqueValues = [...new Set(pc.points)]
      return uniqueValues.length !== 1 || !uniqueValues.includes(undefined)
    })

    store.dispatch(setSelectedFeatures(selectedPointsCoords.map(pc => pc.featureIndex)))
    store.dispatch(setSelectedPoints(pointsInSelection > 1 ? selectedPointsCoords : undefined))
    store.dispatch(updateMode(Modes.draw))
    updateCursor(Modes.draw)
  }
}

export default onSelectObjects
