import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { Typography } from '@mui/material'
import terms from 'common/terms'
import LibraryPanelHeader from './LibraryPanelHeader'
import LibrarySearch from './LibrarySearch'
import OperationsAccordion from './OperationsAccordion'
import DefectedOperationsList from './DefectedOperationsList'
import './libraryPanel.scss'
import PositionedOperationsList from './PositionedOperationsList'

export default function LibraryPanel(): ReactElement {
  const { defectedOperations, positionedOperations } = useSelector((state: RootState) => state.mypLibrary)
  return (
    <div className="myp-settings-panel library-panel">
      <LibraryPanelHeader />
      <div className="myp-setting-panel-content">
        <Typography className="category-title mt-0">{terms.MultiYearPlan.SettingsPanel.library}</Typography>
        <LibrarySearch />
        <OperationsAccordion
          summary={terms.MultiYearPlan.SettingsPanel.Library.positionedOperations}
          operationsCount={positionedOperations.length}
          content={<PositionedOperationsList />}
        />
        <OperationsAccordion
          summary={terms.MultiYearPlan.SettingsPanel.Library.defectedOperations}
          operationsCount={defectedOperations.length}
          content={<DefectedOperationsList />}
        />
      </div>
    </div>
  )
}
