import { ReactElement } from 'react'
import terms from 'common/terms'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import DeleteModal from 'components/common/Modals/DeleteModal'
import { deleteRepertoryPR, deleteFolderPR } from 'reducers/RemarkablePoints/remarkablePoints.thunk'
import { toggleDisplayDeleteModal, updateSelectedPr } from 'reducers/RemarkablePoints/remarkablePoints'

export default function DeletePrModal():
 ReactElement {
  const dispatch = useDispatch()
  const { displayDeleteModal, selectedPr, loadingPr } = useSelector((state: RootState) => state.remarkablePoints)
  const { activeFile } = useSelector((state: RootState) => state.files)

  const onModalClose = () => {
    dispatch(toggleDisplayDeleteModal(false))
    dispatch(updateSelectedPr(undefined))
  }

  const onDelete = () => {
    if (activeFile) {
      if (activeFile.folder !== null) {
        dispatch(deleteFolderPR({
          repertoryId: activeFile.directory,
          folderId: activeFile.folder,
          projectId: activeFile.id,
          prId: selectedPr.id,
        }))
      } else {
        dispatch(deleteRepertoryPR({
          repertoryId: activeFile.directory,
          projectId: activeFile.id,
          prId: selectedPr.id,
        }))
      }
    }
  }

  return (
    <DeleteModal
      open={displayDeleteModal}
      onModalClose={onModalClose}
      modalTitle={terms.MultiYearPlan.Actions.deletePr + selectedPr?.label}
      modalNotice={terms.MultiYearPlan.Actions.deleteNotice}
      onDelete={onDelete}
      isLoading={loadingPr}
    />
  )
}
