import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { Grid } from '@mui/material'
import { Files } from 'reducers/Files/types'
import FileCard from 'components/Files/FileCard'
import UpdateFileModal from 'components/Files/Actions/UpdateFileModal'
import DeleteFileModal from 'components/Files/Actions/DeleteFileModal'
import MoveFile from 'components/Files/Actions/MoveFile'
import ShareFileModal from 'components/Files/Actions/ShareFileModal'

export default function FilesContainer(): ReactElement {
  const { repertoryFiles } = useSelector((state: RootState) => state.files)
  return (
    <>
      <Grid
        item
        container
        spacing={1}
        sx={{ marginTop: '3rem', marginBottom: '4.5rem' }}
      >
        {repertoryFiles.map((file: Files) => (
          <Grid item key={file.id} sx={{ justifyContent: 'center' }}>
            <FileCard file={file} />
          </Grid>
        ))}
      </Grid>
      <UpdateFileModal />
      <DeleteFileModal />
      <ShareFileModal />
      <MoveFile />
    </>
  )
}
