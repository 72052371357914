import { ReactElement } from 'react'
import terms from 'common/terms'

import './actions.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { toggleDisplayDeleteModal } from 'reducers/Repertories/repertories'
import { deleteRepertory } from 'reducers/Repertories/repertories.thunk'
import DeleteModal from 'components/common/Modals/DeleteModal'

export default function DeleteRepertoryModal(): ReactElement {
  const dispatch = useDispatch()
  const { displayDeleteModal, contextRepertoryId, isLoading } = useSelector((state: RootState) => state.repertories)

  const onModalClose = () => {
    dispatch(toggleDisplayDeleteModal(false))
  }

  const onDelete = () => {
    dispatch(deleteRepertory(contextRepertoryId))
  }

  return (
    <>
      <DeleteModal
        open={displayDeleteModal}
        onModalClose={onModalClose}
        modalTitle={terms.Repertory.deleteRepertory}
        modalNotice={terms.Repertory.deleteNotice}
        onDelete={onDelete}
        isLoading={isLoading}
      />
    </>
  )
}
