/* eslint-disable react/no-array-index-key */
import terms from 'common/terms'
import FormInput from 'components/common/Inputs/FormInput'
import {
  ReactElement, useCallback, useEffect, useState,
} from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { updateFilters, updateWorkPhases } from 'reducers/library'
import { RootState } from 'Store'
import { InputField, InputTypes } from 'types'
import { WorkPhaseFields } from './types'
import {
  getActualFilters, getActualFiltersFields, getDefaultFiltersInput, getFilterValues, getSelectedValue,
  handleChange, setSearchValues,
} from './utils'
import WorkPhase from './WorkPhases'

export default function FiltersFields(): ReactElement {
  const dispatch = useDispatch()
  const [filtersList, setFiltersList] = useState<InputField[]>([])
  const { importedPortfolio } = useSelector((state: RootState) => state.import)
  const { filters, workPhases } = useSelector((state: RootState) => state.library)
  const [filtersValues, setFiltersValues] = useState<any>({})
  const [selectedValues, setSelectedValues] = useState<any>({})

  const getValues = useCallback(() => {
    let valuesList: any = {}
    filtersList.forEach(async (filter: InputField) => {
      if (filter.label === terms.Portfolio.Library.Filters.workPhase) {
        const phaseValues: string[] = await getFilterValues(importedPortfolio.project, 'phase_travaux__valeur')
        const dateValues: string[] = await getFilterValues(importedPortfolio.project, 'phase_travaux__annee')
        valuesList = { ...valuesList, phaseTravaux: phaseValues, date: dateValues }
      } else {
        const values: string[] = await getFilterValues(importedPortfolio.project, filter.label)
        valuesList = { ...valuesList, [`${filter.label}`]: values }
      }
      setFiltersValues(valuesList)
    })
  }, [filtersList])

  useEffect(() => {
    if (importedPortfolio) {
      setFiltersList(getActualFiltersFields(importedPortfolio).length === 0
        ? getDefaultFiltersInput(importedPortfolio) : getActualFiltersFields(importedPortfolio))
      dispatch(updateFilters(getActualFilters(importedPortfolio)))
    }
  }, [importedPortfolio])

  useEffect(() => {
    if (Object.keys(filters).length !== 0) {
      const additionalFilters: InputField[] = []
      Object.keys(filters).forEach((filter: string) => {
        if (filtersList.filter((filterInput: InputField) => filterInput.label === filter).length === 0) {
          additionalFilters.push({
            key: filter,
            label: filter,
            placeholder: terms.Common.search,
            required: false,
            inputType: InputTypes.Select,
            withSearch: true,
            multiple: true,
          })
        }
      })
      if (filtersList.length !== 0) {
        setFiltersList(filtersList.concat(additionalFilters))
      } else {
        setFiltersList(additionalFilters)
      }
    }
  }, [filters])

  useEffect(() => {
    if (filtersList.length !== 0) getValues()
  }, [filtersList])

  useEffect(() => {
    if (Object.keys(selectedValues).length !== 0) {
      if (Object.keys(filters).includes('Phases études/travaux')) {
        dispatch(updateFilters({ ...selectedValues, 'Phases études/travaux': filters['Phases études/travaux'] }))
      } else {
        dispatch(updateFilters({ ...filters, ...selectedValues }))
      }
    }
  }, [selectedValues])

  const handleClose = (filterToDelete: string, index?: number) => {
    let newFiltersList: any = {}
    if (filterToDelete !== terms.Portfolio.Library.Filters.workPhase) {
      setFiltersList(filtersList.filter((filterInput: InputField) => filterInput.label !== filterToDelete))
      Object.keys(filters).forEach((filter: string) => {
        if (filter !== filterToDelete) {
          newFiltersList = { ...newFiltersList, [`${filter}`]: filters[`${filter}`] }
        }
      })
    } else {
      const newPhases: WorkPhaseFields[] = []
      workPhases.forEach((phase: WorkPhaseFields) => {
        if (index) {
          if (phase.index < index) {
            newPhases.push(phase)
          } else if (phase.index > index) {
            newPhases.push({ index: phase.index - 1, phase: phase.phase, appliedFilter: phase.appliedFilter })
          }
        }
      })
      dispatch(updateWorkPhases(newPhases))
      newFiltersList = {
        ...newFiltersList,
        [`${filterToDelete}`]: newPhases,
      }
    }
    dispatch(updateFilters(newFiltersList))
    setSearchValues(selectedValues[`${filterToDelete}`], index, true)
    setSelectedValues(newFiltersList)
  }

  const getValue = (filter: string) => {
    if (selectedValues[`${filter}`]) {
      return selectedValues[`${filter}`]
    }
    if (filters[`${filter}`]) {
      return filters[`${filter}`]
    }
    return ''
  }

  return (
    <Form className="library-form" autoComplete="off">
      {filtersList.map((filter: InputField, index: number) => (
        filter.label !== terms.Portfolio.Library.Filters.workPhase ? (
          <FormInput
            key={filter.label}
            field={{ ...filter, values: filtersValues[`${filter.label}`] }}
            onChange={v => handleChange(filter.label, v, selectedValues, setSelectedValues)}
            value={getValue(filter.label)}
            extraStyle="px-2"
            index={index}
            setSearchValues={v => setSearchValues(v, index)}
            getSelectedValue={() => getSelectedValue(getValue(filter.label), index)}
            closeButton
            closeButtonStyle="close-btn"
            onClose={() => handleClose(filter.label, index)}
          />
        )
          : <WorkPhase key={index} filtersValues={filtersValues} onClose={handleClose} />
      ))}
    </Form>
  )
}
