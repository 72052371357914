import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import GenericTopBar from 'components/common/TopBar/GenericTopBar'
import { updateActiveBlock } from 'reducers/synoptic'
import {
  toggleExportLoader, updateExportFlag, updateExportResponseError, updateLaunchExport,
} from 'reducers/export'
import terms from 'common/terms'
import history from 'customHistory'
import PathNames from 'common/PathNames'
import { RootState } from 'Store'

export default function CreationTopBar(): ReactElement {
  const dispatch = useDispatch()
  const { activeRepertory } = useSelector((state: RootState) => state.repertories)
  const { activeFolder } = useSelector((state: RootState) => state.folders)

  const backToPlan = () => {
    if (activeFolder) {
      history.push(`${PathNames.RepertoryContent}/${activeRepertory.id}/${PathNames.Folder}/${activeFolder.id}`)
    } else {
      history.push(`${PathNames.RepertoryContent}/${activeRepertory.id}`)
    }

    dispatch(toggleExportLoader(false))
    dispatch(updateExportFlag(false))
    dispatch(updateLaunchExport(false))
    dispatch(updateActiveBlock(terms.Synoptic.createFondPlan))
    dispatch(updateExportResponseError(undefined))
  }

  return (
    <GenericTopBar
      onStepBack={backToPlan}
      getPreviousStep={() => terms.Export.backToSchema}
      onClose={backToPlan}
      closeIcon={false}
    />
  )
}
