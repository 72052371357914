import { updateSearchValue } from 'reducers/label'
import { store } from 'Store'
import { SearchFields } from '../types'

export const setSearchValues = (v: string, index: number | undefined) => {
  const { searchValue } = store.getState().label
  if (searchValue.filter((search: SearchFields) => search.index === index).length === 0) {
    store.dispatch(updateSearchValue(searchValue.concat({ index, value: v })))
  } else {
    store.dispatch(updateSearchValue((searchValue.filter((search: SearchFields) => search.index !== index))
      .concat({ index, value: v })))
  }
}

export const getSelectedValue = (value: string, index: number | undefined) => {
  const { searchValue } = store.getState().label
  const actualSearchValue = searchValue.filter((search: SearchFields) => search.index === index)
  if (value !== '' && actualSearchValue.length !== 0 && actualSearchValue[0].value === value) {
    return [value] as string[]
  }
  if (actualSearchValue.length !== 0) {
    return [actualSearchValue[0].value] as string[]
  }
  return []
}
