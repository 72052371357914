/* eslint-disable react/no-array-index-key */
import terms from 'common/terms'
import ActionButton from 'components/common/Buttons/ActionButton'
import GenericInput from 'components/common/Inputs/GenericInput'
import GenericSelect from 'components/common/Inputs/GenericSelect'
import { ChangeEvent, ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { FileTypes } from 'reducers/Files/types'
import { RootState } from 'Store'
import { getErrorMessage } from '../utils'
import { DirectionSelectOptions, GeographicFields, GeographicInputFields } from './GeographicPerimeterInputFields'

type Props = {
    direction: string
    setDirection: (v: string) => void
    getInputValue: (v: string) => string
    handleChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, v: string) => void
    addSection: () => void
}
export default function geographicPerimeterFields({
  direction, setDirection, getInputValue, handleChange, addSection,
}: Props): ReactElement {
  const { activeFile } = useSelector((state: RootState) => state.files)
  const { sectionResponseError, mypEmptyFields } = useSelector((state: RootState) => state.multiYearPlan)
  const { emptyFields, responseError } = useSelector((state: RootState) => state.creation)
  return (
    <>
      {GeographicInputFields.map((field: GeographicFields, index: number) => (
        (field.label === terms.Inputs.direction && activeFile.type === FileTypes.Schema ? (
          <GenericSelect
            key={`${field.label}-${index}`}
            label={field.label}
            options={DirectionSelectOptions}
            onChange={e => setDirection(e.target.value)}
            value={direction}
          />
        )
          : ((!field.filetype || field.filetype === activeFile.type) && field.label !== terms.Inputs.direction) && (
          <GenericInput
            key={`${field.label}-${index}`}
            label={field.label}
            placeholder={field.placeholder as string}
            value={getInputValue(field.label) as string}
            onChange={e => handleChange(e as ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, field.label)}
            inputProps={field.hasInputProps ? { inputMode: 'numeric', pattern: '/d*' } : {}}
            extraStyle={`input-style${field.label !== terms.Inputs.step ? '' : ' step-input-style'}`}
            errorMessage={getErrorMessage(activeFile.type === FileTypes.Synoptic ? responseError : sectionResponseError,
              field.key, activeFile.type === FileTypes.Synoptic ? emptyFields : mypEmptyFields,
                   getInputValue(field.label) as string)}
            autoFocus={field.autoFocus}
          />
          ))
      ))}
      <div className="add-btn-container">
        <ActionButton
          onClick={addSection}
          leftIcon
          text={terms.File.Declaration.addSection}
          buttonStyle="add-section-btn"
        />
      </div>
    </>
  )
}
