import { ReactElement } from 'react'
import terms from 'common/terms'
import TopBar from 'components/TopBar'
import DashboardNavBar from 'components/Dashboard/DashboardNavBar'
import UpdateFileModal from 'components/Files/Actions/UpdateFileModal'
import DeleteFileModal from 'components/Files/Actions/DeleteFileModal'
import MoveFile from 'components/Files/Actions/MoveFile'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import Loader from 'components/Loader'
import ShareFileModal from 'components/Files/Actions/ShareFileModal'

export default function FolderContent(): ReactElement {
  const { loadingFiles } = useSelector((state: RootState) => state.files)
  return (
    <>
      <TopBar appName={terms.Common.appName} />
      {loadingFiles && <Loader center />}
      <DashboardNavBar />
      <UpdateFileModal />
      <DeleteFileModal />
      <ShareFileModal />
      <MoveFile />
    </>
  )
}
