import { createSlice } from '@reduxjs/toolkit'
import {
  getGroups, getGroupMembers, shareDocument,
} from './cerbere.thunk'
import { CerbereState } from './types'

const initialState: CerbereState = {
  services: { results: [], count: '0' },
  users: { results: [], count: '0' },
  isLoading: false,
  shareDone: false,
}

export const cerbereSlice = createSlice({
  name: 'cerbere',
  initialState,
  reducers: {
    resetSharingStatus: state => {
      state.shareDone = false
    },
  },
  extraReducers: builder => {
    builder.addCase(getGroups.fulfilled, (state, action) => {
      state.services = action.payload
    })
    builder.addCase(getGroupMembers.fulfilled, (state, action) => {
      state.users = action.payload
    })
    builder.addCase(shareDocument.fulfilled, state => {
      state.isLoading = false
      state.shareDone = true
    })
    builder.addCase(shareDocument.pending, state => {
      state.isLoading = true
    })
    builder.addCase(shareDocument.rejected, state => {
      state.isLoading = false
    })
  },
})

export const {
  resetSharingStatus,
} = cerbereSlice.actions

export default cerbereSlice.reducer
