import { MYPSection } from 'reducers/MultiYearPlan/types'
import { ResponseError } from 'types'

/* eslint-disable camelcase */
export type TimeRange = {
    start_year: number | null
    end_year: number | null
}
export type Files = {
    id: number
    creation_date: string
    last_modified: string
    name: string
    time_range: TimeRange
    folder: number | null
    directory: number | null
    type: string
    sections: MYPSection[]
    status: number
    confirm_change: boolean
    abort_change: boolean
}
export interface FilesState {
    orderBy: string
    search: string
    repertoryFiles: Files[]
    folderFiles: Files[]
    displayDeleteModal: boolean
    displayUpdateModal: boolean
    displayShareModal: boolean
    timeRange?: TimeRange
    openFile: boolean
    displayDeplaceModal: boolean
    fileName: string
    activeFile?: Files
    moveToFolder: boolean
    contextFile?: Partial<Files>
    cloneFile: boolean
    fileType: string
    createdFile?: Files
    responseError?: ResponseError
    isCloning: boolean
    isLoading: boolean
    deletedFile?: number
    loadingFiles: boolean
    updateFileName: boolean
    searchingFiles: boolean
    years: number[]
    phases: string[]
    displayUpdateTemporalModal: boolean
    displayUpdateGeographicModal: boolean
    abortFileUpdate: boolean
    abortGeographicPerimeterUpdate: boolean
    noPerimeterChange: boolean
    validateChange: boolean
    validateTemporalChange: boolean
    displayUpdatePerimeterLoader: boolean
    displayUpdateGeographicLoader: boolean
}

export enum FileTypes {
    Schema = 'MYP',
    Synoptic = 'SYN',
}

export type GetParams = {
    repertoryId: number
    folderId?: number
    ordering?: string
    search?: string | null
    fileId?: number
}

export type PostParams = {
    repertoryId: number
    folderId?: number
    fileName: string
    fileType: string
}

export type DeleteParams = {
    repertoryId: number
    folderId?: number
    fileId: number
}

export type PatchParams = {
    repertoryId: number
    folderId?: number
    file: Partial<Files>
}
