import { ReactElement } from 'react'
import {
  Box, Modal, Typography,
} from '@mui/material'
import terms from 'common/terms'
import ActionButton from 'components/common/Buttons/ActionButton'

import './modal.scss'

export default function UpdateModal({
  open, onModalClose, modalTitle, content, onUpdate, actionType, isDisabled, modalStyle,
}: {open: boolean, onModalClose: () => void, modalTitle: string, content: ReactElement, onUpdate: () => void,
   actionType: string, isDisabled?: boolean, modalStyle?: string})
: ReactElement {
  return (
    <Modal
      open={open}
      onClose={onModalClose}
      disableEscapeKeyDown
    >
      <Box className={`action-modal ${modalStyle !== '' ? modalStyle : ''}`}>
        <Typography variant="h6" component="h1">
          {modalTitle}
        </Typography>
        {content}
        <div className="modal-action-btn">
          <ActionButton
            text={terms.Common.cancel}
            onClick={onModalClose}
            buttonStyle="cancel-action-btn"
          />
          <ActionButton text={actionType} onClick={onUpdate} buttonStyle="update-btn" disabled={isDisabled} />
        </div>
      </Box>
    </Modal>
  )
}

UpdateModal.defaultProps = {
  isDisabled: false,
  modalStyle: '',
}
