import terms from 'common/terms'
import ActionButton from 'components/common/Buttons/ActionButton'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dropFolderFilesSections, dropRepertoryFileSections } from 'reducers/MultiYearPlan/multiYearPlan.thunk'
import { DeclarationMode } from 'reducers/MultiYearPlan/types'
import { RootState } from 'Store'

export default function DeclarationMethodeChoice(): ReactElement {
  const dispatch = useDispatch()
  const { activeFile } = useSelector((state: RootState) => state.files)

  const handleClick = (declarationMode: string) => {
    if (activeFile) {
      if (activeFile.folder !== null) {
        dispatch(dropFolderFilesSections({
          repertoryId: activeFile.directory,
          folderId: activeFile.folder,
          fileId: activeFile.id,
          declarationMode,
        }))
      } else {
        dispatch(dropRepertoryFileSections({
          repertoryId: activeFile.directory,
          fileId: activeFile.id,
          declarationMode,
        }))
      }
    }
  }
  return (
    <div className="declaration-choice">
      <ActionButton
        text={terms.File.Declaration.Schema.manualDeclaration}
        onClick={() => handleClick(DeclarationMode.Manual)}
        buttonStyle="declaration-choice-btn"
      />
      <ActionButton
        text={terms.File.Declaration.Schema.fileImport}
        onClick={() => handleClick(DeclarationMode.Import)}
        buttonStyle="declaration-choice-btn"
      />
    </div>
  )
}
