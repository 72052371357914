/* eslint-disable react/no-array-index-key */
import { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { ColorResult, TwitterPicker } from 'react-color'
import terms from 'common/terms'
import { ObjectFamily, Track } from 'reducers/MultiYearPlan/Import/ObjectsFamilies/type'
import { Menu, Chip, Tooltip } from '@mui/material'
import { PatchFamilyTrack } from 'reducers/MultiYearPlan/Import/ObjectsFamilies/objectsFamilies.thunk'
import AddTrackButton from '../../Button/AddTrackButton'
import TrackLabelInput from './TrackLabelInput'
import OBJECT_FAMILY_COLORS_LIST from './ObjectFamilyColorsList'
import ContextMenu from './Actions/ContextMenu'

export default function TracksContainer({ objectFamily }: {objectFamily: ObjectFamily}): ReactElement {
  const dispatch = useDispatch()
  const { activeFile } = useSelector((state: RootState) => state.files)
  const [addTrack, setAddTrack] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [anchorElColor, setAnchorElColor] = useState<null | HTMLElement>(null)
  const [HTMLelement, setHTMLElement] = useState<null | HTMLElement>(null)
  const [activeTrack, setActiveTrack] = useState<Track>()

  const openColorPicker = Boolean(anchorElColor)

  const handleOpen = (event: React.MouseEvent<HTMLElement>, track: Track) => {
    setActiveTrack(track)
    setHTMLElement(event.currentTarget)
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }

  const handleCloseColorPicker = () => {
    setAnchorElColor(null)
  }

  const handleOpenColorPicker = () => {
    setAnchorElColor(HTMLelement)
    setAnchorEl(null)
  }

  const handleChooseColorClick = (c: string) => {
    if (activeFile) {
      dispatch(PatchFamilyTrack({
        repertoryId: activeFile.directory,
        folderId: activeFile.folder,
        fileId: activeFile.id,
        classificationId: objectFamily.id,
        trackId: activeTrack?.id,
        track: {
          color: c,
        },
      }))
      setAnchorElColor(null)
    }
  }

  const handleTrackAdd = () => {
    if (objectFamily.id) {
      setAddTrack(true)
    }
  }

  return (
    <>
      <div className="add-track-container">
        <ContextMenu
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          handleOpenColorPicker={handleOpenColorPicker}
          familyId={objectFamily.id}
          activeTrack={activeTrack as Track}
        />
        {objectFamily?.tracks?.map((track: Track) => (
          <div key={track.label} onContextMenu={e => handleOpen(e, track)}>
            <Tooltip title={terms.MultiYearPlan.Import.colorNotice} placement="top">
              <Chip label={track.label} className="track-chip" style={{ backgroundColor: track.color }} />
            </Tooltip>
            <Menu
              open={openColorPicker}
              anchorEl={anchorElColor}
              anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
              className="object-family-color-panel"
              PaperProps={{
                sx: {
                  overflow: 'visible',
                },
              }}
              onClose={handleCloseColorPicker}
            >
              <TwitterPicker
                colors={OBJECT_FAMILY_COLORS_LIST}
                onChange={(c: ColorResult) => handleChooseColorClick(c.hex)}
              />
            </Menu>
          </div>
        ))}
        {addTrack && <TrackLabelInput objectFamily={objectFamily} onTrackAdd={() => setAddTrack(false)} />}
        <AddTrackButton onClickAddTrack={handleTrackAdd} />
      </div>
    </>
  )
}
