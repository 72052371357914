/* eslint-disable max-len */
import { useState } from 'react'
import ActionButton from 'components/common/Buttons/ActionButton'
import Dialog from '@mui/material/Dialog'
import { RootState } from 'Store'
import _ from 'lodash'
import IconButton from '@mui/material/IconButton'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { OperationsYear } from 'reducers/MultiYearPlan/Operations/types'
import { useDispatch, useSelector } from 'react-redux'
import { patchOperationPosition } from 'reducers/MultiYearPlan/OperationActions/operationactions.thunk'
import { toggleMoveOperationDialog } from 'reducers/MultiYearPlan/OperationActions/operationactions'
import { updateOperations } from 'reducers/MultiYearPlan/Operations/operations'

import DialogTitle from '@mui/material/DialogTitle'
import Paper, { PaperProps } from '@mui/material/Paper'
import Draggable from 'react-draggable'
import './operations.scss'
import terms from 'common/terms'

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  )
}

export default function MoveOperationDialog() {
  const dispatch = useDispatch()
  const { activeFile } = useSelector((state: RootState) => state.files)
  const {
    displayMoveOperationDialog, selectedOperationPoint,
  } = useSelector((state: RootState) => state.mypoperationactions)
  const { Operations, formatedOperations } = useSelector((state: RootState) => state.mypoperations)
  const [offsetX, setOffsetX] = useState<number>()
  const [offsetY, setOffsetY] = useState<number>()

  const handleClose = () => {
    dispatch(updateOperations(Operations))
    dispatch(toggleMoveOperationDialog(false))
  }

  const handleSave = () => {
    dispatch(toggleMoveOperationDialog(false))
    dispatch(patchOperationPosition({
      repertoryId: activeFile.directory,
      folderId: activeFile.folder,
      projectId: activeFile.id,
      operationId: selectedOperationPoint?.data.operationId,
      labelId: selectedOperationPoint?.data.labelId,
      x_label_offset: offsetX,
      y_label_offset: offsetY,
    }))
  }

  const style = {
    backgroundColor: '#41A8F9', width: 56, height: 56, borderRadius: 8, color: 'white', marginBottom: 10,
  }
  const handleClick = (operation: string) => {
    const formated = _.cloneDeep(formatedOperations)
    formated.years.map((obj: OperationsYear, index: number) => {
      if (selectedOperationPoint && index === selectedOperationPoint.data.yearIndex) {
        switch (operation) {
          case 'up':
            obj.classifications[selectedOperationPoint.data.classificationIndex].operation_labels[selectedOperationPoint.data.operationIndex].data[0].y_label_offset -= 10
            setOffsetY(obj.classifications[selectedOperationPoint.data.classificationIndex].operation_labels[selectedOperationPoint.data.operationIndex].data[0].y_label_offset)
            break
          case 'down':
            obj.classifications[selectedOperationPoint.data.classificationIndex].operation_labels[selectedOperationPoint.data.operationIndex].data[0].y_label_offset += 10
            setOffsetY(obj.classifications[selectedOperationPoint.data.classificationIndex].operation_labels[selectedOperationPoint.data.operationIndex].data[0].y_label_offset)
            break
          case 'left':
            obj.classifications[selectedOperationPoint.data.classificationIndex].operation_labels[selectedOperationPoint.data.operationIndex].data[0].x_label_offset -= 10
            setOffsetX(obj.classifications[selectedOperationPoint.data.classificationIndex].operation_labels[selectedOperationPoint.data.operationIndex].data[0].x_label_offset)
            break
          case 'right':
            obj.classifications[selectedOperationPoint.data.classificationIndex].operation_labels[selectedOperationPoint.data.operationIndex].data[0].x_label_offset += 10
            setOffsetX(obj.classifications[selectedOperationPoint.data.classificationIndex].operation_labels[selectedOperationPoint.data.operationIndex].data[0].x_label_offset)
            break
          default:
            break
        }
      }
      return obj
    })
    dispatch(updateOperations(formated))
  }

  return (
    <Dialog
      open={displayMoveOperationDialog}
      className="move-operation-dialog"
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      sx={{ backgroundColor: 'transparent' }}
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title" />
      <DialogContent className="d-flex flex-column align-items-center">
        <IconButton
          onClick={() => handleClick('up')}
          style={style}
        >
          <ArrowUpwardIcon />
        </IconButton>
        <div className="d-flex justify-content-center">
          <IconButton
            onClick={() => handleClick('left')}
            style={{
              backgroundColor: '#41A8F9', width: 56, height: 56, borderRadius: 8, color: 'white', marginRight: 30,
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <IconButton
            onClick={() => handleClick('right')}
            style={style}
          >
            <ArrowForwardIcon />
          </IconButton>
        </div>
        <IconButton
          onClick={() => handleClick('down')}
          style={style}
        >
          <ArrowDownwardIcon />
        </IconButton>
      </DialogContent>
      <DialogActions className="d-flex flex-column align-items-center justify-content-center text-center">
        <ActionButton
          text={terms.MultiYearPlan.Actions.reset}
          onClick={handleClose}
          buttonStyle="move-operation-cancel-action-btn w-100"
        />
        <ActionButton
          text={terms.MultiYearPlan.Actions.save}
          onClick={handleSave}
          buttonStyle="move-operation-save-action-btn ml-0 mt-3 w-100"
        />
      </DialogActions>
    </Dialog>
  )
}
