import { ExtendLineStringMode, ViewMode } from '@nebula.gl/edit-modes'
import { EditorModeName } from 'components/GeoEditor/types'
import { store } from 'Store'

import CustomModifyMode, { SelectedPoints } from './EditionLayer/CustomModifyMode'
import { GeoEditorState, setEditorLayerMode, setLayersVisibility } from './reducer'

export enum Modes {
  deletePoint = 'deletePoint',
  draw = 'draw',
  grab = 'grab',
  selectRectangle = 'selectRectangle',
  selectPolygon = 'selectPolygon',
  rotate = 'rotate',
}

export const onModeChange = (mode: Modes): void => {
  const { layersVisibility } = store.getState().geoEditor as GeoEditorState

  switch (mode) {
    case Modes.selectPolygon:
    case Modes.selectRectangle:
      store.dispatch(setEditorLayerMode(EditorModeName.View))
      store.dispatch(setLayersVisibility({
        ...layersVisibility,
        selectionLayer: true,
      }))
      break
    case Modes.deletePoint:
    case Modes.rotate:
    case Modes.draw:
    case Modes.grab:
      store.dispatch(setEditorLayerMode(EditorModeName.Edit))
      store.dispatch(setLayersVisibility({
        ...layersVisibility,
        selectionLayer: false,
      }))
      break
    default:
      break
  }
}
// Clean previous mode cursors classes
export const cleanCursor = (): void => {
  const mapNodeClasses = document.querySelector('#deckgl-wrapper')?.classList
  Object.values(Modes).forEach(m => mapNodeClasses?.remove(m))
}

// Add new mode cursor class
export const updateCursor = (newMode: Modes): void => {
  cleanCursor()
  const mapNodeClasses = document.querySelector('#deckgl-wrapper')?.classList
  mapNodeClasses?.add(newMode)
}

type ModeNameToMode = {
  [EditorModeName.Edit]: CustomModifyMode;
  [EditorModeName.View]: ViewMode;
  [EditorModeName.Extend]: ExtendLineStringMode;
}

export const EditorLayerModes = (mode: Modes, selectedPoints: SelectedPoints): ModeNameToMode => ({
  [EditorModeName.Edit]: new CustomModifyMode(mode, selectedPoints),
  [EditorModeName.View]: new ViewMode(),
  [EditorModeName.Extend]: new ExtendLineStringMode(),
})

type InteractiveState = {
  isDragging: boolean;
}

export const getCursor = (state: InteractiveState): string => {
  const { mode } = store.getState().geoEditor as GeoEditorState
  if (mode !== Modes.grab) return ''
  if (state.isDragging) return 'grabbing'
  return 'grab'
}
