import {
  PayloadAction, createSlice,
} from '@reduxjs/toolkit'
import { PageFormat, PageOrientation } from 'components/Export/types'
import {
  MypExportPdfState, PdfSettings,
} from './types'

const initialState: MypExportPdfState = {
  displayExportPdfModal: false,
  exportSettings: {
    pageFormat: PageFormat.A3,
    pageNumber: '1',
    pageOrientation: PageOrientation.portrait,
    greenOption: true,
    includeTileblock: true,
    includeLegend: true,
  },
}

export const mypExportPdfSlice = createSlice({
  name: 'mypexportpdf',
  initialState,
  reducers: {
    resetExportSettings: state => {
      state.exportSettings = {
        pageFormat: PageFormat.A3,
        pageNumber: '1',
        pageOrientation: PageOrientation.portrait,
        greenOption: true,
        includeTileblock: true,
        includeLegend: true,
      }
    },
    toggleDisplayExportPdfModal: (state, action: PayloadAction<boolean>) => {
      state.displayExportPdfModal = action.payload
    },
    updateSettings: (state, action: PayloadAction<PdfSettings>) => {
      state.exportSettings = {
        ...state.exportSettings,
        ...action.payload,
      }
    },
  },
})
export const {
  toggleDisplayExportPdfModal, updateSettings, resetExportSettings,
} = mypExportPdfSlice.actions

export default mypExportPdfSlice.reducer
