import { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import { toggleObjectOrientationMode } from 'reducers/object'
import terms from 'common/terms'
import { ToolsProps } from './types'

export default function ModifyObjectOrientation({ closePopup }: ToolsProps): ReactElement {
  const dispatch = useDispatch()
  const modifyObjectOrientation = async () => {
    dispatch(toggleObjectOrientationMode(true))
    closePopup(undefined)
  }

  return (
    <>
      <div role="button" tabIndex={0} className="popup-items" onClick={modifyObjectOrientation}>
        {terms.Object.button.orientation}
      </div>
    </>
  )
}
