import {
  PayloadAction, createSlice,
} from '@reduxjs/toolkit'
import {
  MypCommentsState, Comment, NewComment, UpdateComment,
} from './types'
import {
  getComments, modifyComment, addComment, DeleteComment,
} from './comments.thunk'

const initialState: MypCommentsState = {
  comments: [],
  commentsLoaded: false,
  newCommentContext: {
    displayAddButton: false,
    newCommentIndex: null,
  },
  updateCommentContext: {
    displayUpdateButton: false,
    updateCommentIndex: null,
  },
  validateComment: false,
  CommentOnCreation: false,
}

export const mypCommentsSlice = createSlice({
  name: 'mypcomments',
  initialState,
  reducers: {
    updateComments: (state, action: PayloadAction<Comment[]>) => {
      state.comments = action.payload
    },
    addNewComment: (state, action: PayloadAction<NewComment>) => {
      state.newCommentContext = action.payload
    },
    toggleUpdateButton: (state, action: PayloadAction<UpdateComment>) => {
      state.updateCommentContext = action.payload
    },
    toggleCommentOnCreation: (state, action: PayloadAction<boolean>) => {
      state.CommentOnCreation = action.payload
    },
    toggleAddBUtton: state => {
      state.newCommentContext.displayAddButton = false
    },
    resetCommentsOperations: state => {
      state.updateCommentContext.displayUpdateButton = false
      state.newCommentContext.displayAddButton = false
      state.CommentOnCreation = false
    },
  },
  extraReducers: builder => {
    builder.addCase(modifyComment.fulfilled, state => {
      state.updateCommentContext.displayUpdateButton = false
    })
    builder.addCase(getComments.pending, state => {
      state.commentsLoaded = false
    })
    builder.addCase(getComments.fulfilled, (state, action) => {
      state.comments = action.payload
      state.commentsLoaded = true
    })
    builder.addCase(addComment.fulfilled, state => {
      state.newCommentContext.displayAddButton = false
      state.responseError = undefined
      state.CommentOnCreation = false
    })
    builder.addCase(addComment.rejected, (state, action) => {
      state.responseError = action.payload
    })
    builder.addCase(DeleteComment.fulfilled, (state, action) => {
      state.comments = state.comments.filter(comment => comment.id !== action.payload)
    })
  },
})
export const {
  updateComments, addNewComment, toggleUpdateButton,
  toggleCommentOnCreation, toggleAddBUtton, resetCommentsOperations,
} = mypCommentsSlice.actions

export default mypCommentsSlice.reducer
