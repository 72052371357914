import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IconButton } from '@mui/material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { ObjectFamily } from 'reducers/MultiYearPlan/Import/ObjectsFamilies/type'
import {
  toggleDisplayDeleteObjectFamily,
  updateActiveObjectFamily,
  updateObjectsFamilies,
} from 'reducers/MultiYearPlan/Import/ObjectsFamilies/objectsFamilies'
import { RootState } from 'Store'

export default function DeleteObjectFamilyButton({ objectFamily }: {objectFamily: ObjectFamily}): ReactElement {
  const dispatch = useDispatch()
  const { objectsFamilies } = useSelector((state: RootState) => state.objectsFamilies)

  const handleDeleteObjectFamily = () => {
    if (objectFamily.id && objectFamily.id !== -1) {
      dispatch(toggleDisplayDeleteObjectFamily(true))
      dispatch(updateActiveObjectFamily(objectFamily))
    } else {
      dispatch(updateObjectsFamilies(objectsFamilies.filter((family: ObjectFamily) => family.label !== '')))
    }
  }

  return (
    <IconButton
      className="bg-white text-dark"
      aria-label="delete"
      size="large"
      onClick={() => handleDeleteObjectFamily()}
    >
      <DeleteOutlineIcon />
    </IconButton>
  )
}
