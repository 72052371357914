/* eslint-disable max-len */
import { ReactElement, useState } from 'react'
import {
  Alert, Button, Snackbar, Typography,
} from '@mui/material'
import terms from 'common/terms'
import { useDispatch, useSelector } from 'react-redux'
import { updateZoomPr } from 'reducers/RemarkablePoints/remarkablePoints'
import { setExportAlert, toggleDisplaySettingsPanel } from 'reducers/MultiYearPlan/Settings/settings'
import { toggleDisplayExportPdfModal } from 'reducers/MultiYearPlan/ExportPdf/exportpdf'
import { RootState } from 'Store'
import MAIN_API from 'config/config'

export default function ExportSettings(): ReactElement {
  const dispatch = useDispatch()
  const { activeFile } = useSelector((state: RootState) => state.files)
  const { exportAlert } = useSelector((state: RootState) => state.mypSettings)
  const [downloading, setDownloading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  const handlePdfExport = () => {
    window.scrollTo(0, 0)
    dispatch(updateZoomPr(2200))
    dispatch(toggleDisplayExportPdfModal(true))
    dispatch(toggleDisplaySettingsPanel(false))
  }

  const handleExcelExport = () => {
    dispatch(setExportAlert(true))
    const url = activeFile.folder !== null
      ? `${MAIN_API.proxy}/module_schematique/directories/${activeFile.directory}/folders/${activeFile.folder}/projects/${activeFile.id}/portfolio/export/`
      : `${MAIN_API.proxy}/module_schematique/directories/${activeFile.directory}/projects/${activeFile.id}/portfolio/export/`

    fetch(url,
      {
        method: 'GET',
        headers: {
          Authorization:
              `Bearer ${localStorage.getItem('access_token')}`,
          'Access-Control-Request-Headers': '*',
        },
      })
      .then(async response => {
        if (response.ok) {
          const fileName = (response.headers.get('content-disposition') as string).split('"')[1]
          const blob = await response.blob()
          return { blob, fileName }
        }
        return undefined
      })
      .then(response => {
        if (response) {
          const blobUrl = window.URL.createObjectURL(response.blob)
          const link = document.createElement('a')
          link.href = blobUrl
          document.body.appendChild(link)
          link.setAttribute('download', response.fileName)
          dispatch(setExportAlert(false))
          setDownloading(true)
          link.click()
        } else {
          dispatch(setExportAlert(false))
          setError(true)
        }
      })
  }

  const warningText = exportAlert ? terms.Export.exportLoadingNotice : terms.Export.exportFail
  return (
    <>
      <Snackbar
        open={exportAlert || downloading || error}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={() => setTimeout(() => {
          setDownloading(false)
          setError(false)
        }, 1000)}
      >
        <Alert variant="filled" severity={downloading ? 'success' : 'warning'}>
          {downloading ? terms.Export.exportSuccess : warningText }
        </Alert>
      </Snackbar>
      <Typography className="category-title">{terms.MultiYearPlan.SettingsPanel.export}</Typography>
      <Button onClick={() => handlePdfExport()}>
        {terms.MultiYearPlan.SettingsPanel.pdfExport}
      </Button>
      <Button onClick={handleExcelExport}>
        {terms.MultiYearPlan.SettingsPanel.regeneratePortfolio}
      </Button>
    </>
  )
}
