/* eslint-disable max-len */
/* eslint-disable no-self-assign */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import nextId from 'react-id-generator'
import { RootState } from 'Store'
import { toggleOpenTbContextMenu, updateContextPosition } from 'reducers/MultiYearPlan/TitleBlockActions/titleblockactions'
import { TitleBlockType, TBTypes, CartoucheElement } from 'reducers/MultiYearPlan/TitleBlock/types'
import {
  Line, Layer, CustomLayerProps, Serie, Datum,
} from '@nivo/line'
import '../multiYearPlan.scss'
import { getTbElement } from 'reducers/MultiYearPlan/TitleBlockActions/titleblockactions.thunk'
import TbActionsMenu from './TbElementsAction/Buttons/TbActionsMenu'
import DeleteTbElementModal from './TbElementsAction/Modals/DeleteTbElementModal'
import ModifyTbElementModal from './TbElementsAction/Modals/ModifyTbElementModal'
import AddTbElementModal from './TbElementsAction/Modals/AddTbElementModal'

function LineChartTbObjects() {
  const dispatch = useDispatch()
  const { zoomPr } = useSelector((state: RootState) => state.remarkablePoints)
  const { activeFile } = useSelector((state: RootState) => state.files)
  const { TitleBlock } = useSelector((state: RootState) => state.mypTitleBlock)
  const { mypSectionsList } = useSelector((state: RootState) => state.multiYearPlan)
  const { exportSettings } = useSelector((state: RootState) => state.mypExportPdf)

  const toggleContextMenu = (point: any, event: React.MouseEvent<Element, MouseEvent>) => {
    dispatch(getTbElement({
      repertoryId: activeFile.directory,
      folderId: activeFile.folder,
      fileId: activeFile.id,
      tbElementId: point.data.id,
    }))
    dispatch(toggleOpenTbContextMenu(true))
    dispatch(updateContextPosition({ x: event.clientX, y: event.clientY }))
  }

  const formated = _.cloneDeep(TitleBlock) as unknown as TitleBlockType
  formated?.cartouche_display?.forEach((category: TBTypes) => (
    category.cartouche_elements.forEach((element: CartoucheElement) => {
      element.data = [
        {
          x: element.localization_start,
          label: element.label,
          id: element.id,
          middle: ((((zoomPr - 170) * element.localization_end) / mypSectionsList[mypSectionsList.length - 1]?.localizations[1]?.x) - (((zoomPr - 170) * element.localization_start) / mypSectionsList[mypSectionsList.length - 1]?.localizations[1]?.x)) / 2,
        },
        {
          x: element.localization_end - 0.2,
          id: element.id,
        },
      ]
      element.id = element.id
      element.color = formated.color
    })
  ))
  function Lines({
    series, lineGenerator, xScale, yScale,
  }: CustomLayerProps) {
    return series.map(({ id, data, color } : Serie) => (
      <>
        <>
          <path
            key={id}
            onClick={(event: React.MouseEvent<Element, MouseEvent>) => toggleContextMenu(data[0], event)}
            d={lineGenerator(
              data.map((d: Datum) => ({
                x: xScale(d.data.x),
                y: yScale(d.data.y),
              })),
            )}
            fill="none"
            stroke={color}
            className="operation-path"
            strokeWidth={38}
          />
          <g transform={`translate(${data[0].position.x},${data[0].position.y})`}>
            <text
              className="operation-label"
              dominantBaseline="middle"
              style={{
                color: 'black',
                fontSize: 16,
                fontFamily: 'sans-serif',
                textAnchor: 'middle',
                translate: `${data[0].data.middle}px 0px`,
                pointerEvents: 'none',
              }}
            >
              {data[0].data.label}
            </text>
          </g>

        </>
      </>
    ))
  }
  return (
    <>
      <div className="line-chart-container-tbo mt-5" id="line-chart-container-tbo" style={exportSettings.includeTileblock ? { display: 'block' } : { display: 'none' }}>
        <TbActionsMenu />
        <DeleteTbElementModal />
        <ModifyTbElementModal />
        <AddTbElementModal />
        <React.Fragment key={nextId()}>
          <div className="pl-2 year-bar no-color" style={{ width: zoomPr - 151 }}><span>test</span></div>
          <div className="container2">
            {formated.cartouche_display.map((classification: any) => (
              <Line
                key={nextId()}
                width={zoomPr}
                height={40}
                data={classification.cartouche_elements}
                margin={{
                  top: 0,
                  right: 150,
                  bottom: 0,
                  left: 20,
                }}
                xScale={{
                  type: 'linear',
                  min: 0,
                  max: mypSectionsList[mypSectionsList.length - 1]?.localizations[1]?.x,
                }}
                yScale={{
                  type: 'point',
                }}
                axisBottom={null}
                axisTop={null}
                axisRight={null}
                axisLeft={null}
                yFormat=" >-.2f"
                enableGridX={false}
                enableGridY={false}
                colors={{ datum: 'color' }}
                enablePoints
                useMesh
                enablePointLabel
                pointLabel="label"
                tooltip={() => <div className="line-chart-tooltip" />}
                lineWidth={38}
                layers={
                  [
                    'grid',
                    'axes',
                    'areas',
                    'slices',
                    'mesh',
                    'legends',
                    Lines,
                  ] as Layer[]
          }
              />
            ))}
          </div>
        </React.Fragment>
      </div>
    </>

  )
}
export default LineChartTbObjects
