import terms from 'common/terms'
import TopBar from 'components/TopBar'
import { ReactElement } from 'react'
import DashboardNavBar from 'components/Dashboard/DashboardNavBar'

export default function RepertoriesContent(): ReactElement {
  return (
    <>
      <TopBar appName={terms.Common.appName} />
      <DashboardNavBar />
    </>
  )
}
