import { ReactElement, useState } from 'react'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import {
  Button, IconButton, Menu, MenuItem,
} from '@mui/material'
import { patchFile } from 'reducers/Files/files.thunk'
import terms from 'common/terms'
import './file.scss'
import { Files } from 'reducers/Files/types'
import {
  toggleMoveToFolder, toggleDisplayUpdateModal, updateContextFile,
  toggleDisplayDeleteModal, toggleDisplayDeplaceModal, toggleCloneFile,
  toggleDisplayShareModal,
} from 'reducers/Files/files'
import DuplicateFile from './Actions/DuplicateFile'

export default function FileActionsMenu({ file }: {file: Files}): ReactElement {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const { activeFolder } = useSelector((state: RootState) => state.folders)
  const { activeRepertory } = useSelector((state: RootState) => state.repertories)

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    dispatch(toggleMoveToFolder())
    dispatch(toggleCloneFile())
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onClick = (action: (v: boolean) => void) => {
    dispatch(updateContextFile(file))
    dispatch(action(true))
    setAnchorEl(null)
  }

  const onClickMove = () => {
    dispatch(updateContextFile(file))
    setAnchorEl(null)
    if (activeFolder) {
      dispatch(patchFile({
        repertoryId: activeRepertory.id,
        folderId: activeFolder.id,
        file: {
          id: file.id,
          folder: null,
        },
      }))
    } else {
      dispatch(toggleDisplayDeplaceModal(true))
    }
  }

  return (
    <>
      <IconButton onClick={handleOpen}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="file-action-menu"
      >
        <MenuItem>
          <Button
            className="actions-menu-btn"
            onClick={() => onClick(toggleDisplayUpdateModal)}
          >
            {terms.Common.rename}
          </Button>
        </MenuItem>
        <MenuItem>
          <Button
            className="actions-menu-btn"
            onClick={() => onClick(toggleDisplayDeleteModal)}
          >
            {terms.Common.delete}
          </Button>
        </MenuItem>
        <MenuItem>
          <DuplicateFile file={file} handleClose={handleClose} />
        </MenuItem>
        <MenuItem>
          <Button
            className="actions-menu-btn"
            onClick={() => onClick(toggleDisplayShareModal)}
          >
            {terms.File.Actions.share}
          </Button>
        </MenuItem>
        <MenuItem>
          <Button
            className="actions-menu-btn"
            onClick={onClickMove}
          >
            {activeFolder ? `${terms.File.Actions.moveTo} ${activeRepertory.label}` : terms.File.Actions.moveToFolder}
          </Button>
        </MenuItem>
      </Menu>
    </>
  )
}
