import { Project, Section, Task } from '../types'
import { IMPORT_STATUS, TASKS_NAMES } from './types'

export const getTaskStatus = (project: Project | any, importedElement: string)
: string | undefined => project?.tasks?.filter((task: Task) => task.name === importedElement)[0]?.status

export const getValidatedSections = (project: Project): number => {
  let validatedSections = 0
  project?.sections?.forEach((section: Section) => {
    if (section.tasks) {
      validatedSections += section.tasks.filter((task: Task) => task.name
        === TASKS_NAMES.Validate && task.result === (IMPORT_STATUS.Validated || null)).length
    }
  })
  return validatedSections
}
