import { Paper, Typography } from '@mui/material'
import terms from 'common/terms'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addNewFolder, setEditionMode, setFolderToDelete } from 'reducers/Folders/folder'
import { XYPosition } from 'reducers/Folders/types'
import { RootState } from 'Store'

export default function ContextMenu({
  context, xyPosition, isCreation,
}:{context: boolean, xyPosition: XYPosition, isCreation?: boolean}): ReactElement {
  const dispatch = useDispatch()
  const { editableFolder, editionMode } = useSelector((state: RootState) => state.folders)

  const handleClick = () => {
    dispatch(addNewFolder({
      id: -1,
      creation_date: (new Date()).toISOString(),
      last_modified: (new Date()).toISOString(),
      label: 'Dossier sans titre',
    }))
  }

  const handleRename = () => {
    dispatch(setEditionMode(true))
  }

  const handleDelete = () => {
    if (editableFolder) {
      dispatch(setFolderToDelete(editableFolder))
    }
  }

  return (
    <>
      {context && !editionMode && (
        <Paper
          sx={{
            top: xyPosition.y,
            left: xyPosition.x,
            width: isCreation ? '142px' : '273px',
            height: isCreation ? '43px' : '113px',
          }}
          className="context-menu"
          onClick={() => (isCreation ? handleClick() : null)}
        >
          {isCreation ? (
            <Typography className="context-menu-text" sx={{ textAlign: 'center' }}>
              {terms.Folder.createFolder}
            </Typography>
          ) : (
            <>
              <Typography className="context-menu-text" onClick={handleRename}>
                {terms.Common.rename}
              </Typography>
              <Typography className="context-menu-text" onClick={handleDelete}>
                {terms.Folder.deleteFolder}
              </Typography>
            </>
          )}
        </Paper>
      )}
    </>
  )
}

ContextMenu.defaultProps = {
  isCreation: false,
}
