import terms from 'common/terms'
import { ResponseError } from 'types'

export const dropRejected: ResponseError = {
  status: 'dropRejected',
  message: '',
}

export const dropError: ResponseError = {
  status: 'dropError',
  message: '',
}

export const getErrorMessage = (error: ResponseError | undefined) => {
  switch (true) {
    case (!error):
      return ''
    case (error?.status === 'dropRejected'):
      return terms.Common.Errors.dropRejected
    default:
      return terms.Common.Errors.error
  }
}
