import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkApi } from 'types'
import {
  deleteRequest, get, post,
} from '@osrdata/app_core/dist/requests'
import {
  DeleteParams, Filter, FiltersChoices, PostParams,
} from './types'
import { GetParams } from '../types'

const getFiltersList = createAsyncThunk<FiltersChoices[], GetParams, ThunkApi>(
  'mypSettings/getFiltersList',
  async (params: GetParams, thunkApi) => {
    if (params.folderId && params.folderId !== null) {
      try {
        const response: FiltersChoices[] = await
        // eslint-disable-next-line max-len
        get(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.fileId}/operation-filter-choices/`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response: FiltersChoices[] = await
        get(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/operation-filter-choices/`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

const getAppliedFiltersList = createAsyncThunk<Filter[], GetParams, ThunkApi>(
  'mypSettings/getAppliedFiltersList',
  async (params: GetParams, thunkApi) => {
    if (params.folderId && params.folderId !== null) {
      try {
        const response: Filter[] = await
        // eslint-disable-next-line max-len
        get(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.fileId}/operation-filters/`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response: Filter[] = await
        get(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/operation-filters/`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

const postFilter = createAsyncThunk<Filter, PostParams, ThunkApi>(
  'mypSettings/postFilter',
  async (params: PostParams, thunkApi) => {
    if (params.folderId && params.folderId !== null) {
      try {
        const response: Filter = await
        // eslint-disable-next-line max-len
        post(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.fileId}/operation-filters/`,
          params.filter)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response: Filter = await
        // eslint-disable-next-line max-len
        post(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/operation-filters/`, params.filter)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

const deleteFilter = createAsyncThunk<number, DeleteParams, ThunkApi>(
  'mypSettings/deleteFilter',
  async (params: DeleteParams, thunkApi) => {
    if (params.folderId && params.folderId !== null) {
      try {
        await
        // eslint-disable-next-line max-len
        deleteRequest(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.fileId}/operation-filters/${params.filterId}/`)
        return params.filterId
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        await
        // eslint-disable-next-line max-len
        deleteRequest(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/operation-filters/${params.filterId}/`)
        return params.filterId
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

export {
  getFiltersList,
  getAppliedFiltersList,
  postFilter,
  deleteFilter,
}
