import { ReactElement } from 'react'
import { IoIosArrowRoundBack, IoIosClose } from 'react-icons/io'
import { IconContext } from 'react-icons'
import './topbar.scss'

type Props = {
    onStepBack: () => void
    getPreviousStep: () => string
    onClose: () => void
    stepper?: ReactElement
    closeIcon?: boolean
}

const defaultProps = {
  stepper: <></>,
  closeIcon: true,
}

export default function GenericTopBar({
  onStepBack, getPreviousStep, onClose, stepper, closeIcon,
}: Props): ReactElement {
  return (
    <div className="top-bar">
      <div className="top-bar-content">
        <div className="previous-step">
          <IconContext.Provider value={{ size: '2.5em' }}>
            <div className="previous-step-icon">
              <IoIosArrowRoundBack className="action-cursor-pointer" onClick={onStepBack} width={30} />
            </div>
          </IconContext.Provider>
          <div className="previous-step-text">{getPreviousStep()}</div>
        </div>
        <div>{stepper}</div>
        {closeIcon && (
        <IconContext.Provider value={{ size: '2.5em' }}>
          <div className="btn-close">
            <IoIosClose className="action-cursor-pointer" onClick={onClose} />
          </div>
        </IconContext.Provider>
        )}
      </div>
    </div>
  )
}

GenericTopBar.defaultProps = defaultProps
