import { updateEditionParams } from 'reducers/label'
import { store } from 'Store'
import { LabelsLayersIds } from '../types'

export function getFeatures(objectId: string) {
  const { labelLayerData, labelToUpdate } = store.getState().label
  if (labelLayerData) {
    const selectedFeature = labelLayerData.features.filter(
      (feature: GeoJSON.Feature) => feature.properties?.object_id === objectId,
    )
    const oldFeaturesGeometry: any = []
    if (labelToUpdate.layer.id === LabelsLayersIds.label) {
      selectedFeature[0].geometry.coordinates.map((coord: any, index: number) => {
        if (index !== 0) {
          oldFeaturesGeometry.push(coord)
        }
        return oldFeaturesGeometry
      })
    }
    return oldFeaturesGeometry
  }
  return null
}

export function getUpdatedFeatures(params: any): void {
  const { labelLayerData } = store.getState().label
  const coordinatesList: any = []

  if (params[0].layer.id === LabelsLayersIds.label) {
    coordinatesList.push(params[0].geometry.coordinates)
    getFeatures(params[0].properties.object_id)?.forEach((f: any) => coordinatesList.push(f))
  }
  if (params[0].layer.id === LabelsLayersIds.actionZones) {
    const selectedFeature = labelLayerData.features.filter(
      (feature: GeoJSON.Feature) => feature.properties?.object_id === params[0].properties.object_id,
    )
    coordinatesList.push(selectedFeature[0].geometry.coordinates[0])
    params.forEach((feature: any) => coordinatesList.push(feature.geometry.coordinates))
  }

  const newFeature = {
    geometry: {
      type: 'Multipolygon',
      coordinates: coordinatesList,
    },
    properties: {},
  }
  if (newFeature) store.dispatch(updateEditionParams(newFeature))
}

export function getFeatureStyle() {
  const { labelToUpdate } = store.getState().label
  return {
    stroke: '#FFFF38',
    strokeWidth: 3,
    fill: labelToUpdate?.properties.color === null ? '#FFFFFF' : labelToUpdate?.properties.color,
    fillOpacity: 0.8,
  }
}
