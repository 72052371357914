import FormInput from 'components/common/Inputs/FormInput'
import { ReactElement, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { updateCartridgeWarnings } from 'reducers/export'
import { RootState } from 'Store'
import { TiWarning } from 'react-icons/ti'
import terms from 'common/terms'
import { CARTRIDGE_WARNINGS } from '../ExportFields'

export default function CartridgeWarnings(): ReactElement {
  const dispatch = useDispatch()
  const { cartridge, cartridgeWarnings } = useSelector((state: RootState) => state.export)
  const [warnings, setWarnings] = useState<string>('')

  useEffect(() => {
    if (cartridge) {
      dispatch(updateCartridgeWarnings(cartridge.warnings))
    }
  }, [cartridge])

  const handleChange = (value: string) => {
    setWarnings(value)
    dispatch(updateCartridgeWarnings(value))
  }

  return (
    <div className="form-container cartouche-warnings-container">
      <div className="warning">
        <TiWarning />
        {terms.Export.warning}
      </div>
      <Form autoComplete="off">
        <FormInput
          field={CARTRIDGE_WARNINGS}
          onChange={e => handleChange(e.target.value)}
          value={cartridgeWarnings || warnings}
        />
      </Form>
    </div>
  )
}
