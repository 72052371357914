import terms from 'common/terms'
import { ReactElement } from 'react'
import { Dropdown } from 'react-bootstrap'
import { AiOutlineEllipsis } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { updateVersions } from 'reducers/export'
import { RootState } from 'Store'
import ExportService from '../ExportService'
import { Cartridge, CartridgeVersion, VersionsFields } from '../types'

export default function CartridgeDropdown(): ReactElement {
  const dispatch = useDispatch()
  const {
    versions, cartridgeTitle, cartridgeWarnings, cartridge,
  } = useSelector((state: RootState) => state.export)

  const addVesrion = () => {
    const newVersion: VersionsFields[] = [{
      index: versions.length + 1,
      version: {
        author: '',
        comment: '',
        date: '',
        index: null,
        version: '',
      },
    }]
    dispatch(updateVersions(versions.concat(newVersion)))
  }

  const onValidate = () => {
    const versionsList: CartridgeVersion[] = []
    versions.forEach((version: VersionsFields) => {
      if (version.version.index !== null) versionsList.push(version.version)
    })
    const params: Cartridge = {
      project: cartridge.project,
      title: cartridgeTitle,
      warnings: cartridgeWarnings,
      versions: versionsList,
    }
    dispatch(ExportService.updateCartridge(params))
  }

  return (
    <Dropdown autoClose="outside" drop="end">
      <Dropdown.Toggle size="sm" variant="outline-light" className="card-tools-btn" id="dropdown-basic">
        <AiOutlineEllipsis />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          as="button"
          onClick={addVesrion}
        >
          {terms.Export.addVersion}
        </Dropdown.Item>
        <Dropdown.Item
          as="button"
          onClick={onValidate}
        >
          {terms.Export.validate}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}
