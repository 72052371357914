import { ReactElement, SyntheticEvent, useState } from 'react'
import {
  Autocomplete, FormHelperText, InputLabel, TextField, Tooltip,
} from '@mui/material'
import { SubClassification } from 'reducers/MultiYearPlan/Import/ObjectsFamilies/type'
import { Classification } from 'reducers/MultiYearPlan/Operations/types'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

type Props = {
    value: Classification | SubClassification | string | number | null | undefined
    onChange: (event: SyntheticEvent<Element, Event>,
         v: Classification | SubClassification | string | number | null | undefined) => void
    options: Classification[] | SubClassification[] | string[] | number[]
    placeholder: string
    label: string
    disabled?: boolean
    extraStyle?: string
    errorMessage: string
    optionLabelType?: string
}
export default function OperationSelectField({
  value, onChange, options, placeholder, label, disabled, extraStyle, errorMessage, optionLabelType,
}: Props): ReactElement {
  const [inputValue, setInputValue] = useState<string>('')

  const OptionLabel = (option: Classification | SubClassification | string | number) => {
    if (typeof option === 'string' || typeof option === 'number') {
      return option.toString()
    } if (optionLabelType === 'code') {
      return option.code.toString()
    }
    return option.label.toString()
  }

  return (
    <div className={extraStyle}>
      <InputLabel shrink sx={{ color: errorMessage !== '' ? '#FF5737' : '#8A8A8A' }}>{label}</InputLabel>
      <Autocomplete
        value={value}
        onChange={onChange}
        inputValue={inputValue}
        onInputChange={(_event, newInputValue) => setInputValue(newInputValue)}
        renderOption={(props, option) => {
          if (optionLabelType === 'code') {
            return (
              <Tooltip title={(option as SubClassification).label} placement="top-start">
                <li {...props}>{OptionLabel(option)}</li>
              </Tooltip>
            )
          }
          return <li {...props}>{OptionLabel(option)}</li>
        }}
        options={options}
        getOptionLabel={option => OptionLabel(option)}
        renderInput={params => <TextField {...params} placeholder={value ? '' : placeholder} />}
        disabled={disabled}
      />
      {errorMessage !== '' && (
      <div className="input-error-container">
        <ErrorOutlineIcon className="mr-1" />
        <FormHelperText>{errorMessage}</FormHelperText>
      </div>
      )}
    </div>
  )
}

OperationSelectField.defaultProps = {
  disabled: false,
  extraStyle: '',
  optionLabelType: '',
}
