import { polygon } from '@turf/helpers'
import turfBooleanPointInPolygon from '@turf/boolean-point-in-polygon'
import turfIntersects from '@turf/boolean-intersects'
import {
  BoundingBoxArray, FeatureCollection, LineString, Polygon,
} from '@nebula.gl/edit-modes'
import { WebMercatorViewport } from 'deck.gl'
import { GeoEditorState, SnappingFeature } from 'components/GeoEditor/reducer'
import { store } from 'Store'
import { Grid } from '../GridLayer'

export type Viewport = WebMercatorViewport & {
  getBounds: () => BoundingBoxArray;
};

const updateSnappingFeatures = (
  viewport: Viewport | undefined, featureCollection?: FeatureCollection,
): SnappingFeature[] => {
  const { grid } = store.getState().geoEditor as GeoEditorState
  if (viewport) {
    const [minX, minY, maxX, maxY] = viewport.getBounds()
    const polygonBbox = polygon([[
      [minX, minY],
      [maxX, minY],
      [maxX, maxY],
      [minX, maxY],
      [minX, minY],
    ]])
    const newSnappingFeatures: SnappingFeature[] = []
    const collection = featureCollection || grid || Grid
    collection.features.forEach(feat => {
      if ((feat.geometry.type === 'LineString'
            && turfIntersects(polygonBbox.geometry as Polygon, feat.geometry as LineString))
            || (feat.geometry.type === 'Point'
            && turfBooleanPointInPolygon(feat.geometry.coordinates, polygonBbox.geometry))) {
        newSnappingFeatures.push(feat as SnappingFeature)
      }
    })
    return newSnappingFeatures
  }
  return []
}

export default updateSnappingFeatures
