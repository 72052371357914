import {
  ReactElement, useEffect, useRef, useState,
} from 'react'
import {
  FormHelperText,
  InputBase, InputLabel, Paper,
} from '@mui/material'

import './input.scss'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { InputsProps } from './types'

export default function GenericInput({
  label, placeholder, value, onChange, inputProps, extraStyle, errorMessage, labelColor, autoFocus,
}: InputsProps): ReactElement {
  const curserPos = useRef(0)
  const [inputEvent, setInputEvent] = useState<React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>>()

  useEffect(() => {
    if (inputEvent) {
      inputEvent.target.setSelectionRange(curserPos.current, curserPos.current)
    }
  }, [value, inputEvent])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInputEvent(e)
    if (e.target.selectionStart !== null) {
      curserPos.current = e.target.selectionStart
    }
    onChange(e)
  }

  return (
    <div className={`generic-input ${extraStyle}`}>
      <InputLabel shrink sx={{ color: errorMessage !== '' ? '#FF5737' : labelColor }}>
        {label}
      </InputLabel>
      <Paper
        elevation={0}
        component="form"
        autoComplete="off"
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          onKeyPress={event => {
            if (event.key === 'Enter') { event.preventDefault() }
          }}
          inputProps={inputProps}
          autoFocus={autoFocus}
        />
      </Paper>
      <div className="input-error-container">
        {errorMessage !== '' && <ErrorOutlineIcon className="mr-1" />}
        <FormHelperText>{errorMessage}</FormHelperText>
      </div>
    </div>
  )
}

GenericInput.defaultProps = {
  inputProps: undefined,
  extraStyle: '',
  errorMessage: '',
  labelColor: '#E1E1E1',
}
