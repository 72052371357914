import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { ThemeProvider } from '@mui/material/styles'
import { BrowserTracing } from '@sentry/tracing'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import theme from 'common/CustomTheme'
import { store, persistor } from './Store'
import * as serviceWorker from './serviceWorker'

import App from './App'
import './i18n'
import './index.css'

import '@sncf/bootstrap-sncf.metier/dist/bootstrap-sncf.min.css'

require('@sncf/bootstrap-sncf.metier')

Sentry.init({
  dsn: 'https://3585ba7f26b84d989e9005e0cb872b18@sentry.shared.dgexsol.fr/49',
  environment: process.env.REACT_APP_REALM,
  integrations: [new BrowserTracing()],
  sampleRate: 1.0,
  tracesSampleRate: 0.01,
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
