import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { patchFolderPortfolio, patchRepertoryPortfolio } from 'reducers/MultiYearPlan/Import/import.thunk'
import { RootState } from 'Store'
import ImportActionButton from './ImportActionButton'

export default function CriteriaConfirmationButton(): ReactElement {
  const dispatch = useDispatch()
  const { selectedAttributValue, isLoading } = useSelector((state: RootState) => state.mypImport)
  const { activeFile } = useSelector((state: RootState) => state.files)

  const handleClick = () => {
    if (activeFile.folder !== null) {
      dispatch(patchFolderPortfolio({
        repertoryId: activeFile.directory,
        folderId: activeFile.folder,
        fileId: activeFile.id,
        portfolio: { column_criteria: selectedAttributValue?.id },
      }))
    } else {
      dispatch(patchRepertoryPortfolio({
        repertoryId: activeFile.directory,
        fileId: activeFile.id,
        portfolio: { column_criteria: selectedAttributValue?.id },
      }))
    }
  }
  return (
    <ImportActionButton handleClick={handleClick} disabled={selectedAttributValue === null || isLoading} />
  )
}
