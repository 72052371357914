import { ReactElement, useEffect } from 'react'
import { Container, Fade } from '@mui/material'
import ContainerHeader from 'components/common/Container/ContainerHeader'
import ContainerBody from 'components/common/Container/ContainerBody'
import ContainerFooter from 'components/common/Container/ContainerFooter'
import SectionsList from 'components/Files/Creation/GeographicPerimeter/SectionsList'
import ImportLoader from 'components/common/Container/ImportLoader'
import { CreationLevels } from 'components/Dashboard/types'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { FileTypes } from 'reducers/Files/types'
import { DeclarationMode } from 'reducers/MultiYearPlan/types'
import terms from 'common/terms'
import { patchFile, updateGeographicPerimeter } from 'reducers/Files/files.thunk'
import { toggleDisplaySettingsPanel } from 'reducers/MultiYearPlan/Settings/settings'
import { resetState, toggleUpdateMypSections } from 'reducers/MultiYearPlan/multiYearPlan'
import { toggleAbortChanges, toggleDisplayUpdateGeographicModal } from 'reducers/Files/files'
import { getFilesSectionsList } from 'reducers/MultiYearPlan/multiYearPlan.thunk'
import GeographicPerimeterInputs from './GeographicPerimeterInputs'
import GeographicPerimeterButton from './GeographicPerimeterButton'
import {
  getRollBackText,
  getSubtitle, getTitle, handleClose, handleRollBack,
} from './utils'
import DeclarationMethodeChoice from './DeclarationMethodeChoice'
import ImportFile from './ImportFile'
import SectionsResetNotice from './SectionsResetNotice'
import UpdateGeographicPerimeterModal from './UpdateGeographicPerimeterModal'
import { displayTabContent } from '../utils'

export default function GeographicPerimeterContainer(): ReactElement {
  const dispatch = useDispatch()
  const { activeRepertory } = useSelector((state: RootState) => state.repertories)
  const { activeFolder } = useSelector((state: RootState) => state.folders)
  const {
    activeFile, abortFileUpdate, abortGeographicPerimeterUpdate, displayUpdateGeographicLoader, isLoading,
  } = useSelector((state: RootState) => state.files)
  const {
    creationLevel, sectionsList, validationNotice,
  } = useSelector((state: RootState) => state.creation)
  const {
    mypCreationStatus, geoPerimeterDeclarationMode, mypSectionsList, backToDeclarationChoice, deletedSection,
    mypValidationNotice, updateMypSections,
  } = useSelector((state: RootState) => state.multiYearPlan)
  const { displaySettingsPanel } = useSelector((state: RootState) => state.mypSettings)
  const { tabsList } = useSelector((state: RootState) => state.dashboard)

  useEffect(() => {
    if (abortFileUpdate && abortGeographicPerimeterUpdate) {
      dispatch(getFilesSectionsList({
        repertoryId: activeFile.directory,
        folderId: activeFile.folder,
        fileId: activeFile.id,
      }))
      dispatch(toggleAbortChanges())
      dispatch(toggleDisplaySettingsPanel(false))
      dispatch(resetState())
      displayTabContent(tabsList, activeFile, activeRepertory, activeFolder)
    }
  }, [abortFileUpdate, abortGeographicPerimeterUpdate])

  const getContainerBodyContent = () => {
    if ((activeFile.type === FileTypes.Synoptic && creationLevel !== CreationLevels.Geographic)) {
      return <GeographicPerimeterInputs />
    }
    if (activeFile.type === FileTypes.Schema && !displayUpdateGeographicLoader && !isLoading) {
      if ((mypCreationStatus !== CreationLevels.Geographic && (geoPerimeterDeclarationMode === DeclarationMode.Manual
      || (geoPerimeterDeclarationMode === DeclarationMode.Import && mypSectionsList.length !== 0)))
       || (mypSectionsList.length !== 0 && !backToDeclarationChoice) || deletedSection
        || (mypSectionsList.length !== 0 && displaySettingsPanel)) {
        return <GeographicPerimeterInputs />
      }
      if (geoPerimeterDeclarationMode === DeclarationMode.Import) {
        return <ImportFile />
      }
      return <DeclarationMethodeChoice />
    }
    return <></>
  }

  const onClose = () => {
    if (updateMypSections) {
      dispatch(patchFile({
        repertoryId: activeFile.directory,
        folderId: activeFile.folder,
        file: { id: activeFile.id, abort_change: true },
      }))
      dispatch(updateGeographicPerimeter({
        repertoryId: activeFile.directory,
        folderId: activeFile.folder,
        file: { id: activeFile.id, abort_change: true },
      }))
      dispatch(toggleUpdateMypSections(false))
      dispatch(toggleDisplayUpdateGeographicModal(false))
    } else {
      handleClose(activeFolder, activeRepertory, activeFile)
    }
  }

  return (
    <>
      <Fade timeout={400} in mountOnEnter unmountOnExit>
        <Container
          maxWidth={false}
          disableGutters
          sx={{
            background: creationLevel === CreationLevels.Geographic || displayUpdateGeographicLoader || isLoading
              ? '#183B68' : '',
          }}
          className="geographic-content-container"
        >
          <ContainerHeader
            onRollBack={() => handleRollBack(activeFile.type === FileTypes.Synoptic ? creationLevel : mypCreationStatus,
              activeFile.type, geoPerimeterDeclarationMode, mypSectionsList, backToDeclarationChoice, deletedSection)}
            rollBackText={getRollBackText(creationLevel, mypSectionsList, backToDeclarationChoice, deletedSection,
              geoPerimeterDeclarationMode)}
            onClose={onClose}
            tooltip={geoPerimeterDeclarationMode !== '' || mypSectionsList.length !== 0}
            tooltipTitle={terms.File.Declaration.geographicCloseNotice}
            displayRollBackBtn={!updateMypSections}
          />
          <ContainerBody
            title={getTitle(creationLevel, displayUpdateGeographicLoader, isLoading)}
            subtitle={creationLevel === CreationLevels.Geographic || displayUpdateGeographicLoader || isLoading ? (
              <ImportLoader
                title={activeFile.type === FileTypes.Schema ? terms.File.Declaration.Schema.spLoaderNotice
                  : terms.File.Declaration.Synoptic.initializationNotice}
              />
            )
              : getSubtitle(activeFile.type)}
            content={getContainerBodyContent()}
          />
          {(creationLevel === CreationLevels.Temporal || (mypCreationStatus === CreationLevels.Temporal
          && !backToDeclarationChoice && !displayUpdateGeographicLoader && !isLoading)) && (
            <div className="section-container">
              <SectionsList
                sectionsList={activeFile.type === FileTypes.Schema ? mypSectionsList : sectionsList}
                validationNotice={activeFile.type === FileTypes.Schema ? mypValidationNotice : validationNotice}
                activeFile={activeFile}
                fileType={activeFile.type}
              />
            </div>
          )}
          {backToDeclarationChoice && <SectionsResetNotice />}
          {((activeFile.type === FileTypes.Synoptic && creationLevel !== CreationLevels.Geographic)
          || (activeFile.type === FileTypes.Schema && mypCreationStatus !== CreationLevels.Geographic
            && (geoPerimeterDeclarationMode !== '' || (mypSectionsList.length !== 0 && !backToDeclarationChoice)
              || (mypSectionsList.length === 0 && deletedSection) || (mypSectionsList.length !== 0
                && displaySettingsPanel)) && !displayUpdateGeographicLoader && !isLoading))
          && <ContainerFooter content={<GeographicPerimeterButton />} />}
        </Container>
      </Fade>
      <UpdateGeographicPerimeterModal />
    </>
  )
}
