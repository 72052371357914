import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import CustomStepper from 'components/common/Stepper/CustomStepper'
import IMPORT_STEPS from '../ImportSteps'
import ImportDocumentContent from './ImportDocumentContent'
import FerrolocalizationContent from './FerroLocalizationContent'
import GenerateLabels from './GenerateLabels'

export default function StepsContent(): ReactElement {
  const { activeStep, ferrolocalizationNoticeDisplay } = useSelector((state: RootState) => state.import)

  switch (activeStep) {
    case -1:
      return <CustomStepper steps={IMPORT_STEPS} orientation="vertical" />
    case 0:
      return <ImportDocumentContent />
    case 2:
      return !ferrolocalizationNoticeDisplay ? <FerrolocalizationContent /> : <></>
    case 3:
      return <GenerateLabels />
    default:
      return <></>
  }
}
