import { Section } from 'components/Dashboard/types'
import { ReactElement } from 'react'
import { FiTrash } from 'react-icons/fi'
import { MdEdit } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { getSectionStatus } from './utils'
import ValidationNotice from './ValidationNotice'

type Props = {
    sectionsList: Section[]
    onSectionUpdate: (v: Section) => void
    onSectionDelete: (v: number) => void
    validationNotice: boolean
}
export default function SynopticTableBody({
  sectionsList, onSectionUpdate, onSectionDelete, validationNotice,
}: Props): ReactElement {
  const { activeProject } = useSelector((state: RootState) => state.creation)
  return (
    <tbody>
      {sectionsList && sectionsList.map((section: Section) => (
        <tr key={section.id}>
          <td className="section-label">{section.name}</td>
          <td>{section.code_ligne}</td>
          <td>{section.pk_sncf_debut}</td>
          <td>{section.pk_sncf_fin}</td>
          <td>
            <div className="mr-4">
              <MdEdit
                className="action-cursor-pointer"
                onClick={() => onSectionUpdate(section)}
              />
            </div>
            <div className="mr-4">
              <FiTrash
                className="action-cursor-pointer"
                onClick={() => onSectionDelete(section.id)}
              />
            </div>
            {validationNotice
                     && (
                     <ValidationNotice
                       status={getSectionStatus(section as Section, activeProject)}
                     />
                     )}
          </td>
        </tr>
      ))}
    </tbody>
  )
}
