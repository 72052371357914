import terms from 'common/terms'
import SnackBar from 'components/common/Snackbar/SnackBar'
import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { updateResponseError } from 'reducers/MultiYearPlan/Import/import'
import { RootState } from 'Store'
import { MypImportStepsList } from '../types'
import ImportStepsContent from './ImportStepsContent'

export default function ImportContentContainer(): ReactElement {
  const { responseError, activeStep } = useSelector((state: RootState) => state.mypImport)

  const getErrorMessage = () => {
    if (responseError.status === 500 || responseError.status === 502) {
      return terms.Common.Errors.error
    }
    if (responseError.message.file) {
      return responseError.message.file
    }
    return ''
  }

  return (
    <>
      {responseError && ((responseError.status === 500 || responseError.status === 502
       || activeStep === MypImportStepsList.UploadFile)) && (
       <SnackBar
         message={getErrorMessage()}
         error={responseError !== undefined}
         setError={updateResponseError}
       />
      )}
      <ImportStepsContent />
    </>
  )
}
