import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import PathNames from 'common/PathNames'
import { OrderBy } from 'components/Dashboard/types'
import history from 'customHistory'
import { ResponseError } from 'types'
import {
  deleteRepertory, getRepertoriesList, patchRepertory, postRepertory,
} from './repertories.thunk'
import { RepertoriesState, Repertory } from './types'

const initialState: RepertoriesState = {
  orderBy: OrderBy.DescendingLastModification,
  search: '',
  newLabel: '',
  repertories: [],
  displayDeleteModal: false,
  contentSearch: '',
  orderContentBy: OrderBy.DescendingLastModification,
  updateImage: false,
  contextRepertoryId: undefined,
  createdRepertory: false,
  searchingContent: false,
  isloading: false,
}

export const repertoriesSlice = createSlice({
  name: 'repertories',
  initialState,
  reducers: {
    updateOrderBy: (state, action: PayloadAction<string>) => {
      state.orderBy = action.payload
    },
    updateSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload
    },
    updateLabel: (state, action: PayloadAction<string>) => {
      state.newLabel = action.payload
    },
    updateContextRepertoryId: (state, action: PayloadAction<number>) => {
      state.contextRepertoryId = action.payload
    },
    toggleDisplayDeleteModal: (state, action: PayloadAction<boolean>) => {
      state.displayDeleteModal = action.payload
    },
    updateActiveRepertory: (state, action: PayloadAction<Repertory>) => {
      state.activeRepertory = action.payload
    },
    updateContentSearch: (state, action: PayloadAction<string>) => {
      state.contentSearch = action.payload
      state.searchingContent = true
    },
    updateOrderContent: (state, action: PayloadAction<string>) => {
      state.orderContentBy = action.payload
    },
    resetActiveRepertory: state => {
      state.activeRepertory = undefined
      state.newLabel = ''
      state.contentSearch = ''
      state.orderContentBy = OrderBy.DescendingLastModification
      state.createdRepertory = false
      state.error = undefined
      state.searchingContent = false
    },
    setError: (state, action: PayloadAction<ResponseError | undefined>) => {
      state.error = action.payload
    },
    toggleUpdateImage: state => {
      state.updateImage = false
    },
    toggleCreatedRepertory: state => {
      state.createdRepertory = false
    },
  },
  extraReducers: builder => {
    builder.addCase(getRepertoriesList.fulfilled, (state, action) => {
      state.repertories = action.payload
    })

    builder.addCase(postRepertory.fulfilled, (state, action) => {
      state.activeRepertory = action.payload
      state.createdRepertory = true
    })
    builder.addCase(patchRepertory.fulfilled, (state, action) => {
      state.activeRepertory = action.payload
      state.error = undefined
      state.createdRepertory = true
      if (history.location.pathname.includes(PathNames.PicturePreviz)) {
        state.updateImage = true
        state.activeRepertory = undefined
        state.newLabel = ''
      }
    })
    builder.addCase(deleteRepertory.fulfilled, (state, action) => {
      state.repertories = state.repertories.filter(rep => rep.id !== action.payload)
      state.displayDeleteModal = false
      state.isloading = false
    })
    builder.addCase(deleteRepertory.pending, state => {
      state.isloading = true
    })
    builder.addMatcher(isAnyOf(
      getRepertoriesList.rejected,
      postRepertory.rejected,
      patchRepertory.rejected,
      deleteRepertory.rejected,
    ), (state, action) => {
      state.error = action.payload
      state.isloading = false
    })
  },
})

export const {
  updateOrderBy, updateLabel, updateSearch, updateActiveRepertory, toggleDisplayDeleteModal, updateContentSearch,
  updateOrderContent, resetActiveRepertory, setError, toggleUpdateImage, updateContextRepertoryId,
  toggleCreatedRepertory,
} = repertoriesSlice.actions

export default repertoriesSlice.reducer
