import { ReactElement, useEffect, useState } from 'react'
import terms from 'common/terms'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { getTaskStatus, getValidatedSections } from 'components/Dashboard/Creation/utils'
import { TASKS_NAMES, TASKS_STATUS } from 'components/Dashboard/Creation/types'
import CreationService from 'components/Dashboard/Creation/CreationService'
import { CreationLevels } from 'components/Dashboard/types'
import { updateActiveBlock } from 'reducers/synoptic'
import SnackBar from 'components/common/Snackbar/SnackBar'
import { resetResponseError } from 'reducers/creation'
import { resetMypResponseError, updateDeclarationMode } from 'reducers/MultiYearPlan/multiYearPlan'
import { FileTypes } from 'reducers/Files/types'
import GeographicPerimeterContainer from './GeographicPerimeterContainer'

import './geographicPerimeter.scss'
import { getSnackbarMessage } from './utils'
import { displayTabContent } from '../utils'

export default function GeographicPerimeterDeclaration(): ReactElement {
  const dispatch = useDispatch()
  const { activeRepertory } = useSelector((state: RootState) => state.repertories)
  const { activeFolder } = useSelector((state: RootState) => state.folders)
  const { createdFile, activeFile } = useSelector((state: RootState) => state.files)
  const {
    activeProject, creationLevel, modifySections, responseError,
  } = useSelector((state: RootState) => state.creation)
  const { tabsList } = useSelector((state: RootState) => state.dashboard)
  const { sectionResponseError, mypSectionsList } = useSelector((state: RootState) => state.multiYearPlan)
  const { displaySettingsPanel } = useSelector((state: RootState) => state.mypSettings)
  const [error, setError] = useState<boolean>(false)

  useEffect(() => {
    if (activeProject && activeProject.sections.length !== 0) {
      if (((getTaskStatus(activeProject, TASKS_NAMES.ImportVp) === TASKS_STATUS.Pending
        && getValidatedSections(activeProject) === activeProject.sections.length)
        || creationLevel === CreationLevels.Geographic)
      ) {
        setTimeout(() => {
          dispatch(CreationService.getSingleProject(activeProject.id))
        }, 1000)
      }
    }
  })

  useEffect(() => {
    if (activeProject && activeProject.sections.length !== 0) {
      if ((getValidatedSections(activeProject) === activeProject.sections.length
    || getTaskStatus(activeProject, TASKS_NAMES.ImportVp) === TASKS_STATUS.Failure)
    && creationLevel === CreationLevels.Temporal && !modifySections && createdFile) {
        dispatch(CreationService.importSections(activeProject.id))
      }
      if (activeProject.status === CreationLevels.Geographic) {
        if (getTaskStatus(activeProject, TASKS_NAMES.ImportVp) === TASKS_STATUS.Success) {
          dispatch(updateActiveBlock(terms.Synoptic.createFondPlan))
          displayTabContent(tabsList, createdFile, activeRepertory, activeFolder)
        } else if (getTaskStatus(activeProject, TASKS_NAMES.ImportVp) === TASKS_STATUS.Failure) {
          setError(true)
          dispatch(CreationService.updateProject({
            project: { ...activeProject, status: CreationLevels.Temporal },
            creationLevel: CreationLevels.Temporal,
          }))
        }
      }
    }
  }, [activeProject])

  useEffect(() => {
    if (activeFile && activeFile.type === FileTypes.Schema && mypSectionsList.length !== 0 && !displaySettingsPanel) {
      if (activeFile.status === CreationLevels.Geographic) {
        dispatch(updateDeclarationMode(''))
        displayTabContent(tabsList, activeFile, activeRepertory, activeFolder)
      }
    }
  }, [activeFile])

  useEffect(() => {
    if (activeProject) {
      if (getTaskStatus(activeProject, TASKS_NAMES.ImportVp) === TASKS_STATUS.Failure) setError(true)
    }
  }, [activeProject])

  useEffect(() => {
    if ((responseError && responseError.message.error)
    || (sectionResponseError && sectionResponseError.message.non_field_errors)) {
      setError(true)
    }
  }, [responseError, sectionResponseError])

  return (
    <main>
      {(getTaskStatus(activeProject, TASKS_NAMES.ImportVp) === TASKS_STATUS.Failure
       || (responseError && responseError.message.error)
       || (sectionResponseError && sectionResponseError.message.non_field_errors)) && (
       <SnackBar
         message={getSnackbarMessage(responseError || sectionResponseError)}
         error={error}
         setError={responseError ? resetResponseError : resetMypResponseError}
       />
      )}
      <GeographicPerimeterContainer />
    </main>
  )
}
