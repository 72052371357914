import { ReactElement, useEffect } from 'react'
import UpdateModal from 'components/common/Modals/UpdateModal'
import terms from 'common/terms'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { reset, toggleDisplaySettingsForm, updateExportFlag } from 'reducers/export'
import history from 'customHistory'
import PathNames from 'common/PathNames'
import { toggleShowGeoeditor } from 'reducers/object'
import { updateActiveBlock } from 'reducers/synoptic'
import { updateClickedFeatureInfo } from 'reducers/map'
import CreationService from 'components/Dashboard/Creation/CreationService'
import { toggleUpdateExportSettingsUpdated } from 'reducers/creation'
import ExportService from './ExportService'
import ExportSettingsForm from './ExportSettingsForm'

export default function ExportSettingsFormModal(): ReactElement {
  const dispatch = useDispatch()
  const {
    displaySettingsForm, rkStep, pageScale, realityScale, displayCartridge,
  } = useSelector((state: RootState) => state.export)
  const { exportSettingsUpdated } = useSelector((state: RootState) => state.creation)
  const { activeFile } = useSelector((state: RootState) => state.files)

  useEffect(() => {
    if (exportSettingsUpdated) {
      dispatch(toggleShowGeoeditor(false))
      dispatch(updateActiveBlock(terms.Synoptic.generateDoc))
      dispatch(updateClickedFeatureInfo(undefined))
      history.push(PathNames.Export)
      dispatch(reset())
      dispatch(updateExportFlag(true))
      dispatch(ExportService.getExportStatus(activeFile.id))
      dispatch(ExportService.exportProject({
        projectId: activeFile.id,
        params: { complete: false, include_cbdp: displayCartridge },
      }))
      dispatch(toggleUpdateExportSettingsUpdated(false))
    }
  }, [exportSettingsUpdated])

  const onModalClose = () => {
    dispatch(toggleDisplaySettingsForm(false))
  }
  const OnContinue = () => {
    dispatch(CreationService.updateExportSettings({
      projectId: activeFile.id, rkStep: +rkStep, pageScale: +pageScale, realityScale: +realityScale,
    }))
  }
  return (
    <UpdateModal
      open={displaySettingsForm}
      onModalClose={onModalClose}
      modalTitle={terms.Export.settingsFormTitle}
      content={<ExportSettingsForm />}
      onUpdate={OnContinue}
      actionType={terms.Common.continue}
      modalStyle="export-settings-modal"
      isDisabled={rkStep.length === 0 || pageScale.length === 0 || realityScale.length === 0}
    />
  )
}
