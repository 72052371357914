import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import terms from 'common/terms'
import {
  getTitleBlockFilters, pacthTitleBlockFilter,
} from 'reducers/MultiYearPlan/TitleBlock/titleblock.thunk'
import { FilterType } from 'reducers/MultiYearPlan/TitleBlock/types'
import FilterAccordion from './FilterAccordion'

export default function CartridgeAccordion(): ReactElement {
  const dispatch = useDispatch()
  const { activeFile } = useSelector((state: RootState) => state.files)
  const { TitleBlockFilters } = useSelector((state: RootState) => state.mypTitleBlock)

  useEffect(() => {
    dispatch(getTitleBlockFilters({
      repertoryId: activeFile.directory,
      folderId: activeFile.folder,
      fileId: activeFile.id,
    }))
  }, [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, option: FilterType) => {
    dispatch(pacthTitleBlockFilter({
      repertoryId: activeFile.directory,
      folderId: activeFile.folder,
      fileId: activeFile.id,
      filterId: option.id,
      objectFilter: {
        is_visible: event.target.checked,
      },
    }))
  }
  return (
    <FilterAccordion
      options={TitleBlockFilters}
      handleChange={(event, option) => handleChange(event, option as FilterType)}
      summary={terms.MultiYearPlan.SettingsPanel.cartridge}
    />
  )
}
