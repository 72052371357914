// eslint-disable-next-line import/prefer-default-export
export enum MypImportStepsList {
    UploadFile = 0,
    CriteriaChoice = 1,
    LabelChoice = 2,
    PortfolioFormat = 3,
    Ferrolocalization = 4,
    OperationChoice = 5,
    Parametrage = 6
}
