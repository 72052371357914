import { Button } from '@mui/material'
import terms from 'common/terms'
import { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import { cloneFolderFile, cloneRepertoryFile } from 'reducers/Files/files.thunk'
import { Files } from 'reducers/Files/types'

export default function DuplicateFile({ file, handleClose }: {file: Files, handleClose: () => void}): ReactElement {
  const dispatch = useDispatch()

  const handleClick = () => {
    if (file.folder !== null) {
      dispatch(cloneFolderFile(file))
    } else {
      dispatch(cloneRepertoryFile(file))
    }
    handleClose()
  }

  return (
    <Button
      className="actions-menu-btn"
      onClick={handleClick}
    >
      {terms.File.Actions.duplicate}
    </Button>
  )
}
