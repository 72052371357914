import { ReactElement } from 'react'
import {
  Box, IconButton, Modal, Typography,
} from '@mui/material'
import terms from 'common/terms'
import AddIcon from '@mui/icons-material/Add'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { PrFields } from 'reducers/RemarkablePoints/types'
import {
  toggleDisplayInputFieldsModal, toggleEmptyFields, updatePrFields, updateResponseError, updateSelectedPr,
} from 'reducers/RemarkablePoints/remarkablePoints'
import ActionButton from 'components/common/Buttons/ActionButton'
import Loader from 'components/Loader'
import PrFieldsContainer from './PrFieldsContainer'
import PrValidationButton from './PrValidationButton'

import '../../common/Modals/modal.scss'

export default function AddPrModal(): ReactElement {
  const dispatch = useDispatch()
  const {
    prFields, displayInputFieldsModal, selectedPr, loadingPr,
  } = useSelector((state: RootState) => state.remarkablePoints)

  const handleClick = () => {
    const newPrFields: PrFields[] = [{
      index: prFields.length + 1,
      pr: {
        line_code: '',
        kp: '',
        label: '',
        is_important: false,
      },
    }]
    dispatch(updatePrFields(prFields.concat(newPrFields)))
  }

  const handleClose = () => {
    dispatch(toggleDisplayInputFieldsModal(false))
    dispatch(updateResponseError([]))
    dispatch(toggleEmptyFields(false))
    if (selectedPr) {
      dispatch(updateSelectedPr(undefined))
    }
  }

  return (
    <Modal open={displayInputFieldsModal}>
      <Box className="pr-creation-modal">
        {loadingPr && <Loader center />}
        <div className="pr-dropdown-header">
          <Typography>
            {selectedPr ? terms.MultiYearPlan.Actions.updatePr : terms.MultiYearPlan.prToAdd}
          </Typography>
          {!selectedPr && (
          <div>
            <IconButton onClick={handleClick}><AddIcon /></IconButton>
          </div>
          )}
        </div>
        <PrFieldsContainer />
        <div className="add-pr-btns-container">
          <ActionButton
            text={terms.Common.cancel}
            onClick={handleClose}
            buttonStyle="cancel-add-pr-btn"
          />
          <PrValidationButton />
        </div>
      </Box>
    </Modal>
  )
}
