import { Container, Fade } from '@mui/material'
import ContainerBody from 'components/common/Container/ContainerBody'
import ContainerFooter from 'components/common/Container/ContainerFooter'
import ContainerHeader from 'components/common/Container/ContainerHeader'
import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import ImportLoader from 'components/common/Container/ImportLoader'
import terms from 'common/terms'
import { toggleLoadingRepresentation } from 'reducers/MultiYearPlan/OperationActions/operationactions'
import ImportButtonsContainer from './Button/ImportButtonsContainer'
import ImportContentContainer from './Content/ImportContentContainer'
import ParametrageContainer from './Content/ObjectsFamilies/ParametrageContainer'
import { MypImportStepsList } from './types'
import {
  getImportContainerNotice, getImportContainerTitle, getRollBackText, onClose, onRollBack,
} from './utils'

export default function MypImportSteps(): ReactElement {
  const dispatch = useDispatch()
  const { activeStep } = useSelector((state: RootState) => state.mypImport)
  const { activeFile } = useSelector((state: RootState) => state.files)
  const { displaySettingsPanel } = useSelector((state: RootState) => state.mypSettings)
  const {
    loadingRepresentation, generatedRepresentation,
  } = useSelector((state: RootState) => state.mypoperationactions)

  useEffect(() => {
    if (generatedRepresentation) {
      setTimeout(() => {
        dispatch(toggleLoadingRepresentation(false))
      }, 1000)
    }
  }, [generatedRepresentation])
  return (
    <main>
      <Fade timeout={400} in mountOnEnter unmountOnExit>
        <Container
          maxWidth={false}
          disableGutters
          sx={{
            height: '100vh',
            background: activeStep !== MypImportStepsList.Parametrage || loadingRepresentation ? '#183B68' : '',
          }}
          className={activeStep === MypImportStepsList.Parametrage ? 'parametrage-container' : ''}
        >
          <ContainerHeader
            onRollBack={() => onRollBack(activeStep, activeFile)}
            rollBackText={getRollBackText(activeStep)}
            onClose={() => onClose(activeFile, displaySettingsPanel)}
            displayRollBackBtn={!displaySettingsPanel && !loadingRepresentation}
            displayCloseBtn={!loadingRepresentation}
          />
          <ContainerBody
            title={loadingRepresentation ? '' : getImportContainerTitle(activeStep)}
            subtitle={loadingRepresentation ? <ImportLoader title={terms.MultiYearPlan.Import.loading} />
              : getImportContainerNotice(activeStep)}
            content={loadingRepresentation ? <></> : <ImportContentContainer />}
          />
          {activeStep === MypImportStepsList.Parametrage && !loadingRepresentation && <ParametrageContainer />}
          <ContainerFooter content={loadingRepresentation ? <></> : <ImportButtonsContainer />} />
        </Container>
      </Fade>
    </main>
  )
}
