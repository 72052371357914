import { SelectedPoints } from 'components/GeoEditor/EditionLayer/CustomModifyMode'
import { ModeConfig, EditorModeName } from 'components/GeoEditor/types'
import GeoEditorService, { Configuration, ConfigurationResponse } from 'components/GeoEditor/GeoEditorService'
import { LineString, Point, Feature as RawFeature } from 'geojson'
import { cleanCursor, Modes } from 'components/GeoEditor/utils'
import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import { FeatureCollection } from '@nebula.gl/edit-modes'
import { Feature, featureCollection } from '@turf/helpers'
import { WebMercatorViewport } from '@deck.gl/core'
import { Grid } from './GridLayer'
import { Viewport } from './snapping/updateSnappingFeatures'

export type ExtendingEditContext = {
  featureIndexes: number[];
  positionIndexes: number[];
}

export type SnappingFeature = RawFeature<Point | LineString>
export type LayersVisibility = { [k: string]: boolean }

const HISTORY_DEPTH = 5

export interface GeoEditorState {
  geoJson?: FeatureCollection;
  history: FeatureCollection[];
  grid: FeatureCollection | undefined;
  layersVisibility: LayersVisibility;
  mode: Modes;
  editorLayerModeName: EditorModeName;
  modeConfig: ModeConfig;
  snappingFeatures?: SnappingFeature[];
  selectedFeatures: number[];
  multiDragRefPoint?: Feature<Point>;
  selectedPoints?: SelectedPoints;
  // Extending LineString
  extendingFeatureCollection: FeatureCollection;
  extendingEditContext?: ExtendingEditContext;
  config: Configuration | undefined;
  viewportGeoEditor?: Viewport
  loading: boolean
  saveStatus: boolean
  saveMessage: string
  saveSeverity: any
}

const initialState: GeoEditorState = {
  geoJson: undefined,
  history: [],
  grid: undefined,
  editorLayerModeName: EditorModeName.Edit,
  mode: Modes.grab,
  modeConfig: {},
  selectedFeatures: [],
  selectedPoints: undefined,
  snappingFeatures: Grid.features as SnappingFeature[],
  extendingFeatureCollection: featureCollection([]) as FeatureCollection,
  layersVisibility: {
    editionLayer: true,
    TivLayer: true,
    selectionLayer: false,
  },
  config: undefined,
  viewportGeoEditor: undefined,
  loading: false,
  saveStatus: false,
  saveMessage: '',
  saveSeverity: '',
}

export const geoEditorSlice = createSlice({
  name: 'geoEditor',
  initialState,
  reducers: {
    disable: () => {
      cleanCursor()
      return initialState
    },
    setEditorLayerMode: (state, action: PayloadAction<EditorModeName>) => {
      state.editorLayerModeName = action.payload
    },
    setGeoJson: (state, action: PayloadAction<FeatureCollection | undefined>) => {
      state.geoJson = action.payload
    },
    setLayersVisibility: (state, action: PayloadAction<LayersVisibility>) => {
      state.layersVisibility = action.payload
    },
    updateMode: (state, action: PayloadAction<Modes>) => {
      state.mode = action.payload
    },
    updateHistory: (state, action: PayloadAction<FeatureCollection | undefined>) => {
      if (action.payload === undefined) {
        state.history.pop()
      } else if (state.history?.length <= HISTORY_DEPTH && action.payload !== undefined) {
        state.history.push(action.payload)
      } else {
        state.history.shift()
        state.history.push(action.payload)
      }
    },
    setGrid: (state, action: PayloadAction<FeatureCollection>) => {
      state.grid = action.payload
    },
    setMultiDragRefPoint: (state, action: PayloadAction<Feature<Point> | undefined>) => {
      state.multiDragRefPoint = action.payload
    },
    setModeConfig: (state, action: PayloadAction<ModeConfig>) => {
      state.modeConfig = action.payload
    },
    setSnappingFeatures: (state, action: PayloadAction<SnappingFeature[] | undefined>) => {
      state.snappingFeatures = action.payload
    },
    setSelectedFeatures: (state, action: PayloadAction<number[]>) => {
      state.selectedFeatures = action.payload
    },
    setSelectedPoints: (state, action: PayloadAction<SelectedPoints>) => {
      state.selectedPoints = action.payload
    },
    setExtendingFeatureCollection: (state, action: PayloadAction<FeatureCollection>) => {
      state.extendingFeatureCollection = action.payload
    },
    setExtendingEditContext: (state, action: PayloadAction<ExtendingEditContext | undefined>) => {
      state.extendingEditContext = action.payload
    },
    setGeoeditorViewport: (state, action: PayloadAction<any | undefined>) => {
      const newViewport = new WebMercatorViewport(action.payload) as Viewport
      state.viewportGeoEditor = newViewport
    },
    updateErrorStatus: (state, action: PayloadAction<any | undefined>) => {
      state.saveStatus = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(GeoEditorService.getConfiguration.fulfilled,
      (state, action: PayloadAction<ConfigurationResponse>) => {
        state.config = action.payload.configuration
        state.geoJson = action.payload.data
      })
    builder.addMatcher(isAnyOf(
      GeoEditorService.saveGeometry.pending,
    ), state => {
      state.loading = true
    })
    builder.addMatcher(isAnyOf(
      GeoEditorService.saveGeometry.fulfilled,
    ), state => {
      state.loading = false
      state.saveStatus = true
      state.saveMessage = 'Sauvegardée'
      state.saveSeverity = 'success'
    })
    builder.addMatcher(isAnyOf(
      GeoEditorService.saveGeometry.rejected,
    ), state => {
      state.loading = false
      state.saveStatus = true
      state.saveMessage = 'Une erreur est survenue'
      state.saveSeverity = 'warning'
    })
  },
})

export const {
  disable: disableGeoEditor,
  setEditorLayerMode,
  setModeConfig: setGeoEditorModeConfig,
  setGeoJson,
  updateMode,
  setSnappingFeatures,
  setSelectedFeatures,
  setSelectedPoints,
  setExtendingFeatureCollection,
  setExtendingEditContext,
  setLayersVisibility,
  setGeoeditorViewport,
  updateErrorStatus,
  setMultiDragRefPoint,
  updateHistory,
  setGrid,
} = geoEditorSlice.actions

export default geoEditorSlice.reducer
