import { ReactElement } from 'react'
import terms from 'common/terms'
import ActionButton from 'components/common/Buttons/ActionButton'

type Props = {
  onClickAddTrack: () => void
}

export default function AddTrackButton({ onClickAddTrack }: Props): ReactElement {
  return (
    <ActionButton
      text={terms.MultiYearPlan.Import.addTrack}
      onClick={onClickAddTrack}
      leftIcon
      leftIconStyle="mr-2"
      buttonStyle="add-track-btn"
    />
  )
}
