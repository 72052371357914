import { ReactElement, useState } from 'react'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import {
  Button, IconButton, Menu, MenuItem,
} from '@mui/material'
import terms from 'common/terms'
import { Operation } from 'reducers/MultiYearPlan/OperationActions/types'
import { useDispatch, useSelector } from 'react-redux'
import {
  toggleDisplayAddOperationModal,
  toggleDisplayDeleteModal, toggleDisplayModifyOperationModal,
} from 'reducers/MultiYearPlan/OperationActions/operationactions'
import { getOperation } from 'reducers/MultiYearPlan/OperationActions/operationactions.thunk'
import { RootState } from 'Store'

export default function PositionedOperationActionsMenu({ operation }: {operation: Operation}): ReactElement {
  const dispatch = useDispatch()
  const { activeFile } = useSelector((state: RootState) => state.files)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    dispatch(getOperation({
      repertoryId: activeFile.directory,
      folderId: activeFile.folder,
      projectId: activeFile.id,
      operationId: operation.id,
    }))
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onClick = (action: (v: boolean) => void) => {
    dispatch(action(true))
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton onClick={handleOpen}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className=""
      >
        <MenuItem>
          <Button
            className="actions-menu-btn"
            onClick={() => onClick(toggleDisplayModifyOperationModal)}
          >
            {terms.MultiYearPlan.Actions.modifyOperation}
          </Button>
        </MenuItem>
        <MenuItem>
          <Button
            className="actions-menu-btn"
            onClick={() => onClick(toggleDisplayAddOperationModal)}
          >
            {terms.MultiYearPlan.Actions.duplicateObject}
          </Button>
        </MenuItem>
        <MenuItem>
          <Button
            className="actions-menu-btn"
            onClick={() => onClick(toggleDisplayDeleteModal)}
          >
            {terms.Common.delete}
          </Button>
        </MenuItem>
      </Menu>
    </>
  )
}
