import { ReactElement } from 'react'
import { Source, Layer } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'

export default function RkLayerHover(): ReactElement {
  const { rkLayerData } = useSelector((state: RootState) => state.object)
  const { hoveredFeatureInfo } = useSelector((state: RootState) => state.map)
  const featureInfo = hoveredFeatureInfo
  const objectId = featureInfo ? featureInfo?.properties.object_id : 0
  return (
    <>
      <Source type="geojson" data={rkLayerData} id="hoverrk">
        <Layer
          id="hoverrk"
          filter={['==', 'object_id', objectId]}
          type="circle"
          minzoom={12}
          paint={{
            'circle-radius': {
              stops: [
                [15, 4],
                [16, 6],
              ],
            },
            'circle-color': '#000000',
          }}
          layout={{}}
        />
        <Layer
          id="hoverrkdesc"
          filter={['==', 'object_id', objectId]}
          type="symbol"
          minzoom={12}
          paint={{
            'text-color': '#000000',
            'text-halo-width': 0.3,
            'text-halo-color': '#000000',
          }}
          layout={{
            'text-allow-overlap': true,
            'text-field': ['get', 'description'],
            'text-offset': [0, 1.2],
            'text-size': {
              stops: [
                [15, 8],
                [16, 12],
              ],
            },
            'text-font': ['Roboto Condensed'],
          }}
        />
      </Source>
    </>
  )
}
