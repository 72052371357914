import { ReactElement, useEffect } from 'react'
import terms from 'common/terms'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import DeleteModal from 'components/common/Modals/DeleteModal'
import { resetDeletedFile, toggleDisplayDeleteModal } from 'reducers/Files/files'
import { deleteFolderFile, deleteRepertoryFile } from 'reducers/Files/files.thunk'
import { ActiveTab } from 'components/Dashboard/types'
import { updateTabsList } from 'reducers/dashboard'

export default function DeleteFileModal():
 ReactElement {
  const dispatch = useDispatch()
  const {
    displayDeleteModal, contextFile, deletedFile, isLoading,
  } = useSelector((state: RootState) => state.files)
  const { activeFolder } = useSelector((state: RootState) => state.folders)
  const { activeRepertory } = useSelector((state: RootState) => state.repertories)
  const { tabsList } = useSelector((state: RootState) => state.dashboard)

  useEffect(() => {
    if (deletedFile) {
      if (tabsList && tabsList.filter((tab: ActiveTab) => tab.tabId === deletedFile).length !== 0) {
        dispatch(updateTabsList(tabsList.filter((tab: ActiveTab) => tab.tabId !== deletedFile)))
      }
      dispatch(resetDeletedFile())
    }
  }, [deletedFile])

  const onModalClose = () => {
    dispatch(toggleDisplayDeleteModal(false))
  }

  const onDelete = () => {
    if (activeFolder) {
      dispatch(deleteFolderFile({ repertoryId: activeRepertory.id, folderId: activeFolder.id, fileId: contextFile.id }))
    } else {
      dispatch(deleteRepertoryFile({ repertoryId: activeRepertory.id, fileId: contextFile.id }))
    }
  }

  return (
    <DeleteModal
      open={displayDeleteModal}
      onModalClose={onModalClose}
      modalTitle={terms.File.Actions.deleteFile}
      modalNotice={terms.File.Actions.deleteNotice}
      onDelete={onDelete}
      isLoading={isLoading}
    />
  )
}
