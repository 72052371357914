import { ReactElement } from 'react'
import { Source, Layer } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'

export default function AdvLayerHover(): ReactElement {
  const { advLayerData } = useSelector((state: RootState) => state.object)
  const { hoveredFeatureInfo } = useSelector((state: RootState) => state.map)

  const featureInfo = hoveredFeatureInfo
  const objectId = featureInfo ? featureInfo?.properties.object_id : 0

  return (
    <>
      <Source type="geojson" data={advLayerData} id="hoveradv">
        <Layer
          id="hoveradv"
          filter={['==', 'object_id', objectId]}
          type="symbol"
          minzoom={12}
          paint={{
            'text-color': '#000000',
            'text-halo-width': 0.6,
            'text-halo-color': '#000000',
          }}
          layout={{
            'text-allow-overlap': true,
            'text-field': ['get', 'description'],
            'text-offset': [0, 0.9],
            'text-size': {
              stops: [
                [15, 6],
                [16, 14],
              ],
            },
            'text-font': ['Roboto Condensed'],
          }}
        />
      </Source>
    </>
  )
}
