import terms from 'common/terms'
import ActionButton from 'components/common/Buttons/ActionButton'
import ImportService from 'components/Portfolio/Import/ImportService'
import { WorkPhaseFields } from 'components/Portfolio/Library/types'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateApplyFilters, updateSearchValues, updateWorkPhases } from 'reducers/library'
import { RootState } from 'Store'

type Props = {
  handleClose: () => void
}

export default function ApplyFiltersButton({ handleClose }: Props): ReactElement {
  const dispatch = useDispatch()
  const { filters, workPhases } = useSelector((state: RootState) => state.library)
  const { importedPortfolio } = useSelector((state: RootState) => state.import)

  const handleClick = () => {
    handleClose()
    let finalFilters: any = {}
    if (Object.keys(filters).length === 0) {
      Object.keys(importedPortfolio.filters).forEach((filter: string) => {
        finalFilters = { ...finalFilters, [`${filter}`]: [] }
      })
    } else {
      Object.keys(importedPortfolio.filters).forEach((filter: string) => {
        if (!Object.keys(filters).includes(filter) && importedPortfolio.filters[filter].length !== 0) {
          finalFilters = { ...filters, [`${filter}`]: [] }
        }
      })
    }
    dispatch(ImportService.updatePortfolio({
      projectId: importedPortfolio.project,
      portfolio: { filters: Object.keys(finalFilters).length !== 0 ? finalFilters : filters },
    }))
    dispatch(updateSearchValues([]))
    dispatch(updateApplyFilters(true))
    const appliedPhases: WorkPhaseFields[] = []
    if (workPhases) {
      workPhases.forEach((w :WorkPhaseFields) => {
        if (w.phase.length !== 0) {
          appliedPhases.push({
            index: w.index,
            phase: w.phase,
            appliedFilter: true,
          })
        }
      })
      dispatch(updateWorkPhases(appliedPhases))
    } else {
      dispatch(updateWorkPhases([]))
    }
  }

  return (
    <ActionButton
      text={terms.Portfolio.Library.Filters.applyButton}
      onClick={handleClick}
      buttonStyle="modal-btn"
    />
  )
}
