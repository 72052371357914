import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import {
  Box, IconButton, Modal, Typography,
} from '@mui/material'
import terms from 'common/terms'
import CloseIcon from '@mui/icons-material/Close'
import {
  toggleDisplayAddOperationModal, toggleEmptyFields,
  resetObjectsFileds,
} from 'reducers/MultiYearPlan/OperationActions/operationactions'
import {
  setResponseError,
  setSelectedDefectedOperation, toggleDisplayPlaceDefectedOperationModal,
} from 'reducers/MultiYearPlan/Library/library'
import OperationFieldsContainer from '../OperationFields/OperationFieldsContainer'

import '../operations.scss'
import ValidationButton from '../Buttons/ValidationButton'

export default function AddOperationModal(): ReactElement {
  const dispatch = useDispatch()
  const { displayAddOperationModal, selectedOperation } = useSelector((state: RootState) => state.mypoperationactions)
  const {
    displayPlaceDefectedOperationModal, selectedDefectedOperation,
  } = useSelector((state: RootState) => state.mypLibrary)

  const onClose = () => {
    dispatch(toggleDisplayAddOperationModal(false))
    dispatch(toggleDisplayPlaceDefectedOperationModal(false))
    dispatch(toggleEmptyFields(false))
    dispatch(resetObjectsFileds())
    dispatch(setSelectedDefectedOperation(undefined))
    dispatch(setResponseError())
  }

  const getModalHeaderText = () => {
    if (selectedOperation) {
      return terms.MultiYearPlan.Actions.duplicateObject
    }
    if (selectedDefectedOperation) {
      return terms.MultiYearPlan.SettingsPanel.Library.fixAnomaly
    }
    return terms.MultiYearPlan.Operations.addOperation
  }

  return (
    <Modal open={displayAddOperationModal || displayPlaceDefectedOperationModal}>
      <Box className="operation-creation-modal">
        <div className="operation-modal-header">
          <Typography>{getModalHeaderText()}</Typography>
          <div>
            <IconButton onClick={onClose}><CloseIcon /></IconButton>
          </div>
        </div>
        <OperationFieldsContainer />
        <div>
          <ValidationButton />
        </div>
      </Box>
    </Modal>
  )
}
