import { ReactElement } from 'react'
import ActionButton from 'components/common/Buttons/ActionButton'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { setActiveStep } from 'reducers/import'

import './import.scss'
import terms from 'common/terms'

export default function SummaryButton(): ReactElement {
  const dispatch = useDispatch()
  const { activeStep } = useSelector((state: RootState) => state.import)

  const handleClick = () => {
    dispatch(setActiveStep(activeStep + 1))
  }

  return (
    <ActionButton
      rightIcon
      text={terms.Portfolio.Import.Buttons.start}
      onClick={handleClick}
      buttonStyle="start-btn"
    />
  )
}
