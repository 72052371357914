import terms from 'common/terms'
import { CartoucheElementFerroloc, TbElementType } from 'reducers/MultiYearPlan/TitleBlockActions/types'
import { ResponseError } from 'types'

export const getErrorMessage = (responseError: ResponseError | undefined, field: string, emptyFields: boolean,
  value: string | TbElementType) => {
  if (responseError && responseError.message[field]) {
    if (emptyFields && (value === '' || value === null)) {
      return terms.Common.Errors.requiredField
    }
    return responseError.message[field]
  }
  if (emptyFields && (value === '' || value === null)) {
    return terms.Common.Errors.requiredField
  }
  return ''
}

export default getErrorMessage

export const hasEmptyFields = (label: string, elementType: string,
  ferroloc: CartoucheElementFerroloc) => (label === '' || elementType === ''
    || ferroloc.line_code === '' || ferroloc.start_kp === '' || ferroloc.end_kp === '')
