import { ReactElement } from 'react'
import { Button } from 'react-bootstrap'
import { IoMdAdd, IoIosArrowRoundForward } from 'react-icons/io'

type Props ={
    onClick: () => void
    leftIcon?: boolean
    leftIconStyle?: string,
    text: string
    rightIcon?: boolean
    buttonStyle?: string
    disabled?: boolean
    topIcon?: ReactElement
}

const defaultProps = {
  leftIcon: false,
  leftIconStyle: '',
  rightIcon: false,
  buttonStyle: '',
  disabled: false,
  topIcon: <></>,
}

export default function ActionButton({
  onClick, leftIcon, leftIconStyle, text, rightIcon, buttonStyle, disabled, topIcon,
}: Props): ReactElement {
  return (
    <Button className={buttonStyle} onClick={onClick} disabled={disabled}>
      {leftIcon && <IoMdAdd className={leftIconStyle} />}
      {topIcon}
      {text}
      {rightIcon && <IoIosArrowRoundForward className="btn-add-synoptic-icon" />}
    </Button>
  )
}

ActionButton.defaultProps = defaultProps
