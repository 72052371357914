import terms from 'common/terms'
import { FileTypes } from 'reducers/Files/types'

export type GeographicFields = {
    key: string
    label: string
    placeholder?: string
    hasInputProps: boolean
    filetype?: string
    autoFocus?: boolean
}

export const GeographicInputFields: GeographicFields[] = [
  {
    key: 'name',
    label: terms.Inputs.sectionName,
    placeholder: terms.Inputs.sectionNamePlaceholder,
    hasInputProps: false,
    autoFocus: true,
  },
  {
    key: 'code_ligne',
    label: terms.Inputs.lineNumber,
    placeholder: terms.Inputs.lineNumberPlaceholder,
    hasInputProps: true,
  },
  {
    key: 'pk_sncf_debut',
    label: terms.Inputs.pkDebut,
    placeholder: terms.Inputs.pkPlaceholder,
    hasInputProps: false,
  },
  {
    key: 'pk_sncf_fin',
    label: terms.Inputs.pkFin,
    placeholder: terms.Inputs.pkPlaceholder,
    hasInputProps: false,
  },
  {
    key: 'step',
    label: terms.Inputs.step,
    hasInputProps: true,
    filetype: FileTypes.Schema,
  },
  {
    key: 'direction',
    label: terms.Inputs.direction,
    hasInputProps: false,
    filetype: FileTypes.Schema,
  },
]

export enum DirectionValues {
  Ascending = 'INC',
  Descending = 'DEC'
}

export const DirectionSelectOptions = [
  {
    value: terms.Inputs.increment,
    key: DirectionValues.Ascending,
  },
  {
    value: terms.Inputs.decrement,
    key: DirectionValues.Descending,
  },
]
