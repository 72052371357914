import { ReactElement, SyntheticEvent, useState } from 'react'
import {
  Autocomplete, FormHelperText, InputLabel, TextField,
} from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { TbElementType } from 'reducers/MultiYearPlan/TitleBlockActions/types'

type Props = {
    value: TbElementType | null
    onChange: (event: SyntheticEvent<Element, Event>, v: TbElementType | null) => void
    options: TbElementType[]
    placeholder: string
    label: string
    disabled?: boolean
    extraStyle?: string
    errorMessage: string
}
export default function TbElementTypeSelectField({
  value, onChange, options, placeholder, label, disabled, extraStyle, errorMessage,
}: Props): ReactElement {
  const [inputValue, setInputValue] = useState<string>('')
  return (
    <div className={extraStyle}>
      <InputLabel shrink sx={{ color: errorMessage !== '' ? '#FF5737' : '#8A8A8A' }}>{label}</InputLabel>
      <Autocomplete
        value={value}
        onChange={onChange}
        inputValue={inputValue}
        onInputChange={(_event, newInputValue) => setInputValue(newInputValue)}
        options={options}
        getOptionLabel={option => option.label}
        renderInput={params => <TextField {...params} placeholder={value ? '' : placeholder} />}
        disabled={disabled}
      />
      {errorMessage !== '' && (
      <div className="input-error-container">
        <ErrorOutlineIcon className="mr-1" />
        <FormHelperText>{errorMessage}</FormHelperText>
      </div>
      )}
    </div>
  )
}

TbElementTypeSelectField.defaultProps = {
  disabled: false,
  extraStyle: '',
}
