import { ReactElement, SyntheticEvent } from 'react'
import { Autocomplete, FormHelperText, TextField } from '@mui/material'
import { PortfolioHeaders } from 'reducers/MultiYearPlan/Import/types'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

type Props = {
    value: PortfolioHeaders | null
    onChange: (event: SyntheticEvent<Element, Event>, v: PortfolioHeaders | null) => void
    inputValue: string
    onInputChange: (v: string) => void
    options: PortfolioHeaders[]
    placeholder: string
    inputStyle: string
    errorMessage: string
}
export default function CustomAutoComplete({
  value, onChange, inputValue, onInputChange, options, placeholder, inputStyle, errorMessage,
}: Props): ReactElement {
  return (
    <div>
      <Autocomplete
        value={value}
        onChange={onChange}
        inputValue={inputValue}
        onInputChange={(_event, newInputValue) => onInputChange(newInputValue)}
        options={options}
        getOptionLabel={option => option.label.toString()}
        renderInput={params => <TextField {...params} placeholder={value ? '' : placeholder} />}
        className={inputStyle}
      />
      {errorMessage !== '' && (
      <div className="input-error-container">
        <ErrorOutlineIcon className="mr-1" />
        <FormHelperText>{errorMessage}</FormHelperText>
      </div>
      )}
    </div>
  )
}
