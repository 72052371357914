import terms from 'common/terms'
import { InputTypes } from 'types'

export type OperationInput = {
    key: string
    label: string
    placeholder?: string
    fieldType?: string
}

export const FerrolocInputFields: OperationInput[] = [
  {
    key: 'line_code',
    label: terms.Inputs.line,
    placeholder: terms.Inputs.lineNumberPlaceholder,
    fieldType: InputTypes.Select,
  },
  {
    key: 'track',
    label: terms.Inputs.trackName,
    placeholder: terms.Inputs.trackNamePlaceholder,
  },
  {
    key: 'start_kp',
    label: terms.Inputs.pkDebut,
    placeholder: terms.Inputs.pkPlaceholder,
    fieldType: InputTypes.SimpleField,
  },
  {
    key: 'end_kp',
    label: terms.Inputs.pkFin,
    placeholder: terms.Inputs.pkPlaceholder,
    fieldType: InputTypes.SimpleField,
  },
]

export const PhasesInputFields: OperationInput[] = [
  {
    key: 'year',
    label: terms.Inputs.years,
    placeholder: terms.Inputs.yearsPlaceholder,
  },
  {
    key: 'phase',
    label: terms.Inputs.phase,
    placeholder: terms.Inputs.phasePlaceholder,
  },
]
