import { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { FormHelperText, InputBase } from '@mui/material'
import { ObjectFamily } from 'reducers/MultiYearPlan/Import/ObjectsFamilies/type'
import {
  patchClassification, postClassification,
} from 'reducers/MultiYearPlan/Import/ObjectsFamilies/objectsFamilies.thunk'
import terms from 'common/terms'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

export default function ObjectFamilyLabelInput({ objectFamily }: {objectFamily: ObjectFamily}): ReactElement {
  const dispatch = useDispatch()
  const { activeFile } = useSelector((state: RootState) => state.files)
  const [familyLabel, setFamilyLabel] = useState<string>(objectFamily.label)

  const handleOutsideClick = () => {
    if (objectFamily.id && objectFamily.id !== -1) {
      if (activeFile && (familyLabel !== objectFamily.label) && familyLabel !== '') {
        dispatch(patchClassification({
          repertoryId: activeFile.directory,
          folderId: activeFile.folder,
          fileId: activeFile.id,
          classificationId: objectFamily.id,
          objectFamily: {
            id: objectFamily.id,
            label: familyLabel,
          },
        }))
      }
    } else {
      dispatch(postClassification({
        repertoryId: activeFile.directory,
        folderId: activeFile.folder,
        fileId: activeFile.id,
        objectFamily: {
          label: familyLabel,
        },
      }))
    }
  }

  return (
    <div
      className={`object-family-label-input-container ${familyLabel === ''
       && ((objectFamily.id && objectFamily.id !== -1) || !objectFamily.id || objectFamily.id === -1)
        ? ' object-family-label-input-error' : ''}`}
    >
      <InputBase
        value={familyLabel}
        onBlur={() => handleOutsideClick()}
        onChange={e => setFamilyLabel(e.target.value)}
        id={`${objectFamily.label}-${objectFamily.id}`}
        className="object-family-label-input"
      />
      {familyLabel === '' && objectFamily.id && objectFamily.id !== -1 && (
      <div className="input-error-container">
        <ErrorOutlineIcon className="mr-1" />
        <FormHelperText>{terms.Common.Errors.requiredField}</FormHelperText>
      </div>
      )}

    </div>
  )
}
