import { ReactElement } from 'react'
import { Nav, Tab } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { updateActiveBlock } from 'reducers/synoptic'
import {
  toggleDisplayCartridge, toggleDisplaySettingsForm, updatePageScale, updateRealityScale, updateRkStep,
} from 'reducers/export'
import PortfolioTabContent from 'components/Portfolio/PortfolioTabContent'
import CreationService from 'components/Dashboard/Creation/CreationService'
import {
  toggleShowPanel, updateResponseError, toggleUpdateMode, updateLabelText, updateSearchValue, setOverlappingPopupInfo,
} from 'reducers/label'
import { toggleShowGeoeditor } from 'reducers/object'
import { updateClickedFeatureInfo } from 'reducers/map'
import GenerateDocument from 'components/Export/GenerateDocument'
import terms from 'common/terms'
import { AppState } from 'reducers/app'
import Loader from 'components/Loader'
import ExportSettingsFormModal from 'components/Export/ExportSettingsFormModal'
import { DEFAULT_VIEWPORT } from 'components/Map/utils'
import SynopticDisplay from './SynopticDisplay'

export default function SynopticNavBar(): ReactElement {
  const dispatch = useDispatch()
  const { activeBlock } = useSelector((state: RootState) => state.synoptic)
  const { activeProject, projectZoom } = useSelector((state: RootState) => state.creation)
  const { updateMode } = useSelector((state: RootState) => state.label)
  const { loadingLayers } = useSelector((state: RootState) => state.object)
  const { loading } = useSelector((state: RootState): AppState => state.app)

  const onBlockSelect = (blockName: string) => {
    if (projectZoom !== DEFAULT_VIEWPORT.zoom) {
      dispatch(CreationService.updateZoomLevel({ projectId: activeProject.id, zoom: projectZoom }))
    }
    if (blockName !== terms.Synoptic.generateDoc) {
      dispatch(toggleShowGeoeditor(false))
      dispatch(updateActiveBlock(blockName))
      dispatch(updateClickedFeatureInfo(undefined))
    }
    if (blockName !== terms.Synoptic.addPortfolio) {
      dispatch(setOverlappingPopupInfo([]))
    }
    if (blockName === terms.Synoptic.addPortfolio) {
      dispatch(CreationService.getSingleProject(activeProject?.id))
      dispatch(toggleShowPanel(false))
      dispatch(updateResponseError(undefined))
      dispatch(updateSearchValue([]))
      if (updateMode) {
        dispatch(toggleUpdateMode(false))
        dispatch(updateLabelText(''))
      }
    }
    if (blockName === terms.Synoptic.generateDoc) {
      dispatch(toggleDisplaySettingsForm(true))
      dispatch(updateRkStep('1'))
      dispatch(toggleDisplayCartridge(false))
      dispatch(updatePageScale('1'))
      dispatch(updateRealityScale('100'))
    }
  }

  return (
    <>
      {(loading || loadingLayers) && <Loader center />}
      <Tab.Container id="left-tabs-example" defaultActiveKey={terms.Synoptic.createFondPlan as string}>
        <Nav variant="tabs" className="synoptic-navigation-tab">
          <Nav.Item className="action-cursor-pointer">
            <Nav.Link
              eventKey={terms.Synoptic.createFondPlan as string}
              active={activeBlock === terms.Synoptic.createFondPlan}
              onClick={() => onBlockSelect(terms.Synoptic.createFondPlan)}
            >
              {terms.Synoptic.createFondPlan}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="action-cursor-pointer">
            <Nav.Link
              eventKey={terms.Synoptic.addPortfolio as string}
              active={activeBlock === terms.Synoptic.addPortfolio}
              onClick={() => onBlockSelect(terms.Synoptic.addPortfolio)}
            >
              {terms.Synoptic.addPortfolio}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="action-cursor-pointer">
            <Nav.Link
              eventKey={terms.Synoptic.generateDoc as string}
              active={activeBlock === terms.Synoptic.generateDoc}
              onClick={() => onBlockSelect(terms.Synoptic.generateDoc)}
            >
              {terms.Synoptic.generateDoc}
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane
            eventKey={terms.Synoptic.createFondPlan as string}
            active={activeBlock === terms.Synoptic.createFondPlan}
            unmountOnExit
          >
            <SynopticDisplay />
          </Tab.Pane>
          <Tab.Pane
            eventKey={terms.Synoptic.addPortfolio as string}
            active={activeBlock === terms.Synoptic.addPortfolio}
            unmountOnExit
          >
            <PortfolioTabContent />
          </Tab.Pane>
          <Tab.Pane
            eventKey={terms.Synoptic.generateDoc as string}
            active={activeBlock === terms.Synoptic.generateDoc}
          >
            <GenerateDocument />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
      <ExportSettingsFormModal />
    </>
  )
}
