import terms from 'common/terms'
import { InputField, InputTypes } from 'types'

export const DESCRIPTION_FIELDS: InputField = {
  key: 'description',
  label: terms.Inputs.labelText,
  placeholder: terms.Inputs.labelTextPlaceholder,
  required: true,
  inputType: InputTypes.SimpleField,
  type: 'textarea',
}

export const AREA_FIELDS: Array<InputField> = [
  {
    key: 'code_ligne',
    label: terms.Inputs.lineNumber,
    placeholder: terms.Inputs.lineNumberLabel,
    required: true,
    inputType: InputTypes.Select,
  },
  {
    key: 'nom_voie',
    label: terms.Inputs.trackName,
    placeholder: terms.Inputs.trackNamePlaceholder,
    required: false,
    inputType: InputTypes.Select,
    withSearch: true,
  },
]

export const PK_FIELDS: Array<InputField> = [
  {
    key: 'pk_debut',
    label: terms.Inputs.pkDebut,
    placeholder: terms.Inputs.pkDebutPlaceholder,
    required: true,
    inputType: InputTypes.SimpleField,
  },
  {
    key: 'pk_fin',
    label: terms.Inputs.pkFin,
    placeholder: terms.Inputs.pkFinPlaceholder,
    required: true,
    inputType: InputTypes.SimpleField,
  },
]
