/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */
import { get, post } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkApi } from 'types'
import { Project } from 'components/Dashboard/types'
import {
  ServiceResponse, UserList, PostParams,
} from './types'

const getGroups = createAsyncThunk<ServiceResponse, string, ThunkApi>(
  'cerbere/getGroups',
  async (search, thunkApi) => {
    try {
      const response = await get('/cerbere/groups/', { search: search || undefined })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const getGroupMembers = createAsyncThunk<UserList, string, ThunkApi>(
  'cerbere/getGroupMembers',
  async (id, thunkApi) => {
    try {
      const response = await get(`/cerbere/groups/${id}/members/`, { page_size: 500 })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const shareDocument = createAsyncThunk<Project, PostParams, ThunkApi>(
  'myp/declareFilesSection',
  async (params: PostParams, thunkApi) => {
    if (params.folderId && params.folderId !== null) {
      try {
        const response = await
        // eslint-disable-next-line max-len
        post(`/module_schematique/directories/${params.repertoryId}/folders/${params?.folderId}/projects/${params.fileId}/share/`,
          { owner_ids: params.usersId })
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response = await
        post(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/share/`,
          { owner_ids: params.usersId })
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

export {
  getGroups,
  getGroupMembers,
  shareDocument,
}
