import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { Box, Modal } from '@mui/material'
import SettingsContainer from './SettingsContainer'
import PrevizualisationContainer from './PrevizualisationContainer'
import './exportpdf.scss'

export default function ExportPdfModal(): ReactElement {
  const { displayExportPdfModal } = useSelector((state: RootState) => state.mypExportPdf)

  return (
    <Modal open={displayExportPdfModal}>
      <Box className="export-pdf-modal">
        <SettingsContainer />
        <PrevizualisationContainer />
      </Box>
    </Modal>
  )
}
