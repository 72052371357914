import { ReactElement } from 'react'
import NoticeBadges from 'components/common/Badge/NoticeBadge'
import terms from 'common/terms'
import { IMPORT_STATUS } from 'components/Dashboard/Creation/types'

type Props = {
    status: string
}

export default function ValidationNotice({ status }: Props): ReactElement {
  switch (status) {
    case IMPORT_STATUS.EmptyRange:
      return (
        <NoticeBadges notice={terms.Validation.emptyRange} />
      )
    case IMPORT_STATUS.LineError:
      return (
        <NoticeBadges notice={terms.Validation.lineError} />
      )
    case IMPORT_STATUS.ImportError:
      return (
        <NoticeBadges />
      )
    case IMPORT_STATUS.Validated:
      return (
        <NoticeBadges isValide />
      )
    default:
      return <></>
  }
}
