import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import { ObjectFamily, ObjectsFamiliesState } from './type'
import {
  getClassifications,
  getSubClassifications,
  patchSubClassification,
  patchClassification,
  postFamiliyTrack,
  deleteClassification,
  deleteFamilyTrack,
  PatchFamilyTrack,
  postClassification,
} from './objectsFamilies.thunk'

const initialState: ObjectsFamiliesState = {
  objectsFamilies: [],
  updateObjectsFamilies: false,
  trackName: '',
  subClassifications: [],
  displayDeleteObjectFamily: false,
}

export const objectsFamiliesSlice = createSlice({
  name: 'objectsFamilies',
  initialState,
  reducers: {
    updateObjectsFamilies: (state, action: PayloadAction<ObjectFamily[]>) => {
      state.objectsFamilies = action.payload
    },
    resetResponseError: state => {
      state.responseError = undefined
    },
    toggleDisplayDeleteObjectFamily: (state, action: PayloadAction<boolean>) => {
      state.displayDeleteObjectFamily = action.payload
    },
    updateActiveObjectFamily: (state, action: PayloadAction<ObjectFamily>) => {
      state.activeObjectFamily = action.payload
    },
    updateSubCLassificationDraggedId: (state, action: PayloadAction<number>) => {
      state.subClassificationDraggedId = action.payload
    },
  },
  extraReducers: builder => {
    builder.addMatcher(isAnyOf(
      getClassifications.fulfilled,
    ), (state, action) => {
      if (state.objectsFamilies.length !== action.payload.length
         && state.objectsFamilies.filter(family => family.label === '').length !== 0
          && state.objectsFamilies.length !== 0) {
        state.objectsFamilies = action.payload
        state.objectsFamilies.push({
          label: '',
          tracks: [],
          operations: [],
          color: '',
          id: -1,
          sub_classifications: [],
          is_visible: true,
        })
      } else {
        state.objectsFamilies = action.payload
      }
    })
    builder.addMatcher(isAnyOf(
      patchClassification.fulfilled,
    ), (state, action) => {
      state.objectsFamilies = state.objectsFamilies.map(file => (file.id === action.payload.id
        ? action.payload : file))
      state.responseError = undefined
    })
    builder.addMatcher(isAnyOf(
      getSubClassifications.fulfilled,
    ), (state, action) => {
      state.subClassifications = action.payload
    })
    builder.addMatcher(isAnyOf(
      deleteFamilyTrack.fulfilled,
      PatchFamilyTrack.fulfilled,
      postFamiliyTrack.fulfilled,
      postClassification.fulfilled,
      patchClassification.fulfilled,
      patchSubClassification.fulfilled,
    ), state => {
      state.updateObjectsFamilies = !state.updateObjectsFamilies
    })
    builder.addMatcher(isAnyOf(
      deleteClassification.fulfilled,
    ), state => {
      state.deleteObjectFamily = !state.deleteObjectFamily
      state.displayDeleteObjectFamily = false
    })
    builder.addMatcher(isAnyOf(
      patchClassification.rejected,
      deleteFamilyTrack.rejected,
      getSubClassifications.rejected,
      postFamiliyTrack.rejected,
      deleteClassification.rejected,
      PatchFamilyTrack.rejected,
      postClassification.rejected,
      patchSubClassification.rejected,
    ), (state, action) => {
      state.responseError = action.payload
    })
  },
})

export const {
  updateObjectsFamilies, resetResponseError,
  toggleDisplayDeleteObjectFamily, updateActiveObjectFamily,
  updateSubCLassificationDraggedId,
} = objectsFamiliesSlice.actions

export default objectsFamiliesSlice.reducer
