import terms from 'common/terms'
import { OrderingList, OrderBy } from 'components/Dashboard/types'
import { ReactElement, useState } from 'react'
import { useDispatch } from 'react-redux'
import SouthIcon from '@mui/icons-material/South'
import NorthIcon from '@mui/icons-material/North'
import { Button, Menu, MenuItem } from '@mui/material'

import './order.scss'

export default function OrderingMenu({ orderBy, updateOrderBy }:
     { orderBy: string, updateOrderBy: (v: string) => void }): ReactElement {
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const toggleOrderingChoice = (sortBy: string) => {
    dispatch(updateOrderBy(sortBy))
    setAnchorEl(null)
  }

  return (
    <div>
      <Button onClick={handleClick}>
        <span className="sort-by-text">
          {terms.Common.sortBy}
        </span>
        {' '}
        <span className="sort-icons">
          {OrderingList.filter(o => o.key === orderBy)[0].value}
          {orderBy === OrderBy.AscendingAlphabetical || orderBy === OrderBy.AscendingLastModification
           || orderBy === OrderBy.AscendingCreationDate
            ? <NorthIcon /> : <SouthIcon />}
        </span>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => toggleOrderingChoice(orderBy === OrderBy.AscendingAlphabetical
            ? OrderBy.DescendingAlphabetical : OrderBy.AscendingAlphabetical)}
          className="sort-options"
        >
          {terms.Common.alphabeticalOrder}
        </MenuItem>
        <MenuItem
          onClick={() => toggleOrderingChoice(orderBy === OrderBy.AscendingLastModification
            ? OrderBy.DescendingLastModification : OrderBy.AscendingLastModification)}
          className="sort-options"
        >
          {terms.Common.modificationDate}
        </MenuItem>
        <MenuItem
          onClick={() => toggleOrderingChoice(orderBy === OrderBy.AscendingCreationDate
            ? OrderBy.DescendingCreationDate : OrderBy.AscendingCreationDate)}
          className="sort-options"
        >
          {terms.Common.creationDate}
        </MenuItem>
      </Menu>
    </div>
  )
}
