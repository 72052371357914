import { FeatureCollection } from '@nebula.gl/edit-modes'
import {
  point, lineString, Point, Feature, Properties, featureCollection,
} from '@turf/helpers'
import { GeoJsonLayer } from '@deck.gl/layers'
import bboxPolygon from '@turf/bbox-polygon'
import pointsWithinPolygon from '@turf/points-within-polygon'
import envelope from '@turf/envelope'
import flatten from 'lodash/flatten'
import { store } from 'Store'
import { planeDistance } from '../EditionLayer/utils'
import updateSnappingFeatures, { Viewport } from '../snapping/updateSnappingFeatures'
import { setGrid, setSnappingFeatures } from '../reducer'

type Bbox = [number, number, number, number]

const FRANCE_BBOX: Bbox = [-5.2, 42, 9, 51.3]

function createArrOfEmptyArr<T>(length: number): Array<Array<T>> {
  const arr = [] as Array<Array<T>>
  for (let i = 0; i < length; i += 1) {
    arr.push([] as Array<T>)
  }
  return arr
}

export const lineStringGrids = (
  bbox: [number, number, number, number], cellSide: number,
) => {
  const west = bbox[0]
  const south = bbox[1]
  const east = bbox[2]
  const north = bbox[3]

  const xFraction = cellSide / (planeDistance([west, south], [east, south]) * 100)
  const cellWidth = xFraction * (east - west)
  const yFraction = cellSide / (planeDistance([west, south], [west, north]) * 140)
  const cellHeight = yFraction * (north - south)

  const bboxHeight = north - south
  const nbOfRows = Math.floor(bboxHeight / cellHeight)

  const columns = [] as Feature<Point>[][]
  const rows = createArrOfEmptyArr<Feature<Point>>(nbOfRows + 2)

  let currentX = west
  let currentColumnIndex = 0
  while (currentX <= east) {
    let currentY = south
    let currentColumn = [] as Feature<Point>[]
    while (currentY <= north) {
      const cellPt = point([currentX, currentY])
      currentColumn.push(cellPt)
      rows[currentColumnIndex]?.push(cellPt)
      currentY += cellHeight
      currentColumnIndex += 1
    }
    columns.push(currentColumn)
    currentColumn = []
    currentColumnIndex = 0
    currentX += cellWidth
  }

  const colLinestrings = columns.map(col => {
    if (col.length > 0) {
      return lineString([col[0].geometry.coordinates, col[col.length - 1].geometry.coordinates])
    }
    return lineString([[0, 0], [0, 1]])
  })
  const rowLinestrings = rows.map(row => {
    if (row.length > 0) {
      return lineString([row[0].geometry.coordinates, row[row.length - 1].geometry.coordinates])
    }
    return lineString([[0, 0], [0, 1]])
  })

  return [featureCollection([
    ...colLinestrings,
    ...rowLinestrings,
  ]) as FeatureCollection, columns]
}

const lineGridProps = lineStringGrids(FRANCE_BBOX, 2)

export const Grid = lineGridProps[0] as FeatureCollection
export const points = lineGridProps[1] as Feature[][]

const getCellSize = (zoom: number): number => {
  switch (true) {
    case zoom < 8:
      return 16
    case zoom < 10.5:
      return 4
    case zoom < 11.5:
      return 2
    case zoom < 14:
      return 1
    default:
      return 0.5
  }
}

export const updateGrid = (newViewport: Viewport): void => {
  const chosenPoints = lineStringGrids(FRANCE_BBOX, getCellSize(newViewport.zoom))[1] as Feature[][]
  const bounds = newViewport.getBounds()
  const pol = bboxPolygon(bounds)
  const flatPoints = featureCollection(flatten(chosenPoints as Feature[][]) as Feature<Point, Properties>[])
  const pointsInBbox = pointsWithinPolygon(flatPoints, pol)
  const envelopePolygon = envelope(pointsInBbox)

  const envelopePolygonCoords = envelopePolygon.geometry.coordinates[0]

  if (envelopePolygonCoords[0][0] < Infinity) {
    const newBbox: Bbox = [
      envelopePolygonCoords[0][0],
      envelopePolygonCoords[0][1],
      envelopePolygonCoords[2][0],
      envelopePolygonCoords[2][1],
    ]

    const newGrid = lineStringGrids(newBbox, getCellSize(newViewport.zoom))[0] as FeatureCollection

    store.dispatch(setGrid(newGrid))
    store.dispatch(setSnappingFeatures(updateSnappingFeatures(newViewport, newGrid)))
  }
}

const createGridLayer = (grid: FeatureCollection | undefined = undefined) => new GeoJsonLayer({
  id: 'grid-layer',
  data: grid || Grid,
  pickable: false,
  stroked: true,
  filled: false,
  extruded: false,
  lineWidthMinPixels: 1,
  opacity: 0.05,
  getLineWidth: 1,
})

export default createGridLayer
