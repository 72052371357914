import {
  Feature, point, Properties,
} from '@turf/helpers'
import pointToLineDistance from '@turf/point-to-line-distance'
import { LineString, Point } from 'geojson'
import { planeDistance } from '../EditionLayer/utils'

// Compute the distance between a Point, and either a LineString or an other point
const pointToFeatureDistance = (
  feature: Feature<Point | LineString, Properties>, refPoint: Feature<Point, Properties>,
): number => {
  if (feature.geometry.type === 'Point') {
    return planeDistance(
      feature.geometry.coordinates as [number, number], refPoint.geometry.coordinates as [number, number],
    )
  }

  // Artificially put LineString appear further from points to prioritize points
  return pointToLineDistance(refPoint, feature as Feature<LineString, Properties>, {
    method: 'planar',
  })
}

// With an array of features and the mouse coords, we want to find the closest feature to the mouse
const getNearestFeature = (
  coords: [number, number], features: Feature<Point | LineString>[],
): Feature<Point | LineString> => {
  let chosenFeature = features[0]
  // Search for closest feature
  const mousePoint = point(coords)
  let minDist = pointToFeatureDistance(chosenFeature, mousePoint)
  features.forEach(snappingFeature => {
    const dist = pointToFeatureDistance(snappingFeature, mousePoint)
    if (dist < minDist) {
      minDist = dist
      chosenFeature = snappingFeature
    }
  })
  return chosenFeature
}

export default getNearestFeature
