import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import { Files } from 'reducers/Files/types'
import {
  declareFilesSection, deleteFolderFilesSection, deleteRepertoryFileSection,
  dropFolderFilesSections, dropRepertoryFileSections, getAllFilesList, getFilesSectionsList,
  importFolderFilesSection, importRepertoryFileSection,
  patchFolderFilesSection, patchRepertoryFileSection,
} from './multiYearPlan.thunk'
import { MultiyearPlanState, MYPSection } from './types'

const initialState: MultiyearPlanState = {
  geoPerimeterDeclarationMode: '',
  mypSectionsList: [],
  mypEmptyFields: false,
  backToDeclarationChoice: false,
  deletedSection: false,
  allFilesList: [],
  orderChange: false,
  updateTimeRange: false,
  updateMypSections: false,
  gettingSections: false,
}

export const multiYearPlanSlice = createSlice({
  name: 'multiYearPlan',
  initialState,
  reducers: {
    resetState: () => initialState,
    updateDeclarationMode: (state, action: PayloadAction<string>) => {
      state.geoPerimeterDeclarationMode = action.payload
      state.backToDeclarationChoice = false
    },
    updateMypSectionsList: (state, action: PayloadAction<Array<MYPSection>>) => {
      state.mypSectionsList = action.payload
    },
    updateCreationStatus: (state, action: PayloadAction<number | undefined>) => {
      state.mypCreationStatus = action.payload
    },
    updateMypEmptyFields: (state, action: PayloadAction<boolean>) => {
      state.mypEmptyFields = action.payload
    },
    resetMypResponseError: state => {
      state.sectionResponseError = undefined
    },
    toggleBackToDeclaration: (state, action: PayloadAction<boolean>) => {
      state.backToDeclarationChoice = action.payload
    },
    toggleDeletedSection: state => {
      state.deletedSection = false
    },
    setUpdatedMypSection: (state, action: PayloadAction<MYPSection>) => {
      state.updatedMypSection = action.payload
    },
    resetAllFilesList: state => {
      state.allFilesList = []
    },
    updateFileToImport: (state, action: PayloadAction<Files | undefined>) => {
      state.fileToImport = action.payload
    },
    toggleUpdateTimeRange: (state, action: PayloadAction<boolean>) => {
      state.updateTimeRange = action.payload
    },
    toggleUpdateMypSections: (state, action: PayloadAction<boolean>) => {
      state.updateMypSections = action.payload
    },
    resetUpdatedMypSection: state => {
      state.updatedMypSection = undefined
    },
  },
  extraReducers: builder => {
    builder.addCase(getAllFilesList.fulfilled, (state, action) => {
      state.allFilesList = action.payload
    })
    builder.addCase(declareFilesSection.fulfilled, (state, action) => {
      state.mypSectionsList.push(action.payload)
      state.sectionResponseError = undefined
      state.updatedMypSection = undefined
      state.deletedSection = false
      state.mypEmptyFields = false
    })
    builder.addCase(getFilesSectionsList.pending, state => {
      state.gettingSections = true
    })
    builder.addMatcher(isAnyOf(
      getFilesSectionsList.fulfilled,
    ), (state, action) => {
      state.mypSectionsList = action.payload
      state.orderChange = false
      state.gettingSections = false
    })
    builder.addMatcher(isAnyOf(
      deleteRepertoryFileSection.fulfilled,
      deleteFolderFilesSection.fulfilled,
    ), state => {
      state.deletedSection = true
    })
    builder.addMatcher(isAnyOf(
      importRepertoryFileSection.fulfilled,
      importFolderFilesSection.fulfilled,
    ), (state, action) => {
      state.mypSectionsList = action.payload
      state.fileToImport = undefined
      state.allFilesList = []
    })
    builder.addMatcher(isAnyOf(
      dropRepertoryFileSections.fulfilled,
      dropFolderFilesSections.fulfilled,
    ), (state, action) => {
      state.mypSectionsList = []
      state.geoPerimeterDeclarationMode = action.payload
      state.backToDeclarationChoice = false
    })
    builder.addMatcher(isAnyOf(
      patchRepertoryFileSection.fulfilled,
      patchFolderFilesSection.fulfilled,
    ), state => {
      state.orderChange = true
    })
    builder.addMatcher(isAnyOf(
      declareFilesSection.rejected,
      dropRepertoryFileSections.rejected,
      dropFolderFilesSections.rejected,
      importRepertoryFileSection.rejected,
      importFolderFilesSection.rejected,
      getAllFilesList.rejected,
    ), (state, action) => {
      state.sectionResponseError = action.payload
    })
  },
})

export const {
  updateDeclarationMode, updateMypSectionsList, updateCreationStatus, updateMypEmptyFields, resetMypResponseError,
  toggleBackToDeclaration, toggleDeletedSection, resetState, setUpdatedMypSection, updateFileToImport,
  resetAllFilesList, toggleUpdateTimeRange, toggleUpdateMypSections, resetUpdatedMypSection,
} = multiYearPlanSlice.actions

export default multiYearPlanSlice.reducer
