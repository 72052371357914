import { ReactElement } from 'react'
import Container from '@mui/material/Container'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import FileCreationButton from 'components/Files/FileCreationButton'
import RepertoriesContentBanner from 'components/Repertories/RepertoryContent/RepertoriesContentBanner'
import RepertoriesContentContainer from 'components/Repertories/RepertoryContent/RepertoriesContentContainer'
import { resetContexts } from 'reducers/Folders/folder'
import FolderContentContainer from 'components/Folders/FolderContentContainer'
import RepertoryCreationButton from './components/Home/RepertoryCreationButton'
import RepertoriesContainer from './components/Home/RepertoriesContainer'
import Banner from './components/Home/Banner'

export default function NavTabContent(): ReactElement {
  const dispatch = useDispatch()
  const { activeRepertory } = useSelector((state: RootState) => state.repertories)
  const { activeFolder } = useSelector((state: RootState) => state.folders)
  return (
    <>
      {!activeRepertory && !activeFolder && (
      <>
        <Container maxWidth={false} disableGutters sx={{ overflow: 'hidden' }}>
          <Banner />
          <RepertoriesContainer />
        </Container>
        <RepertoryCreationButton />
      </>
      )}

      {activeRepertory && !activeFolder && (
      <>
        <Container
          maxWidth={false}
          disableGutters
          sx={{ overflow: 'hidden' }}
          onClick={() => dispatch(resetContexts())}
        >
          <RepertoriesContentBanner />
          <RepertoriesContentContainer />
        </Container>
        <FileCreationButton />
      </>
      )}
      {activeFolder && (
        <>
          <Container maxWidth={false} disableGutters sx={{ overflow: 'hidden' }}>
            <FolderContentContainer />
          </Container>
          <FileCreationButton />
        </>
      )}
    </>
  )
}
