import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { patchFolderPortfolio, patchRepertoryPortfolio } from 'reducers/MultiYearPlan/Import/import.thunk'
import { RootState } from 'Store'
import ImportActionButton from './ImportActionButton'

export default function FormatConfirmationButton(): ReactElement {
  const dispatch = useDispatch()
  const {
    isLineFormat, startYearAttribut, endYearAttribut, yearAttribut, phaseAttribut, isLoading,
  } = useSelector((state: RootState) => state.mypImport)
  const { activeFile } = useSelector((state: RootState) => state.files)

  const isDisabled = isLineFormat ? yearAttribut === null : (startYearAttribut === null
     || endYearAttribut === null)

  const handleClick = () => {
    const portfolio = isLineFormat ? { column_year: yearAttribut?.id, column_phase: phaseAttribut?.id }
      : { column_start_year: startYearAttribut?.id, column_end_year: endYearAttribut?.id }
    if (activeFile.folder !== null) {
      dispatch(patchFolderPortfolio({
        repertoryId: activeFile.directory,
        folderId: activeFile.folder,
        fileId: activeFile.id,
        portfolio,
      }))
    } else {
      dispatch(patchRepertoryPortfolio({
        repertoryId: activeFile.directory,
        fileId: activeFile.id,
        portfolio,
      }))
    }
  }
  return (
    <ImportActionButton handleClick={handleClick} disabled={isDisabled || isLoading} />
  )
}
