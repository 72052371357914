import drawMouse from 'assets/icons/draw_mouse.svg'
import grabMouse from 'assets/icons/grab_hand.svg'
import rectangleSelection from 'assets/icons/rectangle_selection.svg'
import selectMouse from 'assets/icons/select_mouse.svg'
import rotateMouse from 'assets/icons/rotate.svg'
import bin from 'assets/icons/bin.svg'
import { Modes } from '../utils'

export type Tool = {
  key: string;
  label: string;
  icon: string;
  mode: Modes;
  onClick?: () => void;
}

const TOOLS: Tool[] = [
  {
    key: 'selectRectangle',
    label: 'Sélection par rectangle',
    icon: rectangleSelection,
    mode: Modes.selectRectangle,
  },
  {
    key: 'selectPolygon',
    label: 'Sélection par points multiples',
    icon: selectMouse,
    mode: Modes.selectPolygon,
  },
  {
    key: 'draw',
    label: 'Modifier la position des points sélectionnés',
    icon: drawMouse,
    mode: Modes.draw,
  },
  {
    key: 'grab',
    label: 'Déplacer la carte',
    icon: grabMouse,
    mode: Modes.grab,
  },
  {
    key: 'rotate',
    label: 'Pivoter les points sélectionnés ',
    icon: rotateMouse,
    mode: Modes.rotate,
  },
  {
    key: 'bin',
    label: 'Supprimer un point sélectionné',
    icon: bin,
    mode: Modes.deletePoint,
  },
]

export default TOOLS
