import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ActiveTab, OrderBy } from 'components/Dashboard/types'

interface DashboardState {
    cardDisplayMode: boolean
    tabsList: Array<ActiveTab>
    activeTab: ActiveTab
    orderBy: string
    selectTab?: { tabId: number }
}

const initialState: DashboardState = {
  cardDisplayMode: true,
  tabsList: [],
  activeTab: { tabName: 'home', tabId: undefined },
  orderBy: OrderBy.DescendingLastModification,
}

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    toggleDisplayMode: (state, action: PayloadAction<boolean>) => {
      state.cardDisplayMode = action.payload
    },
    updateTabsList: (state, action: PayloadAction<Array<ActiveTab>>) => {
      state.tabsList = action.payload
    },
    updateActiveTab: (state, action: PayloadAction<ActiveTab>) => {
      state.activeTab = action.payload
    },
    updateOrderBy: (state, action: PayloadAction<string>) => {
      state.orderBy = action.payload
    },
    toggleSelectTab: (state, action: PayloadAction<{ tabId: number } | undefined>) => {
      state.selectTab = action.payload
    },
  },
})

export const {
  toggleDisplayMode, updateTabsList, updateActiveTab, updateOrderBy, toggleSelectTab,
} = dashboardSlice.actions

export default dashboardSlice.reducer
