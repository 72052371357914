import { ReactElement } from 'react'
import { Grid } from '@mui/material'
import terms from 'common/terms'
import GenericInput from 'components/common/Inputs/GenericInput'
import { useDispatch, useSelector } from 'react-redux'
import { updateLabel } from 'reducers/Repertories/repertories'
import { RootState } from 'Store'

import '../repertory.scss'

export default function RepertoryNameInput(): ReactElement {
  const dispatch = useDispatch()
  const { newLabel } = useSelector((state: RootState) => state.repertories)
  return (
    <Grid item width={400} mt={5} mb={10}>
      <GenericInput
        label={terms.Repertory.repertoryName}
        placeholder={terms.Repertory.namePlaceholder}
        value={newLabel}
        onChange={e => dispatch(updateLabel(e.target.value))}
      />
    </Grid>
  )
}
