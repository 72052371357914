import { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { Menu, Paper } from '@mui/material'
import { ColorResult, TwitterPicker } from 'react-color'
import { ObjectFamily } from 'reducers/MultiYearPlan/Import/ObjectsFamilies/type'
import { patchClassification } from 'reducers/MultiYearPlan/Import/ObjectsFamilies/objectsFamilies.thunk'
import OBJECT_FAMILY_COLORS_LIST from './ObjectFamilyColorsList'

export default function ObjectFamilyColor({ objectFamily }: {objectFamily: ObjectFamily}): ReactElement {
  const dispatch = useDispatch()
  const { activeFile } = useSelector((state: RootState) => state.files)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (objectFamily.id && objectFamily.id !== -1) {
      setAnchorEl(anchorEl ? null : event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChooseColorClick = (c: string) => {
    if (activeFile) {
      dispatch(patchClassification({
        repertoryId: activeFile.directory,
        folderId: activeFile.folder,
        fileId: activeFile.id,
        classificationId: objectFamily.id,
        objectFamily: {
          color: c,
        },
      }))
      setAnchorEl(null)
    }
  }

  return (
    <>
      <Paper className="family-color-container" onClick={handleClick} sx={{ backgroundColor: objectFamily.color }} />
      <Menu
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
        className="object-family-color-panel"
        PaperProps={{
          sx: {
            overflow: 'visible',
          },
        }}
        onClose={handleClose}
      >
        <TwitterPicker
          color=""
          colors={OBJECT_FAMILY_COLORS_LIST}
          onChange={(c: ColorResult) => handleChooseColorClick(c.hex)}
        />
      </Menu>

    </>
  )
}
