import { ReactElement } from 'react'
import { Paper, Typography } from '@mui/material'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import terms from 'common/terms'

export default function SectionsResetNotice(): ReactElement {
  return (
    <Paper className="reset-section-notice">
      <AiOutlineInfoCircle />
      <Typography>
        {terms.File.Declaration.Schema.resetSectionsNotice}
      </Typography>
    </Paper>
  )
}
