import { ReactElement, useState, SyntheticEvent } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { Chip } from '@mui/material'
import { IoMdArrowDropright } from 'react-icons/io'
import CustomAutoComplete from 'components/common/Inputs/CustomAutoComplete'
import { PortfolioHeaders } from 'reducers/MultiYearPlan/Import/types'
import { MypImportStepsList } from '../types'

type Props = {
  chipLabel: string
  placeholder: string
  value?: PortfolioHeaders
  onChange: (event: SyntheticEvent<Element, Event>, v: PortfolioHeaders | null) => void
  errorMessage: string
}

export default function AttributsChoiceContainer({
  chipLabel, placeholder, value, onChange, errorMessage,
}: Props): ReactElement {
  const {
    portfolio, selectedAttributValue, responseError, activeStep,
  } = useSelector((state: RootState) => state.mypImport)
  const [inputValue, setInputValue] = useState<string>('')
  return (
    <>
      <div
        className={`ferro-localization-fields ${responseError && responseError.status === 400
           && activeStep !== MypImportStepsList.UploadFile ? ' attributs-mapping-error' : ''}`}
        style={{ width: 'auto' }}
      >
        <Chip label={chipLabel.toUpperCase()} />
        <div className="arrow-container">
          <div className="elipse-style" />
          <div className="line-style" />
          <IoMdArrowDropright className="arrow-style" />
        </div>
        <CustomAutoComplete
          value={value || selectedAttributValue}
          onChange={onChange}
          inputValue={inputValue}
          onInputChange={setInputValue}
          options={portfolio?.headers}
          placeholder={placeholder}
          inputStyle="myp-import-autocomplete"
          errorMessage={errorMessage}
        />
      </div>
    </>
  )
}

AttributsChoiceContainer.defaultProps = {
  value: undefined,
}
