/* eslint-disable react/no-array-index-key */
import terms from 'common/terms'
import GenericInput from 'components/common/Inputs/GenericInput'
import GenericSelect from 'components/common/Inputs/GenericSelect'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { InputTypes } from 'types'
import { OperationFerrolocFields } from 'reducers/MultiYearPlan/OperationActions/types'
import {
  toggleDeleteFerrolocField, updateFerrolocFields,
} from 'reducers/MultiYearPlan/OperationActions/operationactions'
import { getLineCodeTracksList } from 'reducers/MultiYearPlan/OperationActions/operationactions.thunk'
import { OperationInput, FerrolocInputFields } from './OperationInputFields'
import { getErrorMessage } from './utils'
import OperationSelectField from './OperationSelectField'

export default function FerrolocalizationInputs({ ferrolocIndex }: {ferrolocIndex: number}):
    ReactElement {
  const dispatch = useDispatch()
  const {
    ferrolocFields, tracksList, emptyFields, responseError, deleteFerrolocField,
  } = useSelector((state: RootState) => state.mypoperationactions)
  const { lineCodes } = useSelector((state: RootState) => state.remarkablePoints)
  const { libraryResponseError } = useSelector((state: RootState) => state.mypLibrary)

  const [lineCode, setLineCode] = useState<string>('')
  const [track, setTrack] = useState<string>('')
  const [pkDebut, setPkDebut] = useState<string>('')
  const [pkFin, setPkFin] = useState<string>('')

  useEffect(() => {
    const newFerroloc = {
      line_code: lineCode,
      track: track === '' ? null : track,
      start_kp: pkDebut,
      end_kp: pkFin,
    }
    const newFerrolocFields: OperationFerrolocFields[] = ferrolocFields.map((ferroloc:
         OperationFerrolocFields) => (ferroloc.index !== ferrolocIndex
      ? ferroloc : { index: ferrolocIndex, ferroloc: newFerroloc }))
    dispatch(updateFerrolocFields(newFerrolocFields))
  }, [lineCode, track, pkDebut, pkFin])

  useEffect(() => {
    setLineCode(ferrolocFields[ferrolocIndex - 1].ferroloc.line_code)
    setTrack(ferrolocFields[ferrolocIndex - 1].ferroloc.track)
    setPkDebut(ferrolocFields[ferrolocIndex - 1].ferroloc.start_kp)
    setPkFin(ferrolocFields[ferrolocIndex - 1].ferroloc.end_kp)
    dispatch(getLineCodeTracksList(+ferrolocFields[ferrolocIndex - 1].ferroloc.line_code))
    if (deleteFerrolocField) {
      dispatch(toggleDeleteFerrolocField(false))
    }
  }, [deleteFerrolocField])

  const handleChange = (value: string, fieldLabel: string) => {
    switch (fieldLabel) {
      case terms.Inputs.line:
        setLineCode(value)
        dispatch(getLineCodeTracksList(+value))
        break
      case terms.Inputs.pkDebut:
        setPkDebut(value)
        break
      case terms.Inputs.pkFin:
        setPkFin(value)
        break
      default:
        break
    }
  }

  const getInputValue = (fieldLabel: string) => ferrolocFields.filter((ferroloc:
     OperationFerrolocFields) => ferroloc.index === ferrolocIndex)[0].ferroloc[fieldLabel]

  return (
    <div className="ferroloc-fields">
      {FerrolocInputFields.map((field: OperationInput, index: number) => {
        if (field.fieldType === InputTypes.Select) {
          return (
            <GenericSelect
              key={`${field.label}-${index}`}
              label={field.label}
              options={lineCodes}
              onChange={e => handleChange(e.target.value, field.label)}
              value={getInputValue(field.key)}
              errorMessage={getErrorMessage((responseError || libraryResponseError), field.key, emptyFields,
                getInputValue(field.key), ferrolocIndex)}
              labelColor="#8A8A8A"
            />
          )
        }
        if (field.fieldType === InputTypes.SimpleField) {
          return (
            <GenericInput
              key={`${field.label}-${index}`}
              label={field.label}
              placeholder={field.placeholder as string}
              value={getInputValue(field.key)}
              onChange={e => handleChange(e.target.value, field.label)}
              errorMessage={getErrorMessage((responseError || libraryResponseError), field.key, emptyFields,
                getInputValue(field.key), ferrolocIndex)}
              labelColor="#8A8A8A"
            />
          )
        }
        return (
          <OperationSelectField
            key={`${field.label}-${index}`}
            value={track}
            onChange={(_event, newInputValue) => setTrack(newInputValue as string)}
            options={tracksList}
            placeholder={field.placeholder as string}
            label={field.label}
            disabled={lineCode === '' || tracksList.length === 0}
            errorMessage={getErrorMessage((responseError || libraryResponseError), field.key, emptyFields,
              track, ferrolocIndex)}
          />
        )
      })}
    </div>
  )
}
