import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import terms from 'common/terms'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import ActionButton from 'components/common/Buttons/ActionButton'
import { updateZoomPr } from 'reducers/RemarkablePoints/remarkablePoints'
import {
  IconButton, Typography,
  Autocomplete, InputLabel, TextField,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import {
  toggleDisplayExportPdfModal, updateSettings, resetExportSettings,
} from 'reducers/MultiYearPlan/ExportPdf/exportpdf'
import {
  ExportPdfInputFields, ExportPdfInput, ExportPdfCheckboxFields, ExportPdfCheckbox,
} from './ExportPdfInputFields'
import './exportpdf.scss'

export default function SettingsContainer(): ReactElement {
  const dispatch = useDispatch()
  const { exportSettings } = useSelector((state: RootState) => state.mypExportPdf)

  const onClose = () => {
    dispatch(toggleDisplayExportPdfModal(false))
    dispatch(resetExportSettings())
  }
  const onChange = (option: string, fieldLabel: string) => {
    switch (fieldLabel) {
      case terms.MultiYearPlan.ExportPdf.inputs.pageFormat.label:
        dispatch(updateSettings({
          pageFormat: option,
        }))
        break
      case terms.MultiYearPlan.ExportPdf.inputs.pageNumber.label:
        dispatch(updateSettings({
          pageNumber: option,
        }))
        dispatch(updateZoomPr(Number(option) * 2200))
        break
      case terms.MultiYearPlan.ExportPdf.inputs.pageOrientation.label:
        dispatch(updateSettings({
          pageOrientation: option,
        }))
        break
      default:
        break
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, fieldLabel: string) => {
    switch (fieldLabel) {
      case terms.MultiYearPlan.ExportPdf.checkboxes.greenOption.label:
        dispatch(updateSettings({
          greenOption: event.target.checked,
        }))
        break
      case terms.MultiYearPlan.ExportPdf.checkboxes.includeTileBlock.label:
        dispatch(updateSettings({
          includeTileblock: event.target.checked,
        }))
        break
      case terms.MultiYearPlan.ExportPdf.checkboxes.includeLegend.label:
        dispatch(updateSettings({
          includeLegend: event.target.checked,
        }))
        break
      default:
        break
    }
  }

  return (

    <div className="settings-container">
      <div className="export-pdf-header">
        <Typography>
          {terms.MultiYearPlan.ExportPdf.modalTitle}
        </Typography>
        <div>
          <IconButton onClick={onClose}><CloseIcon /></IconButton>
        </div>
      </div>
      <div className="mt-1 pr-5 d-flex flex-column h-100">
        {ExportPdfInputFields.map((field: ExportPdfInput) => (
          <div key={`${field.key}`} className="mt-4">
            <InputLabel shrink>{field.label}</InputLabel>
            <Autocomplete
              value={exportSettings[field.key]}
              onChange={(_event, newInputValue) => onChange(newInputValue as string, field.label)}
              options={field.options}
              renderInput={params => <TextField {...params} placeholder={field.placeholder} />}
            />
          </div>
        ))}
        <div className="mt-4">
          {ExportPdfCheckboxFields.map((field: ExportPdfCheckbox) => (
            <FormGroup key={`${field.key}`}>
              <FormControlLabel
                sx={{ marginBottom: 0, paddingTop: 2 }}
                control={(
                  <Checkbox
                    checked={exportSettings[field.key]}
                    onChange={e => handleChange(e, field.label)}
                  />
              )}
                label={field.label}
              />
            </FormGroup>
          ))}
        </div>
        <div className="d-flex flex-column align-items-center mt-auto">
          <ActionButton
            text={terms.Common.cancel}
            onClick={onClose}
            buttonStyle="export-pdf-cancel-btn"
          />
        </div>
      </div>
    </div>
  )
}
