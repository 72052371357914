import { ReactElement } from 'react'
import { Source, Layer } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { FiltersCategories, ObjectsLayer } from 'components/Map/ObjectsLayers/types'
import { getFilterCheckStatus, getLayerVisibility } from '../utils'

export default function mcLayer(): ReactElement {
  const { mcLayerData, filtersList } = useSelector((state: RootState) => state.object)

  return (
    <>
      <Source type="geojson" data={mcLayerData} id={ObjectsLayer.mc}>
        <Layer
          id={ObjectsLayer.mc}
          filter={['==', 'auto_added', true]}
          type="line"
          paint={{
            'line-color': '#d27519',
            'line-width': 2,
            'line-opacity': 1,
          }}
          layout={{
            visibility: getLayerVisibility(filtersList, FiltersCategories.Cartridge, ObjectsLayer.mc,
              getFilterCheckStatus(filtersList, FiltersCategories.General, ObjectsLayer.gaia)),
          }}
        />
        <Layer
          id={`${ObjectsLayer.mc}-description`}
          filter={['==', 'auto_added', true]}
          type="symbol"
          paint={{
            'text-color': '#d27519',
            'text-halo-width': 2,
            'text-halo-color': 'rgba(255,255,255,0.75)',
            'text-opacity': 1,
            'text-halo-blur': 1,
          }}
          layout={{
            'text-field': ['get', 'description'],
            'symbol-spacing': 200,
            'text-size': 14,
            'symbol-placement': 'line',
            'text-font': [
              'Roboto Condensed',
            ],
            visibility: getLayerVisibility(filtersList, FiltersCategories.Cartridge, ObjectsLayer.mc,
              getFilterCheckStatus(filtersList, FiltersCategories.General, ObjectsLayer.gaia)),
          }}
        />
      </Source>
    </>
  )
}
