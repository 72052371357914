import terms from 'common/terms'
import { FileTypes } from 'reducers/Files/types'

export const getTabChipsLabel = (fileType?: string) => {
  if (fileType) {
    return fileType === FileTypes.Schema ? terms.Common.mypAbrev : terms.Common.synopAbrev
  }
  return ''
}

export const getTabChipsColor = (fileType?: string) => {
  if (fileType) {
    return fileType === FileTypes.Schema ? '#FCF3B9' : '#CAF2CB'
  }
  return ''
}

export const getTabChipsWidth = (fileType?: string) => {
  if (fileType) {
    return fileType === FileTypes.Schema ? '18px' : '11px'
  }
  return ''
}
