import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface SynopticState {
    activeBlock: string
}

const initialState: SynopticState = {
  activeBlock: '',
}

export const synopticSlice = createSlice({
  name: 'synoptic',
  initialState,
  reducers: {
    updateActiveBlock: (state, action: PayloadAction<string>) => {
      state.activeBlock = action.payload
    },
  },
})

export const { updateActiveBlock } = synopticSlice.actions

export default synopticSlice.reducer
