import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import terms from 'common/terms'
import { patchFolderPortfolio, patchRepertoryPortfolio } from 'reducers/MultiYearPlan/Import/import.thunk'
import ImportActionButton from './ImportActionButton'

export default function FerrolocalizationConfirmationButton(): ReactElement {
  const dispatch = useDispatch()
  const { ferrolocalizationAttributs, isLoading } = useSelector((state: RootState) => state.mypImport)
  const { activeFile } = useSelector((state: RootState) => state.files)

  const isDisabled = !ferrolocalizationAttributs || ferrolocalizationAttributs[terms.Inputs.lineNumber] === null
   || ferrolocalizationAttributs[terms.Inputs.requiredTrackName] === null
   || ferrolocalizationAttributs[terms.Inputs.pkDebut] === null
   || ferrolocalizationAttributs[terms.Inputs.pkFin] === null
  const handleClick = () => {
    const portfolio = {
      column_line_code: ferrolocalizationAttributs[terms.Inputs.lineNumber].id,
      column_track: ferrolocalizationAttributs[terms.Inputs.requiredTrackName].id,
      column_start_kp: ferrolocalizationAttributs[terms.Inputs.pkDebut].id,
      column_end_kp: ferrolocalizationAttributs[terms.Inputs.pkFin].id,
    }
    if (activeFile.folder !== null) {
      dispatch(patchFolderPortfolio({
        repertoryId: activeFile.directory,
        folderId: activeFile.folder,
        fileId: activeFile.id,
        portfolio,
      }))
    } else {
      dispatch(patchRepertoryPortfolio({
        repertoryId: activeFile.directory,
        fileId: activeFile.id,
        portfolio,
      }))
    }
  }
  return (
    <ImportActionButton handleClick={handleClick} disabled={isDisabled || isLoading} />
  )
}
