import {
  Button, Card, CardActions, CardContent, CardHeader, Chip, Typography,
} from '@mui/material'
import terms from 'common/terms'
import CreationService from 'components/Dashboard/Creation/CreationService'
import { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import { toggleOpenFile, updateActiveFile, updateCreatedFile } from 'reducers/Files/files'
import { Files, FileTypes } from 'reducers/Files/types'
import { getFilesSectionsList } from 'reducers/MultiYearPlan/multiYearPlan.thunk'

import './file.scss'
import FileActionsMenu from './FileActionsMenu'

export default function FileCard({ file }: {file: Files}): ReactElement {
  const dispatch = useDispatch()

  const onFileOpen = () => {
    if (file.type === FileTypes.Synoptic) {
      dispatch(CreationService.getSingleProject(file.id))
    } else {
      dispatch(getFilesSectionsList({
        repertoryId: file.directory as number,
        folderId: file.folder as number,
        fileId: file.id,
      }))
    }
    dispatch(toggleOpenFile(true))
    dispatch(updateActiveFile(file))
    dispatch(updateCreatedFile(undefined))
  }
  return (
    <Card className="file-card">
      <CardHeader
        action={<FileActionsMenu file={file} />}
        title={file.name}
      />
      <CardContent>
        <Typography>
          {`${terms.File.lastModified} ${(new Date(file.last_modified)).toLocaleDateString()}`}
        </Typography>
      </CardContent>
      <CardActions>
        <Button variant="outlined" onClick={onFileOpen}>{terms.Common.open}</Button>
        <Chip
          size="small"
          label={file.type === FileTypes.Schema ? terms.Common.multiYearSchema : terms.Common.synoptic}
          sx={{ backgroundColor: file.type === FileTypes.Schema ? '#FCF3B9' : '#CAF2CB' }}
        />
      </CardActions>
    </Card>
  )
}
