import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import { ResponseError } from 'types'
import {
  getFolderFileCodeLines, getRepertoryFileCodeLines, postFolderPr, postRepertoryPr,
  getRepertoryPr, getFolderPr, deleteRepertoryPR, deleteFolderPR, patchRepertoryPr, patchFolderPr,
} from './remarkablePoints.thunk'
import {
  PrFields, RemarkablePointsState, PR,
} from './types'

const initialState: RemarkablePointsState = {
  prFields: [],
  lineCodes: [],
  zoomPr: 1600,
  displayDeleteModal: false,
  displayInputFieldsModal: false,
  responseError: [],
  emptyFields: false,
  prList: [
    {
      id: 'pr',
      data: [],
    },
  ],
  updateChart: false,
  contextPosition: 0,
  openPrChartContextMenu: false,
  loadingPr: false,
}

export const remarkablePointsSlice = createSlice({
  name: 'remarkablePoints',
  initialState,
  reducers: {
    updatePrFields: (state, action: PayloadAction<PrFields[]>) => {
      state.prFields = action.payload
    },
    updateZoomPr: (state, action: PayloadAction<number>) => {
      state.zoomPr = action.payload
    },
    toggleDisplayDeleteModal: (state, action: PayloadAction<boolean>) => {
      state.displayDeleteModal = action.payload
    },
    toggleDisplayInputFieldsModal: (state, action: PayloadAction<boolean>) => {
      state.displayInputFieldsModal = action.payload
    },
    updateResponseError: (state, action: PayloadAction<ResponseError[]>) => {
      state.responseError = action.payload
    },
    toggleEmptyFields: (state, action: PayloadAction<boolean>) => {
      state.emptyFields = action.payload
    },
    updateSelectedPr: (state, action: PayloadAction<PR | undefined>) => {
      state.selectedPr = action.payload
    },
    updateContextPosition: (state, action: PayloadAction<number>) => {
      state.contextPosition = action.payload
    },
    toggleOpenPrChartContextMenu: (state, action: PayloadAction<boolean>) => {
      state.openPrChartContextMenu = action.payload
    },
  },
  extraReducers: builder => {
    builder.addMatcher(isAnyOf(
      getRepertoryFileCodeLines.fulfilled,
      getFolderFileCodeLines.fulfilled,
    ), (state, action: PayloadAction<string[]>) => {
      state.lineCodes = action.payload
    })
    builder.addMatcher(isAnyOf(
      getRepertoryPr.fulfilled,
      getFolderPr.fulfilled,
    ), (state, action: PayloadAction<PR[]>) => {
      state.prList[0].data = action.payload
      state.loadingPr = false
    })
    builder.addMatcher(isAnyOf(
      postRepertoryPr.fulfilled,
      postFolderPr.fulfilled,
    ), state => {
      state.responseError = []
      state.emptyFields = false
      state.updateChart = !state.updateChart
      state.displayInputFieldsModal = false
      state.prFields = []
      state.loadingPr = false
    })
    builder.addMatcher(isAnyOf(
      postRepertoryPr.rejected,
      postFolderPr.rejected,
      patchRepertoryPr.rejected,
      patchFolderPr.rejected,
    ), (state, action) => {
      if (state.responseError.filter(error => error.index === action.payload?.index).length === 0) {
        state.responseError.push(action.payload as ResponseError)
      } else {
        state.responseError = state.responseError.map(error => (error.index !== action.payload?.index ? error
          : action.payload as ResponseError))
      }
      state.loadingPr = false
    })
    builder.addMatcher(isAnyOf(
      deleteRepertoryPR.fulfilled,
      deleteFolderPR.fulfilled,
    ), state => {
      state.displayDeleteModal = false
      state.updateChart = !state.updateChart
      state.selectedPr = undefined
      state.loadingPr = false
    })
    builder.addMatcher(isAnyOf(
      deleteRepertoryPR.rejected,
      deleteFolderPR.rejected,
    ), state => {
      state.loadingPr = false
    })
    builder.addMatcher(isAnyOf(
      patchRepertoryPr.fulfilled,
      patchFolderPr.fulfilled,
    ), state => {
      state.displayInputFieldsModal = false
      state.updateChart = !state.updateChart
      state.selectedPr = undefined
      state.prFields = []
      state.loadingPr = false
    })
    builder.addMatcher(isAnyOf(
      patchRepertoryPr.pending,
      patchFolderPr.pending,
      postRepertoryPr.pending,
      postFolderPr.pending,
      deleteRepertoryPR.pending,
      deleteFolderPR.pending,
    ), state => {
      state.loadingPr = true
    })
  },
})

export const {
  updatePrFields, updateZoomPr, toggleDisplayDeleteModal, toggleDisplayInputFieldsModal, updateResponseError,
  toggleEmptyFields, updateSelectedPr, updateContextPosition, toggleOpenPrChartContextMenu,
} = remarkablePointsSlice.actions
export default remarkablePointsSlice.reducer
