/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */
import {
  deleteRequest, get, patch, post,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Files } from 'reducers/Files/types'
import { ThunkApi } from 'types'
import {
  DeleteParams, DropParams, GetParams, ImportParams, MYPSection, PostParams,
} from './types'

const declareFilesSection = createAsyncThunk<MYPSection, PostParams, ThunkApi>(
  'myp/declareFilesSection',
  async (params: PostParams, thunkApi) => {
    if (params.folderId && params.folderId !== null) {
      try {
        const response = await
        // eslint-disable-next-line max-len
        post(`/module_schematique/directories/${params.repertoryId}/folders/${params?.folderId}/projects/${params.fileId}/sections`,
          params.section)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response: MYPSection = await
        post(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/sections`,
          params.section)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

const deleteRepertoryFileSection = createAsyncThunk(
  'myp/deleteRepertoryFileSection',
  async (params: DeleteParams, thunkApi) => {
    try {
      await
      // eslint-disable-next-line max-len
      deleteRequest(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/sections/${params.sectionId}`)
      return params.sectionId
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const importRepertoryFileSection = createAsyncThunk<MYPSection[], ImportParams, ThunkApi>(
  'myp/importRepertoryFileSection',
  async (params: ImportParams, thunkApi) => {
    try {
      const response: MYPSection[] = await
      // eslint-disable-next-line max-len
      post(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/import-sections`,
        { project_id: params.projectId })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const patchRepertoryFileSection = createAsyncThunk(
  'myp/patchRepertoryFileSection',
  async (params: PostParams, thunkApi) => {
    try {
      const response = await
      // eslint-disable-next-line max-len
      patch(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/sections/${params.section.id}`,
        params.section)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const dropRepertoryFileSections = createAsyncThunk<string, DropParams, ThunkApi>(
  'myp/dropRepertoryFileSections',
  async (params: DropParams, thunkApi) => {
    try {
      await
      // eslint-disable-next-line max-len
      deleteRequest(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/drop-sections`)
      return params.declarationMode
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const getFilesSectionsList = createAsyncThunk(
  'myp/getFilesSectionsList',
  async (params: GetParams, thunkApi) => {
    if (params.folderId !== null) {
      try {
        const response = await
        // eslint-disable-next-line max-len
        get(`/module_schematique/directories/${params.repertoryId}/folders/${params?.folderId}/projects/${params.fileId}/sections`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response = await
        get(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/sections/`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

const deleteFolderFilesSection = createAsyncThunk(
  'myp/deleteFolderFilesSection',
  async (params: DeleteParams, thunkApi) => {
    try {
      await
      // eslint-disable-next-line max-len
      deleteRequest(`/module_schematique/directories/${params.repertoryId}/folders/${params?.folderId}/projects/${params.fileId}/sections/${params.sectionId}`)
      return params.sectionId
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const importFolderFilesSection = createAsyncThunk<MYPSection[], ImportParams, ThunkApi>(
  'myp/importFolderFilesSection',
  async (params: ImportParams, thunkApi) => {
    try {
      const response: MYPSection[] = await
      // eslint-disable-next-line max-len
      post(`/module_schematique/directories/${params.repertoryId}/folders/${params?.folderId}/projects/${params.fileId}/import-sections`,
        { project_id: params.projectId })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const dropFolderFilesSections = createAsyncThunk<string, DropParams, ThunkApi>(
  'myp/dropFolderFilesSections',
  async (params: DropParams, thunkApi) => {
    try {
      await
      // eslint-disable-next-line max-len
      deleteRequest(`/module_schematique/directories/${params.repertoryId}/folders/${params?.folderId}/projects/${params.fileId}/drop-sections`)
      return params.declarationMode
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const patchFolderFilesSection = createAsyncThunk(
  'myp/patchFolderFilesSection',
  async (params: PostParams, thunkApi) => {
    try {
      const response = await
      // eslint-disable-next-line max-len
      patch(`/module_schematique/directories/${params.repertoryId}/folders/${params?.folderId}/projects/${params.fileId}/sections/${params.section.id}`,
        params.section)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const getAllFilesList = createAsyncThunk<Files[], string, ThunkApi>(
  'myp/getAllFilesList',
  async (search: string, thunkApi) => {
    try {
      const response: Files[] = await get('/module_schematique/projects-search/', { search })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

export {
  getFilesSectionsList,
  declareFilesSection,
  deleteRepertoryFileSection,
  deleteFolderFilesSection,
  importRepertoryFileSection,
  importFolderFilesSection,
  dropRepertoryFileSections,
  dropFolderFilesSections,
  getAllFilesList,
  patchRepertoryFileSection,
  patchFolderFilesSection,
}
