import terms from 'common/terms'
import ActionButton from 'components/common/Buttons/ActionButton'
import { SearchFields } from 'components/Portfolio/types'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  toggleAddFilter, updateFilters, updateSearchValues, updateWorkPhases,
} from 'reducers/library'
import { RootState } from 'Store'

export default function AddFiltersButton(): ReactElement {
  const dispatch = useDispatch()
  const { addFilter, searchValues, filters } = useSelector((state: RootState) => state.library)

  const handleClick = () => {
    dispatch(toggleAddFilter(!addFilter))
    const newFilter = searchValues.filter((value: SearchFields) => value.index === -1)[0]?.value
    if (addFilter && newFilter !== '') {
      if (newFilter === 'Phases études/travaux') dispatch(updateWorkPhases([]))
      dispatch(updateFilters({ ...filters, [`${newFilter}`]: [] }))
      dispatch(updateSearchValues(searchValues.filter((value: SearchFields) => value.index !== -1)))
    }
  }

  return (
    <ActionButton
      text={addFilter ? terms.Portfolio.Library.Filters.validationButton : terms.Portfolio.Library.Filters.addButton}
      onClick={handleClick}
      buttonStyle="modal-btn add-filter-btn"
    />
  )
}
