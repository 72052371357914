import { ReactElement, useState } from 'react'
import terms from 'common/terms'
import ActionButton from 'components/common/Buttons/ActionButton'
import { useDispatch } from 'react-redux'
import { resetActiveFile } from 'reducers/Files/files'
import { resetContexts } from 'reducers/Folders/folder'
import { updateSectionsList } from 'reducers/creation'
import { updateMypSectionsList, resetUpdatedMypSection } from 'reducers/MultiYearPlan/multiYearPlan'
import FileCreationModal from './FileCreationModal'

export default function FileCreationButton(): ReactElement {
  const dispatch = useDispatch()
  const [open, setOpen] = useState<boolean>(false)

  const handleOpen = () => {
    setOpen(true)
    dispatch(resetActiveFile())
    dispatch(resetContexts())
    dispatch(updateSectionsList([]))
    dispatch(updateMypSectionsList([]))
    dispatch(resetUpdatedMypSection())
  }

  return (
    <div className="d-flex justify-content-center">
      <ActionButton
        text={terms.File.createDocument}
        leftIcon
        leftIconStyle="btn-icon"
        onClick={handleOpen}
        buttonStyle="add-repertory-btn"
      />
      <FileCreationModal open={open} handleOpen={setOpen} />
    </div>
  )
}
