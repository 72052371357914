import { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { InputBase } from '@mui/material'
import { ObjectFamily } from 'reducers/MultiYearPlan/Import/ObjectsFamilies/type'
import { postFamiliyTrack } from 'reducers/MultiYearPlan/Import/ObjectsFamilies/objectsFamilies.thunk'

type Props = {
  onTrackAdd: () => void
  objectFamily: ObjectFamily
}

export default function TrackLabelInput(
  { objectFamily, onTrackAdd }: Props,
): ReactElement {
  const dispatch = useDispatch()
  const { activeFile } = useSelector((state: RootState) => state.files)
  const [trackLabel, setTrackLabel] = useState<string>('')

  const handleOutsideClick = () => {
    if (activeFile && trackLabel) {
      dispatch(postFamiliyTrack({
        repertoryId: activeFile.directory,
        folderId: activeFile.folder,
        fileId: activeFile.id,
        classificationId: objectFamily.id,
        track: {
          label: trackLabel,
        },
      }))
      onTrackAdd()
    }
  }

  return (
    <InputBase
      value={trackLabel}
      autoFocus
      onBlur={() => handleOutsideClick()}
      onChange={e => setTrackLabel(e.target.value)}
      id="object-family-track"
    />
  )
}
