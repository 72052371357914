import { ReactElement } from 'react'
import PanelHeader from './PanelHeader'
import ImportSettings from './ImportSettings'
import LibrarySettings from './Library/LibrarySettings'
import ExportSettings from './ExportSettings'
import FiltersSettings from './FiltersSettings'

import './mypSetting.scss'

export default function MypSettingsPanel(): ReactElement {
  return (
    <div className="myp-settings-panel">
      <PanelHeader />
      <div className="myp-setting-panel-content">
        <FiltersSettings />
        <ImportSettings />
        <LibrarySettings />
        <ExportSettings />
      </div>
    </div>
  )
}
