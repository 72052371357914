export enum ObjectsLayer {
    tiv = 'module_schematique_tiv',
    label = 'module_schematique_label',
    signal = 'module_schematique_infra_signal',
    rk = 'module_schematique_infra_rk',
    adv = 'module_schematique_infra_adv',
    te = 'module_schematique_infra_te',
    pe = 'module_schematique_infra_pe',
    bv = 'module_schematique_infra_bv',
    gaia = 'module_schematique_objects_auto',
    manual = 'module_schematique_objects_manual',
    vmax = 'module_schematique_infra_vm',
    gv= 'module_schematique_infra_gv',
    gf= 'module_schematique_infra_gf',
    pan = 'module_schematique_infra_pan',
    uic = 'module_schematique_infra_groupe_uic',
    secteur = 'module_schematique_infra_secteur',
    ss = 'module_schematique_infra_ss',
    css = 'module_schematique_infra_css',
    vu = 'module_schematique_infra_pe',
    vb = 'module_schematique_infra_pe',
    ipcs = 'module_schematique_infra_pe',
    mc = 'module_schematique_infra_mc',
    regionsncf = 'module_schematique_infra_region_sncf',
    regionadm = 'module_schematique_infra_region_adm',
    tunnel = 'module_schematique_infra_tunnel',
    poste = 'module_schematique_infra_poste',
  }

export enum LabelsLayersIds {
  label = 'labelLayer',
  actionZones = 'actionZoneLayer',
  outline = 'outlineLayer'
}

export enum FiltersCategories {
  Infra = 'Infrastructure',
  Signal = 'Signalisation',
  Te = 'Traction électrique',
  General = 'Général',
  ModeExploit = 'Mode d\'exploitation',
  Ouvrage = 'Ouvrage d\'arts',
  Carre = 'CARRE',
  Cartridge = 'Cartouche bas de page',
}

export enum ObjectsName {
  VU = 'Voie unique',
  VB = 'Voie banalisée',
  IPCS = 'IPCS',
}

export enum SignalType {
  carre = 'CARRE',
  carrev = 'CARRE_VIOLET',
  disque = 'DISQUE',
  ga = 'GUIDON_DARRET',
  sem = 'SEMAPHORE',
  av = 'AVERTISSEMENT',
}
