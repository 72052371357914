import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import { ResponseError } from 'types'
import { DefectedOperation, MypLibraryState } from './types'
import {
  deleteDefectedOperation, fixDefectedOperation, getDefectedOperation, getDefectedOperations, getPositionedOperations,
} from './library.thunk'
import { Operation } from '../OperationActions/types'

const initialState: MypLibraryState = {
  displayLibraryPanel: false,
  defectedOperations: [],
  positionedOperations: [],
  updateLibrary: false,
  loadingPositionedOperations: false,
  loadingDefectedOperations: false,
  displayPlaceDefectedOperationModal: false,
  searchValue: '',
  deletingDefectedOperation: false,
}

export const mypLibrarySlice = createSlice({
  name: 'mypLibrary',
  initialState,
  reducers: {
    toggleDisplayLibraryPanel: (state, action: PayloadAction<boolean>) => {
      state.displayLibraryPanel = action.payload
    },
    setSelectedDefectedOperation: (state, action: PayloadAction<DefectedOperation | undefined>) => {
      state.selectedDefectedOperation = action.payload
    },
    setSelectedDefectedOperationForDelete: (state, action: PayloadAction<DefectedOperation | undefined>) => {
      state.selectedDefectedOperationForDelete = action.payload
    },
    toggleDisplayPlaceDefectedOperationModal: (state, action: PayloadAction<boolean>) => {
      state.displayPlaceDefectedOperationModal = action.payload
    },
    setResponseError: (state, action: PayloadAction<ResponseError | undefined>) => {
      state.libraryResponseError = action.payload
    },
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload
    },
    setSelectedPositionedOperation: (state, action: PayloadAction<Operation | undefined>) => {
      state.selectedPositionedOperation = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getDefectedOperations.fulfilled, (state, action) => {
      state.defectedOperations = action.payload
      state.loadingDefectedOperations = false
    })
    builder.addCase(getDefectedOperations.pending, state => {
      if (state.updateLibrary) {
        state.loadingDefectedOperations = true
      }
    })
    builder.addCase(getPositionedOperations.fulfilled, (state, action) => {
      state.positionedOperations = action.payload
      state.loadingPositionedOperations = false
    })
    builder.addCase(getPositionedOperations.pending, state => {
      if (state.updateLibrary) {
        state.loadingPositionedOperations = true
      }
    })
    builder.addCase(getDefectedOperation.fulfilled, (state, action) => {
      state.selectedDefectedOperation = action.payload
      state.displayPlaceDefectedOperationModal = true
    })
    builder.addCase(deleteDefectedOperation.pending, state => {
      state.deletingDefectedOperation = true
    })
    builder.addMatcher(isAnyOf(
      getDefectedOperations.rejected,
      getPositionedOperations.rejected,
      fixDefectedOperation.rejected,
      deleteDefectedOperation.rejected,
    ), (state, action) => {
      state.libraryResponseError = action.payload
      state.loadingDefectedOperations = false
      state.loadingPositionedOperations = false
      state.deletingDefectedOperation = false
    })
    builder.addMatcher(isAnyOf(
      deleteDefectedOperation.fulfilled,
      fixDefectedOperation.fulfilled,
    ), state => {
      state.libraryResponseError = undefined
      state.selectedDefectedOperation = undefined
      state.selectedDefectedOperationForDelete = undefined
      state.updateLibrary = !state.updateLibrary
      state.displayPlaceDefectedOperationModal = false
      state.deletingDefectedOperation = false
    })
  },
})

export const {
  toggleDisplayLibraryPanel, setSelectedDefectedOperation, toggleDisplayPlaceDefectedOperationModal,
  setResponseError, setSearchValue, setSelectedPositionedOperation, setSelectedDefectedOperationForDelete,
} = mypLibrarySlice.actions

export default mypLibrarySlice.reducer
