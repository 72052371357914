import { ReactElement } from 'react'
import ActionButton from 'components/common/Buttons/ActionButton'
import history from 'customHistory'
import PathNames from 'common/PathNames'
import terms from 'common/terms'
import { useDispatch } from 'react-redux'
import { resetActiveRepertory } from 'reducers/Repertories/repertories'

export default function SkipPictureConfigurationButton(): ReactElement {
  const dispatch = useDispatch()

  const handleClose = () => {
    history.push(PathNames.Home)
    dispatch(resetActiveRepertory())
  }
  return (
    <div className="d-flex flex-row justify-content-center">
      <ActionButton
        text={terms.Repertory.skipStep}
        onClick={handleClose}
        buttonStyle="skip-btn"
      />
    </div>
  )
}
