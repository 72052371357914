import { ReactElement, useEffect, useState } from 'react'
import {
  Accordion, AccordionDetails, AccordionSummary, Typography, IconButton, Button,
} from '@mui/material'
import nextId from 'react-id-generator'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { ObjectFamily } from 'reducers/MultiYearPlan/Import/ObjectsFamilies/type'
import { phaseFilter } from 'reducers/MultiYearPlan/Operations/types'
import { FilterType } from 'reducers/MultiYearPlan/TitleBlock/types'
import Checkbox from '@mui/material/Checkbox'
import './mypSetting.scss'
import terms from 'common/terms'
import { ColumnValues } from 'reducers/MultiYearPlan/Settings/types'

type Props = {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>,
       v: ObjectFamily | FilterType | phaseFilter | ColumnValues) => void
  options: ObjectFamily[] | FilterType[] | phaseFilter[] | ColumnValues[]
  summary: string
  filterId?: number
  deleteFilter?: boolean
  optionLabelType?: string
  onFilterApply?: () => void
  onDelete?: (v: number) => void
}

export default function FilterAccordion({
  summary, handleChange, options, deleteFilter, optionLabelType, filterId, onDelete, onFilterApply,
}: Props): ReactElement {
  const [expanded, setExpanded] = useState<boolean>(false)

  useEffect(() => {
    if (filterId === -1) {
      setExpanded(!expanded)
    }
  }, [filterId])

  const optionLabel = (option: ObjectFamily | FilterType | phaseFilter | ColumnValues) => {
    if (optionLabelType === 'phase') {
      return (option as phaseFilter).phase
    }
    if (optionLabelType === 'column') {
      return (option as ColumnValues).value !== '' ? (option as ColumnValues).value
        : terms.MultiYearPlan.SettingsPanel.empty
    }
    return (option as ObjectFamily || option as FilterType).label
  }
  return (
    <>
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)} className="filters-accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography className="filter-summary">
            {summary}
          </Typography>
          {deleteFilter && onDelete
          && (
          <IconButton onClick={e => {
            e.stopPropagation()
            onDelete(filterId as number)
          }}
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
          )}
        </AccordionSummary>
        <AccordionDetails className="filters-accordion-list">
          <FormGroup>
            {options?.length !== 0 ? options?.map((option: ObjectFamily | FilterType | phaseFilter | ColumnValues) => (
              <FormControlLabel
                key={nextId()}
                sx={{ marginBottom: 0, paddingTop: 2 }}
                control={(
                  <Checkbox
                    checked={option.is_visible}
                    onChange={e => handleChange(e, option)}
                    disabled={deleteFilter && filterId !== undefined && filterId !== -1}
                  />
                )}
                label={optionLabel(option)}
              />
            )) : <Typography>{terms.MultiYearPlan.SettingsPanel.noOptions}</Typography>}
          </FormGroup>
          {deleteFilter && options.length !== 0 && filterId === -1 && (
            <div className="validate-filter-btn">
              <Button onClick={onFilterApply}>{terms.MultiYearPlan.SettingsPanel.applyFilter}</Button>
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  )
}

FilterAccordion.defaultProps = {
  filterId: undefined,
  deleteFilter: false,
  onDelete: () => null,
  optionLabelType: 'label',
  onFilterApply: () => null,
}
