import { ReactElement } from 'react'
import ActionButton from 'components/common/Buttons/ActionButton'
import terms from 'common/terms'
import history from 'customHistory'
import PathNames from 'common/PathNames'
import { useDispatch } from 'react-redux'
import { resetState } from 'reducers/MultiYearPlan/Import/import'
import { toggleDisplaySettingsPanel } from 'reducers/MultiYearPlan/Settings/settings'
import { toggleDisplayLibraryPanel } from 'reducers/MultiYearPlan/Library/library'

export default function ImportPortfolioButton(): ReactElement {
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(resetState())
    dispatch(toggleDisplaySettingsPanel(false))
    dispatch(toggleDisplayLibraryPanel(false))
    history.push(PathNames.MypImport)
  }

  return (
    <ActionButton
      text={terms.MultiYearPlan.importBtn}
      onClick={handleClick}
      buttonStyle="myp-import-portfolio-btn"
    />
  )
}
