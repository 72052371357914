import { createTheme } from '@mui/material/styles'
import { frFR } from '@mui/material/locale'

export const theme = createTheme(
  {
    typography: {
      fontFamily: 'Avenir',
    },
    components: {
      MuiIconButton: {
        styleOverrides: {
          root: {
            '&:focus': {
              outline: 'none',
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            '&:focus': {
              outline: 'none',
            },
          },
        },
      },
    },
  },
  frFR,
)

export default theme
