import { ReactElement } from 'react'
import { Source, Layer } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { FiltersCategories, ObjectsLayer } from 'components/Map/ObjectsLayers/types'
import { getFilterCheckStatus, getLayerVisibility } from '../utils'

export default function PanLayer(): ReactElement {
  const { panLayerData, filtersList } = useSelector((state: RootState) => state.object)

  return (
    <>
      <Source type="geojson" data={panLayerData} id={ObjectsLayer.pan}>
        <Layer
          id={`icon-${ObjectsLayer.pan}`}
          filter={['==', 'auto_added', true]}
          type="symbol"
          paint={{}}
          minzoom={12}
          layout={{
            'icon-image': 'pan',
            'icon-rotate': ['case',
              ['>', ['get', 'angle'], 190], ['+', 90, ['get', 'angle']],
              ['+', 270, ['get', 'angle']],
            ],
            'icon-size': [
              'interpolate',
              ['linear'],
              ['zoom'],
              10,
              0.1,
              15,
              0.7,
            ],
            'icon-rotation-alignment': 'map',
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
            visibility: getLayerVisibility(filtersList, FiltersCategories.Infra, ObjectsLayer.pan,
              getFilterCheckStatus(filtersList, FiltersCategories.General, ObjectsLayer.gaia)),
          }}
        />
        <Layer
          id={`description-${ObjectsLayer.pan}`}
          filter={['==', 'auto_added', true]}
          minzoom={12}
          type="symbol"
          paint={{
            'text-color': '#000000',
          }}
          layout={{
            'text-rotation-alignment': 'map',
            'text-allow-overlap': true,
            'text-offset': ['literal', [0, -3]],
            'text-field': ['get', 'description'],
            'text-rotate': ['case',
              ['>', ['get', 'angle'], 190], ['+', 90, ['get', 'angle']],
              ['+', 270, ['get', 'angle']],
            ],
            'text-size': ['interpolate', ['linear'], ['zoom'], 10, 5, 15, 12],
            'text-font': ['Roboto Condensed'],
            visibility: getLayerVisibility(filtersList, FiltersCategories.Infra, ObjectsLayer.pan,
              getFilterCheckStatus(filtersList, FiltersCategories.General, ObjectsLayer.gaia)),
          }}
        />
      </Source>
    </>
  )
}
