import { ElementType } from 'react'

export type ResponseError = {
    status: string | number,
    message: any,
    index?: number
  }

export type ThunkApi = {
    [x: string]: any
    rejectValue: ResponseError
  }

export type ObjOfStr = {
  [key: string]: string;
}

export enum InputTypes {
  SimpleField = 'simpleField',
  Select = 'select',
}
export interface InputField {
    key: string,
    label: string,
    hideLabel?: boolean,
    placeholder: string,
    required: boolean,
    values?: ObjOfStr[] | string[],
    inputType: InputTypes,
    type?: TypeValues,
    pattern?: string,
    maxLength?: number,
    withSearch?: boolean,
    disabled?: boolean,
    multiple?: boolean,
}

export type FieldValue = string | number | ObjOfStr

export type TypeValues = 'input' | 'textarea' | ElementType<any> | undefined
