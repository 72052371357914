/* eslint-disable camelcase */
/* eslint-disable max-len */
import { ReactElement, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  LocalizationMap, Worker, Viewer,
} from '@react-pdf-viewer/core'
import { toolbarPlugin } from '@react-pdf-viewer/toolbar'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import type { ToolbarSlot, TransformToolbarSlot } from '@react-pdf-viewer/toolbar'
import ActionButton from 'components/common/Buttons/ActionButton'
import { RootState } from 'Store'
import JsPDF from 'jspdf'
import Loader from 'components/Loader'
import html2canvas from 'html2canvas'
import LogoSncf from 'assets/logos/LOGO_SNCF_RESEAU.png'
import './exportpdf.scss'
import { PageOrientation } from 'components/Export/types'
import terms from 'common/terms'
import fr_FR from '@react-pdf-viewer/locales/lib/fr_FR.json'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/toolbar/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'

export default function PrevizualisationContainer(): ReactElement {
  const { exportSettings } = useSelector((state: RootState) => state.mypExportPdf)
  // const { zoomPr } = useSelector((state: RootState) => state.remarkablePoints)
  const { activeFile } = useSelector((state: RootState) => state.files)
  const [iframe, setiframe] = useState<any>(undefined)
  const [loadPDF, setLoadPDF] = useState<boolean>(false)

  const exportPdf = async () => {
    const lineChart = document.getElementById('line-chart-container-sp')
    const leftColumn = document.getElementById('line-chart-container-cl')
    const timeline = document.getElementById('line-chart-container-pr')
    const lineCode = document.getElementById('line-chart-container-codel')
    const comments = document.getElementById('line-chart-container-comments')
    const legend = document.getElementById('legend-wrapper')
    const cbpColumn = document.getElementById('line-chart-container-tbc')
    const cbpContent = document.getElementById('line-chart-container-tbo')
    if (!lineChart || !leftColumn || !timeline || !lineCode || !comments || !legend || !cbpColumn || !cbpContent) {
      setLoadPDF(false)
      return
    }
    const MAX_WIDTH = 16000 // Max canvas width is 16384px.
    const WINDOW_WIDTH = 2200 * exportSettings.pageNumber + 250
    const SCALE = Math.min(MAX_WIDTH / WINDOW_WIDTH, 2)
    const YEAR_BLOCK_HEIGHT = leftColumn.getBoundingClientRect().height / (activeFile.time_range.end_year - activeFile.time_range.start_year + 1)

    const canvasLeftColumn = await html2canvas(leftColumn, { scale: SCALE })
    const canvasLineChart = await html2canvas(lineChart, { windowWidth: WINDOW_WIDTH + 250, scale: SCALE })
    const canvasTimeline = await html2canvas(timeline, { windowWidth: WINDOW_WIDTH, scale: SCALE })
    const canvasLineCode = await html2canvas(lineCode, { windowWidth: WINDOW_WIDTH, scale: SCALE })
    const canvasComments = await html2canvas(comments, { windowWidth: 2700, scale: SCALE })
    const canvasLegend = await html2canvas(legend, { windowWidth: 2450, scale: SCALE })
    const canvasCbpColumn = await html2canvas(cbpColumn, { scale: SCALE })
    const canvasCbpContent = await html2canvas(cbpContent, { scale: SCALE, windowWidth: WINDOW_WIDTH + 250 })

    const imgDataLineChart = canvasLineChart.getContext('2d')
    const imgDataLeftColumn = canvasLeftColumn.getContext('2d')
    const imgDataTimeline = canvasTimeline.getContext('2d')
    const imgDataLineCode = canvasLineCode.getContext('2d')
    const imgDataComments = canvasComments.getContext('2d')
    const imgDataLegend = canvasLegend.getContext('2d')
    const imgDataCbpColumn = canvasCbpColumn.getContext('2d')
    const imgDataCbpContent = canvasCbpContent.getContext('2d')
    if (!imgDataLineChart || !imgDataLeftColumn || !imgDataTimeline || !imgDataLineCode || !imgDataComments || !imgDataLegend || !imgDataCbpColumn || !imgDataCbpContent) {
      setLoadPDF(false)
      return
    }
    const pdf = new JsPDF(
      exportSettings.pageOrientation === PageOrientation.landscape ? 'landscape'
        : 'portrait', 'mm', exportSettings.pageFormat,
    )

    const pageMargin = 10
    // Dimensions in mm
    const pageWidth = pdf.internal.pageSize.getWidth() - 2 * pageMargin
    const pageHeight = pdf.internal.pageSize.getHeight() - 2 * pageMargin

    // Dimensions in px
    const CONTENT_WIDTH = 2200 * SCALE
    const LEFT_COLUMN_WIDTH = 250 * SCALE
    const TIMELINE_HEIGHT = 150 * SCALE
    const LINE_CODE_HEIGHT = 40 * SCALE
    const COMMENTS_HEIGHT = 125 * SCALE
    const LEGEND_HEIGHT = exportSettings.includeLegend ? canvasLegend.height : 0
    const IMAGE_WIDTH = CONTENT_WIDTH + LEFT_COLUMN_WIDTH
    const IMAGE_HEIGHT = pageHeight * (IMAGE_WIDTH / pageWidth)
    const CBP_HEIGHT = exportSettings.includeTileblock ? canvasCbpContent.height : 0
    let CONTENT_HEIGHT = IMAGE_HEIGHT - TIMELINE_HEIGHT - LINE_CODE_HEIGHT - LEGEND_HEIGHT - COMMENTS_HEIGHT - CBP_HEIGHT
    CONTENT_HEIGHT -= CONTENT_HEIGHT % (YEAR_BLOCK_HEIGHT * SCALE)

    const nbPages = exportSettings.pageNumber * Math.ceil(canvasLineChart.height / CONTENT_HEIGHT)
    let currentPage = 1

    const drawingCanvas = document.createElement('canvas')
    const drawingContext = drawingCanvas.getContext('2d')
    if (!drawingContext) return
    drawingCanvas.width = IMAGE_WIDTH
    drawingCanvas.height = IMAGE_HEIGHT
    drawingContext.fillStyle = 'white'
    drawingContext.fillRect(0, 0, IMAGE_WIDTH, IMAGE_HEIGHT)
    let x = 0
    let y = 0
    let croppedImgData = imgDataLineChart.getImageData(x, y, CONTENT_WIDTH, Math.min(CONTENT_HEIGHT, canvasLineChart.height - y))
    let croppedImgDataTimeline = imgDataTimeline.getImageData(x, 50 * SCALE, Math.min(CONTENT_WIDTH, canvasTimeline.width - x), TIMELINE_HEIGHT)
    let croppedImgDataLineCode = imgDataLineCode.getImageData(x, 0, Math.min(CONTENT_WIDTH, canvasTimeline.width - x), LINE_CODE_HEIGHT)
    let croppedImgDataLeftColumn = imgDataLeftColumn.getImageData(0, y, LEFT_COLUMN_WIDTH, Math.min(CONTENT_HEIGHT, canvasLineChart.height - y))
    const croppedImgDataComments = imgDataComments.getImageData(0, 0, 2450 * SCALE, COMMENTS_HEIGHT)
    const croppedImgDataLegend = exportSettings.includeLegend ? imgDataLegend.getImageData(0, 0, 2450 * SCALE, LEGEND_HEIGHT) : undefined
    const croppedImgDataCbpColumn = exportSettings.includeTileblock ? imgDataCbpColumn.getImageData(0, 0, LEFT_COLUMN_WIDTH, CBP_HEIGHT) : undefined
    let croppedImgDataCbpContent = exportSettings.includeTileblock ? imgDataCbpContent.getImageData(x, 0, CONTENT_WIDTH, CBP_HEIGHT) : undefined

    drawingContext.putImageData(croppedImgDataTimeline, LEFT_COLUMN_WIDTH, 0)
    drawingContext.putImageData(croppedImgDataLineCode, LEFT_COLUMN_WIDTH, TIMELINE_HEIGHT)
    drawingContext.putImageData(croppedImgData, LEFT_COLUMN_WIDTH, TIMELINE_HEIGHT + LINE_CODE_HEIGHT)
    drawingContext.putImageData(croppedImgDataLeftColumn, 0, TIMELINE_HEIGHT + LINE_CODE_HEIGHT)
    if (croppedImgDataCbpColumn && croppedImgDataCbpContent) {
      drawingContext.putImageData(croppedImgDataCbpColumn, 0, TIMELINE_HEIGHT + LINE_CODE_HEIGHT + Math.min(CONTENT_HEIGHT, canvasLineChart.height - y))
      drawingContext.putImageData(croppedImgDataCbpContent, LEFT_COLUMN_WIDTH, TIMELINE_HEIGHT + LINE_CODE_HEIGHT + Math.min(CONTENT_HEIGHT, canvasLineChart.height - y))
    }
    if (croppedImgDataLegend) {
      drawingContext.putImageData(croppedImgDataLegend, 0, TIMELINE_HEIGHT + LINE_CODE_HEIGHT + Math.min(CONTENT_HEIGHT, canvasLineChart.height - y) + CBP_HEIGHT)
    }
    drawingContext.putImageData(croppedImgDataComments, 0, TIMELINE_HEIGHT + LINE_CODE_HEIGHT + Math.min(CONTENT_HEIGHT, canvasLineChart.height - y) + LEGEND_HEIGHT + CBP_HEIGHT)
    pdf.addImage(drawingCanvas, 'JPEG', pageMargin, pageMargin, pageWidth, pageHeight, '', 'FAST')
    pdf.text(`${currentPage}/${nbPages}`, pageWidth, pageHeight + pageMargin)
    pdf.text(activeFile.name, pdf.internal.pageSize.getWidth() / 2, pageMargin, { align: 'center' })
    pdf.addImage(LogoSncf, 'PNG', pageMargin, pageMargin, 23, 16, '', 'FAST')

    y += CONTENT_HEIGHT
    while (x < canvasLineChart.width) {
      while (y < canvasLineChart.height) {
        pdf.addPage()
        currentPage += 1
        croppedImgData = imgDataLineChart.getImageData(x, y, CONTENT_WIDTH, Math.min(CONTENT_HEIGHT, canvasLineChart.height - y))
        croppedImgDataTimeline = imgDataTimeline.getImageData(x, 50 * SCALE, Math.min(CONTENT_WIDTH, canvasTimeline.width - x), TIMELINE_HEIGHT)
        croppedImgDataLineCode = imgDataLineCode.getImageData(x, 0, Math.min(CONTENT_WIDTH, canvasTimeline.width - x), LINE_CODE_HEIGHT)
        croppedImgDataLeftColumn = imgDataLeftColumn.getImageData(0, y, LEFT_COLUMN_WIDTH, Math.min(CONTENT_HEIGHT, canvasLineChart.height - y))
        croppedImgDataCbpContent = exportSettings.includeTileblock ? imgDataCbpContent.getImageData(x, 0, CONTENT_WIDTH, CBP_HEIGHT) : undefined
        drawingContext.fillStyle = 'white'
        drawingContext.fillRect(0, 0, IMAGE_WIDTH, IMAGE_HEIGHT)
        drawingContext.putImageData(croppedImgData, LEFT_COLUMN_WIDTH, TIMELINE_HEIGHT + LINE_CODE_HEIGHT)
        drawingContext.putImageData(croppedImgDataTimeline, LEFT_COLUMN_WIDTH, 0)
        drawingContext.putImageData(croppedImgDataLineCode, LEFT_COLUMN_WIDTH, TIMELINE_HEIGHT)
        drawingContext.putImageData(croppedImgDataLeftColumn, 0, TIMELINE_HEIGHT + LINE_CODE_HEIGHT)
        if (croppedImgDataCbpColumn && croppedImgDataCbpContent) {
          drawingContext.putImageData(croppedImgDataCbpColumn, 0, TIMELINE_HEIGHT + LINE_CODE_HEIGHT + Math.min(CONTENT_HEIGHT, canvasLineChart.height - y))
          drawingContext.putImageData(croppedImgDataCbpContent, LEFT_COLUMN_WIDTH, TIMELINE_HEIGHT + LINE_CODE_HEIGHT + Math.min(CONTENT_HEIGHT, canvasLineChart.height - y))
        }
        if (croppedImgDataLegend) {
          drawingContext.putImageData(croppedImgDataLegend, 0, TIMELINE_HEIGHT + LINE_CODE_HEIGHT + Math.min(CONTENT_HEIGHT, canvasLineChart.height - y) + CBP_HEIGHT)
        }
        drawingContext.putImageData(croppedImgDataComments, 0, TIMELINE_HEIGHT + LINE_CODE_HEIGHT + Math.min(CONTENT_HEIGHT, canvasLineChart.height - y) + LEGEND_HEIGHT + CBP_HEIGHT)
        pdf.addImage(drawingCanvas, 'JPEG', pageMargin, pageMargin, pageWidth, pageHeight, '', 'FAST')
        pdf.text(`${currentPage}/${nbPages}`, pageWidth, pageHeight + pageMargin)
        pdf.text(activeFile.name, pdf.internal.pageSize.getWidth() / 2, pageMargin, { align: 'center' })
        pdf.addImage(LogoSncf, 'PNG', pageMargin, pageMargin, 23, 16, '', 'FAST')
        y += CONTENT_HEIGHT
      }
      y = 0
      x += CONTENT_WIDTH
    }
    setiframe(pdf.output('bloburi'))
    setLoadPDF(false)
  }

  const toolbarPluginInstance = toolbarPlugin({
    getFilePlugin: {
      fileNameGenerator: () => `${activeFile.name}`,
    },
  })

  const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance

  const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
    ...slot,
    EnterFullScreen: () => <></>,
    SwitchTheme: () => <></>,
    Print: () => <></>,
    Open: () => <></>,
  })

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: defaultTabs => [
      defaultTabs[0], // Thumbnail tab
    ],
    renderToolbar: () => (
      <div
        style={{
          alignItems: 'center',
          backgroundColor: '#eeeeee',
          borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
          display: 'flex',
          padding: '0.25rem',
          width: '100%',
        }}
      >
        <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
      </div>
    ),
  })

  useEffect(() => {
    if (loadPDF) {
      exportPdf()
    }
  }, [loadPDF])

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
      <div className="previsualization-container">
        {loadPDF && <Loader />}
        <ActionButton
          text={terms.MultiYearPlan.ExportPdf.buttons.previz}
          onClick={() => setLoadPDF(true)}
          buttonStyle="previsualization-btn"
        />
        {iframe && (
        <div
          className="rpv-core__viewer"
          style={{
            border: '1px solid rgba(0, 0, 0, 0.3)',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
          }}
        >
          <div
            style={{
              flex: 1,
              overflow: 'hidden',
            }}
          >
            <Viewer fileUrl={iframe} plugins={[defaultLayoutPluginInstance, toolbarPluginInstance]} localization={fr_FR as unknown as LocalizationMap} />
          </div>
        </div>
        )}

      </div>
    </Worker>
  )
}
