import { ReactElement, useEffect, useState } from 'react'
import { Source, Layer } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { LabelsLayersIds } from '../types'

export default function ActionZoneLayer(): ReactElement {
  const { labelLayerData, enterEditingMode, labelToUpdate } = useSelector((state: RootState) => state.label)
  const [actionZones, setActionZones] = useState<any>(null)

  useEffect(() => {
    if (labelLayerData) {
      const featuresList: Array<GeoJSON.Feature> = []
      labelLayerData.features.forEach((feature: any) => {
        const coordinatesList: GeoJSON.Position[][][] = []
        feature.geometry?.coordinates?.map((coordinate: any, index: number) => {
          if (index !== 0) {
            coordinatesList.push(coordinate)
          }
          return coordinatesList
        })
        const newFeature = {
          ...feature,
          geometry: feature.geometry && feature.geometry.type !== null ? {
            type: feature.geometry.type,
            coordinates: coordinatesList,
          } : null,
        }
        featuresList.push(newFeature)
      })
      const labelGeojson: GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties> = {
        type: 'FeatureCollection',
        features: featuresList,
      }
      setActionZones(labelGeojson)
    }
  }, [labelLayerData])

  return (
    <>
      <Source
        type="geojson"
        data={actionZones}
        id={LabelsLayersIds.actionZones}
      >
        <Layer
          id={LabelsLayersIds.actionZones}
          type="fill"
          paint={{
            'fill-color': ['case',
              ['==', ['get', 'color'], 'null'], '#FFFFFF',
              ['get', 'color'],
            ],
            'fill-opacity': 0.5,
          }}
          layout={{ visibility: 'visible' }}
          filter={enterEditingMode && labelToUpdate.layer.id === LabelsLayersIds.actionZones
            ? ['all', ['!=', 'object_id', labelToUpdate.properties.object_id],
              ['==', 'displayed', true]] : ['==', 'displayed', true]}
        />
      </Source>
    </>
  )
}
