export const getOpenPanelsStyle = (displaySettingsPanel: boolean, displayLibraryPanel: boolean) => {
  if (displaySettingsPanel && !displayLibraryPanel) {
    return 'open-settings-panel'
  }
  if (displayLibraryPanel) {
    return 'open-library-panel'
  }
  return ''
}

export const getChartPrOpenPanelsStyle = (displaySettingsPanel: boolean, displayLibraryPanel: boolean) => {
  if (displaySettingsPanel && !displayLibraryPanel) {
    return 'line-chart-pr-open-settings-panel'
  }
  if (displayLibraryPanel) {
    return 'line-chart-pr-open-library-panel'
  }
  return ''
}

export const getCodeLineOpenPanelStyle = (displaySettingsPanel: boolean, displayLibraryPanel: boolean) => {
  if (displaySettingsPanel && !displayLibraryPanel) {
    return 'code-line-chart-open-settings-panel'
  }
  if (displayLibraryPanel) {
    return 'code-line-chart-open-library-panel'
  }
  return ''
}
