import { store } from 'Store'
import onSelectObjects from './onSelectObjects'
import CustomSelectionLayer from './CustomSelectionLayer'
import { GeoEditorState } from '../reducer'
import { SELECTION_TYPE, SelectParams } from './utils'
import { Modes } from '../utils'

const createSelectionLayer = (): CustomSelectionLayer => {
  const { layersVisibility, geoJson, mode } = store.getState().geoEditor as GeoEditorState

  return new CustomSelectionLayer({
    id: 'selection',
    selectionType: mode === Modes.selectPolygon ? SELECTION_TYPE.POLYGON : SELECTION_TYPE.RECTANGLE,
    onSelect: ({ coordinates }: SelectParams) => {
      if (layersVisibility.selectionLayer && geoJson) {
        onSelectObjects(geoJson, { coordinates })
      }
    },
    layerIds: ['geojson'],
    visible: layersVisibility.selectionLayer,
  })
}

export default createSelectionLayer
