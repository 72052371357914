import { ReactElement } from 'react'
import './container.scss'

type Props = {
    fillContent?: boolean
    extraStyle?: string
    title?: string
    notice?: string | ReactElement
    content?: ReactElement
    contentInputStyle?: string
}

const defaultProps = {
  fillContent: false,
  extraStyle: '',
  title: '',
  notice: '',
  content: <></>,
  contentInputStyle: '',
}

export default function ContentContainer({
  fillContent, extraStyle, title, notice, content, contentInputStyle,
}: Props): ReactElement {
  return (
    <div
      className={`content-container${fillContent ? ' full-content-container' : ''} ${extraStyle}`}
    >
      <div className="content-container-text">
        <div className="text-style">{title}</div>
        <div className="notice-style">{notice}</div>
      </div>
      <div className={`content-container-input ${contentInputStyle}`}>
        {content}
      </div>
    </div>
  )
}

ContentContainer.defaultProps = defaultProps
