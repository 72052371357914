import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import ObjectService from 'components/Objects/ObjectService'
import { ResponseError } from 'types'
import { ObjectInfraCategory } from 'components/Synoptic/types'
import { Filters } from 'components/Map/ToolBar/types'

interface ObjectState {
  showObjectPanel: boolean
  showObjectCreationPanel: boolean
  ObjectPositionMode: boolean
  ObjectOrientationMode: boolean
  ObjectDeletionMode: boolean
  showGeoeditor: boolean
  responseError?: ResponseError
  objectsInfraList: Array<ObjectInfraCategory>
  signalLayerData: any
  advLayerData: any
  rkLayerData: any
  bvLayerData: any
  peLayerData: any
  teLayerData: any
  vmaxLayerData: any
  gvLayerData: any
  gfLayerData: any
  panLayerData: any
  uicLayerData: any
  secteurLayerData: any
  ssLayerData: any
  cssLayerData: any
  posteLayerData: any
  regionSncfLayerData: any
  regionAdmLayerData: any
  tunnelLayerData: any
  mcLayerData: any
  createdObject?: any
  geoeditorUrl: string
  deletedObject?: number
  objectlngLat: any
  newObjectParam: any
  filtersList: Filters[]
  PatchObject: any
  LayerStatus: any
  modifiedObjectFeature: any
  loadingLayers: boolean
}

const initialState: ObjectState = {
  showObjectPanel: false,
  showObjectCreationPanel: false,
  ObjectPositionMode: false,
  ObjectOrientationMode: false,
  ObjectDeletionMode: false,
  showGeoeditor: false,
  objectsInfraList: [],
  signalLayerData: null,
  advLayerData: null,
  rkLayerData: null,
  peLayerData: null,
  bvLayerData: null,
  teLayerData: null,
  vmaxLayerData: null,
  gvLayerData: null,
  gfLayerData: null,
  mcLayerData: null,
  panLayerData: null,
  uicLayerData: null,
  secteurLayerData: null,
  ssLayerData: null,
  cssLayerData: null,
  posteLayerData: null,
  regionSncfLayerData: null,
  regionAdmLayerData: null,
  tunnelLayerData: null,
  geoeditorUrl: '',
  objectlngLat: null,
  newObjectParam: null,
  filtersList: [],
  PatchObject: null,
  LayerStatus: undefined,
  modifiedObjectFeature: null,
  loadingLayers: false,
}

const CreateObject = (state: ObjectState, action: PayloadAction<any | undefined>) => {
  state.createdObject = action.payload
  state.responseError = undefined
}

const removeObject = (state: ObjectState, action: PayloadAction<number>) => {
  state.deletedObject = action.payload
}

export const objectSlice = createSlice({
  name: 'objects',
  initialState,
  reducers: {
    toggleShowObjectPanel: (state, action: PayloadAction<boolean>) => {
      state.showObjectPanel = action.payload
    },
    toggleShowObjectCreationPanel: (state, action: PayloadAction<boolean>) => {
      state.showObjectCreationPanel = action.payload
    },
    toggleObjectPositionMode: (state, action: PayloadAction<boolean>) => {
      state.ObjectPositionMode = action.payload
    },
    toggleObjectOrientationMode: (state, action: PayloadAction<boolean>) => {
      state.ObjectOrientationMode = action.payload
    },
    toggleObjectDeletionMode: (state, action: PayloadAction<boolean>) => {
      state.ObjectDeletionMode = action.payload
    },
    toggleShowGeoeditor: (state, action: PayloadAction<boolean>) => {
      state.showGeoeditor = action.payload
    },
    setGeoeditorUrl: (state, action: PayloadAction<string>) => {
      state.geoeditorUrl = action.payload
    },
    setNewSignalObjectLayer: (state, action: PayloadAction<any>) => {
      state.signalLayerData = action.payload
    },
    setNewRkObjectLayer: (state, action: PayloadAction<any>) => {
      state.rkLayerData = action.payload
    },
    setNewPeObjectLayer: (state, action: PayloadAction<any>) => {
      state.peLayerData = action.payload
    },
    setNewBvObjectLayer: (state, action: PayloadAction<any>) => {
      state.bvLayerData = action.payload
    },
    setNewTeObjectLayer: (state, action: PayloadAction<any>) => {
      state.teLayerData = action.payload
    },
    setNewAdvObjectLayer: (state, action: PayloadAction<any>) => {
      state.advLayerData = action.payload
    },
    setobjectlngLat: (state, action: PayloadAction<any>) => {
      state.objectlngLat = action.payload
    },
    setNewObjectParams: (state, action: PayloadAction<any>) => {
      state.newObjectParam = action.payload
    },
    setObjectModifiedFeature: (state, action: PayloadAction<any>) => {
      state.modifiedObjectFeature = action.payload
    },
    updateStatusLayers: (state, action: PayloadAction<any>) => {
      state.LayerStatus = action.payload
    },
    toggleLoadingLayers: (state, action: PayloadAction<boolean>) => {
      state.loadingLayers = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(ObjectService.createObject.fulfilled, CreateObject)
    builder.addCase(ObjectService.getObjectsList.fulfilled, (state, action) => {
      state.objectsInfraList = action.payload
    })
    builder.addCase(ObjectService.getSignalsLayer.fulfilled, (state, action) => {
      state.signalLayerData = action.payload
    })
    builder.addCase(ObjectService.GetBvLayer.fulfilled, (state, action) => {
      state.bvLayerData = action.payload
    })
    builder.addCase(ObjectService.GetVmaxLayer.fulfilled, (state, action) => {
      state.vmaxLayerData = action.payload
    })
    builder.addCase(ObjectService.GetGvLayer.fulfilled, (state, action) => {
      state.gvLayerData = action.payload
    })
    builder.addCase(ObjectService.GetGfLayer.fulfilled, (state, action) => {
      state.gfLayerData = action.payload
    })
    builder.addCase(ObjectService.GetPanLayer.fulfilled, (state, action) => {
      state.panLayerData = action.payload
    })
    builder.addCase(ObjectService.GetUicLayer.fulfilled, (state, action) => {
      state.uicLayerData = action.payload
    })
    builder.addCase(ObjectService.GetSecteurLayer.fulfilled, (state, action) => {
      state.secteurLayerData = action.payload
    })
    builder.addCase(ObjectService.GetSsLayer.fulfilled, (state, action) => {
      state.ssLayerData = action.payload
    })
    builder.addCase(ObjectService.GetCssLayer.fulfilled, (state, action) => {
      state.cssLayerData = action.payload
    })
    builder.addCase(ObjectService.GetRegionSncfLayer.fulfilled, (state, action) => {
      state.regionSncfLayerData = action.payload
    })
    builder.addCase(ObjectService.GetRegionAdmLayer.fulfilled, (state, action) => {
      state.regionAdmLayerData = action.payload
    })
    builder.addCase(ObjectService.GetPosteLayer.fulfilled, (state, action) => {
      state.posteLayerData = action.payload
    })

    builder.addCase(ObjectService.GetTunnelLayer.fulfilled, (state, action) => {
      state.tunnelLayerData = action.payload
    })
    builder.addCase(ObjectService.GetMcLayer.fulfilled, (state, action) => {
      state.mcLayerData = action.payload
    })

    builder.addCase(ObjectService.GetAdvLayer.fulfilled, (state, action) => {
      state.advLayerData = action.payload
    })
    builder.addCase(ObjectService.GetRkLayer.fulfilled, (state, action) => {
      state.rkLayerData = action.payload
    })
    builder.addCase(ObjectService.GetPeLayer.fulfilled, (state, action) => {
      state.peLayerData = action.payload
    })
    builder.addCase(ObjectService.GetTeLayer.fulfilled, (state, action) => {
      state.teLayerData = action.payload
    })
    builder.addCase(ObjectService.getFilterList.fulfilled, (state, action) => {
      state.filtersList = action.payload
    })
    builder.addCase(ObjectService.patchFilterList.fulfilled, (state, action) => {
      state.filtersList = action.payload
    })
    builder.addCase(ObjectService.patchObject.fulfilled, (state, action) => {
      state.PatchObject = action.payload
    })
    builder.addCase(ObjectService.getLayersStatus.fulfilled, (state, action) => {
      state.LayerStatus = action.payload
    })
    builder.addCase(ObjectService.deleteObject.fulfilled, removeObject)
    builder.addCase(ObjectService.deleteObject.rejected, (state, action) => {
      state.responseError = action.payload
    })
    builder.addMatcher(isAnyOf(
      ObjectService.getSignalsLayer.fulfilled,
      ObjectService.GetRkLayer.fulfilled,
      ObjectService.GetAdvLayer.fulfilled,
      ObjectService.GetBvLayer.fulfilled,
      ObjectService.GetTeLayer.fulfilled,
      ObjectService.GetVmaxLayer.fulfilled,
      ObjectService.GetGvLayer.fulfilled,
      ObjectService.GetGfLayer.fulfilled,
      ObjectService.GetPanLayer.fulfilled,
      ObjectService.GetUicLayer.fulfilled,
      ObjectService.GetSecteurLayer.fulfilled,
      ObjectService.GetSsLayer.fulfilled,
      ObjectService.GetCssLayer.fulfilled,
      ObjectService.GetPeLayer.fulfilled,
      ObjectService.GetRegionSncfLayer.fulfilled,
      ObjectService.GetRegionAdmLayer.fulfilled,
      ObjectService.GetTunnelLayer.fulfilled,
      ObjectService.GetMcLayer.fulfilled,
      ObjectService.GetPosteLayer.fulfilled,
    ), state => {
      state.ObjectOrientationMode = false
      state.ObjectPositionMode = false
      state.modifiedObjectFeature = null
    })
  },
})

export const {
  toggleShowObjectPanel, toggleShowGeoeditor, toggleShowObjectCreationPanel,
  toggleObjectPositionMode, toggleObjectOrientationMode, setObjectModifiedFeature,
  setGeoeditorUrl, setNewSignalObjectLayer, setobjectlngLat, updateStatusLayers,
  setNewObjectParams, setNewRkObjectLayer, setNewAdvObjectLayer, setNewPeObjectLayer, setNewTeObjectLayer,
  setNewBvObjectLayer, toggleLoadingLayers, toggleObjectDeletionMode,
} = objectSlice.actions

export default objectSlice.reducer
