/* eslint-disable import/prefer-default-export */
import terms from 'common/terms'
import { ResponseError } from 'types'

export const getErrorMessage = (responseError: ResponseError | undefined, field: string, emptyFields: boolean,
  value: string) => {
  if (responseError && responseError.message[field]) {
    if (emptyFields && value === '') {
      return terms.Common.Errors.requiredField
    }
    return responseError.message[field]
  }
  if (emptyFields && value === '') {
    return terms.Common.Errors.requiredField
  }
  return ''
}
