/* eslint-disable no-prototype-builtins */
import {
  ReactElement, useEffect, useRef, ChangeEvent,
} from 'react'
import { Paper } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import {
  getFolderFileCodeLines, getRepertoryFileCodeLines, getFolderPr, getRepertoryPr,
} from 'reducers/RemarkablePoints/remarkablePoints.thunk'
import { getOperations } from 'reducers/MultiYearPlan/Operations/operations.thunk'
import { getFilePhases, getFileYears } from 'reducers/Files/files.thunk'
import { getTitleBlock } from 'reducers/MultiYearPlan/TitleBlock/titleblock.thunk'
import { getAppliedFiltersList } from 'reducers/MultiYearPlan/Settings/settings.thunk'
import { getTbElementTypes } from 'reducers/MultiYearPlan/TitleBlockActions/titleblockactions.thunk'
import ImportPortfolioButton from './ImportPortfolioButton'
import LineChartPr from './PrLineChart/LineChartPr'
import LineChartSp from './SpLineChart/LineChartSp'
import Legends from './Legends/Legends'
import LineChartClassifications from './SpLineChart/LineChartClassifications'
import LineChartTbCategories from './TbLineChart/LineChartTbCategories'
import LineChartTbObjects from './TbLineChart/LineChartTbObjects'
import LineCodesChart from './LineCodesChart/LineCodesChart'
import MypSettingsButton from './Settings/MypSettingsButton'
import ToolsButtons from './ToolsButtons'
import Comments from './Comments/Comments'
import './multiYearPlan.scss'
import MypSettingsPanel from './Settings/MypSettingsPanel'
import AddNewObjectButton from './OperationsActions/Buttons/AddNewObjectButton'
import LibraryPanel from './Settings/Library/LibraryPanel'
import ExportPdfModal from './ExportPdf/ExportPdfModal'
import { getOpenPanelsStyle } from './Settings/utils'

export default function MultiYearPlanContainer(): ReactElement {
  const dispatch = useDispatch()
  const { activeFile } = useSelector((state: RootState) => state.files)
  const { updateChart } = useSelector((state: RootState) => state.remarkablePoints)
  const {
    displaySettingsPanel, addedFilter, deletedFilter,
  } = useSelector((state: RootState) => state.mypSettings)
  const { updateOperationsChart, Operations } = useSelector((state: RootState) => state.mypoperations)
  const { TitleBlock, updateTbChart } = useSelector((state: RootState) => state.mypTitleBlock)
  const { Legend } = useSelector((state: RootState) => state.myplegend)
  const { displayLibraryPanel } = useSelector((state: RootState) => state.mypLibrary)
  const { displayExportPdfModal, exportSettings } = useSelector((state: RootState) => state.mypExportPdf)

  const SPChartRef = useRef<HTMLInputElement>(null)
  const PRChartRef2 = useRef<HTMLInputElement>(null)
  const LineCChartRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (activeFile) {
      if (activeFile.folder !== null) {
        dispatch(getFolderFileCodeLines({
          repertoryId: activeFile.directory,
          folderId: activeFile.folder,
          fileId: activeFile.id,
        }))
      } else {
        dispatch(getRepertoryFileCodeLines({ repertoryId: activeFile.directory, fileId: activeFile.id }))
      }
      dispatch(getFileYears({
        repertoryId: activeFile.directory,
        folderId: activeFile.folder,
        fileId: activeFile.id,
      }))
      dispatch(getFilePhases({
        repertoryId: activeFile.directory,
        folderId: activeFile.folder,
        fileId: activeFile.id,
      }))
      dispatch(getTbElementTypes())
    }
  }, [activeFile])
  useEffect(() => {
    if (activeFile) {
      if (activeFile.folder !== null) {
        dispatch(getFolderPr({
          repertoryId: activeFile.directory,
          folderId: activeFile.folder,
          fileId: activeFile.id,
        }))
      } else {
        dispatch(getRepertoryPr({ repertoryId: activeFile.directory, fileId: activeFile.id }))
      }
    }
  }, [activeFile, updateChart])

  useEffect(() => {
    dispatch(getTitleBlock({
      repertoryId: activeFile.directory,
      folderId: activeFile.folder,
      fileId: activeFile.id,
    }))
  }, [updateTbChart])

  useEffect(() => {
    dispatch(getOperations({
      repertoryId: activeFile.directory,
      folderId: activeFile.folder,
      fileId: activeFile.id,
    }))
  }, [updateOperationsChart, addedFilter, deletedFilter])

  useEffect(() => {
    dispatch(getAppliedFiltersList({
      repertoryId: activeFile.directory,
      folderId: activeFile.folder,
      fileId: activeFile.id,
    }))
  }, [addedFilter, deletedFilter])

  const slide = (e: ChangeEvent<{ value: number }>) => {
    if (e.target !== null) {
      if (PRChartRef2.current !== null) {
        PRChartRef2.current.scrollTo(e.target.value, 0)
      }
      if (SPChartRef.current !== null && LineCChartRef.current !== null) {
        SPChartRef.current.scrollTo(e.target.value, 0)
        LineCChartRef.current.scrollTo(e.target.value, 0)
      }
    }
  }

  return (
    <div id="mypcontainer">
      <div style={{ paddingBottom: Legend?.maturities?.length === 1 ? 90 : 120, background: 'white' }}>
        <Paper
          className="myp-container"
        >
          {displaySettingsPanel && <MypSettingsPanel />}
          {displayLibraryPanel && <LibraryPanel />}
          <div className={getOpenPanelsStyle(displaySettingsPanel, displayLibraryPanel)}>
            <div className="myp-btn-container" data-html2canvas-ignore>
              <ImportPortfolioButton />
              {Object.keys(Operations).length !== 0
            && Operations.years[0].hasOwnProperty('classifications') && <AddNewObjectButton />}
              <MypSettingsButton />
              <ExportPdfModal />
            </div>
            <div>
              {Object.keys(Operations).length !== 0
            && Operations.years[0].hasOwnProperty('classifications') && <LineChartClassifications />}
              {Object.keys(Operations).length !== 0 && Operations.years[0].hasOwnProperty('classifications')
            && TitleBlock.hasOwnProperty('cartouche_display') && <LineChartTbCategories />}
            </div>
          </div>
          <div className="line-charts-container" ref={SPChartRef}>
            <LineChartPr PRChartRef2={PRChartRef2} />
            {Object.keys(Operations).length !== 0
          && Operations.years[0].hasOwnProperty('classifications') && <LineCodesChart LineCChartRef={LineCChartRef} />}
            {Object.keys(Operations).length !== 0
          && Operations.years[0].hasOwnProperty('classifications') && <LineChartSp />}
            {Object.keys(Operations).length !== 0 && Operations.years[0].hasOwnProperty('classifications')
          && TitleBlock.hasOwnProperty('cartouche_display') && <LineChartTbObjects />}
          </div>
          <ToolsButtons
            handleChange={(e: ChangeEvent<{ value: number} >) => slide(e)}
          />

        </Paper>
        {Object.keys(Operations).length !== 0 && Operations.years[0].hasOwnProperty('classifications') && <Comments />}
      </div>
      {Object.keys(Operations).length !== 0 && (
      <div
        className={`${getOpenPanelsStyle(displaySettingsPanel, displayLibraryPanel)}
         ${displayExportPdfModal ? ' legend-wrapper-exportpdf' : ' legend-wrapper'}`}
        id="legend-wrapper"
        style={exportSettings.includeLegend ? { display: 'block' } : { display: 'none' }}
      >
        <Legends />
      </div>
      )}
    </div>
  )
}
