/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */
import {
  deleteRequest, get, patch, post,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkApi } from 'types'
import {
  deleteParams, Folder, GetParams, PatchParams, PostParams,
} from './types'

const getFoldersList = createAsyncThunk<Folder[], GetParams, ThunkApi>(
  'folders/get',
  async (params: GetParams, thunkApi) => {
    try {
      const response: Folder[] = await get(`/module_schematique/directories/${params.repertoryId}/folders/`,
        { ordering: params.ordering, search: params.search })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const postFolder = createAsyncThunk<Folder, PostParams, ThunkApi>(
  'folders/post',
  async (params: PostParams, thunkApi) => {
    try {
      const response = await
      post(`/module_schematique/directories/${params.repertoryId}/folders/`, { label: params.folderLabel })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const patchFolder = createAsyncThunk<Folder, PatchParams, ThunkApi>(
  'folders/patch',
  async (params: PatchParams, thunkApi) => {
    try {
      const response = await patch(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}`,
        { label: params.folderLabel })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const deleteFolder = createAsyncThunk<number, deleteParams, ThunkApi>(
  'folders/delete',
  async (params: deleteParams, thunkApi) => {
    try {
      await
      deleteRequest(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}`)
      return params.folderId
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

export {
  getFoldersList,
  postFolder,
  patchFolder,
  deleteFolder,
}
