import { ReactElement, useEffect, useState } from 'react'
import { Layer, Source } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import _ from 'lodash'
import { LabelsLayersIds } from '../types'

export default function OutlineLayer(): ReactElement {
  const { clickedFeatureInfo, hoveredFeatureInfo } = useSelector((state: RootState) => state.map)
  const { libraryLabel } = useSelector((state: RootState) => state.library)
  const {
    labelLayerData, labelToUpdate, enterEditingMode,
  } = useSelector((state: RootState) => state.label)
  const [outlineLayer, setOutlineLayer] = useState<any>(null)

  useEffect(() => {
    if (labelLayerData) {
      const featuresList: Array<GeoJSON.Feature> = []
      const array = _.cloneDeep(labelLayerData)
      array.features.forEach((feature: any) => {
        const newFeature = {
          ...feature,
          geometry: feature.geometry && feature.geometry.type !== null ? {
            coordinates:
            feature.geometry && feature.geometry.type !== null ? feature.geometry.coordinates.splice(1) : null,
            type: feature.geometry && feature.geometry.type !== null ? feature.geometry.type : null,
          } : null,
        }
        featuresList.push(newFeature)
      })
      const labelGeojson: GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties> = {
        type: 'FeatureCollection',
        features: featuresList,
      }
      setOutlineLayer(labelGeojson)
    }
  }, [labelLayerData])

  const featureInfo = clickedFeatureInfo || hoveredFeatureInfo || libraryLabel || labelToUpdate
  const getObjectId = () => {
    if (featureInfo) {
      if (featureInfo.id) return featureInfo.id
      return featureInfo.properties.object_id
    }
    return 0
  }

  return (
    <Source
      type="geojson"
      data={outlineLayer}
      id={LabelsLayersIds.outline}
    >
      <Layer
        id={LabelsLayersIds.outline}
        type="line"
        paint={{
          'line-color': '#FFFF38',
          'line-width': 3,
        }}
        layout={{ visibility: 'visible' }}
        filter={enterEditingMode ? ['literal', false] : ['all', ['==', 'object_id', getObjectId()],
          ['==', 'displayed', true]]}
      />
    </Source>
  )
}
