import PathNames from 'common/PathNames'
import terms from 'common/terms'
import { TASKS_NAMES } from 'components/Dashboard/Creation/types'
import {
  CreationLevels, Project, Section, Task,
} from 'components/Dashboard/types'
import history from 'customHistory'
import {
  setUpdatedSection, toggleValidationNotice, updateActiveProject, updateCreationLevel, updateEmptyFields,
  updateSectionsList, resetResponseError as resetSynopResponseError,
} from 'reducers/creation'
import { updateActiveTab } from 'reducers/dashboard'
import { resetActiveFile, resetResponseError, updateCreatedFile } from 'reducers/Files/files'
import { Files, FileTypes } from 'reducers/Files/types'
import { Folder } from 'reducers/Folders/types'
import {
  updateCreationStatus, updateDeclarationMode, toggleBackToDeclaration, resetState, toggleDeletedSection,
  resetAllFilesList,
} from 'reducers/MultiYearPlan/multiYearPlan'
import { MYPSection } from 'reducers/MultiYearPlan/types'
import { Repertory } from 'reducers/Repertories/types'
import { store } from 'Store'
import { ResponseError } from 'types'
import { getPath } from '../../../../utils'

export const handleRollBack = (creationLevel: number, fileType: string, declarationMode: string,
  sectionsList: MYPSection[], backToDeclaration: boolean, deletedSection: boolean) => {
  if (creationLevel === CreationLevels.Geographic) {
    if (fileType === FileTypes.Synoptic) {
      store.dispatch(updateCreationLevel(CreationLevels.Temporal))
    } else {
      store.dispatch(updateCreationStatus(CreationLevels.Temporal))
    }
  } else {
    if (fileType === FileTypes.Synoptic) {
      history.push(window.location.pathname.replace(PathNames.GeographicPerimeter, PathNames.TemporalPerimeter))
      store.dispatch(updateCreationLevel(CreationLevels.Name))
    } else if (declarationMode !== '' || (sectionsList.length !== 0 && !backToDeclaration)
     || (sectionsList.length === 0 && deletedSection)) {
      store.dispatch(updateDeclarationMode(''))
      store.dispatch(toggleBackToDeclaration(true))
      store.dispatch(toggleDeletedSection())
      store.dispatch(resetAllFilesList())
    } else if (declarationMode === '' && !deletedSection) {
      history.push(window.location.pathname.replace(PathNames.GeographicPerimeter, PathNames.TemporalPerimeter))
      store.dispatch(updateCreationStatus(CreationLevels.Name))
    }
    store.dispatch(updateCreatedFile())
    store.dispatch(resetResponseError())
  }
}

export const getRollBackText = (creationLevel: number, sectionsList: MYPSection[], backToDeclaration: boolean,
  deletedSection: boolean, declarationMode: string) => {
  if (creationLevel === CreationLevels.Geographic) return terms.File.Declaration.backToGeographicPerimeter
  if ((sectionsList.length !== 0 && !backToDeclaration)
   || (sectionsList.length === 0 && deletedSection) || declarationMode !== '') {
    return terms.File.Declaration.backToDeclarationModeChoice
  }
  return terms.File.Declaration.backToTemporelPerimeter
}

export const handleClose = (activeFolder: Folder, activeRepertory: Repertory, activeFile: Files) => {
  history.push(getPath(activeRepertory, activeFolder, activeFile))
  store.dispatch(resetActiveFile())
  store.dispatch(updateActiveTab({ tabName: 'home', tabId: undefined }))
  if (activeFile.type === FileTypes.Synoptic) {
    store.dispatch(updateCreationLevel(undefined))
    store.dispatch(updateActiveProject(undefined))
    store.dispatch(updateEmptyFields(false))
    store.dispatch(toggleValidationNotice(false))
    store.dispatch(setUpdatedSection(undefined))
    store.dispatch(updateSectionsList([]))
    store.dispatch(resetSynopResponseError(undefined))
  } else {
    store.dispatch(resetState())
  }
}

export const getTitle = (creationLevel: number, displayUpdateGeographicLoader: boolean, isLoading: boolean): string => {
  if (creationLevel === CreationLevels.Geographic || displayUpdateGeographicLoader || isLoading) return ''
  return terms.File.Declaration.geographicPerimeter
}

export const getSubtitle = (fileType: string): string => (fileType === FileTypes.Schema
  ? terms.File.Declaration.Schema.geographicPerimeterNotice
  : terms.File.Declaration.Synoptic.geographicPerimeterNotice)

export const getSectionStatus = (section: Section, activeProject: Project) => activeProject?.sections?.filter((sec:
   Section) => sec.id === section.id)[0]?.tasks?.filter((task: Task) => task.name === TASKS_NAMES.Validate)[0]?.result
    || ''

export const getSnackbarMessage = (responseError: ResponseError) => {
  if (responseError) {
    if (responseError.message.error) return responseError.message.error
    if (responseError.message.non_field_errors) return responseError.message.non_field_errors
  }
  return terms.Common.Errors.importError
}

export const reorder = (list: MYPSection[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}
