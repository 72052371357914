import { ReactElement } from 'react'
import terms from 'common/terms'
import ActionButton from 'components/common/Buttons/ActionButton'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { updateObjectsFamilies } from 'reducers/MultiYearPlan/Import/ObjectsFamilies/objectsFamilies'
import { ObjectFamily } from 'reducers/MultiYearPlan/Import/ObjectsFamilies/type'

export default function AddObjectFamilyButton(): ReactElement {
  const dispatch = useDispatch()
  const { objectsFamilies } = useSelector((state: RootState) => state.objectsFamilies)

  const handleClick = () => {
    if (objectsFamilies.filter((family: ObjectFamily) => family.label === '').length === 0) {
      dispatch(updateObjectsFamilies(objectsFamilies.concat([{
        label: '',
        tracks: [],
        operations: [],
        color: '',
      }])))
    }
  }

  return (
    <div className="add-object-family-btn-container">
      <ActionButton
        text={terms.MultiYearPlan.Import.addObjectFamilyButton}
        onClick={handleClick}
        leftIcon
        leftIconStyle="mx-2"
        buttonStyle="add-object-family-btn"
      />
    </div>
  )
}
