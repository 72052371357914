import { ReactElement } from 'react'
import ActionButton from 'components/common/Buttons/ActionButton'
import terms from 'common/terms'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { postOperation } from 'reducers/MultiYearPlan/OperationActions/operationactions.thunk'
import { toggleEmptyFields } from 'reducers/MultiYearPlan/OperationActions/operationactions'
import { fixDefectedOperation } from 'reducers/MultiYearPlan/Library/library.thunk'
import { getFerrolocList, getPhasesList, hasEmptyFields } from '../OperationFields/utils'

export default function ValidationButton(): ReactElement {
  const dispatch = useDispatch()
  const {
    operationLabel, subClassificationId, phaseFields, ferrolocFields, selectedOperation,
  } = useSelector((state: RootState) => state.mypoperationactions)
  const { activeFile } = useSelector((state: RootState) => state.files)
  const { selectedDefectedOperation } = useSelector((state: RootState) => state.mypLibrary)

  const handleClick = () => {
    if (hasEmptyFields(operationLabel, subClassificationId, getPhasesList(phaseFields),
      getFerrolocList(ferrolocFields))) {
      dispatch(toggleEmptyFields(true))
    } else if (selectedDefectedOperation) {
      dispatch(fixDefectedOperation({
        repertoryId: activeFile.directory,
        folderId: activeFile.folder,
        fileId: activeFile.id,
        operation: {
          id: selectedDefectedOperation?.id,
          label: operationLabel,
          sub_classification: subClassificationId,
          years: getPhasesList(phaseFields),
          railway_locations: getFerrolocList(ferrolocFields),
        },
      }))
    } else {
      const params = {
        repertoryId: activeFile.directory,
        folderId: activeFile.folder,
        fileId: activeFile.id,
        operation: {
          label: operationLabel,
          sub_classification: subClassificationId,
          years: getPhasesList(phaseFields),
          railway_locations: getFerrolocList(ferrolocFields),
        },
      }
      dispatch(postOperation(params))
    }
  }

  const getButtonText = () => {
    if (selectedOperation) {
      return terms.MultiYearPlan.Actions.duplicateObject
    }
    if (selectedDefectedOperation) {
      return terms.MultiYearPlan.SettingsPanel.Library.placeObject
    }
    return terms.MultiYearPlan.Operations.addOperation
  }
  return (
    <ActionButton
      text={getButtonText()}
      onClick={handleClick}
      buttonStyle="operation-validation-btn"
    />
  )
}
