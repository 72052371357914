import { ReactElement, useEffect, useState } from 'react'
import { Color, ColorResult, SketchPicker } from 'react-color'
import { IoIosClose } from 'react-icons/io'
import { RootState } from 'Store'
import { useDispatch, useSelector } from 'react-redux'
import { setLabelToUpdate, toggleShowColorPanel } from 'reducers/label'
import { updateClickedFeatureInfo, updateHoveredFeatureInfo } from 'reducers/map'
import terms from 'common/terms'
import PortfolioService from '../PortfolioService'
import { ToolsProps } from './types'
import DEFAULT_COLORS from './DefaultColors'

import './popup.scss'

export default function ColorPanel({ closePopup }: ToolsProps): ReactElement {
  const dispatch = useDispatch()
  const { labelToUpdate, updatedLabel, updateMode } = useSelector((state: RootState) => state.label)
  const [labelColor, setLabelColor] = useState<Color>('#FAF6F6')

  useEffect(() => {
    if (labelToUpdate && !updateMode) {
      if (updatedLabel) {
        setLabelColor(updatedLabel.object.properties.color)
      } else {
        setLabelColor(labelToUpdate.properties.color)
      }
    }
  }, [labelToUpdate, updatedLabel])

  const handleClose = () => {
    dispatch(toggleShowColorPanel(false))
    dispatch(updateClickedFeatureInfo(undefined))
    dispatch(setLabelToUpdate(undefined))
    dispatch(updateHoveredFeatureInfo(undefined))
    closePopup(undefined)
  }

  const handleColorChange = (color: ColorResult) => {
    setLabelColor(color.hex)
    const params = {
      projectId: labelToUpdate.properties.project_id,
      labelId: labelToUpdate.properties.object_id,
      params: {
        properties: {
          color: color.hex,
        },
      },
    }
    dispatch(PortfolioService.updateLabel(params))
  }

  return (
    <>
      <div className="color-picker-wrapper">
        <div className="color-picker-header">
          {terms.Portfolio.chooseColor}
          <IoIosClose className="action-cursor-pointer" onClick={handleClose} />
        </div>
        <SketchPicker
          disableAlpha
          className="color-picker"
          color={labelColor}
          onChangeComplete={(c: ColorResult) => handleColorChange(c)}
          presetColors={DEFAULT_COLORS}
        />
      </div>
    </>
  )
}
