import { ReactElement } from 'react'
import { Source, Layer } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { FiltersCategories, ObjectsLayer } from 'components/Map/ObjectsLayers/types'
import { getFilterCheckStatus, getLayerVisibility } from '../utils'

export default function RkLayer(): ReactElement {
  const { rkLayerData, filtersList } = useSelector((state: RootState) => state.object)

  return (
    <>
      <Source type="geojson" data={rkLayerData} id={ObjectsLayer.rk}>
        <Layer
          id={ObjectsLayer.rk}
          filter={['==', 'auto_added', true]}
          type="circle"
          minzoom={12}
          paint={{
            'circle-radius': {
              stops: [
                [15, 3],
                [16, 5],
              ],
            },
            'circle-color': '#000000',
          }}
          layout={{
            visibility: getLayerVisibility(filtersList, FiltersCategories.Infra, ObjectsLayer.rk,
              getFilterCheckStatus(filtersList, FiltersCategories.General, ObjectsLayer.gaia)),
          }}
        />
        <Layer
          id={`label-${ObjectsLayer.rk}`}
          filter={['==', 'auto_added', true]}
          type="symbol"
          minzoom={12}
          paint={{
            'text-color': '#838383',
            'text-halo-width': 2,
            'text-halo-color': 'rgba(255,255,255,0.75)',
            'text-halo-blur': 1,
          }}
          layout={{
            'text-allow-overlap': true,
            'text-field': ['get', 'description'],
            'text-offset': [0, 1.2],
            'text-size': {
              stops: [
                [15, 8],
                [16, 12],
              ],
            },
            'text-font': ['Roboto Condensed'],
            visibility: getLayerVisibility(filtersList, FiltersCategories.Infra, ObjectsLayer.rk,
              getFilterCheckStatus(filtersList, FiltersCategories.General, ObjectsLayer.gaia)),
          }}
        />
      </Source>
    </>
  )
}
