import { ReactElement, useEffect } from 'react'
import { Container, Fade } from '@mui/material'
import history from 'customHistory'
import terms from 'common/terms'
import PathNames from 'common/PathNames'
import ContainerHeader from 'components/common/Container/ContainerHeader'
import ContainerBody from 'components/common/Container/ContainerBody'
import ContainerFooter from 'components/common/Container/ContainerFooter'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { resetActiveRepertory, setError, updateLabel } from 'reducers/Repertories/repertories'
import SnackBar from 'components/common/Snackbar/SnackBar'
import NameDeclarationButton from './NameDeclarationButton'
import RepertoryNameInput from './RepertoryNameInput'

export default function RepertoryNameDeclaration(): ReactElement {
  const dispatch = useDispatch()
  const { activeRepertory, error } = useSelector((state: RootState) => state.repertories)

  useEffect(() => {
    if (activeRepertory) {
      dispatch(updateLabel(activeRepertory.label))
    }
  }, [activeRepertory])

  const backToHome = () => {
    history.push(PathNames.Home)
    dispatch(resetActiveRepertory())
  }

  return (
    <main>
      <Fade timeout={400} in mountOnEnter unmountOnExit>
        <Container maxWidth={false} disableGutters sx={{ height: '100vh' }}>
          <ContainerHeader
            onRollBack={backToHome}
            rollBackText={terms.Common.backToHome}
            onClose={backToHome}
          />
          <ContainerBody
            title={terms.Repertory.declarationTitle}
            subtitle={terms.Common.declarationSubtitle}
            content={<RepertoryNameInput />}
          />
          <ContainerFooter content={<NameDeclarationButton />} />
        </Container>
      </Fade>
      <SnackBar message={terms.Common.Errors.error} error={error} setError={setError} />
    </main>
  )
}
