import { ReactElement } from 'react'
import {
  FormHelperText, InputLabel, MenuItem, Select,
} from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { InputsProps } from './types'
import './input.scss'

export default function GenericSelect({
  label, options, onChange, value, errorMessage, labelColor, disabled,
}: InputsProps): ReactElement {
  return (
    <div className="generic-select">
      <InputLabel shrink sx={{ color: errorMessage !== '' ? '#FF5737' : labelColor }}>{label}</InputLabel>
      <Select
        value={value}
        onChange={onChange}
        disabled={disabled}
      >
        {options?.map(option => {
          if (typeof option === 'string') {
            return (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            )
          }
          return (
            <MenuItem key={option.key} value={option.value}>
              {option.value}
            </MenuItem>
          )
        })}
      </Select>
      <div className="input-error-container">
        {errorMessage !== '' && <ErrorOutlineIcon className="mr-1" />}
        <FormHelperText>{errorMessage}</FormHelperText>
      </div>
    </div>
  )
}

GenericSelect.defaultProps = {
  label: '',
  errorMessage: '',
  labelColor: '#E1E1E1',
}
