import { EditAction, FeatureCollection, ImmutableFeatureCollection } from '@nebula.gl/edit-modes'
import { Feature, LineString } from '@turf/helpers'
import { Position } from 'deck.gl'
import { store } from 'Store'

import { Viewport } from '../snapping/updateSnappingFeatures'
import getNearestFeature from '../snapping/getNearestFeature'
import { GeoEditorState, setGeoJson } from '../reducer'
import { MoveEditContext } from './CustomModifyMode'
import { planeDistance, planeNearestPointOnLine } from './utils'

const ENABLE_SNAP = true
const BASE_SNAPPING_DISTANCE = 30

const getSnappingDistance = (zoom: number | undefined): number => {
  if (!zoom) return BASE_SNAPPING_DISTANCE
  switch (true) {
    case zoom > 14:
      return BASE_SNAPPING_DISTANCE
    case zoom > 10 && zoom < 14:
      return BASE_SNAPPING_DISTANCE * 3
    default:
      return BASE_SNAPPING_DISTANCE * 5
  }
}

const onMove = (editAction: EditAction<FeatureCollection>, viewport: Viewport): void => {
  const { editContext, updatedData } = editAction
  const { featureIndexes, positionIndexes, position } = editContext as MoveEditContext
  const { snappingFeatures } = store.getState().geoEditor as GeoEditorState

  let snappedPosition = position
  if (snappingFeatures && ENABLE_SNAP) {
    const nearestFeature = getNearestFeature(position, snappingFeatures)
    let tempSnappedPosition
    if (nearestFeature.geometry.type === 'Point') {
      tempSnappedPosition = nearestFeature.geometry.coordinates
    } else {
      tempSnappedPosition = planeNearestPointOnLine(
        nearestFeature as Feature<LineString>,
        position,
      ).geometry.coordinates
    }
    const snappingDistance = planeDistance(tempSnappedPosition as Position, position) * 100
    if (snappingDistance < getSnappingDistance(viewport?.zoom)) {
      snappedPosition = tempSnappedPosition as [number, number]
    }
  }
  const newData = new ImmutableFeatureCollection(updatedData)
    .replacePosition(
      featureIndexes[0],
      positionIndexes,
      snappedPosition,
    )
    .getObject()
  store.dispatch(setGeoJson(newData))
}

export default onMove
