import { ReactElement } from 'react'
import terms from 'common/terms'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { resetFolderToDelete } from 'reducers/Folders/folder'

import './folder.scss'
import { deleteFolder } from 'reducers/Folders/folder.thunk'
import DeleteModal from 'components/common/Modals/DeleteModal'

export default function DeleteFolderModal(): ReactElement {
  const dispatch = useDispatch()
  const { displayDeletionModal, editableFolder, loadingFolders } = useSelector((state: RootState) => state.folders)
  const { activeRepertory } = useSelector((state: RootState) => state.repertories)

  const onModalClose = () => {
    dispatch(resetFolderToDelete())
  }

  const onDelete = () => {
    dispatch(deleteFolder({ repertoryId: activeRepertory.id, folderId: editableFolder?.id }))
  }

  return (
    <DeleteModal
      open={displayDeletionModal}
      onModalClose={onModalClose}
      modalTitle={terms.Folder.deleteFolder}
      modalNotice={terms.Folder.deleteModalNotice}
      onDelete={onDelete}
      isLoading={loadingFolders}
    />
  )
}
