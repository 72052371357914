import { ReactElement } from 'react'
import { InputDataType } from './utils'

import './input.scss'
import SelectWithSearch from './SelectWithSearch'
import SimpleSelect from './SimpleSelect'

type Props = InputDataType & {
    setSearchValues: (v: string, index: number| string | undefined) => void,
    getSelectedValue: (v: string | string[], index: number| string | undefined) => string[],
    index?: number | string,
}

const defaultProps = {
  index: undefined,
}
export default function CustomSelect({
  field, value, onChange, setSearchValues, getSelectedValue, index,
}: Props): ReactElement {
  switch (field?.withSearch) {
    case true:
      return (
        <SelectWithSearch
          field={field}
          value={value}
          onChange={onChange}
          setSearchValues={setSearchValues}
          getSelectedValue={getSelectedValue}
          index={index}
        />
      )
    default:
      return <SimpleSelect field={field} value={value} onChange={onChange} />
  }
}

CustomSelect.defaultProps = defaultProps
