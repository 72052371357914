import terms from 'common/terms'
import ActionButton from 'components/common/Buttons/ActionButton'
import { ReactElement } from 'react'
import { AiOutlineBook } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { toggleDisplayLibrary } from 'reducers/library'
import { RootState } from 'Store'

import './libraryBtn.scss'

export default function LibraryButton(): ReactElement {
  const dispatch = useDispatch()
  const { displayLibrary } = useSelector((state: RootState) => state.library)

  const handleClick = () => {
    dispatch(toggleDisplayLibrary(!displayLibrary))
  }

  return (
    <ActionButton
      text={terms.Portfolio.Library.libraryButton}
      topIcon={<AiOutlineBook className="library-btn-icon" />}
      onClick={handleClick}
      buttonStyle="library-btn"
    />
  )
}
