import { ReactElement, useEffect } from 'react'
import GenericInput from 'components/common/Inputs/GenericInput'
import terms from 'common/terms'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { updateOperationLabel } from 'reducers/MultiYearPlan/OperationActions/operationactions'
import { getErrorMessage } from './utils'

export default function OperationLabelInput(): ReactElement {
  const dispatch = useDispatch()
  const {
    operationLabel, emptyFields, responseError, selectedOperation,
  } = useSelector((state: RootState) => state.mypoperationactions)
  const { selectedDefectedOperation, libraryResponseError } = useSelector((state: RootState) => state.mypLibrary)

  useEffect(() => {
    const operation = selectedOperation || selectedDefectedOperation
    if (operation) {
      dispatch(dispatch(updateOperationLabel(operation.label)))
    }
  }, [])

  return (
    <GenericInput
      label={terms.Inputs.operationLabel}
      placeholder={terms.Inputs.operationLabelPlaceholder}
      value={operationLabel}
      onChange={e => dispatch(updateOperationLabel(e.target.value))}
      errorMessage={getErrorMessage((responseError || libraryResponseError), 'label', emptyFields, operationLabel)}
      labelColor="#8A8A8A"
    />
  )
}
