import {
  get, post, deleteRequest, patch,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkApi } from 'types'
import {
  GetParams, PostParams, PR, DeleteParams, PatchParams,
} from './types'

const getRepertoryFileCodeLines = createAsyncThunk(
  'pr/getRepertoryFileCodeLines',
  async (params: GetParams, thunkApi) => {
    try {
      const response: string[] = await
      get(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/line-codes/`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const getFolderFileCodeLines = createAsyncThunk(
  'pr/getFolderFileCodeLines',
  async (params: GetParams, thunkApi) => {
    try {
      const response: string[] = await
      // eslint-disable-next-line max-len
      get(`/module_schematique/directories/${params.repertoryId}/folders/${params?.folderId}/projects/${params.fileId}/line-codes/`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const postRepertoryPr = createAsyncThunk<PR, PostParams, ThunkApi>(
  'pr/postRepertoryPr',
  async (params: PostParams, thunkApi) => {
    try {
      const response: PR = await
      // eslint-disable-next-line max-len
      post(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/remarkable-points/`,
        params.pr.pr)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
        index: params.pr.index,
      })
    }
  },
)

const postFolderPr = createAsyncThunk<PR, PostParams, ThunkApi>(
  'pr/postFolderPr',
  async (params: PostParams, thunkApi) => {
    try {
      const response: PR = await
      // eslint-disable-next-line max-len
      post(`/module_schematique/directories/${params.repertoryId}/folders/${params?.folderId}/projects/${params.fileId}/remarkable-points/`,
        params.pr.pr)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
        index: params.pr.index,
      })
    }
  },
)

const getRepertoryPr = createAsyncThunk(
  'pr/getRepertoryPr',
  async (params: GetParams, thunkApi) => {
    try {
      const response = await
      // eslint-disable-next-line max-len
      get(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/remarkable-points/`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const getFolderPr = createAsyncThunk(
  'pr/getFolderPr',
  async (params: GetParams, thunkApi) => {
    try {
      const response = await
      // eslint-disable-next-line max-len
      get(`/module_schematique/directories/${params.repertoryId}/folders/${params?.folderId}/projects/${params.fileId}/remarkable-points/`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const deleteRepertoryPR = createAsyncThunk(
  'pr/deletePR',
  async (params: DeleteParams, thunkApi) => {
    try {
      const response = await
      // eslint-disable-next-line max-len
      deleteRequest(`/module_schematique/directories/${params.repertoryId}/projects/${params.projectId}/remarkable-points/${params.prId}`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const deleteFolderPR = createAsyncThunk(
  'pr/deletePR',
  async (params: DeleteParams, thunkApi) => {
    try {
      const response = await
      // eslint-disable-next-line max-len
      deleteRequest(`/module_schematique/directories/${params.repertoryId}/folders/${params?.folderId}/projects/${params.projectId}/remarkable-points/${params.prId}`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const patchRepertoryPr = createAsyncThunk<PR, PatchParams, ThunkApi>(
  'pr/patchRepertoryPr',
  async (params: PatchParams, thunkApi) => {
    try {
      const response: PR = await
      // eslint-disable-next-line max-len
      patch(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/remarkable-points/${params.pr.id}`,
        params.pr)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const patchFolderPr = createAsyncThunk<PR, PatchParams, ThunkApi>(
  'pr/patchFolderPr',
  async (params: PatchParams, thunkApi) => {
    try {
      const response: PR = await
      // eslint-disable-next-line max-len
      patch(`/module_schematique/directories/${params.repertoryId}/folders/${params?.folderId}/projects/${params.fileId}/remarkable-points/${params.pr.id}`,
        params.pr)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

export {
  getRepertoryFileCodeLines,
  getFolderFileCodeLines,
  postRepertoryPr,
  postFolderPr,
  getRepertoryPr,
  getFolderPr,
  deleteRepertoryPR,
  deleteFolderPR,
  patchRepertoryPr,
  patchFolderPr,
}
