/* eslint-disable max-len */
import { ReactElement, useEffect, useState } from 'react'
import { Box, Fade, Grid } from '@mui/material'
import terms from 'common/terms'
import OrderingMenu from 'components/common/OrderingMenu/OrderingMenu'
import SearchBar from 'components/common/Search/SearchBar'
import { useDispatch, useSelector } from 'react-redux'
import { updateContentSearch, updateOrderContent } from 'reducers/Repertories/repertories'
import { resetSharingStatus } from 'reducers/Cerbere/cerbere'
import { RootState } from 'Store'
import { getFoldersList } from 'reducers/Folders/folder.thunk'
import { getRepertoryFilesList } from 'reducers/Files/files.thunk'
import { updateActiveProject, updateCreationLevel, updateSectionsList } from 'reducers/creation'
import { ActiveTab, CreationLevels } from 'components/Dashboard/types'
import history from 'customHistory'
import PathNames from 'common/PathNames'
import CreationService from 'components/Dashboard/Creation/CreationService'
import { updateActiveBlock } from 'reducers/synoptic'
import {
  resetResponseError, toggleCloneFile, toggleIsCloning, toggleOpenFile,
} from 'reducers/Files/files'
import { toggleSelectTab, updateActiveTab, updateTabsList } from 'reducers/dashboard'
import { FileTypes } from 'reducers/Files/types'
import SnackBar from 'components/common/Snackbar/SnackBar'
import { updateCreationStatus } from 'reducers/MultiYearPlan/multiYearPlan'
import { resetFolderResponseError } from 'reducers/Folders/folder'
import ContextMenu from './Folders/ContextMenu'
import FoldersContainer from './Folders/FoldersContainer'
import { getErrorMessage, getOrderingValue, showMenu } from './utils'
import FilesContainer from './Files/FilesContainer'
import { getPath } from '../../../utils'

export default function RepertoriesContentContainer(): ReactElement {
  const dispatch = useDispatch()
  const { orderContentBy, activeRepertory, contentSearch } = useSelector((state: RootState) => state.repertories)
  const { shareDone } = useSelector((state: RootState) => state.cerbere)
  const {
    creationContext, xYPosition, folders, activeFolder, folderResponseError,
  } = useSelector((state: RootState) => state.folders)
  const { activeProject } = useSelector((state: RootState) => state.creation)
  const { tabsList, selectTab } = useSelector((state: RootState) => state.dashboard)
  const {
    openFile, moveToFolder, cloneFile, activeFile, responseError, isCloning,
  } = useSelector((state: RootState) => state.files)
  const { gettingSections } = useSelector((state: RootState) => state.multiYearPlan)

  const [displayCloneLoading, setDisplayCloneLoading] = useState(false)
  const [displayCloneDone, setDisplayCloneDone] = useState(false)
  const [displayCloneFailed, setDisplayCloneFailed] = useState(false)

  useEffect(() => {
    if (displayCloneDone) {
      setTimeout(() => {
        setDisplayCloneLoading(false)
        setDisplayCloneDone(false)
      }, 1000)
    }
  }, [displayCloneDone])

  useEffect(() => {
    if (displayCloneFailed) {
      setTimeout(() => {
        setDisplayCloneFailed(false)
      }, 1000)
    }
  }, [displayCloneFailed])

  useEffect(() => {
    if (cloneFile && !isCloning) {
      setDisplayCloneDone(true)
      dispatch(toggleCloneFile())
    }
    if (isCloning) {
      setDisplayCloneLoading(true)
      setTimeout(() => {
        dispatch(toggleIsCloning())
      }, 2000)
    }
    if (!isCloning && (responseError || folderResponseError)) {
      setDisplayCloneLoading(false)
      setDisplayCloneFailed(true)
    }
  }, [cloneFile, isCloning, responseError, folderResponseError])

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetSharingStatus())
    }, 2000)
  }, [shareDone])

  useEffect(() => {
    if (activeRepertory) {
      dispatch(getFoldersList({
        repertoryId: activeRepertory.id,
        ordering: orderContentBy,
        search: contentSearch !== '' ? contentSearch : null,
      }))
      dispatch(getRepertoryFilesList({
        repertoryId: activeRepertory.id,
        ordering: getOrderingValue(orderContentBy),
        search: contentSearch !== '' ? contentSearch : null,
      }))
    }
  }, [activeRepertory, contentSearch, orderContentBy, moveToFolder, cloneFile])

  useEffect(() => {
    if (activeFile && openFile) {
      if (activeFile.type === FileTypes.Synoptic) {
        if (activeProject) {
          const newActiveTab: ActiveTab = {
            tabName: activeProject.name,
            tabId: activeProject.id,
            fileType: activeFile.type,
            directoryId: activeFile.directory,
            folderId: activeFile.folder,
          }
          dispatch(updateActiveTab(newActiveTab))
          if (tabsList && tabsList.filter((tab: ActiveTab) => tab.tabId === newActiveTab.tabId).length === 0) {
            const newTabsList: Array<ActiveTab> = [newActiveTab]
            dispatch(updateTabsList(tabsList.concat(newTabsList)))
          }
          dispatch(updateCreationLevel(activeProject.status))
          if (activeProject.status === CreationLevels.Name) {
            history.push(`${getPath(activeRepertory, activeFolder, activeFile)}/${PathNames.FileSynoptic}/${PathNames.TemporalPerimeter}`)
          } else if (activeProject.status === CreationLevels.Temporal) {
            dispatch(updateSectionsList(activeProject.sections))
            history.push(`${getPath(activeRepertory, activeFolder, activeFile)}/${PathNames.FileSynoptic}/${PathNames.GeographicPerimeter}`)
          } else if (activeProject.status === CreationLevels.Geographic) {
            dispatch(CreationService.getSingleProject(activeProject.id))
            dispatch(updateActiveBlock(terms.Synoptic.createFondPlan))
          }
          dispatch(toggleOpenFile(false))
        }
      } else {
        const newActiveTab: ActiveTab = {
          tabName: activeFile.name,
          tabId: activeFile.id,
          fileType: activeFile.type,
          directoryId: activeFile.directory,
          folderId: activeFile.folder,
        }
        if (!gettingSections) {
          dispatch(updateActiveTab(newActiveTab))
          if (tabsList && tabsList.filter((tab: ActiveTab) => tab.tabId === newActiveTab.tabId).length === 0) {
            const newTabsList: Array<ActiveTab> = [newActiveTab]
            dispatch(updateTabsList(tabsList.concat(newTabsList)))
          }
          dispatch(updateCreationStatus(activeFile.status))
          if (activeFile.status === CreationLevels.Name) {
            history.push(`${getPath(activeRepertory, activeFolder, activeFile)}/${PathNames.FileSchema}/${PathNames.TemporalPerimeter}`)
          } else if (activeFile.status === CreationLevels.Temporal) {
            history.push(`${getPath(activeRepertory, activeFolder, activeFile)}/${PathNames.FileSchema}/${PathNames.GeographicPerimeter}`)
          }
          dispatch(toggleOpenFile(false))
        }
      }
    }
  }, [activeProject, openFile, activeFile, gettingSections])

  useEffect(() => {
    if (activeFile && selectTab && selectTab.tabId === activeFile.id) {
      if (activeFile.type === FileTypes.Synoptic) {
        if (activeProject && activeProject.id === activeFile.id) {
          dispatch(updateCreationLevel(activeProject.status))
          if (activeProject.status === CreationLevels.Geographic) {
            dispatch(updateActiveBlock(terms.Synoptic.createFondPlan))
          } else if (activeProject.status === CreationLevels.Name) {
            if (activeProject.type === FileTypes.Synoptic) {
              if (activeFile) {
                history.push(`${getPath(activeRepertory, activeFolder, activeFile)}/${PathNames.FileSynoptic}/${PathNames.TemporalPerimeter}`)
              }
            }
          } else if (activeProject.status === CreationLevels.Temporal) {
            dispatch(updateSectionsList(activeProject.sections))
            if (activeProject.type === FileTypes.Synoptic) {
              if (activeFile) {
                history.push(`${getPath(activeRepertory, activeFolder, activeFile)}/${PathNames.FileSynoptic}/${PathNames.GeographicPerimeter}`)
              }
            }
          }
          dispatch(toggleSelectTab(undefined))
          dispatch(updateActiveTab({
            tabName: activeProject.name,
            tabId: activeProject.id,
            fileType: activeFile.type,
            directoryId: activeFile.directory,
            folderId: activeFile.folder,
          }))
        }
      } else {
        dispatch(updateActiveProject(undefined))
        dispatch(updateCreationLevel(undefined))
        dispatch(updateActiveTab({
          tabName: activeFile.name,
          tabId: activeFile.id,
          fileType: activeFile.type,
          directoryId: activeFile.directory,
          folderId: activeFile.folder,
        }))
        dispatch(updateCreationStatus(activeFile.status))
        if (activeFile.status === CreationLevels.Name) {
          history.push(`${getPath(activeRepertory, activeFolder, activeFile)}/${PathNames.FileSchema}/${PathNames.TemporalPerimeter}`)
        } else if (activeFile.status === CreationLevels.Temporal) {
          history.push(`${getPath(activeRepertory, activeFolder, activeFile)}/${PathNames.FileSchema}/${PathNames.GeographicPerimeter}`)
        }
        dispatch(toggleSelectTab(undefined))
      }
    }
  }, [activeProject, selectTab, activeFile])

  return (
    <Fade in timeout={500}>
      <Box>
        <Grid
          container
          className="repertory-content-container"
        >
          <Grid item container>
            <SearchBar
              placeholder={terms.Repertory.searchPlaceholder}
              onChange={updateContentSearch}
              search={contentSearch}
            />
            <OrderingMenu orderBy={orderContentBy} updateOrderBy={updateOrderContent} />
          </Grid>
        </Grid>
        <Grid
          container
          className="px-3"
          onContextMenu={e => showMenu(e, true)}
          sx={{
            height: 'calc(100vh - 390px)', overflow: 'auto', flexDirection: 'column', flexWrap: 'nowrap',
          }}
        >
          {folders.length !== 0 && <FoldersContainer />}
          <FilesContainer />
          <ContextMenu context={creationContext} xyPosition={xYPosition} isCreation />
        </Grid>
        <SnackBar
          message={getErrorMessage(folderResponseError || responseError, displayCloneLoading, displayCloneDone, displayCloneFailed, shareDone)}
          error={folderResponseError || responseError || displayCloneLoading || displayCloneDone || displayCloneFailed || shareDone}
          severity={displayCloneDone || shareDone ? 'success' : 'warning'}
          setError={folderResponseError ? resetFolderResponseError : resetResponseError}
        />
      </Box>
    </Fade>
  )
}
