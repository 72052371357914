/* eslint-disable react/no-array-index-key */
import terms from 'common/terms'
import GenericInput from 'components/common/Inputs/GenericInput'
import GenericSelect from 'components/common/Inputs/GenericSelect'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { InputTypes, ResponseError } from 'types'
import { PrFields } from 'reducers/RemarkablePoints/types'
import { updatePrFields } from 'reducers/RemarkablePoints/remarkablePoints'
import { Checkbox, FormControlLabel } from '@mui/material'
import { PrInput, PrInputFields } from './AddPrInputFields'
import { getErrorMessage } from './utils'

type Props = {
  prIndex: number
}

export default function AddPrInputs({ prIndex }: Props):
    ReactElement {
  const dispatch = useDispatch()
  const {
    lineCodes, prFields, responseError, emptyFields, displayInputFieldsModal, selectedPr,
  } = useSelector((state: RootState) => state.remarkablePoints)

  const [lineCode, setLineCode] = useState<string>('')
  const [pk, setPk] = useState<string>('')
  const [prLabel, setPrLabel] = useState<string>('')
  const [isImportant, setIsImportant] = useState<boolean>(false)

  useEffect(() => {
    const newPr = {
      line_code: lineCode,
      kp: pk,
      label: prLabel,
      is_important: isImportant,
    }
    const newPrFields: PrFields[] = prFields.map((pr: PrFields) => (pr.index !== prIndex
      ? pr : { index: prIndex, pr: newPr }))
    dispatch(updatePrFields(newPrFields))
  }, [lineCode, pk, prLabel, isImportant])

  useEffect(() => {
    if (displayInputFieldsModal && selectedPr) {
      setLineCode(selectedPr.line_code)
      setPk(selectedPr.kp)
      setPrLabel(selectedPr.label)
      setIsImportant(selectedPr.is_important)
    }
  }, [displayInputFieldsModal, selectedPr])

  const handleChange = (value: string, fieldLabel: string) => {
    switch (fieldLabel) {
      case terms.Inputs.line:
        setLineCode(value)
        break
      case terms.Inputs.pk:
        setPk(value)
        break
      default:
        setPrLabel(value)
        break
    }
  }

  const getInputValue = (fieldLabel: string) => prFields.filter((pr: PrFields) => pr.index
   === prIndex)[0].pr[fieldLabel]

  const getPrError = () => (selectedPr ? responseError[0]
    : responseError?.filter((error: ResponseError) => error.index === prIndex)[0])

  return (
    <div className="pr-fields">
      {PrInputFields.map((field: PrInput, index: number) => (
        (field.fieldType === InputTypes.Select ? (
          <GenericSelect
            key={`${field.label}-${index}`}
            label={field.label}
            options={lineCodes}
            onChange={e => handleChange(e.target.value, field.label)}
            value={getInputValue(field.key)}
            errorMessage={getErrorMessage(getPrError(), field.key, emptyFields, getInputValue(field.key))}
            labelColor="#8A8A8A"
          />
        )
          : (
            <GenericInput
              key={`${field.label}-${index}`}
              label={field.label}
              placeholder={field.placeholder as string}
              value={getInputValue(field.key)}
              onChange={e => handleChange(e.target.value, field.label)}
              errorMessage={getErrorMessage(getPrError(), field.key, emptyFields, getInputValue(field.key))}
              labelColor="#8A8A8A"
            />
          ))
      ))}
      <div className={`importance-checkbox ${responseError ? ' error-importance-checkbox' : ''}`}>
        <FormControlLabel
          control={(
            <Checkbox
              checked={getInputValue('is_important')}
              onChange={() => setIsImportant(!isImportant)}
            />
        )}
          label={terms.Inputs.important}
        />
      </div>
    </div>
  )
}
