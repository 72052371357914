import { ReactElement } from 'react'
import { Source, Layer } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { FiltersCategories, ObjectsLayer, ObjectsName } from 'components/Map/ObjectsLayers/types'
import { getFilterCheckStatus, getLayerVisibilityByName } from '../utils'

export default function IpcsLayer(): ReactElement {
  const { peLayerData, filtersList } = useSelector((state: RootState) => state.object)

  return (
    <>
      <Source type="geojson" data={peLayerData} id={ObjectsName.IPCS}>
        <Layer
          id={ObjectsName.IPCS}
          filter={['all',
            ['==', 'auto_added', true],
            ['==', 'type', 'IPCS'],
          ]}
          type="symbol"
          paint={{}}
          minzoom={12}
          layout={{
            'text-font': ['Roboto Condensed'],
            'icon-image': 'ipcs',
            'symbol-placement': 'line-center',
            'icon-size': [
              'interpolate',
              ['linear'],
              ['zoom'],
              10,
              0.1,
              13,
              0.5,
              15,
              0.8,
            ],
            visibility: getLayerVisibilityByName(filtersList, FiltersCategories.ModeExploit, ObjectsName.IPCS,
              getFilterCheckStatus(filtersList, FiltersCategories.General, ObjectsLayer.gaia)),
          }}
        />
      </Source>
    </>
  )
}
