import {
  PayloadAction, createSlice, isAnyOf,
} from '@reduxjs/toolkit'
import {
  MypOperationActionsState, Operations,
} from './types'
import { getOperations, getPhaseFilters, patchPhaseFilter } from './operations.thunk'
import formatData from './utils'
import {
  deleteOperation,
  patchOperationPosition,
  patchOperation, postOperation,
} from '../OperationActions/operationactions.thunk'
import { PatchFamilyTrack, patchClassification } from '../Import/ObjectsFamilies/objectsFamilies.thunk'
import { fixDefectedOperation } from '../Library/library.thunk'

const initialState: MypOperationActionsState = {
  Operations: {},
  formatedOperations: {},
  updateOperationsChart: false,
  loadingOperations: false,
  phaseFilters: [],
}

export const mypOperationsSlice = createSlice({
  name: 'mypoperations',
  initialState,
  reducers: {
    resetOperations: state => {
      state.Operations = {}
    },
    updateOperations: (state, action: PayloadAction<Operations>) => {
      state.formatedOperations = action.payload
    },
    updateOperationsChart: state => {
      state.updateOperationsChart = !state.updateOperationsChart
    },
  },
  extraReducers: builder => {
    builder.addCase(getOperations.fulfilled, (state, action) => {
      state.Operations = action.payload
      state.formatedOperations = formatData(state.Operations)
      state.loadingOperations = false
    })
    builder.addCase(getOperations.pending, state => {
      state.loadingOperations = true
    })
    builder.addCase(getOperations.rejected, state => {
      state.loadingOperations = false
    })
    builder.addCase(postOperation.fulfilled, state => {
      state.updateOperationsChart = !state.updateOperationsChart
    })
    builder.addCase(patchOperation.fulfilled, state => {
      state.updateOperationsChart = !state.updateOperationsChart
    })

    builder.addCase(getPhaseFilters.fulfilled, (state, action) => {
      state.phaseFilters = action.payload
    })
    builder.addCase(patchPhaseFilter.fulfilled, (state, action) => {
      state.phaseFilters = state.phaseFilters.map(file => (file.id === action.payload.id
        ? action.payload : file))
    })
    builder.addMatcher(isAnyOf(
      deleteOperation.fulfilled,
      patchPhaseFilter.fulfilled,
      patchOperationPosition.fulfilled,
      PatchFamilyTrack.fulfilled,
      patchClassification.fulfilled,
      fixDefectedOperation.fulfilled,
    ), state => {
      state.updateOperationsChart = !state.updateOperationsChart
    })
  },
})
export const {
  updateOperations,
  resetOperations,
  updateOperationsChart,
} = mypOperationsSlice.actions

export default mypOperationsSlice.reducer
