import { store } from 'Store'
import terms from 'common/terms'
import { ActiveTab } from 'components/Dashboard/types'
import history from 'customHistory'
import { Files } from 'reducers/Files/types'
import { Folder } from 'reducers/Folders/types'
import { Repertory } from 'reducers/Repertories/types'
import { updateActiveTab, updateTabsList } from 'reducers/dashboard'
import { ResponseError } from 'types'
import { getPath } from '../../../utils'

/* eslint-disable import/prefer-default-export */
export const getErrorMessage = (responseError: ResponseError, field: string, emptyFields?: boolean,
  value?: string) => {
  if (responseError) {
    const { message } = responseError
    if (message.time_range) {
      if (message.time_range[field]) {
        return message.time_range[field]
      }
      return message.time_range.non_field_errors
    }
    if (emptyFields && value === '') {
      return terms.Common.Errors.requiredField
    }
    return message[field]
  }
  if (emptyFields && value === '') {
    return terms.Common.Errors.requiredField
  }
  return ''
}

export const displayTabContent = (tabsList: ActiveTab[], activeFile: Files, activeRepertory: Repertory,
  activeFolder: Folder) => {
  if (tabsList && tabsList.filter((tab: ActiveTab) => tab.tabId === activeFile.id).length === 0) {
    const newActiveTab: ActiveTab = {
      tabName: activeFile.name,
      tabId: activeFile.id,
      fileType: activeFile.type,
      directoryId: activeFile.directory as number,
      folderId: activeFile.folder as number,
    }
    const newTabsList: Array<ActiveTab> = [newActiveTab]
    store.dispatch(updateTabsList(tabsList.concat(newTabsList)))
    store.dispatch(updateActiveTab(newActiveTab))
  }
  history.push(getPath(activeRepertory, activeFolder, activeFile))
}
