import {
  Classification, FormatedOperationsYear, Operations, OperationsLabels, OperationsYear,
} from 'reducers/MultiYearPlan/Operations/types'

const formatToSingleChart = (operations: Operations) => {
  const newData: FormatedOperationsYear[] = []
  operations.years?.forEach((year: OperationsYear) => {
    const operationsList: OperationsLabels[] = []
    year.classifications.forEach((classification: Classification) => {
      classification.operation_labels.forEach((operation: OperationsLabels) => {
        operationsList.push({
          ...operation,
          id: operationsList.length + 1,
          data: [
            {
              ...operation.data[0],
              y: ((year.classifications.length - 1) - classification.order) * 40 + 20,
            },
            {
              ...operation.data[1],
              y: ((year.classifications.length - 1) - classification.order) * 40 + 20,
            },
          ],
        })
      })
    })
    newData.push({
      year: year.year,
      classifications: operationsList,
    })
  })
  return { years: newData }
}

export default formatToSingleChart
