import { ReactElement } from 'react'
import {
  Box, Modal, Typography,
} from '@mui/material'
import terms from 'common/terms'
import ActionButton from 'components/common/Buttons/ActionButton'

import './modal.scss'
import Loader from 'components/Loader'

export default function DeleteModal({
  open, onModalClose, modalTitle, modalNotice, onDelete, isLoading,
}: {open: boolean, onModalClose: () => void, modalTitle: string, modalNotice: string, onDelete: () => void,
   isLoading?: boolean})
: ReactElement {
  return (
    <Modal
      open={open}
      onClose={onModalClose}
      disableEscapeKeyDown
    >
      <Box className="action-modal">
        {isLoading && <Loader center />}
        <Typography variant="h6" component="h1">
          {modalTitle}
        </Typography>
        <Typography sx={{ mt: 2, mb: 3 }}>
          {modalNotice}
        </Typography>
        <div className="w-100 d-flex justify-content-between align-items-center px-4 py-2">
          <ActionButton
            text={terms.Common.cancel}
            onClick={onModalClose}
            buttonStyle="cancel-action-btn"
          />
          <ActionButton text={terms.Common.delete} onClick={onDelete} buttonStyle="delete-btn" />
        </div>
      </Box>
    </Modal>
  )
}

DeleteModal.defaultProps = {
  isLoading: false,
}
