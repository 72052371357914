import {
  LineString, ExtendLineStringMode, Feature as NebulaFeature,
} from '@nebula.gl/edit-modes'
import { RefObject } from 'react'
import {
  RENDER_STATE, SHAPE, Editor, EditingMode, DrawLineStringMode, DrawRectangleMode,
} from 'react-map-gl-draw'
import { Feature } from 'geojson'
import { Properties } from '@turf/helpers'

export type FeatureStyleType = {
  feature: Feature;
  state?: RENDER_STATE;
  shape?: SHAPE;
}

export type GeoEditorFeature = NebulaFeature;
export type LineStringFeature = Feature<LineString, Properties>

export enum EditorModeName {
  View = 'view',
  Edit = 'edit',
  Extend = 'extend',
}

export type EditorMode = EditingMode | DrawLineStringMode | DrawRectangleMode | ExtendLineStringMode

export type EditorLayerState = {
  editorRef: RefObject<Editor> | null;
}

export type EditContextObj = {
  featureIndexes?: number[];
  editHandleIndexes?: number[];
  screenCoords?: [number, number];
  mapCoords?: [number, number];
}

export type UpdateEvent = {
  editType: string;
  data: GeoEditorFeature[];
  editContext: EditContextObj;
}

export type SelectEvent = {
  selectedFeature: Feature | null;
  selectedFeatureIndex: number | null;
  selectedEditHandleIndex: number | null;
  screenCoords?: [number, number];
  mapCoords?: [number, number];
}

export type ModeConfig = {
  dragToDraw?: boolean;
  drawAtFront?: boolean;
}
