import { Typography } from '@mui/material'
import { RootState } from 'Store'
import terms from 'common/terms'
import { OverlappingPopupInfo } from 'components/Portfolio/types'
import { ReactElement } from 'react'
import { Popup } from 'react-map-gl'
import { useDispatch, useSelector } from 'react-redux'
import { setOverlappingPopupInfo } from 'reducers/label'

export default function OverlappingNumberPopup({ popupInfo }:{popupInfo: OverlappingPopupInfo}): ReactElement {
  const dispatch = useDispatch()
  const { overlappingPopupInfo } = useSelector((state: RootState) => state.label)

  return (
    <Popup
      anchor="bottom"
      longitude={popupInfo.longitude}
      latitude={popupInfo.latitude}
      onClose={() => {
        dispatch(setOverlappingPopupInfo(overlappingPopupInfo.filter((info: OverlappingPopupInfo) => info.latitude
         !== popupInfo.latitude && info.longitude !== popupInfo.longitude)))
      }}
      closeOnClick={false}
    >
      <Typography>
        {popupInfo.overlappingNumber}
        {' '}
        {terms.Portfolio.overlap}
      </Typography>
    </Popup>
  )
}
