/* eslint-disable no-underscore-dangle */
import {
  MutableRefObject, ReactElement, Ref, useState,
} from 'react'
import MapGL, { MapEvent, MapRef, ViewportProps } from 'react-map-gl'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { polygon } from '@turf/helpers'
import center from '@turf/center'
import PortfolioActionsButtons from 'components/Portfolio/Buttons/PortfolioActionsButtons'
import LabelActionsButtons from 'components/Portfolio/Buttons/LabelActionsButtons'
import SynopticActionsButtons from 'components/Synoptic/Buttons/SynopticActionsButtons'
import { toggleShowColorPanel, setNewLabelObjectLayer, updateOverlappingPopupInfo } from 'reducers/label'
import {
  setNewSignalObjectLayer, setNewRkObjectLayer, setNewAdvObjectLayer, setobjectlngLat, setNewTeObjectLayer,
  setObjectModifiedFeature, setNewBvObjectLayer,
} from 'reducers/object'
import { updateClickedFeatureInfo, updateHoveredFeatureInfo } from 'reducers/map'
import manualLabel from 'assets/icons/manualLabel.png'
import bvIcon from 'assets/icons/bv.png'
import etiquette from 'assets/icons/etiquette.png'
import vmax from 'assets/icons/vmax.png'
import simpleRectangle from 'assets/icons/rectangle_simple.png'
import pan from 'assets/icons/pan.png'
import vb from 'assets/icons/voieunique.png'
import ipcs from 'assets/icons/ipcs.png'

import { LabelsLayersIds, ObjectsLayer, SignalType } from 'components/Map/ObjectsLayers/types'
import ObjectsActionsButtons from 'components/Synoptic/Buttons/ObjectsActionsButtons'
import LibraryButton from 'components/Portfolio/Buttons/Library/LibraryButton'
import terms from 'common/terms'
import { updateLibraryLabel } from 'reducers/library'
import { updateProjectZoom } from 'reducers/creation'
import { OverlappingPopupInfo } from 'components/Portfolio/types'
import MapLayers from './ObjectsLayers/MapLayers'
import { DEFAULT_VIEWPORT, transformRequest } from './utils'
import mapStyle from './empty-map.json'
import ContextMenu from './ContextMenu/ContextMenu'
import ToolBar from './ToolBar/ToolBar'

import './map.scss'
import OverlappingNumberPopup from './ObjectsLayers/LabelLayer/OverlappingNumberPopup'

type Props = {
  viewport: ViewportProps;
  mapRef: MutableRefObject<MapRef | undefined> | undefined;
  onViewportChange: (v: ViewportProps) => void;
};

export default function Map({
  viewport,
  mapRef,
  onViewportChange,
}: Props): ReactElement {
  const dispatch = useDispatch()
  const { activeBlock } = useSelector((state: RootState) => state.synoptic)
  const {
    showObjectPanel, showObjectCreationPanel, rkLayerData, ObjectPositionMode,
    ObjectOrientationMode, ObjectDeletionMode,
  } = useSelector((state: RootState) => state.object)
  const { clickedFeatureInfo } = useSelector((state: RootState) => state.map)
  const {
    showPanel, showColorPanel, labelLayerData, overlappingPopupInfo,
  } = useSelector((state: RootState) => state.label)
  const [hoveredEvent, setHoveredEvent] = useState<MapEvent | undefined>(
    undefined,
  )
  const [contextEvent, setContextEvent] = useState<MapEvent | undefined>(
    undefined,
  )
  const [dragpan, setDragpan] = useState<boolean>(true)
  const [features, setFeatures] = useState<any>(undefined)
  const [feature, setFeature] = useState<any>(undefined)
  const [isDraggable, setIsDraggable] = useState<boolean>(false)
  const [pointerEvent, setPointerEvent] = useState<any>(undefined)

  const {
    signalLayerData, advLayerData, teLayerData, bvLayerData,
  } = useSelector((state: RootState) => state.object)

  const onContextMenu = (e: MapEvent) => {
    setPointerEvent(e.type)
    e.preventDefault()
    setHoveredEvent(undefined)
    if (e && hoveredEvent?.features && hoveredEvent?.features.length !== 0 && !ObjectPositionMode) {
      setContextEvent(e)
      dispatch(toggleShowColorPanel(false))
      if (activeBlock === terms.Synoptic.addPortfolio || activeBlock === terms.Synoptic.createFondPlan) {
        dispatch(updateClickedFeatureInfo(hoveredEvent.features[0]))
      }
    } else if (!showColorPanel) {
      setContextEvent(undefined)
      if (!ObjectPositionMode) dispatch(updateClickedFeatureInfo(undefined))
    }
  }

  const onHover = (e: MapEvent) => {
    if (e.features && e.features.length > 0 && !ObjectPositionMode) {
      setHoveredEvent(e)
      dispatch(updateHoveredFeatureInfo(e.features[0]))
    } else {
      setHoveredEvent(undefined)
      dispatch(updateHoveredFeatureInfo(undefined))
    }
  }

  const onClick = (e: MapEvent) => {
    if (e.features && e.features.length > 0 && !ObjectPositionMode) {
      if (e.features[0].source === LabelsLayersIds.label && e.features[0].properties.overlaping_areas_count >= 1) {
        dispatch(updateOverlappingPopupInfo(true))
      }
    }
    dispatch(updateLibraryLabel(undefined))
  }

  const onMouseEnter = (e: MapEvent) => {
    if (activeBlock === terms.Synoptic.createFondPlan) {
      if (e.features && e.features.length !== 0 && !ObjectPositionMode
        && !ObjectOrientationMode && !ObjectDeletionMode) {
        setFeature(e.features[0])
        dispatch(setObjectModifiedFeature(e.features[0]))
        if (e.features[0].layer.id === ObjectsLayer.adv || e.features[0].layer.id === `manual-${ObjectsLayer.adv}`) {
          setFeatures(advLayerData)
        } else if (e.features[0].layer.id === ObjectsLayer.te
          || e.features[0].layer.id === `manual-${ObjectsLayer.te}`) {
          setFeatures(teLayerData)
        } else if (e.features[0].layer.id === ObjectsLayer.bv
          || e.features[0].layer.id === `manual-${ObjectsLayer.bv}`) {
          setFeatures(bvLayerData)
        } else {
          setFeatures(signalLayerData)
        }
      } else if (e.features && (ObjectPositionMode || ObjectDeletionMode) && clickedFeatureInfo.source.includes('rk')) {
        setFeature(e.features[0])
      }
    } else if (activeBlock === terms.Synoptic.addPortfolio) {
      if (e.features && e.features.length !== 0 && !ObjectPositionMode) {
        setFeature(e.features[0])
        dispatch(setObjectModifiedFeature(e.features[0]))
        if (e.features[0].layer.id === 'labelLayer' || e.features[0].layer.id === 'small-labelLayer') {
          const featuresList: Array<GeoJSON.Feature> = []
          labelLayerData.features.forEach((featuree: any) => {
            if (featuree.geometry.type === 'Point') {
              featuresList.push(featuree)
            } else {
              const newFeature = {
                ...featuree,
                geometry: {
                  type: 'Point',
                  coordinates:
                  featuree.geometry && featuree.geometry.type !== null && featuree.geometry.coordinates.length !== 0
                    ? center(polygon(featuree.geometry.coordinates[0])).geometry.coordinates : [],
                },
              }
              featuresList.push(newFeature)
            }
          })

          const labelGeojson: GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties> = {
            type: 'FeatureCollection',
            features: featuresList,
          }
          setFeatures(labelGeojson)
        }
      }
    }
  }

  const onMouseDown = (e: MapEvent) => {
    if (activeBlock === terms.Synoptic.createFondPlan && ObjectPositionMode) {
      setPointerEvent(e.type)
      if (feature !== undefined) {
        setIsDraggable(true)
        setDragpan(false)
      }
    } else if (activeBlock === terms.Synoptic.createFondPlan && ObjectOrientationMode) {
      setPointerEvent(e.type)
      if (feature !== undefined) {
        setIsDraggable(false)
        setDragpan(false)
      }
    } else if (activeBlock === terms.Synoptic.createFondPlan && ObjectDeletionMode) {
      setPointerEvent(e.type)
      if (feature !== undefined) {
        setIsDraggable(false)
        setDragpan(false)
        const featuresCopy = JSON.parse(JSON.stringify(rkLayerData))
        featuresCopy.features = featuresCopy.features
          .filter((feat: any) => feat.properties.originalIndex !== feature.properties.originalIndex)
        dispatch(setNewRkObjectLayer(featuresCopy))
      }
    } else if (activeBlock === terms.Synoptic.addPortfolio && ObjectPositionMode) {
      setPointerEvent(e.type)
      if (feature !== undefined) {
        setIsDraggable(true)
        setDragpan(false)
      }
    }
  }

  const onMouseUp = () => {
    if (activeBlock === terms.Synoptic.createFondPlan && ObjectPositionMode) {
      setIsDraggable(false)
      setDragpan(true)
    } else if (activeBlock === terms.Synoptic.createFondPlan && ObjectOrientationMode) {
      setIsDraggable(false)
      setDragpan(true)
    } else if (activeBlock === terms.Synoptic.createFondPlan && ObjectDeletionMode) {
      setIsDraggable(false)
      setDragpan(true)
    } else if (activeBlock === terms.Synoptic.addPortfolio && ObjectPositionMode) {
      setIsDraggable(false)
      setDragpan(true)
    }
  }

  const onMouseMove = (e: any) => {
    if (!showObjectCreationPanel) {
      dispatch(setobjectlngLat(e.lngLat))
    }
    if (activeBlock === terms.Synoptic.createFondPlan && ObjectPositionMode && pointerEvent === 'pointerdown') {
      if (isDraggable && feature && !clickedFeatureInfo.source.includes('rk')) {
        const featureCopy = JSON.parse(JSON.stringify(feature))
        featureCopy.geometry.coordinates = e.lngLat
        dispatch(setObjectModifiedFeature(featureCopy))
        setFeature(featureCopy)
        const featuresCopy = JSON.parse(JSON.stringify(features))
        featuresCopy.features.forEach((element: any, index: any) => {
          if (element.properties.object_id === feature.properties.object_id) {
            featuresCopy.features[index].geometry.coordinates = e.lngLat
            if (feature.layer.id === ObjectsLayer.adv || feature.layer.id === `manual-${ObjectsLayer.adv}`) {
              dispatch(setNewAdvObjectLayer(featuresCopy))
            } else if (feature.layer.id === ObjectsLayer.te || feature.layer.id === `manual-${ObjectsLayer.te}`) {
              dispatch(setNewTeObjectLayer(featuresCopy))
            } else if (feature.layer.id === ObjectsLayer.bv || feature.layer.id === `manual-${ObjectsLayer.bv}`) {
              dispatch(setNewBvObjectLayer(featuresCopy))
            } else {
              dispatch(setNewSignalObjectLayer(featuresCopy))
            }
          }
        })
      } else if (isDraggable && feature && clickedFeatureInfo.source.includes('rk')) {
        const featureCopy = JSON.parse(JSON.stringify(feature))
        featureCopy.geometry.coordinates = e.lngLat
        dispatch(setObjectModifiedFeature(featureCopy))
        setFeature(featureCopy)
        const featuresCopy = JSON.parse(JSON.stringify(rkLayerData))
        featuresCopy.features.forEach((element: any, index: any) => {
          if (element.properties.originalIndex === feature.properties.originalIndex) {
            featuresCopy.features[index].geometry.coordinates = e.lngLat
            dispatch(setNewRkObjectLayer(featuresCopy))
          }
        })
      }
    } else if (activeBlock === terms.Synoptic.createFondPlan
    && ObjectOrientationMode && pointerEvent === 'pointerdown') {
      if (!isDraggable && feature && e.angle) {
        const featureCopy = JSON.parse(JSON.stringify(feature))
        if (e.angle < 0) {
          featureCopy.properties.angle = 360 - (-e.angle)
        } else {
          featureCopy.properties.angle = e.angle
        }
        dispatch(setObjectModifiedFeature(featureCopy))
        setFeature(featureCopy)
        const featuresCopy = JSON.parse(JSON.stringify(features))
        featuresCopy.features.forEach((element: any, index: any) => {
          if (element.properties.object_id === feature.properties.object_id) {
            if (e.angle < 0) {
              featuresCopy.features[index].properties.angle = 360 - (-e.angle)
            } else {
              featuresCopy.features[index].properties.angle = e.angle
            }
            if (feature.layer.id === ObjectsLayer.adv || feature.layer.id === `manual-${ObjectsLayer.adv}`) {
              dispatch(setNewAdvObjectLayer(featuresCopy))
            } else if (feature.layer.id === ObjectsLayer.rk || feature.layer.id === `manual-${ObjectsLayer.rk}`) {
              dispatch(setNewRkObjectLayer(featuresCopy))
            } else if (feature.layer.id === ObjectsLayer.te || feature.layer.id === `manual-${ObjectsLayer.te}`) {
              dispatch(setNewTeObjectLayer(featuresCopy))
            } else if (feature.layer.id === ObjectsLayer.bv || feature.layer.id === `manual-${ObjectsLayer.bv}`) {
              dispatch(setNewTeObjectLayer(featuresCopy))
            } else {
              dispatch(setNewSignalObjectLayer(featuresCopy))
            }
          }
        })
      }
    } else if (activeBlock === terms.Synoptic.addPortfolio
    && ObjectPositionMode && pointerEvent === 'pointerdown') {
      if (isDraggable && feature && JSON.stringify(features)) {
        const featureCopy = JSON.parse(JSON.stringify(feature))
        featureCopy.geometry.coordinates = e.lngLat
        dispatch(setObjectModifiedFeature(featureCopy))
        setFeature(featureCopy)
        const featuresCopy = JSON.parse(JSON.stringify(features))
        featuresCopy.features.forEach((element: any, index: any) => {
          if (element.properties.object_id === feature.properties.object_id) {
            featuresCopy.features[index].geometry.coordinates = e.lngLat
            if (feature.layer.id === 'labelLayer' || feature.layer.id === 'small-labelLayer') {
              dispatch(setNewLabelObjectLayer(featuresCopy))
            }
          }
        })
      }
    }
  }

  const onMouseLeave = () => {
    if (activeBlock === terms.Synoptic.createFondPlan && !ObjectPositionMode && !ObjectOrientationMode) {
      setFeatures(undefined)
      setFeature(undefined)
    } else if (activeBlock === terms.Synoptic.addPortfolio && !ObjectPositionMode && !ObjectOrientationMode) {
      setFeatures(undefined)
      setFeature(undefined)
    }
  }

  return (
    <>
      <MapGL
        {...viewport}
        ref={mapRef as Ref<MapRef>}
        transformRequest={transformRequest}
        width="100%"
        height="100%"
        mapStyle={mapStyle}
        onViewportChange={(newViewport: typeof DEFAULT_VIEWPORT) => {
          onViewportChange(newViewport)
          dispatch(updateProjectZoom(newViewport.zoom as number))
        }}
        clickRadius={8}
        onClick={onClick}
        preventStyleDiffing
        interactiveLayerIds={
          activeBlock === terms.Synoptic.addPortfolio
            ? [`small-${LabelsLayersIds.label}`, LabelsLayersIds.label, LabelsLayersIds.actionZones]
            : [ObjectsLayer.adv,
              `manual-${ObjectsLayer.adv}`,
              ObjectsLayer.rk,
              ObjectsLayer.bv,
              `manual-${ObjectsLayer.bv}`,
              `manual-${ObjectsLayer.rk}`,
              `${ObjectsLayer.te}`,
              `manual-${ObjectsLayer.te}`,
              SignalType.carre,
              `manual-${SignalType.carre}`,
              SignalType.carrev,
              `manual-${SignalType.carrev}`,
              SignalType.disque,
              `manual-${SignalType.disque}`,
              SignalType.ga,
              `manual-${SignalType.ga}`,
              SignalType.sem,
              `manual-${SignalType.sem}`,
              SignalType.av,
              `manual-${SignalType.av}`]
        }
        onHover={onHover}
        onContextMenu={onContextMenu}
        onMouseUp={onMouseUp}
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
        onMouseMove={onMouseMove}
        onMouseDown={onMouseDown}
        dragPan={dragpan}
        onLoad={() => {
          const currentMap = mapRef?.current?.getMap()
          currentMap.loadImage(manualLabel, (error: any, image: any) => {
            if (error) return
            currentMap.addImage('manual', image)
          })
          currentMap.loadImage(bvIcon, (error: any, image: any) => {
            if (error) return
            currentMap.addImage('bv', image)
          })
          currentMap.loadImage(etiquette, (error: any, image: any) => {
            if (error) return
            currentMap.addImage('etiquette', image, { sdf: true })
          })
          currentMap.loadImage(vmax, (error: any, image: any) => {
            if (error) return
            currentMap.addImage('vmax', image)
          })
          currentMap.loadImage(simpleRectangle, (error: any, image: any) => {
            if (error) return
            currentMap.addImage('simple_rectangle', image)
          })
          currentMap.loadImage(pan, (error: any, image: any) => {
            if (error) return
            currentMap.addImage('pan', image)
          })
          currentMap.loadImage(vb, (error: any, image: any) => {
            if (error) return
            currentMap.addImage('voieunique', image)
          })
          currentMap.loadImage(ipcs, (error: any, image: any) => {
            if (error) return
            currentMap.addImage('ipcs', image, { sdf: true })
          })
        }}
      >
        {contextEvent && (
          activeBlock === terms.Synoptic.addPortfolio
          || (!ObjectPositionMode && !ObjectOrientationMode && !ObjectDeletionMode)
            ? (
              <ContextMenu
                event={contextEvent}
                reset={() => !showColorPanel && setContextEvent(undefined)}
                setMapHover={setHoveredEvent}
              />
            ) : <></>
        )}
        {overlappingPopupInfo.length !== 0
        && overlappingPopupInfo.map((popupInfo: OverlappingPopupInfo, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <OverlappingNumberPopup key={`popup-${index}`} popupInfo={popupInfo} />
        ))}
        <ToolBar mapRef={mapRef} />
        <MapLayers />
      </MapGL>
      {activeBlock === terms.Synoptic.addPortfolio && !showPanel && (
        <PortfolioActionsButtons />
      )}
      {activeBlock === terms.Synoptic.addPortfolio && <LibraryButton />}
      {activeBlock === terms.Synoptic.createFondPlan
        && !showObjectPanel && !showObjectCreationPanel && <SynopticActionsButtons />}
      {(activeBlock === terms.Synoptic.createFondPlan)
      && (ObjectPositionMode || ObjectOrientationMode || ObjectDeletionMode) && <ObjectsActionsButtons />}
      {activeBlock === terms.Synoptic.addPortfolio && !showPanel && ObjectPositionMode && (
        <LabelActionsButtons />
      )}
    </>
  )
}
