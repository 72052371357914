import { ReactElement, useState, useEffect } from 'react'
import { Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import ActionButton from 'components/common/Buttons/ActionButton'
import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { addComment, modifyComment } from 'reducers/MultiYearPlan/Comments/comments.thunk'
import {
  updateComments, addNewComment,
} from 'reducers/MultiYearPlan/Comments/comments'
import { RootState } from 'Store'
import { Comment } from 'reducers/MultiYearPlan/Comments/types'
import terms from 'common/terms'
import CommentsVersionsFields from './CommentsVersionsFields'
import ContextMenu from './ContextMenu'

export default function CommentsVersions(): ReactElement {
  const dispatch = useDispatch()
  const {
    comments, newCommentContext, updateCommentContext, commentsLoaded, CommentOnCreation,
  } = useSelector((state: RootState) => state.mypcomments)
  const { activeFile } = useSelector((state: RootState) => state.files)
  const { displayExportPdfModal } = useSelector((state: RootState) => state.mypExportPdf)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [activeComment, setActiveComment] = useState<null | Comment>(null)
  const [commentIndex, setCommentIndex] = useState<null | number>(null)
  const [disableBtn, setDisableBtn] = useState<boolean>(true)

  useEffect(() => {
    if (comments.length === 0 && commentsLoaded) {
      const newComment: Comment = {
        index: comments.length + 1,
        date: '',
        entity: '',
        author: '',
        comment: '',
      }
      dispatch(updateComments(comments.concat(newComment)))
      dispatch(addNewComment({
        displayAddButton: true,
        newCommentIndex: comments.length + 1,
      }))
    }
  }, [comments])

  const handleClick = (comment : Comment) => {
    dispatch(addComment({
      index: comment.index,
      date: comment.date,
      entity: comment.entity,
      author: comment.author,
      comment: comment.comment,
      repertoryId: activeFile.directory,
      folderId: activeFile.folder,
      fileId: activeFile.id,
    }))
  }

  const handleModify = (comment : Comment) => {
    dispatch(modifyComment({
      commentId: comment.id,
      index: comment.index,
      date: comment.date,
      entity: comment.entity,
      author: comment.author,
      comment: comment.comment,
      repertoryId: activeFile.directory,
      folderId: activeFile.folder,
      fileId: activeFile.id,
    }))
  }

  const handleOpen = (event: React.MouseEvent<HTMLElement>, comment: Comment, index: number) => {
    event.preventDefault()
    setAnchorEl(event.currentTarget)
    setActiveComment(comment)
    setCommentIndex(index)
  }

  const disableAddBtn = (e: boolean) => {
    setDisableBtn(e)
  }

  return (
    <div className="comments-container">
      <ContextMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        comment={activeComment}
        commentIndex={commentIndex}
      />
      {comments.map((comment: Comment, index: number) => (
        <Row key={comment.id} className="comment-row" onContextMenu={e => handleOpen(e, comment, index)}>
          <CommentsVersionsFields
            disableAddBtn={disableAddBtn}
            comment={comment}
            commentIndex={index}
            disabledInput={(newCommentContext.displayAddButton && newCommentContext.newCommentIndex === index + 1)
              || (updateCommentContext.displayUpdateButton && updateCommentContext.updateCommentIndex === index)}
          />
          {((newCommentContext.displayAddButton && newCommentContext.newCommentIndex === index + 1)
          || (comments.length === 0)) && (
          <ActionButton
            text={terms.Common.add}
            onClick={() => handleClick(comment)}
            buttonStyle={`${displayExportPdfModal ? 'd-none' : 'myp-import-portfolio-btn'}`}
            disabled={disableBtn}
          />
          )}
          {((!CommentOnCreation && comments[0]?.id !== '' && !updateCommentContext.displayUpdateButton)) && (
          <IconButton
            aria-label="add"
            onClick={e => handleOpen(e, comment, index)}
            className="pb-3"
            size="small"
            style={{ backgroundColor: 'transparent' }}
            sx={{
              color: 'black', backgroundColor: 'white', borderRadius: 0, marginLeft: 1,
            }}
          >
            <MoreVertIcon fontSize="small" />
          </IconButton>
          )}
          {updateCommentContext.displayUpdateButton && updateCommentContext.updateCommentIndex === index && (
          <ActionButton
            text={terms.Common.update}
            onClick={() => handleModify(comment)}
            buttonStyle={`${displayExportPdfModal ? 'd-none' : 'myp-import-portfolio-btn'}`}
            disabled={disableBtn}
          />
          )}
        </Row>
      ))}
    </div>
  )
}
