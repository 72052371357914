import { get } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkApi } from 'types'
import { GetLabelsParams, GetLabelsResponse } from './types'

const getLabels = createAsyncThunk<GetLabelsResponse, GetLabelsParams, ThunkApi>(
  'library/getLabels',
  async (params: GetLabelsParams, thunkApi) => {
    try {
      const response = await get(`/module_schematique/projects/${params.projectId}/labels/`,
        { lib: params.library, page: params.page })
      return {
        labelsList: response.results.features,
        library: params.library,
        page: response.next === null ? 1 : params.page + 1,
        count: response.count,
      }
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const LibraryService = {
  getLabels,
}

export default LibraryService
