import { RootState } from 'Store'
import UpdateModal from 'components/common/Modals/UpdateModal'
import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toggleDisplaySettingsPanel } from 'reducers/MultiYearPlan/Settings/settings'
import { toggleUpdateTimeRange } from 'reducers/MultiYearPlan/multiYearPlan'
import terms from 'common/terms'
import { Typography } from '@mui/material'
import { resetValidateTemporalChange, toggleDisplayUpdateTemporalModal } from 'reducers/Files/files'
import { patchFile } from 'reducers/Files/files.thunk'
import { displayTabContent } from '../utils'

export default function UpdateTemporalPerimeterModal(): ReactElement {
  const dispatch = useDispatch()
  const {
    displayUpdateTemporalModal, activeFile, responseError, timeRange, validateTemporalChange,
  } = useSelector((state: RootState) => state.files)
  const { tabsList } = useSelector((state: RootState) => state.dashboard)
  const { activeRepertory } = useSelector((state: RootState) => state.repertories)
  const { activeFolder } = useSelector((state: RootState) => state.folders)

  useEffect(() => {
    if (validateTemporalChange) {
      dispatch(toggleDisplaySettingsPanel(false))
      dispatch(toggleUpdateTimeRange(false))
      displayTabContent(tabsList, activeFile, activeRepertory, activeFolder)
      dispatch(resetValidateTemporalChange())
    }
  }, [validateTemporalChange])

  const handleClose = () => {
    dispatch(toggleDisplaySettingsPanel(false))
    dispatch(toggleUpdateTimeRange(false))
    dispatch(toggleDisplayUpdateTemporalModal(false))
    displayTabContent(tabsList, activeFile, activeRepertory, activeFolder)
  }

  const handleContinue = () => {
    dispatch(patchFile({
      repertoryId: activeFile.directory,
      folderId: activeFile.folder,
      file: { id: activeFile.id, time_range: timeRange, confirm_change: true },
    }))
    dispatch(toggleDisplayUpdateTemporalModal(false))
  }

  return (
    <UpdateModal
      open={displayUpdateTemporalModal}
      onModalClose={handleClose}
      modalTitle={terms.File.Declaration.Schema.updateTemporalPerimeter}
      content={(
        <Typography sx={{ padding: '1.5rem !important' }}>
          {responseError && typeof responseError.message.time_range === 'string'
            ? responseError.message.time_range : ''}
        </Typography>
        )}
      onUpdate={handleContinue}
      actionType={terms.Common.continue}
    />
  )
}
