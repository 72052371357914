/* eslint-disable react/no-array-index-key */
import { ReactElement, useEffect } from 'react'
import { Accordion, Dropdown } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { updateLibraryLabel } from 'reducers/library'
import { RootState } from 'Store'
import { AiOutlineEllipsis } from 'react-icons/ai'
import { updateClickedFeatureInfo } from 'reducers/map'
import terms from 'common/terms'
import FiltersModal from '../Library/FiltersModal'
import { Library } from '../Library/types'
import UpdateLabel from '../LabelTools/UpdateLabel'
import DeleteLabel from '../LabelTools/DeleteLabel'

export default function LibraryPanel(): ReactElement {
  const dispatch = useDispatch()
  const {
    importedLabels, createdLabels, notPositionedLabels, importedLabelsCount, createdLabelsCount,
    notPositionedLabelsCount, libraryLabel, displayLibrary,
  } = useSelector((state: RootState) => state.library)
  const { clickedFeatureInfo } = useSelector((state: RootState) => state.map)

  useEffect(() => {
    if (!displayLibrary || clickedFeatureInfo) {
      dispatch(updateLibraryLabel(undefined))
    }
  }, [displayLibrary, clickedFeatureInfo])

  const librariesList: Library[] = [
    {
      category: terms.Portfolio.Library.importedLabels,
      values: importedLabels,
      count: importedLabelsCount,
    },
    {
      category: terms.Portfolio.Library.createdLabels,
      values: createdLabels,
      count: createdLabelsCount,
    },
    {
      category: terms.Portfolio.Library.notPositionedLabels,
      values: notPositionedLabels,
      count: notPositionedLabelsCount,
    },
  ]

  const handleClick = (feature: GeoJSON.Feature) => {
    dispatch(updateLibraryLabel(feature))
    dispatch(updateClickedFeatureInfo(undefined))
  }

  return (
    <div className="panel">
      <FiltersModal />
      <Accordion className="library-accordion">
        {librariesList.map((library: Library) => (
          <Accordion.Item
            key={library.category}
            eventKey={library.category}
          >
            <Accordion.Header>
              {library.category}
              {' '}
              <p>{`(${library.count})`}</p>
            </Accordion.Header>
            <Accordion.Body>
              {library.values.map((feature: GeoJSON.Feature) => (
                <>
                  <div
                    role="button"
                    tabIndex={0}
                    className={`library-labels ${(libraryLabel && libraryLabel.id === feature.id)
                       || (clickedFeatureInfo && clickedFeatureInfo.properties.object_id === feature.id)
                      ? ' library-label-outline' : ''}`}
                    onClick={() => handleClick(feature)}
                  >
                    <div>{feature.properties?.description}</div>
                    <Dropdown autoClose="outside" drop="end">
                      <Dropdown.Toggle size="sm" variant="outline-light" className="card-tools-btn" id="dropdown-basic">
                        <AiOutlineEllipsis />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item as="button">
                          <UpdateLabel closePopup={() => null} />
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <DeleteLabel closePopup={() => null} />
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  )
}
