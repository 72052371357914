/* eslint-disable no-self-assign */
import {
  Classification, OperationsLabels, OperationsYear, Operations,
} from './types'

export const formatData = (formated: Operations) => {
  formated?.years?.forEach((year: OperationsYear, yindex: number) => (
    year.classifications?.forEach((classification: Classification, cindex: number) => (
      classification.operation_labels.forEach((operation: OperationsLabels, index: number) => {
        operation.data = [
          {
            x: operation.data[0].x,
            label: operation.label,
            x_label_offset: operation.x_label_offset,
            y_label_offset: operation.y_label_offset,
            ponctual: operation.data[0].x - operation.data[1].x,
            operationId: operation.operation_id,
            labelId: operation.label_id,
            yearIndex: yindex,
            classificationIndex: cindex,
            classificationId: classification.classification_id,
            operationIndex: index,
            display: true,
            trackId: classification.track_id,
          },
          {
            x: operation.data[1].x,
            label: operation.label,
            x_label_offset: operation.x_label_offset,
            y_label_offset: operation.y_label_offset,
            ponctual: operation.data[0].x - operation.data[1].x,
            operationId: operation.operation_id,
            labelId: operation.label_id,
            yearIndex: yindex,
            classificationIndex: cindex,
            classificationId: classification.classification_id,
            operationIndex: index,
            display: false,
            trackId: classification.track_id,
          },
        ]
        operation.id = index + 1
        operation.color = operation.color
      })
    ))
  ))
  return formated
}

export default formatData
