import { ReactElement, useEffect, useState } from 'react'
import {
  Form, InputGroup, Row, Col,
} from 'react-bootstrap'
import ActionButton from 'components/common/Buttons/ActionButton'
import { useDispatch, useSelector } from 'react-redux'
import { toggleShowObjectCreationPanel, toggleShowObjectPanel } from 'reducers/object'
import { IoIosArrowRoundBack } from 'react-icons/io'
import { AiOutlineExclamationCircle } from 'react-icons/ai'
import { RootState } from 'Store'
import ObjectService from './ObjectService'

import './objetcs.scss'

export default function ObjectCreationPanel(): ReactElement {
  const dispatch = useDispatch()
  const { activeProject } = useSelector((state: RootState) => state.creation)
  const { createObjectError, endOfAction } = useSelector((state: RootState) => state.app)
  const { objectlngLat, newObjectParam } = useSelector((state: RootState) => state.object)
  const [labelDescription, setLabelDescription] = useState('')
  const [kmDescription, setKmDescription] = useState('')
  const [voltageLevel, setVoltageLevel] = useState('')

  const objectType = newObjectParam.draggableId.split('/')[0]
  const objectLayer = newObjectParam.draggableId.split('/')[1]
  const objectFormat = newObjectParam.draggableId.split('/')[3]
  const objectObject = newObjectParam.draggableId.split('/')[4]

  const handleClick = () => {
    dispatch(toggleShowObjectCreationPanel(false))
    dispatch(toggleShowObjectPanel(true))
  }

  const setParams = (type: any) => (
    {
      description: labelDescription,
      start_year: 2000,
      end_year: 2001,
      locked: false,
      type: type || null,
      angle: 90,
      pk_sncf: kmDescription,
      voltage: voltageLevel,
    }
  )

  const onLabelAction = () => {
    const object = {
      projectId: activeProject.id,
      layer: objectLayer,
      params: {
        geometry: {
          type: objectFormat,
          coordinates: objectlngLat,
        },
        properties: setParams(objectType),
      }
      ,
    }
    dispatch(ObjectService.createObject(object))
  }

  useEffect(() => {
    if (endOfAction) {
      dispatch(toggleShowObjectCreationPanel(false))
      dispatch(toggleShowObjectPanel(true))
    }
  }, [endOfAction])

  const onLabelTextChange = (value: string) => {
    setLabelDescription(value)
  }

  const onKmTextChange = (value: string) => {
    setKmDescription(value)
  }

  const onVoltageChange = (value: string) => {
    setVoltageLevel(value)
  }

  return (
    <div className="panel">
      <div className="panel-header">
        <div className="panel-header-icon action-cursor-pointer">
          <IoIosArrowRoundBack onClick={handleClick} />
        </div>
        <div className="panel-header-text">
          Nouveau
          {' '}
          {newObjectParam.draggableId.split('/')[2]}
        </div>
      </div>
      <Form className="panel-form" autoComplete="off">
        <Row className="mr-0 pl-3 pr-2">
          <Col>
            <Form.Group
              controlId="exampleForm.ControlInput1"
            >
              {objectObject !== 'TE'
              && (
              <>
                <Form.Label className="input-label">Libellé</Form.Label>
                <InputGroup hasValidation className="input-field">
                  <Form.Control
                    onChange={e => onLabelTextChange(e.target.value)}
                    placeholder="Entrez un libellé"
                  />
                </InputGroup>
              </>
              )}
              {objectObject === 'SIGNAL'
              && (
              <>
                <Form.Label className="input-label mt-3">Km</Form.Label>
                <InputGroup hasValidation className="input-field">
                  <Form.Control
                    onChange={e => onKmTextChange(e.target.value)}
                    placeholder="Entrez un km à afficher"
                  />
                </InputGroup>
                {createObjectError && (
                <Form.Label className="input-label mt-3 text-danger">
                  <AiOutlineExclamationCircle className="mr-1" />
                  Format non valide
                </Form.Label>
                )}
              </>
              )}
              {objectObject === 'TE'
              && (
              <>
                <Form.Label className="input-label mt-3">Voltage</Form.Label>
                <InputGroup hasValidation className="input-field">
                  <Form.Select aria-label="Default select example" onChange={e => onVoltageChange(e.target.value)}>
                    <option selected disabled>Sélectionnez un voltage</option>
                    <option value="750">750 V</option>
                    <option value="800">800 V</option>
                    <option value="850">850 V</option>
                    <option value="1500">1,5 kV</option>
                    <option value="3000">3 kV</option>
                    <option value="15000">15 kV</option>
                    <option value="25000">25 kV</option>
                  </Form.Select>
                </InputGroup>
              </>
              )}

            </Form.Group>
          </Col>
        </Row>
      </Form>
      <div className="d-flex justify-content-center">
        <ActionButton
          text="Créer l'objet"
          onClick={onLabelAction}
          buttonStyle="validation-btn"
        />
      </div>
    </div>
  )
}
