import { get } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { store } from 'Store'
import axios from 'axios'
import { FeatureCollection } from '@nebula.gl/edit-modes'
import { GeoEditorState } from './reducer'

export type Configuration = {
  callback: string;
  // eslint-disable-next-line camelcase
  geom_path: string;
}

export type ConfigurationResponse = {
  data: FeatureCollection;
  configuration: Configuration;
}

const getConfiguration = createAsyncThunk<ConfigurationResponse, string>(
  'geoEditor/getConfiguration',
  async (configUrl: string) => {
    const res: Configuration = await get(configUrl)
    const { data } = await axios.get(res.geom_path, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      params: {
        page_size: 200000,
      },
    })
    return {
      data,
      configuration: res,
    }
  },
)

const getConfiguration2 = createAsyncThunk<ConfigurationResponse, string>(
  'geoEditor/getConfiguration',
  async (configUrl: string) => {
    const item = store.getState().creation
    const data = await get(configUrl, { project_id: item.activeProject.id })
    return {
      data,
      configuration: data,
    }
  },
)

const saveGeometry = createAsyncThunk(
  'geoEditor/saveGeometry',
  async (url: string) => {
    const { geoJson } = store.getState().geoEditor as GeoEditorState
    await axios.post(url, geoJson, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
  },
)

const GeoEditorService = {
  getConfiguration,
  getConfiguration2,
  saveGeometry,
}

export default GeoEditorService
