/* eslint-disable import/prefer-default-export */
import PathNames from 'common/PathNames'
import { Files } from 'reducers/Files/types'
import { Folder } from 'reducers/Folders/types'
import { Repertory } from 'reducers/Repertories/types'

export const getPath = (activeRepertory: Repertory, activeFolder: Folder, activeFile: Files): string => {
  if (activeFile) {
    if (activeFile.folder !== null) {
      return `${PathNames.RepertoryContent}/${activeFile.directory}/${PathNames.Folder}/${activeFile.folder}`
    }
    return `${PathNames.RepertoryContent}/${activeFile.directory}`
  } if (activeRepertory && activeFolder && !activeFile) {
    return `${PathNames.RepertoryContent}/${activeRepertory.id}/${PathNames.Folder}/${activeFolder.id}`
  } if (activeRepertory && !activeFolder && !activeFile) {
    return `${PathNames.RepertoryContent}/${activeRepertory.id}`
  }
  return '/'
}
