import { ReactElement, useEffect } from 'react'
import { Card, CardHeader, Grid } from '@mui/material'
import { RootState } from 'Store'
import { useDispatch, useSelector } from 'react-redux'
import { Operation } from 'reducers/MultiYearPlan/OperationActions/types'
import { setSelectedPositionedOperation } from 'reducers/MultiYearPlan/Library/library'
import PositionedOperationActionsMenu from './PositionedOperationActionsMenu'

export default function PositionedOperationsList(): ReactElement {
  const dispatch = useDispatch()
  const { positionedOperations, selectedPositionedOperation } = useSelector((state: RootState) => state.mypLibrary)
  const { selectedOperation } = useSelector((state: RootState) => state.mypoperationactions)

  const handleOutsideClick = () => {
    dispatch(dispatch(setSelectedPositionedOperation(undefined)))
  }
  useEffect(() => {
    if (selectedPositionedOperation) {
      document.addEventListener('click', handleOutsideClick, true)
    }
    return () => {
      document.removeEventListener('click', handleOutsideClick, true)
    }
  }, [selectedPositionedOperation])

  return (
    <>
      <Grid
        item
        container
        spacing={1}
        className="operation-card-container"
      >
        {positionedOperations.map((operation: Operation) => (
          <Grid item key={operation.id} sx={{ justifyContent: 'center' }}>
            <Card
              onClick={() => dispatch(setSelectedPositionedOperation(operation))}
              onBlur={() => dispatch(setSelectedPositionedOperation(undefined))}
              className={`operation-card ${selectedPositionedOperation?.id === operation.id
                || selectedOperation?.id === operation.id ? ' library-operation-highlight' : ''}`}
              sx={{ cursor: 'pointer' }}
            >
              <CardHeader
                action={<PositionedOperationActionsMenu operation={operation} />}
                title={operation.label}
              />
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  )
}
