import { ReactElement } from 'react'
import terms from 'common/terms'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import DeleteModal from 'components/common/Modals/DeleteModal'
import { toggleDisplayDeleteObjectFamily } from 'reducers/MultiYearPlan/Import/ObjectsFamilies/objectsFamilies'
import { deleteClassification } from 'reducers/MultiYearPlan/Import/ObjectsFamilies/objectsFamilies.thunk'

export default function DeleteObjectFamilyModal():
 ReactElement {
  const dispatch = useDispatch()
  const { activeFile } = useSelector((state: RootState) => state.files)
  const { displayDeleteObjectFamily, activeObjectFamily } = useSelector((state: RootState) => state.objectsFamilies)

  const onModalClose = () => {
    dispatch(toggleDisplayDeleteObjectFamily(false))
  }

  const onDelete = () => {
    if (activeFile) {
      dispatch(deleteClassification({
        repertoryId: activeFile.directory,
        folderId: activeFile.folder,
        fileId: activeFile.id,
        classificationId: activeObjectFamily.id,
      }))
    }
  }

  return (
    <DeleteModal
      open={displayDeleteObjectFamily}
      onModalClose={onModalClose}
      modalTitle={terms.MultiYearPlan.Import.Actions.deleteObjectFamily}
      modalNotice={terms.MultiYearPlan.Import.Actions.deleteObjectFamilyNotice}
      onDelete={onDelete}
    />
  )
}
