import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import Slider from '@mui/material/Slider'
import AddPrButton from './AddPrButton'
import ZoomButtons from './ZoomButtons'

type Props ={
  handleChange: (e: any) => void
}

export default function ToolsButtons({ handleChange }: Props): ReactElement {
  const { zoomPr } = useSelector((state: RootState) => state.remarkablePoints)
  const { displayLibraryPanel } = useSelector((state: RootState) => state.mypLibrary)
  const { displaySettingsPanel } = useSelector((state: RootState) => state.mypSettings)

  const getMaxSliderValue = () => {
    if (displayLibraryPanel) {
      return zoomPr - (window.innerWidth - 530)
    }
    if (displaySettingsPanel && !displayLibraryPanel) {
      return zoomPr - (window.innerWidth - 500)
    }
    return zoomPr - (window.innerWidth - 250)
  }
  return (
    <div className="myp-tools-container" data-html2canvas-ignore>
      <AddPrButton />
      <ZoomButtons />
      <Slider
        defaultValue={0}
        min={0}
        step={1}
        max={Math.abs(getMaxSliderValue())}
        aria-label="Default"
        valueLabelDisplay="off"
        onChange={handleChange}
        sx={{ width: '30%' }}
      />
    </div>
  )
}
