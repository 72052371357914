import { ReactElement, useEffect, useState } from 'react'
import {
  Grid, Autocomplete,
  Box, Modal, Typography,
  TextField, Checkbox,
} from '@mui/material'
import terms from 'common/terms'
import ActionButton from 'components/common/Buttons/ActionButton'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { User } from 'reducers/Cerbere/types'
import { getGroups, getGroupMembers, shareDocument } from 'reducers/Cerbere/cerbere.thunk'
import { resetResponseError, toggleDisplayShareModal } from 'reducers/Files/files'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import Loader from 'components/Loader'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

export default function ShareFileModal():
 ReactElement {
  const dispatch = useDispatch()
  const { displayShareModal, contextFile } = useSelector((state: RootState) => state.files)
  const { services, users, isLoading } = useSelector((state: RootState) => state.cerbere)
  const [userID, setUserID] = useState<User[]>([])

  const onModalClose = () => {
    dispatch(toggleDisplayShareModal(false))
    dispatch(resetResponseError())
    setUserID([])
  }

  useEffect(() => {
    if (displayShareModal) {
      dispatch(getGroups('module-schematique'))
      setUserID([])
    }
  }, [displayShareModal])

  useEffect(() => {
    if (displayShareModal && services.count > 0) {
      dispatch(getGroupMembers(services.results[0].groups[0].id))
    }
  }, [services])

  const onShare = () => {
    const usersIdList = userID.map(item => item.id)
    dispatch(shareDocument({
      repertoryId: contextFile.directory,
      folderId: contextFile.folder,
      fileId: contextFile.id,
      usersId: usersIdList,
    }))
  }

  const handleChange = (newValue: User[]) => {
    setUserID(newValue)
  }

  const usersInput = () => (
    <Grid item mb={5} className="name-input">
      <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        options={users.results}
        disableCloseOnSelect
        onChange={(_event, newValue) => handleChange(newValue)}
        getOptionLabel={(option: User) => `${option.firstName} ${option.lastName}`}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.firstName}
            {' '}
            {option.lastName}
          </li>
        )}
        className="share-user-list-autocomplete"
        renderInput={params => (
          <TextField {...params} placeholder={terms.File.Actions.sharePlaceholder} />
        )}
      />
    </Grid>
  )

  return (
    <Modal
      open={displayShareModal}
      onClose={onModalClose}
      disableEscapeKeyDown
    >
      <Box className="action-modal">
        {isLoading && <Loader center />}
        <Typography variant="h6" component="h1">
          {terms.File.Actions.share}
        </Typography>
        <Typography sx={{ mt: 2, mb: 3 }}>
          {terms.File.Actions.shareNotice}
        </Typography>
        {usersInput()}
        <div className="modal-action-btn">
          <ActionButton
            text={terms.Common.cancel}
            onClick={onModalClose}
            buttonStyle="cancel-action-btn"
          />
          <ActionButton
            text={terms.Common.share}
            onClick={onShare}
            buttonStyle="update-btn"
            disabled={userID.length === 0}
          />
        </div>
      </Box>
    </Modal>
  )
}
