import terms from 'common/terms'
import { PageFormat, PageOrientation } from 'components/Export/types'
import { InputTypes } from 'types'

export type ExportPdfInput = {
    key: string
    label: string
    placeholder?: string
    fieldType?: string
    options: string[]
}

export type ExportPdfCheckbox = {
  key: string
  label: string
}

export const ExportPdfInputFields: ExportPdfInput[] = [
  {
    key: 'pageFormat',
    label: terms.MultiYearPlan.ExportPdf.inputs.pageFormat.label,
    placeholder: terms.MultiYearPlan.ExportPdf.inputs.pageFormat.placeHolder,
    fieldType: InputTypes.Select,
    options: [PageFormat.A3, PageFormat.A4],
  },
  {
    key: 'pageNumber',
    label: terms.MultiYearPlan.ExportPdf.inputs.pageNumber.label,
    placeholder: terms.MultiYearPlan.ExportPdf.inputs.pageNumber.placeHolder,
    fieldType: InputTypes.Select,
    options: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
  },
  {
    key: 'pageOrientation',
    label: terms.MultiYearPlan.ExportPdf.inputs.pageOrientation.label,
    placeholder: terms.MultiYearPlan.ExportPdf.inputs.pageOrientation.placeHolder,
    fieldType: InputTypes.SimpleField,
    options: [PageOrientation.portrait, PageOrientation.landscape],
  },
]

export const ExportPdfCheckboxFields: ExportPdfCheckbox[] = [
  /// waiting for clear instratuctions on this one ///
  /* {
    key: 'greenOption',
    label: terms.MultiYearPlan.ExportPdf.checkboxes.greenOption.label,
  }, */
  {
    key: 'includeTileblock',
    label: terms.MultiYearPlan.ExportPdf.checkboxes.includeTileBlock.label,
  },
  {
    key: 'includeLegend',
    label: terms.MultiYearPlan.ExportPdf.checkboxes.includeLegend.label,
  },
]
