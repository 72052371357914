/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { ReactElement } from 'react'
import { Form } from 'react-bootstrap'
import { InputDataType } from './utils'

import './input.scss'

export default function SimpleSelect({
  field, value, onChange,
}: InputDataType): ReactElement {
  return (
    <Form.Select value={value} onChange={onChange} className="label-form-select">
      <option value="" />
      {field.values?.map((option, index) => (
        typeof option === 'string'
          ? <option key={index}>{option}</option>
          : <option key={option.value}>{option.label}</option>
      ))}
    </Form.Select>
  )
}
