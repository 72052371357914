import terms from 'common/terms'
import { InputField, InputTypes } from 'types'

const PHASES_FIELD: InputField[] = [
  {
    key: 'phase_travaux',
    label: terms.Portfolio.Library.Filters.workPhase,
    hideLabel: true,
    placeholder: terms.Common.search,
    required: false,
    inputType: InputTypes.Select,
    withSearch: true,
  },
  {
    key: 'date',
    label: terms.Portfolio.Library.Filters.date,
    hideLabel: true,
    placeholder: terms.Common.search,
    required: false,
    inputType: InputTypes.Select,
    withSearch: true,
    multiple: true,
  },
]

export default PHASES_FIELD
