import terms from 'common/terms'

export type ImportFields = {
    label: string
    placeholder: string
}

export const WorkPhaseFields: ImportFields[] = [
  {
    label: terms.MultiYearPlan.Import.yearColumn,
    placeholder: terms.MultiYearPlan.Import.yarColunmHeader,
  },
  {
    label: terms.Inputs.phase,
    placeholder: terms.Inputs.selectPhasePlaceholder,
  },
]

export const FormatFields: ImportFields[] = [
  {
    label: terms.MultiYearPlan.Import.startYearColumn,
    placeholder: terms.MultiYearPlan.Import.startYearColumnHeader,
  },
  {
    label: terms.MultiYearPlan.Import.endYearColumn,
    placeholder: terms.MultiYearPlan.Import.endYearColumnHeader,
  },
]

export const FerrolocalizationFields: ImportFields[] = [
  {
    label: terms.Inputs.lineNumber,
    placeholder: terms.Inputs.lineAttributPlacehoalder,
  },
  {
    label: terms.Inputs.requiredTrackName,
    placeholder: terms.Inputs.trackAttributPlacehoalder,
  },
  {
    label: terms.Inputs.pkDebut,
    placeholder: terms.Inputs.pkDebutAttributPlacehoalder,
  },
  {
    label: terms.Inputs.pkFin,
    placeholder: terms.Inputs.pkFinAttributPlacehoalder,
  },
]
