import terms from 'common/terms'
import ActionButton from 'components/common/Buttons/ActionButton'
import { TASKS_NAMES, TASKS_STATUS } from 'components/Dashboard/Creation/types'
import { getTaskStatus } from 'components/Dashboard/Creation/utils'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateExportAlert } from 'reducers/export'
import { RootState } from 'Store'
import ExportService from '../ExportService'
import { getFile } from '../utils'

import './exportBtn.scss'

export default function ExportSvg(): ReactElement {
  const dispatch = useDispatch()
  const { cartridgeTitle, exportedProject, displayCartridge } = useSelector((state: RootState) => state.export)
  const [exportSvg, setExportSvg] = useState(false)

  useEffect(() => {
    if (exportedProject && exportSvg
       && getTaskStatus(exportedProject, TASKS_NAMES.Export) === TASKS_STATUS.Success) {
      getFile(exportedProject.last_svg_url)
      dispatch(updateExportAlert(false))
      setExportSvg(false)
    } else if (getTaskStatus(exportedProject, TASKS_NAMES.Export) === TASKS_STATUS.Failure) {
      dispatch(updateExportAlert(false))
    }
  }, [exportedProject])

  useEffect(() => {
    if (exportedProject && exportSvg) {
      setTimeout(() => {
        dispatch(ExportService.getExportStatus(exportedProject.id))
      }, 1000)
    }
  })

  const handleClick = () => {
    if (exportedProject) {
      setExportSvg(true)
      dispatch(ExportService.exportProject({
        projectId: exportedProject.id,
        params: {
          file_name: cartridgeTitle,
          file_extension: 'svg',
          complete: true,
          include_cbdp: displayCartridge,
        },
      }))
      dispatch(updateExportAlert(true))
    }
  }

  return (
    <ActionButton
      text={terms.Export.exportSvg}
      onClick={handleClick}
      buttonStyle="export-btn export-svg-btn"
    />
  )
}
