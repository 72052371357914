import { ReactElement } from 'react'
import TbElementLabelInput from './TbElementLabelInput'
import TbElementTypeChoice from './TbElementTypeChoice'
import FerrolocalizationFieldsContainer from './FerrolocalizationFieldsContainer'

export default function TbElementFieldsContainer(): ReactElement {
  return (
    <div className="operation-modal-body operation-fields">
      <TbElementLabelInput />
      <TbElementTypeChoice />
      <FerrolocalizationFieldsContainer />
    </div>
  )
}
