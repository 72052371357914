import { ReactElement } from 'react'
import { Source, Layer } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import MAIN_API from 'config/config'
import { ObjectsLayer } from '../types'
import { trackNameLayer, lineNumberLayer } from './CommonLayers.json'

const sourceLayer = ObjectsLayer.tiv

export default function TivLayer(): ReactElement {
  const { activeProject } = useSelector((state: RootState) => state.creation)

  return (
    <Source
      type="vector"
      url={`${MAIN_API.proxy}/chartis/v2/layer/${sourceLayer}/mvt/sch/?project_id=${activeProject?.id}`}
      source-layer={sourceLayer}
      id={sourceLayer}
    >
      <Layer
        id={`${sourceLayer}-layer`}
        type="line"
        paint={{
          'line-color': ['case',
            ['==', ['get', 'type_voie'], 'VP'], '#1976D2',
            '#6A6C6E',
          ],
          'line-width': ['case',
            ['==', ['get', 'type_voie'], 'VP'], 2,
            1,
          ],
          'line-opacity': 1,
        }}
        layout={{ visibility: 'visible' }}
        source-layer={sourceLayer}
      />
      <Layer
        {...{
          ...lineNumberLayer,
          layout: {
            ...lineNumberLayer.layout,
            'text-field': '{code_ligne}',
            visibility: 'visible',
          },
        }}
        id="schLineNumLayer"
        filter={['==', 'type_voie', 'VP']}
        type="symbol"
        source-layer={sourceLayer}
      />
      <Layer
        {...{
          ...trackNameLayer,
          layout: {
            ...trackNameLayer.layout,
            'text-field': '{nom_voie}',
            'text-size': 14,
            visibility: 'visible',
          },
        }}
        id="schNameVPLayer"
        filter={['==', 'type_voie', 'VP']}
        type="symbol"
        source-layer={sourceLayer}
      />
      <Layer
        {...{
          ...trackNameLayer,
          layout: {
            ...trackNameLayer.layout,
            'text-field': '{nom_voie}',
            'text-size': 10,
            visibility: 'visible',
          },
        }}
        id="schNameVSLayer"
        filter={['!=', 'type_voie', 'VP']}
        type="symbol"
        source-layer={sourceLayer}
      />
    </Source>

  )
}
