import terms from 'common/terms'
import { SearchFields } from 'components/Portfolio/types'
import { updateSearchValues } from 'reducers/import'
import { store } from 'Store'

const getImportDocumentText = () => {
  const { portfolio, showImportLoader } = store.getState().import
  if (portfolio && !showImportLoader) {
    if (portfolio.eligibility === '') return terms.Portfolio.Import.eligibilityGridTitle
    if (portfolio.category === '') return terms.Portfolio.Import.categoryTitle
    return ''
  }
  return ''
}

export const getTitle = () => {
  const {
    settingsNoticeDisplay, activeStep, ferrolocalizationNoticeDisplay,
  } = store.getState().import

  switch (activeStep) {
    case -1:
      return terms.Portfolio.Import.summary
    case 0:
      return getImportDocumentText()
    case 1:
      return settingsNoticeDisplay ? terms.Portfolio.Import.settingsNoticeTitle : terms.Portfolio.Import.settingsTitle
    case 2:
      return ferrolocalizationNoticeDisplay ? terms.Portfolio.Import.attributsValidationNoticeTitle
        : terms.Portfolio.Import.attributsValidationTitle
    default:
      return ''
  }
}

const getImportDocumentNotice = () => {
  const { portfolio, showImportLoader } = store.getState().import
  if (portfolio && !showImportLoader) {
    if (portfolio.eligibility === '') return terms.Portfolio.Import.eligibilityGridNotice
    if (portfolio.category === '') return terms.Portfolio.Import.categoryNotice
    return ''
  }
  if (showImportLoader) return terms.Portfolio.Import.importLoadingNotice
  return ''
}

const getSettingsNotice = () => {
  const { portfolio } = store.getState().import
  if (portfolio) {
    if (portfolio.displayed_columns.length === 0) {
      return terms.Portfolio.Import.noSettingsNotice
    }
    return terms.Portfolio.Import.settingsChoiceNotice
  }
  return ''
}

export const getNotice = () => {
  const {
    settingsNoticeDisplay, activeStep, ferrolocalizationNoticeDisplay, showImportLoader,
  } = store.getState().import
  switch (activeStep) {
    case 0:
      return getImportDocumentNotice()
    case 1:
      return settingsNoticeDisplay ? terms.Portfolio.Import.settingsNotice : getSettingsNotice()
    case 2:
      return ferrolocalizationNoticeDisplay ? terms.Portfolio.Import.attributsValidationNotice : ''
    case 3:
      return showImportLoader ? terms.Portfolio.Import.importLoadingNotice : ''
    default:
      return ''
  }
}

export const setSearchValues = (v: string, index: number | undefined) => {
  const { searchValues } = store.getState().import
  if (searchValues.filter((search: SearchFields) => search.index === index).length === 0) {
    store.dispatch(updateSearchValues(searchValues.concat({ index, value: v })))
  } else {
    store.dispatch(updateSearchValues((searchValues.filter((search: SearchFields) => search.index !== index))
      .concat({ index, value: v })))
  }
}

export const getSelectedValue = (value: string, index: number | undefined) => {
  const { searchValues } = store.getState().import
  const actualSearchValue = searchValues.filter((search: SearchFields) => search.index === index)
  if (value !== '' && actualSearchValue.length !== 0 && actualSearchValue[0].value === value) {
    return [value] as string[]
  }
  if (actualSearchValue.length !== 0) {
    return [actualSearchValue[0].value] as string[]
  }
  return []
}
