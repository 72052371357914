/* eslint-disable react/no-array-index-key */
import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'
import { PrFields } from 'reducers/RemarkablePoints/types'
import { Typography, IconButton, FormHelperText } from '@mui/material'
import terms from 'common/terms'
import {
  toggleDeleteFerrolocField, updateFerrolocFields,
} from 'reducers/MultiYearPlan/OperationActions/operationactions'
import { OperationFerrolocFields, OperationFerroloc } from 'reducers/MultiYearPlan/OperationActions/types'
import { setResponseError } from 'reducers/MultiYearPlan/Library/library'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import FerrolocalizationInputs from './FerrolocalizationInputs'

export default function FerrolocalizationFieldsContainer(): ReactElement {
  const dispatch = useDispatch()
  const {
    ferrolocFields, selectedOperation, responseError,
  } = useSelector((state: RootState) => state.mypoperationactions)
  const { selectedDefectedOperation, libraryResponseError } = useSelector((state: RootState) => state.mypLibrary)

  useEffect(() => {
    if (ferrolocFields.length === 0) {
      dispatch(updateFerrolocFields([{
        index: 1,
        ferroloc: {
          line_code: '',
          track: '',
          start_kp: '',
          end_kp: '',
        },
      }]))
    }
  }, [ferrolocFields])

  useEffect(() => {
    if (selectedOperation) {
      const existingFerrolocFields: OperationFerrolocFields[] = []
      selectedOperation.railway_locations.forEach((ferrolocs: OperationFerroloc, ferroIndex: number) => {
        existingFerrolocFields.push({
          index: ferroIndex + 1,
          ferroloc: {
            line_code: ferrolocs.line_code,
            track: ferrolocs.track,
            start_kp: ferrolocs.start_kp,
            end_kp: ferrolocs.end_kp,
          },
        })
      })
      dispatch(updateFerrolocFields(existingFerrolocFields))
    }
    if (selectedDefectedOperation) {
      const existingFerrolocFields: OperationFerrolocFields[] = []
      selectedDefectedOperation.defected_railway_locations.forEach((ferrolocs: OperationFerroloc,
        ferroIndex: number) => {
        existingFerrolocFields.push({
          index: ferroIndex + 1,
          ferroloc: {
            line_code: ferrolocs.line_code,
            track: ferrolocs.track,
            start_kp: ferrolocs.start_kp,
            end_kp: ferrolocs.end_kp,
          },
        })
      })
      dispatch(updateFerrolocFields(existingFerrolocFields))
    }
  }, [])

  const handleClick = (index: number) => {
    const newFerrolocFields: OperationFerrolocFields[] = []
    ferrolocFields.forEach((ferroloc: OperationFerrolocFields) => {
      if (ferroloc.index !== index) {
        newFerrolocFields.push({ ...ferroloc, index: ferroloc.index > index ? ferroloc.index - 1 : ferroloc.index })
      }
    })
    dispatch(updateFerrolocFields(newFerrolocFields))
    dispatch(toggleDeleteFerrolocField(true))
    if (selectedDefectedOperation && libraryResponseError) {
      const newErrors = {
        ...libraryResponseError,
        message: {
          ...libraryResponseError.message,
          railway_locations: libraryResponseError.message.railway_locations.filter((_error: string,
            errorIndex: number) => (errorIndex !== index - 1)),
        },
      }
      dispatch(setResponseError(newErrors))
    }
  }

  const onFerrolocAdd = () => {
    const newFerrolocField: OperationFerrolocFields[] = [{
      index: ferrolocFields.length + 1,
      ferroloc: {
        line_code: '',
        track: '',
        start_kp: '',
        end_kp: '',
      },
    }]
    dispatch(updateFerrolocFields(ferrolocFields.concat(newFerrolocField)))
  }

  return (
    <div className="ferroloc-phase-container">
      <div className="ferroloc-phase-header">
        <Typography>
          {terms.MultiYearPlan.Operations.ferroloc}
        </Typography>
        <div>
          <IconButton onClick={onFerrolocAdd}><AddIcon /></IconButton>
        </div>
      </div>
      {((libraryResponseError && libraryResponseError.message.railway_locations
           && Object.keys(libraryResponseError.message.railway_locations).length === 1
           && typeof libraryResponseError.message.railway_locations[0] === 'string')
           || (responseError && responseError.message.railway_locations
           && Object.keys(responseError.message.railway_locations).length === 1
           && typeof responseError.message.railway_locations[0] === 'string'))
           && (
           <div className="input-error-container">
             <ErrorOutlineIcon className="mr-1" />
             <FormHelperText>
               {libraryResponseError?.message.railway_locations || responseError?.message.railway_locations}
             </FormHelperText>
           </div>
           )}
      {ferrolocFields.map((ferroloc: PrFields, index: number) => (
        <div key={`${ferroloc.index}-${index}`} className="d-flex">
          <FerrolocalizationInputs ferrolocIndex={ferroloc.index} />
          {ferrolocFields.length > 1 && (
          <div className={`d-flex ${responseError || libraryResponseError ? ' error-remove-btn'
            : 'align-items-center'}`}
          >
            <IconButton onClick={() => handleClick(ferroloc.index)}><RemoveIcon /></IconButton>
          </div>
          )}
        </div>
      ))}
    </div>
  )
}
