import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { Chip, Grid, Tooltip } from '@mui/material'
import { ObjectFamily, SubClassification } from 'reducers/MultiYearPlan/Import/ObjectsFamilies/type'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import {
  patchSubClassification,
  patchSubClassificationDrop,
} from 'reducers/MultiYearPlan/Import/ObjectsFamilies/objectsFamilies.thunk'
import { updateSubCLassificationDraggedId } from 'reducers/MultiYearPlan/Import/ObjectsFamilies/objectsFamilies'
import { onDragOver } from './Actions/utils'

export default function OperationsContainer({ objectFamily }: {objectFamily: ObjectFamily}): ReactElement {
  const dispatch = useDispatch()
  const { activeFile } = useSelector((state: RootState) => state.files)
  const { subClassificationDraggedId } = useSelector((state: RootState) => state.objectsFamilies)

  const handleDelete = (id: number) => {
    if (activeFile) {
      dispatch(patchSubClassification({
        repertoryId: activeFile.directory,
        folderId: activeFile.folder,
        fileId: activeFile.id,
        classificationId: objectFamily.id,
        subClassificationId: id,
        classification: {
          classification: null,
        },
      }))
    }
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>, id: number) => {
    if (objectFamily.id && objectFamily.id !== -1) {
      e.preventDefault()
      e.stopPropagation()
      dispatch(patchSubClassificationDrop({
        repertoryId: activeFile.directory,
        folderId: activeFile.folder,
        fileId: activeFile.id,
        classificationId: objectFamily.id,
        subClassificationId: subClassificationDraggedId,
        classification: {
          classification: id,
        },
      }))
    }
  }

  return (
    <Grid item xs={9}>
      <div
        className="operations-container p-3"
        id={objectFamily.id as unknown as string}
        onDragOver={e => onDragOver(e)}
        onDrop={e => handleDrop(e, objectFamily.id)}
      >
        {objectFamily?.sub_classifications?.map((subClassification: SubClassification) => (
          <Tooltip key={subClassification.id} title={subClassification.label} placement="top">
            <Chip
              className="m-1"
              label={subClassification.code}
              onDelete={() => handleDelete(subClassification.id)}
              deleteIcon={<CloseRoundedIcon />}
              draggable
              onDragStart={() => dispatch(updateSubCLassificationDraggedId(subClassification.id))}
              style={{ backgroundColor: objectFamily.color }}
            />
          </Tooltip>
        ))}
      </div>
    </Grid>
  )
}
