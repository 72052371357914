import { ReactElement, useState } from 'react'
import {
  Accordion, AccordionDetails, AccordionSummary, Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export default function OperationsAccordion({ summary, operationsCount, content }:
   {summary: string, operationsCount: number, content: ReactElement}): ReactElement {
  const [expanded, setExpanded] = useState<boolean>(false)
  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)} className="library-panel-accordion">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography>
          {summary}
          <Typography className="operations-count">{`(${operationsCount})`}</Typography>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{content}</AccordionDetails>
    </Accordion>
  )
}
