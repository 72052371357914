import { Feature as RawFeature } from 'geojson'
import {
  DrawPolygonMode, DrawRectangleMode, FeatureCollection, Position,
} from '@nebula.gl/edit-modes'
// eslint-disable-next-line import/no-unresolved
import { CompositeLayerProps } from '@deck.gl/core/lib/composite-layer'

export const EXPANSION_KM = 50
export const LAYER_ID_GEOJSON = 'selection-geojson'
export const LAYER_ID_BLOCKER = 'selection-blocker'

export const SELECTION_TYPE = {
  NONE: null,
  RECTANGLE: 'rectangle',
  POLYGON: 'polygon',
}

export const MODE_MAP = {
  [SELECTION_TYPE.RECTANGLE]: DrawRectangleMode,
  [SELECTION_TYPE.POLYGON]: DrawPolygonMode,
}

export const MODE_CONFIG_MAP = {
  [SELECTION_TYPE.RECTANGLE]: { dragToDraw: true },
}

export const EMPTY_DATA = {
  type: 'FeatureCollection',
  features: [],
}

export type EditParams = {
  updatedData: FeatureCollection;
  editType: string;
}

export type PickingInfo = {
  index: number; // index in the whole FeatureCollection
  object: RawFeature;
  layer: {
    id?: string;
  };
}

export type SelectParams = {
  pickingInfos?: PickingInfo[];
  coordinates: Position[][];
}

export interface SelectionLayerProps extends CompositeLayerProps<FeatureCollection>{
  layerIds: string[];
  onSelect: (info: SelectParams) => void;
  selectionType: string | null;
}
