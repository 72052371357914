export const OBJECT_FAMILY_COLORS_LIST = [
  '#6d17e7',
  '#ae5098',
  '#e71791',
  '#e519c6',
  '#e21c2e',
  '#cc4632',
  '#e3a51b',
  '#397fc5',
  '#278ad7',
  '#5ca2a0',
  '#80ad51',
  '#00ffa4',
  '#32ccba',
]

export default OBJECT_FAMILY_COLORS_LIST
