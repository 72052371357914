import { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import terms from 'common/terms'
import {
  toggleDisplayAddOperationModal, updateSelectedOperation,
} from 'reducers/MultiYearPlan/OperationActions/operationactions'
import AddOperationModal from '../Modals/AddOperationModal'

export default function AddNewObjectButton(): ReactElement {
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(updateSelectedOperation(undefined))
    dispatch(toggleDisplayAddOperationModal(true))
  }
  return (
    <>
      <Button
        startIcon={<AddIcon />}
        onClick={handleClick}
        className="myp-add-operation-btn"
      >
        {terms.MultiYearPlan.Operations.addOperation}
      </Button>
      <AddOperationModal />
    </>
  )
}
