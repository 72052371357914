/* eslint-disable no-self-assign */
import React from 'react'
import { Line, Layer, CustomLayerProps } from '@nivo/line'
import _ from 'lodash'
import nextId from 'react-id-generator'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { OperationsYear, Classification } from 'reducers/MultiYearPlan/Operations/types'
import '../multiYearPlan.scss'

function LineChartClassifications() {
  const { Operations } = useSelector((state: RootState) => state.mypoperations)

  const formated = _.cloneDeep(Operations)
  formated?.years?.forEach((year: OperationsYear) => (
    year.classifications.forEach((classification: Classification, index) => {
      classification.data = [
        {
          x: 0,
          y: classification.order === 0 ? 10 : classification.order * 10 + 10,
          label: classification.label,
          trackLabel: classification.track_label,
        },
        {
          x: 100,
          y: classification.order === 0 ? 10 : classification.order * 10 + 10,
        },
      ]
      classification.id = index + 1
    })
  ))

  function Points({ points }: CustomLayerProps) {
    return (
      <g>
        {points.map((point: any) => (
          <g transform={`translate(${point.x},${point.y + 22})`} key={point.id}>
            <text
              dominantBaseline="middle"
              style={{
                fontSize: 16,
                fontFamily: 'sans-serif',
                translate: '5px -22px',
                textAnchor: 'initial',
                color: 'black',
              }}
            >
              {point.data.label}
              {' '}
              {point.data.trackLabel}
            </text>
          </g>
        ))}
      </g>
    )
  }

  return (
    <>
      <div className="line-chart-container-cl" id="line-chart-container-cl">
        <div className="code-line-header" />
        {formated?.years?.map((year: OperationsYear) => (
          <React.Fragment key={nextId()}>
            <div className="pl-2 year-bar"><span>{year.year}</span></div>
            <Line
              width={250}
              height={year.classifications.length * 40}
              data={year.classifications}
              margin={{
                top: 20, right: 0, bottom: 20, left: 0,
              }}
              xScale={{
                type: 'linear',
              }}
              yScale={{
                type: 'point',
              }}
              yFormat=" >-.2f"
              colors="#f3f5f8"
              axisBottom={null}
              axisTop={null}
              axisRight={null}
              axisLeft={null}
              enableGridX={false}
              enableGridY={false}
              tooltip={() => (
                <div className="line-chart-tooltip" />
              )}
              useMesh
              lineWidth={38}
              layers={[
                'grid',
                'axes',
                'areas',
                'lines',
                'slices',
                'mesh',
                'legends',
                Points,
              ] as Layer[]}
            />
          </React.Fragment>
        ))}
      </div>
    </>
  )
}
export default LineChartClassifications
