import { createAsyncThunk } from '@reduxjs/toolkit'
import { patch, post } from '@osrdata/app_core/dist/requests'
import { ThunkApi } from 'types'
import { PatchParams, Portfolio, PostParams } from './types'
import { GetParams } from '../types'

const postRepertoryPortfolio = createAsyncThunk<Portfolio, PostParams, ThunkApi>(
  'mypImport/postRepertoryPortfolio',
  async (params: PostParams, thunkApi) => {
    try {
      const response = await
      // eslint-disable-next-line max-len
      post(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/portfolio/`,
        params.portfolio)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const postFolderPortfolio = createAsyncThunk<Portfolio, PostParams, ThunkApi>(
  'mypImport/postFolderPortfolio',
  async (params: PostParams, thunkApi) => {
    try {
      const response = await
      // eslint-disable-next-line max-len
      post(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.fileId}/portfolio/`,
        params.portfolio)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const patchRepertoryPortfolio = createAsyncThunk<Portfolio, PatchParams, ThunkApi>(
  'mypImport/patchRepertoryPortfolio',
  async (params: PatchParams, thunkApi) => {
    try {
      const response = await
      // eslint-disable-next-line max-len
      patch(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/portfolio/`,
        params.portfolio)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const patchFolderPortfolio = createAsyncThunk<Portfolio, PatchParams, ThunkApi>(
  'mypImport/patchFolderPortfolio',
  async (params: PatchParams, thunkApi) => {
    try {
      const response = await
      // eslint-disable-next-line max-len
      patch(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.fileId}/portfolio/`,
        params.portfolio)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const generateClassifications = createAsyncThunk<null, GetParams, ThunkApi>(
  'operations/generateClassifications',
  async (params: GetParams, thunkApi) => {
    if (params.folderId && params.folderId !== null) {
      try {
        await
        // eslint-disable-next-line max-len
        post(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.fileId}/generate-classifications/`, { regenerate: false })
        return null
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response = await
        // eslint-disable-next-line max-len
        post(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/generate-classifications/`, { regenerate: false })
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

const regenerateClassifications = createAsyncThunk<null, GetParams, ThunkApi>(
  'operations/regenerateClassifications',
  async (params: GetParams, thunkApi) => {
    if (params.folderId && params.folderId !== null) {
      try {
        await
        // eslint-disable-next-line max-len
        post(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.fileId}/generate-classifications/`, { regenerate: true })
        return null
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response = await
        // eslint-disable-next-line max-len
        post(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/generate-classifications/`, { regenerate: true })
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

export {
  postRepertoryPortfolio,
  postFolderPortfolio,
  patchRepertoryPortfolio,
  patchFolderPortfolio,
  generateClassifications,
  regenerateClassifications,
}
