import { ReactElement } from 'react'
import { MapEvent, Popup } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import terms from 'common/terms'
import LabelsContextMenu from './LabelsContextMenu'
import ObjectsContextMenu from './ObjectsContextMenu'

import './contextMenu.scss'

type Props = {
  event: MapEvent
  reset: () => void
  setMapHover: (e: MapEvent | undefined) => void
}

export default function ContextMenu({
  event, reset, setMapHover,
}: Props): ReactElement | null {
  const { activeBlock } = useSelector((state: RootState) => state.synoptic)
  const coords = event.lngLat

  return (
    <Popup
      longitude={coords[0]}
      latitude={coords[1]}
      closeButton={false}
      className="popup-wrapper"
      tipSize={0}
      anchor="top-left"
      captureScroll
      captureClick
      capturePointerMove
      onPointerMove={() => setMapHover(undefined)}
      onClose={() => reset()}
    >
      {activeBlock === terms.Synoptic.addPortfolio
        && <LabelsContextMenu closePopup={reset} />}
      {activeBlock === terms.Synoptic.createFondPlan
        && <ObjectsContextMenu closePopup={reset} />}
    </Popup>
  )
}
