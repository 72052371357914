import { Point } from '@nebula.gl/edit-modes'
import { EditableGeoJsonLayer } from '@nebula.gl/layers'
import { Feature } from '@turf/helpers'
import { RGBAColor } from 'deck.gl'
import { store } from 'Store'
import { GeoEditorState } from '../reducer'
import { Viewport } from '../snapping/updateSnappingFeatures'
import { EditorLayerModes } from '../utils'
import { SelectedPoints } from './CustomModifyMode'
import onEdit from './onEdit'

type HandleProperties = {
  featureIndex: number;
  positionIndexes: [number] | [number, number];
}

const getEditHandlePointColor = (
  handle: Feature<Point, HandleProperties>,
  selectedPoints: SelectedPoints,
): RGBAColor => {
  const selectedPoint = selectedPoints?.find(sp => sp.featureIndex === handle.properties.featureIndex)
  const positionIndex = handle.properties.positionIndexes[handle.properties.positionIndexes.length - 1]
  if (selectedPoint?.points[positionIndex]) return [180, 0, 0]
  return [100, 100, 100]
}

const getEditHandlePointColorAll = (
): RGBAColor => [180, 0, 0]

const createEditionLayer = (viewport: Viewport | undefined): EditableGeoJsonLayer => {
  const {
    selectedPoints, geoJson, mode, selectedFeatures, editorLayerModeName, layersVisibility,
  } = store.getState().geoEditor as GeoEditorState

  return new EditableGeoJsonLayer({
    id: 'geojson',
    data: geoJson,
    mode: EditorLayerModes(mode, selectedPoints)[editorLayerModeName],
    selectedFeatureIndexes: selectedFeatures,
    onEdit: editAction => (viewport ? onEdit(editAction, viewport) : undefined),
    visible: layersVisibility.editionLayer,
    pickable: true,
    getEditHandlePointColor: (handle: Feature<Point, HandleProperties>) => (
      selectedPoints?.length === geoJson?.features.length
        ? getEditHandlePointColorAll()
        : getEditHandlePointColor(handle, selectedPoints)
    ),
    updateTriggers: {
      getEditHandlePointColor: [selectedPoints],
    },
    getLineColor: (feature: Feature) => (feature.properties?.type_voie === 'VP' ? [64, 64, 64] : [192, 192, 192]),
    getLineWidth: (feature: Feature) => (feature.properties?.type_voie === 'VP' ? 3 : 2),
  })
}

export default createEditionLayer
