import { Divider } from '@mui/material'
import FormInput from 'components/common/Inputs/FormInput'
import _ from 'lodash'
import { ReactElement, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { InputTypes } from 'types'

import './library.scss'
import { getSelectedValue, setSearchValues } from './utils'

const ADD_FILTER_FIELD = {
  key: 'add_filter',
  label: 'addFilter',
  hideLabel: true,
  placeholder: 'Inputs.addFilterPlacehoalder',
  required: false,
  inputType: InputTypes.Select,
  withSearch: true,
}

export default function AddFilterInput(): ReactElement {
  const { importedPortfolio } = useSelector((state: RootState) => state.import)
  const [newFilter, setNewFilter] = useState('')

  const handleChange = (value: string) => {
    setNewFilter(value)
  }

  return (
    <div className="add-filter-container">
      <Divider
        orientation="horizontal"
        className="divider"
        sx={{ m: 0.5, color: '#E6EAEE', margin: '6px 0px' }}
      />
      <Form className="library-form mt-2" autoComplete="off">
        <FormInput
          field={{
            ...ADD_FILTER_FIELD,
            values: importedPortfolio
              ? _.orderBy(Object.keys(importedPortfolio.filters), item => item, ['asc']) : [],
          }}
          onChange={v => handleChange(v)}
          value={newFilter}
          extraStyle="px-2"
          index={-1}
          setSearchValues={v => setSearchValues(v, -1)}
          getSelectedValue={() => getSelectedValue(newFilter, -1)}
        />
      </Form>
    </div>
  )
}
