import { ReactElement } from 'react'
import {
  Box, Grid, IconButton, Tooltip, Typography,
} from '@mui/material'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import CloseIcon from '@mui/icons-material/Close'

import './container.scss'

type Props = {
  onRollBack: () => void
  rollBackText: string
  onClose: () => void
  tooltip?: boolean
  tooltipTitle?: string
  displayRollBackBtn?: boolean
  displayCloseBtn?: boolean
}
export default function ContainerHeader({
  onRollBack, rollBackText, onClose, tooltip, tooltipTitle, displayRollBackBtn, displayCloseBtn,
}: Props): ReactElement {
  return (
    <Box className="container-header">
      <Grid container className={displayRollBackBtn ? '' : 'flex-row-reverse'}>
        {displayRollBackBtn && (
        <Grid item>
          <IconButton onClick={onRollBack}>
            <KeyboardBackspaceIcon />
          </IconButton>
          <Typography onClick={onRollBack} className="action-cursor-pointer">{rollBackText}</Typography>
        </Grid>
        )}
        {displayCloseBtn && (
        <Grid item>
          {tooltip ? (
            <Tooltip title={tooltipTitle as string} placement="left-start">
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          )}
        </Grid>
        )}
      </Grid>
    </Box>
  )
}

ContainerHeader.defaultProps = {
  tooltip: false,
  tooltipTitle: '',
  displayRollBackBtn: true,
  displayCloseBtn: true,
}
