import { ReactElement } from 'react'
import ActionButton from 'components/common/Buttons/ActionButton'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { updateAreaFields } from 'reducers/label'
import terms from 'common/terms'
import { AreaFields } from '../types'

export default function AddTupleButton(): ReactElement {
  const dispatch = useDispatch()
  const { areaFields } = useSelector((state: RootState) => state.label)

  const handleClick = () => {
    const newAreaFields: Array<AreaFields> = [{
      index: areaFields.length + 1,
      area: {
        code_ligne: '',
        nom_voie: '',
        pk_debut: '',
        pk_fin: '',
      },
    }]
    dispatch(updateAreaFields(areaFields.concat(newAreaFields)))
  }

  return (
    <ActionButton
      text={terms.Portfolio.addTuple}
      leftIcon
      leftIconStyle="mr-2"
      onClick={handleClick}
      buttonStyle="add-tuple-btn"
    />
  )
}
