import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { ResponseError } from 'types'
import {
  MypTitleBlockActionsState, ContextPosition, CartoucheElementFerroloc,
} from './types'
import {
  deleteTbElement, getTbElement, getTbElementTypes, patchTbElement,
  postTbElement,
} from './titleblockactions.thunk'
import { CartoucheElement } from '../TitleBlock/types'

const initialState: MypTitleBlockActionsState = {
  openTbContextMenu: false,
  contextPosition: {
    x: 0,
    y: 0,
  },
  displayDeleteModal: false,
  displayModifyModal: false,
  displayAddModal: false,
  elementLabel: '',
  emptyFields: false,
  tbElementTypes: [],
  elementTypeValue: '',
  isLoading: false,
}

export const mypTitleBlockActionsSlice = createSlice({
  name: 'mypTitleBlockActions',
  initialState,
  reducers: {
    resetObjectsFileds: state => {
      state.responseError = undefined
      state.elementLabel = ''
      state.ferroLocFields = undefined
      state.elementTypeValue = ''
      state.selectedTbElement = undefined
    },
    toggleOpenTbContextMenu: (state, action: PayloadAction<boolean>) => {
      state.openTbContextMenu = action.payload
    },
    updateContextPosition: (state, action: PayloadAction<ContextPosition>) => {
      state.contextPosition = action.payload
    },
    updateSelectedTbElement: (state, action: PayloadAction<CartoucheElement>) => {
      state.selectedTbElement = action.payload
    },
    updateElementLabel: (state, action: PayloadAction<string>) => {
      state.elementLabel = action.payload
    },
    toggleDisplayDeleteModal: (state, action: PayloadAction<boolean>) => {
      state.displayDeleteModal = action.payload
    },
    toggleDisplayModifyModal: (state, action: PayloadAction<boolean>) => {
      state.displayModifyModal = action.payload
    },
    toggleDisplayAddModal: (state, action: PayloadAction<boolean>) => {
      state.displayAddModal = action.payload
    },
    toggleEmptyFields: (state, action: PayloadAction<boolean>) => {
      state.emptyFields = action.payload
    },
    updateResponseError: (state, action: PayloadAction<ResponseError | undefined>) => {
      state.responseError = action.payload
    },
    updateSelectedElement: (state, action: PayloadAction<CartoucheElement | undefined>) => {
      state.selectedTbElement = action.payload
    },
    updateElementTypeValue: (state, action: PayloadAction<string>) => {
      state.elementTypeValue = action.payload
    },
    updateFerrolocFields: (state, action: PayloadAction<CartoucheElementFerroloc>) => {
      state.ferroLocFields = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getTbElement.fulfilled, (state, action) => {
      state.selectedTbElement = action.payload
    })
    builder.addCase(getTbElementTypes.fulfilled, (state, action) => {
      state.tbElementTypes = action.payload
    })
    builder.addCase(patchTbElement.fulfilled, state => {
      state.displayModifyModal = false
      state.selectedTbElement = undefined
      state.responseError = undefined
      state.elementLabel = ''
      state.ferroLocFields = undefined
      state.elementTypeValue = ''
    })
    builder.addCase(postTbElement.fulfilled, state => {
      state.displayAddModal = false
      state.selectedTbElement = undefined
      state.responseError = undefined
      state.elementLabel = ''
      state.ferroLocFields = undefined
      state.elementTypeValue = ''
    })
    builder.addCase(deleteTbElement.fulfilled, state => {
      state.displayDeleteModal = false
      state.selectedTbElement = undefined
      state.isLoading = false
    })
    builder.addCase(deleteTbElement.pending, state => {
      state.isLoading = true
    })
    builder.addMatcher(isAnyOf(
      patchTbElement.rejected,
      postTbElement.rejected,
      deleteTbElement.rejected,
    ), (state, action) => {
      state.responseError = action.payload
      state.isLoading = false
    })
  },
})
export const {
  toggleOpenTbContextMenu, updateContextPosition, updateSelectedTbElement,
  toggleDisplayDeleteModal, toggleDisplayModifyModal, updateElementLabel, toggleEmptyFields,
  updateResponseError, updateSelectedElement, resetObjectsFileds, updateElementTypeValue,
  updateFerrolocFields, toggleDisplayAddModal,
} = mypTitleBlockActionsSlice.actions
export default mypTitleBlockActionsSlice.reducer
