import { ReactElement, useState } from 'react'
import {
  Autocomplete, Chip, InputAdornment, TextField,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { Files, FileTypes } from 'reducers/Files/types'
import terms from 'common/terms'
import { getAllFilesList } from 'reducers/MultiYearPlan/multiYearPlan.thunk'
import { resetAllFilesList, updateFileToImport } from 'reducers/MultiYearPlan/multiYearPlan'

export default function ImportFile(): ReactElement {
  const dispatch = useDispatch()
  const { allFilesList } = useSelector((state: RootState) => state.multiYearPlan)
  const [selectedValue, setSelectedValue] = useState<Files | null>(null)
  const [inputValue, setInputValue] = useState<string>('')

  return (
    <Autocomplete
      freeSolo
      options={allFilesList}
      getOptionLabel={option => {
        if (typeof option === 'string') {
          return option
        }
        if (option.name) {
          return option.name
        }
        return option.name
      }}
      value={selectedValue}
      onChange={(_evt, newValue) => {
        if (typeof newValue === 'string') {
          setSelectedValue(null)
        } else if (newValue && newValue.name) {
          setSelectedValue(newValue)
          dispatch(updateFileToImport(newValue))
        } else {
          setSelectedValue(newValue)
        }
      }}
      inputValue={inputValue}
      onInputChange={(_evt, newInputValue) => {
        setInputValue(newInputValue)
        if (newInputValue === '') {
          dispatch(resetAllFilesList())
        } else {
          dispatch(getAllFilesList(newInputValue))
        }
      }}
      renderOption={(props, option) => (
        <li
          {...props}
          tabIndex={-1}
          key={option.id}
          className="options-container action-cursor-pointer"
        >
          {option.name}
          {' '}
          <Chip
            size="small"
            className="action-cursor-pointer"
            label={option.type === FileTypes.Schema ? terms.Common.multiYearSchema : terms.Common.synoptic}
            sx={{ backgroundColor: option.type === FileTypes.Schema ? '#FCF3B9' : '#CAF2CB' }}
          />
        </li>
      )}
      renderInput={params => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            placeholder: terms.File.Declaration.Schema.searchBarPlaceholder,
          }}
        />
      )}
      className="file-search-bar"
    />
  )
}
