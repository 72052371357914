import { ReactElement } from 'react'
import { Button, Typography } from '@mui/material'
import terms from 'common/terms'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveStep } from 'reducers/MultiYearPlan/Import/import'
import history from 'customHistory'
import PathNames from 'common/PathNames'
import { RootState } from 'Store'
import { CreationLevels } from 'components/Dashboard/types'
import {
  toggleUpdateMypSections, toggleUpdateTimeRange, updateCreationStatus,
} from 'reducers/MultiYearPlan/multiYearPlan'
import { updateObjectsFamilies } from 'reducers/MultiYearPlan/Import/ObjectsFamilies/objectsFamilies'
import { MypImportStepsList } from '../Import/types'
import { getPath } from '../../../utils'

export default function ImportSettings(): ReactElement {
  const dispatch = useDispatch()
  const { activeFile } = useSelector((state: RootState) => state.files)
  const { activeRepertory } = useSelector((state: RootState) => state.repertories)
  const { activeFolder } = useSelector((state: RootState) => state.folders)

  const handleGeographicPerimeterUpdate = () => {
    dispatch(toggleUpdateMypSections(true))
    dispatch(updateCreationStatus(CreationLevels.Temporal))
    // eslint-disable-next-line max-len
    history.push(`${getPath(activeRepertory, activeFolder, activeFile)}/${PathNames.FileSchema}/${PathNames.GeographicPerimeter}`)
  }

  const handleTemporalPerimeterUpdate = () => {
    dispatch(toggleUpdateTimeRange(true))
    dispatch(updateCreationStatus(CreationLevels.Name))
    // eslint-disable-next-line max-len
    history.push(`${getPath(activeRepertory, activeFolder, activeFile)}/${PathNames.FileSchema}/${PathNames.TemporalPerimeter}`)
  }

  const handleCodeTypoUpdate = () => {
    dispatch(setActiveStep(MypImportStepsList.Parametrage))
    history.push(PathNames.MypImport)
    dispatch(updateObjectsFamilies([]))
  }
  return (
    <>
      <Typography className="category-title">{terms.MultiYearPlan.SettingsPanel.importSettings}</Typography>
      <Button onClick={handleGeographicPerimeterUpdate}>
        {terms.MultiYearPlan.SettingsPanel.updateGeographicPerimeter}
      </Button>
      <Button onClick={handleTemporalPerimeterUpdate}>
        {terms.MultiYearPlan.SettingsPanel.updateTemporalPerimeter}
      </Button>
      <Button onClick={handleCodeTypoUpdate}>
        {terms.MultiYearPlan.SettingsPanel.updateCodeTypo}
      </Button>
    </>
  )
}
