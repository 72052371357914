import { ReactElement } from 'react'
import ActionButton from 'components/common/Buttons/ActionButton'

import './object.scss'
import { useDispatch } from 'react-redux'
import { toggleShowObjectPanel } from 'reducers/object'
import terms from 'common/terms'

export default function AddObject(): ReactElement {
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(toggleShowObjectPanel(true))
  }

  return (
    <ActionButton
      text={terms.Synoptic.Buttons.addObject}
      onClick={handleClick}
      buttonStyle="add-object-btn"
    />
  )
}
