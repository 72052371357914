/* eslint-disable react/destructuring-assignment */
import { AxisTickProps } from '@nivo/axes'
import {
  CustomLayerProps,
  Layer,
  Line,
} from '@nivo/line'
import { RootState } from 'Store'
import { DirectionValues } from 'components/Files/Creation/GeographicPerimeter/GeographicPerimeterInputFields'
import { useState, RefObject } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MYPSection } from 'reducers/MultiYearPlan/types'
import { toggleOpenPrChartContextMenu, updateContextPosition } from 'reducers/RemarkablePoints/remarkablePoints'
import { PR } from 'reducers/RemarkablePoints/types'
import '../multiYearPlan.scss'
import DeletePrModal from './DeletePrModal'
import PrActionsMenu from './PrActionsMenu'
import CustomPoint from './types'
import { getChartPrOpenPanelsStyle } from '../Settings/utils'

function LineChartPr({ PRChartRef2 } : {PRChartRef2 : RefObject<HTMLInputElement>}) {
  const dispatch = useDispatch()
  const { zoomPr, prList } = useSelector((state: RootState) => state.remarkablePoints)
  const { mypSectionsList } = useSelector((state: RootState) => state.multiYearPlan)
  const { displayLibraryPanel } = useSelector((state: RootState) => state.mypLibrary)
  const { displaySettingsPanel } = useSelector((state: RootState) => state.mypSettings)
  const [prInfo, setPrInfo] = useState<PR>()

  const toggleContextMenu = (point: any, event: React.MouseEvent<Element, MouseEvent>) => {
    if (point.serieId === 'pr') {
      dispatch(toggleOpenPrChartContextMenu(true))
      dispatch(updateContextPosition(event.pageX))
      setPrInfo({
        id: point.data.id,
        line_code: point.data.line_code,
        kp: point.data.kp,
        label: point.data.label,
        is_important: point.data.is_important,
      })
    }
  }

  const formatedSectionsList = mypSectionsList.map((item: MYPSection) => {
    const obj = {
      ...item,
      data: item.localizations,
    }
    return obj
  })

  const CustomTick = (tick: AxisTickProps<string>) => {
    let rks = 0
    let rkDebutSection = 0
    formatedSectionsList.forEach((element: any) => {
      if (tick.value as unknown as number >= element.data[0].x
        && tick.value as unknown as number <= element.data[1].x) {
        const xdebutsection = element.data[0].x
        if (element.direction === DirectionValues.Ascending) {
          rkDebutSection = +element.pk_sncf_debut.slice(0, -4)
          rks = (((tick.value as unknown as number - xdebutsection) * element.step) + rkDebutSection)
        } else {
          rkDebutSection = +element.pk_sncf_fin.slice(0, -4)
          rks = (rkDebutSection - ((tick.value as unknown as number - xdebutsection) * element.step))
        }
      }
    })

    return (
      <g transform={`translate(${tick.x},${tick.y + 22})`}>
        <line stroke="rgb(0, 0, 0)" strokeWidth={1} y1={-22} y2={-12} />
        <text
          textAnchor="middle"
          dominantBaseline="middle"
          style={{
            fill: '#333',
            fontSize: 12,
            transform: 'translate(0, -5px)',
            color: 'black',
          }}
        >
          {rks}
        </text>
      </g>
    )
  }

  const renderMarkerColor = (point: CustomPoint) => {
    if (point.serieId !== 'pr') {
      return 'red'
    }
    if (point.data.is_important) {
      return 'blue'
    }
    return 'black'
  }

  function Points({ points }: CustomLayerProps) {
    return (
      <g>
        {points.map((point: any) => (
          <g transform={`translate(${point.x},${point.y + 22})`} key={point.id}>
            <circle
              onClick={event => toggleContextMenu(point, event)}
              r="5"
              cy="-22"
              style={{
                fill: renderMarkerColor(point),
                cursor: point.serieId === 'pr' ? 'pointer' : 'default',
              }}
            />
            <text
              textAnchor="middle"
              dominantBaseline="middle"
              style={{
                fill: point.data.is_important ? 'blue' : 'black',
                fontSize: 14,
                transform: 'rotate(337deg)',
                fontFamily: 'sans-serif',
                translate: '0 -35px',
                textAnchor: 'initial',
                color: 'black',
              }}
            >
              {point.data.label}
            </text>
          </g>
        ))}
      </g>
    )
  }

  return (
    <div
      className={`${getChartPrOpenPanelsStyle(displaySettingsPanel, displayLibraryPanel)} line-chart-container-pr`}
      ref={PRChartRef2}
      id="line-chart-container-pr"
    >
      <PrActionsMenu prInfo={prInfo} />
      <DeletePrModal />
      <Line
        width={zoomPr}
        height={150}
        data={formatedSectionsList.concat(prList)}
        margin={{
          top: 100, right: 150, bottom: 40, left: 20,
        }}
        xScale={{
          type: 'linear',
          min: 0,
          max: mypSectionsList[mypSectionsList.length - 1]?.localizations[1]?.x,
        }}
        yScale={{
          type: 'point',
        }}
        axisBottom={{
          renderTick: CustomTick,
          tickValues: zoomPr / 50,
        }}
        axisTop={null}
        axisRight={null}
        axisLeft={null}
        yFormat=" >-.2f"
        colors="#41A8F9"
        enablePointLabel
        tooltip={() => (
          <div className="line-chart-tooltip" />
        )}
        useMesh
        lineWidth={10}
        layers={[
          'grid',
          'axes',
          'areas',
          'lines',
          'slices',
          'mesh',
          'legends',
          Points,
        ] as Layer[]}
      />
    </div>
  )
}
export default LineChartPr
