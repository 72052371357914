import { ReactElement } from 'react'
import terms from 'common/terms'
import ActionButton from 'components/common/Buttons/ActionButton'
import history from 'customHistory'
import PathNames from 'common/PathNames'
import { useDispatch } from 'react-redux'
import { toggleCreatedRepertory } from 'reducers/Repertories/repertories'

export default function RepertoryCreationButton(): ReactElement {
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(toggleCreatedRepertory())
    history.push(PathNames.Repertory)
  }
  return (
    <div className="d-flex justify-content-center">
      <ActionButton
        text={terms.HomePage.creationBtn}
        onClick={handleClick}
        buttonStyle="add-repertory-btn"
      />
    </div>
  )
}
