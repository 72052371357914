import {
  ReactElement, useEffect, useRef, useState,
} from 'react'
import { Paper, InputBase, Typography } from '@mui/material'
import { Folder } from 'reducers/Folders/types'
import { FcFolder } from 'react-icons/fc'

import './folder.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { patchFolder, postFolder } from 'reducers/Folders/folder.thunk'
import { setActionsContext, setActiveFolder, setEditionMode } from 'reducers/Folders/folder'
import history from 'customHistory'
import PathNames from 'common/PathNames'
import { showMenu } from '../utils'

export default function FolderCard({ folder }: {folder: Folder}): ReactElement {
  const dispatch = useDispatch()
  const { editableFolder, editionMode } = useSelector((state: RootState) => state.folders)
  const { activeRepertory } = useSelector((state: RootState) => state.repertories)
  const [folderName, setFolderName] = useState<string>(folder.label)

  const inputRef = useRef<HTMLInputElement>(null)

  const handleOutsideClick = (e: any) => {
    if (inputRef.current !== null && folder.id === editableFolder.id) {
      if (!inputRef.current.contains(e.target)) {
        const folderLabel = ((document.getElementById(`folder-${editableFolder.id}`)) as HTMLInputElement)?.value
        if (editableFolder.id === -1) {
          dispatch(postFolder({
            repertoryId: activeRepertory.id,
            folderLabel,
          }))
        } else if (folderLabel && folderLabel !== '') {
          dispatch(patchFolder({
            repertoryId: activeRepertory.id,
            folderId: editableFolder.id,
            folderLabel,
          }))
        } else {
          dispatch(setEditionMode(false))
          setFolderName(folder.label)
        }
      }
    }
  }

  useEffect(() => {
    if (inputRef !== null && editionMode && editableFolder) {
      document.addEventListener('click', handleOutsideClick, true)
      if (inputRef.current?.getElementsByTagName('textarea')[0].getAttribute('id') === `folder-${editableFolder.id}`) {
        inputRef.current?.getElementsByTagName('textarea')[0].focus()
      }
    }
    return () => {
      document.removeEventListener('click', handleOutsideClick, true)
    }
  }, [inputRef, editionMode])

  const handleDoubleClick = () => {
    dispatch(setActiveFolder(folder))
    history.push(`${PathNames.RepertoryContent}/${activeRepertory?.id}/${PathNames.Folder}/${folder?.id}`)
  }

  return (
    <Paper
      className="folder-card"
      onContextMenu={e => showMenu(e, false, folder)}
      onClick={() => dispatch(setActionsContext(false))}
      onDoubleClick={handleDoubleClick}
    >
      <FcFolder />
      <div>
        <InputBase
          value={folderName}
          onChange={e => setFolderName(e.target.value)}
          readOnly={!editionMode}
          autoFocus
          className={editableFolder?.id === folder.id && editionMode ? 'editable-input' : ''}
          ref={inputRef}
          id={`folder-${folder.id}`}
          multiline
        />
      </div>
      <Typography className="date-style">{new Date(folder.creation_date).toLocaleDateString()}</Typography>
    </Paper>
  )
}
