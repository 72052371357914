import { ReactElement } from 'react'
import { RootState } from 'Store'
import { useDispatch, useSelector } from 'react-redux'
import { setLabelToUpdate, toggleShowColorPanel } from 'reducers/label'
import terms from 'common/terms'
import ColorPanel from './ColorPanel'
import { ToolsProps } from './types'

export default function UpdateLabelColor({ closePopup }: ToolsProps): ReactElement {
  const dispatch = useDispatch()
  const { clickedFeatureInfo } = useSelector((state: RootState) => state.map)
  const { showColorPanel } = useSelector((state: RootState) => state.label)

  const handleClick = () => {
    dispatch(setLabelToUpdate(clickedFeatureInfo))
    dispatch(toggleShowColorPanel(true))
  }

  return (
    <>
      <div role="button" tabIndex={0} className="popup-items" onClick={handleClick}>{terms.Portfolio.changeColor}</div>
      {showColorPanel && <ColorPanel closePopup={closePopup} />}
    </>
  )
}
