import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { ResponseError } from 'types'
import {
  patchTbElement, postTbElement, deleteTbElement,
} from 'reducers/MultiYearPlan/TitleBlockActions/titleblockactions.thunk'
import {
  MypTitleBlockState,
} from './types'
import {
  getTitleBlock, getTitleBlockFilters,
  pacthTitleBlockFilter,
} from './titleblock.thunk'

const initialState: MypTitleBlockState = {
  TitleBlockFilters: [],
  TitleBlock: {
    cartouche_display: [],
    color: '',
  },
  updateTbChart: false,
}

export const mypTitleBlockSlice = createSlice({
  name: 'mypTitleBlock',
  initialState,
  reducers: {
    resetTitleBlock: state => {
      state.TitleBlock = {
        cartouche_display: [],
        color: '',
      }
    },
    updateResponseError: (state, action: PayloadAction<ResponseError | undefined>) => {
      state.responseError = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getTitleBlock.fulfilled, (state, action) => {
      state.TitleBlock = action.payload
    })
    builder.addCase(getTitleBlockFilters.fulfilled, (state, action) => {
      state.TitleBlockFilters = action.payload
    })
    builder.addCase(pacthTitleBlockFilter.fulfilled, (state, action) => {
      state.TitleBlockFilters = state.TitleBlockFilters.map(filter => (filter.id === action.payload.id
        ? action.payload : filter))
      state.updateTbChart = !state.updateTbChart
    })

    builder.addCase(patchTbElement.fulfilled, state => {
      state.updateTbChart = !state.updateTbChart
      state.responseError = undefined
    })
    builder.addCase(postTbElement.fulfilled, state => {
      state.updateTbChart = !state.updateTbChart
      state.responseError = undefined
    })
    builder.addCase(deleteTbElement.fulfilled, state => {
      state.updateTbChart = !state.updateTbChart
    })
    builder.addMatcher(isAnyOf(
      getTitleBlock.rejected,
      patchTbElement.rejected,
      postTbElement.rejected,
    ), (state, action) => {
      state.responseError = action.payload
    })
  },
})
export const {
  resetTitleBlock,
  updateResponseError,
} = mypTitleBlockSlice.actions
export default mypTitleBlockSlice.reducer
