import { ReactElement, useEffect } from 'react'
import { Grid } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { resetResponseError } from 'reducers/MultiYearPlan/Import/ObjectsFamilies/objectsFamilies'
import { getClassifications } from 'reducers/MultiYearPlan/Import/ObjectsFamilies/objectsFamilies.thunk'
import SnackBar from 'components/common/Snackbar/SnackBar'
import { ObjectFamily } from 'reducers/MultiYearPlan/Import/ObjectsFamilies/type'
import { getErrorMessage } from '../../utils'
import AddObjectFamilyButton from '../../Button/AddObjectFamilyButton'
import ObjectFamilyLabelInput from './ObjectFamilyLabelInput'
import TracksContainer from './TracksContainer'
import OperationsContainer from './OperationsContainer'
import ObjectFamilyColor from './ObjectFamilyColor'
import DeleteObjectFamilyButton from '../../Button/DeleteObjectFamilyButton'
import DeleteObjectFamilyModal from './Actions/DeleteObjectFamilyModal'

export default function ObjectsFamiliesContainer(): ReactElement {
  const dispatch = useDispatch()
  const {
    objectsFamilies, responseError, updateObjectsFamilies, deleteObjectFamily,
  } = useSelector((state: RootState) => state.objectsFamilies)
  const { regeneratingClassifications } = useSelector((state: RootState) => state.mypImport)
  const { activeFile } = useSelector((state: RootState) => state.files)

  useEffect(() => {
    if (activeFile) {
      dispatch(getClassifications({
        repertoryId: activeFile.directory,
        folderId: activeFile.folder,
        fileId: activeFile.id,
      }))
    }
  }, [updateObjectsFamilies, deleteObjectFamily, regeneratingClassifications])

  return (
    <Grid item xs={8} sx={{ height: '100%' }}>
      <div className="object-family-container">
        {objectsFamilies.map((family: ObjectFamily) => (
          <div key={`${family.id}`} className="family-param-container">
            <Grid container sx={{ flexWrap: 'nowrap' }}>
              <ObjectFamilyLabelInput objectFamily={family} />
              <TracksContainer objectFamily={family} />
            </Grid>
            <Grid container className="operation-grid-container">
              <OperationsContainer objectFamily={family} />
              {family.tracks?.length === 0 && <ObjectFamilyColor objectFamily={family} />}
              <DeleteObjectFamilyButton objectFamily={family} />
            </Grid>
          </div>
        ))}
      </div>
      <AddObjectFamilyButton />
      <DeleteObjectFamilyModal />
      <SnackBar
        message={getErrorMessage(responseError)}
        error={responseError}
        severity="warning"
        setError={resetResponseError}
      />
    </Grid>
  )
}
