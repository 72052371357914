import { get, patch, post } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkApi } from 'types'
import { GeneratedLabels, Portfolio } from './types'

const getPortfolio = createAsyncThunk<Portfolio, number, ThunkApi>(
  'import/getPortfolio',
  async (projectId: number, thunkApi) => {
    try {
      const response: Portfolio = await get(`/module_schematique/portefeuille_programmatique/${projectId}`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const uploadFile = createAsyncThunk<Portfolio, FormData, ThunkApi>(
  'import/uploadFile',
  async (params: FormData, thunkApi) => {
    try {
      const response: Portfolio = await post('/module_schematique/portefeuille_programmatique/',
        params)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

type PatchParams = {
    projectId: number
    portfolio: Portfolio
}

const updatePortfolio = createAsyncThunk<Portfolio, PatchParams, ThunkApi>(
  'import/updatePortfolio',
  async (params: PatchParams, thunkApi) => {
    try {
      const response: Portfolio = await patch(`/module_schematique/portefeuille_programmatique/${params.projectId}`,
        params.portfolio)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const generatePortfolio = createAsyncThunk<GeneratedLabels, number, ThunkApi>(
  'import/generatePortfolio',
  async (projectId: number, thunkApi) => {
    try {
      const response: GeneratedLabels = await
      post(`/module_schematique/portefeuille_programmatique/${projectId}/generate/`, {})
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const ImportService = {
  getPortfolio,
  uploadFile,
  updatePortfolio,
  generatePortfolio,
}

export default ImportService
