import { ReactElement, useEffect, useState } from 'react'
import terms from 'common/terms'
import { Autocomplete, TextField } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { Filter, FiltersChoices } from 'reducers/MultiYearPlan/Settings/types'
import { getFiltersList } from 'reducers/MultiYearPlan/Settings/settings.thunk'
import { updateAppliedFilters } from 'reducers/MultiYearPlan/Settings/settings'

export default function AddFilterInput(): ReactElement {
  const dispatch = useDispatch()
  const { filtersList, appliedFilters } = useSelector((state: RootState) => state.mypSettings)
  const { activeFile } = useSelector((state: RootState) => state.files)
  const [inputValue, setInputValue] = useState<string>('')

  useEffect(() => {
    dispatch(getFiltersList({
      repertoryId: activeFile.directory,
      folderId: activeFile.folder,
      fileId: activeFile.id,
    }))
  }, [])

  const handleValueChange = (newValue: FiltersChoices) => {
    dispatch(updateAppliedFilters([...appliedFilters, {
      id: -1,
      filtered_column_name: newValue.filtered_column_name,
      filtered_column_values: newValue.filtered_column_value_choices,
    }]))
  }

  return (
    <div className="mt-2 add-myp-filter">
      <Autocomplete
        value={null}
        onChange={(_e, newValue) => handleValueChange(newValue as FiltersChoices)}
        inputValue={inputValue}
        onInputChange={(_e, newInput) => setInputValue(newInput)}
        options={filtersList}
        getOptionLabel={(option: FiltersChoices) => option.filtered_column_name}
        renderInput={params => (
          <TextField
            {...params}
            placeholder={terms.MultiYearPlan.SettingsPanel.addFilter}
          />
        )}
        blurOnSelect
        disabled={appliedFilters.find((filter: Filter) => filter.id === -1)}
      />
    </div>
  )
}
