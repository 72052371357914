import terms from 'common/terms'
import { ReactElement } from 'react'

type Props = {
  msg?: string,
  center?: boolean,
}

const defaultProps: Props = {
  msg: terms.Common.loading,
  center: false,
}

export default function Loader({
  msg, center,
}: Props): ReactElement {
  const className = center ? 'loader center' : 'loader'

  return (
    <div
      className="d-flex align-items-center justify-content-center modal"
      style={{
        height: '100%', width: '100%', position: 'fixed', backgroundColor: 'rgba(255, 255, 255, 0.6)',
      }}
    >
      <div className={`${className} d-flex flex-column`}>
        <div className="spinner-border m-auto" role="status">
          <span className="sr-only">Loading...</span>
        </div>
        <div className="text-center mt-2">{msg}</div>
      </div>
    </div>
  )
}

Loader.defaultProps = defaultProps
