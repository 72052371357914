import {
  ReactElement, useEffect, useState,
} from 'react'
import { EditingMode, Editor, SHAPE } from 'react-map-gl-draw'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { LabelsLayersIds } from '../types'
import { getFeatureStyle, getUpdatedFeatures } from './utils'

const mode = new EditingMode()

export default function EditorLayer(): ReactElement | null {
  const { labelToUpdate } = useSelector((state: RootState) => state.label)
  const [selectedFeatureIndex, setSelectedFeatureIndex] = useState<number>()
  const [editFeatures, setEditFeatures] = useState<any>(undefined)

  useEffect(() => {
    if (labelToUpdate) {
      if (labelToUpdate.layer.id === LabelsLayersIds.label) {
        const newEditFeatures = [{
          ...labelToUpdate,
          geometry: labelToUpdate.geometry,
          properties: {
            ...labelToUpdate.properties,
            shape: SHAPE.RECTANGLE,
          },
        }]
        setEditFeatures(newEditFeatures)
        setSelectedFeatureIndex(0)
      }
      if (labelToUpdate.layer.id === LabelsLayersIds.actionZones) {
        if (labelToUpdate.geometry.coordinates.length === 1) {
          setEditFeatures([labelToUpdate])
          setSelectedFeatureIndex(0)
        } else {
          const actionZonesFeatures: any = []
          labelToUpdate.geometry.coordinates.forEach((coordinate: any) => {
            const polygonFeature = {
              ...labelToUpdate,
              geometry: {
                type: 'Polygon',
                coordinates: coordinate,
              },
            }
            actionZonesFeatures.push(polygonFeature)
          })
          setEditFeatures(actionZonesFeatures)
        }
      }
    }
  }, [labelToUpdate])

  useEffect(() => {
    if (editFeatures) {
      getUpdatedFeatures(editFeatures)
    }
  }, [editFeatures])

  const onUpdate = (payload: any) => {
    const features = payload.data
    setEditFeatures(features)
  }
  const onSelect = (selected: any) => {
    setSelectedFeatureIndex(selected.selectedFeatureIndex)
  }

  return (
    <Editor
      clickRadius={12}
      featureStyle={getFeatureStyle}
      onSelect={onSelect}
      onUpdate={onUpdate}
      mode={mode}
      selectedFeatureIndex={selectedFeatureIndex}
      features={editFeatures || undefined}
    />
  )
}
