import { ReactElement } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import { Menu } from '@mui/material'
import {
  PatchFamilyTrack,
  patchClassification,
} from 'reducers/MultiYearPlan/Import/ObjectsFamilies/objectsFamilies.thunk'
import { ColorResult, TwitterPicker } from 'react-color'
import {
  toggleOpenOperationColorDialog,
} from 'reducers/MultiYearPlan/OperationActions/operationactions'
import OBJECT_FAMILY_COLORS_LIST from '../Import/Content/ObjectsFamilies/ObjectFamilyColorsList'

export default function OperationColor(): ReactElement {
  const dispatch = useDispatch()
  const { activeFile } = useSelector((state: RootState) => state.files)
  const {
    openOperationColorDialog, contextPosition, selectedOperationPoint,
  } = useSelector((state: RootState) => state.mypoperationactions)

  const handleClose = () => {
    dispatch(toggleOpenOperationColorDialog(false))
  }

  const handleChooseColorClick = (c: string) => {
    if (activeFile && selectedOperationPoint) {
      if (selectedOperationPoint.data.trackId) {
        dispatch(PatchFamilyTrack({
          repertoryId: activeFile.directory,
          folderId: activeFile.folder,
          fileId: activeFile.id,
          classificationId: selectedOperationPoint.data.classificationId,
          trackId: selectedOperationPoint.data.trackId,
          track: {
            color: c,
          },
        }))
      } else {
        dispatch(patchClassification({
          repertoryId: activeFile.directory,
          folderId: activeFile.folder,
          fileId: activeFile.id,
          classificationId: selectedOperationPoint?.data.classificationId,
          objectFamily: {
            color: c,
          },
        }))
      }
      dispatch(toggleOpenOperationColorDialog(false))
    }
  }

  return (
    <>
      <Menu
        open={openOperationColorDialog}
        className="object-family-color-panel"
        anchorReference="anchorPosition"
        anchorPosition={{ top: contextPosition.y, left: contextPosition.x }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        onClose={handleClose}
      >
        <TwitterPicker
          color=""
          colors={OBJECT_FAMILY_COLORS_LIST}
          onChange={(c: ColorResult) => handleChooseColorClick(c.hex)}
        />
      </Menu>

    </>
  )
}
