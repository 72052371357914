import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { ToolsProps } from 'components/Portfolio/LabelTools/types'
import DeleteObject from 'components/Objects/ObjectTools/DeleteObject'
import FlipObject from 'components/Objects/ObjectTools/FlipObject'
import ModifyObjectPosition from 'components/Objects/ObjectTools/ModifyObjectPosition'
import ModifyObjectOrientation from 'components/Objects/ObjectTools/ModifyObjectOrientation'

export default function ObjectsContextMenu({
  closePopup,
}: ToolsProps): ReactElement | null {
  const { modifiedObjectFeature } = useSelector((state: RootState) => state.object)
  return (
    <>
      { (modifiedObjectFeature?.layer.id
      === 'manual-module_schematique_infra_signal'
      || modifiedObjectFeature?.layer.id
      === 'module_schematique_infra_signal'
      || modifiedObjectFeature?.layer.id
      === 'CARRE'
      || modifiedObjectFeature?.layer.id
      === 'manual-CARRE'
      || modifiedObjectFeature?.layer.id
      === 'CARRE_VIOLET'
      || modifiedObjectFeature?.layer.id
      === 'manual-CARRE_VIOLET'
      || modifiedObjectFeature?.layer.id
      === 'DISQUE'
      || modifiedObjectFeature?.layer.id
      === 'manual-DISQUE'
      || modifiedObjectFeature?.layer.id
      === 'GUIDON_DARRET'
      || modifiedObjectFeature?.layer.id
      === 'manual-GUIDON_DARRET'
      || modifiedObjectFeature?.layer.id
      === 'SEMAPHORE'
      || modifiedObjectFeature?.layer.id
      === 'manual-SEMAPHORE'
      || modifiedObjectFeature?.layer.id
      === 'AVERTISSEMENT'
      || modifiedObjectFeature?.layer.id
      === 'manual-AVERTISSEMENT'
      )
      && (
      <>
        <FlipObject closePopup={closePopup} />
        <ModifyObjectOrientation closePopup={closePopup} />
      </>
      )}
      <ModifyObjectPosition closePopup={closePopup} />
      <DeleteObject closePopup={closePopup} />
    </>
  )
}
