import terms from 'common/terms'
import { InputField, InputTypes } from 'types'

export const COMMENTS_FIELDS: Array<InputField> = [
  {
    key: 'index',
    label: terms.Inputs.versionIndex,
    placeholder: '',
    required: true,
    inputType: InputTypes.SimpleField,
  },
  {
    key: 'date',
    label: terms.Inputs.versionDate,
    placeholder: 'Format AAAA-MM-JJ',
    required: true,
    inputType: InputTypes.SimpleField,
  },
  {
    key: 'entity',
    label: terms.Inputs.version,
    placeholder: 'Obligatoire',
    required: true,
    inputType: InputTypes.SimpleField,
    type: 'textarea',
  },
  {
    key: 'author',
    label: terms.Inputs.author,
    placeholder: 'Obligatoire',
    required: true,
    inputType: InputTypes.SimpleField,
    type: 'textarea',
  },
  {
    key: 'comment',
    label: terms.Inputs.versionComment,
    placeholder: 'Obligatoire',
    required: false,
    inputType: InputTypes.SimpleField,
    type: 'textarea',
  },
]

export default COMMENTS_FIELDS
