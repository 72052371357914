import terms from 'common/terms'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setLabelToUpdate, toggleShowPanel, toggleUpdateMode, updateAreaFields, updateLabelText,
} from 'reducers/label'
import { toggleDisplayLibrary } from 'reducers/library'
import { RootState } from 'Store'
import { Area, AreaFields } from '../types'
import { ToolsProps } from './types'

export default function UpdateLabel({ closePopup }: ToolsProps): ReactElement {
  const dispatch = useDispatch()
  const { clickedFeatureInfo } = useSelector((state: RootState) => state.map)
  const { libraryLabel } = useSelector((state: RootState) => state.library)

  const handleClick = () => {
    dispatch(toggleUpdateMode(true))
    dispatch(toggleShowPanel(true))
    dispatch(toggleDisplayLibrary(false))
    const areasFields: Array<AreaFields> = []
    const feature: GeoJSON.Feature = clickedFeatureInfo || libraryLabel
    if (clickedFeatureInfo) {
      JSON.parse(clickedFeatureInfo.properties.areas).map((area: Area, index: number) => (
        areasFields.push({
          index: index + 1,
          area,
        })
      ))
    } else {
      libraryLabel.properties.areas.map((area: Area, index: number) => (
        areasFields.push({
          index: index + 1,
          area,
        })
      ))
    }

    dispatch(updateAreaFields(areasFields))
    dispatch(updateLabelText(feature.properties?.description))
    dispatch(setLabelToUpdate(feature))
    closePopup(undefined)
  }

  return (
    <div role="button" tabIndex={0} className="popup-items" onClick={handleClick}>
      {terms.Portfolio.updateLabel}
    </div>
  )
}
