import terms from 'common/terms'
import { SubClassification } from 'reducers/MultiYearPlan/Import/ObjectsFamilies/type'
import {
  OperationFerroloc, OperationFerrolocFields, OperationWorkPhase, OperationWorkPhaseFields,
} from 'reducers/MultiYearPlan/OperationActions/types'
import {
  Classification,
} from 'reducers/MultiYearPlan/Operations/types'
import { ResponseError } from 'types'

export const getPhasesList = (phaseFields: OperationWorkPhaseFields[]) => {
  const phases: OperationWorkPhase[] = phaseFields.flatMap((field: OperationWorkPhaseFields) => (
    (field.phase.year !== 0)
      ? field.phase : []
  ))
  return phases
}

export const getFerrolocList = (ferrolocFields: OperationFerrolocFields[]) => {
  const ferroloc: OperationFerroloc[] = ferrolocFields.flatMap((field: OperationFerrolocFields) => (
    (field.ferroloc.line_code !== '' || field.ferroloc.start_kp !== '' || field.ferroloc.end_kp !== '')
      ? field.ferroloc : []
  ))
  return ferroloc
}

export const hasEmptyFields = (label: string, id: number, years: OperationWorkPhase[],
  ferroloc: OperationFerroloc[]) => (label === '' || id === -1
   || years.find((year: OperationWorkPhase) => year.year === 0 || year.year === null)
   || years.length === 0 || ferroloc.length === 0
    || ferroloc.find((loc: OperationFerroloc) => loc.line_code === '' || loc.start_kp === '' || loc.end_kp === ''))

export const getErrorMessage = (responseError: ResponseError | undefined, field: string, emptyFields: boolean,
  value: string | Classification | SubClassification | number, index?: number) => {
  if (responseError && responseError.message[field]) {
    if (emptyFields && (value === '' || value === null)) {
      return terms.Common.Errors.requiredField
    }
    return responseError.message[field]
  }
  if (responseError && responseError.message.railway_locations && index
     && responseError.message.railway_locations[index - 1]
      && responseError.message.railway_locations[index - 1][field]) {
    if (emptyFields && (value === '' || value === null)) {
      return terms.Common.Errors.requiredField
    }
    return responseError.message.railway_locations[index - 1][field]
  }
  if (responseError && responseError.message.years && index
    && responseError.message.years[index - 1]
     && responseError.message.years[index - 1][field]) {
    if (emptyFields && (value === '' || value === null)) {
      return terms.Common.Errors.requiredField
    }
    return responseError.message.years[index - 1][field]
  }
  if (emptyFields && field !== 'track' && (value === '' || value === null || value === 0)) {
    return terms.Common.Errors.requiredField
  }
  return ''
}
