import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  get, patch, post, deleteRequest,
} from '@osrdata/app_core/dist/requests'
import { ThunkApi } from 'types'
import {
  ObjectFamily, GetParams, PatchParams, SubClassification,
  PostTrackParams, Track, DeleteParams, PatchTrackParams,
  PostClassificationParams, PatchSubClassificationParams,
} from './type'

const getClassifications = createAsyncThunk<ObjectFamily[], GetParams, ThunkApi>(
  'objectsFamilies/getClassifications',
  async (params: GetParams, thunkApi) => {
    if (params.folderId) {
      try {
        const response: ObjectFamily[] = await
        // eslint-disable-next-line max-len
        get(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.fileId}/classifications/`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response: ObjectFamily[] = await
        // eslint-disable-next-line max-len
        get(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/classifications/`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

const patchClassification = createAsyncThunk<ObjectFamily, PatchParams, ThunkApi>(
  'objectsFamilies/patchClassification',
  async (params: PatchParams, thunkApi) => {
    if (params.folderId) {
      try {
        const response: ObjectFamily = await
        // eslint-disable-next-line max-len
        patch(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.fileId}/classifications/${params.classificationId}`,
          params.objectFamily)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response: ObjectFamily = await
        // eslint-disable-next-line max-len
        patch(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/classifications/${params.classificationId}`,
          params.objectFamily)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

const patchSubClassification = createAsyncThunk<SubClassification, PatchSubClassificationParams, ThunkApi>(
  'objectsFamilies/patchSubClassification',
  async (params: PatchSubClassificationParams, thunkApi) => {
    if (params.folderId) {
      try {
        const response: SubClassification = await
        // eslint-disable-next-line max-len
        patch(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.fileId}/classifications/${params.classificationId}/sub-classifications/${params.subClassificationId}`,
          params.classification)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response: SubClassification = await
        // eslint-disable-next-line max-len
        patch(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/classifications/${params.classificationId}/sub-classifications/${params.subClassificationId}`,
          params.classification)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

const patchSubClassificationDrop = createAsyncThunk<SubClassification, PatchSubClassificationParams, ThunkApi>(
  'objectsFamilies/patchSubClassification',
  async (params: PatchSubClassificationParams, thunkApi) => {
    if (params.folderId) {
      try {
        const response: SubClassification = await
        // eslint-disable-next-line max-len
        patch(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.fileId}/sub-classifications/${params.subClassificationId}`,
          params.classification)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response: SubClassification = await
        // eslint-disable-next-line max-len
        patch(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/sub-classifications/${params.subClassificationId}`,
          params.classification)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

const getSubClassifications = createAsyncThunk<SubClassification[], GetParams, ThunkApi>(
  'objectsFamilies/getSubClassifications',
  async (params: GetParams, thunkApi) => {
    if (params.folderId) {
      try {
        const response: SubClassification[] = await
        // eslint-disable-next-line max-len
        get(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.fileId}/sub-classifications/`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response: SubClassification[] = await
        // eslint-disable-next-line max-len
        get(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/sub-classifications/`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

const postFamiliyTrack = createAsyncThunk<Track, PostTrackParams, ThunkApi>(
  'objectsFamilies/postFamiliyTrack',
  async (params: PostTrackParams, thunkApi) => {
    if (params.folderId) {
      try {
        const response = await
        // eslint-disable-next-line max-len
        post(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.fileId}/classifications/${params.classificationId}/tracks`,
          params.track)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response = await
        // eslint-disable-next-line max-len
        post(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/classifications/${params.classificationId}/tracks`,
          params.track)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

const deleteClassification = createAsyncThunk<ObjectFamily[], DeleteParams, ThunkApi>(
  'objectsFamilies/deleteClassification',
  async (params: DeleteParams, thunkApi) => {
    if (params.folderId) {
      try {
        const response: ObjectFamily[] = await
        // eslint-disable-next-line max-len
        deleteRequest(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.fileId}/classifications/${params.classificationId}`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response: ObjectFamily[] = await
        // eslint-disable-next-line max-len
        deleteRequest(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/classifications/${params.classificationId}`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

const deleteFamilyTrack = createAsyncThunk<Track, DeleteParams, ThunkApi>(
  'objectsFamilies/deleteFamilyTrack',
  async (params: DeleteParams, thunkApi) => {
    if (params.folderId) {
      try {
        const response: Track = await
        // eslint-disable-next-line max-len
        deleteRequest(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.fileId}/classifications/${params.classificationId}/tracks/${params.trackId}`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response: Track = await
        // eslint-disable-next-line max-len
        deleteRequest(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/classifications/${params.classificationId}/tracks/${params.trackId}`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

const PatchFamilyTrack = createAsyncThunk<Track, PatchTrackParams, ThunkApi>(
  'objectsFamilies/PatchRepertoryFamilyTrack',
  async (params: PatchTrackParams, thunkApi) => {
    if (params.folderId) {
      try {
        const response: Track = await
        // eslint-disable-next-line max-len
        patch(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.fileId}/classifications/${params.classificationId}/tracks/${params.trackId}`,
          params.track)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response: Track = await
        // eslint-disable-next-line max-len
        patch(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/classifications/${params.classificationId}/tracks/${params.trackId}`,
          params.track)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

const postClassification = createAsyncThunk<ObjectFamily, PostClassificationParams, ThunkApi>(
  'objectsFamilies/postRepertoryClassification',
  async (params: PostClassificationParams, thunkApi) => {
    if (params.folderId) {
      try {
        const response = await
        // eslint-disable-next-line max-len
        post(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.fileId}/classifications/`,
          params.objectFamily)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response = await
        // eslint-disable-next-line max-len
        post(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/classifications/`,
          params.objectFamily)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

export {
  getClassifications,
  patchClassification,
  getSubClassifications,
  patchSubClassification,
  patchSubClassificationDrop,
  postFamiliyTrack,
  deleteClassification,
  deleteFamilyTrack,
  PatchFamilyTrack,
  postClassification,
}
