import { ReactElement } from 'react'
import { Source, Layer } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { FiltersCategories, ObjectsLayer } from 'components/Map/ObjectsLayers/types'
import { getFilterCheckStatus, getLayerVisibility } from '../utils'

export default function SecteurLayer(): ReactElement {
  const { secteurLayerData, filtersList } = useSelector((state: RootState) => state.object)

  return (
    <>
      <Source type="geojson" data={secteurLayerData} id={ObjectsLayer.secteur}>
        <Layer
          id={ObjectsLayer.secteur}
          filter={['==', 'auto_added', true]}
          type="symbol"
          paint={{
            'text-color': '#41A8F9',
            'text-halo-width': 2,
            'text-halo-color': 'rgba(255,255,255,0.75)',
            'text-halo-blur': 2,
          }}
          layout={{
            'text-allow-overlap': false,
            'text-field': ['get', 'description'],
            'text-size': 14,
            'text-font': ['Roboto Condensed'],
            visibility: getLayerVisibility(filtersList, FiltersCategories.Te, ObjectsLayer.secteur,
              getFilterCheckStatus(filtersList, FiltersCategories.General, ObjectsLayer.gaia)),
          }}
        />
      </Source>
    </>
  )
}
