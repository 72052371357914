import { ReactElement } from 'react'
import ActionButton from 'components/common/Buttons/ActionButton'
import { useDispatch } from 'react-redux'

import './label.scss'
import { setLabelToUpdate, toggleEnterEditingMode } from 'reducers/label'
import terms from 'common/terms'

export default function CancelLabelEditingButton(): ReactElement {
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(toggleEnterEditingMode(false))
    dispatch(setLabelToUpdate(undefined))
  }

  return (
    <ActionButton
      text={terms.Portfolio.labelGeoEditorCancel}
      onClick={handleClick}
      buttonStyle="validate-geoeditor-btn"
    />
  )
}
