import terms from 'common/terms'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material'
import {
  updateEndYearAttribut, updatePhaseAttribut, updateStartYearAttribut, updateYearAttribut,
} from 'reducers/MultiYearPlan/Import/import'
import { PortfolioHeaders } from 'reducers/MultiYearPlan/Import/types'
import { RootState } from 'Store'
import AttributsChoiceContainer from './AttributsChoiceContainer'
import { FormatFields, ImportFields, WorkPhaseFields } from './MypImportInputs'
import { ImportErrorsFields } from './types'

export default function PortfolioFormatChoice(): ReactElement {
  const dispatch = useDispatch()
  const {
    isLineFormat, startYearAttribut, endYearAttribut, responseError, yearAttribut, phaseAttribut,
  } = useSelector((state: RootState) => state.mypImport)

  const getSelectedValue = (fieldLabel: string) => {
    if (fieldLabel === terms.MultiYearPlan.Import.yearColumn) {
      return yearAttribut
    }
    if (fieldLabel === terms.Inputs.phase) {
      return phaseAttribut
    }
    if (fieldLabel === terms.MultiYearPlan.Import.startYearColumn) {
      return startYearAttribut
    }
    return endYearAttribut
  }

  const handleChange = (value: PortfolioHeaders | null, fieldLabel: string) => {
    if (fieldLabel === terms.MultiYearPlan.Import.yearColumn) {
      dispatch(updateYearAttribut(value))
    } else if (fieldLabel === terms.Inputs.phase) {
      dispatch(updatePhaseAttribut(value))
    } else if (fieldLabel === terms.MultiYearPlan.Import.startYearColumn) {
      dispatch(updateStartYearAttribut(value))
    } else {
      dispatch(updateEndYearAttribut(value))
    }
  }

  const getErrorMessage = (fieldLabel: string) => {
    if (fieldLabel === terms.MultiYearPlan.Import.startYearColumn) {
      return responseError && responseError.message[ImportErrorsFields.StartYearError]
        ? responseError.message[ImportErrorsFields.StartYearError] : ''
    }
    if (fieldLabel === terms.MultiYearPlan.Import.endYearColumn) {
      return responseError && responseError.message[ImportErrorsFields.EndYearError]
        ? responseError.message[ImportErrorsFields.EndYearError] : ''
    }
    if (fieldLabel === terms.MultiYearPlan.Import.yearColumn) {
      return responseError && responseError.message[ImportErrorsFields.YearError]
        ? responseError.message[ImportErrorsFields.YearError] : ''
    }
    return responseError && responseError.message[ImportErrorsFields.PhaseError]
      ? responseError.message[ImportErrorsFields.PhaseError] : ''
  }

  return (
    <Box
      component="form"
      className="ferro-localization-form"
      noValidate
      autoComplete="off"
      sx={{ width: 'auto' }}
    >
      {isLineFormat ? (
        WorkPhaseFields.map((field: ImportFields) => (
          <AttributsChoiceContainer
            key={field.label}
            chipLabel={field.label}
            value={getSelectedValue(field.label)}
            onChange={(_event, newValue) => handleChange(newValue, field.label)}
            placeholder={field.placeholder}
            errorMessage={getErrorMessage(field.label)}
          />
        ))
      ) : (
        FormatFields.map((field: ImportFields) => (
          <AttributsChoiceContainer
            key={field.label}
            chipLabel={field.label}
            value={getSelectedValue(field.label)}
            onChange={(_event, newValue) => handleChange(newValue, field.label)}
            placeholder={field.placeholder}
            errorMessage={getErrorMessage(field.label)}
          />
        ))
      )}
    </Box>
  )
}
