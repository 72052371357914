import Step from '@mui/material/Step'
import StepContent from '@mui/material/StepContent'
import StepLabel from '@mui/material/StepLabel'
import Stepper, { Orientation } from '@mui/material/Stepper'
import Typography from '@mui/material/Typography'
import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { StepDataType } from './types'

import './stepper.scss'

type Props = {
    steps: Array<StepDataType>
    orientation?: Orientation
}

const defaultProps = {
  orientation: 'horizontal',
}

export default function CustomStepper({ steps, orientation }: Props): ReactElement {
  const { activeStep } = useSelector((state: RootState) => state.import)

  return (
    <Stepper
      orientation={orientation}
      className={`stepper-style${activeStep === -1 ? ' summary-stepper' : ' top-bar-stepper'}`}
      alternativeLabel={activeStep !== -1}
      activeStep={activeStep}
    >
      {steps.map((step, index) => (
        <Step
          key={step.label}
          expanded
          active={activeStep === index}
          className={activeStep !== index && activeStep !== -1 ? 'step-style' : ''}
        >
          <StepLabel>{step.label}</StepLabel>
          {activeStep === -1 && (
          <StepContent>
            <Typography>{step.description}</Typography>
          </StepContent>
          )}
        </Step>
      ))}
    </Stepper>
  )
}

CustomStepper.defaultProps = defaultProps
