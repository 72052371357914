/* eslint-disable no-prototype-builtins */
import { ReactElement, useEffect } from 'react'
import terms from 'common/terms'
import ActionButton from 'components/common/Buttons/ActionButton'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { patchFile } from 'reducers/Files/files.thunk'
import history from 'customHistory'
import PathNames from 'common/PathNames'
import {
  updateCreationLevel, resetResponseError as resetError, updateEmptyFields,
} from 'reducers/creation'
import { CreationLevels } from 'components/Dashboard/types'
import CreationService from 'components/Dashboard/Creation/CreationService'
import { resetResponseError, toggleUpdateFileName } from 'reducers/Files/files'
import { FileTypes } from 'reducers/Files/types'
import {
  resetMypResponseError, toggleUpdateTimeRange, updateCreationStatus, updateMypEmptyFields,
} from 'reducers/MultiYearPlan/multiYearPlan'
import { toggleDisplaySettingsPanel } from 'reducers/MultiYearPlan/Settings/settings'
import { displayTabContent } from '../utils'

export default function TemporalPerimeterButton(): ReactElement {
  const dispatch = useDispatch()
  const {
    activeFile, timeRange, createdFile, updateFileName, isLoading,
  } = useSelector((state: RootState) => state.files)
  const { updateTimeRange } = useSelector((state: RootState) => state.multiYearPlan)
  const { Operations } = useSelector((state: RootState) => state.mypoperations)
  const { tabsList } = useSelector((state: RootState) => state.dashboard)
  const { activeRepertory } = useSelector((state: RootState) => state.repertories)
  const { activeFolder } = useSelector((state: RootState) => state.folders)

  useEffect(() => {
    if (createdFile && createdFile.time_range.start_year !== null && !updateFileName && !updateTimeRange) {
      history.push(`${window.location.pathname.replace(PathNames.TemporalPerimeter, PathNames.GeographicPerimeter)}`)
      if (createdFile.type === FileTypes.Synoptic) {
        dispatch(updateCreationLevel(CreationLevels.Temporal))
      } else {
        dispatch(updateCreationStatus(CreationLevels.Temporal))
      }
    }
  }, [createdFile])

  const handleClick = () => {
    if (!updateTimeRange) {
      if (activeFile && (activeFile.time_range.start_year !== timeRange.start_year
        || activeFile.time_range.end_year !== timeRange.end_year)) {
        dispatch(patchFile({
          repertoryId: activeFile.directory,
          folderId: activeFile.folder,
          file: { id: activeFile.id, time_range: timeRange },
        }))
        if (activeFile.type === FileTypes.Synoptic) {
          dispatch(CreationService.updateProject({
            project: { id: activeFile.id, time_range: timeRange },
            creationLevel: CreationLevels.Temporal,
          }))
        }
      } else {
        dispatch(resetResponseError())
        history.push(`${window.location.pathname.replace(PathNames.TemporalPerimeter, PathNames.GeographicPerimeter)}`)
        if (activeFile.type === FileTypes.Synoptic) {
          dispatch(updateCreationLevel(CreationLevels.Temporal))
          dispatch(updateEmptyFields(false))
          dispatch(resetError())
        } else {
          dispatch(updateCreationStatus(CreationLevels.Temporal))
          dispatch(updateMypEmptyFields(false))
          dispatch(resetMypResponseError())
        }
      }
      dispatch(toggleUpdateFileName(false))
    } else if (activeFile) {
      if (Object.keys(Operations).length !== 0
      && Operations.years[0].hasOwnProperty('classifications')
      && (activeFile.time_range.start_year !== timeRange.start_year
        || activeFile.time_range.end_year !== timeRange.end_year)) {
        dispatch(patchFile({
          repertoryId: activeFile.directory,
          folderId: activeFile.folder,
          file: { id: activeFile.id, time_range: timeRange, confirm_change: false },
        }))
      } else {
        dispatch(toggleDisplaySettingsPanel(false))
        dispatch(toggleUpdateTimeRange(false))
        displayTabContent(tabsList, activeFile, activeRepertory, activeFolder)
        if ((activeFile.time_range.start_year !== timeRange.start_year
          || activeFile.time_range.end_year !== timeRange.end_year)) {
          dispatch(patchFile({
            repertoryId: activeFile.directory,
            folderId: activeFile.folder,
            file: { id: activeFile.id, time_range: timeRange },
          }))
        }
      }
    }
  }

  return (
    <div className="d-flex justify-content-center">
      <ActionButton
        text={isLoading ? terms.Common.loading : terms.Common.continue}
        onClick={handleClick}
        rightIcon
        buttonStyle="action-button"
        disabled={!timeRange || timeRange?.start_year === null || timeRange?.end_year === null || isLoading}
      />
    </div>
  )
}
