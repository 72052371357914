import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import BigLoader from 'components/common/BigLoader/BigLoader'
import DocumentChoice from './DocumentChoice'
import { MypImportStepsList } from '../types'
import CriteriaChoice from './CriteriaChoice'
import LabelChoice from './LabelChoice'
import PortfolioFormatChoice from './PortfolioFormatChoice'
import FerrolocationChoice from './FerrolocalizationChoice'
import OperationChoice from './OperationChoice'

export default function ImportStepsContent(): ReactElement {
  const { activeStep, showLoader } = useSelector((state: RootState) => state.mypImport)

  switch (activeStep) {
    case MypImportStepsList.UploadFile:
      return showLoader ? <BigLoader /> : <DocumentChoice />
    case MypImportStepsList.CriteriaChoice:
      return <CriteriaChoice />
    case MypImportStepsList.LabelChoice:
      return <LabelChoice />
    case MypImportStepsList.PortfolioFormat:
      return <PortfolioFormatChoice />
    case MypImportStepsList.Ferrolocalization:
      return <FerrolocationChoice />
    case MypImportStepsList.OperationChoice:
      return <OperationChoice />
    default:
      return <></>
  }
}
