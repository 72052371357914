/* eslint-disable max-len */
/* eslint-disable no-self-assign */
import { RefObject } from 'react'
import { useSelector } from 'react-redux'
import nextId from 'react-id-generator'
import { RootState } from 'Store'
import { MYPSection } from 'reducers/MultiYearPlan/types'
import { Line, Layer, CustomLayerProps } from '@nivo/line'
import '../multiYearPlan.scss'
import { getCodeLineOpenPanelStyle } from '../Settings/utils'

export default function LineCodesChart({ LineCChartRef } : {LineCChartRef : RefObject<HTMLInputElement>}) {
  const { zoomPr } = useSelector((state: RootState) => state.remarkablePoints)
  const { mypSectionsList } = useSelector((state: RootState) => state.multiYearPlan)
  const { displayLibraryPanel } = useSelector((state: RootState) => state.mypLibrary)
  const { displaySettingsPanel } = useSelector((state: RootState) => state.mypSettings)

  const formatedSectionsList = mypSectionsList.map((item: MYPSection) => {
    const obj = {
      ...item,
      data: [
        {
          x: item.localizations[0].x,
          label: item.code_ligne,
          middle: ((((zoomPr - 170) * item.localizations[1].x) / mypSectionsList[mypSectionsList.length - 1]?.localizations[1]?.x) - (((zoomPr - 170) * item.localizations[0].x) / mypSectionsList[mypSectionsList.length - 1]?.localizations[1]?.x)) / 2,
        },
        {
          x: item.localizations[1].x - 0.2,
        },
      ],
      color: '#c3c4c7',
    }
    return obj
  })

  function Points({ points }: CustomLayerProps) {
    return (
      <>
        {points.map((point: any) => (
          <g key={nextId()} transform={`translate(${point.x},${point.y})`}>
            <text
              className="operation-codes"
              dominantBaseline="middle"
              style={{
                fontSize: 13,
                fontFamily: 'sans-serif',
                textAnchor: 'middle',
                translate: `${point.data.middle}px 0px`,
              }}
            >
              {point.data.label}
            </text>
          </g>
        ))}
      </>
    )
  }
  return (
    <>
      <div className={`${getCodeLineOpenPanelStyle(displaySettingsPanel, displayLibraryPanel)} line-chart-container-codel`} ref={LineCChartRef} id="line-chart-container-codel">
        <div className="container2">
          <Line
            key={nextId()}
            width={zoomPr}
            height={40}
            data={formatedSectionsList}
            margin={{
              top: 0,
              right: 150,
              bottom: 0,
              left: 20,
            }}
            xScale={{
              type: 'linear',
              min: 0,
              max: mypSectionsList[mypSectionsList.length - 1]?.localizations[1]?.x,
            }}
            yScale={{
              type: 'point',
            }}
            axisBottom={null}
            axisTop={null}
            axisRight={null}
            axisLeft={null}
            yFormat=" >-.2f"
            enableGridX={false}
            enableGridY={false}
            colors={{ datum: 'color' }}
            enablePoints
            enablePointLabel
            pointLabel="label"
            tooltip={() => <div className="line-chart-tooltip" />}
            lineWidth={38}
            layers={
            [
              'grid',
              'axes',
              'areas',
              'lines',
              'slices',
              'mesh',
              'legends',
              Points,
            ] as Layer[]
          }
          />
        </div>
      </div>
    </>

  )
}
