export const getLegendContainers = (legendContainers : number): number[] => {
  const containers:number[] = []

  let container = 0
  while (container < legendContainers) {
    containers.push(container)
    container += 1
  }
  return containers
}

export const getFile = async (url: string) => {
  fetch(url,
    {
      method: 'GET',
      headers: {
        Authorization:
          `Bearer ${localStorage.getItem('access_token')}`,
      },
    }).then(resp => resp.json())
    .then(json => {
      window.open(json, '_blank')
    })
}
