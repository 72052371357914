import { ReactElement } from 'react'
import { Typography } from '@mui/material'
import terms from 'common/terms'
import CartridgeAccordion from './CartridgeAccordion'
import PhasesAccordion from './PhasesAccordion'
import TypologyAccordion from './TypologyAccordion'
import AddFilterInput from './AddFilterInput'
import AppliedFiltersAccordions from './AppliedFiltersAccordions'

export default function FiltersSettings(): ReactElement {
  return (
    <>
      <Typography className="category-title">{terms.MultiYearPlan.SettingsPanel.filtersSettings}</Typography>
      <div className="filters-container">
        <CartridgeAccordion />
        <PhasesAccordion />
        <TypologyAccordion />
        <AppliedFiltersAccordions />
        <AddFilterInput />
      </div>
    </>
  )
}
