import { ReactElement } from 'react'
import terms from 'common/terms'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import DeleteModal from 'components/common/Modals/DeleteModal'
import { deleteOperation } from 'reducers/MultiYearPlan/OperationActions/operationactions.thunk'
import { toggleDisplayDeleteModal } from 'reducers/MultiYearPlan/OperationActions/operationactions'
import { deleteDefectedOperation } from 'reducers/MultiYearPlan/Library/library.thunk'

export default function DeleteOperationModal():
 ReactElement {
  const dispatch = useDispatch()
  const {
    displayDeleteModal, selectedOperation, deletingOperation,
  } = useSelector((state: RootState) => state.mypoperationactions)
  const { activeFile } = useSelector((state: RootState) => state.files)
  const {
    selectedDefectedOperation, deletingDefectedOperation, selectedDefectedOperationForDelete,
  } = useSelector((state: RootState) => state.mypLibrary)

  const onModalClose = () => {
    dispatch(toggleDisplayDeleteModal(false))
  }

  const onDelete = () => {
    if (selectedDefectedOperation) {
      dispatch(deleteDefectedOperation({
        repertoryId: activeFile.directory,
        folderId: activeFile.folder,
        projectId: activeFile.id,
        operationId: selectedDefectedOperation.id,
      }))
    } else if (selectedDefectedOperationForDelete) {
      dispatch(deleteDefectedOperation({
        repertoryId: activeFile.directory,
        folderId: activeFile.folder,
        projectId: activeFile.id,
        operationId: selectedDefectedOperationForDelete.id,
      }))
    } else {
      dispatch(deleteOperation({
        repertoryId: activeFile.directory,
        folderId: activeFile.folder,
        projectId: activeFile.id,
        operationId: selectedOperation.id,
      }))
    }
  }

  return (
    <DeleteModal
      open={displayDeleteModal}
      onModalClose={onModalClose}
      modalTitle={terms.MultiYearPlan.Actions.deleteOperation}
      modalNotice={terms.MultiYearPlan.Actions.deleteOperationNotice}
      onDelete={onDelete}
      isLoading={deletingDefectedOperation || deletingOperation}
    />
  )
}
