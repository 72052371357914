import { Button, Menu, MenuItem } from '@mui/material'
import { RootState } from 'Store'
import terms from 'common/terms'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteFamilyTrack } from 'reducers/MultiYearPlan/Import/ObjectsFamilies/objectsFamilies.thunk'
import { Track } from 'reducers/MultiYearPlan/Import/ObjectsFamilies/type'

export default function ContextMenu({
  anchorEl, setAnchorEl, handleOpenColorPicker, familyId, activeTrack,
}: {anchorEl: null | HTMLElement, setAnchorEl: (v: null | HTMLElement) => void, handleOpenColorPicker: () => void,
familyId: number, activeTrack: Track})
: ReactElement {
  const dispatch = useDispatch()
  const { activeFile } = useSelector((state: RootState) => state.files)

  const open = Boolean(anchorEl)
  const onClickDelete = () => {
    if (activeFile) {
      dispatch(deleteFamilyTrack({
        repertoryId: activeFile.directory,
        folderId: activeFile.folder,
        fileId: activeFile.id,
        classificationId: familyId,
        trackId: activeTrack?.id,
      }))
      setAnchorEl(null)
    }
  }
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={() => setAnchorEl(null)}
    >
      <MenuItem>
        <Button
          className="actions-menu-btn"
          onClick={onClickDelete}
        >
          {terms.Common.delete}
        </Button>
      </MenuItem>
      <MenuItem>
        <Button
          className="actions-menu-btn"
          onClick={handleOpenColorPicker}
        >
          {terms.MultiYearPlan.Import.Actions.changeColor}
        </Button>
      </MenuItem>
    </Menu>
  )
}
