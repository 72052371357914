import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkApi } from 'types'
import {
  get, post, deleteRequest, patch,
} from '@osrdata/app_core/dist/requests'
import {
  GetParams, PostParams, Comment, DeleteParams,
} from './types'

const getComments = createAsyncThunk<Comment[], GetParams, ThunkApi>(
  'mypcomments/getComments',
  async (params: GetParams, thunkApi) => {
    if (params.folderId && params.folderId !== null) {
      try {
        const response: Comment[] = await
        // eslint-disable-next-line max-len
        get(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.fileId}/comments/`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response: Comment[] = await
        // eslint-disable-next-line max-len
        get(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/comments/`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

const modifyComment = createAsyncThunk<Comment, PostParams, ThunkApi>(
  'mypcomments/modifyComment',
  async (params: PostParams, thunkApi) => {
    if (params.folderId && params.folderId !== null) {
      try {
        const response: Comment = await
        // eslint-disable-next-line max-len
        patch(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.fileId}/comments/${params.commentId}`, params)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response: Comment = await
        // eslint-disable-next-line max-len
        patch(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/comments/${params.commentId}`, params)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

const addComment = createAsyncThunk<Comment, PostParams, ThunkApi>(
  'mypcomments/addComment',
  async (params: PostParams, thunkApi) => {
    if (params.folderId && params.folderId !== null) {
      try {
        const response: Comment = await
        // eslint-disable-next-line max-len
        post(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.fileId}/comments/`, params)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response: Comment = await
        // eslint-disable-next-line max-len
        post(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/comments/`, params)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

const DeleteComment = createAsyncThunk(
  'mypcomments/DeleteComment',
  async (params: DeleteParams, thunkApi) => {
    if (params.folderId && params.folderId !== null) {
      try {
        await
        // eslint-disable-next-line max-len
        deleteRequest(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.fileId}/comments/${params.commentId}`)
        return params.commentId
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        await
        // eslint-disable-next-line max-len
        deleteRequest(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/comments/${params.commentId}`)
        return params.commentId
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)
export {
  getComments,
  modifyComment,
  addComment,
  DeleteComment,
}
