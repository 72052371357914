import {
  ReactElement, useEffect, useRef, useState,
} from 'react'
import { WebMercatorViewport } from '@deck.gl/core'
import { useDispatch, useSelector } from 'react-redux'
import DeckGL from '@deck.gl/react'
import { RootState } from 'Store'
// import mockGeoJson from './mockLayer'

import { Viewport } from './snapping/updateSnappingFeatures'
import {
  GeoEditorState, setEditorLayerMode,
} from './reducer'
import { EditorModeName } from './types'
import GeoEditorTools from './GeoEditorTools'
import { getCursor, Modes, onModeChange } from './utils'
import createExtendLineLayer from './ExtendLineLayer/createExtendLineLayer'
import createEditionLayer from './EditionLayer/createEditionLayer'
import createSelectionLayer from './SelectionLayer/createSelectionLayer'
import GeoEditorService from './GeoEditorService'
import Feedbacks from './Feedbacks/Feedbacks'
import createGridLayer, { updateGrid } from './GridLayer'

export const DEFAULT_VIEWPORT = {
  latitude: 46.88731499073388,
  longitude: 2.5682289198122756,
  zoom: 8.6650,
  bearing: 0,
  pitch: 0,
  maxZoom: 24,
}

export default function GeoEditor(): ReactElement {
  const deckRef = useRef(null)
  const dispatch = useDispatch()
  const {
    mode, selectedPoints, geoJson, layersVisibility, viewportGeoEditor, grid,
  } = useSelector((state: RootState): GeoEditorState => state.geoEditor)
  const { showGeoeditor, geoeditorUrl } = useSelector((state: RootState) => state.object)
  const [viewport, setViewport] = useState<Viewport | undefined>(viewportGeoEditor)
  const [oldZoom, setOldZoom] = useState<number | undefined>(undefined)

  useEffect(() => {
    if (showGeoeditor) {
      dispatch(GeoEditorService.getConfiguration(geoeditorUrl))
    } else {
      dispatch(GeoEditorService.getConfiguration2(geoeditorUrl))
    }
    if (viewport) updateGrid(viewport)
  }, [])

  useEffect(() => {
    dispatch(setEditorLayerMode(EditorModeName.Edit))
  }, [selectedPoints])

  const gridLayer = createGridLayer(grid)
  const extendLineStringLayer = createExtendLineLayer()
  const editionLayer = createEditionLayer(viewport)
  const selectionLayer = createSelectionLayer()

  useEffect(() => {
    onModeChange(mode)
  }, [mode])

  useEffect(() => {
    if (geoJson && viewport && viewport.zoom !== oldZoom) {
      // dispatch(setSnappingFeatures(updateSnappingFeatures(viewport)))
    }
  }, [oldZoom])

  return (
    <DeckGL
      initialViewState={viewportGeoEditor}
      ref={deckRef}
      controller={{
        dragPan: mode === Modes.grab,
      }}
      onViewStateChange={({ viewState, oldViewState }) => {
        const newViewport = new WebMercatorViewport(viewState) as Viewport
        setViewport(newViewport)

        if (oldViewState) {
          const oldViewport = new WebMercatorViewport(oldViewState) as Viewport
          setOldZoom(oldViewport.zoom)
        }
      }}
      getCursor={getCursor}
      layers={layersVisibility.selectionLayer
        ? [gridLayer, editionLayer, selectionLayer, extendLineStringLayer]
        : [gridLayer, editionLayer, extendLineStringLayer]}
    >
      <GeoEditorTools viewport={viewport} />
      <Feedbacks />
    </DeckGL>
  )
}
