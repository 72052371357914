import { ReactElement } from 'react'
import { Container, Fade } from '@mui/material'
import ContainerHeader from 'components/common/Container/ContainerHeader'
import terms from 'common/terms'
import ContainerBody from 'components/common/Container/ContainerBody'
import ContainerFooter from 'components/common/Container/ContainerFooter'
import history from 'customHistory'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { resetActiveFile, resetResponseError } from 'reducers/Files/files'
import { FileTypes } from 'reducers/Files/types'
import {
  setUpdatedSection, toggleValidationNotice, updateActiveProject, updateCreationLevel, updateEmptyFields,
  updateSectionsList,
} from 'reducers/creation'
import { updateActiveTab } from 'reducers/dashboard'
import SnackBar from 'components/common/Snackbar/SnackBar'
import { resetState } from 'reducers/MultiYearPlan/multiYearPlan'
import { getPath } from '../../../../utils'
import FileNameInput from './FileNameInput'
import FileNameDeclarationButton from './FileNameDeclarationButton'

export default function FileNameDeclaration(): ReactElement {
  const dispatch = useDispatch()
  const { activeRepertory } = useSelector((state: RootState) => state.repertories)
  const { activeFolder } = useSelector((state: RootState) => state.folders)
  const { fileType, activeFile, responseError } = useSelector((state: RootState) => state.files)

  const handleClose = () => {
    history.push(getPath(activeRepertory, activeFolder, activeFile))
    dispatch(resetActiveFile())
    dispatch(updateActiveTab({ tabName: 'home', tabId: undefined }))
    if (activeFile?.type === FileTypes.Synoptic) {
      dispatch(updateCreationLevel(undefined))
      dispatch(updateActiveProject(undefined))
      dispatch(updateEmptyFields(false))
      dispatch(toggleValidationNotice(false))
      dispatch(setUpdatedSection(undefined))
      dispatch(updateSectionsList([]))
    } else {
      dispatch(resetState())
    }
  }

  return (
    <main>
      <Fade timeout={400} in mountOnEnter unmountOnExit>
        <Container maxWidth={false} disableGutters sx={{ height: '100vh' }}>
          <ContainerHeader
            onRollBack={handleClose}
            rollBackText={terms.Common.backToHome}
            onClose={handleClose}
          />
          <ContainerBody
            title={fileType === FileTypes.Schema ? terms.File.Declaration.Schema.multiYearSchemaTitle
              : terms.File.Declaration.Synoptic.synopticTitle}
            subtitle={terms.Common.declarationSubtitle}
            content={<FileNameInput />}
          />
          <ContainerFooter content={<FileNameDeclarationButton />} />
        </Container>
      </Fade>
      <SnackBar message={terms.Common.Errors.error} error={responseError !== undefined} setError={resetResponseError} />
    </main>
  )
}
