import { ReactElement } from 'react'
import GenericInput from 'components/common/Inputs/GenericInput'
import terms from 'common/terms'
import { Checkbox, FormControlLabel } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { toggleDisplayCartridge, updateRkStep } from 'reducers/export'
import ExportScalingFields from './ExportScalingFields'

export default function ExportSettingsForm(): ReactElement {
  const dispatch = useDispatch()
  const { rkStep, displayCartridge } = useSelector((state: RootState) => state.export)

  const handleStepChange = (step: string) => {
    if (!Number.isNaN(+step) && step !== '0') {
      dispatch(updateRkStep(step))
    }
  }
  return (
    <>
      <GenericInput
        label={terms.Export.displayRk}
        value={rkStep}
        onChange={e => handleStepChange(e.target.value)}
        inputProps={{ inputMode: 'numeric', pattern: '/d*' }}
        errorMessage={rkStep.length === 0 ? terms.Common.Errors.requiredField : ''}
      />
      <ExportScalingFields />
      <div className="cartridge-checkbox">
        <FormControlLabel
          control={(
            <Checkbox
              checked={displayCartridge}
              onChange={() => dispatch(toggleDisplayCartridge(!displayCartridge))}
            />
        )}
          label={terms.Export.displayCartridge}
        />
      </div>
    </>
  )
}
