import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  generateClassifications, patchFolderPortfolio, patchRepertoryPortfolio,
} from 'reducers/MultiYearPlan/Import/import.thunk'
import { RootState } from 'Store'
import ImportActionButton from './ImportActionButton'

export default function OperationConfirmationButton(): ReactElement {
  const dispatch = useDispatch()
  const { selectedAttributValue, generatingClassifications } = useSelector((state: RootState) => state.mypImport)
  const { activeFile } = useSelector((state: RootState) => state.files)
  const handleClick = () => {
    if (selectedAttributValue !== null) {
      if (activeFile.folder !== null) {
        dispatch(patchFolderPortfolio({
          repertoryId: activeFile.directory,
          folderId: activeFile.folder,
          fileId: activeFile.id,
          portfolio: { column_maturity: selectedAttributValue?.id },
        }))
      } else {
        dispatch(patchRepertoryPortfolio({
          repertoryId: activeFile.directory,
          fileId: activeFile.id,
          portfolio: { column_maturity: selectedAttributValue?.id },
        }))
      }
    }
    dispatch(generateClassifications({
      repertoryId: activeFile.directory,
      folderId: activeFile.folder,
      fileId: activeFile.id,
    }))
  }
  return (
    <ImportActionButton handleClick={handleClick} disabled={generatingClassifications} />
  )
}
