import { get } from '@osrdata/app_core/dist/requests'
import terms from 'common/terms'
import { updateSearchValues } from 'reducers/library'
import { store } from 'Store'
import { InputField, InputTypes } from 'types'
import { Portfolio } from '../Import/types'
import { SearchFields } from '../types'

const defaultFiltersList = [
  'Libellé',
  'Axes de production',
  'Année de fin de travaux/MeS',
  'Phases études/travaux',
]

export const getDefaultFiltersInput = (portfolio: Portfolio): Array<InputField> => {
  const defaultFilters: Array<InputField> = []
  defaultFiltersList.forEach((filter: string) => {
    if (Object.keys(portfolio.filters).includes(filter)) {
      defaultFilters.push({
        key: filter,
        label: filter,
        placeholder: terms.Common.search,
        required: false,
        inputType: InputTypes.Select,
        withSearch: true,
        multiple: true,
      })
    }
  })
  return defaultFilters
}

export const getActualFiltersFields = (portfolio: Portfolio): Array<InputField> => {
  const actualFilters: Array<InputField> = []
  Object.keys(portfolio.filters).forEach((key: string) => {
    if (portfolio.filters[key].length !== 0) {
      actualFilters.push({
        key,
        label: key,
        placeholder: terms.Common.search,
        required: false,
        inputType: InputTypes.Select,
        withSearch: true,
        multiple: true,
      })
    }
  })
  return actualFilters
}

export const getActualFilters = (portfolio: Portfolio): any => {
  let actualFilters: any = {}
  Object.keys(portfolio.filters).forEach((key: string) => {
    if (portfolio.filters[key].length !== 0) {
      actualFilters = { ...actualFilters, [key]: portfolio.filters[key] }
    }
  })
  return actualFilters
}

export const setSearchValues = (v: string, index: number| string | undefined, deleteIndex?: boolean) => {
  const { searchValues } = store.getState().library
  if (deleteIndex) {
    store.dispatch(updateSearchValues((searchValues.filter((search: SearchFields) => search.index !== index))))
  } else if (searchValues.filter((search: SearchFields) => search.index === index).length === 0) {
    store.dispatch(updateSearchValues(searchValues.concat({ index, value: v })))
  } else {
    store.dispatch(updateSearchValues((searchValues.filter((search: SearchFields) => search.index !== index))
      .concat({ index, value: v })))
  }
}

export const getSelectedValue = (value: string | string[], index: number| string | undefined,
  fieldLabel?: string) => {
  const { searchValues } = store.getState().library
  const actualSearchValue = searchValues.filter((search: SearchFields) => search.index === index)
  if (typeof value === 'string') {
    if (value !== '' && actualSearchValue.length !== 0 && actualSearchValue[0].value === value) {
      return [value] as string[]
    }
    if (actualSearchValue.length !== 0) {
      return [actualSearchValue[0].value] as string[]
    }
    if (value !== '' && fieldLabel === 'Portfolio.Library.Filters.workPhase') {
      return [value] as string[]
    }
    return []
  }
  if (value.length !== 0) {
    return Array.from(new Set(value))
  }
  return []
}

export const getFilterValues = async (projectId: number, filter: string): Promise<string[]> => {
  const response = await
  get(`/module_schematique/portefeuille_programmatique/${projectId}/list_filters/`,
    { filter })
  return response
}

export const handleChange = (filter: string, selectedValue: string | string[],
  selectedValues: any, setSelectedValues: (v: any) => void) => {
  let values: any = {}
  if (typeof selectedValue === 'string') {
    if (!Object.keys(selectedValues).includes(filter)) {
      if (selectedValue !== '') {
        values = { ...selectedValues, [`${filter}`]: [selectedValue] }
      } else {
        values = { ...selectedValues, [`${filter}`]: [] }
      }
    } else if (!selectedValues[`${filter}`].includes(selectedValue)) {
      values = {
        ...selectedValues,
        [`${filter}`]: selectedValue !== ''
          ? selectedValues[`${filter}`].concat(selectedValue) : selectedValues[`${filter}`],
      }
    }
  } else {
    values = { ...selectedValues, [`${filter}`]: selectedValue }
  }
  setSelectedValues(values)
}

export const getAppliedFilters = (portfolio: Portfolio): number => {
  let appliedFilters = 0
  Object.keys(portfolio.filters).forEach((key: string) => {
    if (portfolio.filters[key].length !== 0) {
      appliedFilters += 1
    }
  })
  return appliedFilters
}
