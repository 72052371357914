import { ReactElement, useEffect, useState } from 'react'
import {
  DragDropContext, Draggable, Droppable, DropResult,
} from 'react-beautiful-dnd'
import { FiTrash } from 'react-icons/fi'
import { MdEdit } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import { Files } from 'reducers/Files/types'
import { patchFolderFilesSection, patchRepertoryFileSection } from 'reducers/MultiYearPlan/multiYearPlan.thunk'
import { MYPSection } from 'reducers/MultiYearPlan/types'
import { DirectionSelectOptions } from './GeographicPerimeterInputFields'
import { reorder } from './utils'

type Props = {
    sectionsList: MYPSection[]
    onSectionUpdate: (v: MYPSection) => void
    onSectionDelete: (v: number) => void
    validationNotice: boolean
    activeFile: Files
}
export default function SchemaTableBody({
  sectionsList, onSectionUpdate, onSectionDelete, activeFile,
}: Props): ReactElement {
  const dispatch = useDispatch()
  const [orderedSectionsList, setOrderedSectionsList] = useState<MYPSection[]>([])

  useEffect(() => {
    setOrderedSectionsList(sectionsList)
  }, [sectionsList])

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }
    const orderedSections = reorder(
      sectionsList,
      result.source.index,
      result.destination.index,
    )
    setOrderedSectionsList(orderedSections)
    const updatedSection = orderedSections.filter(section => section.id === +result.draggableId)[0]
    if (activeFile.folder !== null) {
      dispatch(patchFolderFilesSection({
        repertoryId: activeFile.directory as number,
        folderId: activeFile.folder,
        fileId: activeFile.id,
        section: { ...updatedSection, order: result.destination.index },
      }))
    } else {
      dispatch(patchRepertoryFileSection({
        repertoryId: activeFile.directory as number,
        fileId: activeFile.id,
        section: { ...updatedSection, order: result.destination.index },
      }))
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {provided => (
          <tbody ref={provided.innerRef} {...provided.droppableProps}>
            {orderedSectionsList && orderedSectionsList.map((section: MYPSection, index: number) => (
              <Draggable key={section.id} draggableId={section.id.toString()} index={index}>
                {(trProvided, snapshot) => (
                  <tr
                    ref={trProvided.innerRef}
                    {...trProvided.draggableProps}
                    {...trProvided.dragHandleProps}
                    className={snapshot.isDragging ? 'draggable-style' : ''}
                  >
                    <td className="td-order">{(section as MYPSection).order + 1}</td>
                    <td className="section-label">{section.name}</td>
                    <td>{section.code_ligne}</td>
                    <td>{section.pk_sncf_debut}</td>
                    <td>{section.pk_sncf_fin}</td>
                    <td>{(section as MYPSection).step}</td>
                    <td>
                      {DirectionSelectOptions.filter(d => d.key === section.direction)[0].value}
                    </td>
                    <td>
                      <div className="mr-4">
                        <MdEdit
                          className="action-cursor-pointer"
                          onClick={() => onSectionUpdate(section)}
                        />
                      </div>
                      <div className="mr-4">
                        <FiTrash
                          className="action-cursor-pointer"
                          onClick={() => onSectionDelete(section.id)}
                        />
                      </div>
                    </td>
                  </tr>
                )}
              </Draggable>
            ))}
          </tbody>
        )}
      </Droppable>
    </DragDropContext>
  )
}
