import { ReactElement } from 'react'
import { Button } from '@mui/material'
import terms from 'common/terms'
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined'
import { useDispatch } from 'react-redux'
import { toggleDisplayInputFieldsModal, updatePrFields } from 'reducers/RemarkablePoints/remarkablePoints'
import AddPrModal from './PrCreation/AddPrModal'

export default function AddPrButton(): ReactElement {
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(toggleDisplayInputFieldsModal(true))
    dispatch(updatePrFields([]))
  }

  return (
    <>
      <Button
        startIcon={<PinDropOutlinedIcon />}
        onClick={handleClick}
      >
        {terms.MultiYearPlan.addPrBtn}
      </Button>
      <AddPrModal />
    </>
  )
}
