import { ReactElement } from 'react'
import ActionButton from 'components/common/Buttons/ActionButton'
import { useDispatch } from 'react-redux'
import { updateImportFlag, updateResponseError } from 'reducers/import'

import '../label.scss'
import terms from 'common/terms'
import history from 'customHistory'
import PathNames from 'common/PathNames'

export default function ImportPortfolio(): ReactElement {
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(updateImportFlag(true))
    history.push(PathNames.Import)
    dispatch(updateResponseError(undefined))
  }

  return (
    <ActionButton
      text={terms.Portfolio.Import.importPortfolio}
      onClick={handleClick}
      buttonStyle="import-portfolio-btn"
    />
  )
}
