import { ReactElement } from 'react'
import ActionButton from 'components/common/Buttons/ActionButton'
import terms from 'common/terms'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { patchOperation } from 'reducers/MultiYearPlan/OperationActions/operationactions.thunk'
import { toggleEmptyFields } from 'reducers/MultiYearPlan/OperationActions/operationactions'
import { getPhasesList, getFerrolocList, hasEmptyFields } from '../OperationFields/utils'

export default function ValidationModifyButton(): ReactElement {
  const dispatch = useDispatch()
  const {
    operationLabel, selectedOperation, subClassificationId, phaseFields, ferrolocFields,
  } = useSelector((state: RootState) => state.mypoperationactions)
  const { activeFile } = useSelector((state: RootState) => state.files)

  const handleClick = () => {
    if (hasEmptyFields(operationLabel, subClassificationId, getPhasesList(phaseFields),
      getFerrolocList(ferrolocFields))) {
      dispatch(toggleEmptyFields(true))
    } else {
      const params = {
        repertoryId: activeFile.directory,
        folderId: activeFile.folder,
        projectId: activeFile.id,
        operationId: selectedOperation.id,
        operation: {
          label: operationLabel,
          years: getPhasesList(phaseFields),
          sub_classification: subClassificationId,
          railway_locations: getFerrolocList(ferrolocFields),
        },
      }
      dispatch(patchOperation(params))
    }
  }
  return (
    <ActionButton
      text={terms.MultiYearPlan.Actions.modifyObject}
      onClick={handleClick}
      buttonStyle="operation-validation-btn"
    />
  )
}
