import {
  ExtendingEditContext, GeoEditorState, setEditorLayerMode, setExtendingEditContext,
  setExtendingFeatureCollection, setGeoEditorModeConfig, setGeoJson, setMultiDragRefPoint,
  setSelectedFeatures, setSelectedPoints,
} from 'components/GeoEditor/reducer'
import { debounce } from 'lodash'
import { store } from 'Store'

import {
  EditAction, FeatureCollection, ImmutableFeatureCollection, Position,
} from '@nebula.gl/edit-modes'
import {
  Feature,
  featureCollection, LineString, lineString, point,
} from '@turf/helpers'
import turfRotate from '@turf/transform-rotate'

import getRotateAngle from '../snapping/angleSnapping'
import getNearestFeature from '../snapping/getNearestFeature'
import { Viewport } from '../snapping/updateSnappingFeatures'
import { EditorModeName } from '../types'
import { MultiDragEditContext, MultiRotateEditContext, SelectedPoints } from './CustomModifyMode'
import onMove from './onMove'
import {
  absoluteAngle, planeDistance, planeNearestPointOnLine, planeTranslate,
} from './utils'

const onClickPosition = (editAction: EditAction<FeatureCollection>): void => {
  const { updatedData } = editAction
  const editContext = editAction.editContext as ExtendingEditContext
  const { modeConfig } = store.getState().geoEditor as GeoEditorState
  store.dispatch(setSelectedFeatures(editContext.featureIndexes))
  store.dispatch(setSelectedPoints(undefined))

  // Extend line string
  const featureIndex = editContext.featureIndexes[0]
  const feature = updatedData.features[featureIndex]
  const { type } = feature.geometry
  if (type === 'LineString' || type === 'MultiLineString') {
    const clickedPointIndex = editContext.positionIndexes[editContext.positionIndexes.length - 1]
    const lineStringCoords = type === 'MultiLineString'
      ? feature.geometry.coordinates[editContext.positionIndexes[0]]
      : feature.geometry.coordinates
    const shouldExtend = clickedPointIndex === lineStringCoords.length - 1 || clickedPointIndex === 0
    if (shouldExtend) {
      const selectedFeatureCollection = featureCollection([lineString(lineStringCoords)]) as FeatureCollection
      store.dispatch(setGeoEditorModeConfig({
        ...modeConfig,
        drawAtFront: clickedPointIndex === 0,
      }))
      store.dispatch(setExtendingFeatureCollection(selectedFeatureCollection))
      store.dispatch(setExtendingEditContext(editContext))
      store.dispatch(setEditorLayerMode(EditorModeName.Extend))
    }
  }
}

const getNearestPoint = (refPoint: Position, points: (Position | undefined)[]) => {
  let bestPoint = points[0]
  let minDist = Infinity
  points.forEach(p => {
    if (p) {
      const distanceToPoint = planeDistance(refPoint, p)
      if (distanceToPoint < minDist) {
        bestPoint = p
        minDist = distanceToPoint
      }
    }
  })

  return bestPoint as Position
}

const onDragMultiPosition = (editAction: EditAction<FeatureCollection>): void => {
  const {
    selectedPoints, geoJson, snappingFeatures, multiDragRefPoint,
  } = store.getState().geoEditor as GeoEditorState
  if (selectedPoints && geoJson) {
    const { updatedData } = editAction
    const editContext = editAction.editContext as MultiDragEditContext
    let updatedGeoJson = new ImmutableFeatureCollection(updatedData)
    const { pointerDownMapCoords, mapCoords } = editContext
    let refPoint = multiDragRefPoint
    let snapRef = mapCoords
    if (!refPoint) {
      refPoint = point(getNearestPoint(
        pointerDownMapCoords, selectedPoints.reduce((points, current) => ([
          ...points,
          ...current.points,
        ]),
        [] as (Position | undefined)[]),
      ))
      snapRef = refPoint.geometry.coordinates as Position
      store.dispatch(setMultiDragRefPoint(refPoint))
    }
    let snappedPosition = refPoint.geometry.coordinates
    if (snappingFeatures) {
      const nearestFeature = getNearestFeature(mapCoords as [number, number], snappingFeatures)
      let tempSnappedPosition
      if (nearestFeature.geometry.type === 'Point') {
        tempSnappedPosition = nearestFeature.geometry.coordinates
      } else {
        tempSnappedPosition = planeNearestPointOnLine(
          nearestFeature as Feature<LineString>,
          snapRef,
        ).geometry.coordinates
      }
      snappedPosition = tempSnappedPosition as [number, number]
    }
    const usedBearing = absoluteAngle(refPoint.geometry.coordinates as Position, snappedPosition as Position)
    const usedDistance = planeDistance(
      refPoint.geometry.coordinates as Position, snappedPosition as Position,
    )
    selectedPoints.forEach(selectedPoint => {
      const { type } = geoJson.features[selectedPoint.featureIndex].geometry
      selectedPoint.points.forEach((coords, i) => {
        const positionIndexes = type === 'MultiLineString' ? [0, i] : [i]
        if (coords !== undefined) {
          const newCoords = planeTranslate(coords, usedDistance, usedBearing)
          updatedGeoJson = updatedGeoJson.replacePosition(selectedPoint.featureIndex, positionIndexes, newCoords)
        }
      })
    })
    store.dispatch(setGeoJson(updatedGeoJson.getObject()))
  }
}

const onRotateMultiPosition = (editAction: EditAction<FeatureCollection>): void => {
  const { selectedPoints, geoJson } = store.getState().geoEditor as GeoEditorState
  if (selectedPoints && geoJson) {
    const { updatedData } = editAction
    const editContext = editAction.editContext as MultiRotateEditContext
    let updatedGeoJson = new ImmutableFeatureCollection(updatedData)
    const { centroid, angle } = editContext

    // Get closest angle within allowed angles
    const snappedAngle = getRotateAngle(angle % 360)
    selectedPoints.forEach(selectedPoint => {
      const { type } = geoJson.features[selectedPoint.featureIndex].geometry
      selectedPoint.points.forEach((coords, i) => {
        const positionIndexes = type === 'MultiLineString' ? [0, i] : [i]
        if (coords !== undefined) {
          const newPoint = turfRotate(point(coords), snappedAngle, {
            pivot: centroid,
          })
          const newCoords = newPoint.geometry.coordinates as Position
          updatedGeoJson = updatedGeoJson.replacePosition(selectedPoint.featureIndex, positionIndexes, newCoords)
        }
      })
    })

    store.dispatch(setGeoJson(updatedGeoJson.getObject()))
  }
}

const onFinishMove = debounce((editAction: EditAction<FeatureCollection>): void => {
  const { selectedPoints, geoJson, multiDragRefPoint } = store.getState().geoEditor as GeoEditorState
  const { bearing, distance } = editAction.editContext as MultiDragEditContext
  if (geoJson) {
    if (selectedPoints) {
      const newSelectedFeaturesPointCoords: SelectedPoints = []
      selectedPoints.forEach((selectedPoint, i) => {
        newSelectedFeaturesPointCoords.push({
          ...selectedPoint,
          points: new Array(selectedPoint.points.length - 1),
        })
        selectedPoint.points.forEach((coords, j) => {
          if (coords !== undefined) {
            const newCoords = planeTranslate(coords, distance, bearing)
            newSelectedFeaturesPointCoords[i].points[j] = newCoords
          } else {
            newSelectedFeaturesPointCoords[i].points[j] = selectedPoint.points[j]
          }
        })
      })
      store.dispatch(setSelectedPoints(newSelectedFeaturesPointCoords))
    }
  }
  if (multiDragRefPoint) {
    store.dispatch(setMultiDragRefPoint(undefined))
  }
}, 300)

const onFinishRotate = debounce((editAction: EditAction<FeatureCollection>): void => {
  const { selectedPoints, geoJson } = store.getState().geoEditor as GeoEditorState
  const { centroid, angle } = editAction.editContext as MultiRotateEditContext
  const snappedAngle = getRotateAngle(angle % 360)
  if (geoJson) {
    if (selectedPoints) {
      const newSelectedFeaturesPointCoords: SelectedPoints = []
      selectedPoints.forEach((selectedPoint, i) => {
        newSelectedFeaturesPointCoords.push({
          ...selectedPoint,
          points: new Array(selectedPoint.points.length - 1),
        })
        selectedPoint.points.forEach((coords, j) => {
          if (coords !== undefined) {
            const newPoint = turfRotate(point(coords), snappedAngle, {
              pivot: centroid,
            })
            const newCoords = newPoint.geometry.coordinates as Position
            newSelectedFeaturesPointCoords[i].points[j] = newCoords
          } else {
            newSelectedFeaturesPointCoords[i].points[j] = selectedPoint.points[j]
          }
        })
      })
      store.dispatch(setSelectedPoints(newSelectedFeaturesPointCoords))
    }
  }
}, 500)

const onEdit = (editAction: EditAction<FeatureCollection>, viewport: Viewport): void => {
  const { editType, updatedData } = editAction
  if (editType === 'clickPosition') {
    onClickPosition(editAction)
  } else if (editType === 'dragMultiPosition') {
    onDragMultiPosition(editAction)
  } else if (editType === 'finishMovePosition') {
    onFinishMove(editAction)
  } else if (editType === 'rotateMultiPosition') {
    onRotateMultiPosition(editAction)
  } else if (editType === 'finishRotatePosition') {
    onFinishRotate(editAction)
  } else if (editType === 'movePosition') {
    onMove(editAction, viewport)
  } else {
    store.dispatch(setGeoJson(updatedData))
  }
}

export default onEdit
