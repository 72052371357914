import terms from 'common/terms'
import ActionButton from 'components/common/Buttons/ActionButton'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveStep } from 'reducers/import'
import { RootState } from 'Store'

export default function ImportDocumentButton(): ReactElement {
  const dispatch = useDispatch()
  const { activeStep, portfolio, showImportLoader } = useSelector((state: RootState) => state.import)

  const handleClick = () => {
    dispatch(setActiveStep(activeStep + 1))
  }

  return (
    <>
      {portfolio && portfolio.n_rows !== null && portfolio.category !== '' && !showImportLoader && (
      <ActionButton
        text={terms.Portfolio.Import.Buttons.continue}
        onClick={handleClick}
        rightIcon
        buttonStyle="start-btn"
      />
      ) }
    </>
  )
}
