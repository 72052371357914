/* eslint-disable no-useless-escape */
import FormInput from 'components/common/Inputs/FormInput'
import { ReactElement, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { Comment } from 'reducers/MultiYearPlan/Comments/types'
import { RootState } from 'Store'
import { InputField } from 'types'
import { updateComments } from 'reducers/MultiYearPlan/Comments/comments'
import terms from 'common/terms'
import { COMMENTS_FIELDS } from './CommentFields'

type Props = {
  comment: Comment
  commentIndex: number
  disabledInput: boolean
  disableAddBtn: (v: boolean) => void
}

export default function CartridgeVersionsFields({
  comment, commentIndex, disabledInput, disableAddBtn,
}: Props): ReactElement {
  const dispatch = useDispatch()
  const user = useSelector((state: RootState) => state.user)
  const { comments, responseError } = useSelector((state: RootState) => state.mypcomments)
  const [versionId, setVersionId] = useState('')
  const [versionIndex, setVersionIndex] = useState('')
  const [versionDate, setVersionDate] = useState('')
  const [versionEntity, setVersionEntity] = useState('')
  const [versionAuthor, setVersionAuthor] = useState(`${user.account.firstName} ${user.account.lastName}`)
  const [versionComment, setVersionComment] = useState('')

  useEffect(() => {
    if (versionIndex !== '' || versionDate !== '' || versionComment !== '') {
      const newVersion = {
        id: versionId,
        index: versionIndex,
        date: versionDate,
        entity: versionEntity,
        comment: versionComment,
        author: versionAuthor,
      }
      const newVersionFields = comments.map((c: Comment, index: number) => (index !== commentIndex
        ? c : newVersion))
      dispatch(updateComments(newVersionFields))
    }
  }, [versionIndex, versionDate, versionEntity, versionComment])

  useEffect(() => {
    // eslint-disable-next-line max-len
    if (versionIndex !== '' && versionDate.match(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/) && versionComment !== '') {
      disableAddBtn(false)
    } else {
      disableAddBtn(true)
    }
  }, [versionIndex, versionDate, versionEntity, versionComment])

  useEffect(() => {
    const actualVersion: Comment = comment
    if (actualVersion.id && actualVersion.id !== null) setVersionId((actualVersion.id.toString()))
    if (actualVersion.index !== null) setVersionIndex((actualVersion.index.toString()))
    if (actualVersion.date !== '') setVersionDate(actualVersion.date)
    if (actualVersion.entity !== '') setVersionEntity(actualVersion.entity)
    if (actualVersion.comment !== '') setVersionComment(actualVersion.comment)
  }, [comment])

  const getInputValue = (fieldLabel: string) => {
    switch (fieldLabel) {
      case terms.Inputs.versionIndex:
        return versionIndex
      case terms.Inputs.versionDate:
        return versionDate
      case terms.Inputs.version:
        return versionEntity
      case terms.Inputs.author:
        return versionAuthor !== '' ? comment.author : versionAuthor
      case terms.Inputs.versionComment:
        return versionComment
      default:
        return ''
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, fieldLabel: string) => {
    switch (fieldLabel) {
      case terms.Inputs.versionIndex:
        if (!Number.isNaN(+event.target.value)) {
          setVersionIndex(event.target.value)
        }
        break
      case terms.Inputs.versionDate:
        setVersionDate(event.target.value)
        break
      case terms.Inputs.version:
        setVersionEntity(event.target.value)
        break
      case terms.Inputs.author:
        setVersionAuthor(event.target.value)
        break
      case terms.Inputs.versionComment:
        setVersionComment(event.target.value)
        break
      default:
        break
    }
  }
  return (
    <Form autoComplete="off" className="comments-form-container">
      {COMMENTS_FIELDS.map((field: InputField, index: number) => (
        <FormInput
          key={field.key}
          field={{ ...field, hideLabel: commentIndex !== 0 }}
          onChange={e => handleChange(e, field.label)}
          value={getInputValue(field.label)}
          extraStyle="versions-form-input"
          disabled={!disabledInput}
          responseError={responseError}
          index={index}
        />
      ))}
    </Form>
  )
}
