import { RootState } from 'Store'
import UpdateModal from 'components/common/Modals/UpdateModal'
import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toggleDisplaySettingsPanel } from 'reducers/MultiYearPlan/Settings/settings'
import { toggleUpdateMypSections } from 'reducers/MultiYearPlan/multiYearPlan'
import terms from 'common/terms'
import { Typography } from '@mui/material'
import { resetValidateChange, toggleDisplayUpdateGeographicModal } from 'reducers/Files/files'
import { patchFile, updateGeographicPerimeter } from 'reducers/Files/files.thunk'
import { getFilesSectionsList } from 'reducers/MultiYearPlan/multiYearPlan.thunk'
import { displayTabContent } from '../utils'

export default function UpdateGeographicPerimeterModal(): ReactElement {
  const dispatch = useDispatch()
  const {
    displayUpdateGeographicModal, activeFile, responseError, validateChange,
  } = useSelector((state: RootState) => state.files)
  const { tabsList } = useSelector((state: RootState) => state.dashboard)
  const { activeRepertory } = useSelector((state: RootState) => state.repertories)
  const { activeFolder } = useSelector((state: RootState) => state.folders)

  useEffect(() => {
    if (validateChange) {
      dispatch(toggleDisplaySettingsPanel(false))
      dispatch(toggleUpdateMypSections(false))
      dispatch(getFilesSectionsList({
        repertoryId: activeFile.directory,
        folderId: activeFile.folder,
        fileId: activeFile.id,
      }))
      dispatch(resetValidateChange())
      displayTabContent(tabsList, activeFile, activeRepertory, activeFolder)
    }
  }, [validateChange])

  const handleClose = () => {
    dispatch(patchFile({
      repertoryId: activeFile.directory,
      folderId: activeFile.folder,
      file: { id: activeFile.id, abort_change: true },
    }))
    dispatch(updateGeographicPerimeter({
      repertoryId: activeFile.directory,
      folderId: activeFile.folder,
      file: { id: activeFile.id, abort_change: true },
    }))
    dispatch(toggleUpdateMypSections(false))
    dispatch(toggleDisplayUpdateGeographicModal(false))
  }

  const handleContinue = () => {
    dispatch(updateGeographicPerimeter({
      repertoryId: activeFile.directory,
      folderId: activeFile.folder,
      file: { id: activeFile.id, confirm_change: true },
    }))
    dispatch(toggleDisplayUpdateGeographicModal(false))
  }

  return (
    <UpdateModal
      open={displayUpdateGeographicModal}
      onModalClose={handleClose}
      modalTitle={terms.File.Declaration.Schema.updateGeographicPerimeter}
      content={(
        <Typography sx={{ padding: '1.5rem !important' }}>
          {responseError && typeof responseError.message.geographic_perimeter === 'string'
            ? responseError.message.geographic_perimeter : ''}
        </Typography>
        )}
      onUpdate={handleContinue}
      actionType={terms.Common.continue}
    />
  )
}
