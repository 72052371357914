import { ReactElement, useEffect, useState } from 'react'
import {
  Grid, InputBase, InputLabel, Paper,
} from '@mui/material'
import terms from 'common/terms'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { patchFile } from 'reducers/Files/files.thunk'
import UpdateModal from 'components/common/Modals/UpdateModal'
import { resetResponseError, toggleDisplayUpdateModal } from 'reducers/Files/files'

export default function UpdateFileModal():
 ReactElement {
  const dispatch = useDispatch()
  const { displayUpdateModal, contextFile } = useSelector((state: RootState) => state.files)
  const [newFileName, setNewFileName] = useState<string>('')

  useEffect(() => {
    setNewFileName(contextFile.name)
  }, [contextFile])

  const onModalClose = () => {
    dispatch(toggleDisplayUpdateModal(false))
    dispatch(resetResponseError())
  }

  const onRename = () => {
    dispatch(patchFile({
      repertoryId: contextFile.directory,
      folderId: contextFile.folder,
      file: { id: contextFile.id, name: newFileName },
    }))
  }

  const nameInput = () => (
    <Grid item width={400} mb={5} className="name-input">
      <div className="rename-input">
        <InputLabel shrink>
          {terms.File.fileName}
        </InputLabel>
        <Paper
          elevation={0}
          component="form"
          autoComplete="off"
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder={terms.File.fileName}
            value={newFileName}
            onChange={e => setNewFileName(e.target.value)}
            onKeyPress={event => {
              if (event.key === 'Enter') { event.preventDefault() }
            }}
            multiline
          />
        </Paper>
      </div>
    </Grid>
  )

  return (
    <UpdateModal
      open={displayUpdateModal}
      onModalClose={onModalClose}
      modalTitle={terms.File.Actions.renameFile}
      content={nameInput()}
      onUpdate={onRename}
      actionType={terms.Common.rename}
      isDisabled={contextFile.name === newFileName || newFileName === ''}
    />
  )
}
