import { Button, Menu, MenuItem } from '@mui/material'
import terms from 'common/terms'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  toggleOpenOperationContextMenu, toggleDisplayDeleteModal, toggleMoveOperationDialog,
  toggleOpenOperationColorDialog, toggleDisplayModifyOperationModal,
  toggleDisplayAddOperationModal,
  updateSelectedOperation,
} from 'reducers/MultiYearPlan/OperationActions/operationactions'

import { RootState } from 'Store'

export default function OperationsActionsMenu(): ReactElement {
  const dispatch = useDispatch()
  const {
    openOperationContextMenu, contextPosition,
  } = useSelector((state: RootState) => state.mypoperationactions)
  const handleClose = () => {
    dispatch(toggleOpenOperationContextMenu(false))
    dispatch(updateSelectedOperation(undefined))
  }

  const onClick = (action: (v: boolean) => void) => {
    dispatch(toggleOpenOperationContextMenu(false))
    dispatch(action(true))
  }

  return (
    <Menu
      open={openOperationContextMenu}
      onClose={handleClose}
      className="file-action-menu"
      anchorReference="anchorPosition"
      anchorPosition={{ top: contextPosition.y, left: contextPosition.x }}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
    >
      <MenuItem>
        <Button
          className="actions-menu-btn"
          onClick={() => onClick(toggleOpenOperationColorDialog)}
        >
          {terms.MultiYearPlan.Actions.modifyOperationColor}
        </Button>
      </MenuItem>
      <MenuItem>
        <Button
          className="actions-menu-btn"
          onClick={() => onClick(toggleDisplayModifyOperationModal)}
        >
          {terms.MultiYearPlan.Actions.modifyOperation}
        </Button>
      </MenuItem>
      <MenuItem>
        <Button
          className="actions-menu-btn"
          onClick={() => onClick(toggleDisplayAddOperationModal)}
        >
          {terms.MultiYearPlan.Actions.duplicateObject}
        </Button>
      </MenuItem>
      <MenuItem>
        <Button
          className="actions-menu-btn"
          onClick={() => onClick(toggleMoveOperationDialog)}
        >
          {terms.MultiYearPlan.Actions.moveLabel}
        </Button>
      </MenuItem>
      <MenuItem>
        <Button
          className="actions-menu-btn"
          onClick={() => onClick(toggleDisplayDeleteModal)}
        >
          {terms.Common.delete}
        </Button>
      </MenuItem>
    </Menu>
  )
}
