import { ReactElement, useEffect } from 'react'
import terms from 'common/terms'
import ActionButton from 'components/common/Buttons/ActionButton'

import '../repertory.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { patchRepertory, postRepertory } from 'reducers/Repertories/repertories.thunk'
import history from 'customHistory'
import PathNames from 'common/PathNames'
import { toggleCreatedRepertory } from 'reducers/Repertories/repertories'

export default function NameDeclarationButton(): ReactElement {
  const dispatch = useDispatch()
  const { newLabel, activeRepertory, createdRepertory } = useSelector((state: RootState) => state.repertories)

  useEffect(() => {
    if (createdRepertory) {
      history.push(PathNames.Picture)
      dispatch(toggleCreatedRepertory())
    }
  }, [createdRepertory])

  const handleClick = () => {
    if (activeRepertory) {
      if (newLabel !== activeRepertory.label) {
        dispatch(patchRepertory({
          repertoryId: activeRepertory.id,
          repertory: {
            label: newLabel,
          },
        }))
      } else {
        history.push(PathNames.Picture)
      }
    } else {
      dispatch(postRepertory(newLabel))
    }
  }

  return (
    <div className="d-flex justify-content-center">
      <ActionButton
        text={terms.Common.continue}
        onClick={handleClick}
        rightIcon
        buttonStyle="action-button"
        disabled={newLabel === ''}
      />
    </div>
  )
}
