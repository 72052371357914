import { ReactElement } from 'react'
import DocumentUpload from 'components/common/Import/DocumentUpload'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import mime from 'mime-types'
import { updateResponseError } from 'reducers/MultiYearPlan/Import/import'
import terms from 'common/terms'
import ImportNotice from 'components/common/Import/ImportNotice'
import { postFolderPortfolio, postRepertoryPortfolio } from 'reducers/MultiYearPlan/Import/import.thunk'

export default function DocumentChoice(): ReactElement {
  const dispatch = useDispatch()
  const { activeFile } = useSelector((state: RootState) => state.files)

  const handleDrop = (uploadedFile: File[]) => {
    if (uploadedFile.length !== 0) {
      const formData = new FormData()
      if (uploadedFile[0].type === '') {
        const file = new File(uploadedFile, uploadedFile[0].name,
          { type: mime.lookup(uploadedFile[0].name) as string })
        formData.append('file', file)
      } else {
        formData.append('file', uploadedFile[0])
      }
      if (activeFile) {
        if (activeFile.folder !== null) {
          dispatch(postFolderPortfolio({
            repertoryId: activeFile.directory,
            folderId: activeFile.folder,
            fileId: activeFile.id,
            portfolio: formData,
          }))
        } else {
          dispatch(postRepertoryPortfolio({
            repertoryId: activeFile.directory,
            fileId: activeFile.id,
            portfolio: formData,
          }))
        }
      }
    } else {
      dispatch(updateResponseError({ status: '', message: { file: terms.Portfolio.Import.acceptedFormat } }))
    }
  }
  return (
    <>
      <DocumentUpload handleDrop={handleDrop} />
      <ImportNotice />
    </>
  )
}
