import {
  deleteRequest, get, patch, post,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ObjectsLayer } from 'components/Map/ObjectsLayers/types'
import { ThunkApi } from 'types'
import { LabelInfo, LabelData, Tracks } from './types'

const sourceLayer = ObjectsLayer.label

type QueryParams = {
    projectId: number,
    labelId?: number,
    params: LabelData
}

const createLabel = createAsyncThunk<LabelInfo, QueryParams, ThunkApi>(
  'label/createLabel',
  async (params: QueryParams, thunkApi) => {
    try {
      const response: LabelInfo = await post(`/module_schematique/projects/${params.projectId}/labels/`,
        params.params)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const getLabelsLayer = createAsyncThunk<any, number>(
  'label/getLabelsLayer',
  async (projectId: number) => {
    const resp = await get(`/chartis/v2/layer/${sourceLayer}/geojson/sch/`, {
      project_id: projectId,
      page_size: 200000,
    })
    return resp
  },
)

const updateLabel = createAsyncThunk<LabelInfo, QueryParams, ThunkApi>(
  'label/updateLabel',
  async (params: QueryParams, thunkApi) => {
    try {
      const response: LabelInfo = await
      patch(`/module_schematique/projects/${params.projectId}/labels/${params.labelId}`, params.params)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

type DeleteParams = {
  projectId: number
  labelId: number
}

const deleteLabel = createAsyncThunk<number, DeleteParams, ThunkApi>(
  'label/deleteLabel',
  async (params: DeleteParams, thunkApi) => {
    try {
      await deleteRequest(`/module_schematique/projects/${params.projectId}/labels/${params.labelId}`)
      return params.labelId
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const getProjectLines = createAsyncThunk <Array<string>, number>(
  'label/getProjectLines',
  async (projectId: number) => {
    const resp = await get(`/module_schematique/projects/${projectId}/list_lignes/`)
    return resp
  },
)

type SearchProps = {
  projectId: number,
  lineCode?: string,
}

const getLineTracks = createAsyncThunk <Tracks, SearchProps>(
  'label/getLineTracks',
  async (params: SearchProps) => {
    const resp = await get(`/module_schematique/projects/${params.projectId}/list_lignes/`,
      { code_ligne: params.lineCode })
    return { line: params.lineCode, value: resp }
  },
)

const PortfolioService = {
  createLabel,
  getLabelsLayer,
  updateLabel,
  deleteLabel,
  getProjectLines,
  getLineTracks,
}

export default PortfolioService
