import { ReactElement } from 'react'
import CancelObjectModification from 'components/Synoptic/Buttons/CancelObjectModification'
import ValidateObject from 'components/Synoptic/Buttons/ValidateObject'

export default function LabelActionsButtons(): ReactElement {
  return (
    <div className="btn-container">
      <ValidateObject />
      <CancelObjectModification />
    </div>
  )
}
