import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { RootState } from 'Store'
import { updateErrorStatus, GeoEditorState } from '../reducer'

export default function Feedbacks(): ReactElement {
  const dispatch = useDispatch()
  const { saveStatus, saveMessage, saveSeverity } = useSelector((state: RootState): GeoEditorState => state.geoEditor)

  const handleClose = () => {
    dispatch(updateErrorStatus(false))
  }

  return (
    <>
      <Snackbar
        open={saveStatus}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert variant="filled" severity={saveSeverity}>
          {saveMessage}
        </Alert>
      </Snackbar>
    </>
  )
}
