import { ReactElement } from 'react'
import {
  Box, Button, IconButton, Modal, Typography,
} from '@mui/material'
import terms from 'common/terms'
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded'

import './file.scss'
import history from 'customHistory'
import PathNames from 'common/PathNames'
import { useDispatch, useSelector } from 'react-redux'
import { updateFileType } from 'reducers/Files/files'
import { FileTypes } from 'reducers/Files/types'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { RootState } from 'Store'
import { getPath } from '../../utils'

export default function FileCreationModal({ open, handleOpen }: {open: boolean, handleOpen: (v: boolean) => void}):
 ReactElement {
  const dispatch = useDispatch()
  const { activeRepertory } = useSelector((state: RootState) => state.repertories)
  const { activeFolder } = useSelector((state: RootState) => state.folders)
  const { activeFile } = useSelector((state: RootState) => state.files)

  const onSynopticClick = () => {
    history.push(`${getPath(activeRepertory, activeFolder, activeFile)}/${PathNames.FileSynoptic}`)
    dispatch(updateFileType(FileTypes.Synoptic))
  }

  const onSchemaClick = () => {
    history.push(`${getPath(activeRepertory, activeFolder, activeFile)}/${PathNames.FileSchema}`)
    dispatch(updateFileType(FileTypes.Schema))
  }

  return (
    <Modal
      open={open}
    >
      <Box className="file-creation-modal">
        <div className="creation-modal-header">
          <Typography>
            {terms.File.createDocument}
          </Typography>
          <IconButton onClick={() => handleOpen(false)}>
            <CloseRoundedIcon />
          </IconButton>
        </div>
        <div className="file-btn-container">
          <Button
            variant="contained"
            endIcon={<KeyboardArrowRightRoundedIcon />}
            onClick={onSynopticClick}
          >
            {terms.Common.synoptic}
          </Button>
          <Button
            variant="contained"
            endIcon={<KeyboardArrowRightRoundedIcon />}
            onClick={onSchemaClick}
          >
            {terms.Common.multiYearSchema}
          </Button>
        </div>
      </Box>
    </Modal>
  )
}
