import terms from 'common/terms'
import { InputField, InputTypes } from 'types'

export const CARTRIDGE_TITLE: InputField = {
  key: 'title',
  label: '',
  placeholder: '',
  required: true,
  inputType: InputTypes.SimpleField,
  type: 'textarea',
}

export const CARTRIDGE_WARNINGS: InputField = {
  key: 'warnings',
  label: '',
  placeholder: '',
  required: true,
  inputType: InputTypes.SimpleField,
  type: 'textarea',
}

export const VERSIONS_FIELDS: Array<InputField> = [
  {
    key: 'index',
    label: terms.Inputs.versionIndex,
    placeholder: '',
    required: true,
    inputType: InputTypes.SimpleField,
    pattern: '/d*',
  },
  {
    key: 'date',
    label: terms.Inputs.versionDate,
    placeholder: '',
    required: true,
    inputType: InputTypes.SimpleField,
  },
  {
    key: 'version',
    label: terms.Inputs.version,
    placeholder: '',
    required: true,
    inputType: InputTypes.SimpleField,
    type: 'textarea',
  },
  {
    key: 'author',
    label: terms.Inputs.author,
    placeholder: '',
    required: true,
    inputType: InputTypes.SimpleField,
    type: 'textarea',
  },
  {
    key: 'comment',
    label: terms.Inputs.versionComment,
    placeholder: '',
    required: false,
    inputType: InputTypes.SimpleField,
    type: 'textarea',
  },
]
