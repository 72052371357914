import { ReactElement } from 'react'
import terms from 'common/terms'
import { updateSelectedAttributValue } from 'reducers/MultiYearPlan/Import/import'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import AttributsChoiceContainer from './AttributsChoiceContainer'

import './mypImport.scss'
import { ImportErrorsFields } from './types'

export default function CriteriaChoice(): ReactElement {
  const dispatch = useDispatch()
  const { responseError } = useSelector((state: RootState) => state.mypImport)
  return (
    <AttributsChoiceContainer
      chipLabel={terms.MultiYearPlan.Import.criteria}
      placeholder={terms.MultiYearPlan.Import.criteriaPlaceholder}
      onChange={(_event, newValue) => dispatch(updateSelectedAttributValue(newValue))}
      errorMessage={responseError && responseError.message[ImportErrorsFields.CriteriaError]
        ? responseError.message[ImportErrorsFields.CriteriaError] : ''}
    />
  )
}
