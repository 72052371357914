import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkApi } from 'types'
import { get, patch } from '@osrdata/app_core/dist/requests'
import {
  GetParams, Operations, phaseFilter,
  PatchParams,
} from './types'

const getOperations = createAsyncThunk<Operations, GetParams, ThunkApi>(
  'mypoperations/getOperations',
  async (params: GetParams, thunkApi) => {
    if (params.folderId && params.folderId !== null) {
      try {
        const response: Operations = await
        // eslint-disable-next-line max-len
        get(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.fileId}/operations-representation/`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response: Operations = await
        // eslint-disable-next-line max-len
        get(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/operations-representation/`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

const getPhaseFilters = createAsyncThunk<phaseFilter[], GetParams, ThunkApi>(
  'mypoperations/getPhaseFilters',
  async (params: GetParams, thunkApi) => {
    if (params.folderId && params.folderId !== null) {
      try {
        const response : phaseFilter[] = await
        // eslint-disable-next-line max-len
        get(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.fileId}/phase-filters/`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response : phaseFilter[] = await
        // eslint-disable-next-line max-len
        get(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/phase-filters/`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

const patchPhaseFilter = createAsyncThunk<phaseFilter, PatchParams, ThunkApi>(
  'mypoperations/patchPhaseFilter',
  async (params: PatchParams, thunkApi) => {
    if (params.folderId && params.folderId !== null) {
      try {
        const response : phaseFilter = await
        // eslint-disable-next-line max-len
        patch(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.fileId}/phase-filters/${params.phaseFilterId}`,
          params.phaseFilter)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response : phaseFilter = await
        // eslint-disable-next-line max-len
        patch(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/phase-filters/${params.phaseFilterId}`,
          params.phaseFilter)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

export { getOperations, getPhaseFilters, patchPhaseFilter }
