import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import CreationService from 'components/Dashboard/Creation/CreationService'
import ObjectService from 'components/Objects/ObjectService'
import PortfolioService from 'components/Portfolio/PortfolioService'

export interface AppState {
  error: boolean | undefined,
  fullscreen: boolean,
  loading: boolean,
  createObjectError: boolean
  endOfAction: boolean
  severity: any
  message: string
}

const initialState: AppState = {
  error: false,
  fullscreen: false,
  loading: false,
  createObjectError: false,
  endOfAction: false,
  severity: '',
  message: '',

}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleFullscreen: state => {
      state.fullscreen = !state.fullscreen
    },
    getError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    resetEndOfAction: (state, action: PayloadAction<boolean>) => {
      state.endOfAction = action.payload
    },
    resetcreateObjectError: (state, action: PayloadAction<boolean>) => {
      state.createObjectError = action.payload
    },
  },
  extraReducers: builder => {
    builder.addMatcher(isAnyOf(
      CreationService.getProjectsList.pending,
      CreationService.updateProject.pending,
      CreationService.deleteProject.pending,
      CreationService.deleteSection.pending,

      ObjectService.createObject.pending,
      ObjectService.patchObject.pending,
      ObjectService.deleteObject.pending,

      PortfolioService.createLabel.pending,
      PortfolioService.updateLabel.pending,
      PortfolioService.deleteLabel.pending,
      PortfolioService.getProjectLines.pending,

    ), state => {
      state.loading = true
    })

    builder.addMatcher(isAnyOf(
      CreationService.getProjectsList.fulfilled,
      CreationService.updateProject.fulfilled,
      CreationService.deleteProject.fulfilled,
      CreationService.deleteSection.fulfilled,

      ObjectService.createObject.fulfilled,
      ObjectService.patchObject.fulfilled,
      ObjectService.deleteObject.fulfilled,

      PortfolioService.updateLabel.fulfilled,
      PortfolioService.deleteLabel.fulfilled,
      PortfolioService.getProjectLines.fulfilled,

    ), state => {
      state.loading = false
    })

    builder.addMatcher(isAnyOf(
      PortfolioService.createLabel.fulfilled,
    ), state => {
      state.loading = false
      state.error = true
      state.severity = 'success'
      state.message = 'Etiquette créee'
    })

    builder.addMatcher(isAnyOf(
      PortfolioService.updateLabel.fulfilled,
    ), state => {
      state.loading = false
      state.error = true
      state.severity = 'success'
      state.message = 'Etiquette modifiée et positionnée'
    })

    builder.addMatcher(isAnyOf(
      CreationService.getProjectsList.rejected,
      CreationService.updateProject.rejected,
      CreationService.deleteProject.rejected,
      CreationService.deleteSection.rejected,

      ObjectService.createObject.rejected,
      ObjectService.patchObject.rejected,
      ObjectService.deleteObject.rejected,

      PortfolioService.createLabel.rejected,
      PortfolioService.updateLabel.rejected,
      PortfolioService.deleteLabel.rejected,
      PortfolioService.getProjectLines.rejected,

      ObjectService.getSignalsLayer.rejected,
      ObjectService.GetAdvLayer.rejected,
      ObjectService.GetRkLayer.rejected,
      ObjectService.GetPeLayer.rejected,
      ObjectService.GetTeLayer.rejected,
    ), state => {
      state.error = true
      state.loading = false
      state.severity = 'warning'
      state.message = 'Une erreur est survenue'
    })

    builder.addMatcher(isAnyOf(
      ObjectService.createObject.rejected,
    ), state => {
      state.createObjectError = true
    })
    builder.addMatcher(isAnyOf(
      ObjectService.createObject.fulfilled,
    ), state => {
      state.createObjectError = false
      state.endOfAction = true
    })
  },
})

export const {
  toggleFullscreen, getError, setError, setLoading, resetEndOfAction, resetcreateObjectError,
} = appSlice.actions

export default appSlice.reducer
