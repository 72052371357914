import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import CreationService, { DeleteParams, UpdateParams } from 'components/Dashboard/Creation/CreationService'
import { CreationLevels, Project, Section } from 'components/Dashboard/types'
import { ResponseError } from 'types'

interface CreationState {
    projectsList: Array<Project>
    creationMode: boolean
    creationLevel?: number
    createdProject?: Project
    updatedSynoptic?: Project
    sectionsList: Array<Section>
    activeProject?: Project
    layerBbox: GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties> | null
    responseError?: ResponseError
    emptyFields: boolean
    updatedSection?: Section
    pagesCount: number
    activePage: number
    validationNotice: boolean
    deletedProject: any
    modifySections: boolean
    disableSectionsUpdate: boolean
    exportSettingsUpdated: boolean
    projectZoom?: number
    zoomLevelUpdated: boolean
}

const initialState: CreationState = {
  projectsList: [],
  creationMode: false,
  creationLevel: undefined,
  createdProject: undefined,
  updatedSynoptic: undefined,
  sectionsList: [],
  activeProject: undefined,
  layerBbox: null,
  responseError: undefined,
  emptyFields: false,
  updatedSection: undefined,
  pagesCount: 0,
  activePage: 1,
  validationNotice: false,
  deletedProject: null,
  modifySections: false,
  disableSectionsUpdate: false,
  exportSettingsUpdated: false,
  zoomLevelUpdated: false,
}

const REQUEST_LIMIT = 10

const addProject = (state: CreationState, action: PayloadAction<Project>) => {
  state.createdProject = action.payload
  state.projectsList.push(action.payload)
  state.creationLevel = action.payload.status
}

const addSection = (state: CreationState, action: PayloadAction<Section>) => {
  state.sectionsList.push(action.payload)
  state.responseError = undefined
  state.updatedSection = undefined
  state.emptyFields = false
}

const updateProject = (state: CreationState, action: PayloadAction<UpdateParams>) => {
  state.projectsList = state.projectsList.map(project => (project.id === action.payload.project.id
    ? action.payload.project : project))
  state.createdProject = action.payload.project
  state.creationLevel = action.payload.creationLevel
  state.responseError = undefined
}

const deleteSection = (state: CreationState, action: PayloadAction<DeleteParams>) => {
  state.sectionsList = state.sectionsList.filter((section: Section) => section.id !== action.payload.sectionId)
  const updatedProject = state.projectsList.filter(project => project.id === action.payload.projectId)
  state.projectsList = state.projectsList.map(project => (project.id === action.payload.projectId
    ? { ...updatedProject[0], sections: state.sectionsList } : project))
}

export const creationSlice = createSlice({
  name: 'creation',
  initialState,
  reducers: {
    updateProjectsList: (state, action: PayloadAction<Array<Project>>) => {
      state.projectsList = action.payload
    },
    updateCreationMode: (state, action: PayloadAction<boolean>) => {
      state.creationMode = action.payload
    },
    updateCreationLevel: (state, action: PayloadAction<number | undefined>) => {
      state.creationLevel = action.payload
    },
    updateCreatedProject: (state, action: PayloadAction<Project | undefined>) => {
      state.createdProject = action.payload
    },
    setUpdatedSynoptic: (state, action: PayloadAction<Project | undefined>) => {
      state.updatedSynoptic = action.payload
    },
    updateActiveProject: (state, action: PayloadAction<Project | undefined>) => {
      state.activeProject = action.payload
    },
    updateEmptyFields: (state, action: PayloadAction<boolean>) => {
      state.emptyFields = action.payload
    },
    setUpdatedSection: (state, action: PayloadAction<Section | undefined>) => {
      state.updatedSection = action.payload
    },
    resetResponseError: (state, action: PayloadAction<ResponseError | undefined>) => {
      state.responseError = action.payload
    },
    setActivePage: (state, action: PayloadAction<number>) => {
      state.activePage = action.payload
    },
    toggleValidationNotice: (state, action: PayloadAction<boolean>) => {
      state.validationNotice = action.payload
    },
    toggleModifySections: (state, action: PayloadAction<boolean>) => {
      state.modifySections = action.payload
    },
    updateSectionsList: (state, action: PayloadAction<Array<Section>>) => {
      state.sectionsList = action.payload
    },
    toggleDisableSectionsUpdate: (state, action: PayloadAction<boolean>) => {
      state.disableSectionsUpdate = action.payload
    },
    toggleUpdateExportSettingsUpdated: (state, action: PayloadAction<boolean>) => {
      state.exportSettingsUpdated = action.payload
    },
    updateProjectZoom: (state, action: PayloadAction<number>) => {
      state.projectZoom = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(CreationService.getProjectsList.fulfilled, (state, action) => {
      state.projectsList = action.payload.results
      const pages = action.payload.count % REQUEST_LIMIT !== 0
        ? Math.floor(action.payload.count / REQUEST_LIMIT) + 1 : action.payload.count / REQUEST_LIMIT
      state.pagesCount = pages
    })
    builder.addCase(CreationService.createProject.fulfilled, addProject)
    builder.addCase(CreationService.updateProject.fulfilled, updateProject)
    builder.addCase(CreationService.updateProject.rejected, (state, action) => {
      state.responseError = action.payload
    })
    builder.addCase(CreationService.deleteProject.fulfilled, (state, action) => {
      state.deletedProject = action.payload
    })
    builder.addCase(CreationService.getSectionsList.fulfilled, (state, action) => {
      state.sectionsList = action.payload
    })
    builder.addCase(CreationService.declareSection.fulfilled, addSection)
    builder.addCase(CreationService.declareSection.rejected, (state, action) => {
      state.responseError = action.payload
    })
    builder.addCase(CreationService.deleteSection.fulfilled, deleteSection)
    builder.addCase(CreationService.getSingleProject.fulfilled, (state, action) => {
      state.activeProject = action.payload
    })
    builder.addCase(CreationService.importSections.fulfilled, state => {
      state.creationLevel = CreationLevels.Geographic
    })
    builder.addCase(CreationService.getLayersBbox.fulfilled, (state, action) => {
      state.layerBbox = action.payload
    })
    builder.addCase(CreationService.updateExportSettings.fulfilled, state => {
      state.exportSettingsUpdated = true
    })
    builder.addCase(CreationService.updateExportSettings.rejected, (state, action) => {
      state.responseError = action.payload
    })
    builder.addCase(CreationService.updateZoomLevel.fulfilled, (state, action) => {
      state.zoomLevelUpdated = true
      state.activeProject = action.payload
      state.projectsList = state.projectsList.map(project => (project.id === action.payload.id
        ? action.payload : project))
      state.createdProject = action.payload
    })
    builder.addCase(CreationService.updateZoomLevel.rejected, (state, action) => {
      state.responseError = action.payload
    })
  },
})

export const {
  updateProjectsList, updateCreationMode, updateCreationLevel, updateCreatedProject, setUpdatedSynoptic,
  updateActiveProject, updateEmptyFields, setUpdatedSection, resetResponseError, setActivePage, toggleValidationNotice,
  toggleModifySections, updateSectionsList, toggleDisableSectionsUpdate, toggleUpdateExportSettingsUpdated,
  updateProjectZoom,
} = creationSlice.actions

export default creationSlice.reducer
