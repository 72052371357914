import { ReactElement } from 'react'
import BigLoader from 'components/common/BigLoader/BigLoader'

export default function ImportLoader({ title }: {title: string}): ReactElement {
  return (
    <div className="d-flex flex-column">
      <div className="spinner"><BigLoader /></div>
      {title}
    </div>
  )
}
