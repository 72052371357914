/* eslint-disable no-prototype-builtins */
import history from 'customHistory'
import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ActionButton from 'components/common/Buttons/ActionButton'
import { setActiveStep } from 'reducers/MultiYearPlan/Import/import'
import { RootState } from 'Store'
import terms from 'common/terms'
import { toggleDisplaySettingsPanel } from 'reducers/MultiYearPlan/Settings/settings'
import { generateRepresentation } from 'reducers/MultiYearPlan/OperationActions/operationactions.thunk'
import { regenerateClassifications } from 'reducers/MultiYearPlan/Import/import.thunk'
import { toggleGenerateRepresentation } from 'reducers/MultiYearPlan/OperationActions/operationactions'
import { getPath } from '../../../../utils'
import ImportActionButton from './ImportActionButton'

export default function MypGenerationButton(): ReactElement {
  const dispatch = useDispatch()
  const { activeRepertory } = useSelector((state: RootState) => state.repertories)
  const { activeFolder } = useSelector((state: RootState) => state.folders)
  const { activeFile } = useSelector((state: RootState) => state.files)
  const { isLoading } = useSelector((state: RootState) => state.mypImport)
  const { generatedRepresentation } = useSelector((state: RootState) => state.mypoperationactions)

  useEffect(() => {
    if (generatedRepresentation) {
      dispatch(setActiveStep(0))
      dispatch(toggleDisplaySettingsPanel(false))
      history.push(getPath(activeRepertory, activeFolder, activeFile))
      dispatch(toggleGenerateRepresentation(false))
    }
  }, [generatedRepresentation])

  const handleGenerate = () => {
    dispatch(generateRepresentation({
      repertoryId: activeFile.directory,
      folderId: activeFile.folder,
      fileId: activeFile.id,
    }))
  }

  const handleRegenerate = () => {
    dispatch(regenerateClassifications({
      repertoryId: activeFile.directory,
      folderId: activeFile.folder,
      fileId: activeFile.id,
    }))
  }
  return (
    <div className="d-flex justify-content-center">
      <ActionButton
        text={isLoading ? terms.Common.loading : terms.MultiYearPlan.Import.regenerateMyp}
        onClick={handleRegenerate}
        buttonStyle="myp-import-regeneration-btn mr-3"
        disabled={isLoading}
      />
      <ImportActionButton handleClick={handleGenerate} />
    </div>
  )
}
