import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { getPhaseFilters, patchPhaseFilter } from 'reducers/MultiYearPlan/Operations/operations.thunk'
import terms from 'common/terms'
import { phaseFilter } from 'reducers/MultiYearPlan/Operations/types'
import FilterAccordion from './FilterAccordion'

export default function PhasesAccordion(): ReactElement {
  const dispatch = useDispatch()
  const { activeFile } = useSelector((state: RootState) => state.files)
  const { phaseFilters } = useSelector((state: RootState) => state.mypoperations)

  useEffect(() => {
    dispatch(getPhaseFilters({
      repertoryId: activeFile.directory,
      folderId: activeFile.folder,
      fileId: activeFile.id,
    }))
  }, [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, phase: phaseFilter) => {
    dispatch(patchPhaseFilter({
      repertoryId: activeFile.directory,
      folderId: activeFile.folder,
      fileId: activeFile.id,
      phaseFilterId: phase.id,
      phaseFilter: {
        is_visible: event.target.checked,
      },
    }))
  }

  return (
    <FilterAccordion
      options={phaseFilters}
      handleChange={(event, option) => handleChange(event, option as phaseFilter)}
      summary={terms.MultiYearPlan.SettingsPanel.phases}
      optionLabelType="phase"
    />
  )
}
