import { ReactElement, useEffect, useState } from 'react'
import SettingsIcon from '@mui/icons-material/Settings'
import { Repertory } from 'reducers/Repertories/types'
import {
  Card, CardActionArea, CardHeader, IconButton, Tooltip, Typography,
} from '@mui/material'
import terms from 'common/terms'
import history from 'customHistory'
import PathNames from 'common/PathNames'
import { useDispatch } from 'react-redux'
import { toggleCreatedRepertory, updateActiveRepertory } from 'reducers/Repertories/repertories'
import { getAuthConfig } from 'components/Repertories/RepertoryContent/utils'
import axios from 'axios'
import ActionMenu from './RepertoryActions/ActionMenu'

export default function RepertoryCard({ repertory }: {repertory: Repertory}): ReactElement {
  const dispatch = useDispatch()
  const [imageUrl, setImageUrl] = useState('')

  const onSettingClick = () => {
    dispatch(toggleCreatedRepertory())
    dispatch(updateActiveRepertory(repertory))
    history.push(PathNames.Repertory)
  }

  const goToRepertory = () => {
    dispatch(updateActiveRepertory(repertory))
    history.push(`${PathNames.RepertoryContent}/${repertory.id}`)
  }

  useEffect(() => {
    if (repertory.picture_url !== null) {
      const baseURL = repertory.picture_url
      const config = getAuthConfig()
      axios.get(baseURL, config)
        .then(response => {
          setImageUrl(response.data)
        })
        .catch(error => {
          if (error.response) {
            setImageUrl(error.response.data)
          }
        })
    }
  }, [repertory.picture_url])

  return (
    <Card
      className="repertory-card"
      style={{ backgroundImage: `url(${imageUrl})` }}
    >
      {!repertory.has_shared_document && (
      <CardHeader
        avatar={(
          <Tooltip title={terms.Repertory.settingTooltip}>
            <IconButton onClick={onSettingClick}>
              <SettingsIcon />
            </IconButton>
          </Tooltip>
      )}
        action={<ActionMenu repertoryId={repertory.id} />}
      />
      )}
      <CardActionArea onClick={goToRepertory}>
        <Typography>
          {repertory.label}
        </Typography>
      </CardActionArea>
    </Card>
  )
}
