import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import mime from 'mime-types'
import { updateResponseError } from 'reducers/import'
import terms from 'common/terms'
import DocumentUpload from 'components/common/Import/DocumentUpload'
import ImportService from '../ImportService'
import ImportNotice from '../../../common/Import/ImportNotice'

import './content.scss'

export default function DocumentChoice(): ReactElement {
  const dispatch = useDispatch()
  const { activeProject } = useSelector((state: RootState) => state.creation)

  const handleDrop = (uploadedFile: File[]) => {
    if (uploadedFile.length !== 0) {
      const formData = new FormData()
      formData.append('project_id', activeProject.id)
      if (uploadedFile[0].type === '') {
        const file = new File(uploadedFile, 'file_name',
          { type: mime.lookup(uploadedFile[0].name) as string })
        formData.append('file', file)
      } else {
        formData.append('file', uploadedFile[0])
      }
      dispatch(ImportService.uploadFile(formData))
    } else {
      dispatch(updateResponseError({ status: '', message: terms.Portfolio.Import.acceptedFormat }))
    }
  }

  return (
    <div>
      <DocumentUpload handleDrop={handleDrop} />
      <ImportNotice />
    </div>
  )
}
