import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkApi } from 'types'
import {
  get, patch,
} from '@osrdata/app_core/dist/requests'
import {
  GetParams, TitleBlockType,
  FilterType, PatchFilterParams,
} from './types'

const getTitleBlock = createAsyncThunk<TitleBlockType, GetParams, ThunkApi>(
  'mypTitleBlock/getTitleBlock',
  async (params: GetParams, thunkApi) => {
    if (params.folderId && params.folderId !== null) {
      try {
        const response: TitleBlockType = await
        // eslint-disable-next-line max-len
        get(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.fileId}/cartouche/`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response: TitleBlockType = await
        // eslint-disable-next-line max-len
        get(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/cartouche/`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

const getTitleBlockFilters = createAsyncThunk<FilterType[], GetParams, ThunkApi>(
  'mypTitleBlock/getTitleBlockFilters',
  async (params: GetParams, thunkApi) => {
    if (params.folderId && params.folderId !== null) {
      try {
        const response: FilterType[] = await
        // eslint-disable-next-line max-len
        get(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.fileId}/cartouche-filters/`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response: FilterType[] = await
        // eslint-disable-next-line max-len
        get(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/cartouche-filters/`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

const pacthTitleBlockFilter = createAsyncThunk<FilterType, PatchFilterParams, ThunkApi>(
  'mypTitleBlock/pacthTitleBlockFilter',
  async (params: PatchFilterParams, thunkApi) => {
    if (params.folderId && params.folderId !== null) {
      try {
        const response: FilterType = await
        // eslint-disable-next-line max-len
        patch(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.fileId}/cartouche-filters/${params.filterId}`,
          params.objectFilter)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response: FilterType = await
        // eslint-disable-next-line max-len
        patch(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/cartouche-filters/${params.filterId}`,
          params.objectFilter)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

export {
  getTitleBlock,
  getTitleBlockFilters,
  pacthTitleBlockFilter,
}
