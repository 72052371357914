import { createAsyncThunk } from '@reduxjs/toolkit'
import { deleteRequest, get, post } from '@osrdata/app_core/dist/requests'
import { ThunkApi } from 'types'
import { DefectedOperation, GetParams, PostParams } from './types'
import { DeleteParams, Operation } from '../OperationActions/types'

const getDefectedOperations = createAsyncThunk<DefectedOperation[], GetParams, ThunkApi>(
  'mypLibrary/getDefectedOperations',
  async (params: GetParams, thunkApi) => {
    if (params.folderId && params.folderId !== null) {
      try {
        const response: DefectedOperation[] = await
        // eslint-disable-next-line max-len
        get(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.fileId}/defected-operations/`,
          { search: params.search })
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response: DefectedOperation[] = await
        // eslint-disable-next-line max-len
        get(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/defected-operations/`, { search: params.search })
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

const getPositionedOperations = createAsyncThunk<Operation[], GetParams, ThunkApi>(
  'mypLibrary/getPositionedOperations',
  async (params: GetParams, thunkApi) => {
    if (params.folderId && params.folderId !== null) {
      try {
        const response: Operation[] = await
        // eslint-disable-next-line max-len
        get(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.fileId}/operations/`,
          { search: params.search })
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response: Operation[] = await
        // eslint-disable-next-line max-len
        get(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/operations/`, { search: params.search })
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

const deleteDefectedOperation = createAsyncThunk<null, DeleteParams, ThunkApi>(
  'mypLibrary/deleteDefectedOperation',
  async (params: DeleteParams, thunkApi) => {
    if (params.folderId && params.folderId !== null) {
      try {
        const response = await
        // eslint-disable-next-line max-len
        deleteRequest(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.projectId}/defected-operations/${params.operationId}`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response = await
        // eslint-disable-next-line max-len
        deleteRequest(`/module_schematique/directories/${params.repertoryId}/projects/${params.projectId}/defected-operations/${params.operationId}`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

const getDefectedOperation = createAsyncThunk<DefectedOperation, GetParams, ThunkApi>(
  'mypLibrary/getDefectedOperation',
  async (params: GetParams, thunkApi) => {
    if (params.folderId && params.folderId !== null) {
      try {
        const response: DefectedOperation = await
        // eslint-disable-next-line max-len
        get(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.fileId}/defected-operations/${params.operationId}`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response: DefectedOperation = await
        // eslint-disable-next-line max-len
        get(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/defected-operations/${params.operationId}`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

const fixDefectedOperation = createAsyncThunk<Operation, PostParams, ThunkApi>(
  'mypLibrary/fixDefectedOperation',
  async (params: PostParams, thunkApi) => {
    if (params.folderId && params.folderId !== null) {
      try {
        const response: Operation = await
        // eslint-disable-next-line max-len
        post(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.fileId}/defected-operations/${params.operation.id}/fix`,
          params.operation)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response: Operation = await
        // eslint-disable-next-line max-len
        post(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/defected-operations/${params.operation.id}/fix`,
          params.operation)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

export {
  getDefectedOperations,
  getPositionedOperations,
  deleteDefectedOperation,
  getDefectedOperation,
  fixDefectedOperation,
}
