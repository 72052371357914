import { ReactElement } from 'react'
import { Container, Fade } from '@mui/material'
import ContainerHeader from 'components/common/Container/ContainerHeader'
import { useLocation } from 'react-router-dom'
import history from 'customHistory'
import PathNames from 'common/PathNames'
import terms from 'common/terms'
import ContainerFooter from 'components/common/Container/ContainerFooter'
import { useDispatch } from 'react-redux'
import { resetActiveRepertory, setError } from 'reducers/Repertories/repertories'
import PictureDeclarationButtons from './PictureDeclarationButtons'

type acceptedFile = Blob
type stateType = Array<acceptedFile>

export default function PicturePreview(): ReactElement {
  const { state: acceptedFileArray } = useLocation<stateType>()
  const dispatch = useDispatch()
  const imageFile = acceptedFileArray.map(
    file => Object.assign(
      file, { preview: URL.createObjectURL(file) },
    ),
  )

  const handleRollBack = () => {
    history.push(PathNames.Picture)
    dispatch(setError())
  }

  const handleClose = () => {
    history.push(PathNames.Home)
    dispatch(resetActiveRepertory())
  }

  const styles = {
    backgroundImage: `url(${imageFile[0].preview})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: 'calc(100vh - 68px)',
  }

  return (
    <main>
      <Fade timeout={400} in mountOnEnter unmountOnExit>
        <Container maxWidth={false} disableGutters sx={{ height: '100%' }}>
          <ContainerHeader
            onRollBack={handleRollBack}
            rollBackText={terms.Repertory.backToPicture}
            onClose={handleClose}
          />
          <ContainerFooter content={<PictureDeclarationButtons acceptedFiles={acceptedFileArray} />} style={styles} />
        </Container>
      </Fade>
    </main>
  )
}
