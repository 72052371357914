import { Button, Menu, MenuItem } from '@mui/material'
import terms from 'common/terms'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  toggleOpenTbContextMenu, toggleDisplayDeleteModal,
  toggleDisplayModifyModal,
} from 'reducers/MultiYearPlan/TitleBlockActions/titleblockactions'

import { RootState } from 'Store'

export default function TbActionsMenu(): ReactElement {
  const dispatch = useDispatch()
  const {
    openTbContextMenu, contextPosition, selectedTbElement,
  } = useSelector((state: RootState) => state.mypTitleBlockActions)
  const handleClose = () => {
    dispatch(toggleOpenTbContextMenu(false))
  }

  const onClick = (action: (v: boolean) => void) => {
    dispatch(toggleOpenTbContextMenu(false))
    dispatch(action(true))
  }

  return (
    <Menu
      open={openTbContextMenu}
      onClose={handleClose}
      className="file-action-menu"
      anchorReference="anchorPosition"
      anchorPosition={{ top: contextPosition.y, left: contextPosition.x }}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
    >
      <MenuItem>
        <Button
          className="actions-menu-btn"
          onClick={() => onClick(toggleDisplayModifyModal)}
        >
          {terms.MultiYearPlan.Actions.modifyObject}
        </Button>
      </MenuItem>
      <MenuItem>
        <Button
          className="actions-menu-btn"
          onClick={() => onClick(toggleDisplayDeleteModal)}
        >
          {terms.Common.delete}
        </Button>
      </MenuItem>
      {selectedTbElement && selectedTbElement.url && (
      <MenuItem>
        <Button
          className="actions-menu-btn"
          onClick={() => window.open(selectedTbElement.url, '_blank')}
        >
          {terms.MultiYearPlan.TileBlock.goToUrl}
        </Button>
      </MenuItem>
      )}
    </Menu>
  )
}
