import { Alert, Snackbar } from '@mui/material'
import terms from 'common/terms'
import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toggleShowImportLoader, updateResponseError } from 'reducers/import'
import { RootState } from 'Store'
import CategoryChoice from './CategoryChoice'
import DocumentChoice from './DocumentChoice'
import EligibilityGrid from './EligibilityGrid'
import ImportedRowsNotice from './ImportedRowsNotice'

export default function ImportDocumentContent(): ReactElement {
  const dispatch = useDispatch()
  const { portfolio, showImportLoader, responseError } = useSelector((state: RootState) => state.import)

  useEffect(() => {
    if (portfolio && showImportLoader && (portfolio.n_rows === null || portfolio.eligibility !== '')) {
      setTimeout(() => dispatch(toggleShowImportLoader(!showImportLoader)), 1000)
    }
  }, [portfolio])

  useEffect(() => {
    if (showImportLoader && responseError) {
      setTimeout(() => dispatch(toggleShowImportLoader(!showImportLoader)), 1000)
    }
  }, [responseError])

  const handleClose = () => {
    dispatch(updateResponseError(undefined))
  }

  return (
    <>
      {responseError && (
      <Snackbar
        open={responseError && !portfolio && !showImportLoader}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert variant="filled" severity="warning">
          {responseError.status === 500 ? terms.Common.Errors.error : responseError.message}
        </Alert>
      </Snackbar>
      )}
      {!portfolio && !showImportLoader && <DocumentChoice />}
      {portfolio && portfolio.eligibility === '' && !showImportLoader && <EligibilityGrid />}
      {portfolio && portfolio.eligibility !== '' && portfolio.category === '' && !showImportLoader
       && <CategoryChoice />}
      {portfolio && portfolio.category !== '' && portfolio.n_rows !== null && !showImportLoader
       && <ImportedRowsNotice />}
    </>
  )
}
