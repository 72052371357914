/* eslint-disable max-len */
import { ReactElement, useEffect } from 'react'
import {
  Box, Fade, Grid, Grow, Typography,
} from '@mui/material'
import terms from 'common/terms'
import { Repertory } from 'reducers/Repertories/types'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { getRepertoriesList } from 'reducers/Repertories/repertories.thunk'
import OrderingMenu from 'components/common/OrderingMenu/OrderingMenu'
import { setError, updateOrderBy } from 'reducers/Repertories/repertories'
import { updateActiveProject, updateCreationLevel, updateSectionsList } from 'reducers/creation'
import { CreationLevels } from 'components/Dashboard/types'
import { updateActiveBlock } from 'reducers/synoptic'
import { FileTypes } from 'reducers/Files/types'
import history from 'customHistory'
import PathNames from 'common/PathNames'
import { toggleSelectTab, updateActiveTab } from 'reducers/dashboard'
import SnackBar from 'components/common/Snackbar/SnackBar'
import { updateCreationStatus } from 'reducers/MultiYearPlan/multiYearPlan'
import { getPath } from '../../utils'
import RepertoryCard from './RepertoryCard'

export default function RepertoriesContainer(): ReactElement {
  const dispatch = useDispatch()
  const {
    repertories, activeRepertory, orderBy, search, responseError,
  } = useSelector((state: RootState) => state.repertories)
  const { activeProject } = useSelector((state: RootState) => state.creation)
  const { selectTab } = useSelector((state: RootState) => state.dashboard)
  const { activeFile } = useSelector((state: RootState) => state.files)
  const { activeFolder } = useSelector((state: RootState) => state.folders)

  useEffect(() => {
    dispatch(getRepertoriesList({ ordering: orderBy, search: search !== '' ? search : null }))
  }, [])

  useEffect(() => {
    if (activeFile && selectTab && selectTab.tabId === activeFile.id) {
      if (activeFile.type === FileTypes.Synoptic) {
        if (activeProject && activeProject.id === activeFile.id) {
          dispatch(updateCreationLevel(activeProject.status))
          if (activeProject.status === CreationLevels.Geographic) {
            dispatch(updateActiveBlock(terms.Synoptic.createFondPlan))
          } else if (activeProject.status === CreationLevels.Name) {
            history.push(`${getPath(activeRepertory, activeFolder, activeFile)}/${PathNames.FileSynoptic}/${PathNames.TemporalPerimeter}`)
          } else if (activeProject.status === CreationLevels.Temporal) {
            dispatch(updateSectionsList(activeProject.sections))
            history.push(`${getPath(activeRepertory, activeFolder, activeFile)}/${PathNames.FileSynoptic}/${PathNames.GeographicPerimeter}`)
          }
          dispatch(toggleSelectTab(undefined))
          dispatch(updateActiveTab({
            tabName: activeProject.name,
            tabId: activeProject.id,
            fileType: activeFile.type,
            directoryId: activeFile.directory,
            folderId: activeFile.folder,
          }))
        }
      } else {
        dispatch(updateActiveProject(undefined))
        dispatch(updateCreationLevel(undefined))
        dispatch(updateActiveTab({
          tabName: activeFile.name,
          tabId: activeFile.id,
          fileType: activeFile.type,
          directoryId: activeFile.directory,
          folderId: activeFile.folder,
        }))
        dispatch(updateCreationStatus(activeFile.status))
        if (activeFile.status === CreationLevels.Name) {
          history.push(`${getPath(activeRepertory, activeFolder, activeFile)}/${PathNames.FileSchema}/${PathNames.TemporalPerimeter}`)
        } else if (activeFile.status === CreationLevels.Temporal) {
          history.push(`${getPath(activeRepertory, activeFolder, activeFile)}/${PathNames.FileSchema}/${PathNames.GeographicPerimeter}`)
        }
        dispatch(toggleSelectTab(undefined))
      }
    }
  }, [activeProject, selectTab, activeFile])

  return (
    <Fade in timeout={500}>
      <Box>
        <Grid
          container
          className="repertory-container"
        >
          <Grid item container xs={12} sm={10} md={8}>
            <Typography>
              {terms.HomePage.repertoriesLength}
              {' '}
              <span style={{ color: '#43454A' }}>
                (
                {repertories.length}
                )
              </span>
            </Typography>
            <OrderingMenu orderBy={orderBy} updateOrderBy={updateOrderBy} />
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={10}
            md={8}
            spacing={1}
            className="repertory-card-container"
          >
            {repertories.map((repertory: Repertory, index: number) => (
              <Grow
                in
                {...({ timeout: (index + 1) * 500 })}
                key={`${repertory.label}-${repertory.id}`}
              >
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3} sx={{ justifyContent: 'center' }}>
                  <RepertoryCard repertory={repertory} />
                </Grid>
              </Grow>
            ))}
          </Grid>
        </Grid>
        <SnackBar
          message={responseError && typeof responseError.message === 'string' && terms.Common.Errors.error}
          error={responseError && typeof responseError.message === 'string'}
          setError={setError}
        />
      </Box>
    </Fade>
  )
}
