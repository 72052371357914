import { ReactElement, useRef } from 'react'
import { Button } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import terms from 'common/terms'
import { InputDataType } from './utils'

import 'react-bootstrap-typeahead/css/Typeahead.css'
import './input.scss'

type Props = InputDataType & {
  setSearchValues: (v: string, index: number| string | undefined) => void,
  getSelectedValue: (v: string | string[], index: number| string | undefined) => string[],
  index?: number | string
}

const defaultProps = {
  index: undefined,
}
export default function SelectWithSearch({
  onChange, field, setSearchValues, getSelectedValue, index, value,
}: Props): ReactElement {
  const typeaheadRef = useRef<any>(null)

  const toggleButton = (isOpen: boolean, onClick: () => void) => (
    <Button
      className="toggle-button"
      onClick={onClick}
      onMouseDown={e => {
        e.preventDefault()
      }}
      disabled={field.disabled}
    >
      {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
    </Button>
  )

  const getValuesList = () => {
    if (typeof value === 'string') {
      if (value === '') {
        return field.values ? field.values as string[] : []
      }
      if (field.values) {
        return [value, ...((field.values as string[])?.filter((v: string) => v !== value))]
      }
      return []
    }
    if (value && value.length === 0) {
      return field.values ? field.values as string[] : []
    }
    if (field.values) return [...value, ...((field.values as string[])?.filter((v: string) => !value.includes(v)))]
    return []
  }

  return (
    <Typeahead
      onInputChange={(v: string) => {
        setSearchValues(v, index)
        if (v === '') {
          onChange(v)
        }
      }}
      onChange={(selected: any) => {
        if (field.multiple && field.values && field.values.length > 1) typeaheadRef.current?.toggleMenu()
        if (selected.length === 1) {
          onChange(selected[0])
          setSearchValues(selected[0], index)
        }
        if (selected.length > 1) {
          const selectedValues: string[] = Array.from(new Set(selected))
          onChange(selectedValues)
          if (selectedValues.filter((v: string) => !value.includes(v)).length !== 0) {
            setSearchValues(selectedValues.filter((v: string) => !value.includes(v))[0], index)
          }
        }
        if (selected.length === 0) {
          if (field.multiple) {
            onChange([])
          } else {
            onChange('')
          }
        }
      }}
      id="select-with-search"
      ref={typeaheadRef}
      options={getValuesList()}
      placeholder={field.placeholder}
      disabled={field.disabled}
      multiple={field.multiple}
      selected={getSelectedValue(value, index)}
      emptyLabel={terms.Common.noResults}
      filterBy={(option, props) => {
        if (value !== '' && value === props.text) {
          return true
        }
        return option.toLowerCase().indexOf(props.text.toLowerCase()) !== -1
      }}
    >
      {({ isMenuShown, toggleMenu }) => (
        toggleButton(isMenuShown, toggleMenu)
      )}
    </Typeahead>
  )
}

SelectWithSearch.defaultProps = defaultProps
