import { ReactElement } from 'react'
import { IconButton } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { updateZoomPr } from 'reducers/RemarkablePoints/remarkablePoints'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'

export default function ZoomButtons(): ReactElement {
  const dispatch = useDispatch()
  const { zoomPr } = useSelector((state: RootState) => state.remarkablePoints)

  const handleZoomOut = () => {
    if (zoomPr > 200) {
      dispatch(updateZoomPr(zoomPr - 100))
    }
  }

  const handleZoomIn = () => {
    dispatch(updateZoomPr(zoomPr + 100))
  }

  return (
    <>
      <IconButton onClick={handleZoomOut}>
        <ZoomOutIcon />
      </IconButton>
      <IconButton onClick={handleZoomIn}>
        <ZoomInIcon />
      </IconButton>
    </>
  )
}
