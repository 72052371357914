import terms from 'common/terms'

/* eslint-disable camelcase */
type TimeRange = {
 start_year: number | null,
 end_year: number | null
}

export type Project = {
    creation_date?: string,
    id?: number,
    last_modified?: string,
    name?: string,
    time_range?: TimeRange
    owner_id?: string,
    status?: number
    sections?: Array<Section>
    last_file_url?: string
    tasks?: Task[]
    rk_step?: number
    zoom?: number
}

export type ActiveTab = {
    tabName?: string
    tabId?: number
    fileType?: string
    directoryId?: number
    folderId?: number
}

export enum CreationLevels {
    Name = 0,
    Temporal = 1,
    Geographic = 2
}

export type GeographicCoordinates = {
    name?: string,
    code_ligne?: string,
    pk_sncf_debut?: string,
    pk_sncf_fin?: string
}

export type Section = {
    id: number,
    name: string,
    status: string,
    code_ligne: string,
    pk_sncf_debut: string,
    pk_sncf_fin: string
    tasks: Task[] | undefined
}

export type Task = {
    celery_id: string,
    name: string,
    project_id: null | number,
    result: null | string,
    section_id: number,
    status: string,
}

export enum OrderBy {
    AscendingLastModification = 'last_modified',
    DescendingLastModification = '-last_modified',
    AscendingCreationDate = 'creation_date',
    DescendingCreationDate = '-creation_date',
    AscendingAlphabetical = 'label',
    DescendingAlphabetical = '-label',
    AscendingFileAlphabetical = 'name',
    DescendingFileAlphabetical = '-name'
}

export const OrderingList = [
  {
    key: OrderBy.AscendingAlphabetical,
    value: terms.Common.alphabeticalOrder,
  },
  {
    key: OrderBy.DescendingAlphabetical,
    value: terms.Common.alphabeticalOrder,
  },
  {
    key: OrderBy.AscendingLastModification,
    value: terms.Common.modificationDate,
  },
  {
    key: OrderBy.DescendingLastModification,
    value: terms.Common.modificationDate,
  },
  {
    key: OrderBy.AscendingCreationDate,
    value: terms.Common.creationDate,
  },
  {
    key: OrderBy.DescendingCreationDate,
    value: terms.Common.creationDate,
  },
]
