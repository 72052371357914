import { ReactElement } from 'react'
import { Box, Grid, Typography } from '@mui/material'

import './container.scss'

export default function ContainerBody({ title, subtitle, content }:
     {title: string, subtitle: string | ReactElement, content: ReactElement}): ReactElement {
  return (
    <Box className="container-body">
      <Grid container>
        <Grid item>
          <Typography variant="h4">
            {title}
          </Typography>
          <Typography variant="subtitle1">
            {subtitle}
          </Typography>
        </Grid>
        {content}
      </Grid>
    </Box>
  )
}
