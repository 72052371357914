import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toggleObjectPositionMode, setNewRkObjectLayer } from 'reducers/object'
import { RootState } from 'Store'
import { setLabelToUpdate } from 'reducers/label'
import turfExplode from '@turf/explode'
import terms from 'common/terms'
import { ToolsProps } from './types'

export default function ModifyObjectPosition({ closePopup }: ToolsProps): ReactElement {
  const dispatch = useDispatch()
  const { clickedFeatureInfo } = useSelector((state: RootState) => state.map)
  const { libraryLabel } = useSelector((state: RootState) => state.library)
  const modifyObjectPosition = async () => {
    dispatch(toggleObjectPositionMode(true))
    if (clickedFeatureInfo.source.includes('rk')) {
      const featuresList: Array<GeoJSON.Feature> = []
      turfExplode(clickedFeatureInfo).features.forEach((featuree: any, index) => {
        const newFeature = {
          ...featuree,
          geometry: {
            type: 'Point',
            coordinates:
            featuree.geometry.coordinates,
          },
          properties: {
            originalIndex: index,
            ...featuree.properties,
          },
        }
        featuresList.push(newFeature)
      })
      const labelGeojson: GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties> = {
        type: 'FeatureCollection',
        features: featuresList,
      }
      dispatch(setNewRkObjectLayer(labelGeojson))
    }
    closePopup(undefined)
    const feature: GeoJSON.Feature = clickedFeatureInfo || libraryLabel
    dispatch(setLabelToUpdate(feature))
  }

  return (
    <>
      <div role="button" tabIndex={0} className="popup-items" onClick={modifyObjectPosition}>
        {terms.Object.button.position}
      </div>
    </>
  )
}
