import { ReactElement } from 'react'
import { Container, Fade } from '@mui/material'
import ContainerHeader from 'components/common/Container/ContainerHeader'
import terms from 'common/terms'
import ContainerBody from 'components/common/Container/ContainerBody'
import ContainerFooter from 'components/common/Container/ContainerFooter'
import history from 'customHistory'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import PathNames from 'common/PathNames'
import {
  resetActiveFile, resetResponseError, toggleUpdateFileName, updateCreatedFile,
} from 'reducers/Files/files'
import { FileTypes } from 'reducers/Files/types'
import {
  setUpdatedSection, toggleValidationNotice, updateActiveProject, updateCreationLevel, updateEmptyFields,
  updateSectionsList,
} from 'reducers/creation'
import { updateActiveTab } from 'reducers/dashboard'
import { resetState, toggleUpdateTimeRange, updateCreationStatus } from 'reducers/MultiYearPlan/multiYearPlan'
import { toggleDisplaySettingsPanel } from 'reducers/MultiYearPlan/Settings/settings'
import ImportLoader from 'components/common/Container/ImportLoader'
import TemporalPerimeterInputs from './TemporalPerimeterInputs'
import TemporalPerimeterButton from './TemporalPerimeterButton'
import { getPath } from '../../../../utils'
import { displayTabContent } from '../utils'
import UpdateTemporalPerimeterModal from './UpdateTemporalPerimeterModal'

export default function TemporalPerimeterDeclaration(): ReactElement {
  const dispatch = useDispatch()
  const { activeRepertory } = useSelector((state: RootState) => state.repertories)
  const { activeFolder } = useSelector((state: RootState) => state.folders)
  const { fileType, activeFile, displayUpdatePerimeterLoader } = useSelector((state: RootState) => state.files)
  const { updateTimeRange } = useSelector((state: RootState) => state.multiYearPlan)
  const { tabsList } = useSelector((state: RootState) => state.dashboard)

  const handleRollBack = () => {
    history.push(window.location.pathname.replace(`/${PathNames.TemporalPerimeter}`, ''))
    if (activeFile.type === FileTypes.Synoptic) {
      dispatch(updateCreationLevel(undefined))
    } else {
      dispatch(updateCreationStatus(undefined))
    }
    dispatch(updateCreatedFile(undefined))
    dispatch(resetResponseError())
    dispatch(toggleUpdateFileName(false))
  }
  const handleClose = () => {
    if (updateTimeRange) {
      dispatch(toggleDisplaySettingsPanel(false))
      displayTabContent(tabsList, activeFile, activeRepertory, activeFolder)
      dispatch(toggleUpdateTimeRange(false))
    } else {
      history.push(getPath(activeRepertory, activeFolder, activeFile))
      dispatch(resetActiveFile())
      dispatch(toggleUpdateFileName(false))
      dispatch(updateActiveTab({ tabName: 'home', tabId: undefined }))
      if (activeFile.type === FileTypes.Synoptic) {
        dispatch(updateCreationLevel(undefined))
        dispatch(updateActiveProject(undefined))
        dispatch(updateEmptyFields(false))
        dispatch(toggleValidationNotice(false))
        dispatch(setUpdatedSection(undefined))
        dispatch(updateSectionsList([]))
      } else {
        dispatch(resetState())
      }
    }
  }

  const getSubtitle = () => (fileType === FileTypes.Schema ? terms.File.Declaration.Schema.temporalPetimeterNotice
    : terms.Common.declarationSubtitle)

  return (
    <main>
      <Fade timeout={400} in mountOnEnter unmountOnExit>
        <Container
          maxWidth={false}
          disableGutters
          sx={{ height: '100vh', background: displayUpdatePerimeterLoader ? '#183B68' : '' }}
        >
          <ContainerHeader
            onRollBack={handleRollBack}
            rollBackText={terms.File.Declaration.backToName}
            onClose={handleClose}
            displayRollBackBtn={!updateTimeRange}
          />
          <ContainerBody
            title={displayUpdatePerimeterLoader ? '' : terms.File.Declaration.temporalPerimetre}
            subtitle={displayUpdatePerimeterLoader
              ? <ImportLoader title={terms.File.Declaration.Schema.spLoaderNotice} />
              : getSubtitle()}
            content={displayUpdatePerimeterLoader ? <></> : <TemporalPerimeterInputs />}
          />
          <ContainerFooter content={displayUpdatePerimeterLoader ? <></> : <TemporalPerimeterButton />} />
        </Container>
      </Fade>
      <UpdateTemporalPerimeterModal />
    </main>
  )
}
