import { ReactElement, useEffect } from 'react'
import iconHome from 'assets/icons/home.svg'
import { Nav, Tab } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import _ from 'lodash'
import {
  toggleValidationNotice, updateActiveProject, updateCreationLevel,
} from 'reducers/creation'
import { toggleSelectTab, updateActiveTab, updateTabsList } from 'reducers/dashboard'
import SynopticNavBar from 'components/Synoptic/SynopticNavBar'
import { IoIosClose } from 'react-icons/io'
import { toggleShowPanel, updateResponseError } from 'reducers/label'
import {
  toggleShowObjectPanel, updateStatusLayers, toggleShowGeoeditor, toggleShowObjectCreationPanel,
} from 'reducers/object'
import { reset } from 'reducers/library'
import NavTabContent from 'NavTabContent'
import { resetActiveRepertory } from 'reducers/Repertories/repertories'
import history from 'customHistory'
import PathNames from 'common/PathNames'
import { setActiveFolder } from 'reducers/Folders/folder'
import terms from 'common/terms'
import { resetActiveFile } from 'reducers/Files/files'
import { FileTypes } from 'reducers/Files/types'
import { getFilesSectionsList } from 'reducers/MultiYearPlan/multiYearPlan.thunk'
import { updateCreationStatus } from 'reducers/MultiYearPlan/multiYearPlan'
import { resetCommentsOperations, updateComments } from 'reducers/MultiYearPlan/Comments/comments'
import { Chip, IconButton } from '@mui/material'
import { getSingleFolderFile, getSingleRepertoryFile } from 'reducers/Files/files.thunk'
import MultiYearPlanContainer from 'components/MultiYearPlan/MultiYearPlanContainer'
import { DEFAULT_VIEWPORT } from 'components/Map/utils'
import { resetTitleBlock } from 'reducers/MultiYearPlan/TitleBlock/titleblock'
import { resetLegend } from 'reducers/MultiYearPlan/Legend/legend'
import { resetOperations } from 'reducers/MultiYearPlan/Operations/operations'
import { toggleDisplaySettingsPanel } from 'reducers/MultiYearPlan/Settings/settings'
import { setSearchValue, toggleDisplayLibraryPanel } from 'reducers/MultiYearPlan/Library/library'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { ActiveTab } from './types'
import CreationService from './Creation/CreationService'
import { getTabChipsColor, getTabChipsLabel, getTabChipsWidth } from './utils'

export default function DashboardNavBar(): ReactElement {
  const dispatch = useDispatch()
  const { tabsList, activeTab } = useSelector((state: RootState) => state.dashboard)
  const { showObjectPanel, showObjectCreationPanel } = useSelector((state: RootState) => state.object)
  const { activeProject, projectZoom } = useSelector((state: RootState) => state.creation)
  const {
    comments, CommentOnCreation,
  } = useSelector((state: RootState) => state.mypcomments)

  const onTabSelect = (tab: ActiveTab) => {
    dispatch(resetCommentsOperations())
    if (CommentOnCreation) {
      const formated = _.cloneDeep(comments)
      dispatch(updateComments(formated.splice(-1)))
    }
    if (tab.tabId) {
      if (activeProject && projectZoom !== DEFAULT_VIEWPORT.zoom) {
        dispatch(CreationService.updateZoomLevel({
          projectId: activeProject.id,
          zoom: projectZoom,
        }))
      }
      if (tab.fileType === FileTypes.Synoptic) {
        dispatch(updateActiveProject(undefined))
        dispatch(CreationService.getSingleProject(tab.tabId))
        dispatch(toggleValidationNotice(false))
        dispatch(toggleShowPanel(false))
        dispatch(updateResponseError(undefined))
        dispatch(toggleShowGeoeditor(false))
        dispatch(reset())
      } else {
        dispatch(resetTitleBlock())
        dispatch(resetLegend())
        dispatch(resetOperations())
        dispatch(toggleDisplaySettingsPanel(false))
        dispatch(toggleDisplayLibraryPanel(false))
        dispatch(setSearchValue(''))
        dispatch(getFilesSectionsList({
          repertoryId: tab.directoryId as number,
          folderId: tab.folderId,
          fileId: tab.tabId,
        }))
      }
      if (tab.folderId && tab.folderId !== null) {
        dispatch(getSingleFolderFile({
          repertoryId: tab.directoryId as number,
          folderId: tab.folderId,
          fileId: tab.tabId,
        }))
      } else {
        dispatch(getSingleRepertoryFile({
          repertoryId: tab.directoryId as number,
          fileId: tab.tabId,
        }))
      }
      dispatch(toggleSelectTab({ tabId: tab.tabId }))
    }
    if (tab.tabName === 'home') {
      dispatch(updateActiveTab(tab))
      dispatch(resetActiveRepertory())
      dispatch(setActiveFolder(undefined))
      dispatch(resetActiveFile())
      history.push(PathNames.Home)
      dispatch(updateActiveProject(undefined))
      dispatch(updateCreationLevel(undefined))
      dispatch(updateCreationStatus(undefined))
      dispatch(updateStatusLayers(undefined))
      dispatch(resetTitleBlock())
      dispatch(resetLegend())
      dispatch(resetOperations())
      dispatch(toggleDisplaySettingsPanel(false))
      dispatch(toggleDisplayLibraryPanel(false))
      dispatch(setSearchValue(''))
    }
  }

  const onTabClose = (tabId: number | undefined) => {
    dispatch(resetCommentsOperations())
    if (CommentOnCreation) {
      const formated = _.cloneDeep(comments)
      dispatch(updateComments(formated.splice(-1)))
    }
    const newTabsList = tabsList.filter((tab: ActiveTab) => tab.tabId !== tabId)
    dispatch(updateTabsList(newTabsList))
    if (activeProject && projectZoom !== DEFAULT_VIEWPORT.zoom) {
      dispatch(CreationService.updateZoomLevel({
        projectId: activeProject.id,
        zoom: projectZoom,
      }))
    }
    if (activeTab.tabId === tabId) {
      dispatch(updateActiveTab({ tabName: 'home', tabId: undefined }))
      dispatch(updateActiveProject(undefined))
      dispatch(updateCreationLevel(undefined))
      dispatch(updateCreationStatus(undefined))
      dispatch(reset())
      dispatch(resetTitleBlock())
      dispatch(resetLegend())
      dispatch(resetOperations())
    }
  }

  const onObjectHeaderClose = () => {
    dispatch(toggleShowObjectPanel(false))
    dispatch(toggleShowObjectCreationPanel(false))
  }

  const rightSlide = () => {
    (document.getElementById('nav-container') as HTMLElement).scrollLeft += 100
  }
  const leftSlide = () => {
    (document.getElementById('nav-container') as HTMLElement).scrollLeft -= 100
  }

  const showSlideButtons = () => {
    if (document.getElementById('nav-container') !== null) {
      if ((document.getElementById('nav-container') as HTMLElement).scrollWidth > window.innerWidth) {
        return true
      }
    }
    return false
  }

  useEffect(() => {
    const activeElement = document.getElementById(activeTab.tabId?.toString())
    if (activeElement !== null && showSlideButtons()) {
      activeElement.scrollIntoView()
    }
  }, [activeTab])

  return (
    <>
      <main className="mastcontainer pl-0">
        {(showObjectPanel || showObjectCreationPanel) && (
        <div className="tab-object-header">
          <div className="ml-auto">{terms.Object.header}</div>
          <IoIosClose className="action-cursor-pointer ml-auto mr-3" onClick={() => onObjectHeaderClose()} />
        </div>
        )}
        <Tab.Container id="left-tabs-example" defaultActiveKey="home">
          <Nav variant="tabs" className="navigation-tab">
            <Nav.Item className="action-cursor-pointer">
              <Nav.Link
                eventKey="home"
                active={activeTab.tabName === 'home'}
                onClick={() => onTabSelect({ tabName: 'home', tabId: undefined })}
              >
                <img src={iconHome} width="20" alt="" />
              </Nav.Link>
            </Nav.Item>
            {showSlideButtons() && (
            <IconButton className="p-0" onClick={leftSlide}>
              <KeyboardArrowLeftIcon />
            </IconButton>
            )}
            <div id="nav-container" className="nav-container">
              {tabsList && tabsList.map((tab: ActiveTab) => (
                <Nav.Item key={tab.tabId} id={tab.tabId?.toString()} className="d-flex justify-content-between">
                  <Nav.Link
                    eventKey={tab.tabId}
                    active={activeTab.tabId === tab.tabId}
                    onClick={() => onTabSelect(tab)}
                    className="action-cursor-pointer"
                    style={{ gap: '10px' }}
                  >
                    {tab.tabName}
                    <Chip
                      size="small"
                      className="tab-badge"
                      label={getTabChipsLabel(tab.fileType)}
                      sx={{
                        backgroundColor: getTabChipsColor(tab.fileType),
                        width: getTabChipsWidth(tab.fileType),
                      }}
                    />
                  </Nav.Link>
                  <div className={`tab-close-btn${activeTab.tabId === tab.tabId ? ' tab-active' : ''}`}>
                    <IoIosClose className="action-cursor-pointer" onClick={() => onTabClose(tab.tabId)} />
                  </div>
                </Nav.Item>
              ))}
            </div>
            {showSlideButtons() && (
            <IconButton className="p-0" onClick={rightSlide}>
              <KeyboardArrowRightIcon />
            </IconButton>
            )}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="home" active={activeTab.tabName === 'home'}>
              <NavTabContent />
            </Tab.Pane>
            {tabsList && tabsList.map((tab: ActiveTab) => (
              <Tab.Pane eventKey={tab.tabId} key={tab.tabId} active={activeTab.tabId === tab.tabId} unmountOnExit>
                {tab.fileType === FileTypes.Synoptic ? <SynopticNavBar /> : <MultiYearPlanContainer />}
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Tab.Container>
      </main>
    </>
  )
}
