import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkApi } from 'types'
import { get } from '@osrdata/app_core/dist/requests'
import { GetParams, Legend } from './types'

const getLegend = createAsyncThunk<Legend, GetParams, ThunkApi>(
  'legend/getLegend',
  async (params: GetParams, thunkApi) => {
    if (params.folderId && params.folderId !== null) {
      try {
        const response: Legend = await
        // eslint-disable-next-line max-len
        get(`/module_schematique/directories/${params.repertoryId}/folders/${params.folderId}/projects/${params.fileId}/legend/`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response: Legend = await
        // eslint-disable-next-line max-len
        get(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/legend/`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

export default getLegend
