import { store } from 'Store'
import { coordEach, featureEach } from '@turf/meta'
import { ImmutableFeatureCollection } from '@nebula.gl/edit-modes'
import { AllGeoJSON, FeatureCollection, lineString } from '@turf/helpers'
import centroid from '@turf/centroid'
import { Position } from '@deck.gl/core'
import { GeoEditorState, setGeoJson } from '../reducer'
import { planeNearestPointOnLine, planeDistance } from './utils'

const verticalFlip = () => {
  const { geoJson } = store.getState().geoEditor as GeoEditorState

  if (geoJson) {
    let immutableGeoJson = new ImmutableFeatureCollection(geoJson)

    const geoJsonCentroid = centroid(geoJson as AllGeoJSON)
    const mirrorAxis = lineString([
      [geoJsonCentroid.geometry.coordinates[0], 30],
      [geoJsonCentroid.geometry.coordinates[0], 60],
    ])

    featureEach(geoJson as FeatureCollection, (currentFeature, featureIndex) => {
      coordEach(currentFeature, (currentCoord, coordIndex) => {
        const projectedPoint = planeNearestPointOnLine(mirrorAxis, currentCoord as Position)
        const axisDistance = planeDistance(projectedPoint.geometry.coordinates as Position, currentCoord as Position)
        const xTranslation = projectedPoint.geometry.coordinates[0] - currentCoord[0] > 0
          ? 2 * axisDistance
          : (-2) * axisDistance
        const newCoords = [currentCoord[0] + xTranslation, currentCoord[1]] as Position

        immutableGeoJson = immutableGeoJson.replacePosition(featureIndex, [coordIndex], newCoords)
      })
    })

    store.dispatch(setGeoJson(immutableGeoJson.getObject()))
  }
}

export default verticalFlip
