import { ReactElement } from 'react'
import { ReactComponent as WarningTriangle } from 'assets/icons/warning_triangle.svg'
import terms from 'common/terms'
import TopBar from 'components/TopBar'

import './AccessDenied.scss'

export default function AccessDenied(): ReactElement {
  return (
    <>
      <TopBar appName={terms.Common.appName} />
      <div
        id="access-denied"
        className="d-flex w-100 justify-content-center align-items-center"
      >
        <div className="d-flex h-50 w-50 justify-content-center align-items-center text-center flex-column">
          <WarningTriangle className="warning-icon" />
          <span className="main-message">
            {terms.Common.Errors.forbidden.forbiddenTitle}
          </span>
          <br />
          {terms.Common.Errors.forbidden.forbiddenSubtitle}
        </div>
      </div>
    </>
  )
}
