import {
  createSlice, isAnyOf,
} from '@reduxjs/toolkit'
import { MypLegendState } from './types'
import getLegend from './legend.thunk'
import { PatchFamilyTrack, patchClassification } from '../Import/ObjectsFamilies/objectsFamilies.thunk'
import { patchPhaseFilter } from '../Operations/operations.thunk'

const initialState: MypLegendState = {
  Legend: {},
  updateLegendChart: false,
}

export const mypLegendSlice = createSlice({
  name: 'myplegend',
  initialState,
  reducers: {
    resetLegend: state => {
      state.Legend = {}
    },
  },
  extraReducers: builder => {
    builder.addCase(getLegend.fulfilled, (state, action) => {
      state.Legend = action.payload
    })
    builder.addMatcher(isAnyOf(
      getLegend.rejected,
    ), (state, action) => {
      state.responseError = action.payload
    })

    builder.addMatcher(isAnyOf(
      PatchFamilyTrack.fulfilled,
      patchClassification.fulfilled,
      patchPhaseFilter.fulfilled,
    ), state => {
      state.updateLegendChart = !state.updateLegendChart
    })
  },
})

export const { resetLegend } = mypLegendSlice.actions
export default mypLegendSlice.reducer
