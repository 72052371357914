/* eslint-disable react/no-array-index-key */
import { ReactElement, useState } from 'react'
import FormInput from 'components/common/Inputs/FormInput'
import { Form, Button } from 'react-bootstrap'
import ActionButton from 'components/common/Buttons/ActionButton'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import { IoIosClose } from 'react-icons/io'
import { updateSearchValues } from 'reducers/import'
import terms from 'common/terms'
import ImportService from '../ImportService'
import { ATTRIBUTS_FIELD } from './ImportField'
import { getSelectedValue, setSearchValues } from './utils'

export default function SettingsChoice(): ReactElement {
  const dispatch = useDispatch()
  const {
    portfolio, displayedColumns, labelExample, responseError,
  } = useSelector((state: RootState) => state.import)
  const { activeProject } = useSelector((state: RootState) => state.creation)
  const [attribut, setAttribut] = useState('')

  const handleChange = (value: string) => {
    setAttribut(value)
  }

  const addAttribut = () => {
    dispatch(ImportService.updatePortfolio({
      projectId: activeProject.id,
      portfolio: { displayed_columns: [...displayedColumns, attribut] },
    }))
    dispatch(updateSearchValues([]))
    setAttribut('')
  }

  const removeAttribut = (value: string) => {
    dispatch(ImportService.updatePortfolio({
      projectId: activeProject.id,
      portfolio: { displayed_columns: displayedColumns.filter((column: string) => column !== value) },
    }))
  }

  return (
    <div className="attributs-container">
      <Form className="attributs-form" autoComplete="off">
        <FormInput
          field={{
            ...ATTRIBUTS_FIELD,
            values: portfolio
              ? portfolio.columns.filter((c: string) => !portfolio.displayed_columns.includes(c)) : [],
          }}
          onChange={v => handleChange(v)}
          value={attribut}
          extraStyle="px-2"
          responseError={responseError}
          setSearchValues={(v: string) => setSearchValues(v, 0)}
          getSelectedValue={() => getSelectedValue(attribut, 0)}
          index={0}
        />
        <ActionButton
          text={terms.Portfolio.Import.Buttons.addAttribut}
          onClick={addAttribut}
          leftIcon
          buttonStyle="add-attribut-btn"
        />
      </Form>
      <div className="example-container">
        <div className="label-container">
          {displayedColumns && displayedColumns.map((column: string, index: number) => (
            <div key={index}>
              {labelExample[column]?.trim()}
            </div>
          ))}
        </div>
        <div className="attributs-example-container">
          {displayedColumns && displayedColumns.map((column: string, index: number) => (
            <Timeline key={index}>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot />
                  {index !== (displayedColumns.length - 1) && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent>
                  <Button className="attribut-btn">
                    {column}
                    <IoIosClose onClick={() => removeAttribut(column)} />
                  </Button>
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          ))}
        </div>
      </div>
    </div>
  )
}
