/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */
import {
  deleteRequest, get, patch, post,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkApi } from 'types'
import {
  DeleteParams, Files, GetParams, PatchParams, PostParams,
} from './types'

const getRepertoryFilesList = createAsyncThunk<Files[], GetParams, ThunkApi>(
  'files/getRepertoryFiles',
  async (params: GetParams, thunkApi) => {
    try {
      const response: Files[] = await get(`/module_schematique/directories/${params.repertoryId}/projects/`,
        { ordering: params.ordering, search: params.search })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const getFolderFilesList = createAsyncThunk<Files[], GetParams, ThunkApi>(
  'files/getFolderFiles',
  async (params: GetParams, thunkApi) => {
    try {
      const response: Files[] = await
      get(`/module_schematique/directories/${params.repertoryId}/folders/${params?.folderId}/projects/`,
        { ordering: params.ordering, search: params.search })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const postRepertoryFile = createAsyncThunk<Files, PostParams, ThunkApi>(
  'files/repertory/postName',
  async (params: PostParams, thunkApi) => {
    try {
      const response: Files = await post(`/module_schematique/directories/${params.repertoryId}/projects/`,
        { name: params.fileName, type: params.fileType })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const postFolderFile = createAsyncThunk<Files, PostParams, ThunkApi>(
  'files/folder/postName',
  async (params: PostParams, thunkApi) => {
    try {
      const response: Files = await
      post(`/module_schematique/directories/${params.repertoryId}/folders/${params?.folderId}/projects/`,
        { name: params.fileName, type: params.fileType })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const patchFile = createAsyncThunk<Files, PatchParams, ThunkApi>(
  'files/patchFile',
  async (params: PatchParams, thunkApi) => {
    if (params.folderId && params.folderId !== null) {
      try {
        const response: Files = await
        // eslint-disable-next-line max-len
        patch(`/module_schematique/directories/${params.repertoryId}/folders/${params?.folderId}/projects/${params.file.id}`,
          params.file)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response: Files = await
        patch(`/module_schematique/directories/${params.repertoryId}/projects/${params.file.id}`,
          params.file)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

const updateGeographicPerimeter = createAsyncThunk<Files, PatchParams, ThunkApi>(
  'files/updateGeographicPerimeter',
  async (params: PatchParams, thunkApi) => {
    if (params.folderId && params.folderId !== null) {
      try {
        const response: Files = await
        // eslint-disable-next-line max-len
        post(`/module_schematique/directories/${params.repertoryId}/folders/${params?.folderId}/projects/${params.file.id}/validate-geographic-perimeter/`,
          !params.file.confirm_change && !params.file.abort_change ? {} : params.file)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        const response: Files = await
        // eslint-disable-next-line max-len
        post(`/module_schematique/directories/${params.repertoryId}/projects/${params.file.id}/validate-geographic-perimeter/`,
          !params.file.confirm_change && !params.file.abort_change ? {} : params.file)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

const deleteRepertoryFile = createAsyncThunk(
  'files/repertory/delete',
  async (params: DeleteParams, thunkApi) => {
    try {
      await
      deleteRequest(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}`)
      return params.fileId
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteFolderFile = createAsyncThunk(
  'files/foler/delete',
  async (params: DeleteParams, thunkApi) => {
    try {
      await
      // eslint-disable-next-line max-len
      deleteRequest(`/module_schematique/directories/${params.repertoryId}/folders/${params?.folderId}/projects/${params.fileId}`)
      return params.fileId
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const cloneRepertoryFile = createAsyncThunk<Files, Files, ThunkApi>(
  'files/cloneRepertoryFile',
  async (file: Files, thunkApi) => {
    try {
      const response: Files = await
      // eslint-disable-next-line max-len
      post(`/module_schematique/directories/${file.directory}/projects/${file.id}/clone/`, {})
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const cloneFolderFile = createAsyncThunk<Files, Files, ThunkApi>(
  'files/cloneFolderFile',
  async (file: Files, thunkApi) => {
    try {
      const response: Files = await
      // eslint-disable-next-line max-len
      post(`/module_schematique/directories/${file.directory}/folders/${file.folder}/projects/${file.id}/clone/`, {})
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const getSingleRepertoryFile = createAsyncThunk<Files, GetParams, ThunkApi>(
  'files/getSingleRepertoryFile',
  async (params: GetParams, thunkApi) => {
    try {
      const response: Files = await
      get(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const getSingleFolderFile = createAsyncThunk<Files, GetParams, ThunkApi>(
  'files/getSingleFolderFile',
  async (params: GetParams, thunkApi) => {
    try {
      const response: Files = await
      get(`/module_schematique/directories/${params.repertoryId}/folders/${params?.folderId}/projects/${params.fileId}`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const getFileYears = createAsyncThunk<number[], GetParams, ThunkApi>(
  'files/getFileYears',
  async (params: GetParams, thunkApi) => {
    if (params.folderId && params.folderId !== null) {
      try {
        // eslint-disable-next-line max-len
        const response: number[] = await get(`/module_schematique/directories/${params.repertoryId}/folders/${params?.folderId}/projects/${params.fileId}/years/`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        // eslint-disable-next-line max-len
        const response: number[] = await get(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/years/`,
          { ordering: params.ordering, search: params.search })
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

const getFilePhases = createAsyncThunk<string[], GetParams, ThunkApi>(
  'files/getFilePhases',
  async (params: GetParams, thunkApi) => {
    if (params.folderId && params.folderId !== null) {
      try {
        // eslint-disable-next-line max-len
        const response: string[] = await get(`/module_schematique/directories/${params.repertoryId}/folders/${params?.folderId}/projects/${params.fileId}/phases/`)
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    } else {
      try {
        // eslint-disable-next-line max-len
        const response: string[] = await get(`/module_schematique/directories/${params.repertoryId}/projects/${params.fileId}/phases/`,
          { ordering: params.ordering, search: params.search })
        return response
      } catch (e: any) {
        return thunkApi.rejectWithValue({
          message: e.response.data,
          status: e.response.status,
        })
      }
    }
  },
)

export {
  getRepertoryFilesList,
  getFolderFilesList,
  deleteRepertoryFile,
  deleteFolderFile,
  postRepertoryFile,
  postFolderFile,
  patchFile,
  cloneRepertoryFile,
  cloneFolderFile,
  getSingleRepertoryFile,
  getSingleFolderFile,
  getFileYears,
  getFilePhases,
  updateGeographicPerimeter,
}
