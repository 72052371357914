import { ReactElement } from 'react'
import CancelObjectModification from './CancelObjectModification'
import ValidateObject from './ValidateObject'

export default function ObjectsActionsButtons(): ReactElement {
  return (
    <div className="modification-btn-container">
      <ValidateObject />
      <CancelObjectModification />
    </div>
  )
}
