import { ReactElement } from 'react'
import ActionButton from 'components/common/Buttons/ActionButton'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { setActiveStep, toggleSettingsNoticeDisplay, updateSearchValues } from 'reducers/import'
import terms from 'common/terms'

export default function AttributsSettingsButton(): ReactElement {
  const dispatch = useDispatch()
  const { settingsNoticeDisplay, activeStep, displayedColumns } = useSelector((state: RootState) => state.import)

  const handleClick = () => {
    if (settingsNoticeDisplay) {
      dispatch(toggleSettingsNoticeDisplay(false))
    } else {
      dispatch(setActiveStep(activeStep + 1))
      dispatch(toggleSettingsNoticeDisplay(true))
      dispatch(updateSearchValues([]))
    }
  }

  return (
    <ActionButton
      text={settingsNoticeDisplay ? terms.Portfolio.Import.Buttons.start : terms.Portfolio.Import.Buttons.continue}
      onClick={handleClick}
      rightIcon
      buttonStyle={settingsNoticeDisplay ? 'start-btn' : 'continue-btn'}
      disabled={!settingsNoticeDisplay && displayedColumns && displayedColumns.length === 0}
    />
  )
}
