import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { SubClassification } from 'reducers/MultiYearPlan/Import/ObjectsFamilies/type'
import { updateSubCLassificationDraggedId } from 'reducers/MultiYearPlan/Import/ObjectsFamilies/objectsFamilies'
import {
  getSubClassifications, patchSubClassificationDrop,
} from 'reducers/MultiYearPlan/Import/ObjectsFamilies/objectsFamilies.thunk'
import { Grid, Tooltip } from '@mui/material'
import { onDragOver } from './Actions/utils'

export default function ColumnC6ValuesPanel(): ReactElement {
  const dispatch = useDispatch()
  const { activeFile } = useSelector((state: RootState) => state.files)
  const {
    subClassifications, updateObjectsFamilies, deleteObjectFamily,
  } = useSelector((state: RootState) => state.objectsFamilies)
  const { regeneratingClassifications } = useSelector((state: RootState) => state.mypImport)
  const { subClassificationDraggedId } = useSelector((state: RootState) => state.objectsFamilies)

  useEffect(() => {
    if (activeFile) {
      dispatch(getSubClassifications({
        repertoryId: activeFile.directory,
        folderId: activeFile.folder,
        fileId: activeFile.id,
      }))
    }
  }, [updateObjectsFamilies, deleteObjectFamily, regeneratingClassifications])

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(patchSubClassificationDrop({
      repertoryId: activeFile.directory,
      folderId: activeFile.folder,
      fileId: activeFile.id,
      subClassificationId: subClassificationDraggedId,
      classification: {
        classification: null,
      },
    }))
  }

  return (
    <Grid item xs={4}>
      <div
        className="values-panel p-3"
        onDragOver={e => onDragOver(e)}
        onDrop={e => handleDrop(e)}
      >
        {subClassifications?.map((subClassification: SubClassification) => (!subClassification.classification
          && (
            <Tooltip key={subClassification.id} title={subClassification.label} placement="top">
              <div
                draggable
                onDragStart={() => dispatch(updateSubCLassificationDraggedId(subClassification.id))}
                key={subClassification.id}
                className="chip"
              >
                <span>{subClassification.code}</span>

              </div>
            </Tooltip>
          )
        ))}
      </div>
    </Grid>
  )
}
