import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import terms from 'common/terms'
import {
  getClassifications, patchClassification,
} from 'reducers/MultiYearPlan/Import/ObjectsFamilies/objectsFamilies.thunk'
import { ObjectFamily } from 'reducers/MultiYearPlan/Import/ObjectsFamilies/type'
import FilterAccordion from './FilterAccordion'

export default function TypologyAccordion(): ReactElement {
  const dispatch = useDispatch()
  const { activeFile } = useSelector((state: RootState) => state.files)
  const { objectsFamilies } = useSelector((state: RootState) => state.objectsFamilies)

  useEffect(() => {
    dispatch(getClassifications({
      repertoryId: activeFile.directory,
      folderId: activeFile.folder,
      fileId: activeFile.id,
    }))
  }, [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, option: ObjectFamily) => {
    dispatch(patchClassification({
      repertoryId: activeFile.directory,
      folderId: activeFile.folder,
      fileId: activeFile.id,
      classificationId: option.id,
      objectFamily: {
        is_visible: event.target.checked,
      },
    }))
  }

  return (
    <FilterAccordion
      options={objectsFamilies}
      handleChange={(event, option) => handleChange(event, option as ObjectFamily)}
      summary={terms.MultiYearPlan.SettingsPanel.typology}
    />
  )
}
