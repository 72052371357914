// eslint-disable-next-line import/prefer-default-export
export enum ImportErrorsFields {
    FileError = 'file',
    CriteriaError = 'column_criteria',
    LabelError = 'column_label',
    YearError = 'column_year',
    StartYearError = 'column_start_year',
    EndYearError = 'column_end_year',
    LineCodeError = 'column_line_code',
    TrackError = 'column_track',
    KpStartError = 'column_start_kp',
    KpEndError = 'column_end_kp',
    StatusError = 'column_maturity',
    PhaseError = 'column_phase',
}
