import { ReactElement, useEffect } from 'react'
import GenericInput from 'components/common/Inputs/GenericInput'
import terms from 'common/terms'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { updateElementLabel } from 'reducers/MultiYearPlan/TitleBlockActions/titleblockactions'
import { getErrorMessage } from './utils'

export default function TbElementLabelInput(): ReactElement {
  const dispatch = useDispatch()
  const {
    elementLabel, selectedTbElement, emptyFields, responseError,
  } = useSelector((state: RootState) => state.mypTitleBlockActions)

  useEffect(() => {
    if (selectedTbElement) {
      dispatch(dispatch(updateElementLabel(selectedTbElement.label)))
    }
  }, [])

  return (
    <GenericInput
      label={terms.Inputs.operationLabel}
      placeholder={terms.Inputs.operationLabelPlaceholder}
      value={elementLabel}
      onChange={e => dispatch(updateElementLabel(e.target.value))}
      errorMessage={getErrorMessage(responseError, 'label', emptyFields, elementLabel)}
      labelColor="#8A8A8A"
    />
  )
}
