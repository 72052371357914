import { ReactElement, useEffect } from 'react'
import { Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { PortfolioHeaders } from 'reducers/MultiYearPlan/Import/types'
import { updateFerrolocalizationAttributs } from 'reducers/MultiYearPlan/Import/import'
import terms from 'common/terms'
import AttributsChoiceContainer from './AttributsChoiceContainer'
import { FerrolocalizationFields, ImportFields } from './MypImportInputs'
import { ImportErrorsFields } from './types'

export default function FerrolocationChoice(): ReactElement {
  const dispatch = useDispatch()
  const { ferrolocalizationAttributs, responseError } = useSelector((state: RootState) => state.mypImport)

  useEffect(() => {
    if (!ferrolocalizationAttributs) {
      dispatch(updateFerrolocalizationAttributs({
        [terms.Inputs.lineNumber]: null,
        [terms.Inputs.requiredTrackName]: null,
        [terms.Inputs.pkDebut]: null,
        [terms.Inputs.pkFin]: null,
      }))
    }
  }, [ferrolocalizationAttributs])

  const handleChange = (value: PortfolioHeaders | null, fieldLabel: string) => {
    dispatch(updateFerrolocalizationAttributs({ ...ferrolocalizationAttributs, [fieldLabel]: value }))
  }

  const getErrorMessage = (fieldLabel: string) => {
    switch (fieldLabel) {
      case terms.Inputs.lineNumber:
        return responseError && responseError.message[ImportErrorsFields.LineCodeError]
          ? responseError.message[ImportErrorsFields.LineCodeError] : ''
      case terms.Inputs.requiredTrackName:
        return responseError && responseError.message[ImportErrorsFields.TrackError]
          ? responseError.message[ImportErrorsFields.TrackError] : ''
      case terms.Inputs.pkDebut:
        return responseError && responseError.message[ImportErrorsFields.KpStartError]
          ? responseError.message[ImportErrorsFields.KpStartError] : ''
      default:
        return responseError && responseError.message[ImportErrorsFields.KpEndError]
          ? responseError.message[ImportErrorsFields.KpEndError] : ''
    }
  }

  return (
    <Box
      component="form"
      className="ferro-localization-form"
      noValidate
      autoComplete="off"
      sx={{ width: 'auto' }}
    >
      {FerrolocalizationFields.map((field: ImportFields) => (
        <AttributsChoiceContainer
          key={field.label}
          chipLabel={field.label}
          value={ferrolocalizationAttributs ? ferrolocalizationAttributs[field.label] : null}
          onChange={(_event, newValue) => handleChange(newValue, field.label)}
          placeholder={field.placeholder}
          errorMessage={getErrorMessage(field.label)}
        />
      ))}
    </Box>
  )
}
