import { Task } from 'components/Dashboard/types'
import { Files } from 'reducers/Files/types'
import { ResponseError } from 'types'

/* eslint-disable camelcase */
export type MYPSection = {
    name: string
    code_ligne: string
    pk_sncf_debut: string
    pk_sncf_fin: string
    step: number
    order: number
    direction: string
    id: number
    tasks?: Task[]
    localizations: [
        {
            x: number
            label: string
        },
        {
            x: number
        }
    ]
}

export interface MultiyearPlanState {
    geoPerimeterDeclarationMode: string
    mypSectionsList: MYPSection[]
    mypCreationStatus?: number
    mypEmptyFields: boolean
    createdSection?: MYPSection
    sectionResponseError?: ResponseError
    updatedMypSection?: MYPSection
    backToDeclarationChoice: boolean
    deletedSection: boolean
    allFilesList: Files[]
    fileToImport?: Files
    orderChange: boolean
    updateTimeRange: boolean
    updateMypSections: boolean
    gettingSections: boolean
}

export enum DeclarationMode {
    Manual = 'manual',
    Import = 'viaImport'
}

export type GetParams = {
    repertoryId: number
    fileId: number
    folderId?: number
}

export type PostParams = {
    repertoryId: number
    fileId: number
    folderId?: number
    section: Partial<MYPSection>
}

export type DeleteParams = {
    repertoryId: number
    fileId: number
    folderId?: number
    sectionId: number
}

export type ImportParams = {
    repertoryId: number
    fileId: number
    folderId?: number
    projectId: number
}

export type DropParams = {
    repertoryId: number
    fileId: number
    folderId?: number
    declarationMode: string
}
