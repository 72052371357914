import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetResponseError, setUpdatedSection, updateEmptyFields } from 'reducers/creation'
import { RootState } from 'Store'
import { MYPSection } from 'reducers/MultiYearPlan/types'
import { Files, FileTypes } from 'reducers/Files/types'
import {
  deleteFolderFilesSection, deleteRepertoryFileSection, getFilesSectionsList,
} from 'reducers/MultiYearPlan/multiYearPlan.thunk'
import {
  resetMypResponseError, setUpdatedMypSection, toggleDeletedSection, updateMypEmptyFields,
} from 'reducers/MultiYearPlan/multiYearPlan'
import { Section } from '../../../Dashboard/types'
import CreationService from '../../../Dashboard/Creation/CreationService'

import '../../../Dashboard/dashboard.scss'
import SectionTable from './SectionsTable'

type Props = {
  sectionsList: Section[] | MYPSection[]
  validationNotice: boolean
  activeFile: Files
  fileType: string
}
export default function SectionsList({
  sectionsList, validationNotice, activeFile, fileType,
}: Props): ReactElement {
  const dispatch = useDispatch()
  const { activeTab } = useSelector((state: RootState) => state.dashboard)
  const { deletedSection, orderChange } = useSelector((state: RootState) => state.multiYearPlan)

  useEffect(() => {
    if ((deletedSection || orderChange) && fileType === FileTypes.Schema) {
      dispatch(getFilesSectionsList({
        repertoryId: activeFile.directory as number,
        folderId: activeFile.folder as number,
        fileId: activeFile.id,
      }))
    }
  }, [deletedSection, orderChange])

  const onSectionDelete = (sectionId: number) => {
    if (fileType === FileTypes.Synoptic) {
      const projectId = activeTab.tabId ? activeTab.tabId : activeFile.id
      const params = {
        projectId,
        sectionId,
      }
      dispatch(CreationService.deleteSection(params))
    } else if (activeFile.folder !== null) {
      const params = {
        repertoryId: activeFile.directory as number,
        folderId: activeFile.folder as number,
        fileId: activeFile.id,
        sectionId,
      }
      dispatch(deleteFolderFilesSection(params))
      dispatch(toggleDeletedSection())
    } else {
      const params = {
        repertoryId: activeFile.directory as number,
        fileId: activeFile.id,
        sectionId,
      }
      dispatch(deleteRepertoryFileSection(params))
      dispatch(toggleDeletedSection())
    }
  }

  const onSectionUpdate = (section: Section | MYPSection) => {
    if (fileType === FileTypes.Synoptic) {
      dispatch(setUpdatedSection(section as Section))
      dispatch(resetResponseError(undefined))
      dispatch(updateEmptyFields(false))
    } else {
      dispatch(setUpdatedMypSection(section as MYPSection))
      dispatch(resetMypResponseError())
      dispatch(updateMypEmptyFields(false))
      dispatch(toggleDeletedSection())
    }
    onSectionDelete(section.id)
  }

  return (
    <SectionTable
      sectionsList={sectionsList}
      fileType={fileType}
      onSectionUpdate={onSectionUpdate}
      onSectionDelete={onSectionDelete}
      validationNotice={validationNotice}
      activeFile={activeFile}
    />
  )
}
