import { ReactElement } from 'react'
import PanelHeader from './PanelHeader'
import PanelValidationButton from '../Buttons/PanelValidationButton'
import PanelInputFields from '../InputFields/PanelInputFields'
import AddTupleButton from '../Buttons/AddTupleButton'

import './panel.scss'

export default function PortfolioPanel(): ReactElement {
  return (
    <div className="panel">
      <PanelHeader />
      <PanelInputFields />
      <div className="d-flex justify-content-center">
        <AddTupleButton />
        <PanelValidationButton />
      </div>
    </div>
  )
}
