/* eslint-disable no-prototype-builtins */
import {
  ReactElement, useEffect,
} from 'react'
import terms from 'common/terms'
import { Paper } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { Classification, Maturity } from 'reducers/MultiYearPlan/Legend/types'
import nextId from 'react-id-generator'
import getLegend from 'reducers/MultiYearPlan/Legend/legend.thunk'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

export default function Legends(): ReactElement {
  const dispatch = useDispatch()
  const { activeFile } = useSelector((state: RootState) => state.files)
  const { Legend, updateLegendChart } = useSelector((state: RootState) => state.myplegend)

  useEffect(() => {
    dispatch(getLegend({
      repertoryId: activeFile.directory,
      folderId: activeFile.folder,
      fileId: activeFile.id,
    }))
  }, [updateLegendChart])

  const StyledTableCell = styled(TableCell)(() => ({
    color: 'black',
    [`&.${tableCellClasses.head}`]: {
      borderTop: '1px solid #E2E7EF',
      textAlign: 'center',
      '&:first-child': {
        paddingLeft: 6,
        fontWeight: 900,
        fontSize: '1rem',
        backgroundColor: '#e2e7ef',
      },
    },
    [`&.${tableCellClasses.body}`]: {
      whiteSpace: 'nowrap',
      paddingLeft: 6,
      border: '1px solid #E2E7EF',
    },
  }))

  return (
    <>
      {Legend.hasOwnProperty('maturities') && (
      <TableContainer component={Paper}>
        <Table size="small" aria-label="simple table" style={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              <StyledTableCell width="250">{terms.MultiYearPlan.Legend.tableHeader}</StyledTableCell>
              {Legend?.maturities[0]?.classifications.map((classification: Classification) => (
                <StyledTableCell key={nextId()}>{classification.classification}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Legend?.maturities.map((maturity: Maturity) => (
              <TableRow key={nextId()}>
                <StyledTableCell size="small">{maturity.label}</StyledTableCell>
                {maturity?.classifications.map((classification: Classification) => (
                  <StyledTableCell
                    style={{ background: classification.color }}
                    key={nextId()}
                  >
                    {classification?.track_label}
                  </StyledTableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      )}
    </>
  )
}
