import { RootState } from 'Store'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ColumnValues, Filter } from 'reducers/MultiYearPlan/Settings/types'
import { updateAppliedFilters } from 'reducers/MultiYearPlan/Settings/settings'
import { deleteFilter, postFilter } from 'reducers/MultiYearPlan/Settings/settings.thunk'
import FilterAccordion from './FilterAccordion'

export default function AppliedFiltersAccordions(): ReactElement {
  const dispatch = useDispatch()
  const { appliedFilters } = useSelector((state: RootState) => state.mypSettings)
  const { activeFile } = useSelector((state: RootState) => state.files)

  const handleChange = (_event: React.ChangeEvent<HTMLInputElement>, option: ColumnValues) => {
    const filterToUpdate: Filter = appliedFilters.find((filter: Filter) => filter.id === -1)
    dispatch(updateAppliedFilters(appliedFilters.map((filter: Filter) => (filter.id !== -1 ? filter : {
      ...filterToUpdate,
      filtered_column_values:
       filterToUpdate.filtered_column_values.map((column: ColumnValues) => (column.value !== option.value
         ? column : { ...column, is_visible: !column.is_visible })),
    }))))
  }

  const onFilterApply = () => {
    dispatch(postFilter({
      repertoryId: activeFile.directory,
      folderId: activeFile.folder,
      fileId: activeFile.id,
      filter: {
        filtered_column_name: appliedFilters.find((filter: Filter) => filter.id === -1).filtered_column_name,
        filtered_column_values:
        appliedFilters.find((filter: Filter) => filter.id === -1).filtered_column_values,
      },
    }))
  }

  const handleDelete = (filterId: number) => {
    if (filterId === -1) {
      dispatch(updateAppliedFilters(appliedFilters.filter((filter: Filter) => filter.id !== -1)))
    } else {
      dispatch(deleteFilter({
        repertoryId: activeFile.directory,
        folderId: activeFile.folder,
        fileId: activeFile.id,
        filterId,
      }))
    }
  }

  return (
    <>
      {appliedFilters.length !== 0 && appliedFilters.map((filter: Filter) => (
        <div key={filter.id}>
          <FilterAccordion
            summary={filter.filtered_column_name}
            options={filter.filtered_column_values}
            filterId={filter.id}
            handleChange={(event, option) => handleChange(event, option as ColumnValues)}
            deleteFilter
            onDelete={handleDelete}
            onFilterApply={onFilterApply}
            optionLabelType="column"
          />
        </div>
      ))}
    </>
  )
}
