import {
  FeatureCollection, Position, ViewMode, Feature, Polygon,
} from '@nebula.gl/edit-modes'
import { PolygonLayer } from '@deck.gl/layers'

import { CompositeLayer } from '@deck.gl/core'

import { EditableGeoJsonLayer } from '@nebula.gl/layers'
import { polygon } from '@turf/helpers'
import turfBuffer from '@turf/buffer'
import turfDifference from '@turf/difference'
import {
  EditParams, EMPTY_DATA, EXPANSION_KM, LAYER_ID_BLOCKER, LAYER_ID_GEOJSON, MODE_CONFIG_MAP, MODE_MAP,
  PickingInfo,
  SelectionLayerProps, SELECTION_TYPE,
} from './utils'

class CustomSelectionLayer extends CompositeLayer<FeatureCollection, SelectionLayerProps> {
  selectRectangleObjects(coordinates: Position[][]): void {
    const { layerIds, onSelect } = this.props
    const [x1, y1] = this.context.viewport.project(coordinates[0][0])
    const [x2, y2] = this.context.viewport.project(coordinates[0][2])
    const pickingInfos = this.context.deck.pickObjects({
      x: Math.min(x1, x2),
      y: Math.min(y1, y2),
      width: Math.abs(x2 - x1),
      height: Math.abs(y2 - y1),
      layerIds,
    })

    onSelect({ pickingInfos, coordinates })
  }

  selectPolygonObjects(coordinates: Position[][]): void {
    const { layerIds, onSelect } = this.props
    const mousePoints = coordinates[0].map(c => this.context.viewport.project(c))

    const allX = mousePoints.map(mousePoint => mousePoint[0])
    const allY = mousePoints.map(mousePoint => mousePoint[1])
    const x = Math.min(...allX)
    const y = Math.min(...allY)
    const maxX = Math.max(...allX)
    const maxY = Math.max(...allY)

    // Use a polygon to hide the outside, because pickObjects()
    // does not support polygons
    const landPointsPoly = polygon(coordinates)
    const bigBuffer = turfBuffer(landPointsPoly, EXPANSION_KM)
    let bigPolygon
    try {
      // turfDifference throws an exception if the polygon
      // intersects with itself (TODO: check if true in all versions)
      bigPolygon = turfDifference(bigBuffer, landPointsPoly)
    } catch (e) {
      // invalid selection polygon
      console.log('turfDifference() error', e) // eslint-disable-line
      return
    }

    this.setState({
      pendingPolygonSelection: {
        bigPolygon,
      },
    })

    const blockerId = `${this.props.id}-${LAYER_ID_BLOCKER}`

    // HACK, find a better way
    setTimeout(() => {
      const pickingInfos = this.context.deck.pickObjects({
        x,
        y,
        width: maxX - x,
        height: maxY - y,
        layerIds: [blockerId, ...layerIds],
      }) as PickingInfo[]

      onSelect({
        pickingInfos: pickingInfos.filter(item => item.layer.id !== this.props.id),
        coordinates,
      })
    }, 250)
  }

  renderLayers(): Array<EditableGeoJsonLayer | PolygonLayer<unknown>> {
    const { pendingPolygonSelection } = this.state

    const mode = this.props.selectionType ? MODE_MAP[this.props.selectionType] : ViewMode
    const modeConfig = this.props.selectionType ? MODE_CONFIG_MAP[this.props.selectionType] : undefined

    const layers: Array<EditableGeoJsonLayer | PolygonLayer<unknown>> = [
      new EditableGeoJsonLayer({
        ...this.getSubLayerProps({
          id: LAYER_ID_GEOJSON,
          pickable: true,
          mode,
          modeConfig,
          selectedFeatureIndexes: [],
          data: EMPTY_DATA,
          onEdit: ({ updatedData, editType }: EditParams) => {
            if (editType === 'addFeature') {
              const { coordinates } = updatedData.features[0].geometry as Polygon

              if (this.props.selectionType === SELECTION_TYPE.RECTANGLE) {
                this.selectRectangleObjects(coordinates)
              } else if (this.props.selectionType === SELECTION_TYPE.POLYGON) {
                this.selectPolygonObjects(coordinates)
              }
            }
          },
        }),
      }),
    ]

    if (pendingPolygonSelection) {
      const { bigPolygon } = pendingPolygonSelection
      layers.push(
        new PolygonLayer(
          this.getSubLayerProps({
            id: LAYER_ID_BLOCKER,
            pickable: true,
            stroked: false,
            opacity: 1.0,
            data: [bigPolygon],
            getLineColor: () => [0, 0, 0, 1],
            getFillColor: () => [0, 0, 0, 1],
            getPolygon: (o: Feature) => o.geometry.coordinates,
          }),
        ),
      )
    }

    return layers
  }

  static shouldUpdateState(
    { changeFlags: { stateChanged, propsOrDataChanged } }: Record<string, Record<string, boolean>>,
  ): boolean {
    return stateChanged || propsOrDataChanged
  }
}

export default CustomSelectionLayer
