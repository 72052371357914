import { ReactElement } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { InputDataType } from './utils'

import './input.scss'

type Props = InputDataType & {
    icon?: ReactElement,
    disabled?: boolean,
  }

const defaultProps = {
  icon: <></>,
  disabled: false,
}

export default function SimpleInput({
  field, value, onChange, icon, extraStyle, disabled,
}: Props): ReactElement {
  return (
    <InputGroup hasValidation className="input-field">
      <Form.Control
        placeholder={field.placeholder}
        onChange={onChange}
        value={value}
        pattern={field.pattern}
        maxLength={field.maxLength}
        as={field.type}
        className={extraStyle}
        disabled={disabled}
      />
      {icon}
    </InputGroup>
  )
}

SimpleInput.defaultProps = defaultProps
