import { ReactElement } from 'react'
import GenericInput from 'components/common/Inputs/GenericInput'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { updatePageScale, updateRealityScale } from 'reducers/export'
import terms from 'common/terms'
import { InputLabel } from '@mui/material'

export default function ExportScalingFields(): ReactElement {
  const dispatch = useDispatch()
  const { pageScale, realityScale } = useSelector((state: RootState) => state.export)

  const handlePageScaleChange = (value: string) => {
    if (!Number.isNaN(+value) && value !== '0') {
      dispatch(updatePageScale(value))
    }
  }

  const handleRealityScaleChange = (value: string) => {
    if (!Number.isNaN(+value) && value !== '0') {
      dispatch(updateRealityScale(value))
    }
  }

  return (
    <>
      <InputLabel shrink className="export-scaling-label">
        {terms.Export.exportScaling}
      </InputLabel>
      <div className="export-scaling-fields">
        <GenericInput
          label={terms.Export.pageScale}
          value={pageScale}
          onChange={e => handlePageScaleChange(e.target.value)}
          inputProps={{ inputMode: 'numeric', pattern: '/d*' }}
          errorMessage={pageScale.length === 0 ? terms.Common.Errors.requiredField : ''}
        />
        <GenericInput
          label={terms.Export.realityScale}
          value={realityScale}
          onChange={e => handleRealityScaleChange(e.target.value)}
          inputProps={{ inputMode: 'numeric', pattern: '/d*' }}
          errorMessage={realityScale.length === 0 ? terms.Common.Errors.requiredField : ''}
        />
      </div>
    </>
  )
}
