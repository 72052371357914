import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'
import terms from 'common/terms'
import { getDefectedOperations, getPositionedOperations } from 'reducers/MultiYearPlan/Library/library.thunk'
import { toggleDisplaySettingsPanel } from 'reducers/MultiYearPlan/Settings/settings'
import { RootState } from 'Store'

export default function MypSettingsButton(): ReactElement {
  const dispatch = useDispatch()
  const { activeFile } = useSelector((state: RootState) => state.files)
  const { updateLibrary, searchValue } = useSelector((state: RootState) => state.mypLibrary)
  const { addedFilter, deletedFilter } = useSelector((state: RootState) => state.mypSettings)
  const { updateOperationsChart } = useSelector((state: RootState) => state.mypoperations)

  useEffect(() => {
    dispatch(getDefectedOperations({
      repertoryId: activeFile.directory,
      folderId: activeFile.folder,
      fileId: activeFile.id,
      search: searchValue,
    }))
  }, [updateLibrary, searchValue])

  useEffect(() => {
    dispatch(getPositionedOperations({
      repertoryId: activeFile.directory,
      folderId: activeFile.folder,
      fileId: activeFile.id,
      search: searchValue,
    }))
  }, [updateOperationsChart, searchValue, addedFilter, deletedFilter])

  return (
    <Button
      startIcon={<SettingsIcon />}
      onClick={() => dispatch(toggleDisplaySettingsPanel(true))}
      className="myp-settings-btn"
    >
      {terms.MultiYearPlan.settings}
    </Button>
  )
}
