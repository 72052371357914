import terms from 'common/terms'
import { InputField, InputTypes } from 'types'

const PORTFOLIO_LEGEND_FIELDS: InputField = {
  key: 'legendText',
  label: terms.Inputs.labelText,
  placeholder: terms.Inputs.legendPlacehoalder,
  required: true,
  inputType: InputTypes.SimpleField,
}

export default PORTFOLIO_LEGEND_FIELDS
