import { ReactElement } from 'react'
import terms from 'common/terms'
import TopBar from 'components/TopBar'
import DashboardNavBar from 'components/Dashboard/DashboardNavBar'
import DeleteRepertoryModal from './RepertoryActions/DeleteRepertoryModal'

export default function HomePage(): ReactElement {
  return (
    <>
      <TopBar appName={terms.Common.appName} />
      <DashboardNavBar />
      <DeleteRepertoryModal />
    </>
  )
}
