import { ReactElement, useState, useEffect } from 'react'
import terms from 'common/terms'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { updateElementTypeValue } from 'reducers/MultiYearPlan/TitleBlockActions/titleblockactions'
import { TbElementType } from 'reducers/MultiYearPlan/TitleBlockActions/types'
import TbElementTypeSelectField from './TbElementTypeSelectField'
import { getErrorMessage } from './utils'

export default function TbElementTypeChoice(): ReactElement {
  const dispatch = useDispatch()
  const {
    tbElementTypes, emptyFields, responseError, selectedTbElement,
  } = useSelector((state: RootState) => state.mypTitleBlockActions)
  const [elementType, setElementType] = useState<TbElementType | null>(null)

  useEffect(() => {
    if (selectedTbElement) {
      const selectedType = tbElementTypes.filter((e: TbElementType) => e.value === selectedTbElement.type)
      setElementType(selectedType[0])
      dispatch(updateElementTypeValue(selectedType[0].value))
    }
  }, [])

  const handleTbElementTypeChange = (value: TbElementType) => {
    setElementType(value)
    if (value) {
      dispatch(updateElementTypeValue(value.value))
    }
  }

  return (
    <div className="typology-select-container">
      <TbElementTypeSelectField
        value={elementType}
        onChange={(_event, newInputValue) => handleTbElementTypeChange(newInputValue as TbElementType)}
        options={tbElementTypes}
        placeholder={terms.Inputs.tbElementTypesPlaceholder}
        label={terms.Inputs.tbElementTypes}
        errorMessage={getErrorMessage(responseError, 'type', emptyFields, elementType as TbElementType)}
      />
    </div>
  )
}
