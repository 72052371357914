import { ReactElement } from 'react'
import ActionButton from 'components/common/Buttons/ActionButton'
import { toggleObjectPositionMode, toggleObjectOrientationMode, toggleObjectDeletionMode } from 'reducers/object'
import './object.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import ObjectService from 'components/Objects/ObjectService'
import turfCombine from '@turf/combine'
import terms from 'common/terms'

export default function ValidateObject(): ReactElement {
  const dispatch = useDispatch()
  const { modifiedObjectFeature, ObjectDeletionMode } = useSelector((state: RootState) => state.object)
  const { activeProject } = useSelector((state: RootState) => state.creation)
  const { rkLayerData } = useSelector((state: RootState) => state.object)

  const removeObject = async () => {
    const queryParams = {
      projectId: modifiedObjectFeature.properties.project_id,
      objectId: modifiedObjectFeature.properties.object_id,
      layer: modifiedObjectFeature.layer.id.replace('manual-', '').replace('small-', '').replace('small-manual-', '')
        .replace('CARRE_VIOLET', 'module_schematique_infra_signal')
        .replace('manual-CARRE_VIOLET', 'module_schematique_infra_signal')
        .replace('CARRE', 'module_schematique_infra_signal')
        .replace('manual-CARRE', 'module_schematique_infra_signal')
        .replace('DISQUE', 'module_schematique_infra_signal')
        .replace('manual-DISQUE', 'module_schematique_infra_signal')
        .replace('GUIDON_DARRET', 'module_schematique_infra_signal')
        .replace('manual-GUIDON_DARRET', 'module_schematique_infra_signal')
        .replace('SEMAPHORE', 'module_schematique_infra_signal')
        .replace('manual-SEMAPHORE', 'module_schematique_infra_signal')
        .replace('AVERTISSEMENT', 'module_schematique_infra_signal')
        .replace('manual-AVERTISSEMENT', 'module_schematique_infra_signal')
      ,
    }
    dispatch(ObjectService.deleteObject(queryParams))
  }

  const handleClick = () => {
    if (modifiedObjectFeature.layer.id.includes('rk')) {
      const combiendRkLayerData = turfCombine(rkLayerData)
      if (combiendRkLayerData.features.length !== 0) {
        const object = {
          objectId: combiendRkLayerData?.features[0]?.properties?.collectedProperties[0]?.object_id,
          projectId: activeProject.id,
          layer: 'module_schematique_infra_rk',
          params: {
            id: combiendRkLayerData?.features[0]?.properties?.collectedProperties[0]?.object_id,
            geometry: {
              type: combiendRkLayerData.features[0].geometry?.type,
              coordinates: combiendRkLayerData.features[0].geometry?.coordinates,
            },
            properties: {
              start_year: 2000,
              end_year: 2001,
              locked: false,
            },
          },
        }
        dispatch(ObjectService.patchObject(object))
      } else {
        removeObject()
      }
    } else {
      const object = {
        objectId: modifiedObjectFeature.properties.object_id,
        projectId: activeProject.id,
        layer: modifiedObjectFeature.layer.id === 'labelLayer' || modifiedObjectFeature.layer.id
      === 'small-labelLayer' ? 'labels'
          : modifiedObjectFeature.layer.id.replace('manual-', '')
            .replace('small-', '').replace('small-manual-', '')
            .replace('CARRE_VIOLET', 'module_schematique_infra_signal')
            .replace('manual-CARRE_VIOLET', 'module_schematique_infra_signal')
            .replace('CARRE', 'module_schematique_infra_signal')
            .replace('manual-CARRE', 'module_schematique_infra_signal')
            .replace('DISQUE', 'module_schematique_infra_signal')
            .replace('manual-DISQUE', 'module_schematique_infra_signal')
            .replace('GUIDON_DARRET', 'module_schematique_infra_signal')
            .replace('manual-GUIDON_DARRET', 'module_schematique_infra_signal')
            .replace('SEMAPHORE', 'module_schematique_infra_signal')
            .replace('manual-SEMAPHORE', 'module_schematique_infra_signal')
            .replace('AVERTISSEMENT', 'module_schematique_infra_signal')
            .replace('manual-AVERTISSEMENT', 'module_schematique_infra_signal'),
        params: {
          id: modifiedObjectFeature.properties.object_id,
          geometry: {
            type: modifiedObjectFeature?.geometry?.type,
            coordinates: modifiedObjectFeature?.geometry?.coordinates,
          },
          properties: {
            start_year: 2000,
            end_year: 2001,
            locked: false,
            angle: modifiedObjectFeature.properties.angle,
          },
        },
      }
      if (modifiedObjectFeature.layer.id === 'labelLayer' || modifiedObjectFeature.layer.id === 'small-labelLayer') {
        dispatch(ObjectService.patchLabel(object))
      } else {
        dispatch(ObjectService.patchObject(object))
      }
    }
    dispatch(toggleObjectPositionMode(false))
    dispatch(toggleObjectOrientationMode(false))
    dispatch(toggleObjectDeletionMode(false))
  }

  return (
    <ActionButton
      text={ObjectDeletionMode ? terms.Object.button.validateDeletion : terms.Object.button.validateModification}
      onClick={handleClick}
      buttonStyle="validate-object-btn"
    />
  )
}
