import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import { ResponseError } from 'types'
import {
  deleteFolder, getFoldersList, patchFolder, postFolder,
} from './folder.thunk'
import { Folder, FoldersState, XYPosition } from './types'

const initialState: FoldersState = {
  folders: [],
  displayDeletionModal: false,
  creationContext: false,
  actionsContext: false,
  xYPosition: { x: 0, y: 0 },
  editionMode: false,
  loadingFolders: false,
}

export const foldersSlice = createSlice({
  name: 'folders',
  initialState,
  reducers: {
    addNewFolder: (state, action: PayloadAction<Folder>) => {
      state.folders.push(action.payload)
      state.editableFolder = action.payload
      state.editionMode = true
    },
    setEditableFolder: (state, action: PayloadAction<Folder>) => {
      state.editableFolder = action.payload
    },
    setFolderToDelete: (state, action: PayloadAction<Folder>) => {
      state.displayDeletionModal = true
      state.editableFolder = action.payload
    },
    resetFolderToDelete: state => {
      state.displayDeletionModal = false
      state.editableFolder = undefined
    },
    setCreactionContext: (state, action: PayloadAction<boolean>) => {
      state.creationContext = action.payload
    },
    setActionsContext: (state, action: PayloadAction<boolean>) => {
      state.actionsContext = action.payload
    },
    resetContexts: state => {
      state.actionsContext = false
      state.creationContext = false
    },
    setXyPosition: (state, action: PayloadAction<XYPosition>) => {
      state.xYPosition = action.payload
    },
    setEditionMode: (state, action: PayloadAction<boolean>) => {
      state.editionMode = action.payload
    },
    setActiveFolder: (state, action: PayloadAction<Folder | undefined>) => {
      state.activeFolder = action.payload
    },
    resetFolderResponseError: (state, action: PayloadAction<ResponseError | undefined>) => {
      state.folderResponseError = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getFoldersList.fulfilled, (state, action) => {
      state.folders = action.payload
      state.loadingFolders = false
    })
    builder.addCase(getFoldersList.pending, state => {
      state.folders = []
      state.loadingFolders = true
    })
    builder.addCase(postFolder.fulfilled, (state, action) => {
      state.folders = state.folders.map(folder => (folder.id !== -1 ? folder : action.payload))
      state.editableFolder = undefined
      state.editionMode = false
      state.folderResponseError = undefined
    })
    builder.addCase(patchFolder.fulfilled, (state, action) => {
      state.folders = state.folders.map(folder => (folder.id !== action.payload.id ? folder : action.payload))
      state.editableFolder = undefined
      state.editionMode = false
      state.folderResponseError = undefined
    })
    builder.addCase(deleteFolder.fulfilled, (state, action) => {
      state.folders = state.folders.filter(folder => folder.id !== action.payload)
      state.displayDeletionModal = false
      state.editableFolder = undefined
      state.loadingFolders = false
    })
    builder.addCase(deleteFolder.pending, state => {
      state.loadingFolders = true
    })
    builder.addMatcher(isAnyOf(
      getFoldersList.rejected,
      postFolder.rejected,
      patchFolder.rejected,
      deleteFolder.rejected,
    ), (state, action) => {
      state.folderResponseError = action.payload
      state.loadingFolders = false
    })
  },
})

export const {
  addNewFolder, setEditableFolder, setFolderToDelete, setCreactionContext, setXyPosition,
  setActionsContext, resetContexts, resetFolderToDelete, setEditionMode, setActiveFolder, resetFolderResponseError,
} = foldersSlice.actions
export default foldersSlice.reducer
