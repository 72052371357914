/* eslint-disable max-len */
const terms = {
  HomePage: {
    welcomeTo: 'Bienvenue sur',
    bannerSubtitle: 'Veuillez sélectionner un répertoire d’étude pour continuer',
    searchPlaceholder: 'Rechercher un répertoire d’étude',
    repertoriesLength: 'Tous les répertoires d’étude',
    creationBtn: 'Déclarer un nouveau répertoire d’étude',
  },
  Common: {
    appName: 'ARPE',
    sortBy: 'Trier par:',
    alphabeticalOrder: 'A-Z',
    creationDate: 'Date de création',
    modificationDate: 'Date de modification',
    continue: 'Continuer',
    backToHome: 'Retour à l’écran d’accueil',
    declarationSubtitle: 'Pas d’inquiétude, vous pourrez le modifier plus tard',
    backToNameDeclaration: 'Retour au choix du nom',
    cancel: 'Annuler',
    delete: 'Supprimer',
    backHome: 'Retour à l’accueil',
    rename: 'Renommer',
    open: 'Ouvrir',
    share: 'Partager',
    synoptic: 'Synoptique',
    multiYearSchema: 'Schéma pluriannuel',
    synopAbrev: 'S',
    mypAbrev: 'SP',
    noResults: 'Pas de résultat',
    alert: {
      infoText: 'Info',
    },
    disconnect: 'Déconnexion',
    search: 'Rechercher...',
    Errors: {
      dropRejected: 'Veuillez utiliser une image au format jpg, jpeg ou png d’une taille maximale de 10Mo.',
      error: 'Une erreur est survenue',
      requiredField: 'Ce champ ne peut être nul.',
      importError: 'Une erreur est survenue, veuillez relancer le chargement du plan de voies',
      forbidden: {
        forbiddenTitle: 'Vous n’avez pas les droits nécessaires pour accéder à cette application',
        forbiddenSubtitle: 'Veuillez contacter votre approbateur',
      },
    },
    loading: 'Chargement...',
    confirm: 'Confirmer',
    update: 'Modifier',
    add: 'Ajouter',
  },
  Repertory: {
    declarationTitle: 'Quel nom pour votre répertoire d’étude ?',
    repertoryName: 'Nom du répertoire d’étude',
    namePlaceholder: 'Nouveau répertoire d’étude',
    pictureDeclarationTitle: 'Veuillez ajouter la photo de couverture',
    skipStep: 'Passer cette étape',
    selectFile: 'Glissez-déposez une photo ou sélectionnez le fichier sur votre ordinateur',
    backToPicture: 'Retour au choix de l’image',
    validatePicture: 'Valider la photo',
    deleteRepertory: 'Supprimer le répertoire d’étude',
    deleteNotice: 'Attention le répertoire sélectionné sera supprimé définitivement avec les dossiers et les documents qu’il contient.',
    settingTooltip: 'Configurer le répertoire d’étude',
    repertorySetting: 'Réglages du répertoire d’étude',
    searchPlaceholder: 'Rechercher un dossier / document',
  },
  Folder: {
    createFolder: 'Créer un dossier',
    deleteFolder: 'Supprimer le dossier',
    deleteModalNotice: 'Attention le dossier sélectionné sera supprimé définitivement avec les documents qu’il contient.',
  },
  File: {
    createDocument: 'Créer un nouveau document',
    searchPlaceholder: 'Rechercher un document',
    lastModified: 'Dernière modification le',
    fileName: 'Nom du document',
    Actions: {
      duplicate: 'Dupliquer',
      moveTo: 'Déplacer vers',
      share: 'Partager le document',
      shareNotice: 'Sélectionner le ou les utilisateur à qui vous souhaitez partager le document',
      sharePlaceholder: 'Sélectionner un ou des utilisateurs',
      moveToFolder: 'Déplacer vers un dossier',
      deleteFile: 'Supprimer le document',
      deleteNotice: 'Attention le document sélectionné sera supprimé définitivement.',
      renameFile: 'Renommer le document',
      moveFile: 'Déplacer le document',
      move: 'Déplacer',
      foldersList: 'Liste des dossiers',
      selectFolderPlaceholder: 'Sélectionner un dossier',
      cloneLoading: 'Duplication en cours',
      cloneDone: 'Duplication terminée',
      cloneFail: 'Echec de la duplication',
      shareDone: 'Partage effectué',
    },
    Declaration: {
      backToName: 'Retour au choix du nom',
      temporalPerimetre: 'Quel périmètre temporel ?',
      backToTemporelPerimeter: 'Retour au périmètre temporel',
      geographicPerimeter: 'Quel périmètre géographique ?',
      addSection: 'Ajouter la section',
      backToGeographicPerimeter: 'Retour au périmètre géographique',
      backToDeclarationModeChoice: 'Retour au choix de la déclaration',
      geographicCloseNotice: 'Pensez à valider votre section en cours d’ajout!',
      Schema: {
        multiYearSchemaTitle: 'Quel nom pour votre schéma pluriannuel ?',
        namePlaceholder: 'Nouveau schéma pluriannuel',
        multiYearSchemaName: 'Nom du schéma pluriannuel',
        temporalPetimeterNotice: 'Qui s’appliquera à la trame du schéma pluriannuel',
        geographicPerimeterNotice: 'Veuillez ajouter les sections qui composent votre schéma pluriannuel',
        manualDeclaration: 'Ajouter les sections manuellement',
        fileImport: 'Partir d’un périmètre déjà créé',
        searchBarPlaceholder: 'Rechercher un périmètre déjà créé',
        resetSectionsNotice: 'Tout nouveau choix d’une option de déclaration du périmètre géographique supprime les sections déjà déclarées. Veuillez cliquer sur la croix pour éviter cette action.',
        updateTemporalPerimeter: 'Modifier le périmètre temporel',
        updateGeographicPerimeter: 'Modifier le périmètre géographique',
        spLoaderNotice: 'Veuillez patienter, cette action peut prendre plusieurs minutes',
      },
      Synoptic: {
        synopticTitle: 'Quel nom pour votre synoptique ?',
        synopticName: 'Nom du document',
        namePlaceholder: 'Nouveau schéma synoptique',
        geographicPerimeterNotice: 'Veuillez ajouter les sections qui composent votre synoptique',
        initializationNotice: "Veuillez patienter, l'affichage du plan de voies peut prendre plusieurs minutes",
      },
    },
  },
  Synoptic: {
    createFondPlan: 'Création du fond de plan',
    addPortfolio: 'Ajout du portefeuille',
    generateDoc: 'Génération du document',
    Buttons: {
      updateGeographicPerimeter: 'Modifier le périmètre géographique',
      addObject: 'Ajout des éléments d’infrastructure',
      addObjectsAuto: 'Ajout des couches d’infrastructure',
      switchGeoeditor: 'Linéariser le plan de voie',
      switchGeoeditorObject: 'Modifier la position des objets',
      switchGeoeditorObjectValidate: 'Valider la position des objets',
    },
  },
  Object: {
    header: 'Ajout des élements d’infrastructure',
    updateObject: "Modifier l'objet",
    deleteObject: "Supprimer l'objet",
    deleteNotice: "Attention l'objet sélectionné sera définitivement supprimé",
    button: {
      flip: 'Modifier la direction',
      orientation: 'Modifier l‘orientation',
      position: 'Modifier la position',
      validateModification: 'Valider la modification',
      cancelModification: 'Annuler la modification',
      validateDeletion: 'Valider la suppression',
      cancelDeletion: 'Annuler la suppression',
    },
  },
  Inputs: {
    startYearLabel: 'Année de début',
    startYearPlaceholder: 'Entrez une année de début',
    endYearLabel: 'Année de fin',
    endYearPlaceholder: 'Entrez une année de fin',
    sectionName: 'Nom de section',
    sectionNamePlaceholder: 'Entrez un nom de section',
    lineNumber: 'Numéro de ligne',
    lineNumberLabel: 'Entrez le n° de ligne',
    lineNumberPlaceholder: '950000',
    pkDebut: 'PK début',
    pkPlaceholder: '000+000',
    pkDebutPlaceholder: 'Entrez le pk début',
    pkFinPlaceholder: 'Entrez le pk fin',
    pkFin: 'PK fin',
    order: 'Ordre',
    step: 'Pas',
    stepPlaceholder: '1',
    direction: 'Sens',
    increment: 'Croissant',
    decrement: 'Décroissant',
    labelText: 'Libellé',
    labelTextPlaceholder: 'Entrez le libellé de l’étiquette',
    trackName: 'Voie (optionnelle)',
    trackNamePlaceholder: 'Choisissez une voie',
    attritutSelectorPlaceholder: 'Sélectionner un attribut à ajouter à l’étiquette',
    lineAttributPlacehoalder: 'Sélectionner l’attribut NUMÉRO DE LIGNE',
    trackAttributPlacehoalder: 'Sélectionner l’attribut VOIE',
    pkDebutAttributPlacehoalder: 'Sélectionner l’attribut PK DÉBUT',
    pkFinAttributPlacehoalder: 'Sélectionner l’attribut PK FIN',
    eligibilityGrid: 'GRILLE D’ÉLIGIBILITÉ',
    eligibilityGridPlacehoalder: 'Sélectionner la colonne GRILLE D’ÉLIGIBILITÉ',
    category: 'ATTRIBUT COULEUR',
    categoryPlacehoalder: "Sélectionner l'attribut COULEUR",
    versionIndex: 'Indice',
    versionDate: 'Date',
    version: 'Entité',
    versionComment: 'Commentaire',
    author: 'Auteur',
    legendPlacehoalder: 'Entrez le libellé de la légende',
    line: 'Ligne',
    pk: 'Pk',
    prLabel: 'Libellé du PR',
    prLabelPlaceholder: 'Entrez un libellé du PR',
    important: 'PR Important',
    requiredTrackName: 'Voie',
    operationLabel: 'Libellé',
    operationLabelPlaceholder: 'Entrez le libellé',
    typology: 'Famille d’objets',
    typologyPlaceholder: 'Choisir  la famille d’objets',
    opera: 'Code typo/programme/Opera',
    operaPlaceholder: 'Choisir  le code typo/programme/Opera',
    years: 'Année active',
    yearsPlaceholder: 'Entrez l’année active',
    phase: 'Phase',
    selectPhasePlaceholder: 'Sélectionnez l’attribut PHASE',
    spPhase: 'Année active/Phase',
    phasePlaceholder: 'Entrez la phase',
    tbElementTypes: 'Couches d‘infrastructure',
    tbElementTypesPlaceholder: 'Choisir une couche d‘infrastructure',
  },
  Portfolio: {
    addLabel: 'Créer une étiquette',
    labelCreation: 'Création d’une étiquette',
    validationButton: 'Valider l’étiquette',
    addTuple: 'Ajouter un polygone',
    position: 'Position du polygone n°',
    deleteLabel: "Supprimer l'étiquette",
    deleteNotice: "Attention l'étiquette sélectionnée sera définitivement supprimée",
    updateLabelNotice: "Modification de l'étiquette",
    updateLabel: 'Redimensionner l’étiquette',
    changeColor: 'Changer la couleur',
    chooseColor: 'Choisir une couleur',
    updatePolygon: 'Modifier la forme',
    labelGeoEditorValidation: 'Valider la modification',
    labelGeoEditorCancel: 'Annuler la modification',
    overlap: 'superpositions',
    Import: {
      importPortfolio: 'Import du portefeuille',
      backToSynoptic: 'Retour au synoptique',
      backToPreviousStep: "Retour à l'étape précédente",
      summary: 'Sommaire',
      dragAndDropNotice: 'Glissez-déposez le portefeuille ici ou',
      selectNotice: ' sélectionnez le fichier sur votre ordinateur',
      settingsNoticeTitle: 'À cette étape, veuillez sélectionner les attributs à afficher sur les étiquettes',
      settingsNotice: 'Vous pouvez réorganiser les attributs dans l’ordre que vous souhaitez.Les attributes libellé, numéro de ligne, pk début et pk fin sont affichés par défaut dans les étiquettes.',
      noSettingsNotice: "Veuillez renseigner à minima un attribut dans l'étiquette pour continuer le parcours permettant l'import du portefeuille",
      settingsChoiceNotice: "Veuillez sélectionner un attribut ou continuer le parcours permettant l'import du portefeuille",
      settingsTitle: 'Choix des attributs à afficher',
      attributsValidationNoticeTitle: 'À cette étape, nous devons vérifier la conformité du format des champs liés à la ferro-localisation',
      attributsValidationTitle: 'Veuillez sélectionner les attributs de ferro-localisation',
      attributsValidationNotice: 'Si le nom des attributs du portefeuille varie légèrement, nous seront quand même en mesure de placer les étiquettes sur le plan de voie',
      eligibilityGridTitle: 'Veuillez sélectionner la colonne grille d’éligibilité',
      eligibilityGridNotice: "Sont importées toutes les lignes dont la valeur \"grille d'éligibilité\" est non vide.",
      categoryTitle: "Veuillez sélectionner l'attribut pour l'affection de la couleur",
      categoryNotice: "Toutes les valeurs de l'attribut sont analysées. En fonction de cette analyse, une couleur est affectée à une valeur ou à un groupe de valeurs.",
      importLoadingNotice: 'Veuillez patienter, import des étiquettes en cours',
      importedRows: ' lignes ont été importées',
      positionedLabels: ' étiquettes ont été placées sur le plan de voie',
      nonPositionedLabels: ' étiquettes n’ont pas pu être placées, elles seront disponibles dans votre bibliothèque d’étiquettes',
      acceptedFormat: 'Formats acceptés: .xls, .xlsx, .xlsm',
      docNotice: 'Si le fichier importé comporte plus d’un onglet, l’onglet restituant l’information à représenter est à placer en 1ère position',
      excelFilterNotice: "L'outil s'affranchit des filtres appliqués dans l'onglet du portefeuille importé",
      ferrolocationFieldsNotice: 'Les objets avec des informations de ferrolocalisation multilignes et/ou multivoies ne seront pas positionnés automatiquement',
      instructionsSteps: {
        importPortfolio: 'Import du portefeuille',
        importPortfolioDescription: 'Sélectionnez le fichier à importer depuis votre ordinateur, puis la colonne grille d’éligibilité',
        attributsChoice: 'Choix des attributs à afficher',
        attributsChoiceDescription: 'Ici vous pourrez choisir et organiser les différents attributs du portefeuille à afficher dans les étiquettes',
        confirmChoice: 'Conformité des attributs de ferro-localisation',
        confirmChoiceDescription: 'Pour finaliser le placement des étiquettes, veuillez vérifier la conformité du format portefeuille pour les champs liés à la ferro-localisation',
        finalStep: 'Finalisation',
        finalStepDescription: 'Génération et placement automatique des étiquettes sur le plan de voie',
      },
      Buttons: {
        start: 'Commencer',
        continue: 'Continuer',
        addAttribut: 'Ajouter l’attribut',
      },
    },
    Library: {
      importedLabels: 'Étiquettes importées automatiquement',
      createdLabels: 'Étiquettes créées manuellement',
      notPositionedLabels: 'Étiquettes qui n’ont pas pu être placées',
      libraryButton: 'Bibliothèque d’étiquettes',
      Filters: {
        modalHeader: 'Filtrer les étiquettes',
        applyButton: 'Appliquer les filtres',
        addButton: 'Ajouter un filtre',
        validationButton: 'Valider',
        workPhase: 'Phases études/travaux',
        workPhaseExtended: 'Phases études/travaux',
        date: 'Date',
      },
    },
  },
  Map: {
    Toolbar: {
      layerLoading: 'Chargement des couches en cours',
      layerLoadingError: 'Erreur lors du chargement des couches',
      relaunch: 'Relancer le chargement',
    },
  },
  Validation: {
    lineError: 'La section de ligne correspondante n’existe pas, veuillez vérifier que le numéro de ligne soit valide.',
    emptyRange: 'La section de ligne correspondante n’existe pas, veuillez vérifier que les pk renseignés soient valides.',
    success: 'Succès',
    fail: 'Echec',
  },
  Export: {
    backToSchema: 'Retour au schéma synoptique',
    exportSvg: 'Exporter en SVG',
    exportPdf: 'Exporter en PDF',
    exportLoading: 'Génération du document',
    objectsLegend: "Légende des éléments d'infrastructure",
    portfolioLegend: 'Légende des objets du portefeuille',
    cartoucheTitle: 'DOCUMENT INTERNE SNCF RÉSEAU',
    cartoucheTransmetter: 'Émetteur: DGAFP/programmatique',
    addVersion: 'Ajouter une ligne',
    validate: 'Valider',
    warning: 'Points d’attention',
    exportLoadingNotice: 'Export en cours...',
    validatedCartridge: 'Cartouche validé',
    exportFail: "Echec de l'export",
    exportSuccess: 'Export réussi',
    changeLegendTitle: 'Changer le libellé de la légende',
    settingsFormTitle: 'Paramètres de l’export',
    displayRk: 'Règle d’affichage des repères kilométriques',
    displayCartridge: 'Inclure le cartouche bas de page',
    exportScaling: 'Mise à l’échelle de la schématique',
    pageScale: 'cm',
    realityScale: 'm',
  },
  MultiYearPlan: {
    importBtn: 'Importer le portefeuille',
    settings: 'Paramètres',
    addPrBtn: 'Ajouter un PR',
    prToAdd: 'PR à ajouter',
    addPr: 'Ajouter le',
    pr: 'PR',
    Import: {
      backToMyp: 'Retour au schéma pluriannuel',
      backToPreviousStep: 'Retour à l’étape précédente',
      criteriaChoiceTitle: 'Veuillez sélectionner vos critères pour caractériser vos objets',
      criteriaChoiceNotice: 'Codes typo, opéra, programmes',
      criteria: 'Critère',
      criteriaPlaceholder: 'Sélectionnez le critère pour caractériser vos objets',
      generateMyp: 'Générer le schéma pluriannuel',
      regenerateMyp: 'Réinitialiser les familles d’objets',
      label: 'Libellé',
      labelChoiceTitle: 'Veuillez sélectionner la colonne libellé',
      labelPlaceholder: 'Sélectionnez l’attribut LIBELLÉ',
      formatChoiceTitle: 'Veuillez sélectionner les colonnes correspondant aux années',
      formatXColumn: 'Format X colonnes',
      formatLine: 'Format dé-pivoté “ligne”',
      yearColumn: 'Colonne année',
      yarColunmHeader: 'Sélectionnez la colonne correspondant à l’année de réalisation',
      startYearColumn: 'Colonne année de début',
      startYearColumnHeader: 'Sélectionnez la colonne correspondant à l’année de début de réalisation',
      endYearColumn: 'Colonne année de fin',
      endYearColumnHeader: 'Sélectionnez la colonne correspondant à l’année de fin de réalisation',
      ferrolocalizationTitle: 'Veuillez sélectionner les attributs de ferro-localisation',
      operationChoiceTitle: 'Afin de visualiser les différents statuts d’opération, veuillez indiquer votre colonne C6',
      operation: 'Colonne C6',
      operationPlaceholder: 'Sélectionnez l’attribut colonne C6',
      objectsFamiliesTitle: 'Veuillez caractériser les familles d’objets à importer',
      objectsFamiliesNotice: 'Ces paramètres seront appliqués par défaut et modifiables à l’import du portefeuille',
      addObjectFamilyButton: 'Créer une nouvelle famille d‘objet ',
      addTrack: 'Ajouter une voie',
      colorNotice: 'La couleur est portée par la voie',
      loading: 'Veuillez patienter, l‘affichage du schéma pluriannuel est en cours',
      Actions: {
        deleteObjectFamily: 'Supprimer la famille d‘objet',
        deleteObjectFamilyNotice: 'Attention la famille d‘objet sera supprimé définitivement et les code typo/programme/paramètres Opera associés seront indiqués comme non-attribué',
        changeColor: 'Changer la couleur',
      },
    },
    Actions: {
      deletePr: 'Supprimer le PR ',
      deleteNotice: 'Attention le PR sélectionné sera supprimé définitivement.',
      updatePr: 'Modifier le PR',
      deleteOperation: 'Supprimer l‘opération',
      deleteOperationNotice: 'Attention l‘opération sélectionnée sera supprimée définitivement.',
      deleteTbElement: 'Supprimer cet élément',
      deleteTbElementNotice: 'Attention l‘élément sélectionné sera supprimé définitivement.',
      moveLabel: 'Déplacer le libellé',
      reset: 'Annuler',
      save: 'Sauvegarder',
      addObject: 'Créer l’objet',
      modifyOperationColor: 'Changer la couleur',
      modifyOperation: 'Éditer l‘objet',
      modifyObject: 'Modifier l’objet',
      duplicateObject: 'Dupliquer l‘objet',
    },
    SettingsPanel: {
      filtersSettings: 'FILTRES AFFICHAGE',
      cartridge: 'Caractéristiques de l’axe',
      phases: 'Phases',
      typology: 'Familles d’objet',
      addFilter: 'Ajouter un filtre',
      importSettings: 'PARAMÈTRES D’IMPORT',
      updateGeographicPerimeter: 'Modifier le périmètre géographique',
      updateTemporalPerimeter: 'Modifier la plage temporelle',
      updateCodeTypo: 'Modifier les familles d’objet',
      library: 'BIBLIOTHÈQUE',
      objectsLibrary: 'Bibliothèque d’objets',
      export: 'EXPORT',
      pdfExport: 'Exporter en PDF',
      regeneratePortfolio: 'Exporter votre portefeuille',
      noOptions: 'Aucune valeur',
      applyFilter: 'Appliquer le filtre',
      empty: 'Vide',
      Library: {
        libraryPanelHeader: 'Retour aux paramètres',
        positionedOperations: 'Objets travaux positionnés',
        defectedOperations: 'Objets non-positionnés',
        fixAnomaly: 'Traiter l’anomalie',
        deleteObject: 'Supprimer l’objet',
        placeObject: 'Positionner l’objet',
      },
    },
    Operations: {
      addOperation: 'Créer un nouvel objet',
      ferroloc: 'Ferrolocalisation',
    },
    TileBlock: {
      addTbElement: 'Créer un nouvel objet',
      sectionTitle: 'Caractéristiques de l’axe',
      goToUrl: 'Suivre le lien',
    },
    Legend: {
      tableHeader: 'Légende',
    },
    Comments: {
      sectionTitle: 'Section cartouche',
      ActionsButton: {
        AddBtn: 'Ajouter un commentaire',
        DeleteBtn: 'Supprimer le commentaire',
        ModifyBtn: 'Modifier le commentaire',
      },
    },
    ExportPdf: {
      modalTitle: 'Exporter le schéma pluriannuel en PDF',
      buttons: {
        validation: 'Exporter le schéma pluriannuel',
        previz: 'Prévisualiser',
      },
      inputs: {
        pageFormat: {
          label: 'Format des feuillets',
          placeHolder: 'Choisir un format',
        },
        pageNumber: {
          label: 'Nombre de feuillets',
          placeHolder: 'Choisir un nombre',
        },
        pageOrientation: {
          label: 'Orientation',
          placeHolder: 'Choisir une orientation',
        },
      },
      checkboxes: {
        greenOption: {
          label: 'Exporter sur fond blanc',
        },
        includeTileBlock: {
          label: 'Inclure les caractéristiques de l’axe',
        },
        includeLegend: {
          label: 'Inclure la légende',
        },
      },
    },
  },
}

export default terms
