import {
  EditAction, FeatureCollection, Geometry, ImmutableFeatureCollection, LineString, LineStringCoordinates,
} from '@nebula.gl/edit-modes'
import { featureCollection } from '@turf/helpers'
import {
  GeoEditorState, setEditorLayerMode, setExtendingFeatureCollection, setGeoJson, setSelectedFeatures,
} from 'components/GeoEditor/reducer'
import { store } from 'Store'
import { EditorModeName } from '../types'

const onExtendLine = (editAction: EditAction<FeatureCollection>): void => {
  const { geoJson, extendingEditContext } = store.getState().geoEditor as GeoEditorState
  if (geoJson && extendingEditContext) {
    const { updatedData } = editAction
    const { featureIndexes, positionIndexes } = extendingEditContext
    const featureIndex: number = featureIndexes[0]
    const updatedFeature = geoJson.features[featureIndex]
    let newGeoJson
    if (updatedFeature.geometry.type === 'MultiLineString') {
      const lineStringIndex: number = positionIndexes[0]
      const oldGeometry = geoJson.features[featureIndex].geometry as LineString
      newGeoJson = new ImmutableFeatureCollection(geoJson)
        .replaceGeometry(featureIndex, {
          ...oldGeometry,
          coordinates: [
            ...oldGeometry.coordinates.slice(0, lineStringIndex),
            updatedData.features[0].geometry.coordinates as LineStringCoordinates,
            ...oldGeometry.coordinates.slice(lineStringIndex + 1),
          ],
        } as Geometry)
        .getObject()
    } else if (updatedFeature.geometry.type === 'LineString') {
      newGeoJson = new ImmutableFeatureCollection(geoJson)
        .replaceGeometry(featureIndex, updatedData.features[0].geometry)
        .getObject()
    }
    store.dispatch(setGeoJson(newGeoJson))
    store.dispatch(setSelectedFeatures([featureIndex]))
    store.dispatch(setExtendingFeatureCollection(featureCollection([]) as FeatureCollection))
    store.dispatch(setEditorLayerMode(EditorModeName.Edit))
  }
}

export default onExtendLine
