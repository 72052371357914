/* eslint-disable react/no-array-index-key */
import IconButton from '@mui/material/IconButton'
import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import RemoveIcon from '@mui/icons-material/Remove'
import { updatePrFields } from 'reducers/RemarkablePoints/remarkablePoints'
import { PrFields } from 'reducers/RemarkablePoints/types'
import AddPrInputs from './AddPrInputs'

export default function PrFieldsContainer(): ReactElement {
  const dispatch = useDispatch()
  const { prFields, responseError } = useSelector((state: RootState) => state.remarkablePoints)

  useEffect(() => {
    if (prFields.length === 0) {
      dispatch(updatePrFields([{
        index: 1,
        pr: {
          line_code: '',
          kp: '',
          label: '',
          is_important: false,
        },
      }]))
    }
  }, [prFields])

  const handleClick = (index: number) => {
    const newPrFields: PrFields[] = []
    prFields.forEach((pr: PrFields) => {
      if (pr.index !== index) {
        newPrFields.push({ ...pr, index: pr.index > index ? pr.index - 1 : pr.index })
      }
    })
    dispatch(updatePrFields(newPrFields))
  }

  return (
    <div className="pr-modal-body">
      {prFields.map((pr: PrFields, index: number) => (
        <div key={`${pr.index}-${index}`} className="d-flex">
          <AddPrInputs prIndex={pr.index} />
          {prFields.length > 1 && (
          <div className={`remove-pr-btn-container ${responseError ? ' error-remove-btn' : ''}`}>
            <IconButton onClick={() => handleClick(pr.index)}><RemoveIcon /></IconButton>
          </div>
          )}
        </div>
      ))}
    </div>
  )
}
