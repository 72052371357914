import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { MypImportStepsList } from '../types'
import CriteriaConfirmationButton from './CriteriaConfirmationButton'
import FerrolocalizationConfirmationButton from './FerrolocalizationConfirmationButton'
import FormatConfirmationButton from './FormatConfirmationButton'
import LabelConfirmationButton from './LabelConfirmationButton'
import MypGenerationButton from './MypGenerationButton'
import OperationConfirmationButton from './OperationConfimationButton'

export default function ImportButtonsContainer(): ReactElement {
  const { activeStep } = useSelector((state: RootState) => state.mypImport)

  switch (activeStep) {
    case MypImportStepsList.CriteriaChoice:
      return <CriteriaConfirmationButton />
    case MypImportStepsList.LabelChoice:
      return <LabelConfirmationButton />
    case MypImportStepsList.PortfolioFormat:
      return <FormatConfirmationButton />
    case MypImportStepsList.Ferrolocalization:
      return <FerrolocalizationConfirmationButton />
    case MypImportStepsList.OperationChoice:
      return <OperationConfirmationButton />
    case MypImportStepsList.Parametrage:
      return <MypGenerationButton />
    default:
      return <></>
  }
}
