import { Position } from '@nebula.gl/edit-modes'
import {
  degreesToRadians, Feature, lineString, LineString, point, Point,
} from '@turf/helpers'
import turfLineIntersect from '@turf/line-intersect'

const rotate = (p: Position, angle: number): Position => [
  (p[0] * Math.cos(angle)) - p[1] * Math.sin(angle),
  (p[0] * Math.sin(angle)) + p[1] * Math.cos(angle),
]

export const planeTranslate = (p: Position, distance: number, direction: number): Position => {
  const [x, y] = p

  const newX = x + distance * Math.cos(direction)
  const newY = y + distance * Math.sin(direction)

  return [newX, newY]
}

export const planeDistance = (coords1: Position, coords2: Position) => {
  const [x1, y1] = coords1
  const [x2, y2] = coords2

  return Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2)
}

export const planeRotate = (p: Position, angle: number, origin: Position): Position => {
  const r = degreesToRadians(angle)

  if (!origin || (origin[0] === 0 && origin[1] === 0)) {
    return rotate(p, r)
  }

  const p0 = p.map((c, i) => c - origin[i]) as Position
  const rotated = rotate(p0, r)
  return rotated.map((c, i) => c + origin[i]) as Position
}

export const absoluteAngle = (point1: Position, point2: Position) => (
  Math.atan2(point2[1] - point1[1], point2[0] - point1[0])
)

const getCoords = (p: Feature<Point>) => p.geometry.coordinates as Position

export const planeNearestPointOnLine = (
  line: Feature<LineString>,
  pt: Position,
): Feature<Point> => {
  let closestPt: Feature<
    Point,
    { dist: number; index: number; location: number }
  > = point([Infinity, Infinity], {
    dist: Infinity,
    index: -1,
    location: -1,
  })

  let length = 0.0
  const coords = line.geometry.coordinates
  for (let i = 0; i < coords.length - 1; i += 1) {
    // start
    const start: Feature<Point, { dist: number }> = point(coords[i])
    start.properties.dist = planeDistance(pt, getCoords(start))
    // stop
    const stop: Feature<Point, { dist: number }> = point(coords[i + 1])
    stop.properties.dist = planeDistance(pt, getCoords(stop))
    // sectionLength
    const sectionLength = planeDistance(getCoords(start), getCoords(stop))
    // perpendicular
    const heightDistance = Math.max(
      start.properties.dist,
      stop.properties.dist,
    )
    const direction = absoluteAngle(getCoords(start), getCoords(stop))
    const perpendicularPt1 = planeTranslate(
      pt,
      heightDistance,
      direction + (Math.PI / 2),
    )
    const perpendicularPt2 = planeTranslate(
      pt,
      heightDistance,
      direction - (Math.PI / 2),
    )
    const intersect = turfLineIntersect(
      lineString([
        perpendicularPt1,
        perpendicularPt2,
      ]),
      lineString([start.geometry.coordinates, stop.geometry.coordinates]),
    )
    let intersectPt:
        | Feature<Point, { dist: number; location: number }>
        | undefined

    if (intersect.features.length > 0 && intersect.features[0]) {
      intersectPt = {
        ...intersect.features[0],
        properties: {
          dist: planeDistance(pt, getCoords(intersect.features[0])),
          location: length + planeDistance(getCoords(start), getCoords(intersect.features[0])),
        },
      }
    }

    if (start.properties.dist < closestPt.properties.dist) {
      closestPt = {
        ...start,
        properties: { ...start.properties, index: i, location: length },
      }
    }

    if (stop.properties.dist < closestPt.properties.dist) {
      closestPt = {
        ...stop,
        properties: {
          ...stop.properties,
          index: i + 1,
          location: length + sectionLength,
        },
      }
    }

    if (
      intersectPt
        && intersectPt.properties.dist < closestPt.properties.dist
    ) {
      closestPt = {
        ...intersectPt,
        properties: { ...intersectPt.properties, index: i },
      }
    }
    // update length
    length += sectionLength
  }

  return closestPt
}
