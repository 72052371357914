export enum IMPORT_STATUS {
    Validated = 'VALIDATED',
    Imported = 'IMPORTED',
    ImportError = 'IMPORT_ERROR',
    LineError = 'VALIDATION_ERROR_LINE_NOT_FOUND',
    EmptyRange = 'VALIDATION_ERROR_EMPTY_RANGE'
}

export enum TASKS_NAMES {
    Validate = 'section.validate',
    MypValidate = 'multi_year_plan_section.validate',
    ImportVp = 'section.import.vp',
    ImportVs = 'section.import.vs',
    ImportInfra = 'section.import.infra',
    Export = 'export',
}

export enum TASKS_STATUS {
    Success = 'SUCCESS',
    Failure = 'FAILURE',
    Pending = 'PENDING'
}
