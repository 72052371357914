import { ReactElement, useEffect } from 'react'
import ActionButton from 'components/common/Buttons/ActionButton'
import history from 'customHistory'
import terms from 'common/terms'
import PathNames from 'common/PathNames'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { patchRepertory } from 'reducers/Repertories/repertories.thunk'
import { toggleUpdateImage } from 'reducers/Repertories/repertories'

export default function PictureDeclarationButtons({ acceptedFiles }: {acceptedFiles: Blob[]}): ReactElement {
  const dispatch = useDispatch()
  const { activeRepertory, updateImage } = useSelector((state: RootState) => state.repertories)

  useEffect(() => {
    if (updateImage) {
      history.push(PathNames.Home)
      dispatch(toggleUpdateImage())
    }
  }, [updateImage])

  const onValidate = () => {
    const formData = new FormData()
    formData.append('picture', acceptedFiles[0])
    const patchParams = {
      repertoryId: activeRepertory.id,
      repertory: {
        picture: formData,
      },
    }
    dispatch(patchRepertory(patchParams))
  }

  return (
    <div className="d-flex justify-content-around">
      <ActionButton
        text={terms.Common.cancel}
        onClick={() => history.push(PathNames.Picture)}
        buttonStyle="cancel-btn"
      />
      <ActionButton
        text={terms.Repertory.validatePicture}
        onClick={onValidate}
        buttonStyle="pic-validation-btn"
      />
    </div>
  )
}
