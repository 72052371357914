import {
  PayloadAction, createSlice, isAnyOf,
} from '@reduxjs/toolkit'
import { ResponseError } from 'types'
import {
  MypOperationActionsState, ContextPosition, OperationPoint,
  OperationFerrolocFields, OperationWorkPhaseFields,
  Operation,
} from './types'
import {
  deleteOperation, generateRepresentation,
  patchOperationPosition, getSubClassificationsByClassificationId,
  getLineCodeTracksList, patchOperation, postOperation, getOperation,
} from './operationactions.thunk'
import { PatchFamilyTrack, patchClassification } from '../Import/ObjectsFamilies/objectsFamilies.thunk'
import { deleteDefectedOperation } from '../Library/library.thunk'

const initialState: MypOperationActionsState = {
  generatedRepresentation: false,
  displayAddOperationModal: false,
  displayModifyOperationModal: false,
  openOperationContextMenu: false,
  openOperationColorDialog: false,
  displayDeleteModal: false,
  displayMoveOperationDialog: false,
  contextPosition: {
    x: 0,
    y: 0,
  },
  loadingRepresentation: false,
  subClassificationByClassification: [],
  operationLabel: '',
  subClassificationId: -1,
  ferrolocFields: [],
  tracksList: [],
  phaseFields: [],
  emptyFields: false,
  deletePhaseField: false,
  deleteFerrolocField: false,
  deletingOperation: false,
}

export const mypOperationActionsSlice = createSlice({
  name: 'mypoperationactions',
  initialState,
  reducers: {
    resetObjectsFileds: state => {
      state.operationLabel = ''
      state.subClassificationId = -1
      state.phaseFields = []
      state.ferrolocFields = []
      state.selectedOperationPoint = undefined
      state.selectedOperation = undefined
      state.responseError = undefined
    },
    toggleDisplayDeleteModal: (state, action: PayloadAction<boolean>) => {
      state.displayDeleteModal = action.payload
    },
    toggleMoveOperationDialog: (state, action: PayloadAction<boolean>) => {
      state.displayMoveOperationDialog = action.payload
    },
    toggleGenerateRepresentation: (state, action: PayloadAction<boolean>) => {
      state.generatedRepresentation = action.payload
    },
    toggleDisplayAddOperationModal: (state, action: PayloadAction<boolean>) => {
      state.displayAddOperationModal = action.payload
    },
    toggleDisplayModifyOperationModal: (state, action: PayloadAction<boolean>) => {
      state.displayModifyOperationModal = action.payload
    },
    updateOperationLabel: (state, action: PayloadAction<string>) => {
      state.operationLabel = action.payload
    },
    toggleOpenOperationContextMenu: (state, action: PayloadAction<boolean>) => {
      state.openOperationContextMenu = action.payload
    },
    toggleOpenOperationColorDialog: (state, action: PayloadAction<boolean>) => {
      state.openOperationColorDialog = action.payload
    },
    updateContextPosition: (state, action: PayloadAction<ContextPosition>) => {
      state.contextPosition = action.payload
    },
    updateSelectedOperationPoint: (state, action: PayloadAction<OperationPoint | undefined>) => {
      state.selectedOperationPoint = action.payload
    },
    updateSelectedOperation: (state, action: PayloadAction<Operation | undefined>) => {
      state.selectedOperation = action.payload
    },
    toggleLoadingRepresentation: (state, action: PayloadAction<boolean>) => {
      state.loadingRepresentation = action.payload
    },
    updateFerrolocFields: (state, action: PayloadAction<OperationFerrolocFields[]>) => {
      state.ferrolocFields = action.payload
    },
    updateSubClassificationId: (state, action: PayloadAction<number | undefined>) => {
      state.subClassificationId = action.payload
    },
    updatePhaseFields: (state, action: PayloadAction<OperationWorkPhaseFields[]>) => {
      state.phaseFields = action.payload
    },
    toggleEmptyFields: (state, action: PayloadAction<boolean>) => {
      state.emptyFields = action.payload
    },
    updateResponseError: (state, action: PayloadAction<ResponseError | undefined>) => {
      state.responseError = action.payload
    },
    toggleDeletePhaseField: (state, action: PayloadAction<boolean>) => {
      state.deletePhaseField = action.payload
    },
    toggleDeleteFerrolocField: (state, action: PayloadAction<boolean>) => {
      state.deleteFerrolocField = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(generateRepresentation.fulfilled, state => {
      state.generatedRepresentation = true
    })
    builder.addCase(generateRepresentation.pending, state => {
      state.loadingRepresentation = true
    })
    builder.addCase(getSubClassificationsByClassificationId.fulfilled, (state, action) => {
      state.subClassificationByClassification = action.payload
    })
    builder.addCase(getLineCodeTracksList.fulfilled, (state, action) => {
      state.tracksList = action.payload
    })
    builder.addCase(postOperation.fulfilled, state => {
      state.displayAddOperationModal = false
      state.operationLabel = ''
      state.subClassificationId = -1
      state.phaseFields = []
      state.ferrolocFields = []
      state.selectedOperationPoint = undefined
      state.selectedOperation = undefined
      state.responseError = undefined
    })

    builder.addCase(patchOperation.fulfilled, state => {
      state.displayModifyOperationModal = false
      state.operationLabel = ''
      state.subClassificationId = -1
      state.phaseFields = []
      state.ferrolocFields = []
      state.responseError = undefined
      state.selectedOperationPoint = undefined
      state.selectedOperation = undefined
    })

    builder.addCase(getOperation.fulfilled, (state, action) => {
      state.selectedOperation = action.payload
    })

    builder.addCase(deleteOperation.pending, state => {
      state.deletingOperation = true
    })

    builder.addMatcher(isAnyOf(
      generateRepresentation.rejected,
      getSubClassificationsByClassificationId.rejected,
      patchOperationPosition.rejected,
      patchOperation.rejected,
      postOperation.rejected,
      deleteOperation.rejected,
    ), (state, action) => {
      state.responseError = action.payload
      state.deletingOperation = false
    })
    builder.addMatcher(isAnyOf(
      deleteOperation.fulfilled,
      patchOperationPosition.fulfilled,
      PatchFamilyTrack.fulfilled,
      patchClassification.fulfilled,
      deleteDefectedOperation.fulfilled,
    ), state => {
      state.displayDeleteModal = false
      state.selectedOperationPoint = undefined
      state.selectedOperation = undefined
      state.deletingOperation = false
    })
  },
})

export const {
  toggleGenerateRepresentation, toggleOpenOperationContextMenu,
  updateContextPosition, toggleDisplayDeleteModal, updateSelectedOperationPoint, toggleMoveOperationDialog,
  toggleDisplayAddOperationModal, toggleLoadingRepresentation, updateOperationLabel,
  updateFerrolocFields, updateSubClassificationId, updatePhaseFields, toggleEmptyFields, updateResponseError,
  toggleOpenOperationColorDialog, toggleDisplayModifyOperationModal, updateSelectedOperation, resetObjectsFileds,
  toggleDeletePhaseField, toggleDeleteFerrolocField,
} = mypOperationActionsSlice.actions
export default mypOperationActionsSlice.reducer
