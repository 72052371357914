import { Button, Menu, MenuItem } from '@mui/material'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import terms from 'common/terms'
import { DeleteComment } from 'reducers/MultiYearPlan/Comments/comments.thunk'
import { toggleUpdateButton } from 'reducers/MultiYearPlan/Comments/comments'
import { Comment } from 'reducers/MultiYearPlan/Comments/types'

export default function ContextMenu({
  anchorEl, setAnchorEl, comment, commentIndex,
}: {anchorEl: null | HTMLElement,
  setAnchorEl: (v: null | HTMLElement) => void,
  comment: null | Comment, commentIndex: null | number
}) : ReactElement {
  const dispatch = useDispatch()
  const { activeFile } = useSelector((state: RootState) => state.files)
  const open = Boolean(anchorEl)

  const onClickDelete = () => {
    dispatch(DeleteComment({
      repertoryId: activeFile.directory,
      folderId: activeFile.folder,
      fileId: activeFile.id,
      commentId: comment?.id,
    }))
    setAnchorEl(null)
  }

  const onClickModify = () => {
    setAnchorEl(null)
    dispatch(toggleUpdateButton({
      displayUpdateButton: true,
      updateCommentIndex: commentIndex,
    }))
  }

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
    >
      <MenuItem>
        <Button
          className="actions-menu-btn"
          onClick={onClickDelete}
        >
          {terms.MultiYearPlan.Comments.ActionsButton.DeleteBtn}
        </Button>
      </MenuItem>
      <MenuItem>
        <Button
          className="actions-menu-btn"
          onClick={onClickModify}
        >
          {terms.MultiYearPlan.Comments.ActionsButton.ModifyBtn}
        </Button>
      </MenuItem>
    </Menu>
  )
}
