/* eslint-disable camelcase */
export enum FileExtention {
    SVG = 'svg',
    PDF = 'pdf'
}

export enum PageFormat {
    A3 = 'A3',
    A4 = 'A4'
}

export enum PageOrientation {
    portrait = 'portrait',
    landscape = 'paysage'
}

export type ExportParams = {
    file_extension?: string,
    file_name?: string,
    complete?: boolean,
    include_cbdp?: boolean
}

export type ExportResponse = {
    svg_file: string
}

export type ObjectLegend = {
    legend: string,
    svg: string,
}

export type PortfolioLegend = {
    legend: string,
    index: number,
    color: string,
}

export type Cartridge = {
    project: number,
    versions: CartridgeVersion[],
    title: string,
    warnings: string,
}

export type CartridgeVersion = {
    author: string,
    comment: string,
    date: string,
    index: number | null,
    version: string
}

export type VersionsFields = {
    index: number,
    version: CartridgeVersion
}

export type PatchExportParams = {
    projectId: number
    rkStep: number
    pageScale: number
    realityScale: number
}
