import { ReactElement } from 'react'
import ActionButton from 'components/common/Buttons/ActionButton'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import terms from 'common/terms'
import { MypImportStepsList } from '../types'

import './button.scss'

export default function ImportActionButton({ handleClick, disabled }:{handleClick: () => void, disabled?: boolean}):
 ReactElement {
  const { activeStep } = useSelector((state: RootState) => state.mypImport)

  const getText = () => (activeStep === MypImportStepsList.Parametrage ? terms.MultiYearPlan.Import.generateMyp
    : terms.Common.confirm)
  const getButtonStyle = () => (activeStep === MypImportStepsList.Parametrage ? 'myp-import-generation-btn'
    : 'myp-import-confirmation-btn')
  return (
    <div className="d-flex justify-content-center">
      <ActionButton
        text={getText()}
        onClick={handleClick}
        buttonStyle={getButtonStyle()}
        rightIcon
        disabled={disabled}
      />
    </div>
  )
}

ImportActionButton.defaultProps = {
  disabled: false,
}
