import { ReactElement } from 'react'
import {
  Autocomplete, FormLabel, Grid, TextField,
} from '@mui/material'
import terms from 'common/terms'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { Folder } from 'reducers/Folders/types'

import '../file.scss'

export default function FolderSelect({ folder, handleChange }: {folder: Folder | null,
     handleChange: (v: Folder) => void}): ReactElement {
  const { folders } = useSelector((state: RootState) => state.folders)
  return (
    <Grid item width={400} mt={5} mb={5}>
      <FormLabel>
        {terms.File.Actions.foldersList}
      </FormLabel>
      <Autocomplete
        options={folders}
        filterOptions={options => options}
        getOptionLabel={option => option.label}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        noOptionsText={terms.Common.noResults}
        value={folder}
        renderInput={params => (
          <TextField
            {...params}
            placeholder={terms.File.Actions.selectFolderPlaceholder}
            size="small"
          />
        )}
        onChange={(_event, newValue) => handleChange(newValue as Folder)}
      />
    </Grid>
  )
}
