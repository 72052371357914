import { ReactElement, useRef } from 'react'
import { Paper, IconButton, InputBase } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

import './search.scss'
import { useDispatch } from 'react-redux'

export default function SearchBar({ placeholder, onChange, search }:
     {placeholder: string, onChange: (value: string) => void, search: string}): ReactElement {
  const dispatch = useDispatch()
  const inputRef = useRef<HTMLInputElement>(null)
  return (
    <Paper
      elevation={0}
      component="form"
      className="search-bar"
    >
      <IconButton type="submit" aria-label="search" disabled>
        <SearchIcon />
      </IconButton>
      <InputBase
        ref={inputRef}
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeholder}
        onBlur={() => search !== '' && inputRef.current?.getElementsByTagName('input')[0].focus()}
        inputProps={{ 'aria-label': `${placeholder}` }}
        value={search}
        onChange={e => dispatch(onChange(e.target.value))}
        onKeyPress={event => {
          if (event.key === 'Enter') { event.preventDefault() }
        }}
      />
    </Paper>
  )
}
