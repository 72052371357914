/* eslint-disable no-case-declarations */
import {
  ReactElement, useState, useEffect, MutableRefObject,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import _ from 'lodash'
import ObjectService from 'components/Objects/ObjectService'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import { NavigationControl, FullscreenControl, MapRef } from 'react-map-gl'
import toggleLayersIcon from 'assets/icons/toggleLayers.svg'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'

import './ToolBar.scss'
import { TASKS_NAMES, TASKS_STATUS } from 'components/Dashboard/Creation/types'
import ActionButton from 'components/common/Buttons/ActionButton'
import CreationService from 'components/Dashboard/Creation/CreationService'
import { getTaskStatus } from 'components/Dashboard/Creation/utils'
import { toggleDisableSectionsUpdate } from 'reducers/creation'
import { ObjectsLayer } from 'components/Map/ObjectsLayers/types'
import { toggleLoadingLayers } from 'reducers/object'
import { Accordion } from 'react-bootstrap'
import terms from 'common/terms'
import { FilterObjects, Filters } from './types'
import RecenterButton from './RecenterButton'

type Props = {
  mapRef: MutableRefObject<MapRef | undefined> | undefined;
};

export default function ToolBar({ mapRef }:Props): ReactElement {
  const dispatch = useDispatch()
  const { activeProject } = useSelector((state: RootState) => state.creation)
  const { filtersList, LayerStatus, loadingLayers } = useSelector((state: RootState) => state.object)
  const [displayPanel, setDisplayPanel] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  useEffect(() => {
    dispatch(ObjectService.getFilterList(activeProject?.id))
    dispatch(ObjectService.getLayersStatus(activeProject?.id))
  }, [activeProject])

  useEffect(() => {
    if (activeProject) {
      if (getTaskStatus(LayerStatus, TASKS_NAMES.ImportInfra) === TASKS_STATUS.Pending
      || !getTaskStatus(LayerStatus, TASKS_NAMES.ImportInfra)) {
        setLoading(true)
        setTimeout(() => {
          dispatch(ObjectService.getLayersStatus(activeProject.id))
        }, 5000)
      } else if (getTaskStatus(LayerStatus, TASKS_NAMES.ImportInfra) === TASKS_STATUS.Failure) {
        setLoading(false)
        setError(true)
      } else if (getTaskStatus(LayerStatus, TASKS_NAMES.ImportInfra) === TASKS_STATUS.Success) {
        setLoading(false)
        setError(false)
        dispatch(ObjectService.getSignalsLayer(activeProject.id))
        dispatch(ObjectService.GetAdvLayer(activeProject.id))
        dispatch(ObjectService.GetRkLayer(activeProject.id))
        dispatch(ObjectService.GetTeLayer(activeProject.id))
        dispatch(ObjectService.GetPeLayer(activeProject.id))
        dispatch(ObjectService.GetBvLayer(activeProject.id))
        if (getTaskStatus(LayerStatus, TASKS_NAMES.ImportVs) === TASKS_STATUS.Pending) {
          setTimeout(() => {
            dispatch(ObjectService.getLayersStatus(activeProject.id))
          }, 5000)
        } else if (getTaskStatus(LayerStatus, TASKS_NAMES.ImportVs) === TASKS_STATUS.Success) {
          const mapReference = mapRef?.current?.getMap()
          if (mapReference) {
            mapReference?.style?.sourceCaches[ObjectsLayer.tiv]?.clearTiles()
            mapReference?.style?.sourceCaches[ObjectsLayer.tiv]?.update(mapReference.transform)
            mapReference.triggerRepaint()
          }
        }
      }
      if (getTaskStatus(LayerStatus, TASKS_NAMES.ImportInfra) === TASKS_STATUS.Success
      && getTaskStatus(LayerStatus, TASKS_NAMES.ImportVs) === TASKS_STATUS.Success) {
        dispatch(toggleLoadingLayers(true))
      }
      dispatch(toggleDisableSectionsUpdate(getTaskStatus(LayerStatus, TASKS_NAMES.ImportInfra) !== TASKS_STATUS.Success
      || getTaskStatus(LayerStatus, TASKS_NAMES.ImportVs) !== TASKS_STATUS.Success))
    }
  }, [LayerStatus])

  useEffect(() => {
    if (loadingLayers) {
      setTimeout(() => {
        dispatch(toggleLoadingLayers(false))
      }, 1000)
    }
  }, [loadingLayers])

  function togglePanel() {
    setDisplayPanel(!displayPanel)
  }

  const patchFilter = (event: any, indexObj: number, indexCat: number) => {
    const newFilter = _.cloneDeep(filtersList)
    newFilter[indexCat].objects[indexObj].checked = event.target.checked
    const object = {
      projectId: activeProject.id,
      newFilter,
    }
    dispatch(ObjectService.patchFilterList(object))
  }

  const relaunchImport = () => {
    dispatch(CreationService.importSections(activeProject.id))
    dispatch(ObjectService.getLayersStatus(activeProject.id))
    setError(false)
    setLoading(true)
  }

  return (
    <>
      <div className={`${displayPanel ? 'map-tool-bar-extended' : 'map-tool-bar'}`}>
        <Paper className="d-flex flex-column align-items-center customHoverFocus justify-content-center bg-secondary">
          <IconButton
            className="customHoverFocus"
            onClick={() => togglePanel()}
          >
            <img src={toggleLayersIcon} alt={toggleLayersIcon} />
          </IconButton>
          <NavigationControl className="navigation-control" />
          <FullscreenControl className="fullscreen-control" />
          <RecenterButton />
        </Paper>
      </div>
      <div className={`${displayPanel ? 'panel-tool-bar-extended' : 'panel-tool-bar'}`}>
        <Accordion className="m-3">
          {filtersList.map((filter: Filters, indexCat: number) => (
            <Accordion.Item key={filter.category} eventKey={filter.category}>
              <Accordion.Header className="infra-objects-accordion">
                {filter.category}
              </Accordion.Header>
              {filter.objects.map((object: FilterObjects, indexObj: number) => (
                <Accordion.Body key={object.name} className="p-0">
                  <FormControlLabel
                    sx={{ ml: 2 }}
                    label={<Typography style={{ fontSize: '12px' }}>{object.name}</Typography>}
                    control={<Checkbox checked={object.checked} />}
                    onChange={e => patchFilter(e, indexObj, indexCat)}
                    disabled={loading || error}
                  />
                </Accordion.Body>
              ))}
            </Accordion.Item>

          ))}
          {loading && (
          <Alert severity="info" variant="outlined" sx={{ fontSize: '12px', marginTop: '30px' }}>
            <AlertTitle>{terms.Common.alert.infoText}</AlertTitle>
            {terms.Map.Toolbar.layerLoading}
          </Alert>
          )}
          {error && (
          <Alert severity="warning" variant="outlined" sx={{ fontSize: '12px', marginTop: '30px' }}>
            <AlertTitle>{terms.Common.alert.infoText}</AlertTitle>
            {terms.Map.Toolbar.layerLoadingError}
          </Alert>
          )}
          {error && (
          <div className="d-flex justify-content-center">
            <ActionButton
              text={terms.Map.Toolbar.relaunch}
              onClick={relaunchImport}
              buttonStyle="relaunch-btn"
              disabled={getTaskStatus(LayerStatus, TASKS_NAMES.ImportVs) === TASKS_STATUS.Pending}
            />
          </div>
          )}
        </Accordion>
      </div>
    </>
  )
}
