import { FilterObjects, Filters } from '../ToolBar/types'

export const getFilterCheckStatus = (filtersList: Filters[], category: string, layer: string): boolean => {
  if (filtersList.length !== 0) {
    const filteredObject = filtersList.filter((filter: Filters) => filter.category === category)[0]
    return filteredObject?.objects.filter((object: FilterObjects) => object.layer === layer)[0]?.checked
  }
  return true
}

export const getFilterCheckStatusByName = (filtersList: Filters[], category: string, name: string): boolean => {
  if (filtersList.length !== 0) {
    const filteredObject = filtersList.filter((filter: Filters) => filter.category === category)[0]
    return filteredObject?.objects.filter((object: FilterObjects) => object.name === name)[0]?.checked
  }
  return true
}

export const getFilterCheckStatusByType = (filtersList: Filters[], category: string, type: string): boolean => {
  if (filtersList.length !== 0) {
    const filteredObject = filtersList.filter((filter: Filters) => filter.category === category)[0]
    return filteredObject?.objects.filter((object: FilterObjects) => object.type === type)[0]?.checked
  }
  return true
}

export const getLayerVisibility = (filtersList: Filters[], category: string,
  layer: string, displayedLayer: boolean) => (getFilterCheckStatus(filtersList, category, layer) && displayedLayer
  ? 'visible' : 'none')

export const getLayerVisibilityByName = (filtersList: Filters[], category: string,
  name: string, displayedLayer: boolean) => (getFilterCheckStatusByName(filtersList, category, name) && displayedLayer
  ? 'visible' : 'none')

export const getLayerVisibilityByType = (filtersList: Filters[], category: string,
  type: string, displayedLayer: boolean) => (getFilterCheckStatusByType(filtersList, category, type) && displayedLayer
  ? 'visible' : 'none')
