import { ReactElement } from 'react'
import { ToolsProps } from 'components/Portfolio/LabelTools/types'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import UpdatePolygon from 'components/Portfolio/LabelTools/UpdatePolygon'
import ModifyObjectPosition from 'components/Objects/ObjectTools/ModifyObjectPosition'
import UpdateLabel from '../../Portfolio/LabelTools/UpdateLabel'
import UpdateLabelColor from '../../Portfolio/LabelTools/UpdateLabelColor'
import DeleteLabel from '../../Portfolio/LabelTools/DeleteLabel'
import { LabelsLayersIds } from '../ObjectsLayers/types'

export default function LabelsContextMenu({ closePopup }: ToolsProps): ReactElement {
  const { clickedFeatureInfo } = useSelector((state: RootState) => state.map)

  switch (clickedFeatureInfo?.layer.id) {
    case LabelsLayersIds.actionZones:
      return <UpdatePolygon closePopup={closePopup} />
    default:
      return (
        <>
          <UpdateLabel closePopup={closePopup} />
          <UpdateLabelColor closePopup={closePopup} />
          <ModifyObjectPosition closePopup={closePopup} />
          <DeleteLabel closePopup={closePopup} />
        </>
      )
  }
}
