import { ReactElement } from 'react'
import {
  Container, Fade,
} from '@mui/material'
import history from 'customHistory'
import ContainerHeader from 'components/common/Container/ContainerHeader'
import PathNames from 'common/PathNames'
import terms from 'common/terms'
import ContainerBody from 'components/common/Container/ContainerBody'
import ContainerFooter from 'components/common/Container/ContainerFooter'
import { useDispatch, useSelector } from 'react-redux'
import { resetActiveRepertory, setError } from 'reducers/Repertories/repertories'
import SnackBar from 'components/common/Snackbar/SnackBar'
import { RootState } from 'Store'
import SkipPictureConfigurationButtons from './SkipPictureConfigurationButton'
import PictureUpload from './PictureUpload'
import { getErrorMessage } from './utils'

export default function PictureConfiguration(): ReactElement {
  const dispatch = useDispatch()
  const { error } = useSelector((state: RootState) => state.repertories)

  const handleRollBack = () => {
    history.push(PathNames.Repertory)
    dispatch(setError(undefined))
  }

  const handleClose = () => {
    history.push(PathNames.Home)
    dispatch(resetActiveRepertory())
  }
  return (
    <main>
      <Fade timeout={400} in mountOnEnter unmountOnExit>
        <Container maxWidth={false} disableGutters sx={{ height: '100vh', backgroundColor: '#183B68' }}>
          <ContainerHeader
            onRollBack={handleRollBack}
            rollBackText={terms.Common.backToNameDeclaration}
            onClose={handleClose}
          />
          <ContainerBody
            title={terms.Repertory.pictureDeclarationTitle}
            subtitle={terms.Common.declarationSubtitle}
            content={<PictureUpload />}
          />
          <ContainerFooter content={<SkipPictureConfigurationButtons />} />
        </Container>
      </Fade>
      <SnackBar message={getErrorMessage(error)} error={error} setError={setError} />
    </main>
  )
}
