import {
  ClickEvent, DraggingEvent, FeatureCollection, FeatureWithProps, ImmutableFeatureCollection,
  ModeProps, ModifyMode, Point, Position, StartDraggingEvent, StopDraggingEvent, utils,
} from '@nebula.gl/edit-modes'
import turfBearing from '@turf/bearing'
import turfCentroid from '@turf/centroid'
import { multiPoint } from '@turf/helpers'
import { store } from 'Store'
import { GeoEditorState, updateHistory, setMultiDragRefPoint } from '../reducer'

import { Modes } from '../utils'
import { absoluteAngle, planeDistance } from './utils'

export type SelectedPoints = Array<{
  featureIndex: number;
  points: Array<Position | undefined>;
}> | undefined

export type MultiDragEditContext = {
  bearing: number;
  distance: number;
  pointerDownMapCoords: Position;
  mapCoords: Position;
}

export type MultiRotateEditContext = {
  centroid: Position;
  angle: number;
}

export type MoveEditContext = {
  featureIndexes: [number];
  positionIndexes: number[];
  position: [number, number];
}

function getRotationAngle(centroid: Position, startDragPoint: Position, currentPoint: Position) {
  const bearing1 = turfBearing(centroid, startDragPoint)
  const bearing2 = turfBearing(centroid, currentPoint)
  return bearing2 - bearing1
}

class CustomModifyMode extends ModifyMode {
  editorMode: Modes

  selectedPoints: SelectedPoints

  constructor(editorMode: Modes, selectedPoints: SelectedPoints) {
    super()
    this.editorMode = editorMode
    this.selectedPoints = selectedPoints
  }

  handleClick(event: ClickEvent, props: ModeProps<FeatureCollection>): void {
    const pickedExistingHandle = utils.getPickedExistingEditHandle(event.picks)
    const pickedIntermediateHandle = utils.getPickedIntermediateEditHandle(event.picks)

    if (pickedExistingHandle) {
      const { featureIndex, positionIndexes } = pickedExistingHandle.properties

      let updatedData
      if (this.editorMode === Modes.deletePoint) {
        try {
          updatedData = new ImmutableFeatureCollection(props.data)
            .removePosition(featureIndex, positionIndexes)
            .getObject()
        } catch (ignored) {
          // This happens if user attempts to remove the last point
          updatedData = {
            ...props.data,
            features: props.data.features.map((f, i) => {
              if (i !== featureIndex) return f
              return {
                ...f,
                properties: {
                  ...f.properties,
                  deleted: true,
                },
                geometry: {
                  type: 'LineString',
                  coordinates: [],
                },
              }
            }),
          } as typeof props.data
        }
        if (updatedData) {
          store.dispatch(updateHistory(props.data))
          props.onEdit({
            updatedData,
            editType: 'removePosition',
            editContext: {
              featureIndexes: [featureIndex],
              positionIndexes,
              position: pickedExistingHandle.geometry.coordinates,
            },
          })
        }
      } else if (this.editorMode === Modes.draw) {
        store.dispatch(updateHistory(props.data))
        props.onEdit({
          updatedData: props.data,
          editType: 'clickPosition',
          editContext: {
            featureIndexes: [featureIndex],
            positionIndexes,
            position: pickedExistingHandle.geometry.coordinates,
          },
        })
      }
    } else if (pickedIntermediateHandle && this.editorMode === Modes.draw && this.selectedPoints === undefined) {
      const { featureIndex, positionIndexes } = pickedIntermediateHandle.properties

      const feature = props.data.features[featureIndex]
      const canAddPosition = !(
        props.modeConfig?.lockRectangles && feature.properties && feature.properties.shape === 'Rectangle'
      )

      if (canAddPosition) {
        const updatedData = new ImmutableFeatureCollection(props.data)
          .addPosition(featureIndex, positionIndexes, pickedIntermediateHandle.geometry.coordinates)
          .getObject()

        if (updatedData) {
          store.dispatch(updateHistory(props.data))
          props.onEdit({
            updatedData,
            editType: 'addPosition',
            editContext: {
              featureIndexes: [featureIndex],
              positionIndexes,
              position: pickedIntermediateHandle.geometry.coordinates,
            },
          })
        }
      }
    }
  }

  handleStartDragging(event: StartDraggingEvent, props: ModeProps<FeatureCollection>) {
    const { geoJson } = store.getState().geoEditor as GeoEditorState
    if (geoJson && this.editorMode !== Modes.grab) {
      store.dispatch(updateHistory(geoJson))
    }
    super.handleStartDragging(event, props)
  }

  handleStopDragging(event: StopDraggingEvent, props: ModeProps<FeatureCollection>): void {
    store.dispatch(setMultiDragRefPoint(undefined))
    super.handleStopDragging(event, props)
  }

  // eslint-disable-next-line no-underscore-dangle
  _dragEditHandle(
    editType: string,
    props: ModeProps<FeatureCollection>,
    editHandle: FeatureWithProps<Point, {
      guideType: 'editHandle';
      editHandleType: string;
      featureIndex: number;
      positionIndexes?: number[];
      shape?: string;
    }>,
    event: StopDraggingEvent | DraggingEvent,
  ): void {
    const editHandleProperties = editHandle.properties

    if (this.selectedPoints) {
      const { mapCoords, pointerDownMapCoords } = event
      if (this.editorMode === Modes.draw) {
        const distance = planeDistance(pointerDownMapCoords, mapCoords)
        const bearing = absoluteAngle(pointerDownMapCoords, mapCoords)
        props.onEdit({
          updatedData: props.data,
          editType: editType === 'finishMovePosition' ? editType : 'dragMultiPosition',
          editContext: {
            distance,
            bearing,
            pointerDownMapCoords,
            mapCoords,
          } as MultiDragEditContext,
        })
      } else if (this.editorMode === Modes.rotate) {
        const pointsCoords = this.selectedPoints
          .map(selectedPoint => selectedPoint.points)
          .flat()
          .filter(p => p !== undefined) as Position[]
        const selectedPointsFeature = multiPoint(pointsCoords)
        const centroid = turfCentroid(selectedPointsFeature)
        const angle = getRotationAngle(centroid.geometry.coordinates as Position, pointerDownMapCoords, mapCoords)
        props.onEdit({
          updatedData: props.data,
          editType: editType === 'finishMovePosition' ? 'finishRotatePosition' : 'rotateMultiPosition',
          editContext: {
            centroid: centroid.geometry.coordinates,
            angle,
          } as MultiRotateEditContext,
        })
      }
    } else {
      props.onEdit({
        updatedData: props.data,
        editType,
        editContext: {
          featureIndexes: [editHandleProperties.featureIndex],
          positionIndexes: editHandleProperties.positionIndexes,
          position: event.mapCoords,
        },
      })
    }
  }
}

export default CustomModifyMode
