import terms from 'common/terms'
import { InputTypes } from 'types'

export type PrInput = {
    key: string
    label: string
    placeholder?: string
    fieldType: string
}

export const PrInputFields: PrInput[] = [
  {
    key: 'line_code',
    label: terms.Inputs.line,
    placeholder: terms.Inputs.lineNumberPlaceholder,
    fieldType: InputTypes.Select,
  },
  {
    key: 'kp',
    label: terms.Inputs.pk,
    placeholder: terms.Inputs.pkPlaceholder,
    fieldType: InputTypes.SimpleField,
  },
  {
    key: 'label',
    label: terms.Inputs.prLabel,
    placeholder: terms.Inputs.prLabelPlaceholder,
    fieldType: InputTypes.SimpleField,
  },
]
